import {sendMessage} from '@/_helpers/service-tools'

export const playerskillService = {
    findAllForEventAndStaff,
    updateScore,
    findAllForEvent,
    loadAllSkillScoresForParticipant,
    loadParticipantStats,
}

async function loadParticipantStats(payload) {
    console.log('Making call')
    //return await sendMessage('GET', 'tryoutskills', {eventId: '', userId: payload.id}, null)
    return await sendMessage('GET', 'tryoutskills/loadParticipantStats', {participantId: payload.participantId, eventId: payload.eventId}, null)
}

async function findAllForEventAndStaff(id, uid) {
    return await sendMessage('GET', 'tryoutskills', {eventId: id, userId: uid}, null)
}

async function loadAllSkillScoresForParticipant(payload) {
    return await sendMessage('GET', 'tryoutskills/loadAllSkillScoresForParticipant', {eventId: payload.eventId, participantId: payload.participantId}, null)
}

async function findAllForEvent(id) {
    return await sendMessage('GET', 'tryoutskills/allstaff', {eventId: id}, null)
}

async function updateScore(participantId, criterionId, staffId, eventId, score) {
    return await sendMessage('PATCH', 'tryoutskills', null, {participantId, criterionId, userId: staffId, eventId, score})
}
