<template>
    <div v-if="authorizedForEvent(['owner','admin','programcoach','headcoach'])">
        <v-app-bar v-if="$vuetify.breakpoint.smAndDown" height="32px" color="var(--cs1-lev2)" style="padding-left:0px;margin-left:0px;">
            <v-menu dense small click-on-open open-on-hover offset-y v-model="showSettingsMenu" >
                <template v-slot:activator="{on, attrs}">
                    <div v-on="on" v-bind="attrs" style="text-align:left;display:flex;">
                        <span class="third-chev"><v-icon small style="color:var(--cs2-lev1);padding-right:5px;">mdi-menu</v-icon>Development Program Settings </span>
                        <span class="setting-heading"> {{ settingsPanes[settingsPane].name }}</span>
                    </div>
                </template>
                <v-list>
                    <v-list-item v-for="(item,n) in settingsPanes" :key="n" :value="n" link>
                        <v-list-item-content @click="settingsPane=n">
                            {{ item.name }}
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
        <div style="display:flex; flex-flow:row">
            <v-navigation-drawer v-if="$vuetify.breakpoint.mdAndUp" permanent >
                <v-list-item class="settings-title">
                    <v-list-item-icon>
                        <v-icon style="color:white">mdi-cog</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="settings-title" >
                            Development Program Settings
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider/>
                <v-list nav>
                    <v-list-item v-for="(item, n) in filteredPanes" :key="n" :value="item.page" link>
                        <v-divider v-if="item.name==='---'"/>
                        <div v-else style="display:flex;flex-flow:row">
                            <v-list-item-icon>
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content @click="settingsPane=item.page">
                                {{ item.name }}
                            </v-list-item-content>
                        </div>
                    </v-list-item>

                </v-list>
            </v-navigation-drawer>
            <div style="width:100%">
                <div class="text-right ma-2 pa-1" >
                    <v-btn @click.native.stop 
                            :disabled="!changes" 
                            x-small 
                            v-on:click="saveDetails" 
                            depressed 
                            color="primary" 
                            class="save-btn"
                        >
                            Save
                        </v-btn>
                </div>
                <dev-stream-details v-if="settingsPane===0"/>
                <ManageTryoutContact v-if="settingsPane===1"/>
                <ManageTryoutCriteria v-if="settingsPane===2"/>
                <ManageTryoutQuestions v-if="settingsPane===3" context="devregistration"/>
                <ManageTryoutQuestions v-if="settingsPane===4" context="devform"/>
                <ManageTryoutQuestions v-if="settingsPane===5" context="episodeform"/>
                <ManageTryoutQuestions v-if="settingsPane===6" context="participantreflection"/>
                <ManageTryoutStaff v-if="settingsPane===7"/>
                <ManageTryoutRoles v-if="settingsPane===8"/>
                <TryoutAthleteImport v-if="settingsPane===9"/>
            </div>
        </div>
    </div>
    <auth-fail v-else/>
</template>

<script>

import DevStreamDetails from '@/ClubPanes/DevelopmentStreams/DevStreamDetails'
import ManageTryoutContact from '@/ClubPanes/NewTryoutSettingsPanels/ManageTryoutContact'
import ManageTryoutCriteria from '@/ClubPanes/NewTryoutSettingsPanels/ManageTryoutCriteria'
import ManageTryoutQuestions from '@/ClubPanes/NewTryoutSettingsPanels/ManageTryoutQuestions'
import ManageTryoutStaff from '@/ClubPanes/NewTryoutSettingsPanels/ManageTryoutStaff'
import ManageTryoutRoles from '@/ClubPanes/NewTryoutSettingsPanels/ManageRoles'
import TryoutAthleteImport from '@/ClubPanes/NewTryoutSettingsPanels/TryoutAthleteImport'

import {mapActions, mapState} from 'vuex'

export default{
    props: [],
    components:{
        DevStreamDetails,
        ManageTryoutContact,
        ManageTryoutCriteria,
        ManageTryoutQuestions,
        ManageTryoutStaff,
        ManageTryoutRoles,
        TryoutAthleteImport,
    },
    computed: {
        ...mapState({
            selectedEvent                   : state => state.navigatorStore.selectedTryout
        }),
        loading(){
            return false ||
                false;
        },
        filteredPanes(){
            return this.settingsPanes
            /*
            return this.settingsPanes.filter((item) => (allowForEvent(item.auth)))
            */
        },
        changes() {
            return Object.keys(this.changeTracker).length>0
        },

    },
    methods: {
        ...mapActions('staffStore', { 
            findEventStaff: 'findAllForEvent',
        }),
        ...mapActions('adminStore', { 
            findEventAdmins: 'findAllForEvent',
        }),
        saveDetails(){
            this.$root.$emit('submit-all-changes')
        },

    },
    watch: {},
    mounted(){
        this.$root.$on('settings-changes-cleared', (id) => {
            var self=this
            self.$delete(self.changeTracker, id)
        })
        this.$root.$on('settings-changes-made', (id) => {
            var self=this
            self.$set(self.changeTracker, id, true)
        })
    },
    async created(){
        await this.findEventStaff({eventId: this.selectedEvent})
        this.findEventAdmins({eventId: this.selectedEvent})
    },
    data(){
        return {
            changeTracker: {},
            showSettingsMenu: false,
            settingsPane: 0, 
            settingsPanes: [
                { 
                    page: 0, 
                    name: 'Development Program Details', 
                    icon: 'mdi-information-variant-box-outline', 
                    auth: ['admin','headcoach', 'programcoach']
                },
                {
                    page: 1,
                    name: 'Program Contact',
                    icon: 'mdi-card-account-mail-outline',
                    auth: ['admin', 'headcoach','programcoach']
                },
                {
                    page: 2,
                    name: 'Program Criteria',
                    icon: 'mdi-karate',
                    auth: ['admin', 'headcoach','programcoach']
                },
                {
                    page: 3,
                    name: 'Registration Questions',
                    icon: 'mdi-file-question-outline',
                    auth: ['admin', 'headcoach','programcoach']
                },
                {
                    page: 4,
                    name: 'Episode Questions',
                    icon: 'mdi-file-question-outline',
                    auth: ['admin', 'headcoach','programcoach']
                },
                {
                    page: 5,
                    name: 'Evalutation Questions',
                    icon: 'mdi-file-question-outline',
                    auth: ['admin', 'headcoach','programcoach']
                },
                {
                    page: 6,
                    name: 'Participant Reflection Questions',
                    icon: 'mdi-file-question-outline',
                    auth: ['admin', 'headcoach','programcoach']
                },
                {
                    page: 7,
                    name: 'Staff',
                    icon: 'mdi-security',
                    auth: ['admin', 'headcoach','programcoach']
                },
                {
                    page: 8,
                    name: 'Roles',
                    icon: 'mdi-hat-fedora',
                    auth: ['admin', 'headcoach','programcoach']
                },
                {
                    page: 9,
                    name: `Import Participants`,
                    icon: 'mdi-import',
                    auth: ['admin', 'headcoach','programcoach']
                },
            ],
            taborder:0,
        }
    }

}
</script>