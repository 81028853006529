import { systemService } from '../_services'
//import Vue from 'vue'

const dataService = systemService

function initialiseState(){
    return {
        status: {},
        data: {},
        stats: {},
        recentUserList: [],
        recentActivityList: [],
        list: [],
        error: null,
        cache: {},
        meta: {},
        internal: {},
    }
}

const state = initialiseState()

const actions = {
    purgeData({commit}){
        commit('purgeData')
    },
    async mainstats({commit}) {
        try{
            commit('mainstatsRequest')

            const response = await dataService.mainstats()
            commit('mainstatsSuccess', response)
        } catch(error) {
            commit('mainstatsFailure', error)
        }
    },
    async recentActivity({commit, dispatch }, payload){
        try{
            const {userId} = payload
            commit('recentActivityRequest', payload)
            const response = await dataService.recentActivity({userId})
            commit('recentActivitySuccess', response)
        } catch(error) {
            commit('recentActivityFailure', error)
            dispatch('alert/error', error, {root: true})
        }
    },
    async recentUsers({commit, dispatch}) {
        try{
            commit('recentUsersRequest')

            const response = await dataService.recentUsers( )
            commit('recentUsersSuccess', response)
        } catch(error) {
            commit('recentUsersFailure', error)
            dispatch('alert/error', error, {root: true})
        }
    },
    propertyUpdate({commit},payload) {
        commit('propertyUpdate', payload)
    }
}
/*
function addToLists(data) {
    const id = data.id
    state.statList.push(id)
    Vue.set(state.data, id, data)
    Vue.set(state.meta, id, {
            delete : false,
    })
}

function sortListByOrder() {
        state.list.sort((a,b) => {
            return (''+state.data[a].lastName).localeCompare(state.data[b].lastName)
        })
}
*/

const mutations = {
purgeData(state){
    const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }
    },
     // ------------- mainStats ------------- \
    
     mainstatsRequest(state) {
        state.status={loading: true}
        state.error=null
    },
    mainstatsSuccess(state, data) {
        state.status={}
        state.stats=data
    },
    mainstatsFailure(state, error) {
        state.status={}
        state.error = error
    },

    //------ RECENTACTIVITY -------\
    recentActivityRequest(state, data) {
        state.internal={loading: true, creating: data}
        state.error=null
    },
    recentActivitySuccess(state, data){
        state.recentActivityList = data.recentActivity
        state.error=null
    },
    recentActivityFailure(state, error) {
        state.internal={}
        state.error=error
    },
    //------ RECENTUSERS -------\
    recentUsersRequest(state) {
        state.error=null
    },
    recentUsersSuccess(state, data){
        state.recentUserList = data.recentUsers
        state.error=null

    },
    recentUsersFailure(state, error) {
        state.internal={}
        state.error=error
    },
    
    //------------ update data -------- \\
    propertyUpdate(state, payload) {
        const metaFields = "delete"
        const {field, id, value} = payload

        if(metaFields.includes(field)){
            state.meta[id][field]=value
            // Manage special fields...
            //switch (field) {
            //}
        } else {
            state.data[id][field]=value
        }
    }

}
export const systemStore = {
    namespaced: true,
    state,
    actions,
    mutations
}