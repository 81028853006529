<template>
    <v-dialog v-if="!loading && !hidden && hidden !== ''" v-model="showFilters" width="80%" max-width="80%">
        <template v-slot:activator="{on,attrs}">
            <v-btn small elevation="2" icon v-on="on" v-bind="attrs" :style="{backgroundColor:filterColor}" >
                <v-icon>mdi-filter-variant</v-icon>
            </v-btn>
            <span style="font-size:small; padding-left:5px;margin-top:auto; margin-bottom:auto;">{{ filterNumbers }}</span>
            <slot :participantNumbers="{filterNumbers }"/>
        </template>
        <v-card>
            <v-card-title>
                <span class="text-h5">Filter Participants Viewed</span>
            </v-card-title>
            <v-card-text>
                <v-row class="no-boundaries">
                    <v-col >
                        {{ displayList.length }} trialists currently visible
                    </v-col>
                </v-row>
                <v-row class="no-boundaries" v-if=" selectedParticipant && (tryouts[selectedTryout].admin || tryouts[selectedTryout].owner)" style="margin-top:20px">
                    <v-col class="no-boundaries">
                        <v-checkbox dence hide-details v-model="bypassCurrent" :label="bypassLabel(selectedPlayer)"/>
                    </v-col>
                </v-row>
                <v-row class="no-boundaries">
                    <v-col class="no-boundaries">
                        <v-checkbox dense hide-details v-model="showBypassed" label="Show Bypassed Athletes" v-on:clicked="refreshList" />
                    </v-col>
                </v-row>
                <v-row class="no-boundaries">
                    <v-col class="no-boundaries">
                        <v-checkbox dense hide-details v-model="showDiscard" label="Show Athletes with 'No Offer'" v-on:clicked="refreshList" />
                    </v-col>
                </v-row>
                
                <v-row class="no-boundaries">
                    <v-col class="no-boundaries">
                        <v-checkbox dense hide-details v-model="showAbsent" label="Show Absent Athletes" v-on:clicked="refreshList" />
                    </v-col>
                </v-row>
                <v-row class="no-boundaries">
                    <v-col style="font-size:x-small" class="no-boundaries">
                        <v-checkbox style="font-size:small" dense hide-details v-model="showNames" label="Show names on buttons"/>
                    </v-col>
                </v-row>
                <v-row class="no-boundaries">
                    <v-col >
                        <!-- 
                        <v-switch small v-if="showNames" v-model="sortByFirstName" label="Sort by first name"/>
                        -->
                        <v-select dense v-model="sortByFirstName" item-text="text" item-value="value" :items="sortOptions" label="Sort buttons by"/>
                    </v-col>
                </v-row>
                <!--
                <v-row>
                    <v-select style="padding-left:10px" :items="sessionNameList" label="Session Filter" v-model="selectedSession" item-text="name" item-value="id"/>
                </v-row>
            -->
                <v-row class="no-boundaries">
                    <v-select style="padding-left:10px" :items="teamNameList" chips deletable-chips label="Team Filter" v-model="selectedTeams" item-text="name" item-value="id" multiple>
                        <!-- 
                        <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0">
                                <span>{{ item.name }}</span>
                            </v-chip>
                            <span v-if="index === 1" class="grey--text text-caption" >
                                (+{{ selectedTeams.length - 1 }} others)
                            </span>
                        </template>
                    -->
                        <template v-slot:prepend-item>
                            <v-list-item ripple @mousedown.prevent @click="toggleAllTeams" >
                                <v-list-item-action>
                                    <v-icon :color="selectedTeams.length > 0 ? 'indigo darken-4' : ''">
                                        {{ icon('team') }}
                                    </v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Select All
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item ripple @mousedown.prevent @click="showTeamUnassigned=1-showTeamUnassigned" >
                                <v-list-item-action>
                                    <v-icon>
                                        {{ showUnassignedIcon('team') }}
                                    </v-icon> 
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Not Assigned to a Team
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                        </template>
                    </v-select>
                </v-row>
                <v-row>
                    <v-select :items="roleNameList" label="Role Filter" chips deletable-chips v-model="selectedRoles" item-text="name" item-value="id" multiple>
                        <!-- 
                        <template v-slot:selection="{item, index}">
                            <v-chip v-if="index === 0">
                                <span>{{  item.name }}</span>
                            </v-chip>
                            <span 
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ selectedRoles.length -1 }} others)
                            </span>
                        </template>
                    -->
                        <template v-slot:prepend-item>
                            <v-list-item
                                ripple
                                @mousedown.prevent
                                @click="toggleAllRoles"
                            >
                                <v-list-item-action>
                                    <v-icon :color="selectedRoles.length>0 ? 'blue darken-4' : '' ">
                                        {{  icon('role') }}
                                    </v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Select All
                                    </v-list-item-title>
                                </v-list-item-content>

                            </v-list-item>
                            <v-list-item ripple @mousedown.prevent @click="showRoleUnassigned=1-showRoleUnassigned" >
                                <v-list-item-action>
                                    <v-icon>
                                        {{  showUnassignedIcon('role') }}
                                    </v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Not assigned to a Role
                                    </v-list-item-title>
                                </v-list-item-content>

                            </v-list-item>
                            <v-divider class="mt-2"/>
                        </template>
                    </v-select>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';


export default {

    props: {
        hidden: {
            type: Boolean,
            default: false,
        },
        disableSmartFilter :{
            type: Boolean,
            default: false,
        },
        defaultShowDiscard:{
            type: Boolean,
            default: false,
        }
    },
    components: {
    },
    computed: {
        ...mapState({
            nShowTeamUnassigned:             state => state.navigatorStore.showTeamUnassigned,
            nShowRoleUnassigned:             state => state.navigatorStore.showRoleUnassigned,
            allRolesSelected:               state => state.navigatorStore.allRolesSelected,
            nselectedSession:                state => state.navigatorStore.selectedSession,  
            nSelectedTeams:                state => state.navigatorStore.selectedTeams,
            nSelectedRoles:                state => state.navigatorStore.selectedRoles,
            nSortByFirstName:                state => state.navigatorStore.sortByFirstName,
            nShowNames:                state => state.navigatorStore.showNames,
            nShowBypassed:                   state => state.navigatorStore.showBypassed,
            nShowDiscard:                   state => state.navigatorStore.showDiscard,
            nShowAbsent:                       state => state.navigatorStore.showAbsent,
            navigatorInitializing:                state => state.navigatorStore.initializing,
            selectedParticipant:            state => state.navigatorStore.selectedParticipant,
            smartFilter:                    state => state.navigatorStore.smartFilter,

            attendancePerSession:           state => state.attendanceStore.perSessionLists,
            attendance:                     state => state.attendanceStore.data,
            attendanceStatus:               state => state.attendanceStore.status,

            roleRecommendations: state => state.teamrecommendationStore.recommendationsByRole,

            participantList:                state => state.participantStore.list,   
            participants:                   state => state.participantStore.data,
            participantStatus:              state => state.participantStore.status,
        
            tryouts:                        state => state.tryoutStore.data,
            selectedTryout:                 state => state.navigatorStore.selectedTryout,
            tryoutStatus:            state => state.tryoutStore.status,

            sessionList:            state => state.sessionStore.list,
            sessions:            state => state.sessionStore.data,
            sessionStatus:            state => state.sessionStore.status,

            playerskills: state => state.playerskillStore.data,

            teams:                          state => state.teamStore.data,
            teamList:                      state => state.teamStore.list,
            teamStatus:                    state => state.teamStore.status,

            roles:                          state => state.roleStore.data,
            roleList:                       state => state.roleStore.list,
            roleStatus:                    state => state.roleStore.status,
        }),
        loading(){
            return false
                || this.participantStatus.loading
                || this.tryoutStatus.loading
                || this.teamStatus.loading
                || this.roleStatus.loading
                || this.navigatorInitializing
                || this.sessionStatus.loading
                || this.attendanceStatus.loading
                || !this.selectedParticipant
        },
        filterColor: function() {
            if( this.showTeamUnassigned && this.allTeamsSelected && this.allRolesSelected && this.selectedSession === 0  && this.showBypassed) return ''
            return 'red'
        },
        filterNumbers: function(){
            if( this.showTeamUnassigned && this.allTeamsSelected && this.allRolesSelected && this.selectedSession === 0  && this.showBypassed) return ''
            if(this.participantList.length > 0 ) return `${this.displayList.length}/${this.participantList.length}`
            return `0`
        },
        bypassCurrent: {
            get() {
                if(!this.selectedPlayer || this.selectedPlayer === 0 || this.selectedPlayer === "null" || this.selectedPlayer==='undefined'){
                    return this.participants[this.displayList[0]].bypassCollection
                    //return false
                }
                if(!this.participants[this.selectedPlayer]) return false
                return this.participants[this.selectedPlayer].bypassCollection
            },
            set(value){
                this.updateParticipantProp({field: 'bypassCollection', id: this.selectedPlayer, value: value}) 
                this.refreshList()
            }
        },
        sortByFirstName: {
            get(){
                return this.nSortByFirstName
            },
            set(value){
                this.setSortByFirstName(value)
                this.refreshList()
            }
        },  
        showAbsent: {
            get(){
                return this.nShowAbsent

            },
            set(value){
                this.setShowAbsent(value)
                this.refreshList
            }
        },
        showNames: {
            get(){
                return this.nShowNames
            },
            set(value){
                this.setShowNames(value)
                this.refreshList()
            }
        },
        showTeamUnassigned: {
            get(){
                return this.nShowTeamUnassigned
            },
            set(value){
                this.setShowTeamUnassigned(value)
                this.refreshList()
            }
        },
        showRoleUnassigned: {
            get(){
                return this.nShowRoleUnassigned
            },
            set(value){
                this.setShowRoleUnassigned(value)
                this.refreshList()
            }
        },
        showBypassed: {
            get(){
                return this.nShowBypassed
            },
            set(value){
                this.setShowBypassed(value)
                this.refreshList()
            }
        },
        showDiscard: {
            get(){
                return this.nShowDiscard
            },
            set(value){
                this.setShowDiscard(value)
                this.refreshList()
            }

        },
        selectedTeams:{
            get(){
                return this.nSelectedTeams
            },
            set(value){
               this.setSelectedTeams(value) 
            }
        },
        selectedRoles:{
            get(){
                return this.nSelectedRoles
            },
            set(value){
                this.setSelectedRoles(value)
            }
        },
        selectedPlayer: {
            get(){
                return this.selectedParticipant
            },
            set(value){
                this.setSelectedParticipant(value)
            }
        },
        sessionNameList: function() {
            const results=[{id: 0, name: 'All Sessions'}]
            for(var i=0;i<this.sessionList.length;i++){
                results.push({
                    id: this.sessionList[i],
                    name: this.sessions[this.sessionList[i]].title
                })
            }
            return results
        },
        teamNameList: function() {
            const results=[]
            for(var i=0;i<this.teamList.length;i++){
                if(this.teams[this.teamList[i]].selected){
                    results.push({
                        id: this.teamList[i],
                        name: this.teams[this.teamList[i]].name
                    })
                }
            }
            return results
        },
        roleNameList: function() {
            const results=[]
            for(var i=0;i<this.roleList.length;i++){
                results.push({
                    id: this.roleList[i],
                    name: this.roles[this.roleList[i]].name
                })
            }
            return results
        },
        selectedSession: {
            get(){
                return this.nselectedSession
            },
            set(value){
                this.setSelectedSession(value)
            }
        },
        allTeamsSelected(){
            return this.selectedTeams.length === this.teamNameList.length
        },
        allRolesSelected(){
            return this.selectedRoles.length === this.roleNameList.length
        },
    },
    watch: {
        nShowBypassed: function(){
        },
        selectedPlayer: function(){
            if(!this.selectedPlayer || this.selectedPlayer === 0) return
            this.bypassCurrent = this.participants[this.selectedPlayer].bypassCollection
        },
        selectedParticipant(){
        },      
        showBypassed: function(){
            this.refreshList()
        },
        showDiscard(){
            this.refreshList()
        },
        smartFilter: function (){
            this.refreshList()
        },
        showTeamUnassigned: function(){
            this.refreshList()
        },
        selectedSession: function(){
            this.refreshList()
        },
        selectedTeams: function(){
            this.refreshList()
        },
        selectedRoles: function(){
            this.refreshList()
        },
        sortByFirstName: function(){
            this.refreshList()
        },  
        showAbsent: function(){
            this.refreshList()
        },
        nShowNames(){
            if(this.nShowNames){
                this.sortOptions=[{text:'Numbers', value:0}, 
                                  {text:'First Name',value:1},
                                  {text:'Last Name', value:2}]
                this.sortByFirstName=1
            } else {
                this.sortOptions=[{text:'Numbers', value:0}]
                this.sortByFirstName=0
            }
            this.refreshList()
        }
    },
    methods: {
        ...mapActions('participantStore', {
            updateParticipantProp: 'propertyUpdate',
            setDisplayList: 'setDisplayList',
        }),
        ...mapActions('navigatorStore', [
            'setShowBypassed',
            'setShowDiscard',
            'setSelectedTeams',
            'setSelectedRoles',
            'setSelectedParticipant',
            'setShowNames',
            'setSelectedSession',
            'setShowTeamUnassigned',
            'setShowRoleUnassigned',
            'setSortByFirstName',
            'setShowAbsent',
            'setObjectiveRemoved',
        ]),
        bypassLabel(selectedParticipant){
            return `Bypass data collection for ${this.firstName(selectedParticipant)} ${this.lastName(selectedParticipant)}`
        },
        firstName: function(playerId){
            if(playerId && playerId !== 0 && this.participants[playerId]) {
                return this.participants[playerId].firstName
            } else {
                return ""
            }
        },
        lastName: function(playerId){
            if(playerId && playerId !== 0 && this.participants[playerId]) {
                return this.participants[playerId].lastName
            } else {
                return ""
            }
        },
        toggleAllTeams() {
            this.$nextTick(()=> {
                if(this.allTeamsSelected) {
                    this.selectedTeams=[]
                    this.showTeamUnassigned=0
                    this.refreshList()
                } else {
                    this.showTeamUnassigned=1
                    this.selectedTeams = this.teamNameList.map(a => a.id)
                    this.refreshList()
                }
            })
        },
        toggleAllRoles() {
            this.$nextTick(()=> {
                if(this.allRolesSelected) {
                    this.selectedRoles=[]
                    this.showRoleUnassigned=0
                    this.refreshList()
                } else {
                    this.showRoleUnassigned=1
                    this.selectedRoles = this.roleNameList.map(a => a.id)
                    this.refreshList()
                }
            })
        },
        icon (filter) {
            if (filter === 'team' && this.allTeamsSelected) return 'mdi-close-box'
            if (filter === 'role' && this.allRolesSelected) return 'mdi-close-box'
            return 'mdi-checkbox-blank-outline'
        },
        showUnassignedIcon(filter){
            if(filter === 'team' && this.showTeamUnassigned) return 'mdi-close-box'
            if(filter === 'role' && this.showRoleUnassigned) return 'mdi-close-box'
            return 'mdi-checkbox-blank-outline'
        },
        async refreshList(){
            // Refilter the available list
                function onlyUnique(value, index, array) {
                    return array.indexOf(value) === index;
                }

            var newList = [...this.participantList]

            // Sort the list..

            switch (this.sortByFirstName){
                case 0: 
                    newList.sort((a,b) => { return this.participants[a].participantNumber - this.participants[b].participantNumber })
                    break;
                case 1: 
                    newList.sort((a,b) => { return this.participants[a].firstName.localeCompare(this.participants[b].firstName) })
                    break;
                case 2: 
                    newList.sort((a,b) => { return this.participants[a].lastName.localeCompare(this.participants[b].lastName) })
                    break;
            }

            // Remove players who have withdrawn

            newList = newList.filter((playerId) => !this.participants[playerId].archived )

            // Remove players who are "Discard" Offers

            if(!this.showDiscard) newList = newList.filter((playerId) => this.participants[playerId].offer!=='Discard')

            // Remove bypassed players if necessary

            if(!this.showBypassed) newList = newList.filter((playerId) => !this.participants[playerId].bypassCollection )

            // Remove players from teams who are not selected

            if(!this.allTeamsSelected) newList = newList.filter((playerId) => (this.selectedTeams.includes(this.participants[playerId].offer) || (this.showTeamUnassigned && !this.participants[playerId].offer) ) )

            // Remove players whose roles are not selected

            var roleSumList =[]
                for(var ridx in this.roleList){

                    if(Object.prototype.hasOwnProperty.call(this.roleRecommendations,this.roleList[ridx]) )
                        if (this.selectedRoles.includes(this.roleList[ridx])) {

                            roleSumList.push(...this.roleRecommendations[this.roleList[ridx]])
                        }
                }
                roleSumList.filter(onlyUnique)

//            if(!this.allRolesSelected) newList = newList.filter((playerId) => roleSumList.includes(playerId) || (this.showRoleUnassigned && !roleSumList.includes(playerId) )) 

            if(!this.allRolesSelected) newList = newList.filter((playerId) => this.selectedRoles.includes(this.participants[playerId].prefRole1 || this.selectedRoles.includes(this.participants[playerId].prefRole2))
                || (roleSumList.includes(playerId) || (this.showRoleUnassigned && !roleSumList.includes(playerId) )) 
            )

            newList.filter(onlyUnique)

            // Remove players who are not in the selected session

            if(this.selectedSession !== 0  && this.selectedSession !== "0" && !this.showAbsent) {
                newList = newList.filter((playerId) => (this.attendance[this.attendancePerSession[this.selectedSession][playerId]].attended>0) && (!(this.participants[playerId].archived || (this.showBypassed?false:this.participants[playerId].bypassCollection))) )
            }


            // If we're doing smart filtering, remove players who are >50% complete

            /*
            if(this.smartFilter && !this.disableSmartFilter){
                newList=newList.filter((playerId) => (this.playerskills[playerId].complete<50))
            }
            */
        
            // Smart filtering - show those athletes who are in the bottom third with respect to completeness...

            if(this.smartFilter && !this.disableSmartFilter){
                // First see if threshold filtering removes too many athletes...
                const threshold=50
                const origListSize = newList.length
                const thresholdList=newList.filter((playerId) => this.playerskills[playerId].complete<threshold)
                if(thresholdList.length > (1/3*origListSize)){
                    // K just make this the list for now...
                    newList = thresholdList
                } else {
                    var sortedList = newList.toSorted((a,b) => this.playerskills[a].complete - this.playerskills[b].complete)
                    const bucketSize = Math.floor(sortedList.length*2/3)
                    const targetList = sortedList.slice(0,bucketSize)
                    newList=newList.filter((playerId) => targetList.includes(playerId))
                }

                this.setObjectiveRemoved(origListSize - newList.length)
            }


            this.displayList = newList

            this.setDisplayList(newList)

            const attendanceRecordId = (this.selectedSession && this.selectedSession !== '0')?this.attendancePerSession[this.selectedSession][this.selectedPlayer]:0
            if(!this.selectedParticipant || this.selectedParticipant==="null") this.setSelectedParticipant(newList[0])

            if(!this.displayList.includes(this.selectedPlayer)){
                if(this.selectedSession && this.selectedPlayer && this.attendance[attendanceRecordId] && !this.attendance[attendanceRecordId].attended) {
                    this.setSelectedParticipant(this.displayList[0])
                }
            } 
           // Emit an event to anyone listening to tell them the list may have changed

           this.$root.$emit('displayListChanged')
        }
    },
    mounted() {
        this.$socket.emit('join-room', `capture-${this.selectedTryout}`, localStorage.getItem('jwt'))
    },
    socket:{
        async 'UpdatePlayerStats'(){
            this.refreshList()
           // this.setSelectedParticipant(this.displayList[0])
        },
    },
    created() {
//        this.setSelectedParticipant(this.initialParticipant)
        this.setSelectedTeams(this.teamList)

        this.setSelectedTeams(this.teamNameList.map(a => a.id))
        this.setSelectedRoles(this.roleNameList.map(a => a.id))
        if(this.defaultShowDiscard) this.setShowDiscard(true)


        this.refreshList()
    },
    data() {
        return {
            displayList: [],
            showFilters: 0,
            sortOptions: [{text:'Number', value:0}],
            selectedSort:0,
        }
    }

}</script>

<style scoped>

</style>