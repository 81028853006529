<template>
    <div class=date>
        <v-menu
            v-model="showMenu"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            class="date-menu"
        >
            <template v-slot:activator="{ on, attrs }">
            <v-text-field
                :value="formatDate(content)"
                :label="title"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="rules"
            ></v-text-field>
            </template>
            <v-date-picker
            v-model="content"
            @input="handleInput"
            ></v-date-picker>
        </v-menu> 
    </div>
</template>


<script>

import moment from 'moment'

export default{
    props: [
        'title',
        'rules',
        'value'
    ],
    components: {},
    computed: {
    },

    methods: {
        formatDate: function(d) {
            return d
                ? moment(d).format("DD/MM/YYYY")
                : "";


        },
        formatDateTime: function(d) {
            return d
                ? moment(d).format('llll')
                : ""
        },
        handleInput(e){
            this.event=e
            this.showMenu=false
            this.$emit('input', this.content)
        }
    },
    created(){
    },
    data(){
        return {
            event: null,
            showMenu: false,
            content: moment(this.value).format('YYYY-MM-DD')
        }
    }
}

</script>


<style>

</style>