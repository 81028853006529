<template>
    <v-row style="margin:0;padding:0">
        <v-dialog v-model="showWarning" max-width="60%">
            <v-card>
                <v-card-title>Confirm Team Unlink</v-card-title>
                <v-card-text>
                    There are currently players assigned to this team. Unlinking it will return them to the "Waitlist" pool.<br/>
                    You cannot undo this operation. Are you sure you want to continue?
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn text @click="cancelRemove">Close</v-btn>
                    <v-btn color="red darken-1" text @click="confirmRemove">Delete</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-col cols="12" style="margin:0;padding:0">
            <v-checkbox dense v-model="teamSelected" :label="teamLabel" style="margin:0px;padding:0px;">
                <template v-slot:label>
                    <v-row style="margin:0px;padding:0px;">
                        <v-col cols="1" style="margin:0px;padding:0px;">
                            <v-dialog v-if="teamSelected" class="color-dialog">
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn 
                                        v-on="on" 
                                        v-bind="attrs" 
                                        x-small 
                                        icon>
                                        <v-icon :style="iconColor">mdi-circle</v-icon>
                                    </v-btn>
                                </template>
                                <v-card style="width:300px">
                                    <v-color-picker v-model="color" style="width:300px"/>
                                </v-card>
                            </v-dialog>

                        </v-col>
                        <v-col style="margin:0px;padding:0px;">
                            {{ teamLabel }} 
                            <span v-if="participantList.filter(filterByOffer).length>0" style="font-size:small;color:red">
                                ({{ participantList.filter(filterByOffer).length}} athletes assigned  )
                            
                            </span>
                        </v-col>
                    </v-row>
                </template>
            </v-checkbox>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex';


export default {
    props: ['teamId'],
    computed: {
        ...mapState({
            teams: state => state.teamStore.data,

            roles: state => state.roleStore.data,
            roleList: state => state.roleStore.list,

            participants: state => state.participantStore.data,
            participantList: state => state.participantStore.list,

            selectedTryout: state => state.navigatorStore.selectedTryout,
        }),
        teamSelected: {
            get() {
                return this.teams[this.teamId].selected

            },
            set(newValue){
                this.updateTeamProperty({field: 'selected', id: this.teamId, value: newValue})           
                //this.$root.$emit("settings-changes-made-teamlinks")
                if(!newValue && this.participantList.filter(this.filterByOffer).length >0){
                        this.$root.$emit("settings-changes-made-teamlinks-destructive", this.teamId)
                        return 
                } else {
                    this.$root.$emit("settings-changes-made-teamlinks-nondestructive", this.teamId)
                }
            }
        },
        teamLabel: {
            get() {
                return this.teams[this.teamId].name
            }
        },
        iconColor: {
            get() {
                return `color: ${this.teams[this.teamId].color}` 
            },
        },
        color: {
            get(){
                return this.teams[this.teamId].color
            },
            set(value){
                this.updateTeamProperty({field: 'color', id: this.teamId, value: value})
                this.$root.$emit("settings-changes-made-teamlinks")
            }
        }
    },
    methods: {
        ...mapActions('teamStore', {
            updateTeamProperty: 'propertyUpdate',
            updateTeam: 'update'
        }),
        ...mapActions('participantStore',{
            findAllParticipantsForEvent: 'findAllForEvent',

        }),
        cancelRemove(){},
        async confirmRemove(){
            if(this.participants.filter(this.filterByOffer).length >0){
                this.destructive=true;
                this.showWarning=true;
                this.$root.$emit("settings-changes-made-teamlinks-destructive", this.teamId)
            } else {
                this.$root.$emit("settings-changes-made-teamlinks-nondestructive", this.teamId)
            }

        },
        roleName(id){
            return `Target number for role: ${this.roles[id].name}`
        },
        filterByOffer(item){
            if(this.participants[item].offer === this.teamId){
                return true
            }
            return false
        }
    },
    async created(){
       // await this.findAllParticipantsForEvent(this.selectedTryout)
    },
    data(){
        return {
            number: 0,
            showWarning: false,
            destructive: false,
        }
    }
}</script>

<style>
.v-dialog{
    width:300px;
}

</style>