import { Stack } from '@/_helpers/stack'

function initialiseState(){
    console.log('Initing navigator state')

    var  tmp = localStorage.getItem('csregister')
    const csRegister = tmp && tmp!=='null'? tmp : null

    tmp = localStorage.getItem('paneStack')
    var paneStack=[]
    try{
        paneStack=JSON.parse(tmp)
    } catch(e){
        paneStack=[]
    }
    console.log(paneStack)
    
    tmp = localStorage.getItem('selectedSeason')
    const selectedSeason = tmp && tmp!=='null'? tmp : null
   
    tmp = localStorage.getItem('selectedClubPane')
    const selectedClubPane = tmp && tmp!=='null'? tmp : null
    
    tmp= localStorage.getItem('selectedClub')
    const selectedClub= tmp && tmp!=='null'? tmp : null
   
    tmp= localStorage.getItem('selectedSession')
    const selectedSession= tmp && tmp!=='null'? tmp : null

    tmp= localStorage.getItem('selectedTryout')
    const selectedTryout= tmp && tmp!=='null'? tmp : null
    
    tmp = localStorage.getItem('selectedNav')
    const selectedNav = tmp && tmp!=='null'? tmp : null
    
    tmp = localStorage.getItem('selectedEpisode')
    const selectedEpisode = tmp && tmp!=='null'? tmp : null
    
    
    tmp = localStorage.getItem('bankState')
    const bankState = tmp && tmp!=='null'? tmp : null
    
    tmp = localStorage.getItem('selectedParticipant')
    const selectedParticipant = tmp && tmp!=='null'? tmp : null

    return {
        navMenu: 'root',
        menuHistory: new Stack (),
        paneHistory: new Stack(paneStack),
        showBurgerMenu: false,
        selectedClub: selectedClub? selectedClub : "",
        selectedSeason: selectedSeason? selectedSeason: "",
        selectedClubPane: selectedClubPane? selectedClubPane : "Home",
        selectedTryout: selectedTryout? selectedTryout : "",
        selectedEpisode: selectedEpisode? selectedEpisode : "",
        selectedNav: selectedNav? selectedNav : "Club",
        initialising: true,
        bankState: bankState?bankState:'noAccount',
        allTeamsSelected: 1,
        allRolesSelected: 1,

        // Filter settings
        showNames: false,
        selectedSession: selectedSession? selectedSession:0,
        selectedTeams: [],
        selectedRoles: [],
        showTeamUnassigned:1,
        showRoleUnassigned: 1,
        showBypassed: 0,
        showDiscard: 0,
        smartFilter: 0,
        selectedParticipant: selectedParticipant? selectedParticipant: 0,
        sortByFirstName: 0,
        showAbsent: 0,
        objectiveRemoved: 0,

        csRegister: csRegister? csRegister:null,


        networkStatus: true,

        displayList: []
    }
}

const state = initialiseState()

const actions = {
    purgeData({commit}){
        commit('purgeData')
    },
    navTo({commit}, to){
        commit('navTo', to)
    },
    navBack({commit}) {
        commit('navBack')
    },
    toggleMenu({commit}) {
        commit('toggleMenu')
    },
    selectClubNoPurge({commit }, clubId) {
        commit('selectClub', clubId)
    },
    selectClub({commit, dispatch}, clubId) {
        dispatch('purgeData', null, {root:true})
        commit('selectClub', clubId)
    },
    justSelectClub({commit},clubId){
        commit('justSelectClub', clubId)
    },
    selectSeason({commit}, seasonId) {
        commit('selectSeason', seasonId)
    },
    selectPane({commit}, pane) {
        commit('selectClubPane', pane)
    },
    lastPane({commit}){
        commit('lastPane')
    },
    clearPaneHistory({commit}){
        commit('clearPaneHistory')
    },
    selectClubPane({commit}, pane) {
        commit('selectClubPane', pane)
    },
    selectTryout({commit}, id) {
        commit('selectTryout', id)
    },
    setSelectedEpisode({commit}, id){
        commit('selectEpisode', id)
    },
    selectEpisode({commit}, id){
        commit('selectEpisode', id)
    },
    selectNav({commit}, navName){
        commit('selectNav', navName)
    },
    setRedirect({commit}, payload){
        commit('setRedirect', payload)
    },
    selectBankState({commit}, state){
        commit('selectBankState', state)
    },
    initialiseNavigator({commit}){
        commit('initialiseNavigator')
    },
    setShowNames({commit}, showNames){
        commit('setShowNames', showNames)
    },
    setSelectedSession({commit}, selectedSession){
        commit('setSelectedSession', selectedSession)
    },
    setShowBypassed({commit}, showBypassed){
        commit('setShowBypassed', showBypassed)
    },
    setShowDiscard({commit}, showDiscard){
        commit('setShowDiscard', showDiscard)
    },
    setAllTeamsSelected({commit}, allTeamsSelected){    
        commit('setAllTeamsSelected', allTeamsSelected)
    }  ,
    setAllRolesSelected({commit}, allRolesSelected){
        commit('setAllRolesSelected', allRolesSelected)
    },
    setSelectedTeams({commit}, selectedTeams){
        commit('setSelectedTeams', selectedTeams)
    }   ,
    setSelectedRoles({commit}, selectedRoles){
        commit('setSelectedRoles', selectedRoles)
    },
    setSelectedParticipant({commit}, selectedParticipant){  
        commit('setSelectedParticipant', selectedParticipant)
    },
    setShowTeamUnassigned({commit}, showTeamUnassigned){
        commit('setShowTeamUnassigned', showTeamUnassigned)
    },
    setShowRoleUnassigned({commit}, showRoleUnassigned){
        commit('setShowRoleUnassigned', showRoleUnassigned)
    },
    setSortByFirstName({commit}, sortByFirstName){
        commit('setSortByFirstName', sortByFirstName)
    },
    setShowAbsent({commit}, showAbsent){
        commit('setShowAbsent', showAbsent)
    },
    setNetworkStatus({commit}, networkStatus){
        commit('setNetworkStatus', networkStatus)
    },
    setSmartFilter({commit}, smartFilter){
        commit('setSmartFilter', smartFilter)
    },
    setObjectiveRemoved({commit}, objectiveRemoved){
        commit('setObjectiveRemoved', objectiveRemoved)
    }





    

}

const mutations = {
purgeData(state){
    const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }
    },
    navTo(state, to) {
            state.menuHistory.push(state.navMenu)
            state.navMenu = to
    },
    navBack(state) {
        state.navMenu = state.menuHistory.pop()
    },
    toggleMenu(state) {
        state.showBurgerMenu = !state.showBurgerMenu
    },

    setObjectiveRemoved(state, newValue){
        state.objectiveRemoved = newValue
    },

    setRedirect(state, payload) {
        state.csRegister=payload
        localStorage.setItem('csregister', payload)
    },
    justSelectClub(state, clubId){
        state.selectedClub=clubId
        localStorage.setItem('selectedClub', clubId)
    },
    selectClub(state, clubId){
        state.selectedClub = clubId
        state.selectedSession=0
        state.selectedParticipant=0
        state.selectedSeason=''
        state.selectedTryout=''
        state.selectedClubPane=''
        state.selectedNav='Club'
        localStorage.setItem('selectedParticipant', '')
        localStorage.setItem('selectedSeason', '')
        localStorage.setItem('selectedSession', '')
        localStorage.setItem('selectedTryout', '')
        localStorage.setItem('selectedClubPane', '')
        localStorage.setItem('selectedNav', 'Club')
        localStorage.setItem('selectedClub', clubId)
            },
    selectSeason(state, seasonId){
        localStorage.setItem('selectedSeason', seasonId)
        state.selectedSeason = seasonId
    },
    selectClubPane(state, pane){
        state.paneHistory.push(state.selectedClubPane)
        state.selectedClubPane = pane
        localStorage.setItem('selectedClubPane', pane)
        localStorage.setItem('paneStack', JSON.stringify(state.paneHistory.contents()))
    },
    lastPane(state){
        state.selectedClubPane = state.paneHistory.pop()
        localStorage.setItem('selectedClubPane', state.selectedClubPane)
        localStorage.setItem('paneStack', JSON.stringify(state.paneHistory.contents()))
    },
    clearPaneHistory(){
        state.paneHistory.empty()
        localStorage.setItem('paneStack', JSON.stringify([]))
    },
    selectTryout(state, id) {
        // Has the tryout been changed?
        const lastTryout = state.selectedTryout
        if(lastTryout !== id){
            state.selectedTryout = id
            localStorage.setItem('selectedTryout', id)
            state.selectedSession = 0
            state.selectedParticipant=0
            localStorage.setItem('selectedSession', 0)
            localStorage.setItem('selectedParticipant', 0)

        }
    },
    selectEpisode(state, id){
            state.selectedEpisode = id
            localStorage.setItem('selectedEpisode', id)
    },
    selectNav(state, navName){
        state.selectedNav = navName
        localStorage.setItem('selectedNav',navName)
    },
    selectBankState(state, bankState ){
        state.bankState = bankState
        localStorage.setItem('bankState', bankState)
    },
    setShowNames(state, showNames){
        state.showNames = showNames
    },
    setSelectedSession(state, selectedSession){
        localStorage.setItem('selectedSession', selectedSession)
        state.selectedSession = selectedSession
    },
    setShowBypassed(state, showBypassed){   
        state.showBypassed = showBypassed
    },
    setShowDiscard(state, showDiscard){   
        state.showDiscard = showDiscard
    },
    setAllRolesSelected(state, allRolesSelected){
        state.allRolesSelected = allRolesSelected
    },
    setAllTeamsSelected(state, allTeamsSelected){
        state.allTeamsSelected = allTeamsSelected
    },
    setSelectedTeams(state, selectedTeams){
        state.selectedTeams = selectedTeams
    },
    setSelectedRoles(state, selectedRoles){
        state.selectedRoles = selectedRoles
    },
    setSelectedParticipant(state, selectedParticipant){
        if(selectedParticipant==='null') selectedParticipant=null
        localStorage.setItem('selectedParticipant', selectedParticipant)
        state.selectedParticipant = selectedParticipant
    },
    setShowTeamUnassigned(state, showTeamUnassigned){
        state.showTeamUnassigned = showTeamUnassigned
    },
    setShowRoleUnassigned(state, showRoleUnassigned){
        state.showRoleUnassigned = showRoleUnassigned
    },
    setSortByFirstName(state, sortByFirstName){
        state.sortByFirstName = sortByFirstName
    },
    setShowAbsent(state, showAbsent){
        state.showAbsent = showAbsent
    },
    setNetworkStatus(state, networkStatus){
        state.networkStatus = networkStatus
        localStorage.setItem('networkStatus', networkStatus)
    },
    setSmartFilter(state, smartFilter){
        state.smartFilter = smartFilter
    },

    async initialiseNavigator(state){
        state.initialising=true
        state.csRegister=null
        state.selectedSeason=null
        state.selectedClub=null
        state.selectedClubPane=null
        state.selectedTryout=null
        state.selectedEpisode=null
        state.selectedNav=null
        state.bankState=null
        state.selectedParticipant=null

        const csRegister = localStorage.getItem('csregister')
        if(csRegister) {
            state.csRegister = csRegister
        }
        const selectedSeason = localStorage.getItem('selectedSeason')
        if(selectedSeason) {
            state.selectedSeason = selectedSeason
        }

        const selectedSession = localStorage.getItem('selectedSession')
        if(selectedSession) {
            state.selectedSession = selectedSession
        }

        const selectedClubPane = localStorage.getItem('selectedClubPane')
        if(selectedClubPane) {
            state.selectedClubPane = selectedClubPane
        }

        const selectedClub= localStorage.getItem('selectedClub')
        if(selectedClub) {
            state.selectedClub= selectedClub
        }

        const selectedTryout= localStorage.getItem('selectedTryout')
        if(selectedTryout) {
            state.selectedTryout= selectedTryout
        }
        
        const selectedEpisode= localStorage.getItem('selectedEpisode')
        if(selectedEpisode) {
            state.selectedEpisode= selectedEpisode
        }
        
        const selectedNav = localStorage.getItem('selectedNav')
        if(selectedNav) {
            state.selectedNav = selectedNav
        }
        
        const bankState = localStorage.getItem('bankState')
        if(bankState) {
            state.bankState = bankState
        }
        const selectedParticipant = localStorage.getItem('selectedParticipant')
        if(selectedParticipant || selectedParticipant !== 'null'){
            state.selectedParticipant = selectedParticipant
        }

        // Reset the filter settings
        state.showNames=false
        state.selectedTeams=[]
        state.selectedRoles=[]
        state.showTeamUnassigned=1
        state.showRoleUnassigned=1
        state.showBypassed=0
        state.showDiscard=0
        state.smartFilter=0

        state.displayList=[]

        state.initialising=false
    }
}


export const navigatorStore = {
    namespaced: true,
    state,
    actions,
    mutations,
}