<template>
    <div>
            Payment FAiled
        </div>
    
</template>
<script>
    
    export default{
        props: [],
        components: {},
        computed: {},
        methods: {},
        data(){
            return {
    
            }
        }
    }
    
</script>
    