<template>
    <div>
        <div class="container" style="padding:0px;margin:0px;">
            <v-row style="margin: 0px;padding:0px;">
                <v-col style="margin:0px;padding:0px;">
                    <v-parallax style="width:100%;" src="https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/app_assets/coachonphone.jpg" >
                        <v-row v-if="loginType==='normal'">
                            <v-col xl="4" lg="4" md="4" sm="12" xs="12" class="login-pane">
                                <ValidationObserver ref="form" v-slot="{handleSubmit}">
                                    <form @submit.prevent="handleSubmit(submit)">
                                        <v-card class="mx-auto cs-login-box" :loading="status.loggingIn" outlined shaped elevation= "5" >
                                            <v-card-text>
                                            <h3>Sign-in to access CoachSight</h3>
                                                <input type="hidden" id="g-recaptcha-response" name="g-recaptcha-response">
                                                <input type="hidden" name="action" value="validate_captcha">

                                                <ValidationProvider name="Email" rules="required" v-slot="{errors}">
                                                    <v-text-field label="Email" hide-details="auto" v-model="username"/>
                                                    <div class="form-error">{{ errors[0] }}</div>
                                                </ValidationProvider>
                                                <ValidationProvider name="Password" rules="required" v-slot="{errors}">
                                                    <v-text-field label="Password" type="password" hide-details="auto" v-model="password"/>
                                                    <div class="form-error">{{ errors[0] }}</div>
                                                </ValidationProvider>
                                            
                                                <div class="form-group-btn">
                                                    <v-btn 
                                                        class="login-btn" 
                                                        type="submit" 
                                                        color="primary" 
                                                        :disabled="status.loggingIn"
                                                    >
                                                        Login
                                                    </v-btn>
                                                    <img v-show="status.loggingIn" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />

                                                </div>
                                                <div>
                                                    Don't have an account yet? <router-link to="/register" class="btn btn-link">Register now</router-link>
                                                </div>
                                                <v-divider></v-divider>
                                                <div class="pt-6">
                                                    Forgotten your Password? <router-link to="/recoverpassword" class="btn btn-link">Reset it here</router-link>
                                                </div>
                                        </v-card-text>

                                    </v-card>
                                    </form>
                                </ValidationObserver>

                            </v-col>
                            <v-col xl="8" lg="8" md="8" sm="12" xs="12" class="info-col hidden-sm-and-down">
                                <v-carousel interval="4000" v-model="page" cycle hide-delimiters :show-arrows="false">
                                    <v-carousel-item v-for="(item,i) in infopanels" :key="i">
                                        <v-row class="fill-height info-pane" align="center" justify="center" >
                                                <div class="info-heading2">
                                                    {{item.headline}}
                                                </div>
                                        </v-row>
                                    </v-carousel-item>
                                </v-carousel>
                            </v-col>
                        </v-row>
                        <v-row v-else-if="loginType==='session'">
                        </v-row>
                    </v-parallax>
                </v-col>
            </v-row>
        </div>
        <div>
            <v-row>
                <v-col>
                    <div class="reasons-heading">
                        {{ reasons.length }} great reasons to get started with CoachSight today...
                    </div>
                </v-col> 
            </v-row>
            <v-row>
                <v-carousel hide-delimiters height="200px" cycle :interval="10000">
                    <template v-for="(item, index) in reasons"> 
                        <v-carousel-item v-if="(index + 1) % columns === 1 || columns === 1" :key="index" > 
                            <v-row class="flex-nowrap" style="height:100%"> 
                                <template v-for="(n,i) in columns"> 
                                    <template v-if="(+index + i) < reasons.length"> 
                                        <v-col :key="i"> 
                                            <v-sheet v-if="(+index + i) < reasons.length" height="100%" >
                                                <v-row class="fill-height" align="center" justify="center" >
                                                    <div class="info-block-icon">
                                                        <img height="70px" contain :src="reasons[+index+i].icon"/>
                                                    </div>
                                                    <div class="info-block-text">{{ reasons[+index+i].text }}</div>
                                                </v-row>
                                            </v-sheet>
                                        </v-col> 
                                    </template> 
                                </template> 
                            </v-row> 
                        </v-carousel-item> 
                    </template> 
                </v-carousel>  
            </v-row>
        </div>
        <div class="purple-section">
            <v-row style="display:flex;flex-flow:column;">
                <div class="purple-section-heading">
                    Get started right now for free...
                </div>
                <div :class="responsiveClass('buying-frame')">
                    <div :class="responsiveClass('buying-box')">
                        <div class="buying-title">
                            <div class="buying-num-circle">
                                <div class="buying-num">1</div>
                            </div>

                            <div class="purple-section-info">
                                Your club gets 25 free registrations when you sign up. 
                            </div>

                        </div>
                        <div class="purple-section-subinfo">
                            That's enough to run a small selection session and get to know how coachsight can make your selection trials better.
                        </div>
                    </div>
                    <div :class="responsiveClass('buying-box')">
                        <div class="buying-title">
                            <div class="buying-num-circle">
                                <div class="buying-num">2</div>
                            </div>
                            <div class="purple-section-info">
                                After that, registration packs are available for purchase as you need them. 
                            </div>
                        </div>
                        <div class="purple-section-subinfo">
                            Any left-over registrations can be saved and used in future trials, including those you buy at a discount. Buy now to lock-in those discount savings
                        </div>
                    </div>

                </div>
            </v-row>

        </div>
        <div >
            <div class="white-section-heading">
                Package Pricing
            </div>
            <v-row >
                <v-col class="price-section" >
                    <RegistrationPacks />
                </v-col>   
            </v-row>
        </div>
        <div style="height:100px;">
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import RegistrationPacks from '../components/RegistrationPacks.vue';

export default {
    components:{
        ValidationObserver,
        ValidationProvider,
        RegistrationPacks,
    },
    computed: {
        ...mapState('account', ['status']),
        ...mapState({
            participants:           state => state.participantStore.data,
            participantList:        state => state.participantStore.list,
            participantsByLastName: state=> state.participantStore.listByLastName,
            participantsByFirstName: state=> state.participantStore.listByFirstName,
            participantStatus:      state => state.participantStore.status,
            displayList:            state => state.participantStore.displayList,
        }),
        columns() {
            if (this.$vuetify.breakpoint.xl) {
                return 4;
            }

            if (this.$vuetify.breakpoint.lg) {
                return 3;
            }

            if (this.$vuetify.breakpoint.md) {
                return 2;
            }

            return 1;
        }
    },
    async created () {
        const redirect = sessionStorage.getItem('redirectPath')
        const verified = sessionStorage.getItem('verified')
        if(verified) {
            this.$store.dispatch('alert/success', 'Your account is verified. Please login now')
            sessionStorage.removeItem('verified')
        } else if(redirect) {
            this.$store.dispatch('alert/info', 'To register for your event please login or create a new account')
        }

    },
    methods: {
        ...mapActions('account', ['login', 'logout']),
        // eslint-disable-next-line
        async submit () {
            this.submitted = true;
            await this.login({username: this.username, password: this.password, socket: this.$socket});


            
        },
        ...mapActions('participantStore', {
            findAllParticipantsForEvent: 'findAllForEvent',
            quietFindAllParticipantsForEvent: 'quietFindAllForEvent',
            updateParticipantProp: 'propertyUpdate',
            updateParticipant: 'update',
        }),
        responsiveClass(className){
            if(this.$vuetify.breakpoint.smAndDown){
                return className+' mobile'
            }
            return className
        },
    },
    data () {
        return {
            sessionId: null,
            eventId: null,
            loginType: 'normal',
            page:null,
            username: '',
            password: '',
            submitted: false,
            rules: {
                required:   value => !!value || 'Required',
                email:      value => !value || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'Not a valid email address' 
            },
            infopanels: [
                {
                    headline:"Any Sport",
                    text:"Configure CoachSight for any selection or interview process",
                    subtext: "",
                    anchor:"",
                },
                {
                    headline:"Selection trials made easy",
                    text:"Run your tryouts with fewer staff and less effort...",
                    subtext: "",
                    anchor:"",
                },
                {
                    headline:"Provide quality feedback to athletes ",
                    text:"Help your athletes feel seen and cared for...",
                    subtext: "",
                    anchor:"",
                },
                {
                    headline:"Real-time status updates",
                    text:"Know who you need more observations for in real time, not after the trial has finished...",
                    subtext: "",
                    anchor:"",
                },
                {
                    headline:"Tryout workflow management",
                    text:"Automate your sign-up, tryout-day registration and offer processes",
                    subtext: "",
                    anchor:"",
                },
                {
                    headline:"Cashless Tryouts",
                    text:"Simplify fee management, with fees paid directly to your account",
                    subtext: "Financial service and platform fees may apply",
                    anchor:"",
                },
                {
                    headline:"Objective Athlete Data",
                    text:"Eliminate perceptions of bias by capturing real performance data",
                    subtext: "",
                    anchor:"",
                },
            ],
            reasons: [
                {
                    icon: "https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/app_assets/noun-favorit-coach-689570-9145B6.svg",
                    text: "Coaching staff can focus on the task of athlete evaluation, not checking athlete attendance or collecting fees"
                },
                {
                    icon: "https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/app_assets/noun-fast-time-4303219-9145B6.svg",
                    text: "Selection data on all athletes is visible in real time, telling you and your staff who they need to pay attention to ",
                },
                {
                    icon: "https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/app_assets/noun-feedback-3385059-9145B6.svg",
                    text: "Great data leads to great feedback to athletes, improved athlete growth and better team selection quality",
                },
                {
                    icon: "https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/app_assets/noun-workflow-3438155-9145B6.svg",
                    text: "Automated workflows drive athlete sign-up, registration, and offer management... saving time and money",
                },
                {
                    icon: "https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/app_assets/noun-payment-6162419-9145B6.svg",
                    text: "Collect all your sign-up and acceptance fees online, paid directly into your own account",
                },
                {
                    icon: "https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/app_assets/noun-quality-data-4245098-9145B6.svg",
                    text: "Data driven decision tools, helping you minimise biases and focus on objective data about athlete performance, delivering the best outcome for your teams and for your athletes",
                },
                {
                    icon: "https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/app_assets/data-import.png",
                    text: "Import your athlete data directly from your club or association management system, using CoachSight to bring new capabilities rather than duplicating what you already have",
                },
                {
                    icon: "https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/app_assets/noun-sports-1962910-9145B6.svg",
                    text: "Any sport, Any selection process... configure CoachSight the way you need it to work",
                },

            ],
            slider: [
                "red", 
                "green", 
                "orange", 
                "blue", 
                "pink", 
                "purple", 
                "indigo", 
                "cyan", 
                "deep-purple", 
                "light-green", 
                "deep-orange", 
                "blue-grey"
            ]
        }
    },

};
</script>

<style>

form {
    margin-top: 40px;
}
.container {
    display: flex;
    justify-content: center;
    padding: 40px;
    max-width: 100% !important;
}


.form-group-btn {
    clear: both;
    padding-top: 20px;
    padding-bottom: 20px;
}

.login-form {
   display: flex;
    width: 100%;
}

.login-sheet {
    width: 70%;
    elevation: 10;
    color: grey lighten-3;
}
.cs-login-box {
    width: 30%;
    margin-left: 40px;
    min-width: 300px;
}

.login-pane {
    margin-top: auto;
    margin-bottom: auto;
}

.info-pane {
    margin-top:10%;
    margin-right: 10px;
    margin-left: 10px;
}
.info-heading{
    font-size: 50px;
    text-shadow: 3px 3px 10px var(--cs1-lev1);
    font-weight: 600;
    color: var(--cs2-lev4);
    font-family: 'Varela Round';
}
.info-heading2{
    font-size: 50px;
    text-shadow: 3px 3px 10px var(--cs1-lev1);
    font-weight: 600;
    color: var(--cs2-lev4);
    font-family: 'Varela Round';
}
.info-text-pane{
    background: var(--cs1-lev3);
    opacity: 0.8;
    border-radius: 10px;
    padding:30px;
    margin-bottom: auto;
}
.info-text{
    font-size:large;
    color: black;
    z-index:300;
}

.info-caveat {
    font-size: small;
    text-align: right;
    margin-top: 10px;
}
.info-block-icon{
        margin-left: 30%;
        margin-right: 30%;
        margin-bottom: 10px;
    }
    .info-block-text{
        margin-left: 10%;
        margin-right: 10%;
        color: var(--cs1-lev1);
        font-size:small;
        font-weight: 600;
    }

    .reasons-heading{
        font-size:40px;
        font-family: 'Varela Round';
        color: var(--cs1-lev1);
        margin: 40px;

    }
    .subtext-tag{
        font-size: x-small;
        position:absolute;
        translate: 4px;
    }
    .purple-section {
        background-color: var(--cs1-lev1);
        padding-bottom:40px;
    }
    .purple-section-heading {
        font-size:40px;
        font-family: 'Varela Round';
        font-weight:600;
        color: white;
        margin:40px;
        text-align: left;
    }
    .purple-section-info {
        font-size:medium;
        font-weight:600;
        font-family: 'Varela Round';
        color: white;
        margin:40px;
        text-align:left;
    }
    .purple-section-subinfo {
        font-size:medium;
        font-family: 'Varela Round';
        color: var(--cs1-lev3);
        text-align:left;
        margin-left:150px;
    }

    .price-section{
        margin: auto;
        display: flex;
        justify-content: center;

    }
    .white-section-heading {
        color: var(--cs1-lev1);
        font-size:40px;
        font-weight:600;
        font-family: 'Varela Round';
        margin:40px;
        text-align: left;
    }

    .buying-frame{
        display:flex;
        flex-flow:row;
        justify-content:center;
    }

    .buying-frame.mobile{
        flex-flow:column;

    }
    .buying-box{
        border-style: solid;
        border-color: white;
        border-radius: 10px;
        margin:20px;
        width:30%;
    }
    .buying-box.mobile{
        width:90%;
    }
    .buying-title{
        display:flex;
        flex-flow:row;
    }
    .buying-num-circle{
        border-radius: 40px;
        background: white;
        height: 40px;
        width:40px;
        margin: 35px;
    }
    .buying-num{
        margin: auto;
        padding: 0px;
        height: 40px;
        width:40px;
        font-family: 'varela round';
        font-weight: 600;
        font-size: x-large;
        color: var(--cs1-lev1);
    }

</style>
