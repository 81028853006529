import { groupService } from '../_services/group.service'
import Vue from 'vue'

const dataService = groupService

function initialiseState(){
    return {
        status: {},
        data: {},
        list: [],
        groupsByActivity: {},
        groupsBySession: {},
        error: null,
        cache: {},
        meta: {},
        internal: {},
        availableByActivity: {},
        groupRoleLists: {}
    }
}

const state = initialiseState()


function addToLists(data) {
    // Add it into the generic list first

    const id = data.id
    state.list.push(id)
    
    // Add it to the data dictionary
    Vue.set(state.data, id, data)
    Vue.set(state.meta, id, {
        delete: false,
    })

    // Now add it to the activity group list

    var list=[]

    if(Object.prototype.hasOwnProperty.call(state.groupsByActivity, data.activityId)) {
        list = state.groupsByActivity[data.activityId]
    }

    list.push(id)

    Vue.set(state.groupsByActivity,data.activityId, list)

    // NOw the sessions

    list=[]

    if(Object.prototype.hasOwnProperty.call(state.groupsBySession, data.sessionId)) {
        list = state.groupsBySession[data.sessionId]
    }

    list.push(id)

    Vue.set(state.groupsBySession,data.sessionId, list)

}

function sortListsByOrder() {
    state.list.sort((a,b)=>{
        return (''+state.data[a].name).localeCompare(''+state.data[b].name)
    })
    for (const activityId in state.groupsByActivity){
        var list = [...state.groupsByActivity[activityId]]

        list.sort((a,b)=>{
        //    return (state.data[a].order-state.data[b].order)
            return (''+state.data[a].name).localeCompare(''+state.data[b].name)
        })
        Vue.set(state.groupsByActivity, activityId, list)
    }
}

const actions = {
    purgeData({commit}){
        commit('purgeData')
    },

    //eslint-disable-next-line
    async sortAvailablePlayers({commit, dispatch}, payload) {
        commit('sortRequest', payload)
    },
    async removeRole({commit}, payload) {
        commit('removeRoleRequest', payload)
        commit('removeRoleSuccess', payload)
    },
    async findAllForEvent({commit, dispatch},payload) {
        const {eventId} = payload
        try{
            commit('loadRequest')
            const response = await dataService.findAllForEvent( eventId)
            commit('loadSuccess', response)

        } catch(error) {
            commit('loadFailure', error)
            return 
        }
        try{
            const response = await dataService.findAllGroupParticipantsForEvent(eventId)
            commit('loadGPSuccess', response)
        } catch(error) {
            commit('loadGPFailure', error)
            dispatch('alert/error', 'Player Groups failed to load', {root: true})
        }
    },
    async create({commit, dispatch }, payload){
        try{
            commit('createRequest', payload)
            const response = await dataService.create(payload)
            commit('createSuccess', response)
            return response
        } catch(error) {
            commit('createFailure', error)
            dispatch('alert/error', error, {root: true})

        }
    },
    async deleteGroupById({commit, dispatch}, payload) {
        try{
            commit('deleteRequest', payload)
            const response = await dataService.del( payload)
            commit('deleteSuccess', response)
        } catch(error) {
            commit('deleteFailure', error)
            dispatch('alert/error', error, {root: true})
        }
    },

    async movePlayer({commit, dispatch}, payload) {
        try{
            console.log(`Move player`)
            console.log(payload)
            commit('movePlayerRequest', payload)

            // if the fromGroup and toGroup are the same, and the fromRole and toRole are the same, then we are not moving
            if(payload.fromGroupId === payload.toGroupId && payload.fromRoleId === payload.toRoleId){
                commit('movePlayerSuccess', null)
                return
            }
            // if the fromGroup and toGroup are the same, and the fromRole and toRole are different, then we are moving within the same group
            if(payload.fromGroupId === payload.toGroupId){
                // update the GP to reflect the change of role
                await dataService.updateGroupParticipant({participantId: payload.participantId, groupId: payload.toGroupId, roleId: payload.toRoleId})
                commit('movePlayerSuccess', null)
                return
            }
            // if the fromGroupId is 0, then we are moving from the available list and a new GP is being created
            if(payload.fromGroupId == 0){
                if(payload.toGroupId == 0){ 
                    return
                }
                // create a new GP
                await dataService.createGroupParticipant({participantId: payload.participantId, groupId: payload.toGroupId, roleId: payload.toRoleId, eventId: payload.eventId, activityId: payload.activityId})
                commit('movePlayerSuccess', null)

                return
            }
        } catch(error) {
            commit('movePlayerFailure', error)
            dispatch('alert/error', error, {root: true})
            return 
        }

        // if the fromGroup and toGroup are different, then we are moving between groups

        if(payload.toGroupId != 0 && payload.fromGroupId != 0){
            try{
                await dataService.updateGroupParticipant({participantId: payload.participantId, groupId: payload.toGroupId, roleId: payload.toRoleId})
                commit('movePlayerSuccess', null)
                return 
            } catch(error) {
                commit('movePlayerFailure', error)
                dispatch('alert/error', error, {root: true})
            }
        }
        // if the toGroupId is 0, then we are moving to the available list and the GP is being deleted

        if(payload.toGroupId == 0){
            try{
                // delete the GP
                await dataService.delGroupParticipant({participantId: payload.participantId, groupId: payload.fromGroupId})
                commit('movePlayerSuccess', null)
                return
            } catch(error) {
                commit('movePlayerFailure', error)
                dispatch('alert/error', error, {root: true})
            }
        }
        dispatch('alert/error', 'Unknown movePlayer scenario', {root: true})
    },
    async removePlayer({commit, dispatch}, payload) {
        try{
            commit('removePlayerRequest', payload)
            const response = await dataService.removePlayerFromGroup(payload)
            commit('removePlayerSuccess', response)
        } catch(error) {
            commit('removePlayerFailure', error)
            dispatch('alert/error', error, {root: true})
        }
    },
    async resetAvailablePlayers({commit}, payload) {
        commit('resetAvailablePlayers', payload) 
    },

    //----------------

    propertyUpdate({commit},payload) {
        commit('propertyUpdate', payload)
    },
    updateListOrder({commit}, list){
        commit('updateListOrder', list)
    },
    async update({commit},payload) {
        try{
            commit('updateRequest', payload)
            const response = await dataService.update(payload)
            commit('updateSuccess', response)
        } catch(error) {
            commit('updateFailure', error)
        }
    }
}


const mutations = {
purgeData(state){
    const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }
    },

    removeRoleRequest(state, payload) {
        state.internal={loading: true, removing: payload}
        state.error=null
    },
    removeRoleSuccess(state, data) {
        for(var groupId in state.groupRoleLists){
            var groupRoles = state.groupRoleLists[groupId]
            if (Object.prototype.hasOwnProperty.call(groupRoles, data.roleId)){
                delete groupRoles[data.roleId]
            }
            Vue.set(state.groupRoleLists, groupId, groupRoles)
        }
        state.internal={updated: true}
        state.error=null
    },
    sortRequest(state, payload) {
        const {activityId, participants} = payload

        state.availableByActivity[activityId].sort((a,b)=>{
            return (participants[a].participantNumber - participants[b].participantNumber)
    })
        

    },
    // ------------- Reset available player lists ------------- \

    resetAvailablePlayers(state, payload) {
       const {participantList, roleList, activityList} = payload

       state.availableByActivity={} 

        for(var activityIdx in activityList){
            const activityId = activityList[activityIdx]
            var availablePlayers = [...participantList]

            if(Object.prototype.hasOwnProperty.call(state.groupsByActivity, activityId)){

                for(var groupIdx in state.groupsByActivity[activityId]){
                    const groupId = state.groupsByActivity[activityId][groupIdx]
                    if(Object.prototype.hasOwnProperty.call(state.groupRoleLists, groupId)){
                        for(var roleIdx in roleList){
                            const roleId = roleList[roleIdx]
                            if(Object.prototype.hasOwnProperty.call(state.groupRoleLists[groupId], roleId)){
                                for(var playerIdx in state.groupRoleLists[groupId][roleId]){
                                    const playerId = state.groupRoleLists[groupId][roleId][playerIdx]
                                    if(availablePlayers.includes(playerId)){
                                        availablePlayers.splice(availablePlayers.indexOf(playerId),1)
                                    }
                                }
                            }
                        }
                    }
                }
            }
            Vue.set(state.availableByActivity, activityId, availablePlayers)
        }

    },
    movePlayerRequest(state, payload) {
        state.internal={loading: true, moving: payload}
        state.error=null
    },

        //eslint-disable-next-line
    movePlayerSuccess(state, data) {

        //eslint-disable-next-line
        const {fromGroupId, fromRoleId, toGroupId, toRoleId, participantId, activityId} = state.internal.moving

        if(fromGroupId === toGroupId && fromRoleId === toRoleId){
            return
        }

        if(toGroupId === 0) {
            // Moving back into the available list
            var toAvailablePlayers = [...state.availableByActivity[activityId]]
            if(!toAvailablePlayers.includes(participantId)){
                toAvailablePlayers.push(participantId)
            }


            Vue.set(state.availableByActivity, activityId, toAvailablePlayers)

        }
        else {
            // Moving to a group role listvar groupRoles = {}
            var groupRoles = {}
            if(Object.prototype.hasOwnProperty.call(state.groupRoleLists, toGroupId)){
                groupRoles = state.groupRoleLists[toGroupId]
            }

            // check that the role lists exists first...
            var list = []
            if(Object.prototype.hasOwnProperty.call(groupRoles, toRoleId)){
                list = groupRoles[toRoleId]
            }
        
            if(!list.includes(participantId))  
                list.push(participantId)

            groupRoles[toRoleId]=list
            Vue.set(state.groupRoleLists, toGroupId, {})
            Vue.set(state.groupRoleLists, toGroupId, groupRoles)

        }

        
        if(fromGroupId == 0) {
            // Removing from the available list

            if(Object.prototype.hasOwnProperty.call(state.availableByActivity, activityId)){
            var availablePlayers = state.availableByActivity[activityId]
            if(availablePlayers.includes(participantId)){
                availablePlayers.splice(availablePlayers.indexOf(participantId),1)
            }
            Vue.set(state.availableByActivity, activityId, availablePlayers)
        }

        } else {
            // Removing from a group/role list
            if(Object.hasOwnProperty.call(state.groupRoleLists, fromGroupId)){
                var fromGroupRoles = state.groupRoleLists[fromGroupId]
                if(Object.hasOwnProperty.call(fromGroupRoles, fromRoleId)){
                    var fromList = [...fromGroupRoles[fromRoleId]]
                    if(fromList.includes(participantId)){
                        fromList.splice(fromList.indexOf(participantId),1)
                    }
                    fromGroupRoles[fromRoleId]=fromList
                    Vue.set(state.groupRoleLists, fromGroupId, {})
                    Vue.set(state.groupRoleLists, fromGroupId, fromGroupRoles)
                }
            }
        }

        state.internal={updated:true}
        state.error=null
    },
    //eslint-disable-next-line
    movePlayerFailure(state, error) {
    },
    removePlayerRequest(state, payload) {
        state.internal={loading: true, removing: payload}
        state.error=null

        const {groupId, playerId} = payload

        var list = []
        if(Object.prototype.hasOwnProperty.call(state.groupParticipants, groupId)){
            list = state.groupParticipants[groupId]
        }
        var idx = -1
        for(var i=0;i<list.length;i++){
            if(list[i]===playerId){
                idx=i
            }
        }
        if(idx>=0){
            list.splice(idx,1)
        }
        Vue.set(state.groupParticipants, groupId, list)

    },
    //eslint-disable-next-line
    removePlayerSuccess(state, data) {
        state.internal={updated: true}
        state.error=null
    },
    removePlayerFailure(state, error) {
        state.internal={}
        state.error=error
    },

   // ------------- loadTryout ------------- \
    
     loadRequest(state) {
        state.status={loading: true}
        state.error=null
    },
    loadSuccess(state, data) {
        state.status={}
        state.data={}
        state.groupsByActivity={}
        state.groupsBySession={}
        state.list=[]
        for (var idx in data.groups) {
            addToLists(data.groups[idx])
        }
        sortListsByOrder()
    },
    loadFailure(state, error) {
        state.status={}
        state.error = error
    },


    loadGPRequest(state) {
        state.status={loading: true}
        state.error=null
    },
    loadGPSuccess(state, data) {
        state.status={}
        state.groupRoleLists={}
        for(var idx in data.groupParticipants){
            const gp = data.groupParticipants[idx]
            var groupRoles = {}
            if(Object.prototype.hasOwnProperty.call(state.groupRoleLists, gp.groupId)){
                groupRoles = state.groupRoleLists[gp.groupId]
            }

            // check that the role lists exists first...
            var list = []
            if(Object.prototype.hasOwnProperty.call(groupRoles, gp.roleId)){
                list = groupRoles[gp.roleId]
            }

            list.push(gp.participantId)
            groupRoles[gp.roleId]=list
            Vue.set(state.groupRoleLists, gp.groupId, {})
            Vue.set(state.groupRoleLists, gp.groupId, groupRoles)
        }
    },

    loadGPFailure(state, error) {
        state.status={}
        state.error = error
    },

    //------ CREATE -------\
    createRequest(state, data) {
        state.internal={loading: true, creating: data}
        state.error=null
    },
    //eslint-disable-next-line
    createSuccess(state, data){
        state.internal={updated: true}
        addToLists(data.group)
        sortListsByOrder()
        state.error=null
    },
    createFailure(state, error) {
        state.internal={}
        state.error=error
    },
    //------ Delete -------\
    deleteRequest(state, id) {
        state.internal={deleting: true, target: id}
        state.error=null
    },
    //eslint-disable-next-line
    deleteSuccess(state, data){
        const groupId=state.internal.target
        console.log(`Deleting group with ID ${groupId}`)
        
        Vue.delete(state.data, groupId )
        Vue.delete(state.meta, groupId)
        var idx=-1
        
        for(var i=0;i<state.list.length;i++){
            if(state.list[i]===groupId) {
                idx=i
            }
        }
        if(idx>=0){
            console.log('Deleting from the main list')
            state.list.splice(idx,1)
        }

        // Also find it in the sessionData

        idx=-1

        for(var activityId in state.groupsByActivity){
            var list= [...state.groupsByActivity[activityId]]
            list.splice(list.indexOf(groupId),1)

            Vue.set(state.groupsByActivity, activityId, null)
            Vue.set(state.groupsByActivity, activityId, list)
        }

        console.log('Removing from session lists')
        for(var sessionId in state.groupsBySession){
            list = [...state.groupsBySession[sessionId]]
            list.splice(list.indexOf(groupId),1)
            Vue.set(state.groupsBySession, sessionId, null)
            Vue.set(state.groupsBySession, sessionId, list)
        }

        Vue.delete(state.groupRoleLists, groupId)

        state.internal={deleted: true}
        state.error=null

    },
    deleteFailure(state, error) {
        state.internal={}
        state.error=error
    },

    // --- UPDATE LIST ORDER ----- \\

    updateListOrder(state, payload){
        Vue.set(state.perSessionLists, payload.sessionId, payload.list)
        var count=0
        for(var idx in state.perSessionLists[payload.sessionId]){
            const id = state.perSessionLists[payload.sessionId][idx]
            state.data[id].order = count++
        }
    },
    
    //------------ update data -------- \\
    propertyUpdate(state, payload) {
        const metaFields = "delete"
        const {field, id, value} = payload

        if(metaFields.includes(field)){
            state.meta[id][field]=value
            // Manage special fields...
            //switch (field) {
            //}
        } else {
            state.data[id][field]=value
        }
    },

    updateRequest(state) {
        state.error=null
    },
    updateSuccess(state, response){
        state.internal={response}

    },
    updateFailure(error){
        state.error=error
        state.status={}
    },

}

export const groupStore = {
    namespaced: true,
    state,
    actions,
    mutations
}