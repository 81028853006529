<template>
    <div class="profile-image"  >
        <div v-if="!profileImage" class="no-image">
            <v-img :src="require('../assets/no-image-available.svg')"  class="prf-image" height="size" :max-width="size" />
        </div>
        <div v-else-if="imageAllowed" class="image-allowed">
            <v-img  :max-width="size" height="size" class="prf-image" :src="profileImage" />
        </div>
        <div v-else class="no-image">
            <v-img :src="require('../assets/no-image-available.svg')"  class="prf-image" :max-width="size" height="size" />
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'

export default{
    props: {
        participantId: {
            default: '',
        }, 
        size: {
            default: '100px',
        }
    },
    components: {},
    computed: {
        ...mapState({
            participants: state => state.participantStore.data,
            participant: state => state.participantStore.participant,
        }),
        theParticipant(){
            if(this.participant ) return this.participant
            if(Object.prototype.hasOwnProperty.call(this.participants, this.participantId)){
                return this.participants[this.participantId]
            } else {
                return null
            }
        },
        imageAllowed: function(){
                if(this.theParticipant.junior === true){
                    return this.theParticipant.imageConsent
                } else {                    
                    return true
                }
        },
        profileImage(){
            if(!this.participantId || !this.participants[this.participantId]) return null
            if(Object.prototype.hasOwnProperty.call(this.participants, this.participantId))
                return this.participants[this.participantId].profileImage
            else    
                return this.participant.profileImage
        },
        imageSize(){
            if(this.size.slice(-2) === "px"){
                return this.size.slice(0,-2)
            }
            return 0
        },
        cssProps(){
            return {
                '--size' : this.size,
            }
        }

    },
    methods: {},
    created(){
    },
    data(){
        return {

        }
    }
}</script>

<style scoped >

.image-allowed {
    margin-left:auto;
    margin-right: auto;
    border-radius: 5px;
    margin-top:auto;
    margin-bottom:auto;
    justify-content:center;
    display:flex;

}
.profile-image {
    position: relative;
    text-align: center;
    color: white;
    width:100%;
    display:flex;
    justify-content:center;
}

.prf-image{
    aspect-ratio:1/1;
    height:100px;
    border-radius: 10px;;
}

.overlay {
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 600;
    width:100%
}

.invert-icon {
    color:gainsboro;
    width:100%;
}

.no-image{
    background-color: black;
    width: var(--size);
    border-radius:10px;
    margin-left: auto;
    margin-right: auto;

}

</style>