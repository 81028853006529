import { episodeService } from '../_services/episode.service'
import Vue from 'vue'

const dataService = episodeService

function initialiseState(){
    return {
        status: {},
        data: {},
        list: [],
        error: null,
        cache: {},
        meta: {},
        internal: {},
    }
}

const state = initialiseState()


function addToLists(data) {
    // Add it into the generic list first


    const id = data.id
    state.list.push(id)
    
    // Add it to the data dictionary
    Vue.set(state.data, id, data)
    Vue.set(state.meta, id, {
        delete: false,
    })
}

function sortListsByOrder() {
    state.list.sort((a,b)=>{
        return (new Date(state.data[b].date) -  new Date(state.data[a].date))
    })
}

const actions = {
    purgeData({commit}){
        commit('purgeData')
    },

    //eslint-disable-next-line
    async sortAvailablePlayers({commit}, payload) {
        commit('sortRequest', payload)
    },
    async loadEpisode({commit },payload) {
        try{
            commit('loadEpisodeRequest')
            const response = await dataService.loadEpisode(payload)
            commit('loadEpisodeSuccess', response.data)
            return response.data.result.episode
        } catch(error) {
            console.error(error)
            commit('loadEpisodeFailure', error)
            return 
        }
    },
    async loadAllEpisodesForEvent({commit},payload) {
        try{
            commit('loadRequest')
            const response = await dataService.loadAllEpisodesForEvent(payload)
            commit('loadSuccess', response.data)
            return response.data.result

        } catch(error) {
            commit('loadFailure', error)
            return 
        }
    },
    async loadAllEpisodesForUserAndEvent({commit},payload) {
        try{
            commit('loadRequest')
            const response = await dataService.loadAllEpisodesForUserAndEvent(payload)
            commit('loadSuccess', response.data)
            return response.data.result.episodes

        } catch(error) {
            commit('loadFailure', error)
            return 
        }
    },
    async createEpisode({commit, dispatch }, payload){
        try{
            commit('createRequest', payload)
            const response = await dataService.createEpisode(payload)
            commit('createSuccess', response.data)
            return response.data.result.episode
        } catch(error) {
            console.error(error)
            commit('createFailure', error)
            dispatch('alert/error', error, {root: true})

        }
    },
    async updateEpisode({commit, dispatch }, payload){
        try{
            commit('updateRequest', payload)
            const response = await dataService.updateEpisode(payload)
            commit('updateSuccess', response)
        } catch(error) {
            commit('updateFailure', error)
            dispatch('alert/error', error, {root: true})

        }
    },
    


    //----------------

    episodePropertyUpdate({commit},payload) {
        commit('propertyUpdate', payload)
    },
    propertyUpdate({commit},payload) {
        commit('propertyUpdate', payload)
    },
    updateListOrder({commit}, list){
        commit('updateListOrder', list)
    },
    async update({commit},payload) {
        try{
            commit('updateRequest', payload)
            const response = await dataService.update(payload)
            commit('updateSuccess', response)
        } catch(error) {
            commit('updateFailure', error)
        }
    }
}


const mutations = {
    purgeData(state){
        const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }
    },

    
    //--------------------------------------------
    //   Mutations for loadEpisode
    //
    
    loadEpisodeRequest(state){
        Vue.set(state.status, 'loading', true)
        state.error=null
    },
    loadEpisodeSuccess(state, data){
        state.status = {loading: true, responseCode: data.code}
        state.data={}
        state.list=[]
        addToLists(data.result.episode)
        Vue.delete(state.status,'loading')
    },
    loadEpisodeFailure(state, error){
        state.error=error
    },
   // ------------- loadEpisode ------------- \
    
     loadRequest(state) {
        state.status={loading: true}
        state.error=null
    },
    loadSuccess(state, data) {
        state.status={}
        state.data={}
        state.list=[]
        for (var idx in data.result.episodes) {
            addToLists(data.result.episodes[idx])
        }
        sortListsByOrder()
    },
    loadFailure(state, error) {
        state.status={}
        state.error = error
    },


    //------ CREATE -------\
    createRequest(state, data) {
        state.internal={loading: true, creating: data}
        state.error=null
    },
    //eslint-disable-next-line
    createSuccess(state, data){
        state.internal={updated: true}
        addToLists(data.result.episode)
        sortListsByOrder()
        state.error=null
    },
    createFailure(state, error) {
        state.internal={}
        state.error=error
    },
    
    
    //--------------------------------------------
    //   Mutations for updateEpisode
    //
    
    updateEpisodeRequest(state){
        Vue.set(state.status, 'loading', true)
        state.error=null
    },
    updateEpisodeSuccess(state, response){
        state.status = {loading: true, responseCode: response.data.code}
        state.data={}
        state.list=[]
        
        Vue.delete(state.status,'loading')
    },
    updateEpisodeFailure(state, error){
        state.error=error
    },
    
    //------------ update data -------- \\
    propertyUpdate(state, payload) {
        const metaFields = "delete"
        const {field, id, value} = payload

        if(metaFields.includes(field)){
            state.meta[id][field]=value
            // Manage special fields...
            //switch (field) {
            //}
        } else {
            state.data[id][field]=value
        }
    },

    updateRequest(state) {
        state.error=null
    },
    updateSuccess(state, response){
        state.internal={response}

    },
    updateFailure(error){
        state.error=error
        state.status={}
    },
}

export const episodeStore = {
    namespaced: true,
    state,
    actions,
    mutations
}