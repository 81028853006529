import { teamService } from '../_services'
import Vue from 'vue'

const dataService = teamService

function initialiseState(){
    return {
        error: null,
        cache: {},

        status: {},
        data: {},
        meta: {},
        list: [],
        internal: {},
        sortedList: [],
    }
}

const state = initialiseState()

const actions = {
    purgeData({commit}){
        commit('purgeData')
    },

    async findAllForSeason({commit}, payload) {
        try{
            const {seasonId} = payload
            commit('loadRequest')

            const response = await dataService.findAllForSeason(seasonId)
            commit('loadSuccess', response)
            return response
        } catch(error) {
            commit('loadFailure', error)
        }
    },
    async loadAllTeamsForEvent({commit}, payload) {
        try{
            const {eventId} = payload
            commit('loadRequest')

            const response = await dataService.findAllForEvent(eventId)
            commit('loadSuccess', response)
            return response
        } catch(error) {
            commit('loadFailure', error)
        }
    },
    async findAllForEvent({commit}, payload) {
        try{
            const {eventId} = payload
            commit('loadRequest')

            const response = await dataService.findAllForEvent(eventId)
            commit('loadSuccess', response)
            return response
        } catch(error) {
            commit('loadFailure', error)
        }
    },
    async quietFindAllForEvent({commit}, payload) {
        try{
            const {eventId} = payload
            commit('quietLoadRequest')

            const response = await dataService.findAllForEvent(eventId)
            commit('loadSuccess', response)
            return response
        } catch(error) {
            commit('loadFailure', error)

        }
    },
    propertyUpdate({commit},payload) {
        commit('propertyUpdate', payload)
    },
    async update({commit, dispatch}, payload) {
        try{
            const {team} = payload
            commit('updateRequest')

            const response = await dataService.update(team)
            commit('updateSuccess', response)
            dispatch('alert/success', 'Team updated', {root:true})
            return response
        } catch(error) {
            commit('updateFailure', error)
        }
    },
    async create({commit, dispatch}, payload) {
        try{
            const {team} = payload
            commit('createRequest')
        
            const response = await dataService.create(team)
            commit('createSuccess', response)
            dispatch('alert/success','New team created', {root: true})
            return response
        } catch(error) {
            commit('createFailure', error)
            dispatch('alert/error','New team could not be created. Please try again', {root: true})
        }
    },

    async link({commit}, payload) {
        try{
            const {teamId, eventId, color} = payload
            commit('linkRequest')

            const response = await dataService.link(teamId, eventId, color)
            commit('linkSuccess')
            return response

        } catch(error) {
            commit('linkFailure', error)
        }
    },
    async unlink({commit}, payload) {
        try{
            const {teamId, eventId} = payload
            commit('unlinkRequest')

            const response = await dataService.unlink(teamId, eventId)
            commit('unlinkSuccess')
            return response
        } catch(error) {
            commit('unlinkFailure', error)
        }
    },
    async del({commit}, payload) {
        try{
            commit('deleteRequest', payload)

            const response = await dataService.del(payload)
            commit('deleteSuccess', response)
            return response
        } catch(error) {
            commit('deleteFailure', error)
        }
    }
}
function addToLists(data ) {
    const id = data.id

        state.list.push(id)
        state.sortedList.push(data)
        Vue.set(state.data, id, data)
        Vue.set(state.meta, id, {
                delete : false,
                selected: false,
        })
}


function sortListByOrder() {
        state.list.sort((a,b) => {
            return state.data[a].name.localeCompare(state.data[b].name)
        })
 }


const mutations = {
purgeData(state){
    const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }
    },
    //-------LINK/UNLINK ---------

    linkRequest(state) {
        state.internal={loading: true}
        state.error=null
    },
    linkSuccess(state) {
        state.internal={}
    },
    linkFailure(state, error) {
        state.internal={}
        state.error = error
    },

    unlinkRequest(state) {
        state.internal={loading: true}
        state.error=null
    },
    unlinkSuccess(state) {
        state.internal={}
    },
    unlinkFailure(state, error) {
        state.internal={}
        state.error = error
    },


    //------ UPDATE -------\
    updateRequest(state) {
        state.internal={loading: true}
        state.error=null
    },
    //eslint-disable-next-line
    updateSuccess(state, data){
        state.internal={updated: true}
        state.error=null
    },
    updateFailure(state, error) {
        state.internal={}
        state.error=error
    },
    // ------------- loadTryout ------------- \
    
    loadRequest(state) {
        //state.status={loading: true}
        state.error=null
    },
    quietLoadRequest(state) {
        state.status={}
        state.error=null
    },
    loadSuccess(state, data) {
        state.data={}
        state.list=[]
        state.sortedList=[]
        for(var idx in data){
            addToLists(data[idx])
        }
        sortListByOrder()
        state.status={loading: false}
    },
    loadFailure(state, error) {
        state.status={}
        state.error = error
    },

    //----------------------------------------

 

    createRequest(state) {
        state.status={loading: true}
        state.error=null
    },
    createSuccess(state, payload) {
        state.status={created: true}
        addToLists(payload.team)
    },
    createFailure(state,error) {
        state.status={error: true}

        state.error=error
    },


    //------------ update data -------- \\
    propertyUpdate(state, payload) {
        const metaFields = "delete"
        const {field, id, value} = payload

        if(metaFields.includes(field)){
            state.meta[id][field]=value
            // Manage special fields...
            //switch (field) {
            //}
        } else {
            state.data[id][field]=value
        }
    },

    //------------ update data -------- \\
    deleteRequest(state, data) {
        state.internal.data = data
        state.status={loading: true}
        state.error={}
    },

    //eslint-disable-next-line
    deleteSuccess(state, response){

        const id = state.internal.data.teamId


        Vue.delete(state.data,id)
        Vue.delete(state.meta, id)
        const index = state.list.indexOf(id)
//        state.list.splice(state.list.indexOf(id,1))

        state.list.splice(index,1)
        state.internal={}

        state.internal={deleted: true}
        state.error=null


//        delFromLists(state, state.internal.data)
        state.status={}


    },

    deleteFailure(state, error){
        state.error=error
        state.status={}
    }

}
export const teamStore = {
    namespaced: true,
    state,
    actions,
    mutations
}