<template>
    <div>
        <v-row>
            <v-col>

       Your account is now active 
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="3">
                Account ID:
            </v-col>
            <v-col>{{ clubs[selectedClub].stripeAccount }}</v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-divider/>
            </v-col>

        </v-row>
        <v-row>
            <v-col >
                <span style="font-size:small">You may select to drop your banking connection to CoachSight by clicking on the button below. If you do so, any trials in progress will only charge platform fees ($4 per player) until you reinstate your account link with us.</span>
        
            </v-col>
            <v-col>
                <v-dialog 
                    v-model="dialog"
                    hide-overlay
                >
                    <template v-slot:activator="{on, attrs}">
                        <v-btn
                            small
                            dark
                            color="warning"
                            v-bind="attrs"
                            v-on="on"
                        >
                            Disconnect my Stripe account
                        </v-btn>
                    </template>
                        <v-row class="warn-row">

                            <v-col cols="4" class="left-col">
                        <span class="empasise">Are you sure you want to disconnect your stripe account?</span><br/>

                            </v-col>
                            <v-col class="warn-info">
                                <v-row>
                                    <v-col class="warn-info">
                                        Remember, disconnecting your Stripe account means you will not receive fees for your trial. Participants will only be charged the platform fee while there is no Stripe account attached to your club.
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-spacer/>
                                    <v-col>
                                        <v-btn text @click="dialog=false">Cancel</v-btn>
                                    </v-col>
                                    <v-col>
                                        <v-btn color="primary" text @click="disconnect">Disconnect</v-btn>
                                    </v-col>
                                </v-row>

                            </v-col>
                        </v-row>
                </v-dialog>
            </v-col>
        </v-row>


    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';



export default {

    props:[],
    components: {},
    computed: {
        ...mapState({
            clubs:              state => state.clubStore.data,
            clubStatus:         state => state.clubStore.status,

            selectedClub:               state => state.navigatorStore.selectedClub,
            navigatorInitialised:       state => state.navigatorStore.initialised,
        }),
    },
    methods: {
        ...mapActions('clubStore',{
            updateClubProp: 'propertyUpdate',
            updateClub: 'update',
        }),
        disconnect(){
            this.updateClubProp({field:'stripeStatus', id: this.selectedClub, value: 'noAccount'})
            this.updateClubProp({field:'stripeAccount', id: this.selectedClub, value: ''})
            this.updateClubProp({field:'stripeLinkURL', id: this.selectedClub, value: ''})
            this.updateClubProp({field:'stripeLinkExpiry', id: this.selectedClub, value: null})

            this.updateClub({club:this.clubs[this.selectedClub]})

        }
    },
    created(){},
    data(){
        return {
            dialog: false,

        }
    }
}
</script>

<style scoped>
.empasise {
    font-weight: 600;
    color:white;
}
.warn-row {
    min-height:100%;
    margin:0;
}
.warn-info {
    margin-top:auto;
    margin-bottom:auto;
    padding-left:10%;
    padding-right:10%;
}
.left-col{
    background-color: var(--cs1-lev1);;
    margin-top:0px;
    padding-left:5%;
    padding-right:5%;
    padding-top: 40px;
    height:30vh;
}

.warn-dialog {
    min-height:30%;
}

</style>