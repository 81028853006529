import {sendMessage} from '../_helpers/service-tools'

export const journalentryService = {
    loadAllJournalEntriesForEvent,
    loadAllJournalEntriesForTeam,
    loadAllJournalEntriesForUser,
    createNewJournalEntry,
    createTeamJournalEntry,
    updateJournalEntry,
}

async function loadAllJournalEntriesForUser(payload){
    if(Object.prototype.hasOwnProperty.call(payload, 'eventId')){
        const response=  await sendMessage('GET', 'journalentry/loadAllJournalEntriesForUser', {eventId: payload.eventId, userId: payload.userId }, null)
        return response
    } else {
        const response=  await sendMessage('GET', 'journalentry/loadAllJournalEntriesForUser', {teamId: payload.teamId, userId: payload.userId }, null)
        return response
    }
}

async function loadAllJournalEntriesForTeam(payload){
    const response=  await sendMessage('GET', 'journalentry/loadAllJournalEntriesForTeam', {teamId: payload.teamId }, null)
    return response
}

async function loadAllJournalEntriesForEvent(payload){
    const response=  await sendMessage('GET', 'journalentry/loadAllJournalEntriesForEvent', {eventId: payload.eventId }, null)
    return response
}

async function createTeamJournalEntry(payload){
    return await sendMessage('POST', 'journalEntry/createTeamJournalEntry', null, {
        teamId: payload.teamId,
        type: payload.type,
        journalOwnerId: payload.journalOwnerId, 
        content: payload.content,
        parentId: payload.parentId,

    })
}


async function createNewJournalEntry(payload){
    return await sendMessage('POST', 'journalEntry/', null, {
        eventId: payload.eventId,
        type: payload.type,
        journalOwnerId: payload.journalOwnerId, 
        content: payload.content,
        parentId: payload.parentId,

    })
}

async function updateJournalEntry(payload){
    return await sendMessage('PATCH', 'journalentry/updateJournalEntry', 
        {journalId: payload.journalId},
        {content: payload.content}
    )
}