import {sendMessage} from '@/_helpers/service-tools'

export const groupService = {
    findAllForEvent,
    create,
    update,
    del,
    findAllGroupParticipantsForEvent,
    createGroupParticipant,
    updateGroupParticipant,
    delGroupParticipant,

}

async function findAllForEvent(id) {
    return await sendMessage('GET', 'group/listAllForEvent',{eventId: id})
}

async function create(group) {
    return await sendMessage('POST', 'group', null, group)

}

async function update(group) {
    return await sendMessage('PATCH', 'group', {groupId: group.id}, group)
}

async function del(groupId) {
    return await sendMessage('PATCH', 'group/delete', {groupId: groupId}, null)
}

async function findAllGroupParticipantsForEvent(id) {
    return await sendMessage('GET', 'groupparticipant/listAllForEvent',{eventId: id})
}

async function createGroupParticipant(groupParticipant) {
    return await sendMessage('POST', 'groupparticipant', null, groupParticipant)

}   

async function updateGroupParticipant(groupParticipant) {
    return await sendMessage('PATCH', 'groupparticipant', {participantId: groupParticipant.participantId}, groupParticipant)
}   

async function delGroupParticipant(groupParticipant) {
    return await sendMessage('DELETE', 'groupparticipant', {participantId: groupParticipant.participantId, groupId: groupParticipant.groupId}, null)
}

