<template>
    <loading v-if='loading'/>
    <div v-else>
        <v-row style="margin-top:10px;">
            <v-col cols="2" sm="2" md="1" lg="1" xl="1">
                <em>Rating</em>
            </v-col>
            <v-col cols="10" sm="10" md="5" lg="5" xl="5" class="text-left">
                <em>Skill</em>
            </v-col>


        </v-row>
        <v-row v-for="criterionId in selectedCriterionList" :key="criterionId">
            <v-col cols="2" sm="2" md="1" lg="1" xl="1">
                <v-progress-circular :color=colorScore(criterionId) :value="criterionScore(criterionId)*20" rotate="-90">

                </v-progress-circular>
            </v-col>
            <v-col cols="10" sm="10" md="5" lg="5" xl="5" class="text-left">
                {{ criteria[criterionId].description }}
            </v-col>

        </v-row>
    </div>
</template>

<script>
import Loading from '@/components/loading'
import {mapState} from 'vuex'

export default{
    props: {
        invertSkills: {
            type: Boolean,
            default: false
        },
        staffId: {
            type: String,
            default: null,
        }

    },
    components:{
        Loading
    },
    computed: {
        ...mapState({

            sessions                    :   state => state.sessionStore.data,
            playerskills                :   state => state.playerskillStore.data,
            allStaffSkills              :   state => state.playerskillStore. allStaffData,
            selectedParticipant         :   state => state.navigatorStore.selectedParticipant,
            selectedSession             :   state => state.navigatorStore.selectedSession,

            criteria                    :   state => state.criterionStore.data,
            criterionList               :   state => state.criterionStore.list,
        }),
        loading(){
            return false ||
                false;
        },
        selectedCriterionList: {
            get(){
                if(this.selectedSession && this.sessions[this.selectedSession] && this.sessions[this.selectedSession].criteria.length>0){
                    if(this.invertSkills){
                        return this.criterionList.filter(x => !this.sessions[this.selectedSession].criteria.includes(x));    
                    } else {
                        return this.sessions[this.selectedSession].criteria
                    }
                }
                return this.criterionList
            }
        },
    },
    methods: {
        criterionAverage(criterionId){
            return this.playerskills[this.selectedParticipant].criteria[criterionId].average/5*100
        },
        criterionAverageScore(criterionId){
            return this.playerskills[this.selectedParticipant].criteria[criterionId].average
        },
        criterionScore(criterionId){
            console.log('calc crit score')
            console.log(this.staffId)
            console.log(`Participant: ${this.selectedParticipant}`)
            const scoresByStaff = this.allStaffSkills[this.selectedParticipant].criteria[criterionId].staff
            if(this.staffId !== null) return scoresByStaff[this.staffId].score

            var score=0
            for(var staffId in scoresByStaff){
                console.log(`Score by ${staffId}: ${scoresByStaff[staffId].score}`)
                if (scoresByStaff[staffId].score>0) {
                    score=scoresByStaff[staffId].score
                }
            }

            return score
        },
        colorAverage(criterionId){
            return this.skillColor[Math.round(this.playerskills[this.selectedParticipant].criteria[criterionId].average)]
        },
        colorScore(criterionId){
            return this.skillColor[this.criterionScore(criterionId)]
        },

    },
    watch: {},
    async created(){

    },
    data(){
        return {
            skillColor: [
                "#e43f3e",
                "#f0864c",
                "#f8c43c",
                "#c2cc36",
                "#5aaf2b",
                "#5aaf2b",
            ],

        }
    }

}
</script>