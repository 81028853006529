import {sendMessage} from '@/_helpers/service-tools'

export const playerattributeService = {
    findAllForEvent,
    updateValue
}

async function findAllForEvent(id) {
    return await sendMessage('GET','playerattribute', {eventId: id}, null)
}

async function updateValue(eventId, participantId, attributeId, value) {
    return await sendMessage('PATCH', 'playerattribute', {attributeId}, {participantId, eventId, value})
}