import { attributeService } from "../_services/attribute.service";
//import {router} from '../helpers'
import Vue from "vue";

function initialiseState(){
    return {
        status: {},
        data: {},
        meta: {},
        list: [],
        error: null
    }
}

const state = initialiseState()

const actions = {
    purgeData({commit}){
        commit('purgeData')
    },
    async findAllForEvent({dispatch, commit}, payload) {
        try{
            const { eventId  } = payload
            commit('findAllForEventRequest')
            const response = await attributeService.findAllForEvent(eventId)
            commit('findAllForEventSuccess', response)
        } catch(error) {
            commit('findAllForEventFailure', error)
            dispatch('alert/error', error, {root: true})
        }
    },
    async quietFindAllForEvent({dispatch, commit}, payload) {
        try{
            const { eventId  } = payload
            commit('quietFindAllForEventRequest')
            const response = await attributeService.findAllForEvent(eventId)
            commit('findAllForEventSuccess', response)

        } catch(error) {
            commit('findAllForEventFailure', error)
            dispatch('alert/error', error, {root: true})
        }
    },
    async create({dispatch, commit}, attribute) {
        try{
            commit('createRequest')
            const response = await attributeService.create(attribute) 
            setTimeout(() => {
                commit('createSuccess', response)
                dispatch('alert/success', 'Your new attribute has been created', {root: true})
            })
        } catch(error) {
            setTimeout(() => {
                commit('createFailure', error)
                dispatch('alert/error', error, {root: true})
            })
        }
    },
    async update({dispatch, commit}, attribute) {
        try{
            commit('updateRequest')
            await attributeService.update(attribute)
            setTimeout(() => {
                commit('updateSuccess')
            })
        } catch(error){
            setTimeout(() => {
                commit('updateFailure', error)
                dispatch('alert/error', error, {root: true})
            })
        }
    },
    async archive({dispatch, commit}, attributeId) {
        try{
            commit('archiveRequest')
            attributeService.archiveAttribute(attributeId)
            commit('archiveSuccess')
        } catch (error) {
            commit('archiveFailure', error)
            dispatch('alert/error', 'One or more questions failed to be archived')
        }
    },
    
   updateListOrder({commit}, list){
        commit('updateListOrder', list)
    },
    propertyUpdate({commit},payload) {
        commit('propertyUpdate', payload)
    }

}
function addToLists(data) {
    const id = data.id

    state.list.push(id)
    Vue.set(state.data, id, data)
    Vue.set(state.meta, id, {
        archive: false,
    })
}

function sortListByOrder() {
/*
    state.list.sort((a,b) =>  {
        return state.questions[a].order - state.questions[b].order
    })
    */
}


const mutations = {
purgeData(state){
    const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }
    },
    findAllForEventRequest(state) {
        state.status={loading: true}
        state.list=[]
        state.error=null
    },
    quietFindAllForEventRequest(state) {
        state.status={}
        state.list=[]
        state.error=null
    },
    findAllForEventSuccess(state, attributes){
        state.status={}
        state.list=[]
        state.data={}

        for (var idx in attributes) {
            addToLists(attributes[idx])
        }
        sortListByOrder()
    },
    findAllForEventFailure(state, error){
        state.status={}
        state.error = error
    },

    // ------------- CREATE ------------- \
    
    createRequest(state) {
        state.internal={loading: true}
        state.error=null
    },
    createSuccess(state, payload) {
        state.internal={}
       
        addToLists(payload.attribute)
    //    sortListOnOrder()
    },
    createFailure(state, error) {
        state.status={}
        state.error = error
    },

    // ------------- UPDATE ------------- \
    
    updateRequest(state) {
        state.internal={loading: true}
        state.error=null
    },
    updateSuccess(state) {
        state.internal={}
       // Add the question in to the lists
       // buildQuestion(appQuestion) 
    },
    updateFailure(state, error) {
        state.status={}
        state.error = error
    },

    // ----------- DATA UPDATE ------- \\
    update(state, payload) {
        const metaFields="archive"

        const {field, id, value} = payload
        if(metaFields.includes(field)){
            state.meta[id][field]=value
            // Manage special fields...
            //switch (field) {
            //}
        } else {
            state.questions[id][field]=value
        }
    },

    //-------- UPDATE LIST ORDER -------\\

    archiveRequest(state, attributeId) {
        state.status={deleting: true, id: attributeId}
        state.error=null
    },
    archiveSuccess(state) {
        delete state.data[state.status.id]
        delete state.meta[state.status.id]

        const index=state.list.indexOf(state.status.id)
        state.list.splice(index, 1)

    },
    archiveFailure(state, error) {
        state.status={}
        state.error = error
    },
    updateListOrder(state, list) {
        state.list = list
        var count=0;
        for(var idx in state.list) {
            const id = state.list[idx]
            state.questions[id].order = count++
        }
    },

    propertyUpdate(state, payload) {
        const metaFields = "archive"
        const {field, id, value} = payload

        if(metaFields.includes(field)){
            state.meta[id][field]=value
            // Manage special fields...
            //switch (field) {
            //}
        } else {
            state.data[id][field]=value
        }
    }

}

export const attributeStore = {
    namespaced: true,
    state,
    actions,
    mutations
}
