import {cscService} from '../_services/csc.service'
//import Vue from 'vue'

const dataService = cscService

function initialiseState(){
    return {
        status: {},
        refreshed: null,
        countries: [],
        states: [],
        cities:[],
        list: [],
        error: null,
    }
}

const state = initialiseState()

const actions = {
    purgeData({commit}){
        commit('purgeData')
    },
    async findAllCountries({commit}) {
        if(!state.refreshed || (new Date() - state.refreshed)/1000 > 3600) {
            try{
                commit('findCountriesRequest')
                const response = await dataService.getCountries()
                commit('findCountriesSuccess', response)
            } catch(error) {
                commit('findCountriesFailure', error)
            }
        }
    },
    async findAllStates({commit}, payload) {
        //if(!state.refreshed || (new Date() - state.refreshed)/1000 > 3600) {
        try{
            commit('findStatesRequest')
            const response = await dataService.getStates(payload)
            commit('findStatesSuccess', response)
        } catch(error) {
            commit('findStatesFailure', error)
        }
    },    
    async findAllCities({commit}, payload) {
        //if(!state.refreshed || (new Date() - state.refreshed)/1000 > 3600) {
        try{
            commit('findCitiesRequest')
            const response = await dataService.getCities(payload)
            commit('findCitiesSuccess', response)
        } catch(error) {
            commit('findCitiesFailure', error)
        }
    },

}

const mutations = {
purgeData(state){
    const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }
    },
    findCountriesRequest(state){
        state.status = {loading: true}
    },
    findCountriesSuccess(state, response){
        state.countries=response

        // Now print AU, US and UK up to the top of the list as duplicates

        const prologue = [{},{},{}, {type:"divider", divider:true}]
        for(var idx in state.countries){
            if(state.countries[idx].iso2==='AU'){
                prologue[0] = state.countries[idx]
            }
            if (state.countries[idx].iso2==='US'){
                prologue[1] = state.countries[idx]
            }
            if (state.countries[idx].iso2==='GB'){
                prologue[2] = state.countries[idx]
            }
        }
        state.countries = prologue.concat(state.countries)


        state.states=[]
        state.cities=[]
        state.status={}
        state.refreshed = new Date()
    },
    findCountriesFailure(state, error){
        state.error = error
    },

    findStatesRequest(state){
        state.status = {loading: true}
    },
    findStatesSuccess(state, response){
        state.states=response
        state.cities=[]
        state.status={}
    },
    findStatesFailure(state, error){
        state.error = error
    },

    findCitiesRequest(state){
        state.status = {loading: true}
    },
    findCitiesSuccess(state, response){
        state.cities=response
        state.status={}
    },
    findCitiesFailure(state, error){
        state.error = error
    }
}

export const cscStore={
    namespaced: true,
    state,
    actions,
    mutations
}