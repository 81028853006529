import { roleService } from '../_services'
//import { router } from '../_helpers'
import Vue from 'vue'

const dataService = roleService

function initialiseState(){
    return {
        status: {},
        list: [],
        data: {},
        meta: {rcLinks: {}},
        error: null,
        internal:{},
    }
}

const state = initialiseState()

const actions = {
    purgeData({commit}){
        commit('purgeData')
    },
    async linkRoleAndCriterion({commit}, payload){
        try {
            commit('linkRoleAndCriterionRequest', payload)
            const response = await dataService.linkRoleAndCriterion(payload)
            commit('linkRoleAndCriterionSuccess', response)
            return response
        } catch(error) {
            commit('linkRoleAndCriterionFailure', error)
        }
    },
    async unlinkRoleAndCriterion({commit}, payload){
        try {
            commit('unlinkRoleAndCriterionRequest', payload)
            const response = await dataService.unlinkRoleAndCriterion(payload)
            commit('unlinkRoleAndCriterionSuccess', response)
            return response
        } catch(error) {
            commit('unlinkRoleAndCriterionFailure', error)
        }
    },
    async loadAllRolesForTeam({dispatch, commit}, payload) {
        try{
            commit('findAllRequest')
            const response = await dataService.loadAllRolesForTeam(payload)
            commit('findAllSuccess', response)
        } catch(error) {
            commit('findAllFailure', error)
            dispatch('alert/error', error, {root: true})
        }
    },
    async loadAllRolesForEvent({dispatch, commit}, payload) {
        try{
            commit('findAllRequest')
            const response = await dataService.findAllForEvent(payload)
            commit('findAllSuccess', response)
        } catch(error) {
            commit('findAllFailure', error)
            dispatch('alert/error', error, {root: true})
        }
    },
    //*******RETIRE  */
    async findAllForEvent({dispatch, commit}, payload) {
        try{
            commit('findAllRequest')
            const response = await dataService.findAllForEvent(payload)
            commit('findAllSuccess', response)
        } catch(error) {
            commit('findAllFailure', error)
            dispatch('alert/error', error, {root: true})
        }
    },
    async quietFindAllForEvent({dispatch, commit}, payload) {
        try{
            commit('quietFindAllRequest')
            const response = await dataService.findAllForEvent(payload)
            commit('findAllSuccess', response)
        } catch(error) {
            commit('findAllFailure', error)
            dispatch('alert/error', error, {root: true})
        }
    },
    async create({dispatch, commit}, payload) {
        try{
            commit('addRequest')
            const response = await dataService.create(payload)
            setTimeout(() => {
                commit('addSuccess',response)
                dispatch('alert/success','Role created',{root:true})
            })
        } catch(error){
            setTimeout(() => {
                // Display a success message after the route change completes
                commit('addFailure',error)
                dispatch('alert/error', error, {root: true})
            })
        }
    },
    async del({commit, dispatch}, payload) {
        try{
            commit('deleteRequest', payload)
            const response = await dataService.del(payload)
            commit('deleteSuccess', response)
            dispatch('alert/success','Role successfully deleted. Remind your staff to reload their app!', {root:true})
        } catch(error) {
            commit('deleteFailure', error)
            dispatch('alert/error','Role was not deleted. Please try again or contact support', {root:true})
        }
    },
    async update({commit}, payload) {
        try{
            commit('updateRequest')
            const response = dataService.update(payload)
            commit('updateSuccess', response)
        } catch(error) {
            commit('updateFailure', error)
        }
    },

    propertyUpdate({commit},payload) {
        commit('propertyUpdate', payload)
    }
}

function addToLists(data) {
    
    const id = data.id

    state.list.push(id)
    Vue.set(state.data, id, data)
    Vue.set(state.meta, id, {
        delete: false,
    })

}

function sortListByOrder() {
    // Nothing to do here...
}

function addLink(data){
    const criterionId = data.criterionId
    const roleId = data.roleId
    const order = data.order

    if(!state.meta.rcLinks[roleId]) state.meta.rcLinks[roleId]=[]
    if(!state.meta.rcLinks[roleId][order]) {
        for(var i=0;i<=order;i++){
            if(!state.meta.rcLinks[roleId][i])
                state.meta.rcLinks[roleId][i]={}
        }
    }

    state.meta.rcLinks[roleId][data.order] = {
        criterionId,
        weight: data.weight,
        order: data.order
    }
}
function sortLinks(){
    const keys = Object.keys(state.meta.rcLinks)
    const newRcLinks={}
    for(var idx in keys){
        newRcLinks[keys[idx]] = state.meta.rcLinks[keys[idx]].toSorted((a,b) => a.order - b.order)
    }
    Vue.set(state.meta, 'rcLinks', newRcLinks)
}

const mutations = {
purgeData(state){
    const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }
    },

//--------------------------------------------
//   Mutations for linkRoleAndCriterion
//

linkRoleAndCriterionRequest(state, payload){
    Vue.set(state.status, 'loading', true)
    state.error=null
    state.internal.payload=payload
},
linkRoleAndCriterionSuccess(state, response){
    state.status = {loading: true, responseCode: response.data.code}
    addLink(response.data.result.rolecriterion)
    sortLinks() 

    Vue.delete(state.status,'loading')
},
linkRoleAndCriterionFailure(state, error){
    console.error('Fail')
    console.error(error)
    state.error=error
    state.status={}
},


//--------------------------------------------
//   Mutations for unlinkRoleAndCriterion
//

unlinkRoleAndCriterionRequest(state, payload){
    Vue.set(state.status, 'loading', true)
    state.error=null
    state.internal.payload = payload
},
unlinkRoleAndCriterionSuccess(state, response){
    state.status = {loading: true, responseCode: response.data.code}

    const roleId = state.internal.payload.roleId

    state.meta.rcLinks[roleId].splice(state.internal.payload.order,1)
    for(var i = 0; i< state.meta.rcLinks[roleId].length;i++){
        if(state.meta.rcLinks[roleId][i].order > state.internal.payload.order){
            state.meta.rcLinks[roleId][i].order--
        }
    }
    Vue.delete(state.status,'loading')
},
unlinkRoleAndCriterionFailure(state, error){
    state.error=error
},

    addRequest(state) {
        state.status={loading: true}
        state.error=null
    },
    //eslint-disable-next-line
    addSuccess(state, data) {
        state.status = {}
        addToLists(data.role)
        sortListByOrder()
        state.meta.rcLinks[data.role.id] =[]
    },
    addFailure(state, error) {
        state.status = {}
        state.error = error
    },

    //--------- FIND ALL -------\\

    findAllRequest(state) {
        state.status = {loading: true}
        state.error=null
    },
    quietFindAllRequest(state) {
        state.status = {}
        state.error=null
    },
    findAllSuccess(state, data) {
        state.list=[]
        state.data={}
        state.responses={}

        if(data.roles.length === 0) {
            state.status = {}
            return
        }
        for(var idx in data.roles) {
            addToLists(data.roles[idx])
            state.meta.rcLinks[data.roles[idx].id] =[]
        }
        sortListByOrder()


        // Add the links to each role

        state.meta.rcLinks={}
        for(idx in data.rcLinks){
            addLink(data.rcLinks[idx])
        }
        
        sortLinks()
        state.status={}
    },
    findAllFailure(state, error) {
        state.status = {}
        state.error = error
    },

    //------ DELETE -------\
    deleteRequest(state, data) {
        state.internal={loading: true, id:data.roleId}
        state.error=null
    },
    //eslint-disable-next-line
    deleteSuccess(state, data){
        state.error=null
        // Remove it from the list and the dataset...

        const idx = state.list.indexOf(state.internal.id)
        const newList = state.list
        newList.splice(idx,1)
        Vue.set(state, 'list', newList)
        Vue.delete(state.data, state.internal.id)
        state.internal={updated: true}
    },
    deleteFailure(state, error) {
        state.internal={}
        state.error=error
    },
   //------ UPDATE -------\
    updateRequest(state) {
        state.internal={loading: true}
        state.error=null
    },
    //eslint-disable-next-line
    updateSuccess(state, data){
        state.internal={updated: true}
        state.error=null
    },
    updateFailure(state, error) {
        state.internal={}
        state.error=error
    },

    // ------------- loadTryout ------------- \
    
    loadRequest(state) {
        state.status={loading: true}
        state.error=null
    },
    loadSuccess(state, data) {
        state.data = [data]   
        state.status={}
    },
    loadFailure(state, error) {
        state.status={}
        state.error = error
    },

    //------------ update data -------- \\
    propertyUpdate(state, payload) {
        const metaFields = "delete"
        const {field, id, value} = payload

        if(metaFields.includes(field)){
            state.meta[id][field]=value
            // Manage special fields...
            //switch (field) {
            //}
        } else {
            state.data[id][field]=value
        }
    }

}

export const roleStore = {
    namespaced: true,
    state,
    actions,
    mutations
}