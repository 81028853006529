<template>
    <loading v-if='loading'/>
    <div v-else class="group-filter-box">
        <v-btn v-if="false" color="primary" label="Group Filters" icon><v-icon>mdi-filter-outline</v-icon></v-btn>
        <v-btn color="primary" label="Add Group" icon @click="showAddGroupDialog=true"><v-icon>mdi-account-multiple-plus</v-icon></v-btn>
        <v-dialog v-model="showAddGroupDialog" max-width="90%" width="90%" class="add-group-dialog">
            <v-card>
                <v-card-title>
                    <span>Create a new group</span>
                </v-card-title>
                <v-card-text>
                    <v-row class="no-boundaries" dense>
                        <v-col cols="6" style="margin-top:0px;">
                            <div>
                                <div class="text-left">
                                    <b>Automatic Group Name:</b> {{ autoName }}
                                </div>
                                <v-text-field label="Override Auto Group Name" v-model="inputGroupName" :placeholder="autoName"></v-text-field>
                            </div>
                            <div class="selection-box">
                                Drop {{ $pluralize(tryouts[selectedTryout].participantPronoun) }} here
                                <v-row>
                                    <v-col class="role-name" v-for="roleId in roleList" :key="roleId" @drop="onDrop($event, roleId)" @dragover.prevent @dragenter.prevent>
                                        {{ roles[roleId].name  }}
                                    </v-col>
                                </v-row>
                                <v-row class="no-boundaries" style="min-height:10px;margin-bottom:8px;">
                                    <v-col v-for="roleId in roleList" :key="roleId" class="drop-zone" @drop="onDrop($event, roleId)" @dragover.prevent @dragenter.prevent>
                                        <div v-for="participantId in selectedParticipants[roleId]" :key="participantId" draggable @dragstart="startDrag($event, {participantId: participantId, fromArea:'selected', fromRoleId: roleId})">
                                            <MiniParticipantTile :participantId="participantId" class="drag-el" v-on:click.native="selectParticipant(participantId)"/>
                                        </div>
                                    </v-col>
                                </v-row>

                            </div>

                        </v-col>
                        <v-col cols="6" @drop="onDrop($event, 'remaining')" @dragover.prevent @dragenter.prevent>
                            <v-row>
                                <v-col cols="2" v-for="participantID in remainingParticipants" :key="participantID" draggable @dragstart="startDrag($event, {participantId: participantID, fromArea: 'remaining'})">
                                    <MiniParticipantTile :participantId="participantID" class="drag-el"/>
                                </v-col>

                            </v-row>
                        </v-col>
                    </v-row>
                    <v-spacer></v-spacer>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="secondary" text @click="cancel">Cancel</v-btn>
                    <v-spacer/>
                    <v-btn color="primary" text @click="save" :disabled="selectedParticipants>0">Save Group</v-btn>
                    <v-spacer/>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Loading from '@/components/loading'
import MiniParticipantTile from '@/components/MiniParticipantTile'

import {mapState,mapActions} from 'vuex'

export default{
    props: [],
    components:{
        Loading,
        MiniParticipantTile,
    },
    computed: {
        loading(){
            return false ||
                false;
        },
        ...mapState({
            participants:       state => state.participantStore.data,
            participantList:    state => state.participantStore.list,
            participantStatus:  state => state.participantStore.status,

            roles:              state => state.roleStore.data,
            roleList:           state => state.roleStore.list,

            tryouts:            state => state.tryoutStore.data,

            selectedTryout:     state => state.navigatorStore.selectedTryout,
            selectedSession: state => state.navigatorStore.selectedSession,

            groups: state => state.groupStore.data,
            groupList: state => state.groupStore.list,
            groupStatus: state => state.groupStore.status,
            groupsByActivity: state => state.groupStore.groupsByActivity,
            groupsBySession:        state => state.groupStore.groupsBySession,
            groupRoleLists: state => state.groupStore.groupRoleLists,
            availablePlayersByActivity: state => state.groupStore.availableByActivity,
        }),
        remainingParticipants(){
            return this.participantList.filter((e)=> {
                const self = this;
                return !self.allSelectedParticipants.includes(e)
            })
        },
        autoName(){
            if(this.allSelectedParticipants.length===0 || this.allSelectedParticipants.length > 3) return "Not Available"
            var autoName=this.participants[this.allSelectedParticipants[0]].lastName
            if(this.allSelectedParticipants.length >1) {
                for(var i=1;i<this.allSelectedParticipants.length;i++){
                    autoName += '/' + this.participants[this.allSelectedParticipants[i]].lastName
                }
            }
            return autoName
        },

    },
    methods: {
        ...mapActions('groupStore', {
            createGroup: 'create',
            findAllGroupsForEvent: 'findAllForEvent',
            addPlayerToGroup: 'addPlayer',
            movePlayerToGroup: 'movePlayer',
            removePlayerFromGroup: 'removePlayer',
            resetAvailablePlayers: 'resetAvailablePlayers',
            sortAvailablePlayers: 'sortAvailablePlayers',
            deleteGroupById: 'deleteGroupById',

        }),
        ...mapActions('navigatorStore', {
            setSelectedParticipant: 'setSelectedParticipant',
        }),
        startDrag(evt, payload ){
            const {participantId, fromArea, fromRoleId } = payload
            evt.dataTransfer.dropEffect="move"
            evt.dataTransfer.effectAllowed="move"   
            evt.dataTransfer.setData('participantId', participantId)
            evt.dataTransfer.setData('fromArea', fromArea)
            evt.dataTransfer.setData('fromRoleId', fromRoleId)
        },
        async onDrop(evt, roleId){
            
            const participantId=evt.dataTransfer.getData('participantId')
            const fromArea=evt.dataTransfer.getData('fromArea')
            if (fromArea ==='remaining'){
                if(!Object.prototype.hasOwnProperty.call(this.selectedParticipants, roleId))
                    this.selectedParticipants[roleId]=[]
                this.selectedParticipants[roleId].push(participantId)
                this.allSelectedParticipants.push(participantId)
            }
            const fromRoleId=evt.dataTransfer.getData('fromRoleId')
            var idx=0;
            if(fromArea === 'selected' && roleId==='remaining'){
                idx = this.selectedParticipants[fromRoleId].indexOf(participantId)
                this.selectedParticipants[fromRoleId].splice(idx,1)

                idx = this.allSelectedParticipants.indexOf(participantId)
                this.allSelectedParticipants.splice(idx,1)
            } else
            if(fromArea === 'selected'){
                // Moving from one role to another...
                idx = this.selectedParticipants[fromRoleId].indexOf(participantId)
                this.selectedParticipants[fromRoleId].splice(idx,1)

                if(!Object.prototype.hasOwnProperty.call(this.selectedParticipants, roleId))
                    this.selectedParticipants[roleId]=[]
                this.selectedParticipants[roleId].push(participantId)

                idx = this.allSelectedParticipants.indexOf(participantId)
                this.allSelectedParticipants.splice(idx,1)

                this.allSelectedParticipants.push(participantId)
                
            }

            /*
            await this.movePlayerToGroup({
                activityId: this.activityId, 
                toGroupId: groupId,
                toRoleId: roleId,
                fromGroupId: fromGroupId,
                fromRoleId: fromRoleId,
                participantId,
                eventId: this.selectedTryout,
            })
            await this.resetAvailablePlayers({participantList: this.participantList, roleList: this.roleList, activityList: this.activityList})
            await this.sortAvailablePlayers({activityId: this.activityId, participants: this.participants})
            */

        },
        async save() {
            const sessions = []
            if(this.selectedSession && this.selectedSession !== "0"){
                sessions.push(this.selectedSession)
            }
            const newGroup = await this.createGroup({
                name: this.inputGroupName!==""?this.inputGroupName:this.autoName,
                comment: this.newComment,
                eventId: this.selectedTryout,
                activityId: '0',
                sessionId: this.selectedSession,
            })
            console.log(newGroup.group)

            const usedRoles = Object.keys(this.selectedParticipants)
            for(var roleIdx in usedRoles){
                const roleId = usedRoles[roleIdx]
                console.log(`RoleId: ${roleId}`)
                for(var i=0;i<this.selectedParticipants[roleId].length;i++){
                    const moveData ={
                        activityId: 0, 
                        toGroupId: newGroup.group.id,
                        toRoleId: roleId,
                        fromGroupId: 0,
                        fromRoleId: 0,
                        participantId: this.selectedParticipants[roleId][i],
                        eventId: this.selectedTryout,
                    } 
                    console.log('Moving player')
                    console.log(moveData)
                    await this.movePlayerToGroup(moveData)
                }
            }
/*
                await this.movePlayerToGroup({
                    participantId: this.selectedParticipants[i],
                    fromGroupId: 0,
                    toGroupId: 

                })
            }
                */
            this.newName=''
            this.newComment=''
            this.addGroupDialog=false
            this.$root.$emit('tryoutRefresh')

            // Set the selected participant to be this group...

            this.setSelectedParticipant(newGroup.group.id)

            this.showAddGroupDialog=false

        },
        cancel(){
            this.inputGroupName=""
            this.selectedParticipants=[]
            this.showAddGroupDialog=false;
        },
    },
    watch: {},
    async created(){

    },
    data(){
        return {
            showAddGroupDialog: false,
            inputGroupName: '',
            selectedParticipants: {},
            allSelectedParticipants:[],

        }
    }

}
</script>

<style scoped>
.group-filter-box {
    padding:10px;
    margin: 5px;
    border-style: solid;
    border-color: black;
    border-width:1px;
    border-radius: 10px;
    min-width:200px;
}

.add-group-dialog{
    height: 80vh;
    overflow-y:unset;
}
.drag-el{
    background-color: #fff;
}
.drop-zone{
    background-color: #eee;
    margin-left:auto;
    margin-right:auto;
    height: 100px;
    width:80%;
    padding: 0px;
    border: 1px solid black;
}

.selection-box {
    padding: 10px;
    border-style: dashed;
    border-width: thin;
    border-radius: 10px;
}
</style>