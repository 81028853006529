import { transtokenService } from '../_services'
import { router } from '@/_helpers'

const dataService = transtokenService

function initialiseState(){
    return {
        status: {},
        data: {},
        error: null,
    }
}

const state = initialiseState()

const actions = {
    purgeData({commit}){
        commit('purgeData')
    },
    async fetch({commit}, token) {
        try{
            commit('loadRequest')
            const response = await dataService.fetch(token)
            commit('loadSuccess', response)
        } catch(error) {
            commit('loadFailure', error)
        }
    },
    //eslint-disable-next-line
    completeTransaction({commit}, id){
        router.push('/clubhouse/'+id) 
    }
}

const mutations = {
purgeData(state){
    const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }
    },
    loadRequest(state) {
        state.status={loading: true}
        state.error=null
    },
    loadSuccess(state, data) {
        state.status = {}
        state.data = data
    },
    loadFailure(state, error) {
        state.status = {}
        state.error = error
    },


}

export const transtokenStore = {
    namespaced: true,
    state,
    actions,
    mutations
}