<template>
    <div class="profile-image" :height="size" >
        <div v-if="imageAllowed" class="image-allowed">
            <v-img  v-if="profileImage" class="rounded-lg" :src="profileImage" :max-height="size" width='auto' :max-width="size"/>
            <v-img v-else
                :src="require('../assets/no-image-available.svg')"
                contain
                :height="size"
                :max-height="size"
                :max-width="size"
            />

        </div>
        <div v-else class="no-image">
            <v-img
                :src="require('../assets/no-image-available.svg')"
                contain
                :height="size"
                :max-height="size"
                :max-width="size"
            />
        </div>
        

<!-- 
        <div v-if="!profileImage" class="no-image">
            <v-icon class="invert-icon" :size="size">mdi-account</v-icon>
            <div v-if="this.imageSize > 100" class="overlay">Image Not Available </div>
        </div>
        <div v-else-if="imageAllowed" class="image-allowed">
            <v-img  class="rounded-lg" :src="profileImage" :max-height="size" width='auto' :max-width="size"/>
        </div>
        <div v-else class="no-image">
            <v-icon class="invert-icon" :size="size">mdi-account</v-icon>
            <div v-if="this.imageSize > 100" class="overlay">Image Not Available </div>
        </div>
-->
    </div>
</template>

<script>
import {mapState} from 'vuex'
 

export default{
    props: ['participantId', 'size'],
    components: {},
    computed: {
        ...mapState({
            participants: state => state.participantStore.data,
            participant: state => state.participantStore.data,
        }),
        imageAllowed: function(){
            if(this.participantId && this.participantId !== 0){
                if(this.participants[this.participantId].junior){
                    return this.participants[this.participantId].imageConsent
                } else {                    
                    return true
                }
            }
            return false
        },
        profileImage(){
            if(Object.prototype.hasOwnProperty.call(this.participants, this.participantId)){
                return this.participants[this.participantId].profileImage
            }
            else    
                return this.participant.profileImage
        },
        imageSize(){
            if(this.size.slice(-2) === "px"){
                return this.size.slice(0,-2)
            }
            return 0
        },

    },
    methods: {},
    created(){
    },
    data(){
        return {

        }
    }
}</script>

<style scoped >

.image-allowed {
    margin-left:auto;
    margin-right: auto;
    border-radius: 15px;
    background:black;
    width: fit-content;

}
.profile-image {
    position: relative;
    text-align: center;
    color: white;
}

.overlay {
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 600;
}

.invert-icon {
    color:gainsboro;
}

.no-image{
    background-color: black;
    width: fit-content;
    border-radius:5px;
}

</style>