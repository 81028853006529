<template>
    
   <div>
        <div cols="12" @click="dialog = true">
            <Bar
                :chart-options="chartSmallOptions"
                :chart-data="chartData"
                :chart-id="chartId"
                :dataset-id-key="datasetIdKey"
                :plugins="plugins"
                :css-classes="cssClasses"
                :styles="styles"
                :width="width"
                :height="height"
            />
        </div> 
        <v-dialog v-model="dialog" persistent width="80%">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Skill Averages for <br/>{{participants[participantId].firstName}} {{participants[participantId].lastName}} </span>
                </v-card-title>
                <v-card-text>
                    <Bar
                        :chart-options="chartDialogOptions"
                        :chart-data="chartData"
                        :chart-id="chartId"
                        :dataset-id-key="datasetIdKey"
                        :plugins="plugins"
                        :css-classes="cssClasses"
                        :styles="styles"
                        :width="width"
                        :height="height"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="blue darken-1" text @click="dialog=false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
   </div>
</template>

<script>
import {mapState , mapActions} from 'vuex'
import { Bar } from 'vue-chartjs/legacy'
import ChartDataLabels from 'chartjs-plugin-datalabels'

import {
 Chart as ChartJS,
 Title,
 Tooltip,
 Legend,
 BarElement,
 CategoryScale,
 LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
ChartJS.register(ChartDataLabels)

export default {
    props: {
        participantId: {
            type: String
        },
        chartId: {
        type: String,
        default: 'bar-chart'
        },
        datasetIdKey: {
        type: String,
        default: 'label'
        },
        width: {
        type: Number,
        default: 200
        },
        height: {
        type: Number,
        default: 50
        },
        cssClasses: {
        default: '',
        type: String
        },
        styles: {
            type: Object,
            default: () => {}
        },
        plugins: {
            type: Array,
            default: () => []
        },
        labelsOn: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        Bar
    },
    computed: {
        ...mapState({
            participants:       state => state.participantStore.data,
            participantList:    state => state.participantStore.list,
            participantStatus:  state => state.participantStore.status,
 
            teamRecommendations:        state => state.teamrecommendationStore.data,
            teamRecommendationList:     state => state.teamrecommendationStore.list,
            teamRecommendationStatus:   state => state.teamrecommendationStore.status,
            recommendationCount:        state => state.teamrecommendationStore.recommendationCount,
            recommendationsByTeamAndPlayer: state => state.teamrecommendationStore.recommendationsByTeamAndPlayer,

            criteria:          state => state.criterionStore.data,
            criterionList:     state => state.criterionStore.list,

            playerskill: state => state.playerskillStore.data,
            playerskillStatus: state => state.playerskillStore.status,
 
            staffList:                  state => state.staffStore.list,

        }),
         chartData() {
            var labels = []
            var data = []
            var backgroundColor = []

            for(var idx in this.criterionList) {
                if(this.criteria[this.criterionList[idx]].context.includes('individual')){
                    const label = this.criteria[this.criterionList[idx]].description
                    labels.push(label)
                    const val = this.playerskill[this.participantId].criteria[this.criterionList[idx]].average
                    data.push(Math.round(val*10)/10)
                    var color=''
                    if(val === 5) color='lime'
                    else if(val >= 4) color='olive'
                    else if(val >= 3) color='gold'
                    else if(val >= 2) color='lightsalmon'
                    else if(val >= 1) color='red'
                    else color='grey'
                    backgroundColor.push(color)

                }

            }
            const dataObj =  {
                labels,
                datasets: [
                    {
                        label: 'Skill Average',
                        backgroundColor,
                        data
                    }
                ]
            }
            return dataObj
         },
        selectorCount() {
             return this.staffList.length
         },
    },
    created(){
    },
    methods: {
        ...mapActions('playerskillStore', {
            updateScore: 'updateScore'
        })
    },
    data() {
         return {
            dialog: false,
            /*
            chartOptions: {
                elements: {
                    bar: {
                        borderRadius: 5
                    }
                },
                plugins: {
                    title: {
                        display: false
                    },
                    legend: {
                        display: false
                    },
                    datalabels: {
                        color: 'black',
                        display: function(context){
                            const index =  context.dataIndex
                            return context.dataset.data[index]>0
                        }
                    }
                },
                scales: {
                    x: {
                        display: this.labelsOn,
                    },
                    y: {
                        display: this.labelsOn,
                        min: 0,
                        max: 5,
                    },
                },
                responsive: true,
                maintainAspectRatio: false
            },
            */
            chartSmallOptions: {
                elements: {
                    bar: {
                        borderRadius: 5
                    }
                },
                plugins: {
                    title: {
                        display: false
                    },
                    legend: {
                        labels:false,
                        display: false
                    },
                    datalabels: {
                        display:false
                    }
                },
                scales: {
                    y: {
                        min: 0,
                        max:5,
                        labels:{
                            font: {
                                size:0
                            }
                        }
                    },
                    x: {
                        ticks: {
                            font: {
                                size: 0
                            }
                        }
                    }
                },
                responsive: true,
                maintainAspectRatio: false
            },
            chartLargeOptions: {
                elements: {
                    bar: {
                        borderRadius: 5
                    }
                },
                plugins: {
                    title: {
                        display: false
                    },
                    legend: {
                        display: false
                    },
                    datalabels: {
                        display:false
                    }
                },
                scales: {
                    y: {
                        min: 0,
                        max:5,
                        labels:{
                            font: {
                                size:0
                            }
                        }
                    },
                    x: {
                        ticks: {
                            font: {
                                size: 0
                            }
                        }
                    }
                },
                responsive: true,
                maintainAspectRatio: false
            },
            chartDialogOptions: {
                elements: {
                    bar: {
                        borderRadius: 5
                    }
                },
                plugins: {
                    title: {
                        display: false
                    },
                    legend: {
                        display: true
                    },
                    datalabels: {
                        display:true
                    }
                },
                scales: {
                    y: {
                        min: 0,
                        max:5,
                        labels:{
                            font: {
                                size:0
                            }
                        }
                    },
                    x: {
                        ticks: {
                            font: {
                                size: 10
                            }
                        }
                    }
                },
                responsive: true,
                maintainAspectRatio: false
            }
     
        }
    },
}

</script>

<style>

.col {
    margin-top: auto
}


</style>