<template>
    <loading v-if="loading"/>
    <div v-else>
        <v-btn v-on:click="buildAoA"> Export Tryout Data</v-btn> 
    </div>
</template>

<script>
import * as XLSX from 'xlsx'

import { mapState, mapActions } from 'vuex'

import Loading from '@/components/loading'

export default {
    props: {
        tryoutId: String
    },
    data () {
        return {
            aoa: {
                data: [],
                cols: []
            }
        };
    },
    components: {
        Loading,
    },
    computed: {
        ...mapState({
            questions: state => state.appquestionStore.data,
            questionIds: state => state.appquestionStore.list,
            qState : state => state.appquestionStore.status,
            responses: state => state.qresponseStore.data,
            responseIds: state => state.qresponseStore.list,
            rState : state => state.qresponseStore.status,
            participants: state => state.participantStore.data,
            participantList: state => state.participantStore.list,
            pState: state => state.participantStore.status,
            criteria: state => state.criterionStore.data,
            criterionList: state => state.criterionStore.list,
            staff: state => state.staffStore.data,
            staffList: state => state.staffStore.list,
            playerSkills: state => state.playerskillStore.allStaffData,
            playerAttributes: state => state.playerattributeStore.data,
            comments: state => state.commentStore.allStaffData,
            commentList: state => state.commentStore.list,
            attributes: state => state.attributeStore.data,
            attributeList: state => state.attributeStore.list,


        }),
        loading: function() {
            return (this.qState.loading || this.rState.loading || this.pState.loading)        
        },
        
    },
    methods: {
        ...mapActions('appquestionStore', {
                findAllQuestions: 'loadAllQuestionsForEvent'
        }),
        ...mapActions('qresponseStore', {
                findAllResponses: 'findAllForEvent'
        }),
        ...mapActions('participantStore', {
                findAllParticipants: 'findAllForEvent'
        }),
        ...mapActions('criterionStore', {
            findAllCriterion : 'findAllForEvent'
        }),
        ...mapActions('staffStore', {
            findAllStaff : "findAllForEvent"
        }),
        ...mapActions('playerskillStore', {
            findAllPlayerSkillScores: 'findAllForEvent',
            clearSkillData: 'clearAllStaffData'
        }),
        ...mapActions('commentStore', {
            findAllComments: 'findAllForEvent'
        }),
        ...mapActions('playerattributeStore', {
            findAllAttributeValues: 'findAllForEvent',
            clearAttributeData: 'clearAllStaffData'
        }),
        ...mapActions('attributeStore', {
            findAllAttributes : 'findAllForEvent'
        }),

        buildAoA() {
            this.aoa.data=[]
            var row = []
            this.aoa.cols=[]
            var merge=[]
            index=2
            // Top Row (Criterion NAmes)

            row.push('');this.aoa.cols.push({name: "A", key: 0})
            row.push('');this.aoa.cols.push({name: "B", key: 1})
            row.push('');this.aoa.cols.push({name: "C", key: 2})
            // HOw many scores are there for each criterion?
            for(var i=0;i<this.questionIds.length;i++) {
                index++
                row.push('')
                const colName = (index<27)? String.fromCharCode(65+index) : 'A'+String.fromCharCode(65+index-27)
                this.aoa.cols.push({name: colName, key: index})
            }
            
            for(var idx in this.criterionList){
                const start=index+1

                for(var idx2 in this.staffList){
                    row.push(this.criteria[this.criterionList[idx]].description)
                    index++
                    const colName = (index<27)? String.fromCharCode(65+index) : 'A'+String.fromCharCode(65+index-27)
                    this.aoa.cols.push({name: colName, key: index})
                }
                index++
                row.push(this.criteria[this.criterionList[idx]].description)
                const colName = (index<27)? String.fromCharCode(65+index) : 'A'+String.fromCharCode(65+index-27)
                this.aoa.cols.push({name: colName, key: index})
                const end = index
                merge.push({s:{r:0,c:start},e:{r:0, c: end}})
            }
            this.aoa.data.push(row)


            // Second row now...
            row=[]
            row.push('First Name');
            row.push('Last Name'); 
            row.push('Player ID'); 

            var index=2;
            for(var id in this.questionIds) {
                index++;
                const colName = (index<27)? String.fromCharCode(65+index) : 'A'+String.fromCharCode(65+index-27)
                // This is a sorted question array, so build the label row

                row.push(this.questions[this.questionIds[id]].question)
                this.aoa.cols.push({name: colName, key: index})

            }
            for(idx in this.criterionList){
                for(idx2 in this.staffList){
                    index++
                    row.push(this.staff[this.staffList[idx2]].firstName + ' ' + this.staff[this.staffList[idx2]].lastName)
                }
                row.push('Average')
                index++
            }
            row.push('Top60 Avg')
            row.push('Confidence')
            this.aoa.data.push(row)

    // Now for the data
            for(var id2 in this.participantList) {
                const targetPartId = this.participantList[id2]
                row=[]
                var datapoints = 0
                const avgAcc=[]
                const top60IncludedNumCriteria = Math.floor(this.criterionList.length*0.6)
                row.push(this.participants[targetPartId].firstName)
                row.push(this.participants[targetPartId].lastName)
                row.push(`${this.participants[targetPartId].participantNumber}`)
                // get only the responses for this participant
                const partResponses = this.responseIds.filter((responseId) => {
                    return this.responses[responseId].data.participantId === targetPartId
                })
                for(var qid in this.questionIds) {
                    const targetQuestionId = this.questionIds[qid]
                    const answer = partResponses.filter((responseId) => {
                        return this.responses[responseId].data.questionId === targetQuestionId
                    })
                    if (answer.length > 1){
                        console.error(this.participants[targetPartId].participantNumber)
                        console.error('****** More than one where there should only be 1')
                    } 
                    if(answer.length===1) {
                        row.push(this.responses[answer[0]].data.result)
                    } else {
                        row.push('')
                    }
                }
                for (idx in this.criterionList) {
                    var acc=0
                    var count=0
                    for(idx2 in this.staffList) {
                        const participantId = this.participantList[id2]
                        const criterionId = this.criterionList[idx]
                        const staffId = this.staffList[idx2]
                        var score=0
                        if (this.playerSkills[participantId]){
                            if(this.playerSkills[participantId].criteria[criterionId]){
                                if( this.playerSkills[participantId].criteria[criterionId].staff[staffId]) {
                                    score=this.playerSkills[participantId].criteria[criterionId].staff[staffId].score
                                }
                            }
                        } 
                        row.push(score>0?score:'')
                        acc+=score
                        if(score >0) count++
                    }
                    const avg = count>0?(acc/count):0
                    //row.push(avg>0?avg:'')
                    row.push(avg)
                    avgAcc.push(avg)
                    datapoints += count

                }

                //Add the analytics 
                var top60 = avgAcc.sort((a,b)=> b-a).slice(0,top60IncludedNumCriteria);
                var top60Sum = 0
                const confidence=(datapoints/(this.criterionList.length*this.staffList.length/2)*100).toFixed(1)
                for(idx in top60) top60Sum+=top60[idx]
                row.push(top60Sum)
                row.push(confidence)

                this.aoa.data.push(row)
            }
//eslint-disable-next-line
            var worksheet = XLSX.utils.aoa_to_sheet(this.aoa.data)
            worksheet["!merges"] = merge

//-----------
            // Now build the comments worksheet

            this.aoa.data=[]
            row = []
            this.aoa.cols=[]
            merge=[] 
            index=2

            row.push('First name');this.aoa.cols.push({name: "A", key: 0})
            row.push('Last name');this.aoa.cols.push({name: "B", key: 1})
            row.push('Player #');this.aoa.cols.push({name: "c", key: 2})


            for(idx2 in this.staffList){
                index++
                row.push(this.staff[this.staffList[idx2]].firstName + ' ' + this.staff[this.staffList[idx2]].lastName)
            }


            this.aoa.data.push(row)
            for(idx in this.participantList) {
                const participantId = this.participantList[idx]
                row=[]
                row.push(this.participants[participantId].firstName)
                row.push(this.participants[participantId].lastName)
                row.push(`${this.participants[participantId].participantNumber}`)

                for(idx2 in this.staffList){
                    const staffId = this.staffList[idx2]
                    var comment=""
                    if(this.comments[participantId] && this.comments[participantId].staff[staffId]) {
                        comment=this.comments[participantId].staff[staffId].comment
                    }
                    row.push(comment)
                }
                this.aoa.data.push(row)

            }
            var commentWorksheet = XLSX.utils.aoa_to_sheet(this.aoa.data)

            this.aoa.data=[]
            row = []
            this.aoa.cols=[]
            merge=[] 
            index=2

            row.push('First name');this.aoa.cols.push({name: "A", key: 0})
            row.push('Last name');this.aoa.cols.push({name: "B", key: 1})
            row.push('Player #');this.aoa.cols.push({name: "c", key: 2})

            for(idx in this.attributeList){
                index++
                row.push(this.attributes[this.attributeList[idx]].title)
                const colName = (index<27)? String.fromCharCode(65+index) : 'A'+String.fromCharCode(65+index-27)
                this.aoa.cols.push({name: colName, key: index})
            }

//-----------

            this.aoa.data.push(row)

            for(idx in this.participantList) {
                const participantId = this.participantList[idx]
                row=[]
                row.push(this.participants[participantId].firstName)
                row.push(this.participants[participantId].lastName)
                row.push(`${this.participants[participantId].participantNumber}`)

                


                for(idx2 in this.attributeList){
                    const attributeId = [this.attributeList[idx2]]
                    var attribute=""
                    
                    if(this.playerAttributes[participantId] && this.playerAttributes[participantId].attributes[attributeId]) {
                        attribute=this.playerAttributes[participantId].attributes[attributeId].value
                    }
                    
                    row.push(attribute)
                }
                this.aoa.data.push(row)

            }
            var attributeWorksheet = XLSX.utils.aoa_to_sheet(this.aoa.data)

            var new_workbook = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(new_workbook, worksheet, "Summary Data")
            XLSX.utils.book_append_sheet(new_workbook, commentWorksheet, "Comments")
            XLSX.utils.book_append_sheet(new_workbook, attributeWorksheet, "Attributes")
            XLSX.writeFile(new_workbook, "participant_data.xlsx")
        }
    },
    async created() {
        await this.findAllQuestions({eventId: this.tryoutId})
        await this.findAllParticipants(this.tryoutId)
        await this.findAllResponses(this.tryoutId)
        await this.findAllCriterion(this.tryoutId)
        await this.findAllStaff({eventId: this.tryoutId})
        await this.findAllPlayerSkillScores({eventId: this.tryoutId})
        await this.findAllComments({eventId: this.tryoutId})
        await this.findAllAttributeValues({eventId: this.tryoutId})
        await this.findAllAttributes({eventId: this.tryoutId})
    }
}
</script>

<style>

</style>