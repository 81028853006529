var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var reset = ref.reset;
return [_c('v-card',[(_vm.devStreamId === '')?_c('v-card-title',[_vm._v(" Edit Development Programme Name: "+_vm._s(_vm.teamName)+" ")]):_c('v-card-title',[_vm._v(" New Development Programme ")]),_c('v-card-text',[_c('v-row',[_c('div',{staticClass:"card-title"})]),_c('v-row',[_c('v-col',{staticStyle:{"margin-top":"10px","margin-bottom":"auto"},attrs:{"cols":"3"}},[_c('v-img',{attrs:{"src":_vm.clubs[_vm.selectedClub].logo,"max-width":"200px"}})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"devstreamName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Development Program Name","hideDetails":"auto"},model:{value:(_vm.newTryout.title),callback:function ($$v) {_vm.$set(_vm.newTryout, "title", $$v)},expression:"newTryout.title"}}),_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Sport","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.sportsArray,"label":"Sport","chips":""},model:{value:(_vm.newTryout.sportId),callback:function ($$v) {_vm.$set(_vm.newTryout, "sportId", $$v)},expression:"newTryout.sportId"}}),_c('div',{staticClass:"field-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"devstreamDescription"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Description","hideDetails":"auto"},model:{value:(_vm.newTryout.description),callback:function ($$v) {_vm.$set(_vm.newTryout, "description", $$v)},expression:"newTryout.description"}}),_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,true)}),_c('div',{staticClass:"dates"},[_c('div',{staticClass:"date"},[_c('DateCombo',{attrs:{"title":"Start Date"},model:{value:(_vm.newTryout.startDate),callback:function ($$v) {_vm.$set(_vm.newTryout, "startDate", $$v)},expression:"newTryout.startDate"}})],1),_c('div',{staticClass:"date"},[_c('DateCombo',{attrs:{"title":"End Date: "},model:{value:(_vm.newTryout.endDate),callback:function ($$v) {_vm.$set(_vm.newTryout, "endDate", $$v)},expression:"newTryout.endDate"}})],1)]),_c('ValidationProvider',{attrs:{"name":"devstreamPronoun","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"What do you call your participants? (e.g. players, referees, etc)","hideDetails":"auto"},model:{value:(_vm.newTryout.participantPronoun),callback:function ($$v) {_vm.$set(_vm.newTryout, "participantPronoun", $$v)},expression:"newTryout.participantPronoun"}}),_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"devstreamNoun","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"How would you describe your program? (e.g. Referee Development, etc)","hideDetails":"auto"},model:{value:(_vm.newTryout.eventNoun),callback:function ($$v) {_vm.$set(_vm.newTryout, "eventNoun", $$v)},expression:"newTryout.eventNoun"}}),_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"devstreamEpisodeForm","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"What do you call your evaluation form? (e.g. referee evaluation form, athlete feedback form,etc)","hideDetails":"auto"},model:{value:(_vm.newTryout.episodeFormName),callback:function ($$v) {_vm.$set(_vm.newTryout, "episodeFormName", $$v)},expression:"newTryout.episodeFormName"}}),_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.closeDevStreamEditor(reset)}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"disabled":invalid,"color":"primary","text":""},on:{"click":function($event){return _vm.saveDevStream(reset)}}},[_vm._v("Save")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }