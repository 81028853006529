<template>
    <v-dialog v-model="imageUploadDialog">
        <template v-slot:activator="{on,attrs}">
            <div>
            <preview v-if="preview && imageObject" :image="imageObject.image" :width="100" :height="100" :coordinates="imageObject.coordinates"/>
            </div>
            <v-btn 
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                :text="text"
            >
                {{label}}
            </v-btn>
        </template>
        <v-card>
            <v-overlay
            absolute
            :value="loading"
            >
                <v-progress-circular
                    :size="70"
                    :width="7"
                    color="purple"
                    indeterminate
                />
            </v-overlay>
            <v-card-title>
                Image Upload
            </v-card-title>
            <v-card-text>
                <cropper v-if="showCropper"
                    ref="cropper"
                    :transitions="true"
                    :debounce="false"
                    class="cropper"
                    :src="image.dataUrl" 

                    :stencil-props="{
                        //aspectRatio:10/12,
                    }"
                    @change="onImageChange"
                />
                <v-skeleton-loader v-else type="image"></v-skeleton-loader>
                <v-checkbox v-if="consentRequired" class="consent-check" small dense v-model="consentGiven">
                    <template v-slot:label>
                        <span style="font-size: 10px">I consent to my image being used by coaches for the purpose of trials conducted using CoachSight.</span>
                    </template>
                </v-checkbox>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn
                    :disabled="loading"
                    text
                    x-small
                    color="primary"
                    @click="$refs.uploadinput.click()"
                > 
                    {{uploadLabel}}
                </v-btn>
                <v-spacer/>
                <v-btn
                    :disabled="loading || !image"
                    text
                    x-small
                    color="primary" 
                    @click="rotateImage"
                >
                    Rotate
                </v-btn>
                <v-spacer/>
                <v-btn
                    :disabled="loading || !image || (consentRequired && !consentGiven)"
                    text
                    x-small
                    color="primary" 
                    @click="submitImage"
                >
                    Submit
                </v-btn>
                <v-spacer/>

            </v-card-actions>
        </v-card>
        <div class="no-boundaries" style="display:none">
                <image-uploader 
                    :debug="0"
                    :maxWidth="maxWidth"
                    :maxSize="imageSize"
                    :quality="0.7"
                    :autoRotate=false
                    :preview="true"
                    outputFormat="verbose"
                    :className="['file-input', 'image-upload', { 'file-input--loaded' : hasImage }]"
                    capture="user"
                    accept="image/*"
                    @input="setImage"
                    @onUpload="startImageResize"
                    @onComplete="endImageResize"
                >
                    <label for="fileInput" slot="upload-label">
                        <span ref="uploadinput" class="button">{{ hasImage ? 'Replace' : 'Upload' }}</span>
                    </label>
                </image-uploader>
                    <span class="button" @click="rotateImage">Rotate</span>
                    <span class="button" @click="submitImage">Submit</span>

        </div>
    </v-dialog>
</template>

<script>
import ImageUploader from 'vue-image-upload-resize'
import {Cropper, Preview} from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import {mapState,mapActions } from 'vuex'

export default {
    props: {
        cropper: {
            type: Boolean,
            default: false,
        },
        preview: {
            type: Boolean,
            default: false,
        },
        imageSize: {
            default: 0.5,
        },
        maxWidth: {
            default: 512
        },
        type: {
            default: 'Club Background',
        },
        text: {
            default: false,
        },
        label: {
            default: 'Change Image'
        },
        delaySave: {
            type: Boolean,
            default: false,
        },
        consentRequired: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Cropper,
        ImageUploader,
        Preview,
    },
    computed: {

        ...mapState({

            selectedClub:   state => state.navigatorStore.selectedClub,
            account: state => state.account,
            user:   state => state.account.user.details,
            seasons: state => state.seasonStore.data,
            seasonList: state => state.seasonStore.list,
            selectedSeason: state => state.navigatorStore.selectedSeason,
        }),
        showCropper(){
            if(!this.image) return false
            return this.cropper && Object.prototype.hasOwnProperty.call(this.image, 'dataUrl')
        },
        fieldRules() {
            return { 
                size: value => ! value || value.size < 1500000 || 'Profile Image must be < 500kB',
            }
        },
        uploadLabel(){
            if(this.image) return "Replace"
            return "Upload"
        },
        previewImage(){
            return this.$refs.cropper.getResult()
        }
    },
    watch:{
    },
    methods: {
        ...mapActions('account', {
            updateUserProp: 'propertyUpdate',
            updateUser: 'update'
        }),
        ...mapActions('clubStore', {
            updateClubProp: 'propertyUpdate',
            updateClub: 'update',
            archiveClub: 'archive',
        }),
        ...mapActions('seasonStore', {
            updateSeasonProp: 'propertyUpdate',
            updateSeason: 'update'
        }),
        rotateImage() {
            this.$refs.cropper.rotate(90);
        },
        setImage(file) {
            this.hasImage=true
            this.image = file
        },
        startImageResize(){

        },
        endImageResize(){

        },
        onImageChange({ coordinates, image }) {
            this.imageObject = {
                coordinates,
                image
            };
        },
        async urltoFile(url, filename, mimeType){
            if (url.startsWith('data:')) {
                var arr = url.split(','),
                    mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[arr.length - 1]), 
                    n = bstr.length, 
                    u8arr = new Uint8Array(n);
                while(n--){
                    u8arr[n] = bstr.charCodeAt(n);
                }
                var file = new File([u8arr], filename, {type:mime || mimeType});
                return Promise.resolve(file);
            }
            return fetch(url)
                .then(res => res.arrayBuffer())
                .then(buf => new File([buf], filename,{type:mimeType}));
        },
        async dataUrlToFile(dataUrl, fileName) {
        
            const res = await fetch(dataUrl);
            const blob = await res.blob();
            return new File([blob], fileName, { type: 'image/png' });
        },
        async submitImage(){
            if(this.delaySave) {
                this.imageUploadDialog=false
                return
            }
            await this.submitPhase2()
        },
        async submitPhase2(name=null){

            if(!this.imageObject.image) return

            const apiUrl = process.env.VUE_APP_CSAPI
            const localUser = JSON.parse(localStorage.getItem('user'))
            const url = new URL(`${apiUrl}/aws/uploadPath`)
            const filename = this.image.info.name
            
            var entity='club'
            var field='logo' 
            var id=this.selectedClub
            var uploadClass = 'Upload Club Logo'
            var idField='clubId'
            var updateEntityMethod='updateClub'
            var updatePropMethod='updateClubProp'
            var successMessage='Your club logo has been updated'

            switch(this.type){
                case 'Club Background':
                    entity='club'
                    field='pageBackgroundImage'
                    id=this.selectedClub
                    uploadClass='Upload Club Background'
                    idField='clubId'
                    updatePropMethod='updateClubProp'
                    updateEntityMethod='updateClub'
                    successMessage='Your club page background has been updated'
                    break
                case 'User Image':
                    entity='user'
                    field='profileImage'
                    id=this.user.id
                    uploadClass="Upload User Image"
                    idField='userId'
                    updatePropMethod='updateUserProp'
                    updateEntityMethod='updateUser'
                    successMessage='Your profile image has been updated'
                    break
                case 'Upload Season Logo':
                    entity='season'
                    field='logo'
                    id=this.selectedSeason
                    uploadClass='Upload Season Logo'
                    idField='seasonId'
                    updatePropMethod='updateSeasonProp'
                    updateEntityMethod='updateSeason'
                    successMessage='Your season logo has been updated'
                    break
            }

            var ext = filename.substring(filename.lastIndexOf('.')+1, filename.length)
            switch(ext){
                case 'jpg': 
                    ext='jpeg'
                    break
            }


            if(name !== null) {
                id=name
            }

            const newFilename = `${id}.${ext}`

            url.searchParams.append('filename', newFilename )
            url.searchParams.append('type', uploadClass)
            url.searchParams.append(idField, id)

            // Now load the modified data from the cropper.

            //eslint-disable-next-line
            const { coordinates, image, visibleArea, canvas } = this.$refs.cropper.getResult();
            this.file = await this.dataUrlToFile(canvas.toDataURL(), newFilename)

            const signedURL = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type':'application/json',
                        'Authorization':'Bearer '+localUser.token,
                    }
                })
                .then(response => {
                    return response.json()
                }
            )
            this.loading=true
            const headers = {
                'Origin':'https://www.coachsight.net',
                'Content-Type': 'image/'+ext,
            }
            const result = await fetch(signedURL.uploadURL, {
                method: 'PUT',
                headers: headers,
                body: this.file,
                
            }).then(response => {
                return response
            })
            if (result.status=="200") {
                const fileUrl = signedURL.uploadURL.split('?')[0]
                await this[updatePropMethod]({field: field, id: name?name:id, value: fileUrl }) 
                const updateData={}
                updateData[entity]={}
                updateData[entity]['id']=name?name:id
                updateData[entity][field]= fileUrl
                await this[updateEntityMethod](updateData)
                this.$toast.success(successMessage)
            } else {
                this.$toast.error('Your file upload did not succeed. Pleasse try again or contact support if the problem persists')
            }
            this.loading=false
            this.imageUploadDialog=false
            this.$root.$emit('imageUploaded')
        }

    },
    created(){
    },
    mounted(){
        this.$root.$on('image-upload-save', async (name)=> {
            var self=this
            await self.submitPhase2(name)
        })
    },
    data(){
        return {
            consentGiven: false,
            image: null,
            hasImage: false,
            imageUploadDialog: false,
            base64: '',
            file: null, 
            loading: false,
            imageObject: {
				coordinates: null,
				image: null
			}
        }
    }
}
</script>

<style>
    .image-upload {
        display:none;
    }

</style>
<style scoped>

.cropper {
    height: 400px;
    width: 400px;
    background: #DDD;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 17px;
}

.button {
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  background: #3fb37f;
  cursor: pointer;
  transition: background 0.5s;
  font-family: Open Sans, Arial;
  margin: 0 10px;
}

.button:hover {
  background: #38d890;
}

.button input {
  display: none;
}
.upload-example-cropper {
  border: solid 1px #EEE;
  min-height: 300px;
  width: 100%;
}


.consent-check .v-label {
    font-size: 10px !important;
    line-height: 1;
    min-height: 8px;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.v-input .v-label {
    height: 20px;
    line-height: 10px;
    letter-spacing: normal;
}

</style>