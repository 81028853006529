<template>
    <v-card v-if="!loading">
        <v-card-text>
            <span class="sessiontitle">Registration Questions</span>
            You are now registered for 


        </v-card-text>

    </v-card>

</template>
<script>
import { mapActions, mapState } from 'vuex';

import { router } from '@/_helpers'

export default{
    props: [ 'token','type' ],
    components: {},
    computed: {
        ...mapState({
            transtoken: state => state.transtokenStore.data,
            transtokenError: state => state.transtokenStore.error,
            account:    state => state.account,
        }),
        loading(){
            return false
                || this.account.loading
        }
    },
    methods: {
        ...mapActions('transtokenStore',{
            fetchTransactionInfo: 'fetch',
            completeTransaction: 'completeTransaction'

        }),
        ...mapActions('tryoutStore', {
            loadTryout: 'loadTryout',
        }),
        ...mapActions('tryoutregStore', {
            setStep: 'setStep',
        }),
        ...mapActions('navigatorStore', {
            selectClub: 'selectClub',
            selectClubPane: 'selectPane',
            selectSeason: 'selectSeason',
            selectTryout: 'selectTryout',
            selectBankState: 'selectBankState',
            selectNav: 'selectNav'
        }),
    },
    async created() {
        // load the transation data
// We may need to reload the user now...

        /*
        if(this.account.user===null){
            //const localUser = JSON.parse(localStorage.getItem('user'))
        } else {

        }
        */

        await this.fetchTransactionInfo(this.token)
        // If the token fetch failed... flash up an error msg and go to the root page...
        if(this.transtokenError){

            this.$store.dispatch('alert/warn', 'Your payment succeeded, but something went wrong with the app. Please reload the page.', {root:true})
            //router.push('/')
        }

        // Reload the participant data...
//        await this.findParticipant({userId: this.account.user.details.id, eventId: this.transtoken.eventId})

        // setup the club, season and pane data to send the app straight back to the closing screen

/*
        this.selectClub(this.transtoken.clubId)
        this.selectTryout(this.transtoken.eventId)
        this.selectSeason(this.transtoken.seasonId)
        this.selectClubPane('TryoutRegistration')
        this.selectNav('Season')
        this.selectBankState('paid')
        */
        await this.completeTransaction(this.transtoken.clubId)
        router.push(`/clubhouse/${this.transtoken.clubId}`)
    },
    data(){
        return {
            router: router,


        }
    }
}

</script>
