<template>
    <div style="text-align:left;padding: 0px 10px 0px 10px">
        <v-row v-if="false">
            <v-spacer/>
                <v-col cols="2" style="text-align:right">
                    <v-btn @click.native.stop 
                        v-if="changes" 
                        x-small 
                        v-on:click="saveDetails" 
                        depressed 
                        color="primary" 
                        class="save-btn"
                    >
                        Save
                    </v-btn>
                </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div v-if="tryouts[selectedTryout].feeExemption" style="margin-top:10px;text-align:left">
                    <v-row>
                        <span style="font-size:small">
                            Tyis tryout has been made exempt from player fees
                        </span>
                    </v-row>
                </div>
            </v-col>
        </v-row>
        <v-row style="margin-top:10px;text-align:left">
            <span style="font-size:small">
                You may set up to three fees which will be presented to athletes to select from during the registration process. Remember that each transaction will
                be charged an additional transaction and/or service fee to include banking and platform fees.
                <v-dialog v-model="showFees">
                    <template v-slot:activator="{on,attrs}">
                        <v-btn icon color="grey" v-on="on" v-bind="attrs" x-small>
                            <v-icon>mdi-information</v-icon>
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>Fees</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col class="text-left">
                                    Current fees are:
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="2" class="text-left" style="margin-top:0px;padding-top:0px">
                                    Trial Fees:
                                </v-col>
                                <v-col cols="5" class="text-left" style="margin-top:0px;padding-top:0px">
                                    Charged to every participant in a trial.
                                </v-col>
                                <v-col class="text-left" style="margin-top:0px;padding-top:0px">
                                    <b>Transaction Fee: </b> {{ (globalSettings.baseFee/100).toLocaleString(tryouts[selectedTryout].localLanguage, {style: 'currency', currency: tryouts[selectedTryout].localCurrency}) }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-divider/>
                            </v-row>
                            <v-row>
                                <v-col cols="2" class="text-left" style="margin-top:0px;padding-top:0px">
                                    Offer Fees
                                </v-col>
                                <v-col cols="5" class="text-left" style="margin-top:0px;padding-top:0px">
                                    Charged to every participant who accepts an offer where payment on acceptance is required
                                </v-col>
                                <v-col class="text-left" style="margin-top:0px;padding-top:0px">
                                <b>Transaction Fee:</b> {{ (globalSettings.baseFee/100).toLocaleString(tryouts[selectedTryout].localLanguage, {style: 'currency', currency: tryouts[selectedTryout].localCurrency}) }}   - plus -
                                <br><b>Service Fee:</b> {{ (globalSettings.vasFee/100)}}% of the offer fee

                                </v-col>

                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-dialog> 
            </span>
        </v-row>
        <v-row>
            <span style="font-size:small;color:red">Your club will receive the amount in the "Value" column</span>
        </v-row><v-row>
            <span style="font-size:small">
                The total fee payable by athletes is indicated in the table below as the "Athlete Charge" column.
            </span>
        </v-row>
        <v-row>
            <v-dialog v-model="editDialog" width="60%" >
                <v-card>
                    <v-card-title>Edit {{ fees[feeIndex].name }}</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col col="12">
                                <v-text-field v-model="editLabel" label="Fee label"/>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="text-left">
                                Price
                            </v-col>
                        </v-row>
                        <v-row style="margin-top:-20px">
                            <v-col cols="12" class="text-left">
                                <money v-model="editValue" v-bind="money" style="width:90%" label="Price"/>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <div style="text-align:left;font-size:small;color:red">(Athletes will be charged {{ editAthleteFee.toLocaleString(tryouts[selectedTryout].localLanguage, {style:'currency', currency: tryouts[selectedTryout].localCurrency}) }} covering bank and platform fees)</div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer/>
                        <v-btn @click="cancelFee" text >Cancel</v-btn>
                        <v-btn @click="saveFee" text color="primary">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-col cols="12">
                <v-data-table dense
                    :items="fees" 
                    :headers="headers"
                    hide-default-footer
                >
                    <template v-slot:item.name="{item}">
                        <span v-if="item.disabled" style="text-decoration:line-through;color:gray">{{ item.name }}</span>
                        <span v-else>{{ item.name }}</span>
                    </template>
                    <template v-slot:item.value="{item}">
                        <span v-if="item.disabled" style="font-style:italic;color:gray">{{ item.value.toLocaleString(tryouts[selectedTryout].localLanguage, {style: 'currency', currency: tryouts[selectedTryout].localCurrency}) }}</span>
                        <span v-else>{{ item.value.toLocaleString(tryouts[selectedTryout].localLanguage, {style: 'currency', currency: tryouts[selectedTryout].localCurrency}) }}</span>
                    </template>
                    <template v-slot:item.label="{item}">
                        <span v-if="item.disabled" style="font-style:italic;color:gray">{{ item.label }}</span>
                        <span v-else>{{ item.label }}</span>
                    </template>
                    <template v-slot:item.athleteFee="{item}">
                        <span v-if="item.disabled" style="font-style:italic;color:gray">Not Applicable</span>
                        <span v-else>{{ item.athleteFee.toLocaleString(tryouts[selectedTryout].localLanguage, {style: 'currency', currency: tryouts[selectedTryout].localCurrency}) }}</span>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-icon small class="mr-2" @click="editFee(item)" > mdi-pencil </v-icon>
                        <v-icon v-if="item.name!=='Offer Acceptance Fee'" small @click="deleteFee(item)" > mdi-delete </v-icon>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {Money} from 'v-money'
import {v4 as uuidv4} from 'uuid';

import { mapState, mapActions } from 'vuex'

export default {
    props: [],
    components: {
        Money,
    },
    computed: {
        ...mapState({
            tryouts:                state => state.tryoutStore.data,
            tryoutStatus:           state => state.tryoutStore.status,

            globalSettingsStatus:   state => state.globalsettingsStore.status,
            globalSettings:         state => state.globalsettingsStore.data,

            selectedTryout:         state => state.navigatorStore.selectedTryout,
        }),
        loading(){
            return false
                || this.globalSettingsStatus.loading
                || this.tryoutStatus.loading
        },
        editAthleteFee(){
            if(this.feeIndex > 2) {
                return this.editValue + (this.globalSettings.baseFee/100) + (this.editValue*this.globalSettings.vasFee/10000)
            }
            return this.editValue + (this.globalSettings.baseFee/100)
        },
    },
    methods: {
        ...mapActions('tryoutStore', {
            updateTryoutProp: 'update',
            updateTryout: 'updateTryout',
            loadTryout: 'loadTryout',
        }),
        ...mapActions('globalsettingsStore',{
            reloadGlobalSettings: 'load'
        }),
        editFee(item){
            this.editValue=item.value
            this.editLabel=item.label
            this.editDialog=true
            this.feeIndex = this.fees.indexOf(item)
        },
        saveFee(){
            this.fees[this.feeIndex].label = this.editLabel
            this.fees[this.feeIndex].value = this.editValue
            if(this.feeIndex <3)
                this.fees[this.feeIndex].athleteFee = this.editValue + (this.globalSettings.baseFee/100) 
            else
                this.fees[this.feeIndex].athleteFee = this.editValue + (this.globalSettings.baseFee/100) + (this.editValue*this.globalSettings.vasFee/10000) 


            switch (this.feeIndex){
                case 0:
                    this.updateTryoutProp({field: 'trialFee1Name', id: this.selectedTryout, value: this.editLabel})
                    this.updateTryoutProp({field: 'trialFee1Value', id: this.selectedTryout, value: this.editValue*100})
                    break
                case 1:
                    this.updateTryoutProp({field: 'trialFee2Name', id: this.selectedTryout, value: this.editLabel})
                    this.updateTryoutProp({field: 'trialFee2Value', id: this.selectedTryout, value: this.editValue*100})
                    break
                case 2:
                    this.updateTryoutProp({field: 'trialFee3Name', id: this.selectedTryout, value: this.editLabel})
                    this.updateTryoutProp({field: 'trialFee3Value', id: this.selectedTryout, value: this.editValue*100})
                    break
                case 3:
                    this.updateTryoutProp({field: 'offerFeeName', id: this.selectedTryout, value: this.editLabel})
                    this.updateTryoutProp({field: 'offerFeeValue', id: this.selectedTryout, value: this.editValue*100})
                    break
            }

            this.fees[this.feeIndex].disabled=false
            this.editDialog=false
            this.changes=true
            this.$root.$emit("settings-changes-made", this.componentId)
        },
        cancelFee(){
            this.editLabel=''
            this.editValue=0
            this.editDialog=false
        },
        deleteFee(item){
            this.deleteDialog=true
            this.feeIndex = this.fees.indexOf(item)
            this.purgeFee()
        },
        purgeFee(){
            this.editLabel="Not Set"
            this.editValue=0
            switch (this.feeIndex){
                case 0:
                    this.updateTryoutProp({field: 'trialFee1Name', id: this.selectedTryout, value: this.editLabel})
                    this.updateTryoutProp({field: 'trialFee1Value', id: this.selectedTryout, value: this.editValue*100})
                    break
                case 1:
                    this.updateTryoutProp({field: 'trialFee2Name', id: this.selectedTryout, value: this.editLabel})
                    this.updateTryoutProp({field: 'trialFee2Value', id: this.selectedTryout, value: this.editValue*100})
                    break
                case 2:
                    this.updateTryoutProp({field: 'trialFee3Name', id: this.selectedTryout, value: this.editLabel})
                    this.updateTryoutProp({field: 'trialFee3Value', id: this.selectedTryout, value: this.editValue*100})
                    break
                case 3:
                    this.updateTryoutProp({field: 'offerFeeName', id: this.selectedTryout, value: this.editLabel})
                    this.updateTryoutProp({field: 'offerFeeValue', id: this.selectedTryout, value: this.editValue*100})
                    break
            }
            this.fees[this.feeIndex].disabled=true
            this.changes=true
            this.$root.$emit("settings-changes-made", this.componentId)
        },

        async saveDetails() {

            await this.updateTryout(this.tryouts[this.selectedTryout])
            this.$root.$emit("settings-changes-cleared", this.componentId)
            this.$store.dispatch('alert/success', 'Tryout updated', {root: true})
            this.changes=false
        },
        loadData(){
            this.reloadGlobalSettings()
            this.fees = [
                    {
                        name: 'Tryout Fee Type 1',
                        label: this.tryouts[this.selectedTryout].trialFee1Name?this.tryouts[this.selectedTryout].trialFee1Name:'Not set',
                        value: this.tryouts[this.selectedTryout].trialFee1Value/100,
                        athleteFee: this.tryouts[this.selectedTryout].trialFee1Value>0?this.tryouts[this.selectedTryout].trialFee1Value/100+(this.globalSettings.baseFee)/100:this.globalSettings.baseFee/100,
                        disabled: this.tryouts[this.selectedTryout].trialFee1Value>0?false:true
                    },
                    {
                        name: 'Tryout Fee Type 2',
                        label: this.tryouts[this.selectedTryout].trialFee2Name?this.tryouts[this.selectedTryout].trialFee2Name:'Not set',
                        value: this.tryouts[this.selectedTryout].trialFee2Value/100,
                        athleteFee: this.tryouts[this.selectedTryout].trialFee2Value>0?this.tryouts[this.selectedTryout].trialFee2Value/100+(this.globalSettings.baseFee)/100:this.globalSettings.baseFee/100,
                        disabled: this.tryouts[this.selectedTryout].trialFee2Value>0?false:true
                    },
                    {
                        name: 'Tryout Fee Type 3',
                        label: this.tryouts[this.selectedTryout].trialFee3Name?this.tryouts[this.selectedTryout].trialFee3Name:'Not set',
                        value: this.tryouts[this.selectedTryout].trialFee3Value/100,
                        athleteFee: this.tryouts[this.selectedTryout].trialFee3Value>0?this.tryouts[this.selectedTryout].trialFee3Value/100+(this.globalSettings.baseFee)/100:this.globalSettings.baseFee/100,
                        disabled: this.tryouts[this.selectedTryout].trialFee3Value>0?false:true
                    },
                    {
                        name: 'Offer Acceptance Fee',
                        label: 'Playing Fee',
                        value: this.tryouts[this.selectedTryout].offerFeeValue/100,
                        athleteFee: this.tryouts[this.selectedTryout].offerFeeValue>0?this.tryouts[this.selectedTryout].offerFeeValue/100+(this.globalSettings.baseFee)/100+(this.tryouts[this.selectedTryout].offerFeeValue/100)*(this.globalSettings.vasFee/10000):this.globalSettings.baseFee/100,
                        disabled: false
                    },
                ]
        }
    },
    mounted(){
        this.$root.$on('settings-clear-changes-flags', ()=> {
            var self=this
            self.changes=false
        })
        this.$root.$on('submit-all-changes', async () => {
            if(this.changes){
                await this.saveDetails()
            }
        })

        this.$root.$on('backout-all-changes', async () => {
            if(this.changes){
                await this.loadTryout(this.selectedTryout)
            }
        })
        this.$root.$on('tryoutRefresh', this.refreshHandler)
    },
    beforeDestroy(){
        this.$root.$off('tryoutRefresh', this.refreshHandler)
    },
    created(){
        this.componentId=uuidv4()
        this.loadData()
        /*
        this.reloadGlobalSettings()
        this.fees = [
                {
                    name: 'Tryout Fee Type 1',
                    label: this.tryouts[this.selectedTryout].trialFee1Name?this.tryouts[this.selectedTryout].trialFee1Name:'Not set',
                    value: this.tryouts[this.selectedTryout].trialFee1Value/100,
                    athleteFee: this.tryouts[this.selectedTryout].trialFee1Value>0?this.tryouts[this.selectedTryout].trialFee1Value/100+(this.globalSettings.baseFee)/100:this.globalSettings.baseFee/100,
                    disabled: this.tryouts[this.selectedTryout].trialFee1Value>0?false:true
                },
                {
                    name: 'Tryout Fee Type 2',
                    label: this.tryouts[this.selectedTryout].trialFee2Name?this.tryouts[this.selectedTryout].trialFee2Name:'Not set',
                    value: this.tryouts[this.selectedTryout].trialFee2Value/100,
                    athleteFee: this.tryouts[this.selectedTryout].trialFee2Value>0?this.tryouts[this.selectedTryout].trialFee2Value/100+(this.globalSettings.baseFee)/100:this.globalSettings.baseFee/100,
                    disabled: this.tryouts[this.selectedTryout].trialFee2Value>0?false:true
                },
                {
                    name: 'Tryout Fee Type 3',
                    label: this.tryouts[this.selectedTryout].trialFee3Name?this.tryouts[this.selectedTryout].trialFee3Name:'Not set',
                    value: this.tryouts[this.selectedTryout].trialFee3Value/100,
                    athleteFee: this.tryouts[this.selectedTryout].trialFee3Value>0?this.tryouts[this.selectedTryout].trialFee3Value/100+(this.globalSettings.baseFee)/100:this.globalSettings.baseFee/100,
                    disabled: this.tryouts[this.selectedTryout].trialFee3Value>0?false:true
                },
                {
                    name: 'Offer Acceptance Fee',
                    label: 'Playing Fee',
                    value: this.tryouts[this.selectedTryout].offerFeeValue/100,
                    athleteFee: this.tryouts[this.selectedTryout].offerFeeValue>0?this.tryouts[this.selectedTryout].offerFeeValue/100+(this.globalSettings.baseFee)/100+(this.tryouts[this.selectedTryout].offerFeeValue/100)*(this.globalSettings.vasFee/10000):this.globalSettings.baseFee/100,
                    disabled: false
                },
            ]
            */
    },
    data(){
        return {
            refreshHandler: async() => {
                await this.loadData()
            },
            componentId: '',
            showFees: false,
            changes: false,
            editDialog: false,
            editLabel:'',
            editValue:0,
            deleteDialog: false,
            fees: [{},{},{},{}],

            feeIndex:0,
            money: {
                decimal: '.',
                thousands: ',',
                prefix: '$',
                precision: 2,
            },
            headers: [
                {
                text: 'Fee',
                align: 'start',
                sortable: false,
                value: 'name',
                },
                { text: 'Label', value: 'label', sortable:false },
                { text: 'Value', value: 'value' , sortable:false},
                { text: 'Athlete Charge', value: 'athleteFee' , sortable:false},
                { text: 'Actions', value: 'actions', sortable: false },
            ],
                

        }
    }

        

}
</script>

<style scoped>

.fee-details {
    margin-bottom: auto;
    align-items:center;
}

.fee-col{
    margin-bottom: auto;
}

.fee-reminder {
    color:red;
    font-size: small;
}

.v-input--is-focused .v-input__slot {
  border: 2px solid #005fcc !important;
  border-bottom-color: rgba(0, 0, 0, 0.38) !important;
}

.v-input__slot {
    border: 0px;
    margin-top:auto;
    margin-bottom: auto;
}
</style>