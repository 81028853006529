<template>
    <loading v-if="loading"/>
    <div v-else-if="account.user && account.user.details.updateDetails">
        <CompleteRegistration/>
    </div>
    <div v-else>
        <ClubsPanel/>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Loading from '@/components/loading'
import ClubsPanel from '@/ClubPanes/ClubsPanel'
import CompleteRegistration from '@/login/CompleteRegistration'

export default {
    components: {
        Loading,
        ClubsPanel,
        CompleteRegistration,
    },
    computed: {
        ...mapState({
            account: state => state.account,
            tryouts: state => state.tryoutStore.data,
            tryoutStatus: state => state.tryoutStore.status,
            tryoutList: state => state.tryoutStore.list,
            clubs: state => state.clubStore.data,
            clubList: state => state.clubStore.list,
        }),
        loading: function() {
            return this.account.status.loading || this.tryoutStatus.loading
        },
        onlineRegList: function() {
            var list = {}
            for(var key in this.tryouts) {
                if(this.tryouts[key].onlineReg){
                    list[key] = this.tryouts[key]
                }
            }
            return list
        },
        noOnlineRegTrials : function() {
            return Object.keys(this.onlineRegList).length === 0
        }
    },
    created () {
       // this.getAllTryouts();
      //  this.navTo('root')
    },
    methods: {
        ...mapActions('tryoutStore', {
            getAllTryouts: 'findAll',
        }),
        ...mapActions('navigatorStore', ['navTo', 'navBack']),
        registrationLink(id) {
            return '/registerfortryout/' + id
        }
    },
    data() {
        return {
            onlineRegListLoading: true,
            noOnlineRegMsg: false,
        }
    }
};
</script>