<template>
        <div class="ql-editor" v-html="sampleHTML"/>
</template>


<script>
import { mapState, mapActions } from 'vuex';

import QRCodeStyling from 'qr-code-styling'
import 'quill/dist/quill.core.css'

//import moment from 'moment'

export default {
    props: ['eventId'],
    components: {
    },
    computed: {
        ...mapState({
            messages:       state => state.messageStore.data,
            messageList:    state => state.messageStore.list,
            messageStatus:  state => state.messageStore.status,
            msgByName:      state => state.messageStore.listByName,
            clubs:          state => state.clubStore.data,
            clubList:       state => state.clubStore.list,
            clubStatus:     state => state.clubStore.status,
            tryouts:        state => state.tryoutStore.data,
            sessions:       state => state.sessionStore.data,
            participants:   state => state.participantStore.data,

            selectedTryout:         state => state.navigatorStore.selectedTryout,
            selectedSession:        state => state.navigatorStore.selectedSession,
            selectedParticipant:    state => state.navigatorStore.selectedParticipant,
            selectedClub:           state => state.navigatorStore.selectedClub,
        }),
        participantNumber(){

            return `<div style="text-align: left"><div style="display: flex;justify-content: center;border-style: solid;width: 200px;margin-left: auto;margin-right: auto;margin-top: 30px;margin-bottom: 30px;font-size:60px;font-size:30px;border-radius: 26px;background: blue;color: white;font-weight: 900;padding: 30px;font-family:sans-serif;"><div style="text-align:right; display: flex;flex-direction:column;justify-content: center;"><div>${this.participants[this.selectedParticipant].participantNumber}</div></div></div>`
        },
        loading() {
            return false
                || this.messageStatus.loading
                || this.clubStatus.loading
                || !this.renderReady

        },
        sampleHTML() {
            var finalString = ""// `<div class="qrcode" ref="qrcode" id="qrcode"></div>`
            
            
            if(this.sessions[this.selectedSession]){
                finalString = this.sessions[this.selectedSession].participantNote

                finalString = finalString.replace(/\$fname/g, this.participants[this.selectedParticipant].firstName)
                finalString = finalString.replace(/\$lname/g, this.participants[this.selectedParticipant].lastName)
                finalString = finalString.replace(/\$cname/g, this.tryouts[this.eventId].contactName)
                finalString = finalString.replace(/\$cphone/g, this.tryouts[this.eventId].contactPhone)
                finalString = finalString.replace(/\$cemail/g, this.tryouts[this.eventId].contactEmail)
                finalString = finalString.replace(/\$clubname/g, this.clubs[this.selectedClub].name)
                finalString = finalString.replace(/\$tryoutname/g, this.tryouts[this.eventId].title)
                finalString = finalString.replace(/\$sessionname/g, this.sessions[this.selectedSession].title)
                finalString = finalString.replace(/\$number/g, this.participantNumber)
                finalString = finalString.replace(/\$qrcode/g, `<div class="qrcode" ref="qrcode" id="qrcode"></div>`)

                finalString = finalString.replace(/\$clublogo/g, "<img height=\"50px\" src=\""  + this.clubs[this.selectedClub].logo + "\"/>")

            }

            return finalString
        },
        signin2Link(){
            const link = "https://coachsight.net/signin2/"+this.selectedSession+"/"+this.selectedParticipant
            return link
        },
        
    },
    methods: {
        ...mapActions('messageStore', {
            findMessagesForEvent: 'loadAllForEvent',
        }),
        ...mapActions('participantStore',{
            findParticipants: 'publicParticipantList'
        }),

        ...mapActions('sessionStore', {
            loadSessionsForEvent: 'findAllForEvent'
        }),

    },
    async mounted(){
        await this.findParticipants({eventId: this.selectedTryout})
        await this.loadSessionsForEvent(this.selectedTryout)
            this.finalString = this.sessions[this.selectedSession].participantNote
            this.finalString = this.finalString.replace(/\$fname/g, this.participants[this.selectedParticipant].firstName)
            this.finalString = this.finalString.replace(/\$lname/g, this.participants[this.selectedParticipant].lastName)
            this.finalString = this.finalString.replace(/\$cname/g, this.tryouts[this.eventId].contactName)
            this.finalString = this.finalString.replace(/\$cphone/g, this.tryouts[this.eventId].contactPhone)
            this.finalString = this.finalString.replace(/\$cemail/g, this.tryouts[this.eventId].contactEmail)
            this.finalString = this.finalString.replace(/\$clubname/g, this.clubs[this.selectedClub].name)
            this.finalString = this.finalString.replace(/\$tryoutname/g, this.tryouts[this.eventId].title)
            this.finalString = this.finalString.replace(/\$sessionname/g, this.sessions[this.selectedSession].title)
            this.finalString = this.finalString.replace(/\$number/g, this.participantNumber)
            this.finalString = this.finalString.replace(/\$qrcode/g, `<div class="qrcode" ref="qrcode" id="qrcode"></div>`)

            this.finalString = this.finalString.replace(/\$clublogo/g, "<img height=\"50px\" src=\""  + this.clubs[this.selectedClub].logo + "\"/>") 

            this.qrCode.append(document.getElementById("qrcode"))
    },
    watch: {
        finalString(){
            this.qrCode.append(document.getElementById("qrcode"))
        }
    },
    async created() {
        await this.findParticipants({eventId: this.selectedTryout})
        await this.loadSessionsForEvent(this.selectedTryout)


        this.qrCode = new QRCodeStyling({
            width: this.codeSize.medium,
            height: this.codeSize.medium,
            type: "svg",
            //data: this.signin2Link,
            data: "https://coachsight.net/signin2/"+this.selectedSession+"/"+this.selectedParticipant,
            image: "https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/coachsight_eye.png",
            dotsOptions: {

                color: "#26249a",
                type: "extra-rounded",
                gradient: {
                    type: 'linear',
                    rotation:0,
                    colorStops: [
                        { offset:0, color: '#9245B7'},
                        { offset:1, color: '#032681'}
                    ]
                }
            },
            backgroundOptions: {
                color: "#ffffff",
            },
            cornersSquareOptions: {
                type: 'extra-rounded',
                color: '#000000',
                gradient: {
                    type: 'linear',
                    rotation: 0,
                    colorStops: [
                        { offset: 0, color: "#000000"},
                        { offset: 1, color: '#2197F3'},
                    ]
                }
            },
            cornersDotOptions: {
                type: 'dot',
                color: '#000000'
            },
            imageOptions: {
                crossOrigin: "anonymous",
                margin: 5
            }
        });
       // await this.findMessagesForEvent(this.selectedTryout) 
        this.finalString = this.sessions[this.selectedSession].participantNote

        this.renderReady=true

    },
    data() {
        return {
            finalString: '',
            renderReady: false,
            qrCode: '',
            codeSize: {
                small: 100,
                medium: 200,
                large: 300
            },
        }
    }
}
</script>

<style>
@import "https://cdnjs.cloudflare.com/ajax/libs/quill/1.3.7/quill.core.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/quill/1.3.7/quill.snow.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/quill/1.3.7/quill.bubble.css";
.qrcode{
    text-align:center;
}


</style>