var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"text-align":"left","padding":"0px 10px 0px 10px"}},[(false)?_c('v-row',[_c('v-spacer'),_c('v-col',{staticStyle:{"text-align":"right"},attrs:{"cols":"2"}},[(_vm.changes)?_c('v-btn',{staticClass:"save-btn",attrs:{"x-small":"","depressed":"","color":"primary"},on:{"click":_vm.saveDetails},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" Save ")]):_vm._e()],1)],1):_vm._e(),_c('v-row',[_c('v-checkbox',{attrs:{"label":"Activate this Tryout"},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}})],1),_c('v-row',[_c('v-checkbox',{attrs:{"label":"Accept online registrations now"},model:{value:(_vm.onlineReg),callback:function ($$v) {_vm.onlineReg=$$v},expression:"onlineReg"}})],1),_c('v-row',[_c('v-col',{staticStyle:{"margin-top":"0px"},attrs:{"cols":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('div',{staticClass:"qrheading"},[_vm._v("Link for registration")]),_c('div',{staticClass:"qrdesc"},[_vm._v("This link can be ssent to athletes to connect to CoachSight. This is particularly useful for athletes who have been \"imported\" into CoachSight from an external team management tool.")]),_c('div',{staticClass:"qrlink"},[_c('div',{staticClass:"no-boundaries",staticStyle:{"word-break":"break-all"}},[_vm._v(" "+_vm._s(_vm.registrationLink)+" ")]),_c('div',{staticClass:"no-boundaries text-right"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"margin-left":"20px"},on:{"click":_vm.copyToClipboard}},'v-icon',attrs,false),on),[_vm._v("mdi-content-copy")])]}}])},[_c('span',[_vm._v("Copy to clipboard")])])],1)])]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('div',[_c('div',{staticClass:"qrheading"},[_vm._v("QRCode for registration")]),_c('div',{staticClass:"qrdesc"},[_vm._v("This code can be used to allow register for this tryout.")]),_c('qr-code',{key:_vm.updateKey,attrs:{"width":_vm.codeSize.large,"height":_vm.codeSize.large,"value":_vm.registrationLink,"qrOptions":{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' },"imageOptions":{ hideBackgroundDots: true, imageSize: 0.4, margin: 0},"dotsOptions":{
                        type: 'extra-rounded',
                        color: '#26249a',
                        gradient: {
                            type: 'linear',
                            rotation: 0,
                            colorStops: [
                                { offset: 0, color: '#9245B7' },
                                { offset: 1, color: '#032681' } ],
                        },
                    },"backgroundOptions":{ color: '#ffffff' },"cornersSquareOptions":{ 
                        type: 'extra-rounded', 
                        color: '#000000' ,
                        gradient: {
                            type: 'linear',
                            rotation: 0,
                            colorStops: [
                                { offset: 0, color:'#000000'},
                                { offset: 1, color: '#2197F3'}
                            ]

                        }
                    },"cornersDotOptions":{ type: 'dot', color: '#000000' },"fileExt":"png","download":true}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }