import { stripeService } from '../_services'

const dataService = stripeService

function initialiseState(){
    return {
        status: {},
        error: null,
        data: {}
    }
}

const state = initialiseState()

const actions = {
    purgeData({commit}){
        commit('purgeData')
    },
    async onboardConnect({commit}, payload) {
        try{
            commit('connectRequest')
            const response = await dataService.onboardConnect(payload)
            commit('connectSuccess', response)
        } catch(error) {
            commit('connectFailure', error)
        }
    },
    async onboardSuccess({commit}, payload) {
        try{
            commit('finishConnectRequest')
            const response = await dataService.onboardSuccess(payload)
            commit('finishConnectSuccess', response)
        } catch(error) {
            commit('finishConnectFailure', error)
        }
    },
    async tryoutCharge({commit}, payload) {
        try{
            commit('tryoutchargeRequest')
            const response = await dataService.tryoutCharge(payload) 
            commit('tryoutchargeSuccess', response)
        } catch(error) {
            commit('tryoutchargeFailure', error)
        }
    },
    async offerCharge({commit}, payload) {
        try{
            commit('offerchargeRequest')
            const response = await dataService.offerCharge(payload) 
            commit('offerchargeSuccess', response)
        } catch(error) {
            commit('offerchargeFailure', error)
        }
    },
    async packageCharge({commit}, payload){
        try {
            commit('packageChargeRequest')
            const response = await dataService.packageCharge(payload)
            commit('offerchargeSuccess', response)
        } catch(error) {
            commit('packageChargeFailure', error)
        }
    }
}

const mutations = {
purgeData(state){
    const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }
    },
    packageChargeRequest(state){
        state.data={}
        state.error=null
    },
    packageChargeSuccess(state, payload){
        state.data = payload
        state.status={}
    },
    packageChargeFailure(state, error){
        state.error=error
        state.status={}
    },
    offerchargeRequest(state ) {
        state.data={}
        state.error=null
    },
    offerchargeSuccess(state, response) {
        state.data=response
    },
    offerchargeFailure(state, error) {
        
        state.error=error
    },

    tryoutchargeRequest(state ) {
        state.data={}
        state.error=null
    },
    tryoutchargeSuccess(state, response) {
        state.data=response
    },
    tryoutchargeFailure(state, error) {
        state.error=error
    },







    connectRequest(state) {
        state.error=null
        state.data={}
    },
    connectSuccess(state, response) {
        state.error=null
        const {accountLinkURL} = response
        state.data.accountLinkURL = accountLinkURL
    },
    connectFailure(state, error) {
        state.error=error
    },
    finishConnectRequest(state){
        state.error=null
        state.status={loading:true}
    },
    finishConnectSuccess(state){
        state.data={}
        state.status={}
    },
    finishConnectFailure(state, error){
        state.error=error
    },

    /*
    propertyUpdate(state, payload) {
        const metaFields = "delete"
        const {field, id, value} = payload

        if(metaFields.includes(field)){
            state.meta[id][field]=value
            // Manage special fields...
            //switch (field) {
            //}
        } else {
            state.data[id][field]=value
        }
    }
    */
}

export const stripeStore = {
    namespaced: true,
    state,
    actions,
    mutations
}