var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('div',{staticStyle:{"text-align":"left","padding":"0px 10px 0px 10px"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
var reset = ref.reset;
return [_c('v-row',[_c('v-col',{staticStyle:{"text-align":"right"},attrs:{"cols":"12"}},[(_vm.changes)?_c('v-btn',{staticClass:"save-btn",staticStyle:{"margin-right":"10px"},attrs:{"x-small":"","depressed":"","color":"primary"},on:{"click":_vm.saveDetails},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" Save ")]):_vm._e(),_c('v-btn',{attrs:{"color":"primary","x-small":""},on:{"click":function($event){_vm.addDialog=true}}},[_vm._v(" Add new activity ")])],1)],1),_c('v-row',[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.addDialog),callback:function ($$v) {_vm.addDialog=$$v},expression:"addDialog"}},[_c('form',{ref:"addNewActivity",staticStyle:{"margin-top":"0px"}},[_c('v-card',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.addVisible),expression:"addVisible"}],ref:"observer"},[_c('v-card-title',[_c('span',{staticClass:"h5-text"},[_vm._v("New Activity")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Session for activity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.sessionList,"label":"Session for this Activity"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.sessions[item].title)+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.sessions[item].title)+" ")]}}],null,true),model:{value:(_vm.selectedSession),callback:function ($$v) {_vm.selectedSession=$$v},expression:"selectedSession"}}),_c('div',{staticClass:"form-error"},[_vm._v(_vm._s(errors))])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Activity name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"mdi-rename-box","label":"Activity Name"},model:{value:(_vm.newName),callback:function ($$v) {_vm.newName=$$v},expression:"newName"}}),_c('div',{staticClass:"text-left",staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Objective"},model:{value:(_vm.newObjective),callback:function ($$v) {_vm.newObjective=$$v},expression:"newObjective"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Duration","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"activity duration","label":"Expected Duration (mins)"},model:{value:(_vm.newDur),callback:function ($$v) {_vm.newDur=$$v},expression:"newDur"}}),_c('div',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.closeAddDialog(reset)}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":invalid},on:{"click":function($event){return _vm.saveActivity(reset)}}},[_vm._v(" Save ")])],1)],1)],1)])],1),_vm._l((_vm.sessionList),function(sessionId){return _c('v-row',{key:sessionId},[_c('v-col',{attrs:{"cols":"12"}},[_c('tryout-activity-editor',{attrs:{"sessionId":sessionId}})],1)],1)})]}}],null,false,4099854057)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }