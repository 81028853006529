<template>
<div class="question-wrap">
    <div class="question-head">
        <div class="question-number">Question {{questionNum}}: </div>
        <div class="question-text">{{ questions[questionId].question }}</div>
    </div>
    <div class="question-response">{{ formResponses[questionId].response }}</div>
</div>

</template>

<script>
import {mapState} from 'vuex'
export default {
    props: [ 'questionId', 'questionNum'],
    computed: {
        ...mapState({
            formResponses: state => state.qresponseStore.responses,
            questionList: state => state.appquestionStore.list,
            questions: state => state.appquestionStore.data,
        })
    },
    methods: {},
    created(){
    },
    data(){
        return {

        }
    }

}

</script>

<style scoped>

    .question-wrap{
        margin-bottom: 20px;
    }

    .question-head{
        display: flex;
        flex-direction: row;
    }
    .question-number{
        font-weight: 600;
        font-size: small;
        text-align: left;
        margin-bottom:auto;
        margin-top:auto;
    }

    .question-text{
        font-weight: 400;
        font-size: small;
        text-align: left;
        color: var(--cs1-lev1);
        margin-bottom:auto;
        margin-top:auto;
        margin-left: 10px;
    }

    .question-response{
        font-weight: 400;
        font-size: medium;
        text-align: left;
        margin-left: 10px;
        width:100%;
        border-bottom: 1px dotted var(--cs1-lev1);
        width: 100%;
        display: block;
    }
</style>