<template>
    <div v-if="!loading">
        <ValidationObserver v-slot="{invalid}">
            <form @submit.prevent="onSubmit">
                <v-row>
                    <div class="junior-participant text-left">
                        <h3>Parental/Guardian Consent</h3>
                        <em><small>This section applies to participants attending who are under the age of {{ juniorAge }} at December 31 this year.</small></em>
                    </div>
                </v-row>
                <v-row><v-divider/></v-row>
                <v-row>
                    <div class="ql-editor" v-html="parentInfo"/>
                </v-row>
                <v-row><v-divider/></v-row>
                <v-row>
                    <v-col cols="12">
                        <ValidationProvider name="parentName" rules="required" v-slot="{errors}">
                            <v-text-field label="Parent/Guardian name:" v-model="pName"/>
                            <div class="field-error">{{errors[0]}}</div>
                        </ValidationProvider>

                    </v-col>

                </v-row>
                <v-row>
                    <v-col cols="6">
                        <ValidationProvider name="Parent/guardian phone number" rules="required|mobileNumber:AU" v-slot="{errors}">
                            <v-text-field label="Parent/Guardian phone number:" v-model="pPhone"/>
                            <div class="field-error">{{errors[0]}}</div>
                        </ValidationProvider>

                    </v-col>
                    <v-col cols="6">
                        <ValidationProvider name="Parent/Guardian email address" rules="required|email" v-slot="{errors}">
                            <v-text-field label="Parent/Guardian email address:" v-model="pEmail"/>
                            <div class="field-error">{{errors[0]}}</div>
                        </ValidationProvider>

                    </v-col>

                </v-row>
                <v-row>
                    <ValidationProvider name="parentalConsent" rules="set" v-slot="{errors}">
                        <v-checkbox v-model="parentalConsent" label="I consent to my child participating in this event, and acknowledge the information in this 'Parental/Guardian' section of the application process"/>
                        <div class="field-error">{{errors[0]}}</div>
                    </ValidationProvider>

                </v-row>
                <v-row>
                    <v-col cols="3">
                        <v-img :src="account.user.details.profileImage"  max-width="200px" max-height="200px"/>

                    </v-col>
                    <v-col cols="9" style="margin-top:0px">
                        <v-checkbox v-model="imageConsent" label="I consent to my child's profile image (shown left) being made available to staff during the event "/>
                    </v-col>
                </v-row>
            </form>
            <v-divider/>
                <div class="buttons">
                    <v-row>
                        <v-col cols="2">
                            <v-btn 
                                color="secondary"
                                @click="lastStep()"
                            >
                                Back
                            </v-btn>
                        </v-col>
                        <v-spacer/>
                        <v-col cols="2">
                            <v-btn 
                                color="primary"
                                @click="saveResponses()"
                                :disabled="invalid"
                            >
                                Continue
                            </v-btn>
                        </v-col>
                        <v-col cols="2">
                            <v-btn text @click="cancel">
                                Cancel
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>

        </ValidationObserver>
    </div>


</template>

<script>
import { mapState, mapActions } from 'vuex';
import {rules} from '@/_helpers/rules'
import { extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {email} from 'vee-validate/dist/rules'
import { router } from '@/_helpers';

extend('email', email)

export default{
    props: [],
    components: {
        ValidationObserver,
        ValidationProvider,
    },
    computed: {
        ...mapState({
            account:        state => state.account,
            tryouts:        state => state.tryoutStore.data,

            participants:   state => state.participantStore.data,
            participantList: state => state.participantStore.list,

            selectedTryout: state => state.navigatorStore.selectedTryout,

            msgByName:      state => state.messageStore.listByName,
            messageStatus:  state => state.messageStore.status,
        }),
        loading(){
            return this.messageStatus.loading || this.account.status.loading
        },
        parentInfo(){
            return this.msgByName['parentInfo'].message
        },
        parentalConsent: {
            get(){
                return this.pConsent
            },
            set(newValue){
                this.pConsent = newValue
            }
        },
        imageConsent: {
            get() {
                return this.iConsent
            },
            set(newValue) {
                this.iConsent = newValue
            }
        },
        juniorAge(){
            return this.tryouts[this.selectedTryout].juniorAge
        },

    },
    methods: {
        ...mapActions('tryoutregStore', {
            setStep: 'setStep',
            setResponses: 'setResponses',
            nextStep: 'nextStep',
            lastStep: 'lastStep',
        }),
        saveResponses() {
            this.setResponses({step:3, responses: {
                parentConsent: this.pConsent,
                imageConsent: this.iConsent,
                parentName: this.pName,
                parentPhone: this.pPhone,
                parentEmail: this.pEmail
            }})
            this.nextStep()
        },
        cancel(){
            this.selectClubPane('Home')
            this.selectClubPane('SeasonHome')
            router.push('/clubhouse'+this.selectedClub)
        }
    },
    created(){},
    data(){
        return {
            rules: rules,
            pConsent: false,
            iConsent: false, 
            pName: null,
            pEmail:'',
            pPhone:'',
        }
    }
}

</script>

<style>

</style>