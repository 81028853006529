import  {feedbackLinkService} from "../_services"
import { rc_feedback } from "@/_helpers/responseCodes"
import Vue from 'vue'

function initialiseState(){
    return {
        status: {},
        list: {},
        data: {},
        meta: {},
        error: null,
        internal: {},
        listByPlayerAndStaffAndCriterion:{},
        listByPlayerAndFrequency:{},
        listByPlayerAndCriterion:{}
    }
}

const state = initialiseState()

const actions={
    purgeData({commit}){
        commit('purgeData')
    },

    async loadAllFeedbackLinksForEventAndStaff({commit}, payload){
        try{
            commit('loadAllFeedbackLinksForEventAndStaffRequest')
            const response = await feedbackLinkService.loadAllFeedbackLinksForEventAndStaff(payload)
            commit('loadAllFeedbackLinksForEventAndStaffSuccess', response)
            return response

        } catch(error){
            commit('loadAllFeedbackLinksForEventAndStaffFailure', error)
            console.error(error)
        }
    },
    async loadAllFeedbackLinksForEvent({commit}, payload){
        try{
            commit('loadAllFeedbackLinksForEventRequest')
            const response = await feedbackLinkService.loadAllFeedbackLinksForEvent(payload)
            commit('loadAllFeedbackLinksForEventSuccess', response)
            return response

        } catch(error){
            commit('loadAllFeedbackLinksForEventFailure', error)
            console.error(error)
        }
    },
    async addFeedbackLink({commit}, payload){
        try{ 
            commit('addFeedbackLinkRequest',payload)
            const response = await feedbackLinkService.addFeedbackLink(payload)
            commit('addFeedbackLinkSuccess', response)
            return response
        } catch(error) {
            commit('addFeedbackLinkFailure',error)
            console.error(error)
        }
    },
    async deleteFeedbackLink({commit}, payload){
        try{
            commit('deleteFeedbackLinkRequest', payload)
            const response = await feedbackLinkService.deleteFeedbackLink(payload)
            commit('deleteFeedbackLinkSuccess', response)
            return response
        } catch(error) {
            commit('deleteFeedbackLinkFailure',error)
            console.error(error)
        }
    },
    updateFeedbackProp({commit},payload) {
        commit('propertyUpdate', payload)
    }

}


function addToLists(data){
    const id = data.id

    const originalListByPlayerAndStaffAndCriterion=state.listByPlayerAndStaffAndCriterion

    if(!originalListByPlayerAndStaffAndCriterion[data.participantId]) {originalListByPlayerAndStaffAndCriterion[data.participantId]={}}
    if(!originalListByPlayerAndStaffAndCriterion[data.participantId][data.staffId]) {originalListByPlayerAndStaffAndCriterion[data.participantId][data.staffId]={}}
    if(!originalListByPlayerAndStaffAndCriterion[data.participantId][data.staffId][data.criterionId]) {originalListByPlayerAndStaffAndCriterion[data.participantId][data.staffId][data.criterionId]=[]}
    if(!originalListByPlayerAndStaffAndCriterion[data.participantId][data.staffId][data.criterionId].includes(data.feedbackId)) originalListByPlayerAndStaffAndCriterion[data.participantId][data.staffId][data.criterionId].push(data.feedbackId)

    Vue.set(state, 'listByPlayerAndStaffAndCriterion', originalListByPlayerAndStaffAndCriterion )

    const playerFrequencyArray=state.listByPlayerAndFrequency

    if(!playerFrequencyArray[data.participantId]) {playerFrequencyArray[data.participantId]={}}
    if(!playerFrequencyArray[data.participantId][data.feedbackId]) { playerFrequencyArray[data.participantId][data.feedbackId] = { count: 0, staff: []} }
    playerFrequencyArray[data.participantId][data.feedbackId].staff.push(data.staffId)
    playerFrequencyArray[data.participantId][data.feedbackId].count++

    //playerFrequencyArray[data.participantId].sort((a,b) => { return a.count - b.count })

    Vue.set(state, 'listByPlayerAndFrequency', playerFrequencyArray)

    const playerCriterionArray=state.listByPlayerAndCriterion

    if(!playerCriterionArray[data.participantId]) playerCriterionArray[data.participantId]={}
    if(!playerCriterionArray[data.participantId][data.criterionId]) playerCriterionArray[data.participantId][data.criterionId]=[]
    playerCriterionArray[data.participantId][data.criterionId].push(data.feedbackId)

    Vue.set(state, 'listByPlayerAndCriterion', playerCriterionArray)


    Vue.set(state.meta, id, {
        delete: false
    })
}


const mutations={
    purgeData(state){
        const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }

    },
    //--------------------------------------------
    //   Mutations for loadAllFeedbackForEventAndStaff
    //
    
    loadAllFeedbackLinksForEventAndStaffRequest(state){
        state.status={loading:true}
        state.error=null
        state.listByPlayerAndFrequency={}
        state.listByPlayerAndStaffAndCriterion={}
    },
    loadAllFeedbackLinksForEventAndStaffSuccess(state, response){
        state.status={}
        state.list=[]
        state.data={}
        state.listByPlayerAndFrequency={}

        state.listByPlayerAndStaffAndCriterion = {}

        for(var idx in response.data.result.feedbackLinks){
            addToLists(response.data.result.feedbackLinks[idx])
        }

    },
    loadAllFeedbackLinksForEventAndStaffFailure(error){
        state.error=error
    },
    //--------------------------------------------
    //   Mutations for loadAllFeedbackForEvent
    //
    
    loadAllFeedbackLinksForEventRequest(){
        state.status={loading:true}
        state.error=null
    },
    loadAllFeedbackLinksForEventSuccess(state, response){
        state.status={}
        state.list=[]
        state.data={}
        state.listByPlayerAndFrequency={}
        state.listByPlayerAndStaffAndCriterion = {}

        for(var idx in response.data.result.feedbackLinks){
            addToLists(response.data.result.feedbackLinks[idx])
        }

    },
    loadAllFeedbackLinksForEventFailure(error){
        state.error=error
    },
    

    
    //--------------------------------------------
    //   Mutations for addFeedbackLink
    //
    
    addFeedbackLinkRequest(state, payload){
        state.error=null
        state.internal.target=payload
    },
    addFeedbackLinkSuccess(state, response){
        state.status = { responseCode: response.data.code}
        if(response.data.code===rc_feedback.OK){
            const {feedbackId, staffId, criterionId, participantId} = state.internal.target

            const coreList = state.listByPlayerAndStaffAndCriterion
            if(!coreList[participantId]) coreList[participantId]={}
            if(!coreList[participantId][staffId]) coreList[participantId][staffId]={}
            if(!coreList[participantId][staffId][criterionId]) coreList[participantId][staffId][criterionId]=[]
            
            const workingList = coreList[participantId][staffId][criterionId]

            if(!workingList.includes(feedbackId)) workingList.push(feedbackId)

            coreList[participantId][staffId][criterionId]=workingList
            Vue.set(state, 'listByPlayerAndStaffAndCriterion', coreList)

            state.internal={}

        }

    },
    addFeedbackLinkFailure(state, error){
        state.error=error
        state.internal={}
    },

      //------------ update data -------- \\
      propertyUpdate(state, payload) {
        const metaFields = "delete,new"
        const {field, id, value} = payload

        if(metaFields.includes(field)){
            state.meta[id][field]=value
            // Manage special fields...
            //switch (field) {
            //}
        } else {
            state.data[id][field]=value
        }
    },
    
    //--------------------------------------------
    //   Mutations for deleteFeedback
    //
    
    deleteFeedbackLinkRequest(state, payload){
        state.status={deleting:true}
        state.error=null
        state.internal.target=payload
    },
    deleteFeedbackLinkSuccess(state, response){
        if(response.data.code === rc_feedback.OK){
            const {feedbackId, staffId, criterionId, participantId} = state.internal.target

            const coreList = state.listByPlayerAndStaffAndCriterion
            const workingList = coreList[participantId][staffId][criterionId]

            const idx = workingList.indexOf(feedbackId)
            workingList.splice(idx,1)

            coreList[participantId][staffId][criterionId]=workingList

            Vue.set(state, 'listByPlayerAndStaffAndCriterion', coreList)
        }

        state.internal={}
        state.status = {responseCode: response.data.code}
    
    },
    deleteFeedbackLinkFailure(state, error){
        state.error=error
    },
}
export const feedbackLinkStore = {
    namespaced: true,
    state,
    actions,
    mutations
}