var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.questions[_vm.qid].type === 'role')?_c('ValidationProvider',{attrs:{"name":_vm.qid,"rules":_vm.applicableRules[_vm.questions[_vm.qid]]},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-input',{attrs:{"value":_vm.roletest},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',[_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v(_vm._s(_vm.questionNumber(_vm.qid)))]),_vm._v(" "+_vm._s(_vm.questions[_vm.qid].question)+" "),(_vm.questions[_vm.qid].required)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e()]),_c('v-row',{staticClass:"question"},[_c('v-col',{class:_vm.responsiveClass('question'),attrs:{"cols":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('v-select',{attrs:{"tabIndex":0,"label":"First preference","items":_vm.roleList},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.roles[item].name)+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.roles[item].name)+" ")]}}],null,true),model:{value:(_vm.value[0]),callback:function ($$v) {_vm.$set(_vm.value, 0, $$v)},expression:"value[0]"}})],1),(_vm.secondRole)?_c('v-col',{class:_vm.responsiveClass('question'),attrs:{"cols":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('v-select',{attrs:{"tabIndex":0,"label":"Second preference","items":_vm.roleList},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.roles[item].name)+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.roles[item].name)+" ")]}}],null,true),model:{value:(_vm.value[1]),callback:function ($$v) {_vm.$set(_vm.value, 1, $$v)},expression:"value[1]"}})],1):_vm._e()],1)]},proxy:true}],null,true),model:{value:(_vm.roletest),callback:function ($$v) {_vm.roletest=$$v},expression:"roletest"}}),_c('div',{staticClass:"field-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,607555738)}):_vm._e(),(_vm.questions[_vm.qid].type === 'short')?_c('ValidationProvider',{attrs:{"name":_vm.qid,"rules":_vm.applicableRules(_vm.questions[_vm.qid])},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"shrink",attrs:{"tabIndex":0,"readonly":_vm.readonly,"hide-details":"true"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text-wrap",staticStyle:{"display":"flex","flex-flow":"row"}},[_c('div',{staticStyle:{"font-weight":"600"}},[_vm._v(_vm._s(_vm.questionNumber(_vm.qid)))]),_c('div',{attrs:{"id":_vm.$style.htmlContainer},domProps:{"innerHTML":_vm._s(_vm.renderQuestionLabel(_vm.qid))}}),(_vm.questions[_vm.qid].required)?_c('div',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e()])]},proxy:true}],null,true),model:{value:(_vm.returnValue),callback:function ($$v) {_vm.returnValue=$$v},expression:"returnValue"}}),_c('div',{staticClass:"field-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3812436016)}):_vm._e(),(_vm.questions[_vm.qid].type==='long')?_c('ValidationProvider',{attrs:{"name":_vm.qid,"rules":_vm.applicableRules(_vm.questions[_vm.qid])},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{staticClass:"shrink",attrs:{"tabIndex":0,"readonly":_vm.readonly,"hide-details":"true"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text-wrap",staticStyle:{"display":"flex","flex-flow":"row"}},[_c('div',{staticStyle:{"font-weight":"600"}},[_vm._v(_vm._s(_vm.questionNumber(_vm.qid)))]),_c('div',{attrs:{"id":_vm.$style.htmlContainer},domProps:{"innerHTML":_vm._s(_vm.renderQuestionLabel(_vm.qid))}}),(_vm.questions[_vm.qid].required)?_c('div',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e()])]},proxy:true}],null,true),model:{value:(_vm.returnValue),callback:function ($$v) {_vm.returnValue=$$v},expression:"returnValue"}}),_c('div',{staticClass:"field-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2470274824)}):_vm._e(),(_vm.questions[_vm.qid].type==='select' && _vm.readonly)?_c('div',[_c('v-text-field',{staticClass:"shrink",attrs:{"tabIndex":0,"readonly":_vm.readonly,"hide-details":"true","value":_vm.renderResponse(_vm.qid, _vm.returnValue)},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text-wrap",staticStyle:{"display":"flex","flex-flow":"row"}},[_c('div',{staticStyle:{"font-weight":"600"}},[_vm._v(_vm._s(_vm.questionNumber(_vm.qid)))]),_c('div',{attrs:{"id":_vm.$style.htmlContainer},domProps:{"innerHTML":_vm._s(_vm.renderQuestionLabel(_vm.qid))}}),(_vm.questions[_vm.qid].required)?_c('div',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e()])]},proxy:true}],null,false,3155177020)})],1):_vm._e(),(_vm.questions[_vm.qid].type==='select' && !_vm.readonly)?_c('ValidationProvider',{attrs:{"name":_vm.qid,"rules":_vm.applicableRules(_vm.questions[_vm.qid])},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"tabIndex":0,"hide-details":"true","items":_vm.questionOptions,"item-text":"text","item-value":"value","label":_vm.questionNumber(_vm.qid)+_vm.questions[_vm.qid].question,"value":_vm.returnValue},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text-wrap",staticStyle:{"display":"flex","flex-flow":"row"}},[_c('div',{staticStyle:{"font-weight":"600"}},[_vm._v(_vm._s(_vm.questionNumber(_vm.qid)))]),_c('div',{attrs:{"id":_vm.$style.htmlContainer},domProps:{"innerHTML":_vm._s(_vm.renderQuestionLabel(_vm.qid))}}),(_vm.questions[_vm.qid].required)?_c('div',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e()])]},proxy:true}],null,true),model:{value:(_vm.returnValue),callback:function ($$v) {_vm.returnValue=$$v},expression:"returnValue"}}),_c('div',{staticClass:"field-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3893407495)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }