<template> 
    <div style="text-align:left;padding: 0px 10px 0px 10px">
        <ValidationObserver v-slot="{invalid, reset}">
            <v-row>
                    <v-col cols="12" style="text-align:right">
                        <v-btn @click.native.stop 
                            v-if="false" 
                            x-small 
                            v-on:click="saveDetails" 
                            depressed 
                            color="primary" 
                            class="save-btn"
                            style="margin-right:10px;"
                        >
                            Save
                        </v-btn>
                        <v-btn color="primary" x-small @click="dialog=true">
                            Add new question
                        </v-btn>
                    </v-col>
            </v-row>
            <v-row>
                <v-col cols="11">
                    <div v-if="context==='trialregistration' || context==='devregistration'" style="margin-bottom:10px;">
                        <h3 style="margin-bottom:10px;">
                            Trial Registration Questions
                        </h3>
                        These questions will be asked when {{ $pluralize(events[selectedEvent].participantPronoun) }} register
                    </div>
                    <div v-if="context==='devform'" style="margin-bottom:10px;">
                        <h3 style="margin-bottom:10px;">
                            Development Form Questions
                        </h3>
                        These questions are asked when a new development form is created
                    </div>
                    <div v-if="context==='episodeform'" style="margin-bottom:10px;">
                        <h3 style="margin-bottom:10px;">
                            Development Episode Questions
                        </h3>
                        These questions are asked for each {{events[selectedEvent].participantPronoun }}
                    </div>
                    <div class="panel-instructions">Click the "Add" button to add a new question, and drag the questions around to change their order. <br/>Changes are only saved when you press "Save"</div>
                </v-col>
                <v-col cols="1" style="margin-top:0">
                    <v-dialog v-model="dialog" persistent width="60%" max-width="600px">
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">New Question</span>
                            </v-card-title>
                            <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            <ValidationProvider name="newQuestion" rules="required" v-slot="{errors}">
                                                <v-text-field v-model="newQuestion" prepend-icon="mdi-chat-question" label="Question"/>
                                                <div class="error-message">{{errors[0]}} </div>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-select :items="questionTypes" dense label="Question Type" v-model="questionType" item-text="text" item-value="value"/>
                                        </v-col>
                                        <v-col cols="8">
                                            <v-checkbox v-if="questionType==='checkbox'" v-model="defaultCheck" label="Default value for checkbox"></v-checkbox>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-checkbox v-model="newRequired" label="Requires an answer"/>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="questionType==='role'">
                                        <v-col cols="12">
                                            <BoundedBox>
                                                <span slot="title">Role Query</span>
                                                <div slot="content">
                                                    This question will ask players to select 2 preferred roles from the list of roles you have defined for this tryout. 

                                                </div>
                                            </BoundedBox>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="questionType==='select'">
                                        <v-col cols="12">
                                            <BoundedBox v-if="questionType==='select'">
                                                <span slot="title">Selection Options</span>
                                                <v-col slot="content" cols="12" class="no-boundaries">
                                                    <v-row class="no-boundaries">
                                                        <v-col class="no-boundaries" cols="10" v-if="questionType==='select'">
                                                            <v-text-field hide-details="true" label="New option" v-model="newOption"></v-text-field>
                                                        </v-col>
                                                        <v-col class="no-boundaries text-right" cols="2" v-if="questionType==='select'">
                                                            <v-btn small  color="primary" @click="addOption">Add</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row class="no-boundaries">
                                                        <v-radio-group v-model="defaultRadio" >
                                                            <v-radio v-for="item,n in sortedQuestionOptions" :key="n" :label="item.text" :value="n" append-icon="mdi-delete" prepend-icon="mdi-delete">
                                                                <div slot="label" style="display:flex;flex-direction:row">
                                                                    <div class="no-boundaries text-right">
                                                                        <v-icon color="red" @click="deleteOption(n)">mdi-delete</v-icon>
                                                                    </div>
                                                                    <div class="no-boundaries">
                                                                    {{ item.text }}
                                                                    </div>
                                                                </div>
                                                            </v-radio>
                                                        </v-radio-group>
                                                    </v-row>
                                                </v-col>
                                            </BoundedBox>
                                        </v-col>
                                    </v-row>

                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn 
                                    color="blue darken-1"
                                    text
                                    @click="dialog=false"
                                >close</v-btn>
                                <v-btn color="blue darken-1" text @click="create(reset)" :disabled="invalid"
                                >Save</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <draggable tag="v-expansion-panels" v-model="orderedList">
                        <app-question-editor v-for="id in orderedList" :key="id" :questionId="id"/>
                    </draggable>
                </v-col>
            </v-row>
        </ValidationObserver>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import draggable from 'vuedraggable'
import AppQuestionEditor from '@/ClubPanes/NewTryoutSettingsPanels/AppQuestionEditor'
import {ValidationProvider, ValidationObserver} from 'vee-validate' 
import BoundedBox from '@/components/BoundedBox'
import {v4 as uuidv4} from 'uuid';

export default {
    props: {
        context: {
            type: String,
            default: 'trialregistration' 
        }
    },
    components: {
        draggable,
        AppQuestionEditor,
        ValidationObserver,
        ValidationProvider,
        BoundedBox
    },
    computed: {
        ...mapState({
            listByContext: state => state.appquestionStore.listByContext,
            status: state => state.appquestionStore.status,
            meta: state => state.appquestionStore.meta,
            questions: state => state.appquestionStore.data,
            selectedEvent: state => state.navigatorStore.selectedTryout,


            events:         state => state.tryoutStore.data,

        }),
        loading: function() {
            return this.status.loading
        },
        orderedList: {
            get() {
                return this.list
            },
            async set(value) {
                this.orderChanges=true
//                this.$root.$emit("settings-changes-made", this.componentId)
                await this.updateListOrder(value)
                for(var i in this.questions){
                    this.updateQuestion(this.questions[i])
                }
            }
        },
        changes() {
            return Object.keys(this.changeTracker).length>0 || this.orderChanges
        },
        sortedQuestionOptions(){
            return this.questionOptions.slice().sort(this.sortOptionsByOrder);
        },
        list(){
            return this.listByContext[this.context]
        }
    },

    methods: {
        ...mapActions('appquestionStore', {
            addQuestion: 'addQuestion',
            updateListOrder: 'updateListOrder',
            findAllQuestionsForEvent: 'loadAllQuestionsForEvent',
            updateQuestion: 'updateQuestion',
            deleteQuestion: 'delete'
        }),
        create: function async(reset) {
            reset()
            let self=this
            let length=self.list.length
            const newQuestion = {
                question: this.newQuestion,
                type: this.questionType,
                eventId: this.selectedEvent,
                required: this.newRequired,
                order: length,
                default: this.questionDefault,
                context: this.context,
            }
            newQuestion.options=JSON.stringify([])
            if(this.questionType==='select'){
                newQuestion.options = JSON.stringify(this.questionOptions)
                newQuestion.default = this.defaultRadio
            }
            if(this.questionType==='checkbox'){
                newQuestion.default = this.defaultCheck?1:0
            }

            self.addQuestion(newQuestion)
            this.questionOptions=[]
            this.newQuestion=""
            this.newRequired=false
            this.defaultCheck= ''
            this.defaultRadio=''
            this.newOption=''
            this.newQuestion=''
            this.newRequired=false
            this.dialog=false
        },
        saveDetails(){
            // Save all the questions that have been changed...

            for(var idx in this.questions){
                var questionId = this.questions[idx].id
                this.updateQuestion(this.questions[questionId])
                this.orderChanges = false
                this.changeTracker={}
                this.$root.$emit("settings-clear-question-changes-flags")
                this.$store.dispatch('alert/success', 'Tryout updated', {root: true})
            }
            this.questionOptions=[]
            this.newQuestion=""
            this.newRequired=false
            this.defaultCheck= ''
            this.defaultRadio=''
            this.newOption=''

        },
        addOption() {
            this.questionOptions.push({text: this.newOption, value: this.questionOptions.length})
            this.newOption=""
        },
        deleteOption(n){
            var options = this.questionOptions
            options.splice(n,1)
            this.questionDefault=0
            for(var idx in options){
                options[idx].value=idx
            }
            this.questionOptions = options
        },
        sortOptionsByOrder(a,b){
            return a.value - b.value
        }


    },
    mounted(){
        this.$root.$on('settings-question-changes-cleared', (id)=> {
            var self=this
            self.$delete(self.changeTracker, id)
        }),
        this.$root.$on('settings-question-changes-made', (id) => {
            var self=this
            self.$set(self.changeTracker, id, true)
            this.$root.$emit("settings-changes-made", this.componentId)
        })
        this.$root.$on('submit-all-changes', () => {
            if(this.changes){
                this.saveDetails()
            }
        })

        this.$root.$on('tryoutRefresh', this.refreshHandler)

        this.$root.$on('backout-all-changes', async () => {
            if(this.changes){
                await this.findAllQuestionsForEvent({eventId: this.selectedEvent})
            }
        })
    },    
    beforeDestroy(){
        this.$root.$off('tryoutRefresh', this.refreshHandler)
    },
    beforeRouteLeave(to, from, next) {
        if (Object.keys(this.changeTracker).length) {
            if (!window.confirm("Leave without saving?")) {
                return;
            }
        }
        next();
    },
/*
    beforeDestroy () {
        if(Object.keys(this.changeTracker).length){
            const answer = window.confirm('There are unsaved changes - click "OK" to save these before leaving, or "Cancel" to exit without saving ')
            if (answer) {
                this.saveTryout()
            } 

        }
    },
*/
    async created() {
        await this.findAllQuestionsForEvent({eventId: this.selectedEvent})
        this.componentId=uuidv4()
    },
    data() {
        return {
            refreshHandler: async() => {
                await this.findAllQuestionsForEvent({eventId: this.selectedEvent})
            },
            componentId: 0,
            dialog: false,
            newQuestion: '',
            newRequired: false,
            changeTracker: {},
            orderChanges: false,
            questionType: 'short',
            defaultCheck: '',
            defaultRadio: '',
            questionTypes: [
                {text: 'Short Answer', value: 'short'},
                {text: 'Long Answer', value: 'long'},
                {text: 'Selection', value: 'select'},
                {text: 'Checkbox', value: 'checkbox'},
                {text: 'Role', value: 'role'},
            ],
            newOption: '',
            questionOptions: [],
        }
    },
}
</script>

<style>

.qnum {
    font-weight: 600;
}

.error-message {
    color: red;
    text-align : left;
}
</style>