<template>
    <div class="description-panel">
        <v-row class="info-row">
            <span class="text-h6">Activity:&nbsp; </span>
            <span class="text-h6">{{activities[activityId].name}}</span>
        </v-row>

        <v-row class="info-row">
            <span class="text-h7">Expected Duration: {{activities[activityId].duration}} minutes</span>
        </v-row>
        <v-divider></v-divider>

        <v-row>
            <div class="ql-editor" v-html="descriptionHTML"/>
        </v-row>


    </div>
</template>

<script>

import {mapState} from 'vuex'

export default {
    props: ['activityId'],
    components: {

    },
    computed:{
        ...mapState({
            activities: state => state.activityStore.data,
        }),
        descriptionHTML(){
            return this.activities[this.activityId].description
        }
    },
    methods: {},
    created(){},
    data(){
        return {}
    }
}

</script>

<style scoped>

.description-panel{
    padding:10px;
    margin: 10px;
}

.info-row{
    padding:10px;
    margin: 10px;
}

</style>