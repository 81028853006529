import {teamrecommendationService}  from '../_services'
import Vue from 'vue'

const dataService = teamrecommendationService

function addToLists(data ) {
    const id = data.id
    const teamId = data.teamId
    const partId = data.participantId
    const role1 = data.role1
    const role2 = data.role2
    const staffId = data.staffId

    state.list.push(id)
    state.sortedList.push(data)
    Vue.set(state.data, id, data)
    Vue.set(state.meta, id, {
            delete : false,
    })

    var recommendationsForPlayer = state.recommendationsByPlayer[partId]
    if(!recommendationsForPlayer) recommendationsForPlayer=[]
    recommendationsForPlayer.push(id)
    Vue.set(state.recommendationsByPlayer, partId, recommendationsForPlayer)
    
    var  countData = state.recommendationCount[teamId]
    if(!countData) countData={}
    var count=0
    if(countData[partId]) count=countData[partId]
    countData[partId]=count+1
    Vue.set(state.recommendationCount, teamId, countData)

    var teamRecommendationsForTeam = state.recommendationsByTeamAndPlayer[teamId]
    if(!teamRecommendationsForTeam) teamRecommendationsForTeam = {}
    if(!teamRecommendationsForTeam[partId]) teamRecommendationsForTeam[partId]=[]
    teamRecommendationsForTeam[partId].push(id)
    Vue.set(state.recommendationsByTeamAndPlayer, teamId, teamRecommendationsForTeam)

    if(role1) {
        var roleRecommendations = state.recommendationsByRole[role1]
        if(!roleRecommendations) roleRecommendations=[]
        if(!roleRecommendations.includes(id)) roleRecommendations.push(partId)
        Vue.set(state.recommendationsByRole, role1, roleRecommendations)
    }
    if(role2) {
        roleRecommendations = state.recommendationsByRole[role2]
        if(!roleRecommendations) roleRecommendations=[]
        if(!roleRecommendations.includes(id)) roleRecommendations.push(partId)
        Vue.set(state.recommendationsByRole, role2, roleRecommendations)
    }

    var teamRecommendationsByStaff = state.recommendationsByStaff
    if(!teamRecommendationsByStaff[staffId]) teamRecommendationsByStaff[staffId]={}
    if(!teamRecommendationsByStaff[staffId][partId]) teamRecommendationsByStaff[staffId][partId]={}
    teamRecommendationsByStaff[staffId][partId]={teamId: teamId, role1Id: role1, role2Id: role2}

    Vue.set(state, 'recommendationsByStaff', teamRecommendationsByStaff)
}

/*
function sortListByOrder() {
        state.list.sort((a,b) => {
            return state.data[a].name.localeCompare(state.data[b].name)
        })
}
*/

//---------------------------------------------------------------------
function initialiseState(){
    return {
        error: null,
        status: {},
        data: {},
        meta: {},
        list: [],
        sortedList: [],
        recommendationCount:{},
        recommendationsByTeamAndPlayer: {},
        recommendationsByPlayer: {},
        recommendationsByRole:{},
        recommendationsByStaff:{},
    }
}

const state = initialiseState()

//---------------------------------------------------------------------
const actions = {
    purgeData({commit}){
        commit('purgeData')
    },

    async findAllForStaff({commit},payload) {
        try{
            const {staffId, eventId} = payload
            commit('loadRequest')
            const response = await dataService.findAllForStaff(staffId, eventId)
            commit('loadSuccess', response)
        } catch(error) {
            commit('loadFailure', error)
        }
    },
    async quietFindAllForStaff({commit},payload) {
        try{
            const {staffId, eventId} = payload
            commit('quietLoadRequest')
            const response = await dataService.findAllForStaff(staffId, eventId)
            commit('loadSuccess', response)
        } catch(error) {
            commit('loadFailure', error)
        }
    },
    async findAllForEvent({commit}, payload) {
        try{
            const {eventId} = payload
            commit('loadRequest')
            const response = await dataService.findAllForEvent(eventId)
            commit('loadSuccess', response)
        } catch(error) {
            commit('loadFailure', error)
        }
    },
    async update({commit},payload) {
        try{
            const recommendation = payload
            commit('updateRequest')
            const response = await dataService.update(recommendation)
            commit('updateSuccess', response)
        } catch(error) {
            commit('updateFailure', error)
        }
    },
}



//---------------------------------------------------------------------
const mutations = {
purgeData(state){
    const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }
    },
    updateRequest(state) {
        state.internal={loading:true}
        state.error=null
    },
    updateSuccess(state, response) {
        state.internal={
            newId: response.teamrecommendation.id
        }
        // if this is a new recommendation add it to the state lists...
        if(!state.data[response.teamrecommendation.id]){
            addToLists(response.teamrecommendation)
        } else {
            // Otherwise just replace it with the new data
            state.data[response.teamrecommendation.id] = response.teamrecommendation
        }
    },
    updateFailure(state, error) {
        state.internal={}
        state.error = error
    },

    loadRequest(state) {
        state.status = {loading:true}
        state.error=null
    },
    quietLoadRequest(state) {
        state.status = {}
        state.error=null
    },
    loadSuccess(state, payload){
        state.data={}
        state.recommendationCount={}
        state.recommendationsByTeamAndPlayer={}
        state.recommendationsByPlayer={}
        state.recommendationsByRole={}
        state.list=[]
        state.sortedList=[]
        const {teamrecommendations} = payload
        for(var idx in teamrecommendations) {
            addToLists(teamrecommendations[idx])
        }
        state.status={}
      //  sortListByOrder()
    },
    loadFailure(state, error) {
        state.status={}
        state.error=error
    },

}

export const teamrecommendationStore = {
    namespaced: true,
    state,
    actions,
    mutations
}