<template>
    <div :style="cssProps" class="bounded-box">
        <div class="bounded-title"><slot name="title"></slot></div>
        <div class="bounded-content"><slot name="content"></slot></div>
    </div>
</template>

<script>
    export default {
        name: 'BoundedBox',
        props: {
            borderWidth:{
                default: '1px',
            },
            titleSize: {
                default: 'small',
            },
            titleWeight:{
                default:300,
            },
            sideMargin: {
                default: '10px',
            },
            sidePadding: {
                default:'10px',
            },
            borderColor: {
                default: 'grey'
            },
            height: {
                default: 'auto'
            },
            topPadding: {
                default: '10px'
            },
            bottomPadding: {
                default: '0px'
            },
            topMargin: {
                default: '0px'
            },
        },
        computed: {
            cssProps() {
                return {
                    '--title-weight': this.titleWeight,
                    '--title-size': this.titleSize,
                    '--border-width': this.borderWidth,
                    '--top-padding': this.topPadding,
                    '--bottom-padding': this.bottomPadding,
                    '--side-margin': this.sideMargin,
                    '--side-padding' : this.sidePadding,
                    '--border-color' : this.borderColor,
                    '--top-margin' : this.topMargin,
                    '--height' : this.height,
                }
            }
        },
    }
</script>

<style scoped >
.bounded-box {
        border-radius: 10px;
        border-width: var(--border-width);
        border-color: var(--border-color);
        border-style: solid;
        padding-left: var(--side-padding);
        padding-right:var(--side-padding);
        margin-right: var(--side-margin);
        margin-left: var(--side-margin);
        padding-top: var(--top-padding);
        padding-bottom: var(--bottom-padding);
        height: var(--height);
        margin-top: var(--top-margin);
        flex-grow:1;
    }
.bounded-title {
    text-align: center;
    font-size: var(--title-size);
    font-weight: var(--title-weight);
    color:var(--border-color);
    margin-top: -1em;
    margin-left:20px;
    background-color: white;
    width: max-content;
    padding-left:10px;
    padding-right: 10px;
}

.bounded-content {
    height: calc(100% - 1em);
}
</style>