//const apiUrl = process.env.VUE_APP_CSAPI
import { 
   // handleResponse, 
    sendMessage , logout } from "../_helpers/service-tools"

export const userService = {
    login,
    logout,
    register,
    update,
    resend,
    recover,
    reset,
    createManual,
    getUserByEmail,
    getUserById,
    getUserByToken,
    verifyPasswordToken,
    newPasswordToken,
    validate,
    completeUser,
    sendOTP,
    verifyOTP,
    otpLogin,
    /*
    getAll,
    getById,
    update,
    delete: _delete
    */
}

async function login(payload) {
    const {username, password, userId} = payload

    try{
        var user=null
        if(userId){
            user =  await sendMessage('POST', 'auth/login', null, {password, userId})
        } else {
            user =  await sendMessage('POST', 'auth/login', null, {email:username, password})
        }
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('jwt', user.token)
        return user

    } catch (error){
        console.error('Error or some sort')
        console.error(error)
        throw(error)
    }
}

async function otpLogin(payload){
    const newPayload={
        userId: payload.userId,
        otp: payload.otp
    }

    if(Object.prototype.hasOwnProperty.call(payload, 'email'))
        newPayload['email'] = payload.email

    return await sendMessage('POST', 'auth/otpLogin', {},newPayload)
}

async function validate(payload){
    return await sendMessage('GET', 'auth/validate', {userId: payload.userId}, null)
}

async function createManual(user) {
    return await sendMessage('POST','auth/manualuser', null, user)
}


async function register(user) {
    return await sendMessage('POST', 'auth/register', null, user)
}

async function completeUser(payload) {
    return await sendMessage('PATCH', 'auth/completeuser', {userId:payload.userId}, {email: payload.email, phone: payload.phone, password:payload.password, profileImage: payload.profileImage})
}



async function update(payload) {
    return await sendMessage('PATCH', 'auth/user', {userId: payload.userId, clubId: payload.clubId, eventId: payload.eventId} , payload.user)
}

async function resend(email) {
    return await sendMessage('POST', 'auth/resend', null, {email})
}

async function recover(email) {
    return await sendMessage('POST', 'auth/recover', null, {email})
}

async function reset(user, token) {
    return await sendMessage('POST', 'auth/reset/'+token, null, {password: user.password, confirmPassword: user.confirmPassword})
}

async function getUserById(payload) {
    return await sendMessage('GET', 'auth/userById', {userId: payload.userId, clubId: payload.clubId, eventId: payload.eventId}, null)
}

async function getUserByEmail(email) {
    return await sendMessage('GET', 'auth/userByEmail', {email}, null)
}

async function getUserByToken(payload) {
    return await sendMessage('GET', 'auth/userByToken', {token:payload.token}, null)
}

async function verifyPasswordToken(payload) {
    return await sendMessage('GET', 'auth/verifyPasswordToken', {token: payload.token}, null)
}

async function newPasswordToken(payload){
    return await sendMessage('GET', 'auth/newPasswordToken', {token: payload.token}, null)
}

async function sendOTP(payload){
    const newPayload={}

    if(Object.prototype.hasOwnProperty.call(payload, 'recaptchaToken'))
        newPayload['recaptchaToken'] = payload.recaptchaToken 
    if(Object.prototype.hasOwnProperty.call(payload, 'email'))
        newPayload['email'] = payload.email
    return await sendMessage('POST', 'auth/sendOTP', {participantId: payload.participantId }, newPayload)
}

async function verifyOTP(payload){
    return await sendMessage('GET', 'auth/verifyOTP', {otp: payload.otp, userId: payload.userId}, null)
}
