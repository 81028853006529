<template>
    <v-dialog 
        v-model="show"
        persistent
        max-width="80%"
        width="600px"
    >
        <ValidationObserver v-slot="{handleSubmit, invalid, reset}">
            <v-card style="opacity:1">
                <v-card-title v-if="identifier">{{ actionName }}: {{ identifier }}</v-card-title>
                <v-card-title v-else>{{ actionName }}</v-card-title>
                <v-card-text>
                    <div style="font-weight:600;margin-bottom: 20px;">
                        Please be aware that this action cannot be undone
                    </div>
                    <div style="margin:10px;">
                        <slot name="consequences"></slot>

                    </div>
                    <div style="font-style:italic">
                        If you are sure, please type "permanently delete" into the text box below

                    </div>
                    <ValidationProvider name="Verification" rules="required|permanentDelete" v-slot="{errors}">
                        <v-text-field
                            hide-details="auto" 
                            v-model="deleteText"
                            outlined
                            background-color="yellow"
                            color="red"
                            x-small
                            dense
                            placeholder="permanently delete"
                            ref="defaultFocus"
                            @keydown.enter.prevent="handleSubmit(confirmAction(reset))"
                        >
                        </v-text-field>
                        <div class="form-error">{{ errors[0] }}</div>
                    </ValidationProvider>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn text color="primary" @click="closeDialog(reset)">Cancel</v-btn>
                        <v-btn
                            :disabled="invalid"
                            @click="confirmAction(reset)"
                        >{{ actionName }}</v-btn>
                </v-card-actions>
            
            </v-card>
        </ValidationObserver>

    </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';


export default{
    props: {
        'identifier':{
            default: null,
        },
        'confirm': {
            default:(()=>{return})
        },
        'cancel': {
            default: (()=>{return})
        },
        'show': {
            type:Boolean,
        },
        'actionName': {
            default: 'Action'
        }

    },
    components:{
        ValidationObserver,
        ValidationProvider,
    },
    computed: {

    },
    methods: {
        closeDialog(reset){
            reset()
            this.deleteText=''
            this.cancel()
        },
        confirmAction(reset){
            reset()
            this.deleteText=''
            this.confirm()
        }

    },
    watch: {},
    async created(){

    },
    data(){
        return {
            deleteText: '',

        }
    }

}
</script>