<template>
    <div>
        <div :id="elementId"></div>
        <v-btn v-if="download" @click="downloadQR">Download</v-btn>
    </div>
</template>

<script>

import QRCodeStyling from 'qr-code-styling'
import { v4 as uuidV4 } from 'uuid'

export default {
    props: {
        value: {type: String, required: true},
        width: {type: Number, required: false, default: 300},
        height: {type: Number, required: false, default: 300},
        color: {type: String, required: false, default: '#000'},
        bgColor: {type: String, required: false, default: '#FFF'},
//        image: {type: String, required: false, default: 'https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/clublogos/MUVC+Logo+Trans.png'},
        filename: {type: String, required: false, default: 'qrcode'},
        qrOptions: {type: Object, required: false, default: () => {return {}}},
        dotsOptions: {type: Object, required: false, default: () => {return {}}},
        backgroundOptions: {type: Object, required: false, default: () => {return {}}},
        cornersSquareOptions: {type: Object, required: false, default: () => {return {}}},
        cornersDotOptions: {type: Object, required: false, default: () => {return {}}},
        fileExt: {type: String, required: false, default: 'svg'},
        download: {type: Boolean, required: false, default: false},
        myClass: {type: String, required: false, default: ''},
        imgClass: {type: String, required: false, default: ''},
        downloadButton: {type: String, required: false, default: ''},
        downloadOptions: {type: Object, required: false, default: () => {return {}}},
        imageOptions: {type: Object, required: false, default: () => {return {}}}

    },
    methods: {
        downloadQR: function () {

            this.qrCode.download({name: this.filename, extension: "svg"})
        }
    },
    mounted() {

        this.qrCode = new QRCodeStyling({
            width: this.width,
            height: this.height,
            type: 'canvas',
            data: this.value,
            image: this.image,
            qrOptions: this.qrOptions,
            imageOptions: this.imageOptions,
            dotsOptions: this.dotsOptions,
            backgroundOptions: this.backgroundOptions,
            cornersDotOptions: this.cornersDotOptions,
            cornersSquareOptions: this.cornersSquareOptions,
            fileExt: this.fileExt,
            download: this.download,
            myClass : this.myClass,
            imgClass: this.imgClass,
            downloadButton: this.downloadButton,
            downloadOptions: this.downloadOptions,

        })
        this.qrCode.append(document.getElementById(this.elementId))
    },
    created() {

        this.elementId = uuidV4()
    },
    data() {
        return {
            qrCode: null,
            elementId: null, 
        }
    }
}
</script>