export class Stack {


    constructor(list) {
      console.log('Creating stack');
      console.log(list); 
      if(list) 
        this.items=list
      else
        this.items=[]

    }

    set(list) {
      this.items=list
    }
    push(element) {
      this.items.push(element)
    }
    pop() {
      return this.items.pop()
    }

    isempty(){
      return this.items.length === 0
    }

    empty(){
      this.items=[]
    }

    size(){
      return this.items.length
    }

    contents(){
      return this.items
    }

  }