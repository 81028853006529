<template>
    <loading v-if='loading'/>
    <v-parallax v-else height="1000" style="height:95vh" src="https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/app_assets/coachonphone.jpg" >
        <v-row style="overflow-y: scroll">
            <v-col v-if="checkinState==='state-0'" cols="12" class="checkin-pane">
                <ValidationObserver v-slot="{invalid}">
                    <v-card class="mx-auto cs-checkin-box" :loading="loadingData" outlined shaped elevation="5">
                        <v-card-text v-if="!loadingData">
                            <div class="club-banner-wrapper">
                                <div>
                                    <v-img max-width="50" :src="clubs[tryouts[eventId].clubId].logo"/>
                                </div>
                                <div>
                                    <h3>{{ clubs[tryouts[eventId].clubId].name }}</h3>
                                </div>
                            </div>
                            <h3>{{ tryouts[eventId].title }}</h3>
                            <br/>
                            <h3>Athlete Check-in</h3>
                            <v-divider/>
                            <div v-if="stage < 4" :class="responsiveClass('state-0-step-1')">
                                <div class="state-0-step-label">
                                    Step 1:
                                </div>
                                <div class="state-0-step-text">
                                    <v-text-field v-model="surname" label="Type in your family (last) name"/>
                                </div>
                            </div>
                            <div v-if="stage>=1 && stage <4" :class="responsiveClass('state-0-step-1')">
                                <div class="state-0-step-label">
                                    Step 2:
                                </div>
                                <div class="state-0-step-text">
                                    <v-select v-model="selectedParticipant" label="Pick your name out of this list" :items="matchingNames" item-text="text" item-value="value"/>

                                </div>
                            </div>
                            <div v-if="stage===2">
                                <div class="state-0-step-label">
                                    Step 3:
                                </div>
                                <div class="state-0-step-text">
                                    <ValidationProvider name="Email Address" rules="required|email" v-slot="{errors}">
                                        <v-text-field v-model="emailAddress" label="Email to send One-time password and future communications to"/>
                                        <div class="form-error">{{  errors[0] }}</div>
                                    </ValidationProvider>
                                </div>
                                <v-overlay absolute :value="sendingEmail">
                                    <v-progress-circular
                                    indeterminate
                                    color="primary"
                                    ></v-progress-circular>
                                </v-overlay>

                            </div>
                            <div v-if="stage===3">
                                <div class="state-0-step-label">
                                    Step 3:
                                </div>
                                <div class="state-0-step-text">
                                    We will send a One Time Password to {{ emailAddresses }}. Please look for it in your email client.
                                </div>
                                <v-overlay absolute :value="sendingEmail">
                                    <v-progress-circular
                                    indeterminate
                                    color="primary"
                                    ></v-progress-circular>
                                </v-overlay>
                            </div>
                            <div v-if="stage===4">
                                <div>
                                    We just sent a one-time passcode to {{ maskedEmail }}. Please type it here.
                                    <v-otp-input :class="responsiveClass('otp-box')" v-model="password" :disabled="otpLoading" @finish="onOTPEntered"/>
                                </div>

                                <v-overlay absolute :value="otpLoading">
                                    <v-progress-circular
                                    indeterminate
                                    color="primary"
                                    ></v-progress-circular>
                                </v-overlay>

                            </div>
                            <div v-if="stage===5">
                                <div class="state-0-step-label">
                                    Step 4:
                                </div>
                                <div class="state-0-step-text">
                                    A photo helps coaches recognise you when they are making decisions
                                    <div slot="content">
                                        <div v-if="account.user && account.user.details && account.user.details.profileImage">
                                            <v-img contain :src="account.user.details.profileImage" height="150px" max-height="150px" />
                                        </div>
                                        <div v-else>
                                            <v-icon size="200">mdi-account-circle</v-icon>
                                        </div>
                                    </div>
                                    <ImageUpload consentRequired text label="Upload profile image" type="User Image" :cropper="true"/>
                                </div>
                            </div>
                            <div v-if="stage===6">
                                <div class="state-0-step-label">
                                    Step 5:
                                </div>
                                <div class="state-0-step-text">
                                    What are your preferred roles?
                                    
                                    <div>
                                        <div>
                                            <v-select v-model="inputRole1" label="First preference" :items="roleList">
                                                <template v-slot:item="{item}">
                                                    {{ roles[item].name }}
                                                </template>
                                                <template v-slot:selection="{item}">
                                                    {{ roles[item].name }}
                                                </template> 
                                            </v-select>
                                        </div>
                                        <div>
                                            <v-select v-model="inputRole2" label="Second preference" :items="roleList">
                                                <template v-slot:item="{item}">
                                                    {{ roles[item].name }}
                                                </template> 
                                                <template v-slot:selection="{item}">
                                                    {{ roles[item].name }}
                                                </template> 
                                            </v-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="stage===6.2">
                                <div class="state-0-step-label">
                                    Step 5:
                                </div>
                                <div class="state-0-step-text">
                                    Your coaches have a few questions they would like you to answer
                                    <tryout-questions :checkinMode="true" :changeState="questionsReady" />
                                </div>
                            
                            </div>
                            <div v-if="stage===7">
                                <h3> That's it!  You're checked in...</h3>
                                <RenderParticipantNote :eventId="eventId"/>

                            </div>
                            <v-overlay v-if="showRecaptcha">
                                <div class="form-group row">
                                    <label for="robot" class="col-sm2 col-form-label"></label>
                                    <div class="col-sm-10 padding-on-top">
                                        <vue-recaptcha ref="recaptcha" @verify="onVerifyRecaptcha" :sitekey="recaptchaSiteKey"></vue-recaptcha>
                                    </div>
                                </div>

                            </v-overlay>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn v-if="stage===4" @click="resetEmail()" text > Back </v-btn>
                            <v-spacer/>
                            <v-btn v-if="stage===2" @click="emailCollected()" :disabled="invalid" color="primary" >Send One Time Password</v-btn>
                            <v-btn v-if="stage===3" @click="requestOTP" color="primary" :disabled="otpLoading || hideOTPSend">Send One Time Password</v-btn>
                            <v-btn v-if="stage===4" @click="requestOTP" > Resend OTP </v-btn>
                            <v-btn v-if="stage===5" color="primary" >Next</v-btn>
                            <v-btn v-if="stage===5" @click="skipPhoto=true" text >Skip</v-btn>
                            <v-btn v-if="stage===6" @click="saveRoles()"  >Next</v-btn>
                            <!-- 
                            <v-btn 
                                v-else
                                @click="state0Find"
                                color="primary"
                                :disabled="!readyToCheckin"
                            >
                                Check In
                            </v-btn>
                        -->
                        </v-card-actions>
                    </v-card>
                </ValidationObserver>
            </v-col>
        </v-row>
    </v-parallax>
</template>

<script>
import Loading from '@/components/loading'

import {logout, responsiveClass } from '../_helpers/service-tools'
import { mapActions, mapState } from 'vuex';

import { router } from '../_helpers'
import { rc_auth,rc_sessionCheckin} from "@/_helpers/responseCodes"
import { VueRecaptcha } from 'vue-recaptcha'
import TryoutQuestions from '@/ClubPanes/TryoutRegistrationPanels/TryoutQuestions'
import RenderParticipantNote from '../components/RenderParticipantNote.vue'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
//import RoleQuestions from '../components/roleQuestions'

import ImageUpload from '@/components/ImageUpload'
const _recaptchaSiteKey = process.env.VUE_APP_RECAPTCHA_KEY

export default{
    props: [ 'eventId', 'sessionId' ],
    components:{
        Loading,
        VueRecaptcha,
        TryoutQuestions,
//        RoleQuestions,
        RenderParticipantNote,
        ImageUpload,
        ValidationObserver,
        ValidationProvider,
    },
    computed: {
        ...mapState({
            clubs:              state => state.clubStore.data,

            tryouts:            state => state.tryoutStore.data,

            participants:       state => state.participantStore.data,
            participantList:    state => state.participantStore.list,

            appquestionList:    state => state.appquestionStore.list,
            formResponses:      state => state.tryoutregStore.qresponses,

            qResponses:         state => state.qresponseStore.data,
            qResponseList:      state => state.qresponseStore.list,

            account:            state => state.account,
            roleList:   state => state.roleStore.list,
            roles:      state => state.roleStore.data,

        }),
        loading(){
            return false ||
                false;
        },
        matchingNames(){
            var names=[]
            for(var idx in this.participantList){
                if(this.participants[this.participantList[idx]].lastName.localeCompare(this.surname, undefined, {sensitivity: 'base'}) ===0){
                    names.push({
                        value: this.participantList[idx],
                        text:   this.participants[this.participantList[idx]].firstName + ' ' +
                                this.participants[this.participantList[idx]].lastName
                    })
                }
            }
            return names
        },
        accountGenerated(){
            if(this.participantList >0){
                return this.participants[this.selectedParticipant].generated
            } else {
                return false
            }
        },
        needEmailAddress(){
            // If the user has been genrated we need to fetch and send an email address
            if(this.participantList.length===0) return true
            //if(this.participants[this.selectedParticipant].generated && !this.emailValid) return true
            if(this.participants[this.selectedParticipant].generated ) return true
            return false
        },
        emailAddressValid(){
            return false
        },
        emailAddresses(){
            const addresses=[]
            if(this.participants[this.selectedParticipant].email) addresses.push(this.participants[this.selectedParticipant].email)
            if(this.participants[this.selectedParticipant].parentEmail) addresses.push(this.participants[this.selectedParticipant].parentEmail)
            if(addresses.length === 1) return addresses[0]

            return `${addresses[0]} and ${addresses[1]}`
        },
        stage(){
            var state=0

            if(state === 0 && this.matchingNames.length>0) state =1                                 // Get surname
            if(state === 1 && this.selectedParticipant !== '' &&  this.needEmailAddress) { state = 2 } // Need to get email address first
            if(state === 1 && this.selectedParticipant !== '' && !this.needEmailAddress) {state = 3 } // User already exists
            if(state === 2 && this.otpSent) state = 4                                               // Send and get OTP
            if(state === 3 && this.otpSent) state = 4                                               // Send and get OTP
            if(state === 4 && this.checkedIn) state = 5                                             // Take photo
            if(state === 5 && (this.photoTaken || this.skipPhoto) ) state = 6          // Ask about playing roles
            if(state === 6 && (this.rolesAnswered)) state = 7

//            if(state === 5 && (this.photoTaken || this.skipPhoto) && this.appquestionList.length>0) state = 6          // Ask questions
//            if(this.questionsAnswered) state = 7

            return state
        },
        photoTaken(){
            return this.account.user && this.account.user.details && this.account.user.details.profileImage
        },
        rolesAnswered(){
            if(this.savingRoles) return false
            if((!Object.prototype.hasOwnProperty.call(this.participants[this.selectedParticipant], 'prefRole1')) ||
               (!Object.prototype.hasOwnProperty.call(this.participants[this.selectedParticipant], 'prefRole2'))) {
                return false
            } 
               
            return this.participants[this.selectedParticipant].prefRole1 || this.participants[this.selectedParticipant].prefRole2
        }
    },
    methods: {
        ...mapActions('tryoutStore', [
            'loadTryout',
        ]),
        ...mapActions('navigatorStore', [
            'setSelectedParticipant',
            'selectTryout',
            'selectClubNoPurge',
            'setSelectedSession',

        ]),
        ...mapActions('participantStore', [
            'publicParticipantList',
            'loadParticipant',
            'updateParticipantProp',
            'updateParticipant',
            'loadParticipantsForEvent'
        ]),
        ...mapActions('clubStore', [
            'loadClub'
        ]),
        ...mapActions('account', [
            'validate',
            'sendOTP',
            'verifyOTP',
            'otpLogin',
        ]),
        ...mapActions('appquestionStore', [
            'loadAllQuestionsForEvent',
        ]),
        ...mapActions('qresponseStore', [
            'addQResponse',
            'loadAllQResponsesForParticipant',
        ]),
        ...mapActions('sessionStore', [
            'sessionCheckin'
        ]),
        ...mapActions('roleStore', [
            'loadAllRolesForEvent',
        ]),

        responsiveClass: responsiveClass,

        async saveRoles(){
            this.savingRoles=true
            await this.updateParticipantProp({field: 'prefRole1', id: this.selectedParticipant, value: this.inputRole1})
            await this.updateParticipantProp({field: 'prefRole2', id: this.selectedParticipant, value: this.inputRole2})
            await this.updateParticipant(this.participants[this.selectedParticipant])
            this.savingRoles=false
        },
        resetEmail(){
            this.emailValid=false
            this.emailAddress=null
        },

        emailCollected(){
            this.emailValid=true
            // Now send the OTP with the email address provided... 
            this.requestOTP()
        },
        async requestOTP(RCresponse=null){
            this.hideOTPSend=true
            this.sendingEmail=true

            const newPayload = {userId: this.participants[this.selectedParticipant].userId, participantId: this.selectedParticipant}

            if(this.emailValid) newPayload['email'] = this.emailAddress
            if(RCresponse && !RCresponse.isTrusted) newPayload['recaptchaToken'] = RCresponse

            const response = await this.sendOTP(newPayload)

            switch(response.data.code){
                case rc_auth.NEED_RECAPTCHA:
                    this.showRecaptcha=true
                    break;
                case rc_auth.EMAIL_CHANGE_NA:
                    this.$toast.error('Could not send to a different email address. Send mail to support for assistance')
                    break;
                case rc_auth.OTP_SENT:
                    this.maskedEmail=response.data.result.email
                    this.otpSent=true
                    break;

            }
            this.sendingEmail=false
        },
        async onOTPEntered(otp){
            this.otpLoading=true
            const response = await this.otpLogin({only: true, otp:otp, userId: this.participants[this.selectedParticipant].userId, email:this.emailAddress})
            
            this.otpLoading=false
            switch(response.data.code){
                case rc_auth.LOGIN_OK:
                        this.otpVerified = true
                        await this.loadParticipant({participantId:this.selectedParticipant})
                        await this.loadAllRolesForEvent({eventId: this.eventId})
                        this.checkin()    
                        break;
                case rc_auth.LOGIN_FAILED:
                        this.$toast.error('Your OTP was incorrect. Please check your email again, or request a new OTP')
                        break;

            }
        },
        async onVerifyRecaptcha(RCresponse){
            this.requestOTP(RCresponse)
            this.showRecaptcha=false
        },
        async questionsReady(){

            // First save the question responses...
            for(var idx2 in this.appquestionList){
                const qid = this.appquestionList[idx2]

                await this.addQResponse({
                    participantId: this.selectedParticipant,
                    eventId: this.eventId,
                    questionId: qid,
                    response: this.formResponses[qid]
                })
            }
            // Move on to completion page
            this.questionsAnswered=true
        },
        async checkin(){
            var  qresponses=[]

            const response = await this.sessionCheckin({sessionId: this.sessionId, userId: this.account.user.details.id, participantId: this.selectedParticipant })
                switch(response.data.code){
                    case rc_sessionCheckin.NO_PARTICIPANT_FOUND:
                        this.$toast.error('There was a problem checking you in. Please start the process again, and if this fails again contact an event staff member')
                        this.checkinState='state-0'
                        break
                    case rc_sessionCheckin.NO_PARTICIPANT_FOR_USER:
                        // Checkin failed... the user is not connected to a participant...
                        //this.$toast.error("We couldn't find you in this event. Please try locating your name using the Find tab below")
                        this.checkinState='state-7'
                        break
                    case rc_sessionCheckin.USER_ALREADY_LINKED:
                        this.$toast.error('We couldn\'t link you to this participant because your account is already linked to another participant in this event.')
                        router.push('/')
                        break
                    case rc_sessionCheckin.USER_CHECKED_IN:
                        await this.selectClubNoPurge(this.tryouts[this.eventId].clubId)
                        await this.selectTryout(this.eventId)
                        await this.setSelectedSession(this.sessionId)
                        this.setSelectedParticipant(this.selectedParticipant)
                        qresponses = await this.loadAllQResponsesForParticipant({participantId: this.selectedParticipant, episodeId: this.selectedEpisode})
                        if(qresponses.length >0) this.questionsAnswered=true
                        this.checkedIn=true

                        break
                } 
        }
    },
    watch: {},
    async created(){
        this.checkinState = 'state-0'

        logout({quiet:true})        // Logout an existing user

        this.loadingData=true
        this.selectTryout(this.eventId)
        this.setSelectedSession(this.sessionId)

        try{
            await this.loadTryout(this.eventId)
            await this.publicParticipantList({eventId: this.eventId})
            await this.loadClub(this.tryouts[this.eventId].clubId)
            await this.loadAllQuestionsForEvent({eventId: this.eventId})

        } catch (error) {
            console.error('Error creating checkin ')
            console.error(error)
        }
        
        this.loadingData=false
    },
    data(){
        return {
            inputRole1:'',
            inputRole2:'',
            savingRoles:false,
            sendingEmail:false,
            emailAddress: '',
            emailValid: false,
            maskedEmail:'',
            hideOTPSend: false,
            recaptchaSiteKey: _recaptchaSiteKey,
            loadingData: false,
            currentState: 0,
            surname: '',
            selectedParticipant: '',
            password: '',
            otpLoading: false,
            otpSent: false,
            readyToCheckin: false,
            showRecaptcha: false,
            otpVerified: false,
            checkedIn: false,
            skipPhoto: false,
            questionsAnswered: false,
        }
    }

}
</script>

<style scoped>

.checkin-pane{
    margin-top: 0px;
    margin-bottom: auto;
    width:100%;
}

.club-banner-wrapper{
    display: flex;
    flex-flow: row;
}

.state-0-step-1{
    display:flex;
    flex-flow: row;
}
.state-0-step-1.mobile{
    flex-flow: column;
}
.state-0-step-label{
    font-weight:600;
    width:12em;
    margin-top:1em;
    margin-bottom:auto;
    font-size:large;
    text-align: left;

}
.state-0-step-text{
    margin-left:5px;
    width:100%;
}

.otp-box{
    width: 300px !important;
    margin-left:auto;
    margin-right:auto;
    margin-top:40px;
}

.otp-box.mobile{
    width:100%!important;
}
</style>