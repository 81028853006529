import { packageService } from "../_services"
import Vue from 'vue'

function initialiseState(){
    return {
        status: {},
        list: {},
        data: {},
        meta: {},
        error: null,
        internal: {},
        regPackages: [],
    }
}

const state = initialiseState()

const actions = {
    purgeData() {
        // No need to purge the package tables
    },
    async findActive({commit}){
        try {
            commit('findActiveRequest')
            const response = await packageService.findActive()
            commit('findActiveSuccess', response.packages)
        } catch (error) {
            commit('findActiveFailure', error)
            console.error('Failure')
            console.error(error)
        }
    }
}

function addToLists(data) {
    const id = data.id

    state.list.push(id)
    
    if(data.type==="registrations"){
        state.regPackages.push(id)
    }

    Vue.set(state.data, id, data)
    Vue.set(state.meta, id, {
        delete: false
    })
}

const mutations = {
    findActiveRequest(state){
        state.status={loading: true}
        state.error=null
    },

    findActiveSuccess(state, list) {
        state.status={}
        state.list=[]
        state.data={}
        state.meta={}
        state.internal={}

        for(var idx in list) {
            addToLists(list[idx])
        }
    },

    findActiveFailure(state, error) {
        state.error = error,
        state.status={}
    }
}

export const packageStore = {
    namespaced: true,
    state,
    actions,
    mutations
}