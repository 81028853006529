<template>
    <ValidationObserver v-slot="{invalid,  reset}">
        <v-card >
            <v-card-title v-if="devStreamId === ''">
                        Edit Development Programme Name: {{teamName}}
            </v-card-title>
            <v-card-title v-else>
                    New Development Programme
            </v-card-title>
            <v-card-text >
                <v-row >
                    <div class="card-title">
                    </div>
                </v-row>
                <v-row>
                    <v-col cols="3" style="margin-top:10px;margin-bottom:auto;">
                        <v-img 
                            :src="clubs[selectedClub].logo"
                            max-width="200px"
                        />
                    </v-col>
                    <v-col>
                        <ValidationProvider name="devstreamName" rules="required" v-slot="{errors}">
                            <v-text-field label="Development Program Name" hideDetails="auto" v-model="newTryout.title"/>
                            <div class="error-message">{{errors[0]}} </div>
                        </ValidationProvider>
                        <ValidationProvider name="Sport" rules="required" v-slot="{errors}">
                            <v-select :items="sportsArray" label="Sport" chips v-model="newTryout.sportId"/>
                            <div class="field-error">{{ errors[0] }}</div>
                        </ValidationProvider>
                        <ValidationProvider name="devstreamDescription" v-slot="{errors}">
                            <v-text-field label="Description" hideDetails="auto" v-model="newTryout.description"/>
                            <div class="error-message">{{errors[0]}} </div>
                        </ValidationProvider>
                        <div class="dates">
                            <div class=date>
                                <DateCombo title="Start Date" v-model="newTryout.startDate"/>
                            </div>
                            <div class="date">
                                <DateCombo title="End Date: " v-model="newTryout.endDate"/>
                            </div>
                        </div>
                        <ValidationProvider name="devstreamPronoun" rules="required" v-slot="{errors}">
                            <v-text-field label="What do you call your participants? (e.g. players, referees, etc)" hideDetails="auto" v-model="newTryout.participantPronoun"/>
                            <div class="error-message">{{errors[0]}} </div>
                        </ValidationProvider>
                        <ValidationProvider name="devstreamNoun" rules="required" v-slot="{errors}">
                            <v-text-field label="How would you describe your program? (e.g. Referee Development, etc)" hideDetails="auto" v-model="newTryout.eventNoun"/>
                            <div class="error-message">{{errors[0]}} </div>
                        </ValidationProvider>
                        <ValidationProvider name="devstreamEpisodeForm" rules="required" v-slot="{errors}">
                            <v-text-field label="What do you call your evaluation form? (e.g. referee evaluation form, athlete feedback form,etc)" hideDetails="auto" v-model="newTryout.episodeFormName"/>
                            <div class="error-message">{{errors[0]}} </div>
                        </ValidationProvider>
                    </v-col>
                    </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn text @click="closeDevStreamEditor(reset)">Cancel</v-btn>
                <v-btn :disabled="invalid" color="primary" text @click="saveDevStream(reset)">Save</v-btn>
            </v-card-actions>
        </v-card>
    </ValidationObserver>
</template>

<script>

import {ValidationProvider, ValidationObserver} from 'vee-validate'
import { mapActions, mapState } from 'vuex'
import DateCombo from '@/components/DateCombo'
export default {
    props: ["devStreamId"],
    components: {
        DateCombo,
        ValidationProvider,
        ValidationObserver
    },
    computed : {
        ...mapState({
            teams:      state => state.teamStore.data,
            teamStatus: state => state.teamStore.status,
            teamError:  state => state.teamStore.error,

            clubs:      state => state.clubStore.data,
            seasons:    state => state.seasonStore.data,

            sports:         state => state.sportStore.data,
            sportsList:     state => state.sportStore.list,
            sportsStatus:   state => state.sportStore.status,

            selectedSeason: state => state.navigatorStore.selectedSeason,
            selectedClub:   state => state.navigatorStore.selectedClub,
        }),
        sportsArray: function(){
            var sportsObjs=[]
            for(var idx in this.sportsList){
                sportsObjs.push({
                    text: this.sports[this.sportsList[idx]].name,
                    value: this.sportsList[idx]
                })

            }
            return sportsObjs
        },

    },
    methods: {
        ...mapActions('tryoutStore', [
            'addTryout'
        ]),
        saveDevStream: async function(reset) {
            await this.addTryout(this.newTryout)
            reset()
            this.clearDevStream()
            this.$root.$emit("close-devstream-editor")
        },
        closeDevStreamEditor(reset){
            reset()
            this.$root.$emit("close-devstream-editor")
        },
        clearDevStream(){
            this.newTryout= {
                    title: '',
                    seasonId:  this.selectedSeason,
                    description: '',
                    startDate: new Date().toISOString().split('T')[0],
                    endDate:   new Date().toISOString().split('T')[0],
                    offerDeadline:   (new Date(Date.now() - (new Date()).getTimezoneOffset()*60000)).toISO,
                    location: 'Melbourne, AU',
                    sportId: this.clubs[this.selectedClub].sportId,
                    participantPronoun: 'athlete',
                    eventType: 'devprogram',
                    clubId: this.selectedClub,
                    active: true,
                    localCurrency: 'AUD',
                    allowJuniors: true,
                    juniorAge: 18,
                    contactEmail: '',
                    contactPhone: '',
                    contactName: '',
                    eventNoun: '',
                    episodeFormName: '',
                }
        },
        
    },
    created(){
        this.clearDevStream()
    },
    data(){
        return {
            changed: false,
            genders: ["Unspecified", "Mixed", "Male", "Female"],
            origName: null,
            origDesc: null,
            origGender: null,
            newTryout: {}
        }
    }
}

</script>

<style scoped>

.card-title {
    font-weight: 600;
}
.addedit-card {
    padding:40px;
    height:100%;
}
.error-message{
    color:var(--error-red);
    text-align: left;;
}

</style>