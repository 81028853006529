<template>
    <div >
    <!--
        <div v-if="eventName">
        You are registering for <em>{{eventName}}</em>
        </div>
    -->
        <div class="container">
            <v-card class="mx-auto" :loading="status.loggingIn" outlined shaped elevation= "5" >
                <ValidationObserver v-slot="{invalid}">
                    <v-card-text>
                        <h3>Register a new CoachSight account</h3>
                        <v-form ref="registerForm" @submit.prevent="handleSubmit">
                            <div>
                                <ValidationProvider name="First name" rules="required" v-slot="{errors}">
                                    <v-text-field label="First Name" hide-details="auto" v-model="user.firstName"/>
                                    <div class="form-error">{{ errors[0] }}</div>
                                </ValidationProvider>
                                <ValidationProvider name="Last name" rules="required" v-slot="{errors}">
                                    <v-text-field label="Last Name" hide-details="auto" v-model="user.lastName"/>
                                    <div class="form-error">{{ errors[0] }}</div>
                                </ValidationProvider>
                                <ValidationProvider name="Email" rules="required|email" v-slot="{errors}">
                                    <v-text-field label="Email address" hide-details="auto" v-model="user.email"/>
                                    <div class="form-error">{{ errors[0] }}</div>
                                </ValidationProvider>
                                <VuePhoneNumberInput default-country-code="AU" v-bind:preferred-countries="['AU','US']" style="padding-top: 5px;" v-model="user.phone"/>
                                <div style="display:flex;flex-direction:col">
                                    <ValidationProvider name="Month of Birth" rules="min:1|max:12" v-slot="{errors}">
                                        <v-text-field label="Month of Birth" :rules="mobRules" hide-details="auto" hint="Clubs might need this to know whether you are junior player" v-model="user.monthOfBirth"/>
                                        <div class="form-error">{{ errors[0] }}</div>
                                    </ValidationProvider>
                                    <v-text-field style="margin-left:5px" label="Year of Birth" :rules="yobRules" hide-details="auto" hint="Clubs might need this to know whether you are junior player" v-model="user.yearOfBirth"/>
                                </div>
                                <ValidationProvider name="Password" rules="required" >
                                    <!-- 
                                    <v-text-field label="Password" v-model="user.password" :append-icon="showPassword? 'mdi-eye' : 'mdi-eye-off'"
                                                :rules="[rules.required, rules.min, rules.strength]"
                                                validate-on-blur
                                                :type="showPassword? 'text' : 'password'"
                                                @click:append="showPassword = !showPassword"
                                                />
                                    -->
                                    <v-text-field label="Password" v-model="user.password" :append-icon="showPassword? 'mdi-eye' : 'mdi-eye-off'"
                                                :rules="[rules.required, rules.min, ]"
                                                validate-on-blur
                                                :type="showPassword? 'text' : 'password'"
                                                @click:append="showPassword = !showPassword"
                                                />
                                    <v-text-field label="Confirm Password" v-model="user.confirmPassword" :type="showPassword? 'text':'password'" />
                                    <div class="strength-label">Password Strength</div>
                                    <v-progress-linear
                                        :color="score.color"
                                        :value="score.value"
                                        />

                                </ValidationProvider>
                            </div>
                            <div class="form-group row">
                                <label for="robot" class="col-sm2 col-form-label"></label>
                                <div class="col-sm-10 padding-on-top">
                                    <vue-recaptcha ref="recaptcha" @verify="onVerify" :sitekey="recaptchaSiteKey"></vue-recaptcha>
                                </div>
                            </div>
                            <div class="form-group-btn">
                                <v-btn type="submit" depressed color="primary" :disabled="invalid || !robot || status.registering">Register</v-btn>
                                <img v-show="status.registering" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                            </div>
                            If you already have a CoachSight account, <router-link to="/login" class="btn btn-link">login</router-link> here

                        </v-form>
                    </v-card-text>

                </ValidationObserver>
            </v-card>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
// eslint-disable-next-line
import { ValidationProvider, ValidationObserver } from "vee-validate"
//import { required } from 'vee-validate/dist/rules'
import zxcvbn from 'zxcvbn';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { VueRecaptcha } from 'vue-recaptcha'
//import {v4 as uuidv4} from 'uuid'
import { router } from '../_helpers'


const _recaptchaSiteKey = process.env.VUE_APP_RECAPTCHA_KEY


export default {
    data () {
        return {
            recaptchaSiteKey: _recaptchaSiteKey,
            robot: false, 
            showPassword: false,
            rules: {
                required:   value => !!value || 'Required',
                min:        value => (value && value.length >=5) || 'Password must be at least 5 characters long',
                strength:   value => (value && zxcvbn(value).score >= 1 ) || 'Please use a stronger password. Try a mix of letters, numbers and symbols',
                confirm:    value => (value && value === this.user.password) || "Passwords don't match",
                email:      value => !value || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'Not a valid email address' ,
                size:       value => !value || value.size < 500000 || 'Profile image must be under 500kB in size'
            },
            mobRules: [
                value => !!value || 'Required',
                value => (value && value> 0 && value < 13)
            ],
            yobRules: [
                value => !!value || 'Required',
                value => (value && value> 1950 && value < new Date().getFullYear())
            ],
            fnameRules: [
                value => !!value || 'Required',
                value => (value && value.length >=2) || 'Minimum 2 characters'
            ],
            emailRules: [
                value => !!value || 'Required',
                v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
            ],
            phoneRules: [
                value => !!value || 'Required',
            ],
            user: {
                email: '',
                phone: '',
                firstName: '',
                lastName: '',
                password: '',
                confirmPassword: '',
                profileImage: '',
                yearOfBirth: 2000,
                monthOfBirth: 1,
            },
            submitted: false,
            aFile: null,
        
        }
    },
    components: {
        ValidationProvider,
        ValidationObserver,
        VuePhoneNumberInput,
        'vue-recaptcha' : VueRecaptcha,
    },
    computed: {
        ...mapState('account', ['status']),
//        ...mapState('tryoutStore', ['tryouts']),
        score() {
            const result = zxcvbn(this.user.password);
            switch(result.score)
            {
                case 4: return {
                    color: "light-green accent-3",
                    value:100
                }
                case 3: return {
                    color: 'lime',
                    value:75
                }
                case 2: return {
                    color: "yellow",
                    value: 50
                }
                case 1: return {
                    color: "orange",
                    value: 25
                }
                default: return {
                    color: "red",
                    value: 0
                }
            }
        },
        
    },
    methods: {
        ...mapActions('account', ['register']),
        //...mapActions('tryoutStore', ['loadTryout']),
        // eslint-disable-next-line
        
        async handleSubmit() {
            if(this.robot) {
                this.submitted = true;
                let self = this;
                const redirectPath = sessionStorage.getItem('redirectPath')
                if(redirectPath){
                    self.user.redirectPath = redirectPath
                }
                const result = await self.register(self.user);
                if(result.code === 200){
                    router.push('/checkyouremail');
                } else {
                    this.registering=false
                }
            }
        },
        onVerify: function (response) {
            if(response) {
                this.robot = true
                this.user.recaptchaToken = response
            }
        },
        
    },
    created() {      
        /*
        const id = sessionStorage.getItem('tryoutId')
        if (typeof(id) != "undefined") {
            this.loadTryout(id)
        }
//        localStorage.removeItem('redirectPath')
        
*/
    },
    watch : {
        /*
        tryouts : function() {
            const redirect = sessionStorage.getItem('redirectPath')
            //const id = sessionStorage.getItem('tryoutId')
            if(redirect) {
                this.$store.dispatch('alert/info', `To register for ${this.eventName}, please create a new account`)
            }
        }
        */
    }
}
</script>

<style scoped>

.container {
    display: flex;
    justify-content: center;
    padding: 40px;
}

.form-group-btn {
    margin-bottom: 10px;
}

.padding-on-top {
    padding-top: 20px;
}
.strength-label {
    font-size: x-sm;
}
</style>