import {journalentryService} from '../_services'
import Vue from 'vue'

const dataService = journalentryService

function initialiseState(){
    return {
        status: {idle:true, ready:false},
        data: {},
        list: [],
        listByJournalOwner: {},
        error: null,
        cache: {},
        meta: {},
    }
}

const state = initialiseState()

const actions = {
    purgeData({commit}){
        commit('purgeData')
    },
    async loadAllJournalEntriesForEvent({commit}, payload){
        try{
            commit('loadAllJournalEntriesForEventRequest')
            const response = await dataService.loadAllJournalEntriesForEvent(payload)
            commit('loadAllJournalEntriesForEventSuccess', response)
            return response
        } catch (error) {
            commit('loadAllJournalEntriesForEventFailure', error)
            console.error('Error in loadAllJournalEntriesForEvent')
            console.error(error)
        }
    },
    async loadAllJournalEntriesForTeam({commit}, payload){
        try{
            commit('loadAllJournalEntriesForEventRequest')
            const response = await dataService.loadAllJournalEntriesForTeam(payload)
            commit('loadAllJournalEntriesForEventSuccess', response)
            return response
        } catch (error) {
            commit('loadAllJournalEntriesForEventFailure', error)
            console.error('Error in loadAllJournalEntriesForTeam')
            console.error(error)
        }
    },
    async loadAllJournalEntriesForUser({commit}, payload){
        try{
            commit('loadAllJournalEntriesForEventRequest')
            const response = await dataService.loadAllJournalEntriesForUser(payload)
            commit('loadAllJournalEntriesForEventSuccess', response)
            return response
        } catch (error) {
            commit('loadAllJournalEntriesForEventFailure', error)
            console.error('Error in loadAllJournalEntriesForUser')
            console.error(error)
        }
    },
    async createNewJournalEntry({commit}, payload){
        try {
            commit('createNewJournalEntryRequest')
            const response = await dataService.createNewJournalEntry(payload)
            commit('createNewJournalEntrySuccess', response)
            return response
        } catch (error) {
            commit('createNewJournalEntryFailure', error)
            console.error('Error creating new journal entry')
            console.error(error)
        }
    },
    async createTeamJournalEntry({commit}, payload){
        try {
            commit('createNewJournalEntryRequest')
            const response = await dataService.createTeamJournalEntry(payload)
            commit('createNewJournalEntrySuccess', response)
            return response
        } catch (error) {
            commit('createNewJournalEntryFailure', error)
            console.error('Error creating new journal entry')
            console.error(error)
        }
    },
    async updateJournalEntry({commit}, payload){
        try{
            commit('updateJournalEntryRequest')
            const response = await dataService.updateJournalEntry(payload)
            commit('updateJournalEntrySuccess', response)
            return response
        } catch (error) {
            commit('updateJournalEntryFailure', error)
            console.error('Error in updateJournalEntry')
            console.error(error)
        }
    },
    async toggleReadActivated({commit}, id){
        commit('toggleReadActivated', id)
    },
    journalPropertyUpdate({commit}, payload){
        commit('propertyUpdate', payload)
    }
}

const mutations = {
    purgeData(state){
        const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }

    },
    toggleReadActivated(state, id){
        state.meta[id].readActivated = !state.meta[id].readActivated
    } ,
    
        //------------ update data -------- \\
        propertyUpdate(state, payload) {
            const metaFields = "delete"
            const {field, id, value} = payload
    
            if(metaFields.includes(field)){
                state.meta[id][field]=value
                // Manage special fields...
                //switch (field) {
                //}
            } else {
                state.data[id][field]=value
            }
        },

    //--------------------------------------------
    //   Mutations for updateJournalEntry
    //
    
    updateJournalEntryRequest(state){
        Vue.set(state.status, 'loading', true)
        state.error=null
    },
    updateJournalEntrySuccess(state, response){
        state.status = {loading: true, responseCode: response.data.code}
        addToLists(response.data.result)
        sortListsByOrder()
        Vue.delete(state.status,'loading')
    },
    updateJournalEntryFailure(state, error){
        state.error=error
    },
    
    //--------------------------------------------
    //   Mutations for createNewJournalEntry
    //
    
    createNewJournalEntryRequest(state){
        Vue.set(state.status, 'loading', true)
        state.error=null
    },
    createNewJournalEntrySuccess(state, response){
        state.status = {loading: true, responseCode: response.data.code}
        
        addToLists(response.data.result)
        sortListsByOrder()
        Vue.delete(state.status,'loading')
    },
    createNewJournalEntryFailure(state, error){
        state.error=error
    },


    //--------------------------------------------
    //   Mutations for loadAllJournalEntriesForEvent
    //
    
    loadAllJournalEntriesForEventRequest(state){
        Vue.set(state.status, 'loading', true)
        state.error=null
    },
    loadAllJournalEntriesForEventSuccess(state, response){
        state.status = {loading: true, responseCode: response.data.code}
        state.data={}
        state.list=[]
        state.listByJournalOwner={}
    
        for(var idx in response.data.result){
            addToLists(response.data.result[idx])
        }
        sortListsByOrder()

        Vue.delete(state.status,'loading')
    },
    loadAllJournalEntriesForEventFailure(state, error){
        state.error=error
    },

}

function addToLists(data){

    const id = data.id
    if(!state.list.includes(id)){
        state.list.push(id)
    }

    Vue.set(state.data, id, data)
    Vue.set(state.meta, id, {
        delete: false,
        readActivated: false,
    })

        var list = []
        if(Object.prototype.hasOwnProperty.call(state.listByJournalOwner, data.journalOwnerId)){
        list = state.listByJournalOwner[data.journalOwnerId] 
        }

        if(!list.includes(id)){
            list.push(id)
        }

    Vue.set(state.listByJournalOwner, data.journalOwnerId, list)

}

function sortListsByOrder(){
    /* state.list.sort((a,b)=>{
        const idA = new String(''+state.data[a].id)
        const idB = new String(''+state.data[b].id)
        return (idA.localCompare(idB))
    })
    */
    for(const idx in state.listByJournalOwner){
        var list = state.listByJournalOwner[idx]
        list.sort((a,b)=>{
            return new Date(state.data[b].createdAt) - new Date(state.data[a].createdAt);
        })
    }
}

export const journalentryStore = {
    namespaced: true,
    state,
    actions,
    mutations
}