import {sendMessage} from '@/_helpers/service-tools'

export const commentService = {
    findAllForEventAndStaff,
    findAllForEvent,
    create,
    update,
    del,
    undelete
}

async function findAllForEventAndStaff(eventId , userId) {
    return await sendMessage('GET','comment/list',{eventId, userId}, null)
}

async function findAllForEvent(eventId) {
    return await sendMessage('GET','comment/listall', {eventId}, null)
}

async function update(commentId, comment, idRemark) {
    return await sendMessage('PATCH','comment',{commentId}, {comment, idRemark})
}
async function create(payload) {
    return await sendMessage('POST','comment',null, payload)
}

async function del(commentId) {
    return await sendMessage('PATCH', 'comment/delete', {commentId}, null)
}

async function undelete(commentId) {
    return await sendMessage('PATCH', 'comment/undelete', {commentId}, null)
}