<template>
    <v-card v-observe-visibility="visibilityChange">
        <ValidationObserver v-slot="{ invalid }">
                <v-card-text>
                    <span class="sessiontitle">Registration Questions</span>
                    <div v-for="questionId in questionList" :key="questionId">
                        <!--
                            Role question: Present a multi-select for the roles defined on this tryout
                        -->
                        <QuestionRender :qid="questionId"  :value="inputRole" :secondRole="true"/>
<!--
                        <ValidationProvider v-if="questions[questionId].type === 'role'" :name="questionId" :rules="applicableRules[questions[questionId]]" v-slot="{errors}">
                            <v-input :value="roletest" v-model="roletest">
                                <template v-slot:default>
                                    <div>
                                        <span style="font-weight:600">{{questionNumber(questionId)}}</span>
                                            {{questions[questionId].question}}
                                        <span v-if="questions[questionId].required" style="color:red;">*</span>

                                    </div>

                                    <v-row class="question" >
                                        <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="responsiveClass('question')">
                                            <v-select v-model="inputRole[0]" label="First preference" :items="roleList">
                                                <template v-slot:item="{item}">
                                                    {{ roles[item].name }}
                                                </template>
                                                <template v-slot:selection="{item}">
                                                    {{ roles[item].name }}
                                                </template> 
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="6" lg="6" xl="6" :class="responsiveClass('question')" >
                                            <v-select v-model="inputRole[1]" label="Second preference" :items="roleList">
                                                <template v-slot:item="{item}">
                                                    {{ roles[item].name }}
                                                </template> 
                                                <template v-slot:selection="{item}">
                                                    {{ roles[item].name }}
                                                </template> 
                                            </v-select>
                                        </v-col>
                                    </v-row>

                                </template>

                            </v-input>
                            <div class="field-error">{{errors[0]}}</div>
                        </ValidationProvider>
                    -->



                        <!--

                            Short Response Question

                        -->
                        
                        <ValidationProvider v-if="questions[questionId].type === 'short'" :name="questionId" :rules="applicableRules(questions[questionId])" v-slot="{ errors}">
                            <v-text-field hide-details="true" v-model="response[questionId]" class="shrink">
                                <template v-slot:label>
                                    <!-- 
                                    <p v-responsive.md.lg.xl class="text-wrap">
                                    -->
                                    <p class="text-wrap">
                                        <span style="font-weight:600">{{questionNumber(questionId)}}</span>
                                        {{questions[questionId].question}}
                                        <span v-if="questions[questionId].required" style="color:red;">*</span>
                                    </p>
                                </template>
                            </v-text-field>
                            <div class="field-error">{{errors[0]}}</div>
                        </ValidationProvider>
                        <!--

                            Long Response Question

                        -->
                        
                        <ValidationProvider v-if="questions[questionId].type==='long'" :name="questionId" :rules="applicableRules(questions[questionId])" v-slot="{errors}">
                            
                            <div class="field-error">{{errors[0]}}</div>
                        </ValidationProvider>
                        
                        <!--

                            Selection Response Question

                        -->

                        <ValidationProvider v-if="questions[questionId].type==='select'" :name="questionId" :rules="applicableRules(questions[questionId])" v-slot="{errors}">
                            <v-select :items="questionOptions(questionId)" item-text="text" item-value="value" :label="questionNumber(questionId)+questions[questionId].question" v-model="response[questionId]" :value="response[questionId]">
                            </v-select>
                            <div class="field-error">{{errors[0]}}</div>
                        </ValidationProvider>
                        
                        <!--

                            Checkbox Response Question

                        -->

                        <ValidationProvider v-if="questions[questionId].type==='checkbox'" :name="questionId" :rules="applicableRules(questions[questionId])" v-slot="{errors}">
                            <v-checkbox  v-model="response[questionId]" :label="questionNumber(questionId)+questions[questionId].question" :value="response[questionId]"></v-checkbox>
                            <div class="field-error">{{errors[0]}}</div>
                        </ValidationProvider>
                        

                    </div>
            
                    <v-divider/>
            </v-card-text>    

            <v-card-actions >
                            <v-btn  v-if="!checkinMode"
                                color="secondary"
                                @click="lastStep()"
                            >
                                Back
                            </v-btn>
                            <v-spacer/>
                            <v-btn 
                                color="primary"
                                @click="saveResponses()"
                                :disabled="invalid"
                            >
                                Continue
                            </v-btn>

            </v-card-actions>
    <!--
                <div v-if="!checkinMode" class="buttons">
                    <v-row>
                        <v-col cols="2">
                            <v-btn 
                                color="secondary"
                                @click="lastStep()"
                            >
                                Back
                            </v-btn>
                        </v-col>
                        <v-spacer/>
                        <v-col cols="2">
                            <v-btn 
                                color="primary"
                                @click="saveResponses()"
                                :disabled="invalid"
                            >
                                Continue
                            </v-btn>
                        </v-col>
                        <v-col cols="2">
                            <v-btn text @click="cancel">
                                Cancel
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            -->
        </ValidationObserver>
    </v-card>

</template>

<script>
import {mapState, mapActions} from 'vuex'
//import { router } from '@/_helpers'

//import {messagingService} from '../_services'

import { ValidationProvider,  ValidationObserver} from 'vee-validate';

import QuestionRender from '@/components/QuestionRender'

import {rules} from '@/_helpers/rules'
import { router } from '@/_helpers';

export default {
    props: {
        checkinMode: {
            default: false,
        },
        changeState: {
            default: (()=>{})
        }
    },
    components: {
        ValidationObserver,
        ValidationProvider,
        QuestionRender,
    },
    computed : {

        ...mapState({
            tryouts: state => state.tryoutStore.data,
            status: state => state.tryoutStore.status,
            sessions: state => state.sessionStore.data,
            sessionMeta: state => state.sessionStore.meta,
            sessionList: state => state.sessionStore.list,
            questions: state => state.appquestionStore.data,
            questionList: state => state.appquestionStore.list,
            participants: state=> state.participantStore.data,
            participantList: state=> state.participantStore.list,
            participantStatus: state => state.participantStore.status,
            account: state => state.account,
            selectedTryout: state => state.navigatorStore.selectedTryout,
            selectedClub: state => state.navigatorStore.selectedClub,
            formStep: state => state.tryoutregStore.step,
            formResponses: state => state.tryoutregStore.qresponses,
            roleList:   state => state.roleStore.list,
            roles:      state => state.roleStore.data,
        }),
        regFormState: {
            get() {
               return this.formStep 
            },
            set(newValue) {
                this.setStep(newValue)
            }
        },
        selectedRoles: {
            get(){
                return `["${this.inputRole[0]}", "${this.inputRole[1]}"]`
            },
            set(newValue){
                this.inputRules = newValue
            }
        },
        /*
        roletest: {
            get(){
                if (this.inputRole[0]==='' && this.inputRole[1]===''){
                    return ""
                } 

                return this.inputRole[0]+this.inputRole[1]

            },
            set(){}
        }
            */
    },
    methods: {
        ...mapActions('tryoutStore', {
            findAllTryouts: 'findAll'
        }),
        ...mapActions('sessionStore', {
            findAllSessions: 'findAllForEvent'
        }),
        /*
        ...mapActions('appquestionStore', {
            findAllQuestions : 'loadAllQuestionsForEvent'
        }),
        */
        ...mapActions('qresponseStore', {
            addResponse: 'add'
        }),
        ...mapActions('participantStore', {
            addParticipant: 'add'
        }),
        ...mapActions('tryoutregStore', {
            setStep: 'setStep',
            setResponses: 'setResponses',
            nextStep: 'nextStep',
            lastStep: 'lastStep',
            updateResponse: 'updateResponse',
        }),
        ...mapActions('navigatorStore', {
            selectClubPane: 'selectPane',
        }),
        ...mapActions('roleStore', {
            findAllRolesForEvent: 'findAllForEvent',
        }),
        responsiveClass(className){
            if(this.$vuetify.breakpoint.smAndDown){
                return className+' mobile'
            }
            return className
        },
        cancel(){
            this.selectClubPane('SeasonHome')
            router.push('/clubhouse/'+this.selectedClub)
        },
        applicableRules(question) {
            let response=""
            if (question.required)
                response="required"
            
            return response

        },
        visibilityChange(isVisible){
            if(isVisible){
                this.visible=true
            }
        },
        async saveResponses () {
                // Store the responses so the next pane can access them...

                this.setResponses({step: 2, responses:this.response})

                for(var idx in this.response){
                    this.updateResponse({questionId: idx, value: this.response[idx]})
                }
                if(this.checkinMode){
                    this.changeState()
                } else {
                    this.nextStep()
                }
        },
        questionLabel: function(id) {
            return `Q${this.questions[id].order+1}: ${this.questions[id].question}`
        },
        questionNumber: function(id) {
            return `Q${this.questions[id].order+1}: `
        },
        questionOptions(id){
            return JSON.parse(this.questions[id].options);
        },


    },
    async created() {
        this.response = this.formResponses
        await this.findAllRolesForEvent({eventId: this.selectedTryout})
        
    },
    data() {
        return {
            inputRole:['',''],
            rules: rules,
            test1: '',
            test2: '',
            tr:null,
            visible:false,
            response: {
                
            },
            quest: [ {
                required: true
            },
            {
                required: false
            }]
        }
    },
    watch: {
        questions : function() {
                for(var idx in this.questionList) {
                    if(!this.response[this.questionList[idx]]) this.response[this.questionList[idx]]=this.questions[idx].default
                }
            },
        inputRole(){
            // Need to update the response for the role question,
            // for find any questions that have the "role" type.
            // Crude but will work for now.
            for(var idx in this.questionList){
                const qid = this.questionList[idx]
                if(this.questions[qid].type==='role'){
                    this.response[qid]= `["${this.inputRole[0]}", "${this.inputRole[1]}"]`
                }
            }

        }
    }

}
</script>

<style>
.v-label {
    display: block !important;
}

.v-input--selection-controls .v-input__slot > .v-label {
    flex-direction: column;
    align-items: flex-start;
}
.aquestion-label {
    flex-direction: column;
    align-items: flex-start;
    display: block !important;
    word-break: break-word;

}
.questionLabel-mobile{
    font-size: 0.9em;
    display: flex;
    justify-content: left;
    text-align: left;
            padding-left: 2em;
        text-indent:-2em;
    margin-top: 5px;
}

.field-error {
    text-align: left;
    color: red;
    font-size: small;
}

.question{
    margin:0px;
    padding:0px;
    padding-left:30px;
}

.question.mobile{
    padding:0px;

}
.v-input__slot{
    flex-wrap:wrap
}

</style>