import { sendMessage } from "../_helpers/service-tools";

export const feedbackLinkService = {
    loadAllFeedbackLinksForEvent,
    loadAllFeedbackLinksForEventAndStaff,
    addFeedbackLink,
    deleteFeedbackLink,
}

async function deleteFeedbackLink(payload){
    return await sendMessage('DELETE', 'feedbacklink', {eventId: payload.eventId, feedbackId: payload.feedbackId, participantId: payload.participantId, staffId: payload.staffId}, {} )
}

async function loadAllFeedbackLinksForEventAndStaff(payload){
    return await sendMessage('GET', 'feedbackLink/loadAllFeedbackLinksForEventAndStaff', {eventId: payload.eventId}, null)
}

async function loadAllFeedbackLinksForEvent(payload){
    return await sendMessage('GET', 'feedbackLink', {eventId: payload.eventId}, null)
}

async function addFeedbackLink(payload){
    return await sendMessage('POST', 'feedbacklink', {},{eventId: payload.eventId, feedbackId: payload.feedbackId, participantId: payload.participantId, staffId: payload.staffId, criterionId: payload.criterionId} )
}