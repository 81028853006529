<template>

    <v-expansion-panel v-if="!loading">
        <v-expansion-panel-header disable-icon-rotate style="padding:5px;">
            <template v-slot:actions>
                <v-dialog v-model="confirmDeleteDialog" max-width="60%">
                    <template v-slot:activator="{on, attrs}">
                        <v-icon small color="red" v-on="on" v-bind="attrs">mdi-delete</v-icon>
                    </template>
                    <v-card>
                        <v-card-title>Confirm Criterion Deletion</v-card-title>
                        <v-card-text>
                            You cannot undo this operation. All information for this criterion will be deleted permanently, including scores that have eben provided and feedback attached to scores. Are you sure you want to continue?
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer/>
                            <v-btn text @click="confirmDeleteDialog=false">Close</v-btn>
                            <v-btn color="red darken-1" text @click="confirmedDelete">Delete</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-icon>mdi-chevron-down </v-icon>
            </template>
            <v-row style="margin:0px;padding:0px;">
                <v-col cols="1" style="margin:0px;padding:0px;text-align:left">
                    <v-icon class="handle">mdi-drag-horizontal-variant</v-icon>
                </v-col>
                <v-col style="text-align:left;margin:auto 0px auto 0px;padding:0px 0px 0px 10px" :class="{strike: meta[criterionId].archive}">
                    {{criteria[criterionId].description}}
                </v-col>
                <v-spacer/>
            </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-row>
                <v-col cols="5">
                    <v-text-field
                        v-model="description"
                        prepend-icon="mdi-information"
                        label="Criterion Name"
                    /> 
                </v-col>
                <v-col style="display:flex;flex-flow:row;margin-top:auto;margin-bottom:auto;">
                    <div style="margin-right:30px;margin-top:auto;margin-bottom:auto;">Context:</div>
                    <v-checkbox style="margin-right:5px;" v-model="contextIndividual" label="Individual" />
                    <v-checkbox v-model="contextGroup" label="Group" />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="5" style="margin-top:0px;margin-bottom:auto;">
                    <v-radio-group style="margin:0px;padding:0px;" v-model="selectedScore" v-for="item in scoreKeys" :key="item">
                        <v-radio :value="item">
                            <template v-slot:label>
                                <v-text-field dense hide-details v-model="self[`level${item}`]">
                                    <template v-slot:prepend>
                                        <v-icon :color="colors[item]" large>{{ `mdi-numeric-${item}-circle` }}</v-icon> 
                                    </template>
                                </v-text-field>
                            </template>
                        </v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols=7 style="margin-top:0px; margin-bottom:auto;">
                    <v-card >
                        <v-card-title>
                            Feedback associated with a score of <v-icon style="margin-left:5px;" :color="colors[selectedScore]" large>{{ `mdi-numeric-${selectedScore}-circle` }}</v-icon> 
                        </v-card-title>
                        <v-card-text>
                            <v-btn small dense text color="primary" class="cs-btn small" @click="addFeedback(selectedScore)">Add Feedback</v-btn>
                            <v-list :key="updateKey" v-if="feedbackByCriterionAndScore[criterionId]">
                                <FeedbackLine  :feedbackId="item" :parentComponentId="componentId" v-for="item in feedbackByCriterionAndScore[criterionId][selectedScore]" :key="item"/>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {v4 as uuidv4} from 'uuid'


import FeedbackLine from '@/components/feedbackLine'

export default {
    props: ['criterionId'],
    components: {
        FeedbackLine,
    },
    computed: {
        ...mapState({
            store: state => state.criterionStore,
            criteria: state => state.criterionStore.data,
            list: state => state.criterionStore.list,
            meta: state => state.criterionStore.meta,
            status: state => state.criterionStore.status,
            error: state => state.criterionStore.error, 
            selectedTryout:     state=> state.navigatorStore.selectedTryout,

            feedbackByCriterionAndScore:        state => state.feedbackStore.listByCriterionAndScore,
            feedback :                  state => state.feedbackStore.data,
            feedbackStatus:     state => state.feedbackStore.status,
        }),
        loading(){
            return this.feedbackStatus.loading
        },
        description: {
            get() {
                return this.criteria[this.criterionId].description
            },
            set(value) {
                this.flagChanges()
                return this.update({field: 'description', id: this.criterionId, value})
            }
        },
        level0: {
            get(){
                return this.criteria[this.criterionId].level0
            },
            set(newValue){
                this.flagChanges()
                return this.update({field: 'level0', id: this.criterionId, value: newValue})
            }
        },
        level1: {
            get(){
                return this.criteria[this.criterionId].level1
            },
            set(newValue){
                this.flagChanges()
                return this.update({field: 'level1', id: this.criterionId, value: newValue})
            }
        },
        level2: {
            get(){
                return this.criteria[this.criterionId].level2
            },
            set(newValue){
                this.flagChanges()
                return this.update({field: 'level2', id: this.criterionId, value: newValue})
            }
        },
        level3: {
            get(){
                return this.criteria[this.criterionId].level3
            },
            set(newValue){
                this.flagChanges()
                return this.update({field: 'level3', id: this.criterionId, value: newValue})
            }
        },
        level4: {
            get(){
                return this.criteria[this.criterionId].level4
            },
            set(newValue){
                this.flagChanges()
                return this.update({field: 'level4', id: this.criterionId, value: newValue})
            }
        },
        level5: {
            get(){
                return this.criteria[this.criterionId].level5
            },
            set(newValue){
                this.flagChanges()
                return this.update({field: 'level5', id: this.criterionId, value: newValue})
            }
        },
        contextIndividual: {
            get(){
                return this.criteria[this.criterionId].context.includes('individual')
            },
            set(newValue){
                this.updateContext(newValue, 'individual')
            }
        },
        contextGroup: {
            get(){
                return this.criteria[this.criterionId].context.includes('group')
            },
            set(newValue){
                this.updateContext(newValue, 'group')
            }
        },
        order: {
            get() {
                return this.criteria[this.criterionId].order
            },
            set(value) {
                this.flagChanges()
                return this.update({field:'order', id: this.criterionId, value})
            }
        },

        flagToDelete: {
            get() {
                return this.meta[this.criterionId].archive
            },
            set(value) {
                const result =  this.propertyUpdate({field: 'archive', id: this.criterionId, value})
                return result
            }
        },
    },
    methods: {
        ...mapActions('criterionStore', {
            update: 'update',
            propertyUpdate: 'update',
            updateCriterion: 'updateCriterion',
            archiveCriterion: 'archiveCriterion',
        }),
        ...mapActions('feedbackStore', [
            'createFeedback',
        ]),
        updateContext(newValue, context){
                if(newValue!==true){
                    if(this.criteria[this.criterionId].context.includes(context)){
                        const list = this.criteria[this.criterionId].context
                        const idx = list.indexOf(context)
                        list.splice(idx,1)
                        this.update({field:'context', id: this.criterionId, value: list})
                        this.flagChanges()
                    }
                } else {
                    if(!this.criteria[this.criterionId].context.includes(context)){
                        const list = this.criteria[this.criterionId].context
                        list.push(context)    
                        this.update({field:'context', id: this.criterionId, value: list})
                        this.flagChanges()
                    }
                }
        },
        flagForDeletion: function() {
            this.flagToDelete=true
            this.flagChanges()
        },
        unflagForDeletion: function() {
            this.flagToDelete=false
            this.flagChanges()
        },
        flagChanges(){
            this.changes=true
 //           this.$root.$emit("settings-criteria-changes-made", this.componentId)
            this.$root.$emit("settings-changes-made", this.componentId)
        },
        async saveDetails(){
            this.changes=false
            this.updateCriterion(this.criteria[this.criterionId])

            // Update all the feedback too...


            this.$root.$emit("settings-criteria-changes-cleared", this.componentId)
            this.$root.$emit("settings-changes-cleared", this.componentId)
            this.$store.dispatch('alert/success', 'Tryout Updated', {root:true})
        },
        confirmedDelete(){
            this.archiveCriterion(this.criterionId)
            this.confirmDeleteDialog=false
            this.$store.dispatch('alert/success', 'Skill criterion deleted', {root:true})

        },
        async addFeedback(associatedScore){
            try{
                await this.createFeedback({
                    eventId: this.selectedTryout,
                    criterionId: this.criterionId,
                    associatedScore: associatedScore,
                    text: '--- New feedback ---'
                })
                this.updateKey=uuidv4()
            } catch (error){
                console.error('Error creating new feedback')
                console.error(error)
            }

        }
    },
    async created(){
        this.componentId = uuidv4()
        console.log(this['level1'])
    },
    mounted(){
        this.$root.$on('submit-all-changes', () => {
            if(this.changes){
                this.saveDetails()
            }
        })
        this.$root.$on('submit-all-changes', () => {
            var self=this
            if(this.changes){
                this.saveDetails()
                self.changes=false
            }
        })
        this.$root.$on(`${this.componentId}-listupdate`, () => {
            this.updateKey=uuidv4()
        })

    },
    data(){
        return {
            self : this,
            updateKey:'00',
            selectedScore:5,
            confirmDeleteDialog:false,
            componentId: 0,
            changes: false,
            scoreKeys: [5,4,3,2,1,0],
            //colors: ['green','lime','yellow','orange','red','gray'],
            colors: ['gray','red','orange','yellow','lime','green'],
        }
    }
}
</script>

<style>
.cs-btn{
    font-family: "Varela Round";
}
.cs-btn.small{
    font-size:10px;
}
</style>