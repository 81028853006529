<template>
    <loading v-if='loading'/>
    <tryout-status-athlete v-else-if="isAthlete"/>
    <TryoutStatusAdmin v-else-if="eventAllows(['admin', 'selector', 'owner', 'support', 'headcoach', 'assistantcoach', 'programcoach'])" style="width:100%"/>
    <auth-fail v-else/>
</template>

<script>
import Loading from '@/components/loading'
import TryoutStatusAdmin from '@/ClubPanes/TryoutStatusAdmin'
import TryoutStatusAthlete from '@/ClubPanes/TryoutStatusAthlete'
import {mapState} from 'vuex'

export default{
    props: [],
    components:{
        Loading,
        TryoutStatusAdmin,
        TryoutStatusAthlete,
    },
    computed: {
        ...mapState({
            tryouts:            state => state.tryoutStore.data,
            teams:              state => state.teamStore.data,

            selectedTryout:     state => state.navigatorStore.selectedTryout,
            selectedNav:        state => state.navigatorStore.selectedNav,
        }),
        loading(){
            return false ||
                false;
        },
        isAthlete(){
            if(this.selectedNav==='Team'){
                return this.teams[this.selectedTryout].athlete
            } else {
                return this.tryouts[this.selectedTryout].athlete
            }
        }

    },
    methods: {

    },
    watch: {},
    async created(){

    },
    data(){
        return {

        }
    }

}
</script>