<template>
    <loading v-if='loading'/>
    <div v-else>
        <div v-responsive.xs.sm>
            <v-row class="subpage-frame">
                <v-col class="no-boundaries">
                    <div class="pane-heading">{{capitalize(events[selectedEvent].participantPronoun)}} Development Program Homepage</div>
                </v-col>
            </v-row>
            <v-row class="no-boundaries">
                <v-col cols="12">
                    <div class="journal-button" @click="selectPane('JournalAthleteHome')">
                        <v-icon large color="white">mdi-notebook-outline</v-icon>
                        <div style="font-size:small; color:white;" class="text-center">My Journal</div>
                    </div>
                </v-col>
                <v-col cols="3">
                    <!-- {{ capitalize($pluralize(events[selectedEvent].participantPronoun)) }}
                -->
                </v-col>
            </v-row>

        </div>
        <div v-responsive.md.lg.xl>
            <v-row class="subpage-frame">
                <v-col class="no-boundaries">
                    <div class="pane-heading">{{capitalize(events[selectedEvent].participantPronoun)}} Development Program Homepage</div>
                </v-col>
            </v-row>
            <v-row class="no-boundaries">
                <v-col cols="2">
                    <div class="journal-button" @click="selectPane('JournalAthleteHome')">
                        <v-icon large color="white">mdi-notebook-outline</v-icon>
                        <div style="font-size:small; color:white;" class="text-center">My Journal</div>
                    </div>
                </v-col>
                <v-col cols="3">
                    <!-- {{ capitalize($pluralize(events[selectedEvent].participantPronoun)) }}
                -->
                </v-col>
            </v-row>
        </div>
    </div>

</template>

<script>
import Loading from '@/components/loading'
import {mapState, mapActions} from 'vuex'

export default{
    props: [],
    components:{
        Loading
    },
    computed: {
        ...mapState({
            events:             state => state.tryoutStore.data,

            selectedEvent:     state => state.navigatorStore.selectedTryout,
        }),        loading(){
            return false ||
                false;
        }

    },
    methods: {
        ...mapActions('navigatorStore', [
            'selectPane'
        ]),

    },
    watch: {},
    async created(){

    },
    data(){
        return {

        }
    }

}
</script>