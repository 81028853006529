<template>
    <loading v-if="loading"/>
        <div v-else>
            <div><h2>Participants Mailout</h2></div>
                <div class="main-frame">
                    <div class="player-list">
                        <div class="list-header">
                            Select Participants
                        </div>
                        <div>
                            <v-virtual-scroll
                                :bench="benched"
                                :items="participantList"
                                height="600"
                                item-height="64"
                            >
                                <template v-slot:default="{ item }">
                                    <v-list-item :key="item">
                                        <recipient-list-item :participantId="item" /> 
                                    </v-list-item>

                                    <v-divider></v-divider>
                                </template>
                            </v-virtual-scroll> 
                        </div>
                        <div>
                            {{recipientList.length}} recipients selected
                        </div>
                    </div> 
                    <div class="mail-frame">
                        <mail-render :eventId="tryoutId" :recipients="recipientList"/>
                    </div>
                <div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import Loading from '@/components/loading'
import RecipientListItem from '@/components/RecipientListItem'
import MailRender from '@/components/MailRender'

export default {
    props: [],
    components: {
        Loading,
        RecipientListItem,
        MailRender,
    },
    data() {
        return ({
            benched: 0,
            tryoutId: 'e355fa63-d34e-4efe-9cfa-451e9e472e3b',
        })
    },
    computed: {
        ...mapState({
            participantStatus: state => state.participantStore.status,
            participantList: state => state.participantStore.list,
            participants: state => state.participantStore.data,
            participantMeta: state => state.participantStore.meta,
        }),
        loading() {
            return this.participantStatus.loading
        },
        recipientList() {
            return this.participantList.filter((e)=>{
                return this.participantMeta[e].selected
            })
        }
    },
    methods: {
        ...mapActions('participantStore', {
            findAllParticipants: 'findAllForEvent'
        })

    },
    async created() {
        await this.findAllParticipants(this.tryoutId)
    },
    watch: {
         recipientList: function() {
        }
    }
}

</script>

<style>
.player-list {
    width: 30%;
    height: 100%;
    border-style: solid;
    border-color: var(--cs1-lev1);
    margin-left: 15px;
    border-width: 2px;
    border-radius: 10px;
}

.list-header{
    background: var(--cs1-lev1);
    color: white;
    padding: 5px;
    font-weight: 600;
}

.main-frame {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.mail-frame {
   border-style: solid;
    border-color: var(--cs1-lev1);
    margin-left: 30px;
    border-width: 2px;
    border-radius: 10px; 
    width: 100%;
}

</style>