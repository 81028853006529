const JWT = require('jsonwebtoken')

export function licenseAllows(feature){
    
    // get the licence that's stored for this customer...
    const token = localStorage.getItem('currentLicense')

    // Verify the JWT first to make sure it's not been tampered with
    const jwtPubKey = process.env.VUE_APP_JWT_PUBKEY
    
    var res=null

    try{
        res = JWT.verify(token, jwtPubKey)
    } catch (error) {
        return false
    }

    return res[feature]

}