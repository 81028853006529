async function sendCSCMessage(cscPath){
    const cscAPIURL= "https://api.countrystatecity.in"

    const requestOptions = {
        method: "GET",
        headers: {
            "X-CSCAPI-KEY": "dVZtT0x4MkE2WFR1MHFXNlRnamVvNXJSZHhsYWFuWDhOSWZCNzdTQQ==",
            "accept": "application/json" 
        }
    }
    var url = new URL(`${cscAPIURL}/${cscPath}`)

    const response = await fetch(url, requestOptions)
    const data = await response.json()
    if(!response.ok){
        if(response.status === 401) {
            location.reload(true)
        }
        return Promise.reject(data)
    }
    return Promise.resolve(data);

}

export const cscService = {
    getCountries,
    getStates,
    getCities,
}

async function getCountries() {
    return await sendCSCMessage('v1/countries')
}

async function getStates(payload) {
    return await sendCSCMessage('v1/countries/'+payload.ciso+'/states')
}

async function getCities(payload) {
    return await sendCSCMessage('v1/countries/'+payload.ciso+'/states/'+payload.siso+'/cities')
}