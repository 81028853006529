import { authService} from '../_services'


function initialiseState(){
    return {
        internal: {},
        data: {},
        status: {},
        error:{},
    }
}

const state = initialiseState()


const actions = {
    purgeData({commit}){
        commit('purgeData')
    },
    async preAuth({commit},payload) {
        try{
            commit('preAuthRequest')
            const response = await authService.preAuth(payload)
            commit('preAuthSuccess', response)
        } catch(error){
            commit('preAuthError', error)
        }
    },

}

const mutations = {
purgeData(state){
    const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }
    },
    preAuthRequest(state){
        state.status={loading:true}
    },
    preAuthSuccess(state, response){
        state.data = response
        state.status={}
    },
    preAuthFailure(state,error) {
        state.error = error
        state.status={error:true}
    }
}

export const authStore = {
    namespaced: true,
    state,
    actions,
    mutations
}