import {sendMessage} from '@/_helpers/service-tools'

export const staffService = {
    findAllForEvent,
    create,
//    update,
    del,
    undelete,
    revokeRole,
    endowRole,
    findAllForSeason,
}

async function findAllForSeason(payload ) {
    return await sendMessage('GET', 'staff/listStaffForSeason', {seasonId:payload.seasonId}, null)
}

async function findAllForEvent(eventId ) {
    return await sendMessage('GET', 'staff/list', {eventId}, null)
}

/*
async function update(staffId, role, userId) {
    return await sendMessage('PATCH', 'staff', {staffId}, {userId, role})
}
*/

async function create(payload) {
    return await sendMessage('POST', 'staff', null, {eventId:payload.eventId, userId:payload.userId, role: payload.role})
}

async function del(payload) {
    return await sendMessage('PATCH', 'staff/delete', payload, null)
}

async function undelete(payload) {
    return await sendMessage('PATCH', 'staff/undelete', payload, null)
}

async function endowRole(payload) {
    return await sendMessage('PATCH', 'staff/endow', payload, null)
}

async function revokeRole(payload) {
    return await sendMessage('PATCH', 'staff/revoke', payload, null)
}