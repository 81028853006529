<template>
    <div v-if="!loading" style="text-align:left;padding: 0px 10px 0px 10px">
        <ValidationObserver v-slot="{handleSubmit, invalid, reset}" ref="form">
            <v-row>
                <v-col cols="12" style="text-align:right">
                    <v-btn @click.native.stop 
                        v-if="changes" 
                        x-small 
                        v-on:click="saveDetails" 
                        depressed 
                        color="primary" 
                        class="save-btn"
                        style="margin-right:10px"
                    >
                        Save
                    </v-btn>
                        <v-btn color="primary" x-small @click="addDialog=true">
                            Add new activity
                        </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-dialog v-model="addDialog" persistent max-width="600px">
                        <form style="margin-top:0px" ref="addNewActivity" >
                            <v-card ref="observer" v-observe-visibility="addVisible">
                                <v-card-title>
                                    <span class="h5-text">New Activity</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            <ValidationProvider name="Session for activity" rules="required" v-slot="{errors}">
                                                <v-select v-model="selectedSession" :items="sessionList" label="Session for this Activity">
                                                    <template v-slot:item="{item}">
                                                        {{ sessions[item].title }}
                                                    </template>
                                                    <template v-slot:selection="{item}">
                                                        {{ sessions[item].title }}
                                                    </template>
                                                </v-select>
                                                <div class="form-error">{{ errors }}</div>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12">
                                            <ValidationProvider name="Activity name" rules="required" v-slot="{errors}">
                                                <v-text-field v-model="newName" prepend-icon="mdi-rename-box" label="Activity Name"/>
                                                <div class="text-left" style="color:red">{{ errors[0] }}</div>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field v-model="newObjective" label="Objective"/>
                                        </v-col>
                                        <v-col cols="12">
                                            <ValidationProvider name="Duration" rules="required|numeric" v-slot="{errors}">
                                                <v-text-field v-model="newDur" name="activity duration" label="Expected Duration (mins)"/>
                                                <div class="form-error">{{  errors[0] }}</div>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer/>
                                    <v-btn
                                        text
                                        @click="closeAddDialog(reset)"
                                    >
                                        Close
                                    </v-btn>
                                    <v-btn 
                                        color="primary"
                                        text 
                                        :disabled="invalid"
                                        @click="saveActivity(reset)"
                                    >
                                        Save
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </form>
                </v-dialog>
            </v-row>
            <v-row v-for="sessionId in sessionList" :key="sessionId">
                <v-col cols="12">
                    <tryout-activity-editor :sessionId="sessionId"/>
                </v-col>
            </v-row>
        </ValidationObserver>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {rules} from '@/_helpers/rules'
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import TryoutActivityEditor from '@/ClubPanes/NewTryoutSettingsPanels/TryoutActivityEditor'

export default {
    props: [],
    components: { ValidationObserver, ValidationProvider, TryoutActivityEditor},
    computed: {
        ...mapState({
            sessions: state => state.sessionStore.data,
            sessionList: state => state.sessionStore.list,

            perSessionActivities: state => state.activityStore.perSessionLists,


            teams: state => state.teamStore.data,
            teamList: state => state.teamStore.list,

            activities: state => state.activityStore.data,
            activityList: state => state.activityStore.list,

            groups: state => state.groupStore.data,
            groupList: state => state.groupStore.list,
            groupsByActivity: state => state.groupStore.groupsByActivity,

            participantList: state => state.participantStore.list,
            selectedTryout: state => state.navigatorStore.selectedTryout,

            sessionStatus: state => state.sessionStore.status,
            groupStatus: state => state.groupStore.status,
            teamStatus:     state => state.teamStore.status,
            activityStatus: state => state.activityStore.status,
            participantStatus: state => state.participantStore.status,

        
        }),
        loading: function(){
            return this.sessionStatus.loading
             || this.participantStatus.loading
             || this.activityStatus.loading
             || this.teamStatus.loading
             || this.groupStatus.loading
        },
        tryoutSessions: function() {
            return this.sessionList
        },
        user: function() {
            return JSON.parse(localStorage.getItem('user'))
        },
        changes() {
            return Object.keys(this.changeTracker).length>0 || this.orderChanges
        }
    },

    methods: {
        ...mapActions('activityStore',{
            createActivity: 'create',
            findAllActivitiesForEvent: 'findAllForEvent',
            updateActivity: 'update',
        }),
        ...mapActions('groupStore',{
            findAllGroupsForEvent: 'findAllForEvent',
            resetAvailablePlayers: 'resetAvailablePlayers',
        }),
        ...mapActions('roleStore', {
            findAllRolesForEvent: 'loadAllRolesForEvent',
        }),
        ...mapActions('participantStore', {
            findAllParticipantsForEvent: 'findAllForEvent',
        }),
        ...mapActions('sessionStore', { 
            findAllSessionsForEvent: 'findAllForEvent', 
            addSession : 'addSession' ,
            updateSession : 'updateSession',
            deleteSession : 'deleteSession',
        }),
        ...mapActions('teamStore', {
            loadTeamsForEvent: 'findAllForEvent',
            linkTeam: 'link',
            unlinkTeam: 'unlink',
        }),

        closeAddDialog: function(reset) {
            this.newName=''
            this.newObjective=''
            this.newDur=''
            this.selectedSession=''
            this.addDialog=false

            reset()
        },
        saveActivity: async function(reset){
            //const isValid = await this.$refs.form.validate() 
            let length=0

            if(Object.prototype.hasOwnProperty.call(this.perSessionActivities,this.selectedSession)){
                length = this.perSessionActivities[this.selectedSession].length
            }
            this.createActivity({
                name: this.newName,
                objective: this.newObjective,
                duration: this.newDur,
                order: length,
                eventId: this.selectedTryout ,
                sessionId: this.selectedSession,
            })
            this.$refs.form.reset()
            this.newObjective = ''
            this.newDur = ''
            this.newName=''
            this.selectedSession=''
            reset()
            this.addDialog=false
        },
        saveDetails(){
            for(var idx in this.activities){
                this.updateActivity(this.activities[idx])
            }
            this.$store.dispatch('alert/success', 'Tryout Updated', {root:true})
            this.$root.$emit("settings-clear-activity-changes-flags")
        },

        async load(){
                try{

                    this.runOnce=true

                    await this.findAllActivitiesForEvent({eventId:this.selectedTryout})
                    await this.findAllGroupsForEvent({eventId:this.selectedTryout}) 
                    await this.loadTeamsForEvent({eventId:this.selectedTryout})
                    await this.findAllSessionsForEvent(this.selectedTryout)

                    await this.findAllParticipantsForEvent(this.selectedTryout)
                    await this.findAllRolesForEvent({eventId: this.selectedTryout})
                    await this.resetAvailablePlayers({participantList: this.participantList, roleList: this.roleList, activityList: this.activityList})
                } catch (e){
                    console.error('Error')
                    console.error(e)
                }
        },
    },
    mounted(){
        this.$root.$on('tryoutRefresh', this.refreshHandler)
        this.$root.$on('settings-activity-changes-cleared', (id)=> {
            var self=this
            self.$delete(self.changeTracker, id)
        })
        this.$root.$on('settings-activity-changes-made', (id) => {
            var self=this
            self.$set(self.changeTracker, id, true)
        })
        this.$root.$on('submit-all-changes', () => {
            var self=this
            if(self.changes){
                self.saveDetails()
                self.changes=false
            }
        })

        this.$root.$on('backout-all-changes', () => {
            var self = this
            if(self.changes){
                self.load()
                self.changes=false
            }
        })
    }, 
    async created(){
        await this.load()
    },
    beforeDestroy(){
        this.$root.$off('tryoutRefresh', this.refreshHandler)
    },
    data() {
        return {
            refreshHandler: async() => { await this.load()},
            runOnce: false,
            orderChanges: false,
            changeTracker: {},
            addDialog: false,
            newDesc: '',
            newDur: '0',
            newName: '',
            newObjective: '',
            rules: rules,
            addVisible: false,
            list: [],
            selectedSession: '',
        }

    }
}
</script>

<style>

alert-icon {
  color:red;
  margin-bottom:2px;
  padding:5px;
  animation: blink 0.02s 20 alternate;
}

.form-error {
    color:red;
    text-align: left;
}

alert-icon:active {
  animation: blink 0.02s 20 alternate;
}

@keyframes blink {
  from { background-color: orange; }
  to { background-color: red; }
}
</style>