import {sendMessage} from '@/_helpers/service-tools'

export const attributeService = {
    findAllForEvent,
    create,
    update,
    archiveAttribute,
}

async function findAllForEvent(id) {
    return await sendMessage('GET','attribute/list', {eventId: id}, null)
}

async function create(attribute) {
    return await sendMessage('POST', 'attribute', null, attribute)

}

async function update(attribute) {
    return await sendMessage('PATCH', 'attribute', {attributeId: attribute.id}, attribute)
}

async function archiveAttribute(attributeId) {
    return await sendMessage('PATCH', 'attribute/archive', {attributeId}, null)
}

