<template>
    <div v-if="loading"/>
    <div v-else-if="true">
        <v-app-bar class="participant-bar" color="var(--cs1-lev2)">
            <v-row class="no-boundaries">
                <v-col cols="6" style="color:White;font-weight:600;text-align:left">
                    My Journal
                </v-col>
                <v-col style="display:flex;flex-flow:row;margin-top:auto;margin-bottom:auto" >
                    <v-spacer/>
                    <JournalEntryEditor tinyButton type="reflection" color="green" :journalOwnerId="account.id" style="margin-left:5px;margin-right:5px;"/>
                    <JournalEntryEditor tinyButton type="participantGoal" color="red" :journalOwnerId="account.id" style="margin-left:5px;margin-right:5px;"/>

                </v-col>
            </v-row>
        </v-app-bar>
        <journalTimeline :userId="userId" class="pt-2 timeline-wrapper"/>
    </div>
    <div v-else-if="$vuetify.breakpoint.mdAndUp" style="display:flex;flex-flow:row" >
        <v-navigation-drawer permanent :expand-on-hover="$vuetify.breakpoint.smAndDown" :mini-variant.sync="miniDrawer" height="100%" value="drawerVisible" style="padding-left:10px;padding-right:10px;">
            <div v-if="selectedParticipantId!== null" style="padding-top:10px;">
                <v-avatar v-if="miniDrawer" size="50">
                    <participant-profile-image :participantId="selectedParticipantId" size="50px"/>
                </v-avatar>
                <div v-else>
                    <v-avatar size="90">
                        <participant-profile-image :participantId="selectedParticipantId" size="90px"/>
                    </v-avatar>
                    <div style="font-weight:600;">
                        {{ account.firstName }} {{ account.lastName }}
                    </div>
                    <v-divider/>
                    <JournalEntryEditor type="reflection" color="green" :journalOwnerId="account.id"/>
                    <JournalEntryEditor type="participantGoal" color="red" :journalOwnerId="account.id"/>
                </div>
            </div>
        </v-navigation-drawer>
        <journalTimeline :userId="userId"/>
    </div>
    <v-row v-else class="no-boundaries" style="width:100%" >
        <v-col >
            <JournalEntryEditor type="reflection" color="green" :journalOwnerId="account.id"/>
            <JournalEntryEditor type="participantGoal" color="red" :journalOwnerId="account.id"/>
            <journalTimeline :userId="userId" />
        </v-col>
    </v-row>
</template>

<script>
import {mapState,mapActions} from 'vuex'

import JournalTimeline from '@/Journal/JournalTimeline'
import ParticipantProfileImage from '@/components/ParticipantProfileImage'

import JournalEntryEditor from './JournalEntryEditor.vue'

export default{
    props: [],
    components:{
        JournalTimeline,
        ParticipantProfileImage,
        JournalEntryEditor,
    },
    computed: {
        ...mapState({
            participants:           state => state.participantStore.data,
            participantsListByUID:  state => state.participantStore.listByUserId,
            participantStatus:      state => state.participantStore.status,

            tryouts:                state => state.tryoutStore.data,
            tryoutStatus:           state => state.tryoutStore.status,

            episodes:               state => state.episodeStore.data,
            episodeList:            state => state.episodeStore.list,

            selectedTryout:         state => state.navigatorStore.selectedTryout,
            selectedParticipantId:  state => state.navigatorStore.selectedParticipant,
            selectedNav:            state => state.navigatorStore.selectedNav,
            account:                state => state.account.user.details,
        }),
        loading(){
            return !this.dataReady;
        },
        selectedParticipantId(){
            return this.participantsListByUID[this.account.id]
        },
        userId(){
            console.log('Participants')
            console.log(this.participants)
            console.log(this.selectedParticipantId)
            console.log(this.participants[this.selectedParticipantId])

            return this.participants[this.selectedParticipantId].userId
        }

    },
    methods: {
        ...mapActions('participantStore',[
            'loadParticipant',
            'loadAllParticipantsForTeam',
        ]),
        ...mapActions('journalentryStore',[
            'toggleReadActivated',
        ]),
        ...mapActions('navigatorStore', [
            'setSelectedParticipant',
        ]),
        responsiveClass(className){
            if(this.$vuetify.breakpoint.smAndDown){
                return className+' mobile'
            }
            return className
        },
    },
    watch: {},
    async created(){
        this.dataReady = false
        if(this.selectedNav==='Team'){
            await this.loadAllParticipantsForTeam({teamId: this.selectedTryout})
        } else {
            console.log(this.selectedTryout)
            console.log(this.tryouts[this.selectedTryout])
            console.log(`Selected Participant: ${this.participantsListByUID[this.account.id]}`)
            await this.loadParticipant({participantId: this.tryouts[this.selectedTryout].participantId})
        }
        console.log('Loading Participants')
        this.setSelectedParticipant( this.participantsListByUID[this.account.id])
        this.dataReady=true
    },
    data(){
        return {
            dataReady: false,
            miniDrawer: false,
            drawerVisibility: true,
        }
    }
}
</script>
<style>
.timeline-wrapper {
    width: calc(100% - 56px);
}
</style>