//import { Stack } from '@/_helpers/stack'

import Vue from "vue"

function initialiseState(){
    return {
        status: {},
        stepData: [],
        step: 1,
        responses: {},
        complete: false,
        error: null,
        newParticipant: '',
        qresponses:{},
    }
}

const state = initialiseState()

const actions = {
    purgeData({commit}){
        commit('purgeData')
    },
    nextStep({commit}){
       commit('nextStep') 

    },
    lastStep({commit}){
        commit('lastStep')
    },
    setStep({commit}, step){
        commit('setStep', step)
    },
    setResponses({commit},payload){
        commit('setResponsesRequest')
        commit('setResponses', payload)
    },
    setCompletion({commit}, payload){
        const {complete} = payload
        commit('setCompletion', complete)
    },
    setError({commit}, payload){
        const {error} = payload
        commit('setError', error)
    },
    setNewParticipant({commit}, payload){
        const {participant} = payload
        commit('setNewParticipant', participant)
    },
    updateResponse({commit}, payload){
        commit('setResponse', payload)
    }
}

const mutations = {
purgeData(state){
    const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }
    },
    setResponse(state, payload){
        Vue.set(state.qresponses, payload.questionId, payload.value)
    },
    nextStep(state){
        state.step=state.step+1
    },
    lastStep(state) {
        if(state.step >0) state.step = state.step-1
    },
    setStep(state, newStep){
        state.step = newStep
    },
    setResponsesRequest(state){
        state.status={unstable: true}
    },
    setResponses(state, payload) {
        const {step, responses} = payload
        state.responses[step] = responses
        state.status={}
    },
    setCompletion(state, complete) {
        state.complete = complete
    },
    setError(state, error) {
        state.error = error
    },
    setNewParticipant(state, participant){
        state.newParticipant = participant
    }
}

export const tryoutregStore = {
    namespaced: true,
    state,
    actions,
    mutations,
}