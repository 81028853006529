
//import Vue from 'vue'


function initialiseState(){
    return {
        status: {},
        data: {
            user: {
                activityTemplates: true,
                activityDiagrams: true,
            },
            club: {
                activityTemplates: true,
                activityDiagrams: true,
            }
        }
    }
}

const state = initialiseState()

const actions = {
    purgeData({commit}){
        commit('purgeData')
    },

}

const mutations = {
purgeData(state){
    const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }
    },

}

export const licenseStore = {
    namespaced: true,
    state,
    actions,
    mutations
}