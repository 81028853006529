<template>
    <v-card style="margin: 10px 10px 0px 10px;padding: 10px 10px 0px 10px;">
    <div style="width:100%;text-align:left;">
        <v-btn color="primary" small style="margin:5px;text-align:left" @click="close">
            <v-icon>mdi-arrow-left</v-icon>
            Back
        </v-btn>
        <v-tabs v-model="tab" fixed-tabs>
            <v-tab id="1" key="1" href="#tab-1">Club Details</v-tab>
            <v-tab id="2" key="2" href="#tab-2">Public Page Appearance</v-tab>
            <v-tab id="3" key="3" href="#tab-3">Banking</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" style="width:100%">
            <v-tab-item key="1" value="tab-1">
                <v-card>
                    <v-row>
                        <v-col cols="6" class="d-flex align-items-start mt-0">
                            <div style="width:100%">
                            <v-text-field 
                                label="Club Name"
                                v-model="clubName"
                            />
                            <V-select :items="sportsArray" label="Club sport" chips v-model="clubSport"/>
                            <v-textarea
                                outlined
                                v-model="description"
                                label="Club Description"
                                counter 
                                :rules="[rules.descMinLength, rules.descMaxLength]"
                            ></v-textarea>
                            </div>
                        </v-col>
                        <v-col cols="6" class="d-flex align-items-start mt-0">
                            <div style="width:100%">
                            <v-text-field :rules="[rules.url]" label="Web Site Address" v-model="website">

                            </v-text-field>
                            <!-- 
                            <v-file-input 
                                show-size 
                                label="Club Icon"
                                :rules="[rules.size]"
                                accept="image/jpeg, image/png"
                                placeholder="Select a club logo (jpg or png only, max 500kB)"
                                prepend-icon="mdi-camera"
                                v-model="file"
                            />
                            -->
                            <v-img v-if="clubLogo" :src="clubLogo" max-height="200" max-width="200" style="margin-left:auto;margin-right:auto;"/>
                            <v-skeleton-loader v-else src="" type="image"/>
                            <div style="margin-top:10px;">
                                <ImageUpload label="Change club logo" type="Club Logo" :cropper="true" />
                            </div>

                            </div>
                        </v-col>
                    </v-row>
                    <v-divider/>
                    <v-row>
                        <v-col cols="12" class="text-left">

                            <h3>Email Settings</h3>

                            <v-checkbox v-model='testRegistrationEmails' label="Enable test emails for registrants"></v-checkbox>
                            <v-checkbox v-model='testOfferEmails' label="Enable test offer emails"></v-checkbox>
                            <v-text-field v-model='testEmailAddress' label="Send test emails to..."></v-text-field>
                        </v-col>
                    </v-row>
                </v-card>
            </v-tab-item>
            <v-tab-item key="2" value="tab-2">
                <div style="display:flex;flex-flow:row;height:180vh">
                    <div class="sample-pane">
                        <ClubPublicInfo fontSize="10px" disabled="true" />
                    </div>
                    <div class="page-settings-panel">
                        <v-row class="no-boundaries">
                            <v-col cols="4" class="page-setting-head">
                                Page background color
                            </v-col>
                            <v-col cols="1"  class="no-boundaries" style="margin-top:auto;margin-bottom:auto;">
                                <div class="dot" :style="iconColor" @click="showColorPicker=true"/>
                                <v-dialog class="color-dialog" v-model="showColorPicker">
                                    <v-card style="width:300px">
                                        <v-color-picker v-model="pageBackgroundColor" mode="hexa" style="width:300px"/>
                                    </v-card>
                                </v-dialog>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4" class="page-setting-head">
                                Page title font
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4" class="page-setting-head">
                                Page title color
                            </v-col>
                            <v-col cols="1"  class="no-boundaries" style="margin-top:auto;margin-bottom:auto;">
                                <div class="dot" :style="'background-color: '+pageTitleColor" @click="showFontColorPicker=true"/>
                                <v-dialog class="color-dialog" v-model="showFontColorPicker">
                                    <v-card style="width:300px">
                                        <v-color-picker v-model="pageTitleColor" mode="hexa" style="width:300px"/>
                                    </v-card>
                                </v-dialog>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <imageUpload text label="Change background image" type="Club Background" :cropper="true" />
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </v-tab-item>
            <v-tab-item key="3" value="tab-3">
                <div style="height:50vh;">
                    <club-banking />
                </div>
            </v-tab-item>
        </v-tabs-items>
<v-divider/>
    </div>
                <v-card-actions>
                    <v-dialog
                        v-model="deleteDialog"
                        persistent
                        width="60%"
                        max-width="60%"
                    >
                        <template v-slot:activator="{on,attrs}">
                            <v-btn
                                color="red"
                                text
                                dark 
                                v-bind="attrs"
                                v-on="on"
                            >
                                Delete This Club
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">Delete this club forever ?</span>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <span>Are you sure you want to delete this club? <br/><br/>
                                    This action cannot be undone, and all selection data associated with this club will be erased. <br/>
                                    <v-divider></v-divider>
                                    <em>Note:</em> User accounts for athletes and staff will not be deleted, however all data relevant to those users in relation to this club will be removed.</span>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn
                                    color="blue darken-1"
                                    text 
                                    @click="deleteDialog=false"
                                >
                                    No, keep my club
                                </v-btn>
                                <v-spacer/>
                                <v-btn 
                                    color="red darken-1"
                                    text 
                                    @click="deleteClub"
                                    >
                                    Yes, delete this club forever!
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-spacer/>
                    <v-btn
                        text 
                        @click="close()"
                    >Close</v-btn>
                </v-card-actions>

</v-card>
</template>


<script>
import { mapState, mapActions} from 'vuex'
import ClubBanking from '@/ClubPanes/ClubSettingsPanes/ClubBanking'
import {router} from '@/_helpers'
import ClubPublicInfo from '@/ClubPanes/ClubPublicInfo'
import ImageUpload from '@/components/ImageUpload'

export default {
    props: ['showBanking'],
    components: {
        ClubBanking,
        ClubPublicInfo,
        ImageUpload,
    },
    computed: {
        ...mapState({
            account: state => state.account,
            selectedClub: state => state.navigatorStore.selectedClub,
            selectedSeason: state => state.navigatorStore.selectedSeason,
            clubs: state => state.clubStore.data,

            sports:     state => state.sportStore.data,
            sportsList: state => state.sportStore.list,
        }),
        loading: function() {
            return this.account.status.loading || this.selectedClub===''
        },
        imageLoaded(){
            if(!this.image) return false
            return Object.prototype.hasOwnProperty.call(this.image, 'dataUrl')
        },
        iconColor: {
            get() {
                return `background-color: ${this.pageBackgroundColor}` 
            },
        },
        sportsArray: function(){
                var sportsObjs=[]
                for(var idx in this.sportsList){
                    sportsObjs.push({
                        text: this.sports[this.sportsList[idx]].name,
                        value: this.sportsList[idx]
                    })

                }
                return sportsObjs
            },
        testRegistrationEmails: {
            get(){
                return this.clubs[this.selectedClub].testRegistrationEmails
            },
            set(newValue) {
                this.updateClubProp({
                    field: 'testRegistrationEmails',
                    id: this.selectedClub,
                    value:newValue,
                })
                this.updateClub({club: this.clubs[this.selectedClub]})
            }
        },
        testOfferEmails: {
            get(){
                return this.clubs[this.selectedClub].testOfferEmails
            },
            set(newValue){
                this.updateClubProp({
                    field: 'testOfferEmails',
                    id: this.selectedClub,
                    value: newValue
                })
                this.updateClub({club: this.clubs[this.selectedClub]})
            }
        },
        testEmailAddress: {
            get(){
                return this.clubs[this.selectedClub].testEmailAddress
            },
            set(newValue){
                this.updateClubProp({
                    field: 'testEmailAddress',
                    id: this.selectedClub,
                    value: newValue
                })
                this.updateClub({club: this.clubs[this.selectedClub]})
            }
        },

        clubLogo: {
            get() {
                return this.clubs[this.selectedClub].logo
            },
            set(newValue) {
                this.updateClubProp({
                    field: 'logo',
                    id: this.selectedClub,
                    value: newValue
                })
                this.updateClub({club: this.clubs[this.selectedClub]})
            }
        },
        clubName: {
            get() {
                return this.clubs[this.selectedClub].name
            },
            set(newValue) {
                this.updateClubProp({
                    field: 'name',
                    id: this.selectedClub,
                    value: newValue
                })
                this.updateClub({club: this.clubs[this.selectedClub]})
            }
        },
        clubSport: {
            get() {
                return this.clubs[this.selectedClub].sportId
            },
            set(newValue){
                this.updateClubProp({
                    field: 'sportId',
                    id: this.selectedClub,
                    value: newValue
                })
                this.updateClub({club: this.clubs[this.selectedClub]})
            }
        },
        description: {
            get() {
                return this.clubs[this.selectedClub].description
            },
            set(newValue) {
                this.updateClubProp({
                    field: 'description',
                    id: this.selectedClub,
                    value: newValue
                })
                this.updateClub({club: this.clubs[this.selectedClub]})
            }
        },
        website: {
            get(){
                return this.clubs[this.selectedClub].website
            },
            set(newValue){
                this.updateClubProp({
                    field: 'website',
                    id: this.selectedClub,
                    value: newValue
                })
                this.updateClub({club: this.clubs[this.selectedClub]})
            }
        },
        filelink: {
            get() {
                return this.clubs[this.selectedClub].logo
            }
        },
        file: {
            get() {
                return this.aFile
            },
            async set(newValue) {
                this.aFile=newValue
                const apiUrl = process.env.VUE_APP_CSAPI
                const localUser = JSON.parse(localStorage.getItem('user'))
                const url = new URL(`${apiUrl}/aws/uploadPath`)

                // What was the file extension

                const ext = this.aFile.name.substring(this.aFile.name.lastIndexOf('.')+1, this.aFile.name.length)

                url.searchParams.append('filename', `clublogos/${this.selectedClub}.`+ext )
                url.searchParams.append('type', `Upload Club Logo` )
                url.searchParams.append('clubId', this.selectedClub)

                const signedURL = await fetch(url, {

                        method: 'GET',
                        headers: {
                            'Content-Type':'application/json',
                            'Authorization':'Bearer '+localUser.token,
                        }
                    }).then(response => {
                        return response.json()
                    }
                )
                
                // Upload the file

                const result = await fetch(signedURL.uploadURL, {
                    method: 'PUT',
                    headers: {
                        'Origin':'https://www.coachsight.net'
                    },
                    body: this.aFile
                }).then(response => {
                    return response
                })
                if (result.status=="200") {
//                    this.$store.dispatch('alert/success', 'Your  has been created', {root: true})
                    const logo = signedURL.uploadURL.split('?')[0]
                    await this.updateClubProp({field: 'logo', id: this.selectedClub, value: logo }) 
                    await this.updateClub({
                        club: {
                            id: this.selectedClub, 
                            logo: logo,
                        }
                    })
                    this.$toast.success('Your club logo has been updated')
                } else {
//                    this.$store.dispatch('alert/warn', 'Your club logo failed to upload. Please try uploading it in the club profile page', {root: true})
                    this.$toast.error('Your file upload did not succeed. Pleasse try again or contact support if the problem persists')
                }
                this.dialog=false;



            },

        },
        pageBackgroundColor: {
            get() {
                return this.clubs[this.selectedClub].pageBackgroundColor
            },
            async set(newValue){
                this.updateClubProp({
                    field: 'pageBackgroundColor',
                    id: this.selectedClub,
                    value: newValue
                })
                await this.updateClub({club: this.clubs[this.selectedClub]})
            }
        },
        pageTitleFont: {
            get(){
                return this.clubs[this.selectedClub].pageTitleFont
            },
            async set(newValue){
                this.updateClubProp({
                    field: 'pageTitleFont',
                    id: this.selectedClub,
                    value: newValue,
                })
            }
        },
        pageTitleColor: {
            get(){
                return this.clubs[this.selectedClub].pageTitleColor
            },
            async set(newValue){
                this.updateClubProp({
                    field: 'pageTitleColor',
                    id: this.selectedClub,
                    value: newValue,
                })
            }
        },
        pageBackgroundImage: {
            get(){
                return this.clubs[this.selectedClub].pageBackgroundImage
            },
            async set(newValue){
                this.updateClubProp({
                    field: 'pageBackgroundImage',
                    id: this.selectedClub,
                    value: newValue,
                })
            }
        },
    },
    methods: {
        ...mapActions('clubStore', {
            updateClubProp: 'propertyUpdate',
            updateClub: 'update',
            archiveClub: 'archive',
        }),
        ...mapActions('navigatorStore', [
            'selectPane',
            'selectNav',
            'lastPane'
        ]),

        deleteClub: async function() {
            this.archiveClub({clubId: this.selectedClub})
            this.deleteDialog=false
            router.push('/')
            this.$store.dispatch('alert/success','Your club has been removed', {root:true})
        },
        close(){
            if(/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(this.clubs[this.selectedClub].website) ===false){
                this.clubs[this.selectedClub].website=""
            }

//            this.clubHomeNav()
console.log('Last pane')
            this.lastPane()

        },
        save(){

        },
        clubHomeNav() {
            this.selectNav('Club')
            this.selectPane('Club')
        },


        // Cropper methods

        
        uploadImage(event) {
            // Reference to the DOM input element
            var input = event.target;
            // Ensure that you have a file before attempting to read it
            if (input.files && input.files[0]) {
                // create a new FileReader to read this image and convert to base64 format
                var reader = new FileReader();
                // Define a callback function to run, when FileReader finishes its job
                reader.onload = (e) => {
                // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
                // Read image as base64 and set to imageData
                this.img = e.target.result;
                };
                // Start the reader job - read file as a data url (base64 format)
                reader.readAsDataURL(input.files[0]);
            }
        },
    


    },

    async created() {
        if(this.showBanking){
            this.tab='tab-2'
        }
    },
    data() {
        return {
            showColorPicker:false,
            showFontColorPicker: false,
            tab: 0,
            rules: {
                size: value => !value || value.size < 500000 || 'Logo size must be under 500kB',
                descMaxLength: value => value.length < 2000 || 'Description can be at most 2000 characters',
                descMinLength: value => value.length > 1 || 'Description must be provided',
                url: value => /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value)
            },
            aFile: null,
            deleteDialog: false,
            aPageBackgroundColor: '#FFFFFF',
            fonts: [
                "Arial",
                "Verdana",
                "Tahoma",
                "Trebuchet MS",
                "Times New Roman",
                "Georgia",
                "Garamond",
                "Courier New",
                "Brush Script MT",
            ],
        }

    }
}



</script>


<style scoped>

v-expansion-panels {
    width: 80%;
}
.coachsight-pane {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    margin: 0vw 1vw 0vw 1vw;
    width: 97vw;
}

.clubpane {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: inline-block;
    float: left;

}

.sample-pane{
    pointer-events: none;
    width:60%;
    aspect-ratio:2.1;
    height:70vh;

    flex-grow:0;

}
.page-settings-panel{
    margin:30px 10px 30px 10px;
    flex-grow:1
}
.page-setting-head{
    margin:0px;
    padding:0px;
    text-align:right;
    font-weight: 300;
    font-size:medium;

}

.dot {
  height: 20px;
  width: 20px;
  background-color: #bbb;
  border-radius: 50%;
  border-color:grey;
  border-style:solid;
  border-width:2px;
  display: inline-block;
  margin-top:auto;
  margin-bottom:auto;
}



</style>