import {sendMessage} from '@/_helpers/service-tools'

export const messagingService = {
    sendInvitationEmail,
    sendOfferEmail,
    sendRegEmail,
    sendStaffInvitationEmail,
    sendStaffEndowEmail,
    sendStaffRevokeEmail,
}

async function sendInvitationEmail(eventId , recipientList) {
    return await sendMessage('POST', 'messaging/sendinvitationemail', {eventId}, {recipientList})
}

async function sendOfferEmail(eventId , recipientList, test) {
    return await sendMessage('POST', 'messaging/sendofferemail', {eventId, test}, {recipientList})
}

async function sendRegEmail(eventId , participantId) {
    return await sendMessage('GET', 'messaging/sendregemail', {eventId, participantId}, null)
}

async function sendStaffInvitationEmail(eventId , email,name, newUserId) {
    return await sendMessage('GET', 'messaging/staffinvitationemail', {eventId, email, name, newUserId}, null)
}

async function sendStaffEndowEmail(eventId , email,name, newUserId) {
    return await sendMessage('GET', 'messaging/staffendowemail', {eventId, email, name, newUserId}, null)
}

async function sendStaffRevokeEmail(eventId , email,name, newUserId) {
    return await sendMessage('GET', 'messaging/staffrevokeemail', {eventId, email, name, newUserId}, null)
}


