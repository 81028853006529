import Vue from 'vue'
import { userService } from '../_services'

function initialiseState(){
    return {
        all: {},
        internal: {},
        data: {},
        list: [],
        meta: {},
        error: {},
        status: {},

    }
}

const state = initialiseState()

const actions = {
    purgeData({commit}){
        commit('purgeData')
    },
    async getByToken({commit}, payload) {
        try{
            commit('getByTokenRequest')
            const response = await userService.getUserByToken(payload)
            commit('getByTokenSuccess', response)
        } catch(error) {
            commit('getByTokenFailure', error)
        }
    },
    async loadUserById({commit}, payload) {
        try{
            commit('getByEmailRequest')
            const response = await userService.getUserById(payload)
            commit('getByEmailSuccess', response)
        } catch(error) {
            error => commit('getByEmailFailure', error)
        }
    },
    /*RETIRE*/
    async getById({commit}, payload) {
        try{
            commit('getByEmailRequest')
            const response = await userService.getUserById(payload)
            commit('getByEmailSuccess', response)
        } catch(error) {
            error => commit('getByEmailFailure', error)
        }
    },
    async getByEmail({commit}, email) {
        try{
            commit('getByEmailRequest')
            const response = await userService.getUserByEmail(email)
            commit('getByEmailSuccess', response)
            return response
        } catch(error) {
            commit('getByEmailFailure', error)
        }
    },
    async getAll({commit}) {
        try{
            commit('getAllRequest')
            const response = await userService.getAll()
            commit('getAllSuccess', response)
        } catch(error) {
            commit('getAllFailure', error)
        }
    },
    async delete({commit}, id) {
        try{
            commit('deleteRequest', id);
            const response = await userService.delete(id)
            commit('deleteSuccess', response)
        } catch(error) {
            commit('deleteFailure', {id, error: error.toString() })
        }
    },
    async createManual({commit}, user) {
        try{
            commit('createManualRequest', user )
            const response = await userService.createManual(user)
            commit('createManualSuccess', response)
        } catch(error) {
            commit('createManualFailure', error)
        }
    },
    async update({commit}, payload) {
        try{
            commit('updateRequest')
            const response = await userService.update(payload)
            commit('updateSuccess', response)
        } catch(error) {
            commit('updateFailure', error)
        }
    },
    async verifyPasswordToken({commit}, payload){
        try{
            commit('verifyRequest')
            const response = await userService.verifyPasswordToken(payload)
            commit('verifySuccess', response)
        } catch(error) {
            commit('verifyFailure', error)
        }
    },
    async newPasswordToken({commit, dispatch}, payload){
        try{
            commit('newPasswordTokenRequest')
            const response = await userService.newPasswordToken(payload)
            commit('newPasswordTokenSuccess', response)
        } catch(error) {
            commit('newPasswordTokenFailure', error)
            dispatch('alert/error', 'There was an error communicaing to the server. Please try again shortly', {root:true})
        }
    },
    propertyUpdate({commit},payload) {
        commit('propertyUpdate', payload)
    }
}

function addToLists(data) {
    const id = data.id
    state.list.push(id)
    Vue.set(state.data, id, data)
    Vue.set(state.meta, id, {
            delete : false,
            selected: false,
    })
}

const mutations = {
purgeData(state){
    const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }
    },
    newPasswordTokenRequest(state){
        state.status={loading:true}
    },
    newPasswordTokenSuccess(state, response){
        state.status={newToken:true, response}
    },
    newPasswordTokenFailure(state, error){
        state.status={error:true}
        state.error = error
    },
    verifyRequest(state){
        state.status={loading:true}
    },
    verifySuccess(state, response){
        if(response.valid){
            state.status={verified: true}
        }
        else{
            state.status={verified: false}
        }
    },
    verifyFailure(state, error){
        state.error={error}
    },
    updateRequest(state, user){
        state.internal.userToUpdate = user
        state.internal.response=null
        state.error={}
    },
    updateSuccess(state, response){
        state.internal.response=response
    },
    updateFailure(state, error){
        state.error = error
    },
    getByEmailRequest(state) {
        state.all = {loading: true}
        state.data={}
        state.meta={}
        state.list=[]
        state.error=[]
    },
    getByEmailSuccess(state, user) {
        
        state.all = {user: user, loaded:true}


        if(user.users.length>0) 
            addToLists(user.users[0])
        else
            state.all = user.users[0]
    },
    getByEmailFailure(state, error) {
        state.all = {error}
        state.error=error
    },
    getByTokenRequest(state) {
        state.all = {loading: true}
        state.status = {loading:true}
        state.data={}
        state.meta={}
        state.list=[]
        state.error=[]
    },
    getByTokenSuccess(state, user) {
        state.all = {user: user}
        state.status={}

        if(user.users.length>0) 
            addToLists(user.users[0])
    },
    getByTokenFailure(state, error) {
        state.all = {error}
        state.status={}
        state.error=error
    },




    getAllRequest(state) {
        state.all = {loading: true}
        state.meta={}
        state.data={}
        state.list=[]
        state.error=[]
    },
    getAllSuccess(state,users) {
        state.all = {items: users}
        for(var idx in users){
            addToLists(users[idx])
        }
    },
    getAllFailure(state, error) {
        state.all = {error}
        state.error=error
    },
    deleteRequest(state, id) {
        // add 'deleting:true' property to user being deleted
        state.all.items = state.all.items.map(user =>
            user.id === id
                ? { ...user, deleting: true}
                : user
        )
    },
    deleteSuccess(state, id) {
        // remove deleted user from state

        state.all.items = state.all.items.filter(user => user.id!== id)


    },
    deleteFailure(state, {id, error }) {
        // remove 'deleting:true' property and add 'deleteError:[error]' property to user
        state.all.items = state.items.map(user => {
            if(user.id === id) {
                // make copy of user without 'deleting:true' property
                // eslint-disable-next-line
                const { deleting, ...userCopy } = user
                // return copy of user with 'deleteError:[error]' property
                return { ...userCopy, deleteError: error }
            }
            return user
        })
    },
    createManualRequest(state, user) {
        state.all = {loading: true, userData: user}
    },
    createManualSuccess(state, user) {
        state.all = {user: user}

    },
    createManualFailure(state, error) {
        state.all = {error}
    },
    //------------ update data -------- \\
    propertyUpdate(state, payload) {
        const metaFields = ""
        const {field, id, value} = payload

        if(metaFields.includes(field)){
            state.meta[id][field]=value
            // Manage special fields...
            //switch (field) {
            //}
        } else {
            state.data[id][field]=value
        }
    }
}

export const userStore = {
    namespaced: true,
    state,
    actions,
    mutations
}