<template>
    <div v-if="!loading" class="regpane">
        <span class="panetitle">Registration Form: {{tryouts[tryoutId].title}}</span>
        <v-divider></v-divider>
        <v-stepper v-if="juniorAthlete && juniorAthletesAllowed" v-model="regFormState" >
            <v-stepper-header>
                <v-stepper-step :complete="regFormState > 1" step="1" >
                    <small>Tryout Information</small>
                </v-stepper-step>

                <v-stepper-step :complete="regFormState>2" step="2" >
                    <small>Application Questions</small>
                </v-stepper-step>

                <v-stepper-step :complete="regFormState >2" step="3" >
                    <small>Parental consent</small>
                    <small>(For junior athletes)</small>
                </v-stepper-step>

                <v-stepper-step :complete="regFormState>3" step="4" >
                    <small>Confirmation and Payment</small>
                </v-stepper-step>


                <v-stepper-step step="5" >
                    <small>Completion</small>
                </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
                <v-stepper-content step="1">
                    <tryout-information class="fullpane"/>
                </v-stepper-content>

                <v-stepper-content step="2">
                    <tryout-questions class="fullpane"/>
                </v-stepper-content>

                <v-stepper-content step="3">
                    <tryout-child-safety :key="formStep" class="fullpane"/>
                </v-stepper-content>

                <v-stepper-content step="4">
                    <tryout-confirmation :key="formStep" class="fullpane"/>
                </v-stepper-content>

                <v-stepper-content step="5">
                    <tryout-completion :key="formStep" class="fullpane"/>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>

        <v-stepper v-else-if="!juniorAthlete" v-model="regFormState" >
            <v-stepper-header>
                <v-stepper-step :complete="regFormState > 1" step="1" >
                    <small>Tryout Information</small>
                </v-stepper-step>

                <v-stepper-step :complete="regFormState>2" step="2" >
                    <small>Application Questions</small>
                </v-stepper-step>

                <v-stepper-step :complete="regFormState>3" step="3" >
                    <small>Confirmation and Payment</small>
                </v-stepper-step>

                <v-stepper-step step="4" >
                    <small>Completion</small>
                </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
                <v-stepper-content step="1">
                    <tryout-information class="fullpane"/>
                </v-stepper-content>

                <v-stepper-content step="2">
                    <tryout-questions class="fullpane"/>
                </v-stepper-content>

                <v-stepper-content step="3">
                    <tryout-confirmation :key="formStep" class="fullpane"/>
                </v-stepper-content>

                <v-stepper-content step="4">
                    <tryout-completion :key="formStep" class="fullpane"/>
                </v-stepper-content>
            </v-stepper-items>
        
        </v-stepper>
        <div v-else>
            Juniors Not Permitted for this Tryout
        </div>

    </div>


</template>

<script>
import {mapState, mapActions} from 'vuex'
import moment from 'moment'
import TryoutInformation from '@/ClubPanes/TryoutRegistrationPanels/TryoutInformation'
import TryoutQuestions from '@/ClubPanes/TryoutRegistrationPanels/TryoutQuestions'
import TryoutChildSafety from '@/ClubPanes/TryoutRegistrationPanels/TryoutChildSafety'
import TryoutConfirmation from '@/ClubPanes/TryoutRegistrationPanels/TryoutConfirmation'
import TryoutCompletion from '@/ClubPanes/TryoutRegistrationPanels/TryoutCompletion'

export default {
    props: ['tryoutId'],
    components: {
        TryoutInformation,
        TryoutQuestions,
        TryoutChildSafety,
        TryoutConfirmation,
        TryoutCompletion,
    },
    computed: {
        ...mapState({
            account:                state => state.account,
            
            tryouts:                state => state.tryoutStore.data,
            tryoutStatus:           state => state.tryoutStore.status,
            tryoutList:             state => state.tryoutStore.list,

            formStep:               state => state.tryoutregStore.step,
            selectedTryout:         state => state.navigatorStore.selectedTryout,
            selectedSeason:         state => state.navigatorStore.selectedSeason,
            selectedClub:           state => state.navigatorStore.selectedClub,

            participants:           state => state.participantStore.data,
            participantList:        state => state.participantStore.list,
            participantStatus:      state => state.participantStore.status,

            qresponses:             state => state.qresponseStore.data,
            qresponseList:          state => state.qresponseStore.list,
            qresponseStatus:        state => state.qresponseStore.status,

            questions:              state => state.appquestionStore.data,
        }),
        loading(){
            return false   
                || this.qresponseStatus.loading
                || this.participantStatus.loading
                || this.tryoutStatus.loading
                || this.incompleteData

        },
        startDate: {
            get() {
                return moment(this.tryouts[this.tryoutId].startDate).format("dddd MMMM Do")
            }
        },
        endDate: {
            get() {
               return moment(this.tryouts[this.tryoutId].endDate).format("dddd MMMM Do, YYYY") 
            }
        },
        description: {
            get() {
                return this.tryouts[this.tryoutId].description
            }
        },
        reqAttendance: {
            get() {
                return this.tryouts[this.tryoutId].reqAttendance
            }
        },
        regFormState: {
            get() {
               return this.formStep 
            },
            set(newValue) {
                this.setStep(newValue)
            }
        },
        juniorAthlete() {
            const age = new Date().getFullYear() -  this.account.user.details.yearOfBirth
            return age < this.tryouts[this.tryoutId].juniorAge
        },
        juniorAthletesAllowed() {
            return this.tryouts[this.tryoutId].allowJuniors
        }

    },
    methods: {
        ...mapActions('qresponseStore', {
            loadResponses: 'findAllForParticipant'
        }),
        ...mapActions('sessionStore', {
            findAllSessions: 'findAllForEvent',
        }),
        ...mapActions('navigatorStore', {
            selectTryout: 'selectTryout',
        }),
        ...mapActions('tryoutregStore', {
            setStep: 'setStep',
            setResponses: 'setResponses',
            nextStep: 'nextStep',
            lastStep: 'lastStep',
            updateResponse: 'updateResponse',
        }),
        /*
        ...mapActions('appquestionStore', {
            findAllQuestions : 'loadAllQuestionsForEvent'
        }),
        */
        ...mapActions('messageStore',{
            findAllMessages: 'loadAllForEvent'
        }),
        ...mapActions('participantStore',{
            findParticipant: 'inEvent'
        }),
        ...mapActions('tryoutStore', {
            loadTryout: 'loadTryout',
        }),
        onComplete() {
        },
        newRegistration(){
            return this.participantList.length>0
        }
    },
    mounted() {
    },
    async created() {
        this.incompleteData=true


        this.regFormState=1

        const localUser = JSON.parse(localStorage.getItem('user'))

        const participants= await this.findParticipant({userId: localUser.details.id, eventId: this.tryoutId})

        // What do we know about this participants current registration state?

        // If the participant doesn't exist, we can set the step and exit...

        if (participants.length ===0){
            this.regFormState=1
            // Load any default responses...
            const qResponses={}
            for(var qidx in this.questions){
                if(this.questions[qidx].type === 'select' || this.questions[qidx].type==='checkbox') {
                    qResponses[this.questions[qidx].id] = this.questions[qidx].default?parseInt(this.questions[qidx].default):199
                    await this.updateResponse({questionId: qidx, value: this.questions[qidx].default?parseInt(this.questions[qidx].default):188})

                } else {
                    qResponses[this.questions[qidx].id] = this.questions[qidx].default
                    await this.updateResponse({questionId: qidx, value: this.questions[qidx].default})

                }
            }

        } else {
            const participant = this.participants[this.participantList[0]]

            // load up the previous responses...
            await this.loadResponses(participant.id)
            const qResponses={}
            for(var idx in this.qresponseList){
                const questionId = this.qresponses[this.qresponseList[idx]].questionId

                if(this.questions[questionId].type === 'select' || this.questions[questionId].type==='checkbox') {

                    // Need to convert the string stored into an int for the controls to accept their values

                    qResponses[questionId] = parseInt(this.qresponses[this.qresponseList[idx]].response)
                    this.updateResponse({questionId: questionId, value: parseInt(this.qresponses[this.qresponseList[idx]].response)  })
                } else {
                    qResponses[questionId] = this.qresponses[this.qresponseList[idx]].response
                    this.updateResponse({questionId: questionId, value: this.qresponses[this.qresponseList[idx]].response  })
                }
            }
            //this.setResponses({step:2, responses: qResponses})

            const s3Responses={}
            s3Responses.parentConsent = participant.parentConsent
            s3Responses.imageConsent = participant.imageConsent
            s3Responses.parentName = participant.parentName
            s3Responses.parentPhone = participant.parentPhone
            s3Responses.parentEmail = participant.parentEmail
            this.setResponses({step:3, responses: s3Responses})


//            {"2":{"a6962174-c10e-4352-819e-9054a931b47c":"fdsa","1f112b2d-6cf9-43d6-9cdd-41c3c5fa44c0":"ghhggh","d9d7a34d-b226-4e8a-b0b2-e83b48e69b05":"eeeee"}}

            // Are they already registered and paid up?
            if(participant.registrationStatus === 'complete'){
                // 
                // Go straight to the last step
                if(this.juniorAthlete && this.juniorAthletesAllowed){
                    this.regFormState=5
                } else {
                    this.regFormState=4
                }
            } else

            
            // Are they registered but not yet paid up?
            if(participant.registrationStatus === 'incomplete'){
                // load up the question responses and Send them to the confirm and pay page... 
                if(this.juniorAthlete && this.juniorAthletesAllowed){
                    this.regFormState=4
                } else {
                    this.regFormState=3
                }

            } else

            // Are they completely unregistered?
            if(this.newRegistration){
                this.regFormState=1
            }

        }
        this.incompleteData = false
    },
    data() {
        return {
            incompleteData: true,
        }
    }
}


</script>

<style>

    .regpane {
        padding:10px;
    }

    .finishedEvent {
        color:red;
        text-decoration: line-through;
    }
    .panetitle {
        font-weight: 900;
        font-size: larger;
        line-height: 200%;
    }
    .panedate {
        font-weight: 500;
        line-height: 200%;
    }
    .sessiontitle {
        display: block;
        text-align: left;
        font-weight: 900;
        font-size: large;
    }

    .description {
        text-align: left;
    }

    .fullpane {
        height: 100%;
        margin: 0px;
        margin-top:5px;
        padding:0px;
    }

    .buttons {
        margin-top:10px;
    }
</style>
