<template>
    <loading v-if="loading"/>
    <div v-else>
        <v-row>
            <v-col cols="9" class="pane-heading">
                Edit Offer Email
            </v-col>
            <v-col cols="1">
                <v-btn small color="primary" v-if="editting" @click="save">Save</v-btn>
                <v-btn small color="primary" v-else @click="editting=true">Edit</v-btn>
            </v-col>
            <v-col cols="1">

                <v-btn v-if="false" small text color="primary">Restore</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header style="font-size:small">
                        <b>Editor Instructions</b>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="text-left">
                
                    You can define the mail that goes out to successful candidates here. There are special values you can insert in your message:<br/>
                    <br/>
                    <ul>
                        <li ><b>$fname </b>: The Athlete's first name</li>
                        <li ><b>$lname</b>: The Athletes last name</li>
                        <li ><b>$team</b>: The name of the team that the offer is for</li>
                        <li ><b>$clubname</b>: The name of your club</li>
                        <li ><b>$clublogo</b>: Your club logo (if it has been uploaded)</li>


                    </ul>
                    <span style="font-weight:600;color: #cc6600;">Don't forget to include a link to the offer review page (see the example below).  </span>
                    <ul>
                        <li><b>$link</b>: The link to the Offer Review page</li>
                    </ul>
                    <br/>
                    The following values can also he inserted if they have been defined for the tryout in "Settings"<br/>
                    <ul>
                        <li><b>$cname</b>: The name of the tryout contact person</li>
                        <li><b>$cphone</b>: The phone number for the tryout contact person</li>
                        <li><b>$cemail</b>: The email for the tryout contact person</li>
                        <li><b>$deadline</b>: The final date that offers can be taken up</li>
                        <li><b>$training</b>: The training details provided for the selected team</li>
                    </ul>

                    </v-expansion-panel-content>
                </v-expansion-panel>

            </v-expansion-panels>
        </v-row>
        <v-row>
            <div v-if="!editting" style="padding-top: 20px">
                <b>Preview Email</b>
            </div>
            <div v-if="editting" style="padding-top: 20px">
                <b>Edit your Offer Email below</b>
            </div>
        </v-row>
        <v-row>
            <div style="width:100%">
                <v-card class="vue-editor">
                    <table class="offer-table" border="0">
                        <tbody>
                            <tr>
                                <div v-if="editting" >
                                    <vue-editor v-if="editting" v-model="offerMessage" :editorOptions="editorSettings" width="100%" />
                                </div>
                                <div v-else >
                                    <render-offer-email
                                        :firstName="firstName"
                                        :lastName="lastName"
                                        :teamOffered="teamOffered"
                                        :tryoutId="selectedTryout"
                                        :offerContactName="offerContactName"
                                        :offerContactPhone="offerContactPhone"
                                        :offerContactEmail="offerContactEmail"
                                        :offerTraining="offerTraining"
                                    />
                                </div>
                            </tr>
                        </tbody>
                    </table>
                </v-card>
            </div>
        </v-row>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import Loading from '@/components/loading'

import { VueEditor } from 'vue2-editor'
//eslint-disable-next-line
import DividerToolbar from 'quill-divider'

import RenderOfferEmail from '@/components/RenderOfferEmail'

export default {
    props: [],
    components: {
        VueEditor,
        Loading,
        RenderOfferEmail
    },
    computed: {
        ...mapState({
            messages:       state => state.messageStore.data,
            messageList:    state => state.messageStore.list,
            messageStatus:  state => state.messageStore.status,
            msgByName:      state => state.messageStore.listByName,
            clubs:          state => state.clubStore.data,
            clubList:       state => state.clubStore.list,
            clubStatus:     state => state.clubStore.status,
            selectedClub:   state => state.navigatorStore.selectedClub,
            selectedTryout: state => state.navigatorStore.selectedTryout,
        }),
        loading() {
            return false
                || this.messageStatus.loading

        },
        offerMessage: {
            get() {
                return this.messages[this.msgByName['offerEmail'].id].message
            },
            set(newValue) {
                this.updateMessageProperty({field: 'message', id: this.msgByName['offerEmail'].id, value: newValue})
            }
        },
    },
    methods: {
        ...mapActions('messageStore',{
            loadAllMessages: 'loadAllForEvent',
            createMessage: 'create',
            updateMessage: 'update',
            updateMessageProperty: 'propertyUpdate',
        }),

        save() {
            this.updateMessage({message: this.messages[this.msgByName['offerEmail'].id]})
            this.editting=false
        },
    },
    created() {
        if(this.selectedTryout) {
            this.loadAllMessages(this.selectedTryout)
        }

    },
    data() {
        return {
            quill: null,
            editting: false,
            firstName: 'Jane',
            lastName: 'Smith',
            teamOffered: 'The Team',
            offerLink: '<a href="http://www.coachsight.net" rel="noopener noreferrer" target="_blank">Review Offer</a>',
            offerContactName:  'John Doe',
            offerContactEmail: 'john@user.com',
            offerContactPhone: '0455 555 555',
            offerTraining: 'Training is every Tuesday from 6 until 8pm',
            
//            block: `<h2>$clublogo $clubname</h2><p>\n</p><div class="quill-hr" contenteditable="false"><hr style="border: none;border-bottom: 1px inset;" class=""></div><p>\n</p><p>Dear $fname,</p><p>\n</p><p>Thank you for taking the time to try-out with $clubname. We are pleased to be able to offer you a position in the following team:</p><p>\n</p><p class="ql-align-center"><span style="color: rgb(0, 102, 204);">$team</span></p><p>\n</p><h3>Your Next Steps:</h3><ol><li>Please respond to accept of decline this offer, by clicking on this link: Review Offer</li><li>If you accept our offer please complete our Membership Form here</li></ol><p>\n</p><h4>Fees:</h4><p>The Season fees for this year are $XXX. If you are new to our club, or require a new uniform for any reason, there will be qan additional uniform fee.</p><p>\n</p><h3>Training Sessions:</h3><p>Training is on Thursday nights at 6:00pm.</p><p>\n</p><h4>Offer Deadline:</h4><p>The deadline for accepting your offer is 5:00 pm, Friday, 13th March</p>`,
            block: `<h2>$clublogo $clubname</h2><p>\n</p><div class="quill-hr" contenteditable="false"><hr style="border: none;border-bottom: 1px inset;" class=""></div><p>\n</p><p>Dear $fname,</p><p>\n</p><p>Thank you for taking the time to try-out with $clubname. We are pleased to be able to offer you a position in the following team:</p><p>\n</p><p class="ql-align-center"><span style="color: rgb(0, 102, 204);">$team</span></p><p>\n</p><h3>Your Next Steps:</h3><ol><li>Please respond to accept of decline this offer, by clicking on this link: $offerLink</li><li>If you accept our offer please complete our Membership Form here</li></ol><p>\n</p><h4>Fees:</h4><p>The Season fees for this year are $XXX. If you are new to our club, or require a new uniform for any reason, there will be qan additional uniform fee.</p><p>\n</p><h3>Training Sessions:</h3><p>Training is on Thursday nights at 6:00pm.</p><p>\n</p><h4>Offer Deadline:</h4><p>The deadline for accepting your offer is 5:00 pm, Friday, 13th March</p>`,
            customToolbar: [

                [{header: []}],
                ["bold","italic","underline",{ "color": []} ],
                [{list: "ordered"}, {list: "bullet"}],
                [{align: []}],
                [{'cname': '3'}],
                ["close-button"],
            ],
            editorSettings: {
                modules:{
                    divider: true,
                    toolbar: {
                        container: 
                        [
                            [{header: []}],
                            ["bold","italic","underline",{ "color": []} ],
                            [{list: "ordered"}, {list: "bullet"}],
                            [{align: []}],
                            ["divider", "link"],
                            ["closeButton"],

                        ],
                        handlers: {
                            closeButton: () => {
                            },
                            customBtn: () => {
                                var range = this.quill.getSelected()
                                if(range) {
                                    this.quill.insertText('ArgH!')

                                }
                            }
                        }
                    }
                }
            },

        }
    }
}
</script>

<style scoped>
.ql-close-button {
    color: black;
}

.offer-table{
    border-collapse: collapse; 
    width:100%;
    color:var(--cs1-lev1);
}

ul {
    list-style: disc;
}

.custom-button{
    width: 100px !important;
}

.instruction-div{
    border-style: solid;
    border-color: black;
}
.instruction{
    width: 100%;
    font-size: small;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    padding-left: 10px;
}
</style>