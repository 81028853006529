import { clubService } from '../_services'
import Vue from 'vue'

const dataService = clubService

function initialiseState(){
    return {
        internal: {},
        status: {},
        data: {},
        error: null,
        cache: {},
        list: [],
        searchList: [],
        meta: {},
        created: null
    }
}

const state = initialiseState()

const actions = {
    //eslint-disable-next-line
    purgeData({commit}){
       // commit('purgeData')
    },
    async load({commit}, payload) {
        commit('loadRequest') 

        try{
            const response=await dataService.load(payload)
            commit('loadSuccess', response.clubs)

        } catch (error) {
            commit('loadFailure', error)
        }
    },
    async loadClub({commit}, payload) {
        commit('loadRequest') 

        try{
            const response=await dataService.load(payload)
            commit('loadSuccess', response.clubs)

        } catch (error) {
            commit('loadFailure', error)
        }
    },

    async reload({commit}, payload) {
        commit('reloadRequest')
        try{
            const response = await dataService.load(payload)
            commit('reloadSuccess', response.clubs)
        } catch(error) {
            commit('reloadFailure')
        }
    },
    async create({commit, dispatch}, payload) {
//        const {name, shortName, description, country, state, city, sport, logo} = payload
        commit('createRequest')
        try{
            const response = await dataService.create(payload)
            commit('createSuccess', response)
        } catch(error) {
            commit('createFailure', error)
            dispatch('alert/error', error, {root: true})

        }
    },
    async searchForName({commit}, payload) {
        try{
            const {name} = payload
            commit('searchRequest')
            const response = await dataService.find(name)
            commit('searchSuccess', response)
        } catch(error) {
            commit('searchFailure', error)
        }
    },
    async clearSearch({commit}) {
        commit('clearSearch')
    },
    async findAllForUser({commit}, payload) {
        try{
            const {userId} = payload
            commit('loadRequest')
            const response = await dataService.findAllForUser(userId)
            commit('loadSuccess', response)
        } catch(error) {
            commit('loadFailure', error)
        }
    },
    propertyUpdate({commit},payload) {
        commit('propertyUpdate', payload)
    },
    async update({commit, dispatch}, payload){
        try{
            const {club} = payload
            commit('updateRequest')
            const response = await dataService.updateClub(club)
            commit('updateSuccess', response)
        } catch(error) {
            commit('updateFailure', error)
            dispatch('alert/error', error)
        }
    },
    async archive({commit}, payload) {
        try{
            const {clubId} = payload
            commit('archiveRequest')
            await dataService.archive(clubId)
            commit('archiveSuccess' )
        } catch(error) {
            commit('archiveFailure', error)
        }
    },
    async unarchive({commit}, payload) {
        try{
            const {club} = payload
            commit('unarchiveRequest')
            await dataService.unarchive(club)
            commit('unarchiveSuccess', club.id)
        } catch(error) {
            commit('unarchiveFailure', error)
        }
    }
}
function addToLists(list, data) {
    const id = data.id

    list.push(id)

    Vue.set(state.data, id, data)
    Vue.set(state.meta, id, {
            delete : false,
    })
}

function sortListByOrder(list) {
        list.sort((a,b) => {
            return state.data[a].name.localeCompare(state.data[b].name)
        })
}

const mutations = {
    purgeData(state){
    const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }
    },
    clearSearch(state) {
        state.searchList=[]
        state.status={}
    },
    searchRequest(state) {
        state.status={loading: true}
        state.error = null
    },
    searchSuccess(state, data) {
        state.status={}
        const {clubs} = data
        state.searchList=[]
        for(var idx in clubs) {
            addToLists(state.searchList, clubs[idx])
        }
        sortListByOrder(state.searchList)
    },
    searchFailure(state, error) {
        state.status={}
        state.error=error
    },

    createRequest(state) {
        state.status={loading: true}
        state.error=null
    },
    createSuccess(state, data) {
        const club = data.club
        state.status={created: true, id:club.id}
        addToLists(state.list, club)
        sortListByOrder(state.list)
        state.error=null
    },
    createFailure(state, error) {
        state.status={}
        state.error=error
    },

    //------ UPDATE -------\
    updateRequest(state) {
        state.internal={loading: true}
        state.error=null
    },
    //eslint-disable-next-line
    updateSuccess(state, data){
        state.internal={updated: true}
        state.error=null
    },
    updateFailure(state, error) {
        state.status={}
        state.error=error
    },

    archiveRequest(state, clubId){
        state.internal={clubId}
        state.status={loading:true}
        state.error=null
    },
    archiveSuccess(state) {
        state.status={},
        state.error=null,
        Vue.delete(state.data, state.internal.clubId)
        Vue.delete(state.meta, state.internal.clubId)
        const list=[]
        for(var idx in state.list){
            if(state.list[idx] !== state.internal.clubId) {
                list.push(state.list[idx])
            }
        }
        state.list=list
        state.internal={}
    },
    archiveFailure(state, error){
        state.status={}
        state.error=error
    },
    unarchiveRequest(state){
        state.status={loading:true}
        state.error=null
    },
    //eslint-disable-next-line
    unarchiveSuccess(state, club){
        state.status={}
        state.error=null
    },
    unarchiveFailure(state, error){
        state.status={}
        state.error=error
    },

    // ------------- loadClubs ------------- \
    
    loadRequest(state) {
        state.status={loading: true}
        state.error=null
    },
    loadSuccess(state, data) {
        state.status={}
        state.data={}
        state.list=[]

        if(data.length===1) localStorage.setItem('currentLicense', data[0].license)
        for(var idx in data){
            addToLists(state.list, data[idx])
        }
        sortListByOrder(state.list)
    },
    loadFailure(state, error) {
        state.status={}
        state.error = error
    },

    reloadRequest(state) {
        state.internal={loading: true}
        state.error=null
    },
    reloadSuccess(state, data){
        for(var idx in data){
            Vue.set(state.data, data[idx].id, data[idx])
        }
        state.internal={}
    },
    reloadFailure(state, error){
        state.error=error
        state.internal={}
    },


    //------------ update data -------- \\
    propertyUpdate(state, payload) {
        const metaFields = "delete"
        const {field, id, value} = payload

        if(metaFields.includes(field)){
            state.meta[id][field]=value
            // Manage special fields...
            //switch (field) {
            //}
        } else {
            state.data[id][field]=value
        }
    }

}
export const clubStore = {
    namespaced: true,
    state,
    actions,
    mutations
}