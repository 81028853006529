var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('v-row',[_c('div',{staticClass:"junior-participant text-left"},[_c('h3',[_vm._v("Parental/Guardian Consent")]),_c('em',[_c('small',[_vm._v("This section applies to participants attending who are under the age of "+_vm._s(_vm.juniorAge)+" at December 31 this year.")])])])]),_c('v-row',[_c('v-divider')],1),_c('v-row',[_c('div',{staticClass:"ql-editor",domProps:{"innerHTML":_vm._s(_vm.parentInfo)}})]),_c('v-row',[_c('v-divider')],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"parentName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Parent/Guardian name:"},model:{value:(_vm.pName),callback:function ($$v) {_vm.pName=$$v},expression:"pName"}}),_c('div',{staticClass:"field-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Parent/guardian phone number","rules":"required|mobileNumber:AU"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Parent/Guardian phone number:"},model:{value:(_vm.pPhone),callback:function ($$v) {_vm.pPhone=$$v},expression:"pPhone"}}),_c('div',{staticClass:"field-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Parent/Guardian email address","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Parent/Guardian email address:"},model:{value:(_vm.pEmail),callback:function ($$v) {_vm.pEmail=$$v},expression:"pEmail"}}),_c('div',{staticClass:"field-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('v-row',[_c('ValidationProvider',{attrs:{"name":"parentalConsent","rules":"set"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"label":"I consent to my child participating in this event, and acknowledge the information in this 'Parental/Guardian' section of the application process"},model:{value:(_vm.parentalConsent),callback:function ($$v) {_vm.parentalConsent=$$v},expression:"parentalConsent"}}),_c('div',{staticClass:"field-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-img',{attrs:{"src":_vm.account.user.details.profileImage,"max-width":"200px","max-height":"200px"}})],1),_c('v-col',{staticStyle:{"margin-top":"0px"},attrs:{"cols":"9"}},[_c('v-checkbox',{attrs:{"label":"I consent to my child's profile image (shown left) being made available to staff during the event "},model:{value:(_vm.imageConsent),callback:function ($$v) {_vm.imageConsent=$$v},expression:"imageConsent"}})],1)],1)],1),_c('v-divider'),_c('div',{staticClass:"buttons"},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.lastStep()}}},[_vm._v(" Back ")])],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":function($event){return _vm.saveResponses()}}},[_vm._v(" Continue ")])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")])],1)],1)],1)]}}],null,false,1210056434)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }