import  {sessionService } from '../_services'
import Vue from 'vue'

function initialiseState(){
    return {
        status: {},
        internal: {},
        data: {},
        meta: {},
        list: [],
        error: null
    }
}

const state = initialiseState()

const actions = {
    purgeData({commit}){
        commit('purgeData')
    },
    async findOne({commit}, payload){
        try{
            commit('findOneRequest')
            const session = await sessionService.findOne(payload)
            commit('findOneSuccess',session)
        } catch(error){
            commit('findOneFailure', error)
        }
    },

    async loadAllSessionsForEvent({dispatch, commit}, payload) {
        try{
            commit('findAllForEventRequest')
            const sessions = await sessionService.findAllForEvent({id: payload.eventId})
            commit('findAllForEventSuccess', sessions)
            return sessions

        } catch(error) {
                commit('findAllForEventFailure', error)
                dispatch('alert/error', error, {root: true})
        }
    },
    async loadAllSessionsForTeam({dispatch, commit}, payload) {
        try{
            commit('findAllForEventRequest')
            const sessions = await sessionService.loadAllSessionsForTeam({id: payload.teamId})
            commit('findAllForEventSuccess', sessions)
            return sessions

        } catch(error) {
                commit('findAllForEventFailure', error)
                dispatch('alert/error', error, {root: true})
        }
    },
    async findAllForEvent({dispatch, commit}, id) {
        try{
            commit('findAllForEventRequest')
            const sessions = await sessionService.findAllForEvent({id: id})
            commit('findAllForEventSuccess', sessions)
            return sessions

        } catch(error) {
                commit('findAllForEventFailure', error)
                dispatch('alert/error', error, {root: true})
        }
    },
    async quietFindAllForEvent({dispatch, commit}, id) {
        try{
            commit('quietFindAllForEventRequest')
            const response = await sessionService.findAllForEvent(id)
            commit('findAllForEventSuccess', response)
        } catch(error) {
            commit('findAllForEventFailure', error)
            dispatch('alert/error', error, {root: true})
        }
    },
    async addSession({dispatch, commit}, session) {
        try{
            commit('addSessionRequest')
            const response = await sessionService.addSession(session)
            setTimeout(() => {
                commit('addSessionSuccess', response)
                dispatch('alert/success', 'Your new session has been created.', {root: true})
            })
        } catch(error) {
            setTimeout(() => {
                commit('addSessionFailure', error)
                dispatch('alert/error', error, {root: true})
            }, 4000)
        }
    },
    async updateSession({dispatch, commit}, session) {
        try{
            commit('updateSessionRequest')
            await sessionService.updateSession(session)
            setTimeout(() => {
                commit('updateSessionSuccess')
            })
        } catch(error) {
            setTimeout(() => {
                commit('updateSessionFailure', error)
                dispatch('alert/error', error, {root: true})
            })
        }
    },
    async deleteSession({dispatch, commit}, sessionId) {
        try{
            commit('deleteSessionRequest')
            const response = sessionService.deleteSession(sessionId)
            setTimeout(() => {
                commit('deleteSessionSuccess', response)
            })
        } catch(error) {
            setTimeout(() => {
                commit('deleteSessionFailure', error)
                dispatch('alert/error', error, {root: true})
            })
        }
    },
    async sessionCheckin({commit}, payload) {
        try {
            // Payload is userId, sessionId
            commit('checkinSessionRequest')
            const response = await sessionService.checkinSession(payload)
            commit('checkinSessionSuccess', response)
            return response
        } catch(error) {
            commit('checkinSessionFailure', error)
        }
    },
    update({commit}, payload) {
        commit('update', payload)
    }
}

function extractDateAndTimes(session) {
    const date1 = new Date(session.startDateTime)
    const date2 = new Date(session.endDateTime)
    const date = date1.toLocaleDateString("en-CA")
    const startTime = date1.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
    const endTime = date2.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })

    return {date, startTime, endTime}
}

function buildSession(element) {
    const id = element.id

    state.list.push(id)
  
    Vue.set(state.data, id, element)
    Vue.set(state.meta, id, {
        delete: false,
        showStartDateTime: false,
        showDate: false,
        showStart: false,
        showEnd: false,
        ...extractDateAndTimes(element),
    })
}

function sortIDList() {
    state.list.sort(function(a,b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(state.data[a].startDateTime) - new Date(state.data[b].startDateTime);
    });
    /*
    state.list.sort(function(a,b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(state.data[a].title) - new Date(state.data[b].title);
    }); 
    */
}

const mutations = {
purgeData(state){
    const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }
    },
    checkinSessionRequest(state, payload){
        state.internal.payload=payload
        state.error={}
        state.status.loading=true
    },
    checkinSessionSuccess(state, response) {
        state.status = {
            responseCode: response.data.code ,
            participantId: response.data.participantId
        }
    },
    checkinSessionFailure(state, error){
        state.error=error
    },
    quietFindAllForEventRequest(state) {
        state.status={}
        state.error=null

    },
    // eslint-disable-next-line
    findOneRequest(state){

    },
    // eslint-disable-next-line
    findOneSuccess(state, session){
        buildSession(session)

    },
    findOneFailure(state, error){
        state.error=error
    },
    findAllForEventRequest(state) {
        state.status={loading: true}
        state.error=null
    },
    findAllForEventSuccess(state, sessions) {
        state.status={}
        state.list=[]
        state.data={}
        state.meta={}
        state.sessions={}

        for(var idx in sessions){
            buildSession(sessions[idx])
        }

        sortIDList()
    },
    findAllForEventFailure(state, error) {
        state.status={}
        state.error=error
    },
    
    //-------

    addSessionRequest(state) {
        state.internal={loading: true}
        state.error=null
    },
    addSessionSuccess(state, payload) {
        state.internal = {added: true}

        buildSession(payload.session)
        sortIDList()
    },
    addSessionFailure(state, error) {
        state.internal={}
        state.error=error
    },

    // ------- Update ------ //
    updateSessionRequest(state) {
        state.internal = {loading: true}
        state.error=null
    },
    updateSessionSuccess(state) {
        state.internal = {updated: true}
    },
    updateSessionFailure(state, error) {
        state.internal={}
        state.error=error
    },

    // ------- Delete ------ //
    deleteSessionRequest(state) {
        state.internal = {loading: true}
        state.error=null
    },
    deleteSessionSuccess(state, sessionId ) {
        const list=[];
        Vue.delete(state.sessions, sessionId)
        Vue.delete(state.data, sessionId)
        Vue.delete(state.meta, sessionId)
        for(var idx in state.list) {
            if(state.list[idx] !== sessionId) {
                list.push(state.list[idx])
            }
        }
        state.list = list
        state.internal = {deleted: true}
    },
    deleteSessionFailure(state, error) {
        state.internal={}
        state.error=error
    },
    update(state, payload) {
        const metaFields="date startTime endTime delete"

        const {field, id, value} = payload
        if(metaFields.includes(field)){
            state.meta[id][field]=value
            switch (field) {
                case 'date': {
                    // Update the date and times in the data object too
                    state.data[id].startDateTime = new Date(value + "T" +  state.meta[id].startTime)
                    state.data[id].endDateTime = new Date(value + "T" +  state.meta[id].endTime)
                    break;
                }
                case 'endTime': {
                    state.data[id].endDateTime = new Date(state.meta[id].date + "T" + value) 
                    break;
                }
                case 'startTime': {
                    state.data[id].startDateTime = new Date(state.meta[id].date + "T" + value) 
                    break;
                }
            }
        } else {
            state.data[id][field]=value
        }
    },
}

export const sessionStore = {
    namespaced: true,
    state,
    actions,
    mutations
}