<template>
    <Loading v-if="loading"/>
    <div v-else class="regpane">
        <div class="welcome">Welcome to CoachSight!</div>
        <br/>
        <div class="welcome-text">Hi {{user.firstName}}, now is the perfect time to complete your profile information so your team knows you...</div>
        <v-stepper v-model="regFormState">
            <v-stepper-header>
                <v-stepper-step :complete="regFormState>1" step="1">
                    <small>Your Profile Information</small>
                </v-stepper-step>
                <v-stepper-step :complete="regFormState>2" step="2">
                    <small>Your Profile Photo</small>
                </v-stepper-step>
                <v-stepper-step :complete="regFormState>2" step="3">
                    <small>Profile Complete</small>
                </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
                <v-stepper-content step="1">
                    <ValidationObserver>
                        <v-card>
                            <v-card-title>Profile Information</v-card-title>
                            <v-card-text class="text-left">
                                <v-row>
                                    <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                                        <ValidationProvider name="First Name" rules="required" v-slot="{errors}">
                                            <v-text-field label="First name" v-model="firstName"/>
                                            <div class="field-error">{{ errors[0] }}</div>
                                        </ValidationProvider>

                                    </v-col>
                                    <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                                        <ValidationProvider name="Last Name" rules="required" v-slot="{errors}">
                                            <v-text-field label="Last name" v-model="lastName"/>
                                            <div class="field-error">{{ errors[0] }}</div>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" sm="12" ms="4" lg="4" xl="4">
                                        <ValidationProvider name="Nickname" v-slot="{errors}">
                                            <v-text-field label="Nickname" v-model="nickname"/>
                                            <div class="field-error">{{ errors[0] }}</div>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                        <ValidationProvider name="Email address" rules="required|email|emailconfirm:@confirmEmail" v-slot="{errors}">
                                            <v-text-field label="Email Address" v-model="email"/>
                                            <div class="field-error">{{ errors[0] }}</div>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                        <ValidationProvider name="Confirm email address" rules="required|email" v-slot="{errors}">
                                            <v-text-field label="Email Address" v-model="email"/>
                                            <div class="field-error">{{ errors[0] }}</div>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                        <!--TODO : INTERNATIONAL-->
                                        <ValidationProvider name="Phone Number" rules="required|mobileNumber:AU" v-slot="{errors}">
                                            <v-text-field label="Phone Number" v-model="phone"/>
                                            <div class="field-error">{{ errors[0] }}</div>
                                        </ValidationProvider>
                                    </v-col>

                                </v-row>
                                <div class="text-h6">Bio</div>
                                <div>Tell us a little bit about yourself...</div>
                                <vue-editor v-model="bio" :editorOptions="editorSettings" width="100%"/>
                                <div class="text-h6">Sports</div>
                                <div>What sports do you play, or are you involved with?</div>
                                <v-text-field v-model="sports"/>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn color="primary" text @click="setStep(2)">Next</v-btn>
                            </v-card-actions>
                        </v-card>
                    </ValidationObserver>
                </v-stepper-content>
                <v-stepper-content step="2">
                    <ValidationObserver>
                        <v-card>
                            <v-card-title>Your profile photo</v-card-title>
                            <v-card-text>
                                This is important to make sure your coaches know who you are!

                                <div>
                                    <span slot="title">Profile Image</span>
                                    <div slot="content">
                                        <div v-if="account.user.details.profileImage">
                                            <v-img contain :src="account.user.details.profileImage" height="150px" max-height="150px"/>
                                        </div>
                                        <div v-else>
                                            <v-icon size="200">mdi-account-circle</v-icon>
                                        </div>
                                        <ImageUpload consentRequired label="Profile Image" type="User Image" cropper/>

                                    </div>
                                </div>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn text @click="setStep(1)">Back</v-btn>
                                <v-btn color="primary" text @click="setStep(3)">Next</v-btn>
                            </v-card-actions>
                        </v-card>
                    </ValidationObserver>
                </v-stepper-content>
                <v-stepper-content step="3">
                    <ValidationObserver>
                        <v-card>
                            <v-card-title>All done!</v-card-title>
                            <v-card-text>
                                Congratulations - you're all done!
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn text @click="setStep(2)">Back</v-btn>
                                <v-btn color="primary" text @click="done()">Complete</v-btn>
                            </v-card-actions>
                        </v-card>
                    </ValidationObserver>
                </v-stepper-content>

            </v-stepper-items>
        </v-stepper>
    </div>
</template>

<script>
import { mapState , mapActions} from 'vuex';
import { ValidationObserver, ValidationProvider} from 'vee-validate'
import Loading from '@/components/loading'
import { VueEditor} from 'vue2-editor'
import ImageUpload from '@/components/ImageUpload'

import zxcvbn from 'zxcvbn';

export default {
    props: ["userId", "token", "eventId"],
    components: {
        ValidationObserver,
        // eslint-disable-next-line
        ValidationProvider,
        Loading,
        VueEditor,
        ImageUpload,
    },
    computed: {
        ...mapState({
            formStep:       state => state.account.step,
            account:        state => state.account,
        }),
        fieldRules() {
            return { 
                size: value => ! value || value.size < 1500000 || 'Profile Image must be < 500kB',
            }
        },
        loading(){
            return false  
        },
        passwordRequired(){
            return true
        },
        confirmRule(){
            return `match:${this.user.password}`
        }   ,
        user(){
            return this.account.user.details
        },
        firstName: {
            get(){
                return this.account.user.details.firstName
            },
            set(newValue){
                this.propertyUpdate({field: 'firstName', value: newValue})
            }
        },
        lastName: {
            get(){
                return this.account.user.details.lastName
            },
            set(newValue){
                this.propertyUpdate({field: 'lastName', value: newValue})
            }
        },
        nickname: {
            get(){
                return this.account.user.details.nickname
            },
            set(newValue){
                this.propertyUpdate({field: 'nickname', value: newValue})
            }
        },
        email: {
            get(){
                return this.account.user.details.email
            },
            set(newValue){
                this.propertyUpdate({field: 'email', value: newValue})
            }
        },
        phone: {
            get(){
                return this.account.user.details.phone
            },
            set(newValue){
                this.propertyUpdate({field: 'phone', value: newValue})
            }
        },
        bio: {
            get(){
                return this.account.user.details.bio
            },
            set(newValue){
                this.propertyUpdate({field: 'bio', value: newValue})
            }
        },
        sports: {
            get(){
                return this.account.user.details.sports
            },
            set(newValue){
                this.propertyUpdate({field: 'sports', value: newValue})
            }
        },


        regFormState: {
            get(){
                return this.formStep
            },
            set(newValue){
                this.setStep(newValue)
            }
        },
        score() {
            const result = zxcvbn(this.user.password);
            switch(result.score)
            {
                case 4: return {
                    color: "light-green accent-3",
                    value:100
                }
                case 3: return {
                    color: 'lime',
                    value:75
                }
                case 2: return {
                    color: "yellow",
                    value: 50
                }
                case 1: return {
                    color: "orange",
                    value: 25
                }
                default: return {
                    color: "red",
                    value: 0
                }
            }
        },
        file: {
            get() {
                return this.aFile
            },
            async set(newValue) {
                this.aFile=newValue
                this.changes=true

                if(this.aFile.size >1500000) return;

                // If the file is under 1500K just throw it into AWS S3

                const apiUrl = process.env.VUE_APP_CSAPI
                const localUser = JSON.parse(localStorage.getItem('user'))
                const url = new URL(`${apiUrl}/aws/uploadPath`)

                // What was the file extension

                const ext = this.aFile.name.substring(this.aFile.name.lastIndexOf('.')+1, this.aFile.name.length)

                url.searchParams.append('type', 'Upload User Image')
//                url.searchParams.append('filename', `userimages/${this.account.user.details.id}.`+ext )
                url.searchParams.append('filename', `tempfilename.`+ext )
                const signedURL = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Content-Type':'application/json',
                            'Authorization':'Bearer '+localUser.token,
                        }
                    }).then(response => {
                        return response.json()
                    }
                )
                
                // Upload the file

                const result = await fetch(signedURL.uploadURL, {
                    method: 'PUT',
                    headers: {
                        'Origin':'https://www.coachsight.net'
                    },
                    body: this.aFile
                }).then(response => {
                    return response
                })
                if (result.status=="200") {
//                    this.$store.dispatch('alert/success', 'Your profile has been created', {root: true})
                    const profileImage = "https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/userimages/"+this.account.user.details.id+'.' + ext
                    this.propertyUpdate({field: 'profileImage', value: profileImage }) 
                    await this.update({userId: this.account.user.details.id, user: this.account.user.details})
                } else {
                    this.$store.dispatch('alert/warn', 'Your profile image failed to upload. Please check the size and format (jpeg or png, <500kB) and try uploading it again', {root: true})
                }
                
            }
        }
    },
    methods: {
        ...mapActions('account', {
            setStep: 'setStep',
            nextStep: 'nextStep',
            lastStep: 'lastStep',
            forceLogin: 'forceLogin',
            login: 'login',
            reset: 'reset',
            update: 'update',
            propertyUpdate: 'propertyUpdate',
        }),
        ...mapActions('userStore', {
            getUserByToken: 'getByToken',
        }),
        async done(){
            
            this.account.user.details.updateDetails=false
            this.update({user: this.account.user.details, userId: this.account.user.details.id})
        },
    },

    async created(){
    },
    data(){
        return {
            aFile:[],
            changes:false,
            showPassword: false,
            confirm:null,
            confirmEmail: '',
            confirmPassword: '',
            editorSettings: {
                modules:{
                    divider: true,
                    toolbar: {
                        container: 
                        [
                            [{header: []}],
                            ["bold","italic","underline",{ "color": []} ],
                            [{list: "ordered"}, {list: "bullet"}],
                            [{align: []}],
                            ["divider", "link"],
                            ["closeButton"],

                        ],
                        handlers: {
                            closeButton: () => {
                            },
                            customBtn: () => {
                                var range = this.quill.getSelected()
                                if(range) {
                                    this.quill.insertText('ArgH!')

                                }
                            }
                        }
                    }
                }
            },

        }
    }
}
</script>

<style scoped>
.welcome{
    font-weight: 600;
    font-size: larger;
    text-align:left
}
.welcome-text{
    text-align: left
}
</style>