function initialiseState(){
    return {
        type: null,
        message: null,
        color: null
    }
}

const state = initialiseState()

const actions = {
    purgeData({commit}){
        commit('purgeData')
    },
    info({commit}, message) {
        commit('info', message)
    },
    success({commit} , message) {
        commit('success', message)
    },
    error({commit}, message) {
        commit('error', message)
    },
    clear({commit}) {
        commit('clear')
    }
}

const mutations = {
purgeData(state){
    const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }
    },
    info(state, message) {
        state.type = 'alert-info'
        state.message = message
        state.color = "blue"
        state.timeout=6000
    },
    success(state, message) {
        state.type = 'alert-success'
        state.message = message
        state.color = "green"
        state.timeout=6000
    },
    error(state, message) {
        state.type = 'alert-danger'
        state.message = message
        state.color = "red"
        state.timeout=6000
    },
    clear(state) {
        state.type = null
        state.message = null
        state.color=null
        state.timeout=0
    }

}

export const alert = {
    namespaced: true,
    state,
    actions,
    mutations
}