<template>
    <div>
        <ValidationProvider v-if="questions[qid].type === 'role'" :name="qid" :rules="applicableRules[questions[qid]]" v-slot="{errors}">
            <v-input :value="roletest" v-model="roletest">
                <template v-slot:default>
                    <div>
                        <span style="font-weight:600">{{questionNumber(qid)}}</span>
                            {{questions[qid].question}}
                        <span v-if="questions[qid].required" style="color:red;">*</span>
                    </div>
                    <v-row class="question" >
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6" :class="responsiveClass('question')">
                            <v-select :tabIndex="0" v-model="value[0]" label="First preference" :items="roleList">
                                <template v-slot:item="{item}">
                                    {{ roles[item].name }}
                                </template>
                                <template v-slot:selection="{item}">
                                    {{ roles[item].name }}
                                </template> 
                            </v-select>
                        </v-col>
                        <v-col v-if="secondRole" cols="12" sm="12" md="6" lg="6" xl="6" :class="responsiveClass('question')" >
                            <v-select :tabIndex="0" v-model="value[1]" label="Second preference" :items="roleList">
                                <template v-slot:item="{item}">
                                    {{ roles[item].name }}
                                </template> 
                                <template v-slot:selection="{item}">
                                    {{ roles[item].name }}
                                </template> 
                            </v-select>
                        </v-col>
                    </v-row>
                </template>
            </v-input>
            <div class="field-error">{{errors[0]}}</div>
        </ValidationProvider>

        <ValidationProvider v-if="questions[qid].type === 'short'" :name="qid" :rules="applicableRules(questions[qid])" v-slot="{ errors}">
            <v-text-field :tabIndex="0" :readonly="readonly" hide-details="true" v-model="returnValue" class="shrink">
                <template v-slot:label>
                    <div class="text-wrap" style="display:flex;flex-flow:row;">
                        <div style="font-weight:600">{{questionNumber(qid)}}</div>
                        <div v-html="renderQuestionLabel(qid)" :id="$style.htmlContainer"></div>
                        <div v-if="questions[qid].required" style="color:red;">*</div>
                    </div>
                </template>
            </v-text-field>
            <div class="field-error">{{errors[0]}}</div>
        </ValidationProvider>

        <ValidationProvider v-if="questions[qid].type==='long'" :name="qid" :rules="applicableRules(questions[qid])" v-slot="{errors}">
            <v-textarea :tabIndex="0" :readonly="readonly" hide-details="true" v-model="returnValue" class="shrink">
                <template v-slot:label>
                    <div class="text-wrap" style="display:flex;flex-flow:row;">
                        <div style="font-weight:600">{{questionNumber(qid)}}</div>
                        <div v-html="renderQuestionLabel(qid)" :id="$style.htmlContainer"></div>
                        <div v-if="questions[qid].required" style="color:red;">*</div>
                    </div>
                </template>
            </v-textarea>
            <div class="field-error">{{errors[0]}}</div>
        </ValidationProvider>
        <div v-if="questions[qid].type==='select' && readonly" >
                <v-text-field :tabIndex="0" :readonly="readonly" hide-details="true" :value="renderResponse(qid, returnValue)" class="shrink">
                    <template v-slot:label>
                        <!-- 
                        <p class="text-wrap">
                            <span style="font-weight:600">{{questionNumber(qid)}}</span>
                            {{questions[qid].question}}
                        </p>
                    -->
                        <div class="text-wrap" style="display:flex;flex-flow:row;">
                            <div style="font-weight:600">{{questionNumber(qid)}}</div>
                            <div v-html="renderQuestionLabel(qid)" :id="$style.htmlContainer"></div>
                            <div v-if="questions[qid].required" style="color:red;">*</div>
                        </div>
                    </template>
                </v-text-field>
            </div>
        <ValidationProvider v-if="questions[qid].type==='select' && !readonly" :name="qid" :rules="applicableRules(questions[qid])" v-slot="{errors}">
            <v-select :tabIndex="0" hide-details="true" :items="questionOptions" item-text="text" item-value="value" :label="questionNumber(qid)+questions[qid].question" v-model="returnValue" :value="returnValue">
                <template v-slot:label>
                    <div class="text-wrap" style="display:flex;flex-flow:row;">
                        <div style="font-weight:600">{{questionNumber(qid)}}</div>
                        <div v-html="renderQuestionLabel(qid)" :id="$style.htmlContainer"></div>
                        <div v-if="questions[qid].required" style="color:red;">*</div>
                    </div>
                </template>
            </v-select>

            <div class="field-error">{{errors[0]}}</div>
        </ValidationProvider>

    </div>
</template>

<script>
import {mapState} from 'vuex'

import { ValidationProvider  } from 'vee-validate';
import {v4 as uuidv4} from 'uuid'

export default{
    props: {
        qid: {
            type: String,
            required: true
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        value:{}
    },
    components:{
        ValidationProvider
    },
    computed: {
        ...mapState({
            questions:              state => state.appquestionStore.data,
            roleList:               state => state.roleStore.list,
            roles:                  state => state.roleStore.data,
        }),
        loading(){
            return false ||
                false;
        },
        /*
        response: {
            get(){
                if(Object.prototype.hasOwnProperty.call(this.responses, this.qid)){
                    return null
                } 
                return this.responses[this.qid]
            },
            set(newValue){
                this.returnValue = newValue
            },
        },
        */
        questionOptions(){
            return JSON.parse(this.questions[this.qid].options);

        }
    

    },
    methods: {
        renderResponse(questionId, response){
            const type = this.questions[questionId].type
            var options=[]

            switch(type){
                case 'short': 
                    return response
                case 'select': 
                    if(response===null) return "---"
                    options = JSON.parse(this.questions[questionId].options)
                    return options[response].text
                case 'checkbox':
                    return response?"Yes":"No"
            } 

        },
        roletest() {
            if (this.value[0]==='' && this.value[1]===''){
                return ""
            } 
            return this.value[0]+this.value[1]
        },
        responsiveClass(className){
            if(this.$vuetify.breakpoint.smAndDown){
                return className+' mobile'
            }
            return className
        },
        questionNumber(id) {
            return `Q${this.questions[id].order+1}: `
        },
        applicableRules(question) {
            let response=""
            if (question.required)
                response="required"
            
            return response
        },
        renderQuestionLabel(questionId){
            const list = this.questions[questionId].question.split('*')
            var bold=false
            var output=''

            for(var i=list[0]===''?1:0;i<list.length;i++){
                if(list[i]==="" ){
                    bold=!bold
                    if(bold) output+='<span>' 
                        else output += '</span>'
                } else {
                    output+=list[i]
                }
            }
            return output
        }
    },
    watch: {
        returnValue(){
            if(this.returnValue !== this.originalValue) {
                this.$root.$emit('changed-question-response', {qid: this.qid, value: this.returnValue})
            }
        }
    },
    async created(){
        if(this.value!==null){
            if(this.questions[this.qid].type==='select') {
                this.returnValue = Number(this.value)
                this.originalValue = Number(this.value)
            } else {
                this.returnValue = this.value
                this.originalValue = this.value

            }
        }
        this.componentId=uuidv4()
    },
    data(){
        return {
            componentId: null,
            returnValue: null,
            originalValue: null,
        }
    }

}
</script>

<style scoped>
.question-heading {
    text-align: left;
    font-size:16px;
    transform: scale(0.75);
}

.question-response {
    font-size:16px;
    text-align: left;
}

.emphasis{
    color:blue;
    font-weight:600;
}

</style>

<style module>
#htmlContainer > span {
color:blue;
font-weight:600;
}

#htmlContainer > h2 {
    color:green;
    font-weight:600;
}
</style>