import { sendMessage} from '@/_helpers/service-tools'

export const criterionService = {
    findAllForEvent,
    addCriterion,
    updateCriterion,
    archiveCriterion,
    deleteCriterion,
}

async function findAllForEvent(payload) {
    return await sendMessage('GET','criterion/list', {eventId: payload.eventId}, null)
}

async function updateCriterion(criterion) {
    return await sendMessage('PATCH', 'criterion', {criterionId: criterion.id}, criterion)
}

async function addCriterion(criterion){
    return await sendMessage('POST', 'criterion', null, criterion)
}

async function deleteCriterion(payload){
    return await sendMessage('DELETE', 'criterion', {criterionId: payload.criterionId}, null)
}

async function archiveCriterion(criterionId){
    return await sendMessage('PATCH', 'criterion/archive', {criterionId} , null)
}