<template>
    <div :style="cssProps" class="feedback-wrapper">
        <div v-if="feedback[feedbackId].category" class="category">{{ criteria[feedback[feedbackId].criterionId].description }}({{ feedback[feedbackId].associatedScore }})  {{ feedback[feedbackId].category }}</div>
        <div v-else class="category">{{ criteria[feedback[feedbackId].criterionId].description }}({{ feedback[feedbackId].associatedScore }})  [New Feedback]</div>
        <div class="feedback-note">{{ feedback[feedbackId].text }}
            <div v-if="staffList.length>0" class="staff-list">
                <div class="staff-name" v-for="(item,n) in staffList" :key="n">{{ staff[item].firstName }} {{ staff[item].lastName  }},</div>
            </div>
        </div>
        <v-divider/>
    </div>
</template>

<script>
import { mapState } from 'vuex';


export default{
    props: {
        feedbackId: {
            default: ''
        },
        dense: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        staffList:{
            default(){ return []},
        },
    },
    components:{
    },
    computed: {
        ...mapState({
            feedback:           state => state.feedbackStore.data,
            criteria:           state => state.criterionStore.data,
            staff:              state => state.staffStore.data,
        }),
        cssProps() {
            return {
                '--wrapper-margins': this.dense?'0px':'10px',
                '--selected-background': this.selected?'orange':'white',
            }
        },
    },
    methods: {

    },
    watch: {},
    async created(){

    },
    data(){
        return {

        }
    }

}
</script>

<style scoped>
.feedback-wrapper{
    text-align: left;
    margin:var(--wrapper-margins);
}
.category{
    display:inline-block;
    padding-left:10px;
    padding-right: 10px;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    text-align: center;
    border-color: orange;
    margin-left: -15px;
    font-size:x-small;
    background:white;
}

.feedback-note{
    font-style: italic;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    padding: 10px 10px 0px 10px;
    border-color: orange;
    margin-top: -5px;
}

.staff-list{
    display: flex;
    justify-content: right;
    color: var(--cs2-lev1);
    font-size: x-small;
}
</style>