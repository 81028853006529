<template>
    <loading v-if="loading"/>
    <div v-else>
        <fresh-banking v-if="display===`noAccount` || display === 'onboarding'"/> 
        <account-active v-else-if="display==='active'"/>
    </div>

</template>

<script>
import { mapActions, mapState } from 'vuex';
import FreshBanking from '@/ClubPanes/ClubSettingsPanes/FreshBanking'
import AccountActive from '@/ClubPanes/ClubSettingsPanes/AccountActive'

//import Stripe from 'stripe'
import Loading from '@/components/loading'

export default {
    props: [],
    components: {
        FreshBanking,
        Loading,
        AccountActive,
    },
    computed: {
        ...mapState({
            acccount:       state => state.account,

            clubs:          state => state.clubStore.data,
            clubStatus:     state => state.clubStore.status,

            stripeStatus:   state => state.stripeStore.status,
            stripeData:     state => state.stripeStore.data,

            selectedClub:           state => state.navigatorStore.selectedClub,
            navigatorInitialising:  state => state.navigatorStore.initialising,
        }),
        loading(){
            return false 
                || this.navigatorInitialising
                || this.clubStatus.loading
        },
        display() {
            return this.clubs[this.selectedClub].stripeStatus
        }
    },
    methods: {
        ...mapActions('clubStore', {
            loadClubs: 'findAllForUser',
            updateClubProp: 'propertyUpdate',
            updateClub: 'update',
            loadClub: 'load',
        }),
        ...mapActions('stripeStore', {
            onboardSuccess: 'onboardSuccess',
        }),

    },
    mounted(){
    },
    async created(){
        this.stable=false
        var stripeStatus = this.clubs[this.selectedClub].stripeStatus

        if(stripeStatus === '') {
            this.updateClubProp({
                field: 'stripeStatus',
                id: this.selectedClub,
                value: 'noAccount',
            })
            stripeStatus='noAccount'
        }

        // If there is a bank setup in progress we get sent here so send the transaction status to the banking setup page if necessary...

        if(stripeStatus === 'active'){
            window.history.pushState('data','CoachSight',`${process.env.VUE_APP_VUE_LINK_PREFIX}/clubhouse/${this.selectedClub}`)
        } else if (stripeStatus==='onboarding'){
            window.history.pushState('data','CoachSight',`${process.env.VUE_APP_VUE_LINK_PREFIX}/clubhouse/${this.selectedClub}`)
            this.$toast.error(`There was a problem creating your account. , either because you are already connected (try logging out and then back in to CoachSight, or retry the process below.)`, {
                timeout: 10000,
            })
        }
        this.stable=true

    },
    data() {
        return {
            stable: false,
        }
    }
}
</script>

<style scoped>

</style>