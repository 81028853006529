<template>
        <div v-if="loading">
            <loading/>
        </div>
        <v-expansion-panel v-else>
            <v-expansion-panel-header disable-icon-rotate>
               <div :class="{strike: meta[questionId].delete}">
                    <span class="qnum">Q{{questions[questionId].order+1}}:</span> {{questions[questionId].question}}
                </div>
                <template v-slot:actions>
                    <v-btn @click.native.stop
                        v-if="false && changes"
                        x-small
                        v-on:click="saveDetails"
                        depressed
                        color="primary"
                        class="save-btn"
                    >
                        Save
                    </v-btn>
                    <v-dialog v-model="confirmDeleteDialog" max-width="60%">
                        <template v-slot:activator="{on, attrs}">
                            <v-icon small color="red" v-on="on" v-bind="attrs">mdi-delete</v-icon>
                        </template>
                        <v-card>
                            <v-card-title>Confirm Question Deletion</v-card-title>
                            <v-card-text>
                                You cannot undo this operation. All information for this question will be deleted permanently. Are you sure you want to continue?
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn text @click="confirmDeleteDialog=false">Close</v-btn>
                                <v-btn color="red darken-1" text @click="confirmedDelete">Delete</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <!-- 
                    <v-icon small v-if="flagToDelete" @click.stop="unflagForDeletion">mdi-delete-restore</v-icon>
                    <v-icon small v-else color="red" @click.stop="flagForDeletion">mdi-delete</v-icon>
                    -->
                    <v-icon>mdi-chevron-down </v-icon>
                </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-text-field 
                    v-model="question"
                    prepend-icon="mdi-chat-question"
                    label="Question"
                />
                <v-row>
                    <v-col cols="8">
                        <v-select :items="questionTypes" dense label="Question Type" v-model="questionType" :value="questionType" item-text="text" item-value="value"/>
                    </v-col>
                    <v-col>
                        <v-checkbox
                            v-model="required"
                            label="Requires an answer" 
                        />
                    </v-col>
                </v-row>
                <v-row v-if="questionType==='select'">
                    <v-col cols="12">
                        <BoundedBox >
                            <div slot="title">Selection Options</div>
                            <div slot="content">
                                <v-row class="no-boundaries">
                                    <v-col class="no-boundaries" cols="10" v-if="questionType==='select'">
                                        <v-text-field hide-details="true" label="New option" v-model="newOption"></v-text-field>
                                    </v-col>
                                    <v-col class="no-boundaries text-right" cols="2" v-if="questionType==='select'">
                                        <v-btn small  color="primary" @click="addOption">Add</v-btn>
                                    </v-col>
                                </v-row>
                                <v-row class="no-boundaires">
                                    <v-radio-group v-model="questionDefault" :value="questionDefault">
                                        <v-radio v-for="item,n in sortedOptions" :key="n" :label="item.text" :value="n">
                                            <div slot="label" style="display:flex;flex-direction:row">
                                                <div class="no-boundaries text-right">
                                                    <v-icon color="red" @click="deleteOption(n)">mdi-delete</v-icon>
                                                </div>
                                                <div class="no-boundaries">
                                                    {{  item.text }}
                                                </div>
                                            </div>
                                        </v-radio>
                                    </v-radio-group>
                                </v-row>
                            </div>
                        </BoundedBox>
                    </v-col>
                </v-row>
                <v-row v-if="questionType==='roles'">
                    <v-col cols="12">
                        <BoundedBox>
                            <span slot="title">Role Query</span>
                            <div slot="content">
                                This question will ask players to select 2 preferred roles from the list of roles you have defined for this tryout. 

                            </div>
                        </BoundedBox>
                    </v-col>
                                    <!--
                    <v-col cols="12">
                        <BoundedBox >
                            <div slot="title">Role Options</div>
                            <div slot="content">
                                <v-row class="no-boundaries">
                                    <v-col class="no-boundaries" cols="10" v-if="questionType==='select'">
                                        <v-text-field hide-details="true" label="New option" v-model="newOption"></v-text-field>
                                    </v-col>
                                    <v-col class="no-boundaries text-right" cols="2">
                                        <v-btn small  color="primary" @click="addOption">Add</v-btn>
                                    </v-col>
                                </v-row>
                                <v-row class="no-boundaires">
                                    <v-radio-group v-model="questionDefault" :value="questionDefault">
                                        <v-radio v-for="item,n in sortedOptions" :key="n" :label="item.text" :value="n">
                                            <div slot="label" style="display:flex;flex-direction:row">
                                                <div class="no-boundaries text-right">
                                                    <v-icon color="red" @click="deleteOption(n)">mdi-delete</v-icon>
                                                </div>
                                                <div class="no-boundaries">
                                                    {{  item.text }}
                                                </div>
                                            </div>
                                        </v-radio>
                                    </v-radio-group>
                                </v-row>
                            </div>
                        </BoundedBox>
                    </v-col>
                                -->
                </v-row>
                <v-row v-else-if="questionType==='checkbox'">
                    <v-checkbox label="question checked by default" v-model="questionDefault"/>
                </v-row>
            </v-expansion-panel-content>

        </v-expansion-panel>
</template>

<script>
import { mapState, mapActions} from 'vuex'
import Loading from "@/components/loading"
import BoundedBox from '@/components/BoundedBox'
import { v4 as uuidv4} from 'uuid'

export default {
    props: ['questionId'],
    components: {
        Loading,
        BoundedBox,
    },
    computed: {
        ...mapState({
            questions: state=> state.appquestionStore.data,
            meta: state => state.appquestionStore.meta,
            status: state => state.appquestionStore.status,
            list: state => state.appquestionStore.list
        }),
        loading: function() {
            return this.status.loading
        },
        question: {
            get() {
                return this.questions[this.questionId].question
            },
            async set(value) {
      //          this.$root.$emit("settings-question-changes-made", this.componentId)
                this.$root.$emit("settings-changes-made", this.componentId)
                this.changes=true
                await this.update({field: 'question', id: this.questionId, value})
                await this.updateQuestion(this.questions[this.questionId])

            }

        },
        questionType: {
            get() {
                return this.questions[this.questionId].type
            },
            async set(newValue){
                this.$root.$emit("settings-changes-made", this.componentId)
                this.changes=true

                await this.update({field: 'type', id: this.questionId, value: newValue})
                await this.updateQuestion(this.questions[this.questionId])
            }
        },
        questionDefault: {
            get(){
                return parseInt(this.questions[this.questionId].default)
            },
            async set(newValue){
                var updateValue=""
                if(this.questionType==='checkbox'){
                    if(newValue){
                        updateValue="1"
                    } else {
                        updateValue="0"
                    }
                } else {
                    updateValue = `${newValue}`
                }
                await this.update({field: 'default', id: this.questionId, value: updateValue})
                await this.updateQuestion(this.questions[this.questionId])
                this.$root.$emit("settings-changes-made", this.componentId)
                this.changes=true
            }
        },
        questionOptions: {
            get(){
                return JSON.parse(this.questions[this.questionId].options)
            },
            async set(newValue){
                this.$root.$emit("settings-changes-made", this.componentId)
                this.changes=true
                await this.update({field: 'options', id: this.questionId, value: JSON.stringify(newValue)})
                await this.updateQuestion(this.questions[this.questionId])
            }
        },
        flagToDelete: {
            get() {
                console.log(this.meta)
                return this.meta[this.questionId].delete
            },
            set(value) {
       //         this.$root.$emit("settings-question-changes-made", this.componentId)
                this.$root.$emit("settings-changes-made", this.componentId)
                this.changes=true
                return this.update({field: 'delete', id: this.questionId, value})
            }
        },
        required: {
            get() {
                return this.questions[this.questionId].required
            },
            async set(value) {
                this.changes=true
                this.$root.$emit("settings-changes-made", this.componentId)
        //        this.$root.$emit("settings-question-changes-made", this.componentId)
                await this.update({field: 'required', id: this.questionId, value})
                await this.updateQuestion(this.questions[this.questionId])
            }
        },
        sortedOptions(){
            return this.questionOptions.slice().sort(this.sortOptionsByOrder);
        },

    },
    methods: {
        ...mapActions('appquestionStore', {
            update: 'update',
            updateQuestion: 'updateQuestion',
            deleteQuestion: 'delete'
        }),
        flagForDeletion: function() {
            this.flagToDelete=true
        },
        unflagForDeletion: function() {
            this.flagToDelete=false
        },
        saveDetails(){
            this.updateQuestion(this.questions[this.questionId])
            this.$root.$emit("settings-question-changes-cleared", this.componentId)
            this.$root.$emit("settings-changes-cleared", this.componentId)
            this.changes=false
        },
        confirmedDelete(){
            console.log(1)
            this.deleteQuestion(this.questionId)
            console.log(2)
            this.confirmDeleteDialog=false
            console.log(3)
            this.$store.dispatch('alert/success', 'Question deleted', {root:true})
            console.log(4)

        },
        addOption(){
            var options = this.questionOptions
            options.push({text:this.newOption, value:this.questionOptions.length})
            this.questionOptions = options
            this.newOption=""
        },
        deleteOption(n){
            var options = this.questionOptions
            options.splice(n,1)
            this.questionDefault=0
            for(var idx in options){
                options[idx].value=idx
            }
            this.questionOptions = options

        },
        sortOptionsByOrder(a,b){
            return a.value - b.value
        }
    },
    mounted(){
        this.$root.$on('settings-clear-question-changes-flags', ()=> {
            var self=this
            self.changes=false
        })
        this.$root.$on('submit-all-changes', () => {
            var self=this
            if(self.changes){
                self.saveDetails()
                self.changes=false
            }
        })
    },
    created(){
        this.componentId=uuidv4()
    },
    data() {
        return {
            confirmDeleteDialog:false,
            componentId: 0,
            types:['Short Answer'],
            changes: false,
            questionTypes: [
                {text: 'Short Answer', value: 'short'},
                {text: 'Long Answer', value: 'long'},
                {text: 'Selection', value: 'select'},
                {text: 'Checkbox', value: 'checkbox'},
                {text: 'Role', value: 'role' },
            ],
           defaultCheck: false,
           defaultSelect: 0, 
           newOption: ''
        }
    }
}

</script>

<style>
.strike {
    color: red;
    text-decoration: line-through;
}
</style>