<template>
    <div >
        <v-dialog v-model="showFeedbackDialog" width="70%">
            <v-card>
                <v-card-title>Feedback Comments</v-card-title>
                <v-card-text>
                    <v-select :key="updateKey" v-model="selectedFeedback" multiple dense :items="relevantFeedbackList" @change="feedbackChange">
                        <template v-slot:item="{item}">
                             <FeedbackListLine :feedbackId="item" dense/>
                        </template>
                        <template v-slot:selection="{item}">
                             <FeedbackListLine :feedbackId="item" selected/>
                        </template>
                    </v-select>
                    <v-text-field label="New feedback" v-model="newFeedback">
                        <template v-slot:append>
                            <v-btn icon @click="addFeedback">
                                <v-icon>mdi-content-save-plus-outline</v-icon>
                            </v-btn>
                        </template>
                    </v-text-field>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-row>
            <v-col cols="2" class="no-boundaries" style="margin-top:0">
                <v-progress-circular v-if="episodeMode" :indeterminate="criterionLoading" :color="episodeCompletionColor" :value="scored" rotate="-90" >{{average}}</v-progress-circular>
                <v-progress-circular v-else :indeterminate="criterionLoading" :color="completionColor" :value="complete" rotate="-90" >{{average}}</v-progress-circular>
            </v-col>
            <v-col cols="10" class=" no-boundaries text-left" style="margin-bottom:10px;">
                <span class="skill-label" >{{criteria[criterionId].description}}</span>
                <v-icon style="margin-bottom:5px;" :color="feedbackColor" @click="showFeedbackDialog=score===0?false:true">{{ feedbackIcon }}</v-icon>
            </v-col>
            <v-col>
                <v-slider 
                    v-model="score"
                    ticks="always"
                    tick-size="3"
                    step=1
                    max=5
                    min=0
                    horizontal
                    color="var(--cs1-lev1)"
                    track-color="white"
                    :thumb-size="30"
                    thumb-label="always"
                    hide-details="true"
                    dense
                >
                    <template v-slot:thumb-label="{value}">
                        <v-icon :color="iconColor[Math.min(Math.floor(value),5)]">
                            {{icons[Math.min(Math.floor(value),5)]}} 
                        </v-icon>
                    </template>
                </v-slider>

            </v-col>
        </v-row>
        <div>
        </div>
    </div>
</template>

<script>
import {mapState , mapActions} from 'vuex'
import { v4 as uuidv4} from 'uuid'

import FeedbackListLine from './feedbackListLine.vue'

export default {
    props: {
        criterionId: {
            type: String,
            default: '',
        },
        participantId: {
            type: String,
            default: '',
        },
        episodeMode: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        FeedbackListLine
    },
    computed: {
        ...mapState({
            playerskill: state => state.playerskillStore.data,
            playerskillStatus: state => state.playerskillStore.status,
            criteria: state => state.criterionStore.data,
            criterionStatus: state => state.criterionStore.status,
            account: state => state.account,
            _selectedTryout: state => state.navigatorStore.selectedTryout,
            _selectedEpisode: state => state.navigatorStore.selectedEpisode,
            feedback:                           state => state.feedbackStore.data,
            feedbackByCriterionAndScore:        state => state.feedbackStore.listByCriterionAndScore,
            feedbackLinksByPlayerAndStaffAndCriterion: state => state.feedbackLinkStore.listByPlayerAndStaffAndCriterion,

        }),
        loading: function() {
            return this.playerskillStatus.loading
        },
        relevantFeedbackList(){
            if(this.feedbackByCriterionAndScore[this.criterionId])
                return this.feedbackByCriterionAndScore[this.criterionId][this.score]
            return []
        },
        selectedEvent(){
            if(this.episodeMode) return this._selectedEpisode
            return this._selectedTryout
        },

        score: {
            get() {
                if(this.participantId && this.participantId !== '0' && this.participantId !== 0 && this.playerskill[this.participantId]){
                    const score = this.playerskill[this.participantId].criteria[this.criterionId].score
                    return score
                } else {
                    return 0
                }
            },
            //eslint-disable-next-line
            set(value) {
                if(this.participantId && this.participantId !== 0 && this.participantId !== '0') {
                    this.updateScore({
                        participantId: this.participantId,
                        criterionId: this.criterionId ,
                        userId: this.account.user.details.id,
                        eventId: this.selectedEvent,
                        score: value
                    })
                }
            }
        },
        description: {
            get() {
                if(this.criterionId && this.criterionId !== 0) {
                    return this.criteria[this.criterionId].decription
                } else {
                    return ""
                }
            }
        },
        average: {
            get() {
                if(this.participantId && this.participantId !== 0 && this.participantId !== '0' && this.playerskill[this.participantId] && this.criterionId !==0 && this.playerskill[this.participantId].criteria[this.criterionId].average !== null) {
                    return this.playerskill[this.participantId].criteria[this.criterionId].average.toFixed(1)
                } else {
                    return 0
                }
            }
        },
        complete: {
            get() {
                if(this.participantId && this.participantId !== '0' && this.participantId !== 0 && this.criterionId !==0 && this.playerskill[this.participantId]) {
                    return this.playerskill[this.participantId].criteria[this.criterionId].complete
                } else {
                    return 0
                }
            }
        },
        scored(){
            return 100
        },
        episodeCompletionColor(){
            if(this.score === 0) return "red"
            return this.progressColor[4]
        },
        completionColor: function() {
            const c = Math.floor(this.complete / 25)
            return this.progressColor[c] 
        },
        criterionLoading: function () {
            if (this.criterionId && this.criterionId !== 0 && this.criterionStatus[this.criterionId]){
                return (this.criterionStatus[this.criterionId].loading )
            } else {
                return false
            }
        },
        feedbackColor(){
            switch(this.score){
                case 0: return 'white'
                case 1:
                    if(this.relevantSelected.length===0) return 'red'
                    return 'green'
                case 2:
                    if(this.relevantSelected.length===0) return 'red'
                    return 'green'
                case 4:
                    if(this.relevantSelected.length>0) return 'green'
                    break
                case 5:
                    if(this.relevantSelected.length>0) return 'green'
                    break
            }
            return 'gray'
            
        },
        relevantSelected(){
            return this.relevantFeedbackList.filter(value => this.selectedFeedback.includes(value));
        },
        feedbackIcon(){
            switch(this.score){
                case 1:
                    //if(this.relevantSelected.length>0) return 'mdi-chat' 
                    return 'mdi-chat'
                case 2:
                    //if(this.relevantSelected.length>0) return 'mdi-chat' 
                    return 'mdi-chat'
                case 4:
                    if(this.relevantSelected.length>0) return 'mdi-chat' 
                    break;
                case 5:
                    if(this.relevantSelected.length>0) return 'mdi-chat' 
                    break;
            }
            return "mdi-chat-outline"
        },
        selectedFeedback: {
            get(){
                if(this.updateKey==='') return []
                if(!this.feedbackLinksByPlayerAndStaffAndCriterion[this.participantId]
                 || !this.feedbackLinksByPlayerAndStaffAndCriterion[this.participantId][this.account.user.details.id]
                 || !this.feedbackLinksByPlayerAndStaffAndCriterion[this.participantId][this.account.user.details.id][this.criterionId]){
                    return []
                 }

                return this.feedbackLinksByPlayerAndStaffAndCriterion[this.participantId][this.account.user.details.id][this.criterionId]
            },
            set(){

            }
        }

    },
    methods: {
        ...mapActions('playerskillStore', {
            updateScore: 'updateScore'
        }),
        ...mapActions('feedbackStore', [
           'createFeedback' ,
        ]),
        ...mapActions('feedbackLinkStore',[
            'addFeedbackLink',
            'deleteFeedbackLink',
        ]),
        async addFeedback(){
            const fb = await this.createFeedback({
                eventId: this.selectedEvent,
                criterionId: this.criterionId,
                associatedScore: this.score,
                text: this.newFeedback
            })
            // clear the field
            this.newFeedback=null
            await this.addFeedbackLink({
                eventId: this.selectedEvent,
                participantId: this.participantId,
                criterionId: this.criterionId,
                feedbackId: fb.data.result.feedback.id,
                staffId: this.account.user.details.id
            })
//            this.selectedFeedback.push(fb.data.result.feedback.id)
            this.updateKey=uuidv4()
        },
        async feedbackChange(e){
            // Need to remove any feedback items that aren't relevent to the current score...
            //const filteredState =  this.relevantFeedbackList.filter(value => e.includes(value));

            const added = this.arrayDiff(e, this.currentState)
            const removed = this.arrayDiff(this.currentState, e)
            for(var idx in added){
                await this.addFeedbackLink({
                    eventId: this.selectedEvent,
                    participantId: this.participantId, 
                    criterionId: this.criterionId,
                    feedbackId: added[idx], 
                    staffId: this.account.user.details.id
                })
            }
            for(var idx2 in removed){
                await this.deleteFeedbackLink({
                    eventId: this.selectedEvent,
                    criterionId: this.criterionId,
                    participantId: this.participantId, 
                    feedbackId: removed[idx2], 
                    staffId: this.account.user.details.id
                })
            }
            this.updateKey=uuidv4()
            this.currentState=e
        },
        arrayDiff(a, b) {
            let difference = [];
            for (let i = 0; i < a.length; i++) {
                if (b.indexOf(a[i]) === -1) {
                    difference.push(a[i]);
                }
            }
            return difference;
        }
    },
    async created(){
        if(this.feedbackLinksByPlayerAndStaffAndCriterion[this.participantId] && 
           this.feedbackLinksByPlayerAndStaffAndCriterion[this.participantId][this.account.user.details.id] &&
           this.feedbackLinksByPlayerAndStaffAndCriterion[this.participantId][this.account.user.details.id][this.criterionId]){
                this.currentState = this.feedbackLinksByPlayerAndStaffAndCriterion[this.participantId][this.account.user.details.id][this.criterionId]
           }
    },
    data() {
        return {
            currentState: [],
            updateKey:'--',
            enableSaveFeedback: false,
            newFeedback:'',
            showFeedbackDialog: false,
            icons: [
                "mdi-numeric-0-circle", 
                "mdi-numeric-1-circle", 
                "mdi-numeric-2-circle", 
                "mdi-numeric-3-circle", 
                "mdi-numeric-4-circle", 
                "mdi-numeric-5-circle"
                ],
            iconColor: [
                "gray",
                "red",
                "orange",
                "yellow",
                "lime",
                "light-green accent-3"
            ],
            progressColor : [
                "red",
                "red",
                "orange",
                "light-green accent-3",
                "light-green accent-4"
            ]
        }
    },
    watch: {
    }

}

</script>

<style scoped >

.col {
    margin-top: auto
}

.v-progress-circular__info{
    color: black;
    font-weight: 600;
    font-size: small;
}
.v-slider__thumb-label{
    transform: translateY(20%) translateY(9px) translateX(-50%) rotate(45deg)!important;
}


</style>