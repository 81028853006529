<template>
  <v-app>

    <div id="app">
      <v-snackbar 
        elevation="24" 
        v-model="alert.type" 
        :timeout="alert.timeout"
        :color="alert.color"
        top
        >
          {{alert.message}}
      </v-snackbar>
      <template >
        <div v-if="!wholePage" class="topline" >
        <top-nav class="coachsight-header" />
        </div>
        <template >
            <div class="main-frame">
                <div style="margin:0px;padding:0px;width:100%">
                    <div >
                        <router-view></router-view>
                    </div>

                </div>
            </div>
        </template>
      </template>

    </div>
  </v-app>
</template>

<script>

import { mapState, mapActions} from 'vuex'
import TopNav from '@/components/Header'


export default {
  name: 'App',
  components: {
    TopNav,
  },
  data() {
      return {
        menu: false
      }
  },
  computed: {
    ...mapState({
      account: state => state.account,
      alert: state => state.alert
    }),
    coach: function() {
      return this.account.user.details.role === "coach"
    },
    wholePage(){
      const path=JSON.parse(sessionStorage.getItem('pathElements'))[0]
      if(path==='sessionPoster') return true
      return false
    }
  },
  methods: {
    ...mapActions({
      clearAlert: 'alert/clear'
    }),
    toggleMenu: function() {
      this.menu = !this.menu
    },
  },
  watch: {
    // eslint-disable-next-line
    $route(to, from) {
      this.clearAlert();
    }

  },
  created(){
  }
}
</script>

<style>

:root {
  --cs1-lev1: #9145B6;
  --cs1-lev2: #BA43B6;
  --cs1-lev3: #FAA6FF;
  --cs2-lev1: #2196F3;
  --cs2-lev2: #55DDE0;
  --cs2-lev3: #A5E6BA;
  --cs2-lev4: #E6FFEE;
  --cs2-lev5: #f8e5c8;
  --error-red: red;

}
.v-application{
  padding: 0px;
}

.navigator {

}

.content-frame {
    display: flex;

}
.main-frame {
  width: 100%;
}

.login-container {
  display: flex;
  justify-content: center;
  width: 100%;
}


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 00px;
}

.burger{
  display: flex;
  justify-content: left
}

.form-error {
  color: var(--error-red);
  text-align: left;
}
.root-chev {
    cursor: pointer;
    position: relative;
    display: block;
    /*width: 150px;*/
    height: 32px;
    background: var(--cs2-lev1);
    font: 22px Constantia, sans-serif;
    color: white;
    line-height: 32px;
    text-align: center;
    text-decoration: none;
    padding-left:10px;
    padding-right:10px;
    margin-left:0px;
    z-index: 30;
    font-size: small;
    font-weight:600;
}

.root-chev:before,
.root-chev:after {
    content: '';
    position: absolute;
    right: -11px;
    width: 20px;
    height: 8px;
    border-radius: 2px;
    background: var(--cs2-lev1);;
}
.root-chev:before {
    top: 17px;
    -webkit-transform: rotate(131deg);
    -ms-transform: rotate(131deg);
    transform: rotate(131deg);
}
.root-chev:after {
    bottom: 17px;
    -webkit-transform: rotate(237deg);
    -ms-transform: rotate(237deg);
    transform: rotate(237deg);
}

.first-chev {
    cursor: pointer;
    position: relative;
    display: block;
    height: 32px;
    background: var(--cs2-lev2);
    font: 22px Constantia, sans-serif;
    color: white;
    line-height: 32px;
    text-align: center;
    text-decoration: none;
    padding-left:20px;
    padding-right:20px;
    margin-left:0px;
    z-index: 25;
    font-size:small;
    font-weight: 600;
}

.tryout-name {
    cursor: pointer;
    position: relative;
    display: block;
    height: 32px;
    font-family: Constantia, sans-serif;
    font-size:medium;
    color: white;
    line-height: 32px;
    text-align: center;
    text-decoration: none;
    padding-left:20px;
    padding-right:20px;
    margin-left:0px;
    z-index: 25;
    font-weight: 600;

}

.chev-text{
  vertical-align:middle;
  font-family: Varela round;
  font-size: medium;
  position: relative;
  margin-top:auto;
  margin-bottom:auto;
    /*
    -webkit-transform: translateY(-25%);
    -ms-transform: translateY(-25%);
    transform: translateY(-25%);
    */
}
.chev-text.small{
  font-family: Varela round;
  font-size: x-small;
}


.first-chev:before,
.first-chev:after {
    content: '';
    position: absolute;
    right: -11px;
    width: 20px;
    height: 8px;
    border-radius: 2px;
    background: var(--cs2-lev2);;
}
.first-chev:before {
    top: 17px;
    -webkit-transform: rotate(131deg);
    -ms-transform: rotate(131deg);
    transform: rotate(131deg);
}
.first-chev:after {
    bottom: 17px;
    -webkit-transform: rotate(237deg);
    -ms-transform: rotate(237deg);
    transform: rotate(237deg);

}


.second-chev {
    cursor: pointer;
    position: relative;
    display: block;
    height: 32px;
    background: var(--cs2-lev3);
    font: 22px Constantia, sans-serif;
    color: white;
    line-height: 32px;
    text-align: center;
    text-decoration: none;
    padding-left:20px;
    padding-right:20px;
    margin-left:0px;
    z-index: 20;
    font-size: small;
    font-weight:600;
}

.second-chev:before,
.second-chev:after {
    content: '';
    position: absolute;
    right: -11px;
    width: 20px;
    height: 8px;
    border-radius: 2px;
    background: var(--cs2-lev3);;
}
.second-chev:before {
    top: 17px;
    -webkit-transform: rotate(131deg);
    -ms-transform: rotate(131deg);
    transform: rotate(131deg);
}
.second-chev:after {
    bottom: 17px;
    -webkit-transform: rotate(237deg);
    -ms-transform: rotate(237deg);
    transform: rotate(237deg);
}

.third-chev {
    cursor: pointer;
    position: relative;
    display: block;
    height: 32px;
    background: var(--cs2-lev4);
    font-family: Constantia, sans-serif;
    font-size: small;
    font-weight:600;
    color: var(--cs2-lev1);
    line-height: 32px;
    text-align: center;
    text-decoration: none;
    padding-left:20px;
    padding-right:20px;
    margin-left:0px;
    z-index: 15;
}

.third-chev:before,
.third-chev:after {
    content: '';
    position: absolute;
    right: -11px;
    width: 20px;
    height: 8px;
    border-radius: 2px;
    background: var(--cs2-lev4);;
}
.third-chev:before {
    top: 17px;
    -webkit-transform: rotate(131deg);
    -ms-transform: rotate(131deg);
    transform: rotate(131deg);
}
.third-chev:after {
    bottom: 17px;
    -webkit-transform: rotate(237deg);
    -ms-transform: rotate(237deg);
    transform: rotate(237deg);
}

.fourth-chev {
    cursor: pointer;
    position: relative;
    display: block;
    height: 32px;
    background: var(--cs2-lev5);
    font: 22px Constantia, sans-serif;
    color: var(--cs2-lev1);
    line-height: 32px;
    text-align: center;
    text-decoration: none;
    padding-left:20px;
    padding-right:20px;
    margin-left:0px;
    z-index: 10;
    font-size: small;
    font-weight:600;
}

.fourth-chev:before,
.fourth-chev:after {
    content: '';
    position: absolute;
    right: -11px;
    width: 20px;
    height: 8px;
    border-radius: 2px;
    background: var(--cs2-lev5);;
}
.fourth-chev:before {
    top: 17px;
    -webkit-transform: rotate(131deg);
    -ms-transform: rotate(131deg);
    transform: rotate(131deg);
}
.fourth-chev:after {
    bottom: 17px;
    -webkit-transform: rotate(237deg);
    -ms-transform: rotate(237deg);
    transform: rotate(237deg);
}
</style>

:was