import { staffService } from '../_services'
import Vue from 'vue'
//import { router } from '../_helpers'

const dataService = staffService

function initialiseState(){
    return {
        status: {},
        data: {},
        list: [],
        error: null,
        cache: {},
        meta: {},
        internal: {},
        supportList: [],
        selectorList: [],
        adminList: [],
        seasonList: [],
    }
}

const state = initialiseState()

const actions = {
    purgeData({commit}){
        commit('purgeData')
    },
    async findStaffForSeason({commit}, payload){
        try {
            commit('loadSeasonRequest')
            const response = await dataService.findAllForSeason(payload)
            commit('loadSeasonSuccess', response)
            return response
        } catch (error) {
            commit('loadSeasonFailure', error)
        }
    },
    async findAllStaffForEvent({commit},payload) {
        try{
            const {eventId} = payload
            commit('loadRequest')

            const response = await dataService.findAllForEvent( eventId)
            commit('loadSuccess', response)
            return response
        } catch(error) {
            commit('loadFailure', error)
        }
    },
    async findAllForEvent({commit},payload) {
        try{
            const {eventId} = payload
            commit('loadRequest')

            const response = await dataService.findAllForEvent( eventId)
            commit('loadSuccess', response)
            return response
        } catch(error) {
            commit('loadFailure', error)
        }
    },
    async quietFindAllForEvent({commit},payload) {
        try{
            const {eventId} = payload
            commit('quietLoadRequest')

            const response = await dataService.findAllForEvent( eventId)
            commit('loadSuccess', response)
            return response
        } catch(error) {
            commit('loadFailure', error)
        }
    },
    async quietFindAllForEventAndStaff({commit},payload) {
        try{
            const {eventId} = payload
            commit('quietLoadRequest')

            const response = await dataService.findAllForEvent( eventId)
            commit('loadSuccess', response)
            return response
        } catch(error) {
            commit('loadFailure', error)
        }
    },
    async add({commit}, payload) {
        try{
            //eslint-disable-next-line
            const {eventId, role, userId} = payload
                // If the user is "BRAND" new, don't create it yet (because the userID won't exist yet...)
                // Instead just lodge it in the store and allow it to be updated.

            commit('createSuccess', {
                    id: 0, 
                    ...payload,
                    firstName: '',
                    lastName: '',
                    deleted: false,
                })
        } catch(error) {
            console.error('Add staff failed')
        }
    },
    async create({commit }, payload){
        try{
            commit('createRequest', payload)
            const response = await dataService.create( payload)
            commit('createSuccess', response)
            return response
        } catch(error) {
            commit('createFailure', error)
            console.log('Error here')
            console.log(error)
        }
    },
    async delete({commit, dispatch}, payload) {
        try{
            commit('deleteRequest', payload)
            const response = await dataService.del( payload)
            commit('deleteSuccess', response)
            return response
        } catch(error) {
            commit('deleteFailure', error)
            dispatch('alert/error', error, {root: true})
        }
    },
    async undelete({commit, dispatch}, payload) {
        try{
            commit('undeleteRequest', payload)

            const response = await dataService.undelete(payload)
            commit('undeleteSuccess', response)
            return response
        } catch(error) {
            commit('undeleteFailure', error)
            dispatch('alert/error', error, {root: true})
        }
    },
    async endowRole({commit}, payload){
        try{
            commit('endowRoleRequest', payload)

            const response = await dataService.endowRole(payload)
            commit('endowRoleSuccess', response)
            return response
        } catch(error) {
            commit('endowRoleFailure', error)
        }
    },
    async revokeRole({commit}, payload){
        try{
            commit('revokeRoleRequest', payload)
            const response = await dataService.revokeRole(payload)
            commit('revokeRoleSuccess', response)
            return response
        } catch(error) {
            commit('revokeRoleFailure', error)
        }
    },

    propertyUpdate({commit},payload) {
        commit('propertyUpdate', payload)
    }
}
function addToLists(data, season=false) {
    const id = data.id
    
    if(!season) {
        state.list.push(id)
        if(data.selector) state.selectorList.push(id)
        if(data.support) state.supportList.push(id)
        if(data.admin) state.adminList.push(id)
    } else {
        state.seasonList.push(id) 
    }

    Vue.set(state.data, id, data)
    Vue.set(state.meta, id, {
            delete : false,
    })
        
}

async function sortListByOrder() {
        await state.list.sort((a,b) => {
            /*
            console.log('===')
            console.log(`${a} : `)
            console.log(state.data[a])
            console.log(`${b} :`)
            console.log(state.data[b])
            */
            return (''+state.data[a].lastName).localeCompare(state.data[b].lastName)
        })
}

const mutations = {
purgeData(state){
    const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }
    },

        // -------------- EndowRole -------------\

        endowRoleRequest(state, data) {
            state.internal.data = data
            state.error=null
        },
    
        endowRoleSuccess(state) {
            state.error=null
            switch (state.internal.data.role){
                case 'support':{
                        state.supportList.push(state.internal.data.userId)
                    }
                    break
                case 'selector':{
                        state.selectorList.push(state.internal.data.userId)
                    }
                    break
                case 'admin':{
                        state.adminList.push(state.internal.data.userId)
                    }
                    break
            }
            
        },
    
        endowRoleFailure(state, error){
            state.error=error
        },
    // -------------- RevokeRole -------------\

    revokeRoleRequest(state, data) {
        state.internal.data = data
        state.error=null
    },

    revokeRoleSuccess(state) {
        state.error=null
        switch (state.internal.data.role){
            case 'support':{
                    const idx = state.supportList.indexOf(state.internal.data.userId)
                    state.supportList.splice(idx,1)
                }
                break
            case 'selector':{
                    const idx = state.selectorList.indexOf(state.internal.data.userId)
                    state.selectorList.splice(idx,1)
                }
                break
            case 'admin':{
                    const idx = state.adminList.indexOf(state.internal.data.userId)
                    state.adminList.splice(idx,1)
                }
                break

        }
        
    },

    revokeRoleFailure(state, error){
        state.error=error
    },

//--------------------------------------------
//   Mutations for loadSeason
//

loadSeasonRequest(state){
    Vue.set(state.status, 'loading', true)
    state.error=null
},
loadSeasonSuccess(state, response){
    state.status = {loading: true, responseCode: response.data.code}
    state.data={} // If the current event staff are loaded, they will get loaded again here anyway...
    state.seasonList=[] // Maintain a seperate list for season staff to event lists don't get confused.

    const data = response.data.result.staffList

    for(var idx in data){
        addToLists(data[idx], true)
    }

    Vue.delete(state.status,'loading')
},
loadSeasonFailure(state, error){
    state.error=error
},
     // ------------- loadTryout ------------- \
    
     loadRequest(state) {
        state.status={loading: true}
        state.error=null
    },
    quietLoadRequest(state) {
        state.status={}
        state.error=null
    },
    loadSuccess(state, data) {
        state.status={}
        state.data={}
        state.meta={}
        state.list=[]
        state.supportList=[]
        state.selectorList=[]
        state.adminList=[]
        for (var idx in data) {
            addToLists(data[idx])
        }
        sortListByOrder()
    },
    loadFailure(state, error) {
        state.status={}
        state.error = error
    },
    //------ CREATE -------\
    createRequest(state, payload) {
        state.internal={loading: true,payload}
        state.error=null
    },
    //eslint-disable-next-line
    createSuccess(state, data){
        state.list.push(state.internal.payload.userId)

        sortListByOrder()

        state.error=null
        state.internal={updated: true}
    },
    createFailure(state, error) {
        state.internal={}
        state.error=error
    },
    //------ UPDATE -------\
    updateRequest(state) {
        state.internal={updating: true}
        state.error=null
    },
    //eslint-disable-next-line
    updateSuccess(state, data){
        state.internal={updated: true}
        state.error=null
    },
    updateFailure(state, error) {
        state.internal={}
        state.error=error
    },
    //------ Delete -------\
    deleteRequest(state, id) {
        state.internal={deleting: true, target: id}
        state.error=null
    },
    //eslint-disable-next-line
    deleteSuccess(state, data){
        state.data[state.internal.target.userId]["deleted"]=true
        Vue.delete(state.data,state.internal.target.userId)
        Vue.delete(state.meta, state.internal.target.userId)
        state.list.splice(state.list.indexOf(state.internal.target.userId,1))
        state.adminList.splice(state.list.indexOf(state.internal.target.userId,1))
        state.supportList.splice(state.list.indexOf(state.internal.target.userId,1))
        state.selectorList.splice(state.list.indexOf(state.internal.target.userId,1))
        state.internal={deleted: true}
        state.error=null
    },
    deleteFailure(state, error) {
        state.internal={}
        state.error=error
    },
    //------ UnDelete -------\
    undeleteRequest(state, id) {
        state.internal={deleting: false, target: id}
        state.error=null
    },
    //eslint-disable-next-line
    undeleteSuccess(state, data){
        state.data[state.internal.target.userId]["deleted"]=false
        state.internal={deleted: false}
        state.error=null
    },
    undeleteFailure(state, error) {
        state.internal={}
        state.error=error
    },

    //------------ update data -------- \\
    propertyUpdate(state, payload) {
        const metaFields = "delete"
        const {field, id, value} = payload

        if(metaFields.includes(field)){
            state.meta[id][field]=value
            // Manage special fields...
            //switch (field) {
            //}
        } else {
            state.data[id][field]=value
        }
    }

}
export const staffStore = {
    namespaced: true,
    state,
    actions,
    mutations
}