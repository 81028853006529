import { playerskillService } from '../_services'
import Vue from 'vue'
import { router } from '../_helpers'

const dataService = playerskillService

function initialiseState(){
    return {
        status: {},
        list: [],
        data: {},
        error: null,
        cache: {},
        allStaffData: {},
        histogram: {},
        timeSeries: [],
    }
}

const state = initialiseState()

const actions = {
    purgeData({commit}){
        commit('purgeData')
    },
    "SOCKET_SKILLCHANGE"(){
       // console.info('Saw a skill change!')
    },
    async loadParticipantStats({commit},payload) {
        try{
            commit('loadParticipantStatsRequest')

            const response = await dataService.loadParticipantStats(payload)
            commit('loadParticipantStatsSuccess', response)
            return response
        } catch(error) {
            commit('loadParticipantStatsFailure', error)
        }
    },    
    async findAllForEventAndStaff({commit},payload) {
        try{
            const {eventId, userId} = payload
            commit('loadRequest')

            const response = await dataService.findAllForEventAndStaff( eventId, userId)
            if(response.length === 0) router.push("/")
            commit('loadSuccess', response)
            return response
        } catch(error) {
            commit('loadFailure', error)
        }
    },    
    async loadAllSkillScoresForParticipant({commit},payload) {
        try{
            commit('loadRequest')

            const response = await dataService.loadAllSkillScoresForParticipant( payload )
            commit('loadSuccess', response.data.result)
            return response.data.result

        } catch(error) {
            commit('loadFailure', error)
        }
    },
    async findAllForEvent({commit}, payload) {
        try{
            const {eventId} = payload
            commit('loadAllRequest')

            const response = await dataService.findAllForEvent(eventId)
            commit('loadAllSuccess', response)
        } catch(error) {
            commit('loadAllFailure', error)
        }
    },
    async clearAllStaffData({commit}) {
        commit('clearAllStaffData')
    },
    async quietFindAllForEventAndStaff({commit},payload) {
        try{
            const {eventId, userId} = payload
            commit('quietLoadRequest')
            const response = await dataService.findAllForEventAndStaff( eventId, userId)
            if(response.length === 0) router.push("/")
            commit('loadSuccess', response)
        } catch(error) {
            commit('loadFailure', error)
        }
    },
    async updateLocalScore({commit}, payload){
        commit('updateLocalScoreRequest', payload)
    },
    async updateScore({commit}, payload) {
        try{
            const {participantId, criterionId, userId, eventId, score } = payload
            commit('updateScoreRequest', payload)
            const response = await dataService.updateScore(participantId, criterionId, userId, eventId, score)
            commit('updateScoreSuccess', response)
        } catch(error) {
            commit('updateScoreFailure', error)
        }
    },
    propertyUpdate({commit},payload) {
        commit('propertyUpdate', payload)
    }
}
/*
function addToLists(data) {
    const id = data.id

    state.list.push(id)
    Vue.set(state.data, id, data)
    Vue.set(state.meta, id, {
            delete : false,
    })
}

function sortListByOrder() {
        state.list.sort((a,b) => {
            return state.data[a].participantNumber - state.data[b].participantNumber
        })
}
*/

const mutations = {
purgeData(state){
    const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }
    },

    
    //--------------------------------------------
    //   Mutations for loadParticipantStats
    //
    
    loadParticipantStatsRequest(state){
        Vue.set(state.status, 'loading', true)
        state.error=null
    },
    loadParticipantStatsSuccess(state, response){
        state.status = {loading: true, responseCode: response.data.code}
        state.data={}
        state.list=[]
    
        Vue.set(state, 'timeSeries' , response.data.result.timeSeries)
        Vue.set(state, 'histogram', response.data.result.histogram)

        Vue.delete(state.status,'loading')
    },
    loadParticipantStatsFailure(state, error){
        state.error=error
    },
   
    //eslint-disable-next-line
    loadAllRequest(state) {
        state.status={loading: true}

    },
    loadAllSuccess(state, payload) {
        // Build up the response
        state.allStaffData = {}

        for (var idx in payload) {
            const participantId=payload[idx].participantId
            const criterionId = payload[idx].criterionId
            const staffId = payload[idx].staffId
            const score = payload[idx].score

            if(!state.allStaffData[participantId]) state.allStaffData[participantId]={criteria:{}}
            if(!state.allStaffData[participantId].criteria[criterionId])  state.allStaffData[participantId].criteria[criterionId]={staff: {}}
            if(!state.allStaffData[participantId].criteria[criterionId].staff[staffId])  state.allStaffData[participantId].criteria[criterionId].staff[staffId]= {}
            state.allStaffData[participantId].criteria[criterionId].staff[staffId].score = score
        }
        state.status={}
    },
    loadAllFailure(state, error) {
        state.status={}
        state.error=error 
    },
    clearAllStaffData(state){
        state.allStaffData = {}
    },
  
    updateScoreRequest(state, payload) {
        state.status[payload.criterionId]={loading:true}
        state.cache={pid: payload.participantId, cid: payload.criterionId}
    },
    updateLocalScoreRequest(state, payload){
        const {userId,participantId, criterionId, score, average, criterionComplete, participantComplete} = payload

        // Only update the actual score if this is the same user as who created the update

        const localUserId = JSON.parse(localStorage.getItem('user')).details.id
        if(localUserId === userId){
            state.data[participantId].criteria[criterionId].score=score
        } 

        // Update averages and completion data

        state.data[participantId].criteria[criterionId].average=average
        state.data[participantId].criteria[criterionId].complete=criterionComplete
        state.data[participantId].complete=participantComplete
    },
        // eslint-disable-next-line
    updateScoreSuccess(state, payload) {
        const {score, average, criterionComplete, participantComplete } = payload
        const participantId = state.cache.pid
        const criterionId = state.cache.cid

        var avg =average
        if(avg===null) avg=0

        if(score || score===0) state.data[participantId].criteria[criterionId].score = score
        if(avg || avg===0) state.data[participantId].criteria[criterionId].average = avg
        if(criterionComplete || criterionComplete === 0) state.data[participantId].criteria[criterionId].complete = criterionComplete
        if(participantComplete || participantComplete===0) state.data[participantId].complete = participantComplete
        state.status={}

    },
    updateScoreFailure(state,error) {
        state.status={}
        state.error = error
    },
    //------ UPDATE -------\
    updateRequest(state) {
        state.status={loading: true}
        state.error=null
    },
    //eslint-disable-next-line
    updateSuccess(state, data){
        state.status={updated: true}
        state.error=null
    },
    updateFailure(state, error) {
        state.status={}
        state.error=error
    },

    // ------------- loadTryout ------------- \
    
    loadRequest(state) {
        state.status={loading: true}
        state.error=null
    },
    quietLoadRequest(state) {
        state.status={}
        state.error=null
        state.list=[]
    },
    loadSuccess(state, data) {
        state.list=[]
        state.data = data

        state.status={}
    },

    loadFailure(state, error) {
        state.status={}
        state.error = error
    },

    //------------ update data -------- \\
    propertyUpdate(state, payload) {
        const metaFields = "delete"
        const {field, id, value} = payload

        if(metaFields.includes(field)){
            state.meta[id][field]=value
            // Manage special fields...
            //switch (field) {
            //}
        } else {
            state.data[id][field]=value
        }
    }

}
export const playerskillStore = {
    namespaced: true,
    state,
    actions,
    mutations
}