
import {sendMessage} from '@/_helpers/service-tools'

export const seasonService = {
    findAllForClub,
    addSeason,
    updateSeason,
    find
}

async function find(payload){
    return await sendMessage('GET', 'season', payload, null)
}




async function findAllForClub(payload) {
    return await sendMessage('GET','season/listforclub', payload, null)
    
}

async function addSeason(season) {
    return await sendMessage('POST', 'season', null, season)
   
}

async function updateSeason(season) {
    return await sendMessage('PATCH', 'season', {seasonId: season.id}, season)
    
}