import { activityService } from '../_services/activity.service'
import Vue from 'vue'

const dataService = activityService

function initialiseState(){
    return {
        status: {},
        data: {},
        list: [],
        perSessionLists: {},
        error: null,
        cache: {},
        meta: {},
        internal: {},
    }
}

const state = initialiseState()


function addToLists(data) {
    // Add it into the generic list first

    const id = data.id
    state.list.push(id)

    data.order=0
    if(Object.prototype.hasOwnProperty.call(state.perSessionLists, data.sessionId))
        data.order = state.perSessionLists[data.sessionId].length

    // Add it to the data dictionary
    Vue.set(state.data, id, data)
    Vue.set(state.meta, id, {
        delete: false,
    })

    // Now add it to the session activity list

    var list=[]

    if(Object.prototype.hasOwnProperty.call(state.perSessionLists, data.sessionId)) {
        list = state.perSessionLists[data.sessionId]
    }

    list.push(id)

    Vue.set(state.perSessionLists,data.sessionId, list)
}

function sortListsByOrder() {
    state.list.sort((a,b)=>{
        return (''+state.data[a].name).localeCompare(''+state.data[b].name)
    })
    for (const sessionId in state.perSessionLists){
        var list = state.perSessionLists[sessionId]

        list.sort((a,b)=>{
            return (state.data[a].order-state.data[b].order)
        })
        Vue.set(state.perSessionLists, sessionId, list)
    }
}

const actions = {
    purgeData({commit}){
        commit('purgeData')
    },
    async findAllForEvent({commit},payload) {
        try{
            const {eventId} = payload
            commit('loadRequest')

            const response=await dataService.findAllForEvent( eventId)
            commit('loadSuccess', response)
        } catch(error) {
            commit('loadFailure', error)
        }
    },
    async create({commit, dispatch }, payload){
        try{
            commit('createRequest', payload)

            const response=await dataService.create(payload)
            commit('createSuccess', response)
        } catch(error) {
            commit('createFailure', error)
            dispatch('alert/error', error, {root: true})
        }
    },
    async delete({commit, dispatch}, payload) {
        try{
            commit('deleteRequest', payload)
            const response=await dataService.del( payload)
            commit('deleteSuccess', response)
        } catch(error) {
            commit('deleteFailure', error)
            dispatch('alert/error', error, {root: true})
        }
    },
    propertyUpdate({commit},payload) {
        commit('propertyUpdate', payload)
    },
    updateListOrder({commit}, list){
        commit('updateListOrder', list)
    },
    async update({commit},payload) {
        try{
            commit('updateRequest', payload)
            const response = await dataService.update(payload)
            commit('updateSuccess', response)
        } catch(error) {
            commit('updateFailure', error)
        }
    }
}

const mutations = {
    purgeData(state){
        const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }
    },
   // ------------- loadTryout ------------- \
    
     loadRequest(state) {
        state.status={loading: true}
        state.error=null
    },
    loadSuccess(state, data) {
        
        state.status={}
        state.data={}
        state.list=[]
        state.perSessionLists={}
        for (var idx in data.activities) {
            addToLists(data.activities[idx])
        }
        sortListsByOrder()
    },
    loadFailure(state, error) {
        state.status={}
        state.error = error
    },
    //------ CREATE -------\
    createRequest(state, data) {
        state.internal={loading: true, creating: data}
        state.error=null
    },
    //eslint-disable-next-line
    createSuccess(state, data){
        state.internal={updated: true}
        addToLists(data.activity)
        sortListsByOrder()
        state.error=null
    },
    createFailure(state, error) {
        state.internal={}
        state.error=error
    },
    //------ Delete -------\
    deleteRequest(state, id) {
        state.internal={deleting: true, target: id}
        state.error=null
    },
    //eslint-disable-next-line
    deleteSuccess(state, data){
        const activityId=state.internal.target.activityId
        
        Vue.delete(state.data, activityId )
        Vue.delete(state.meta, activityId)
        var idx=-1
        
        for(var i=0;i<state.list.length;i++){
            if(state.list[i]===activityId) {
                idx=i
            }
        }
        if(idx>=0){
            state.list.splice(idx,1)
        }

        // Also find it in the sessionData

        idx=-1

        for(var sessionId in state.perSessionLists){
            if(state.perSessionLists[sessionId].includes(activityId)){
                state.perSessionLists[sessionId].splice(state.perSessionLists[sessionId].indexOf(activityId))
            }
        }

        state.internal={deleted: true}
        state.error=null

    },
    deleteFailure(state, error) {
        state.internal={}
        state.error=error
    },

    // --- UPDATE LIST ORDER ----- \\

    updateListOrder(state, payload){
        Vue.set(state.perSessionLists, payload.sessionId, payload.list)
        var count=0
        for(var idx in state.perSessionLists[payload.sessionId]){
            const id = state.perSessionLists[payload.sessionId][idx]
            state.data[id].order = count++
        }
    },
    
    //------------ update data -------- \\
    propertyUpdate(state, payload) {
        const metaFields = "delete"
        const {field, id, value} = payload

        if(metaFields.includes(field)){
            state.meta[id][field]=value
            // Manage special fields...
            //switch (field) {
            //}
        } else {
            state.data[id][field]=value
        }
    },

    updateRequest(state) {
        state.error=null
    },
    updateSuccess(state, response){
        state.internal={response}

    },
    updateFailure(error){
        state.error=error
        state.status={}
    },

}

export const activityStore = {
    namespaced: true,
    state,
    actions,
    mutations
}