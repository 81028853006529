import { 
    sendMessage, 
//    sendMessageV
    } from '@/_helpers/service-tools'

export const messageService = {
    create, 
    loadAllForEvent,
    update
}



async function create(message) {
    return await sendMessage('POST', 'message', null, message)
}

async function loadAllForEvent(eventId){
    return await sendMessage('GET','message/listforevent', {eventId}, null)
}

async function update(message) {
    //eslint-disable-next-line
    const {name, ...msgToSend} = message
    return await sendMessage('PATCH', 'message', {"messageId": message.id}, msgToSend)
}