import { playerattributeService } from '../_services'
//import Vue from 'vue'
//import { router } from '../_helpers'

const dataService = playerattributeService

function initialiseState(){
    return {
        status: {},
        data: {},
        error: null,
        cache: {},
    }
}

const state = initialiseState()

const actions = {
    purgeData({commit}){
        commit('purgeData')
    },
    async updateLocalAttributeValue({commit}, payload){
        commit('updateLocalAttributeValueRequest', payload)
    },
    async findAllForEvent({commit},payload) {
        try{
            const {eventId} = payload
            commit('loadRequest')
            const response = await dataService.findAllForEvent( eventId)
            commit('loadSuccess', response)
        } catch(error) {
            commit('loadFailure', error)
        }
    },
    async quietFindAllForEvent({commit},payload) {
        try{
            const {eventId} = payload
            commit('quietLoadRequest')
            const response = await dataService.findAllForEvent( eventId)
            commit('loadSuccess', response)
        } catch(error){
            commit('loadFailure', error)
        }
    },
    async quietFindAllForEventAndStaff({commit},payload) {
        try{
            const {eventId} = payload
            commit('quietLoadRequest')
            const response = await dataService.findAllForEvent( eventId)
            commit('loadSuccess', response)
        } catch(error) {
            commit('loadFailure', error)
        }
    },

    async update({commit}, payload) {
        try{
            const {participantId, attributeId, userId, eventId, value } = payload

            commit('updateRequest', payload)
            const response = await dataService.update(participantId, attributeId, userId, eventId, value)
            commit('updatedSuccess', response)
        } catch(error) {
            commit('updateFailure', error)
        }
    },
    async updateValue({commit}, payload) {
        try{
            const {participantId, attributeId, eventId, value} = payload

            commit('updateValueRequest', payload)
            const response = await dataService.updateValue(eventId, participantId, attributeId, value)
            commit('updateValueSuccess', response)
        } catch(error) {
            commit('updateValueFailure', error)
        }
    },
    propertyUpdate({commit},payload) {
        commit('propertyUpdate', payload)
    }
}
/*
function addToLists(data) {
    const id = data.id

    state.list.push(id)
    Vue.set(state.data, id, data)
    Vue.set(state.meta, id, {
            delete : false,
    })
}

function sortListByOrder() {
        state.list.sort((a,b) => {
            return state.data[a].participantNumber - state.data[b].participantNumber
        })
}
*/

const mutations = {
purgeData(state){
    const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }
    },
    updateLocalAttributeValueRequest(state, payload){
        const {playerAttribute}  =payload
        const participantId = playerAttribute.participantId
        const attributeId = playerAttribute.attributeId
        const value = playerAttribute.value

        if(!state.data[participantId]) {
            state.data[participantId]={attributes:{}}
        }
        if(!state.data[participantId].attributes[attributeId]){
            state.data[participantId].attributes[attributeId]={value:null}

        } 
        state.data[participantId].attributes[attributeId].value = value
    },
  
    updateValueRequest(state, payload) {
        state.status[payload.attributeId]={loading:true}
        state.cache={pid: payload.participantId, aid: payload.attributeId, value: payload.value}
    },
        // eslint-disable-next-line
    updateValueSuccess(state, payload) {
        const participantId = state.cache.pid
        const attributeId = state.cache.aid
        const value = state.cache.value

        if(value || value===0) state.data[participantId].attributes[attributeId].value = value
        state.status={}

    },
    updateValueFailure(state,error) {
        state.status={}
        state.error = error
    },
    //------ UPDATE -------\
    updateRequest(state) {
        state.status={loading: true}
        state.error=null
    },
    //eslint-disable-next-line
    updateSuccess(state, data){
        state.status={updated: true}
        state.error=null
    },
    updateFailure(state, error) {
        state.status={}
        state.error=error
    },

    // ------------- load ------------- \
    
    loadRequest(state) {
        state.status={loading: true}
        state.error=null
        state.list=[]
    },
    quietLoadRequest(state) {
        state.status={}
        state.error=null
        state.list=[]
    },
    loadSuccess(state, data) {
        state.status={}
        state.data = data
    },
    loadFailure(state, error) {
        state.status={}
        state.error = error
    },

    //------------ update data -------- \\
    propertyUpdate(state, payload) {
        const metaFields = "delete"
        const {field, id, value} = payload

        if(metaFields.includes(field)){
            state.meta[id][field]=value
            // Manage special fields...
            //switch (field) {
            //}
        } else {
            state.data[id][field]=value
        }
    }

}
export const playerattributeStore = {
    namespaced: true,
    state,
    actions,
    mutations
}