<template>
    <div v-if="loading"></div>
    <div v-else-if="recipients">
        <div class="control-header">
                <v-row>
                    <v-col cols="8" style="text-align:left;justify-content: start">
                        <span class="pane-heading">Mail Preview</span>
                        <v-pagination v-model="partIdx" :length="recipients" :total-visible="7" />
                    </v-col>
                    <v-col cols="4">
                        <v-btn small color="primary" v-on:click="testEmail()"> Send Test Mail </v-btn> 
                        <v-btn small text color="primary" v-on:click="sendEmail()"> Send Final Mail </v-btn> 
                    </v-col>
                </v-row>
        </div>
        <div>
            <!-- 
            <span v-html="email"></span> 
            -->
            <render-offer-email 
                :firstName="firstName"
                :lastName="lastName"
                :teamOffered="teams[this.participants[this.participantId].offer].name"
                :offerLink="offerLink"
                :offerContactName="tryouts[this.eventId].contactName"
                :offerContactPhone="tryouts[this.eventId].contactPhone"
                :offerContactEmail="tryouts[this.eventId].contactEmail"
                :offerTraining="teams[this.participants[this.participantId].offer].training"
            />


        </div>
    </div>
    <div style="width:100%" v-else>
        <div style="width: 100%">
        <h3>No recipients selected yet</h3> 
        </div>
    </div>
</template>

<script>

import {sessionInvitationEmail1, sessionInvitationEmail2, sessionInvitationEmail3} from '@/_helpers/inviteEmailTemplate'
import {callbackInvitationEmail1, callbackInvitationEmail2, callbackInvitationEmail3} from '@/_helpers/callbackEmailTemplate'
import {offerEmail1, offerEmail2, offerEmail3, offerEmail4, offerEmail5} from '@/_helpers/offerTemplate'
import {mapState, mapActions} from 'vuex'

import RenderOfferEmail from '@/components/RenderOfferEmail'

import {messagingService} from '../_services'
const urlPrefix = process.env.VUE_APP_VUE_LINK_PREFIX

export default {
    props: {
        //'mailoutId', 
        
        eventId: {
            type: String
        },
    },
    components: {
        RenderOfferEmail
    },
    data() {
        return ({
            email1a: sessionInvitationEmail1,
            email2a: sessionInvitationEmail2,
            email3a: sessionInvitationEmail3,
            email1b: callbackInvitationEmail1,
            email2b: callbackInvitationEmail2,
            email3b: callbackInvitationEmail3,           
            email1: offerEmail1,
            email2: offerEmail2, 
            email3: offerEmail3,
            email4: offerEmail4,
            email5: offerEmail5,
            partIdx: 1,
        })
    },
    computed: {
        ...mapState({
            participants: state => state.participantStore.data,
            participantMeta: state=> state.participantStore.meta,
            participantList: state => state.participantStore.list,
            participantStatus: state => state.participantStore.status,
            teams: state => state.teamStore.data,
            teamStatus: state => state.teamStore.status,
            tryouts: state => state.tryoutStore.data,
            tryoutStatus: state => state.tryoutStore.status,
         }),
         offerLink() {
                return urlPrefix + "/reviewoffer/"+this.eventId 
         },
         loading: function() {
             return this.participantStatus.loading
                ||  this.teamStatus.loading
                || this.tryoutStatus.loading
                || !this.eventId
         },

        participantId: function() {
            if(this.recipients === 0) return ""
            return this.recipientList[this.partIdx-1]
        } ,
        firstName: function() {
            if(this.recipients === 0) return "<NONE>"
            return this.participants[this.participantId].firstName
        },
        lastName: function() {
            if(this.recipients === 0) return "<NONE>"
            return this.participants[this.participantId].lastName
        },
        participantNumber: function() {
            if(this.recipients === 0) return "<NONE>"
            return this.participants[this.participantId].participantNumber
        },
        recipients: function() {
            return this.recipientList && this.recipientList.length
        },
        email: function() {
            /*
            return this.email1 + 
                this.firstName +
                this.email2 + 
                this.participantNumber +
                this.email3
                */
            var contactString=""
            const tryout = this.tryouts[this.eventId]

            if(tryout.contactName) {
                contactString = `<p>For any questions, contact ${tryout.contactName }`
                if(tryout.contactEmail || tryout.contactPhone) {
                    contactString += ` (`
                    if(tryout.contactEmail) {contactString += `Email: ${tryout.contactEmail}`;if(tryout.contactPhone) contactString+=", "}
                    if(tryout.contactPhone) contactString += `Phone: ${tryout.contactPhone}` 
                    contactString += `)`
                } 
                contactString+=`</p>` 
            }

            return this.email1 +
                this.firstName +
                this.email2 + 
                this.teams[this.participants[this.participantId].offer].name+
                this.email3 +
                urlPrefix + "/reviewoffer/"+this.eventId + 
                this.email4+
                contactString +
                this.email5
        },
        recipientList() {
            return this.participantList.filter((e)=>{
                return this.participantMeta[e].selected
            })
        }
    },
    methods: {
        ...mapActions('participantStore', {
            findAllParticipants : 'findAllForEvent',
        }),
        async testEmail() {
            await messagingService.sendOfferEmail(this.eventId,this.recipientList, true)

        },

        async sendEmail() {
            await messagingService.sendOfferEmail(this.eventId,this.recipientList, false)

        },

    },
    watch: {
        recipientList: function() {
            if (this.partIdx > this.recipientList.length)
                this.partIdx = 1
        },
        partIdx: function () {
        }
    },
}

</script>

<style>
.control-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px;
    align-items: center;
}
</style>