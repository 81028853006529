<template>
    <loading v-if='loading'/>
    <div v-else :style="cssProps" class="public-page">
        <div class="transbox">
            <div :class="responsiveClass('club-banner')">
                <div :class="responsiveClass('club-name')">
                    {{ clubs[selectedClub].name }}
                </div>
                <div :class="responsiveClass('club-logo')">
                    <v-img v-if="clubs[selectedClub].logo" :src="clubs[selectedClub].logo" max-height="200" max-width="200" style="margin-left:auto;margin-right:auto;"/>
                </div>
            </div>

            <div :class="responsiveClass('club-info')">
                <BoundedBox bottomPadding="10px" title="Club Information" borderWidth="2px" titleSize="1.5em" titleWeight="500" borderColor="black" :class="responsiveClass('club-info-box')">
                    <span slot="title">
                        Club Information
                    </span>

                    <div slot="content" v-if="clubs[selectedClub].website">

                        <div :class="responsiveClass('club-info-text')">You can find more information about this club at their website</div>
                        <div v-if="clubs[selectedClub].linkText">
                            <v-btn small style="font-size: 0.5em;" color="primary" @click="visitClubWebsite">{{ clubs[selectedClub].linkText }}</v-btn>
                        </div>
                        <div v-else>
                            <v-btn small color="primary" @click="visitClubWebsite">Visit club website</v-btn>
                        </div>
                    </div> 

                </BoundedBox>
                <div :class="responsiveClass('club-seasons-tryouts')">
                    <!--
                    <BoundedBox style="margin-bottom:20px;margin-top:30px;" borderWidth="2px" titleSize="large" titleWeight="500" borderColor="black" :class="responsiveClass('club-season-box')">
                        <span slot="title">
                            Current Seasons
                        </span>
                        <div slot="content" class="club-tryout-list">
                            <v-row v-if="activeSeasonList.length === 0">
                                No active seasons
                            </v-row>
                            <v-row class="no-boundaries" v-for="season in activeSeasonList" :key="season" >
                                <v-col cols="2" xl="2" lg="2" md="2" sm="6" xs="6" style="margin:0px;padding:2px;">
                                    <v-img
                                        :src="seasonLogo(seasons[season].logo, seasons[season].sportId)"
                                        class="white--text align-end"
                                        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                        height= "20px"
                                        style="aspect-ratio:1;margin:0px;padding:0px;;"
                                    >
                                    </v-img>
                                </v-col>
                                <v-col cols="5" :class="responsiveClass('season-title')" style="margin-top:auto;margin-bottom:auto;" >
                                    <div class="no-boundaries" @click="setSeason(season)"> {{ seasons[season].name }}</div>
                                </v-col>
                                <v-col class="no-boundaries" cols="4">
                                    <v-btn width="100%" x-small color="primary" @click="setSeason(season)">View</v-btn>
                                </v-col>
                            </v-row>

                        </div>

                    </BoundedBox>
                -->
                    <BoundedBox borderWidth="2px" titleSize="1.5em" titleWeight="500" borderColor="black" :class="responsiveClass('tryout-box')">
                        <span slot="title">
                            Current Tryouts
                        </span>
                        <div slot="content">

                            <div v-if="activeSeasonList.length === 0">
                                No active tryouts
                            </div>
                            <div v-else >
                                <div v-for="season in activeSeasonList" :key="season">
                                    <div :class="responsiveClass('season-title')">
                                        {{ seasons[season].name }}
                                    </div>
                                    <div class="club-tryout-list">
                                        <div class="tryout-row" v-for="tryoutId in tryoutList" :key="tryoutId">
                                            <div v-if="tryouts[tryoutId].seasonId === season">
                                                <v-tooltip top class="custom-tooltip" open-delay="1000" style="margin:0px;padding:0px;">
                                                    <template v-slot:activator="{on,attrs}">
                                                        <div v-on="on" v-bind="attrs" :class="responsiveClass('tryout-name')">
                                                            <div>
                                                                {{ tryouts[tryoutId].title }}
                                                            </div>
                                                            <!--
                                                            <div class="elipsis">................................................................................................................................................................................</div>
                                                            -->
                                                        </div>
                                                    </template>
                                                    <v-card style="margin:0px;padding:0px;">
                                                        <v-card-title>
                                                            {{ tryouts[tryoutId].title }}
                                                        </v-card-title>
                                                        <v-card-text>
                                                            <div class="tooltip-text"><b>Location:</b> {{ tryouts[tryoutId].location }}</div>
                                                            <div class="tooltip-text"><b>Date:</b> {{ moment(tryouts[tryoutId].startDate).format('ddd Do MMM, YYYY') }} - {{ moment(tryouts[tryoutId].endDate).format('ddd Do MMM, YYYY') }}</div>
                                                            <div class="tooltip-text"><b>Description:</b> </div>
                                                            <div class="tooltip-text">{{ tryouts[tryoutId].description }}</div>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-tooltip>
                                                <div :class="responsiveClass('tryout-register')">

                                                    <v-btn v-if="tryouts[tryoutId].onlineReg && tryouts[tryoutId].athlete && tryouts[tryoutId].registered && tryouts[tryoutId].registrationStatus==='incomplete' && tryouts[tryoutId].feeExemption" style="width:100%;margin-left:5px;text-align:right;" x-small color="red" @click="tryoutReg(tryoutId)">Complete registration</v-btn>
                                                    <v-btn  v-else-if="tryouts[tryoutId].onlineReg && tryouts[tryoutId].athlete && tryouts[tryoutId].registered && tryouts[tryoutId].registrationStatus==='incomplete' && !tryouts[tryoutId].feeExemption" style="width:100%;margin-left:5px;text-align:right;" x-small color="green" @click="tryoutReg(tryoutId)">Complete and pay for Registration</v-btn>
                                                    <v-btn v-else-if="tryouts[tryoutId].onlineReg && tryouts[tryoutId].athlete && tryouts[tryoutId].registered" style="width:100%;margin-left:5px;text-align:right;" x-small color="primary" @click="tryoutReg(tryoutId)">View Application</v-btn>
                                                    <v-btn  v-else-if="tryouts[tryoutId].onlineReg" style="width:100%;margin-left:5px;text-align:right;" x-small color="primary" @click="tryoutReg(tryoutId)">Register now</v-btn>
                                                    <div v-else :class="responsiveClass('no-reg')">Not taking online registrations</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </BoundedBox>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Loading from '@/components/loading'
import BoundedBox from '@/components/BoundedBox'
import {mapState, mapActions}  from 'vuex'

export default{
    props: {
        fontSize: {
            default:'20px',
        }
    },
    components:{
        Loading,
        BoundedBox,
    },
    computed: {
        ...mapState({
            clubs:      state => state.clubStore.data,
            account:    state => state.account,

            tryouts:    state => state.tryoutStore.data,
            tryoutList: state => state.tryoutStore.list,
            tryoutStatus: state => state.tryoutStore.status,

            seasons:    state => state.seasonStore.data,
            seasonList: state => state.seasonStore.list,
            sports:     state => state.sportStore.data,

            selectedClub: state => state.navigatorStore.selectedClub,
        }),
        loading(){
            return false ||
                this.tryoutStatus.loading ;
        },
        cssProps() {
            return {
                '--page-background-color': this.clubs[this.selectedClub].pageBackgroundColor,
                '--club-name-font': this.clubNameFont,
                '--club-name-color': this.clubNameColor,
                '--font-size': this.fontSize,
                '--background': 'url('+this.backgroundImage+')',
            }
        },
        backgroundImage: {
            get(){
                return this.clubs[this.selectedClub].pageBackgroundImage
            },
        },
        activeSeasonList: function () {
            // If the user is a club owner, show all seasons
            /*
            if(this.account.user.details.id === this.clubs[this.selectedClub].ownerId) {
                return this.seasonList
            }
            */
            // Otherwise only show active seasons
            return this.seasonList.filter(seasonId => this.seasons[seasonId].active)
        },
        activeSeasons(){
            return this.seasons
        }
    },
    methods: {
        ...mapActions('navigatorStore', {
            selectTryout: 'selectTryout',
            selectClubPane: 'selectPane',
            selectSeason: 'selectSeason',
            selectNav: 'selectNav',
        }),

        visitClubWebsite(){
            const url = 'https://'+this.clubs[this.selectedClub].website
            window.open(url, '_blank', 'noreferrer');
        },
        responsiveClass(className){
            if(this.$vuetify.breakpoint.smAndDown){
                return className+' mobile'
            }
            return className
        },
        smallDisplay(){
            return this.$vuetify.breakpoint.smAndDown
        },
        tryoutReg(id){
            this.selectTryout(id)
            this.selectClubPane('TryoutRegistration')
        },
        isAdminUser() {
            return (this.clubs[this.selectedClub].ownerId === this.account.id ) || this.clubs[this.selectedClub].admin
        },
        async setSeason(season){
            this.selectSeason(season)
            this.selectClubPane('SeasonHome')
            this.selectNav('Season')
        },
        seasonLogo(logo, sportId){
            if(logo) return logo
            return this.sports[sportId].cardImage
        },

    },
    async created(){
        if(!this.clubs){
            await this.findClub(this.clubId)
        }
    },
    data(){
        return {
            pageBackgroundColor: 'bisque',
            clubNameFont: 'Tahoma',
            clubNameColor: 'blue',
            fonts: [
                "Arial",
                "Verdana",
                "Tahoma",
                "Trebuchet MS",
                "Times New Roman",
                "Georgia",
                "Garamond",
                "Courier New",
                "Brush Script MT",
            ]
        }
    }
}
</script>

<style scoped>

.public-page {
    position: relative;
    width:100%;
    background-color: var(--page-background-color);
    margin:00px;
    padding:10px;
    height:92vh;
    display:flex;
    align-items:center;
    justify-content: center;
    font-size: var(--font-size);
    background-size: contain;

}

.public-page::before {
    content:"";
    background-image: var(--background);
    background-size: contain;
    position:absolute;
    top:0px;
    bottom:0px;
    left:0px;
    right:0px;
    opacity:0.2;
}

.transbox {
    margin:30px;
    background-color:white;
    border-radius:20px;
    width:90%;
    height:80vh;
    margin-top:0px;
}

.club-seasons-tryouts{
    margin-top:30px;
}

.club-seasons-tryouts.mobile{
    width:100%
}

.tryout-box{
}
.tryout-box.mobile{
    margin-top:30px;
    height:35vh; 
    width: 95%;
}

.club-header{
    display: flex;
    flex-direction: row;
    height:5vh;
}

.club-logo{
    margin:5px;
}
.club-logo img{
    width:100%;
    height:100%;
    object-fit:contain;
}

.club-name{
    font-family: var(--club-name-font);
    color: var(--club-name-color);
    font-size: 2em;
    text-align:center;
    width:100%;
    
}

.club-name.mobile{
    font-size: 1.0em;
}

.season-title{
    font-size:medium;
    margin:0px;
    padding:0px;
    text-align:left;
    background: var(--page-background-color);
    color:white;
    font-family: 'Varela Round';
    font-weight:400;
}

.season-title.mobile{
    font-size:small;
}
.club-info{
    display:flex;
    flex-direction:column;
}
.club-info.mobile{
    flex-direction:column;
    width: 100%;
}

.club-info-box {
    margin-top:30px;
}

.club-info-box.mobile{
    height: 30%;
    width:95%;
}
.club-info-text{
    font-size:1em;
    text-align: left;
}

.club-info-text.mobile {
    font-size:small;
}
.club-season-box{

}
.club-season-box.mobile{
    width:95%;
    height:15vh;
}
.club-website{
    margin:20px;
    border-style: solid;
    border-color:black;
    border-radius:20px;
    padding:20px;
    margin-bottom:20%;
}

.club-website.mobile{
    /*width:100%;*/
}

.club-tryouts{
    margin:20px;
    border-style: solid;
    border-color:black;
    width:100%;
    border-radius:20px;
    padding:20px;
    margin-top:20%;
}
.club-tryouts.mobile{
    height: 35vh;
}

.club-tryout-list{
    overflow-y:scroll;
    height:100%;
}




.heading{
    font-weight:600;
    font-size:1.5em;
    text-align:left;
    margin-bottom:20px;
}

.empty{
    width:100%;
}

.tryout-row{
    display: flex;
    flex-flow:column;
    align-items: stretch;
    margin: 0px 00px 0px 0px;
}
.tryout-name {
    font-weight:200;
    font-size:0.7em;
    text-align:left;
    color:black;
    padding:0px;
    overflow-x:hidden;
    text-wrap:nowrap;
    z-index:0;
    display:flex;
    flex-flow:row;
}
.tryout-name.mobile {
    font-size:0.5em;
}

.no-reg{
    font-weight:200;
    font-size:0.7em;
    text-align:left;
    color:black;
    padding:0px;
    overflow-x:hidden;
    text-wrap:word-break;
    z-index:0;
    background-color:#fbd794;
    border-radius:5px;
    padding:5px;
    margin:2px;
    text-align:center;
}
.no-reg.mobile {
    font-size: 0.5em;
}

.elipsis{
    flex-grow:0;
}
.tryout-register{
    text-align:right;
    flex-grow:1;
    margin-left:30%;
    margin-right:5%;
}
.tryout-register.mobile {
    margin-left:40%;
}

.tooltip-text{
    text-align:left;
}

.custom-tooltip {
    opacity: 1!important;
  background: var(--v-tooltip-bg, rgba(97, 97, 97, 0.9)) !important;
}

.v-tooltip__content.menuable__content__active {
  opacity: 1!important;
}

.v-card__text {
    padding:10px;
    box-shadow: inset 0 0 0 10px white;
    opacity:1!important;
}

.v-tooltip .v-overlay__content {
    background: rgba(var(--v-theme-surface-variant), 1) !important;
}

.v-btn.v-size--x-small {
    font-size: 0.425rem;
}


</style>