<template>
    <loading v-if='loading'/>
    <div v-else>
        <div v-responsive.xs.sm>

        </div> 
        <div v-responsive.md.lg.xl>
            <v-row>
                <v-col cols="4">
                    <div class="pane-heading">Team Homepage</div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="1" style="margin-top:0px;margin-bottom:auto;">
                    <div v-if="allowJournal" class="settings-button mb-5" @click="selectPane('JournalHome')">
                        <v-icon large color="white">mdi-notebook-outline</v-icon>
                        <div style="font-size:small; color:white;" class="text-center">Journal Library</div>
                    </div>
                </v-col>
                <v-col cols="8">

                </v-col>
                <v-col>
                    <v-row class="no-boundaries">
                        <h3>The Team</h3>
                    </v-row>
                    <v-row v-if="participantList.length===0">
                        <v-col class="no-boundaries">Nobody has been selected into this team</v-col>
                    </v-row>
                    <v-row class="no-boundaries">
                        <v-col cols="6" class="no-boundaries" v-for="idx in participantList" :key="idx">
                            <player-select-button teamSelector hideOffer :participantId="idx" v-on:click.native="selectParticipant(idx)"/> 
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>


        </div>
    </div>

</template>

<script>
import Loading from '@/components/loading'
import PlayerSelectButton from '@/components/PlayerSelectButton'

import {mapActions, mapState} from 'vuex'

export default{
    props: [],
    components:{
        Loading,
        PlayerSelectButton,
    },
    computed: {
        ...mapState({
            teams:              state => state.teamStore.data,
            
            participants:       state => state.participantStore.data,
            participantList:    state => state.participantStore.list,
            participantStatus:  state => state.participantStore.status,

            selectedTryout:     state => state.navigatorStore.selectedTryout,

        }),
        loading(){
            return false ||
                false;
        },
        allowJournal(){
            return true
        }

    },
    methods: {
        /*
        ...mapActions('teamStore', {

        }),
        */
        ...mapActions('participantStore', [
            'loadAllParticipantsForTeam'    
        ]),

        ...mapActions('navigatorStore', [
            'selectPane',
        ]),
        async loadData(){
            await this.loadAllParticipantsForTeam({teamId: this.selectedTryout})

        }

    },
    watch: {},
    mounted(){
        this.$root.$on('tryoutRefresh', this.refreshHandler)
    },
    async created(){
        await this.loadData()

    },
    data(){
        return {
            refreshHandler: ()=>{ 
                var self=this
                self.loadData()
            },
        }
    }

}
</script>