<template>
    <v-card elevation="10">
        <v-card-title>Contact Details</v-card-title>
        <v-card-text>
            <table>
                <tr>
                    <td class="contact-title">Name:</td>
                    <td class="detail">{{  contact.name }}</td>
                </tr>
                <tr v-if="contact.phone">
                    <td class="contact-title">Phone:</td>
                    <td class="detail">{{ contact.phone }}</td>
                </tr>
                <tr v-if="contact.email">
                    <td class="contact-title">Email:</td>
                    <td class="detail">{{ contact.email  }}</td>
                </tr>
            </table>

        </v-card-text>
    </v-card>
</template>

<script>

export default {
    props: ['contact'],
    components: {},
    computed: {},
    methods: {},
    created(){},
    data() {
        return {

        }
    }

}
</script>

<style scoped>

.contact-title{
    font-weight: 600;

}

.detail{
    font-weight:300 ;
    text-align: left;
}

</style>