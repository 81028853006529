<template>
    <loading v-if="loading"/>
    <div v-else>
        <h1>You are already registered for this tryout series...</h1> 
        <div>
            Your participant number is: 
        </div>
        <div class="participant-id"> 
            {{participantNumber}}
        </div>
        <div>
            <p>
            <em>Tryouts start {{whenTryoutsStart}} !</em>
            </p>
            Please check your email for further details about the tryout sesssions. 
            <p>
            <br/>
            You will need to register your attendance at each session using the QR code at the venue, and make sure your player number is clearly visible on your left leg.
            </p>
            <br/>
            <p>
            <br/>
            We are looking forward to seeing you at the tryouts! If you have any further questions, contact Andy Davison on 0408 255 761
            </p>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Loading from '@/components/loading'
//import {router} from '@/_helpers/router'

import moment from 'moment'

export default {
    components: {
        Loading,
    },
    computed: {
        ...mapState({
            status: state => state.tryoutStore.status,
            participants: state => state.participantStore.data,
            participantList: state => state.participantStore.list,
            tryouts: state => state.tryoutStore.data,
            account: state => state.account,
            participantStatus: state => state.participantStore.status
        }),
        loading: function() {
            return (this.participantStatus.loading || this.status.loading)
        },
        whenTryoutsStart : {
            get() {
                return moment(this.startDate).fromNow()
            }
        },
        participantNumber: {
            get() {
                if(this.participants[this.participantList[0]]){
                    const number = this.participants[this.participantList[0]].participantNumber
                    return number
                } else {
                    return ""
                }
            }
        },
        eventId : {
            get() {
                return this.$route.query.eventId
            }
        },
        startDate: {
            get() {
                if(this.tryouts[this.$route.query.eventId]){
                    return this.tryouts[this.$route.query.eventId].startDate
                } else {
                    return ""
                }
            }
        }
    },
    methods: {
        ...mapActions('tryoutStore', {
            findAllTryouts: 'findAll'
        }),
        ...mapActions('participantStore',{
            findParticipant: 'inEvent'
        })
    },
    async created() {
        const userId = this.account.user.details.id
        //await this.findAllTryouts()
        await this.findParticipant({userId: userId, eventId: this.$route.query.eventId})
    }
}
</script>

<style>
.participant-id {
    display: flex;
    justify-content: center;
    border-style: solid;
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size:xxx-large;
    border-radius: 36px;
    background: blue;
    color: white;
    font-weight: 900;
    padding: 40px;
}
</style>