var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"text-align":"left","padding":"0px 10px 0px 10px"}},[_c('v-row',[_c('v-col',{staticStyle:{"text-align":"right"},attrs:{"cols":"22"}},[(_vm.changes)?_c('v-btn',{staticClass:"save-btn",staticStyle:{"margin-right":"10px"},attrs:{"x-small":"","depressed":"","color":"primary"},on:{"click":_vm.saveDetails},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" Save ")]):_vm._e(),_c('v-btn',{attrs:{"color":"primary","x-small":""},on:{"click":function($event){_vm.dialog=true}}},[_vm._v(" Add new session ")])],1)],1),_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"1"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
var invalid = ref.invalid;
return [_c('form',{staticStyle:{"margin":"0px","padding":"0px"},on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.create(reset))}}},[_c('v-card',{ref:"observer"},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("New Session")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Session name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"mdi-rename-box","label":"Session Name"},model:{value:(_vm.newName),callback:function ($$v) {_vm.newName=$$v},expression:"newName"}}),_c('div',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-venue","label":"Venue"},model:{value:(_vm.newVenue),callback:function ($$v) {_vm.newVenue=$$v},expression:"newVenue"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Session Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateCombo',{attrs:{"title":"Session Date"},model:{value:(_vm.newdate),callback:function ($$v) {_vm.newdate=$$v},expression:"newdate"}}),_c('div',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"time"},[_c('ValidationProvider',{attrs:{"name":"Start Time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_vm._v(" Start Time: "),_c('VueTimepicker',{attrs:{"drop-direction":"up","minute-interval":15},model:{value:(_vm.newstartTime),callback:function ($$v) {_vm.newstartTime=$$v},expression:"newstartTime"}}),_c('div',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"time"},[_c('ValidationProvider',{attrs:{"name":"End Time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_vm._v(" End Time: "),_c('VueTimepicker',{attrs:{"drop-direction":"up","minute-interval":15},model:{value:(_vm.newendTime),callback:function ($$v) {_vm.newendTime=$$v},expression:"newendTime"}}),_c('div',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.closeDialog(reset)}}},[_vm._v("close")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","type":"submit","disabled":invalid}},[_vm._v("Save")])],1)],1)],1)]}}])})],1)],1)],1),_c('v-row',[_c('v-expansion-panels',_vm._l((_vm.list),function(id){return _c('session-editor',{key:id,attrs:{"sessionId":id}})}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }