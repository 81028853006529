<template>
    <v-card style="width:100%">
        <v-card-title :style="headerStyle">
            <v-row>
                <v-col class="photo-col" cols="5">
                    <participant-profile-image v-responsive.sm.xs :participantId="participantId" size="60px" style="margin-top:0px" />
                    <participant-profile-image v-responsive.md.lg.xl :participantId="participantId" size="80px" />
                </v-col>
                <v-col cols="6" >
                    <div class="player-number">
                        {{participants[participantId].participantNumber}} 
                    </div>
                    <div class="first-name">
                        {{participants[participantId].firstName}} 
                    </div>
                    <div class="last-name">
                        {{participants[participantId].lastName}}
                    </div>
                    <div class="last-name">
                        <v-chip v-if="participants[participantId].offerResponse==='accept'" color="green">Offer Accepted</v-chip>
                        <v-chip v-else-if="participants[participantId].offerResponse==='decline'" color="red">Offer Declined</v-chip>
                        <v-chip v-else color="orange">Offer Pending</v-chip>
                    </div>
                </v-col>
                <v-col cols="1" style="margin-top:0;padding:0;">
                    <v-icon v-if="!recommendationMode && participants[participantId].offerResponse !== 'accept' && participants[participantId].offerResponse !== 'decline'" color="red" @click="unassignFromTeam">
                        mdi-close-circle
                    </v-icon>

                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text>
            <v-row class="assignment-title">
                    Recommended Roles
            </v-row>
            <v-row class="assignment-text">
<!--                    {{recommendedRoles}}-->

                <v-chip v-if="recommendedRoles.length===0" pill x-small color="blue" text-color="white">No Roles Recommended</v-chip>
                <v-chip v-else v-for="role in recommendedRoles" :key="role" pill x-small color="var(--cs1-lev1)" text-color="white">{{ role }}</v-chip>
            </v-row>
            <v-row style="padding-bottom:2px;padding-top:2px;">
                <v-divider/>
            </v-row>
            <v-row class="assignment-title">
                Skill Averages
            </v-row>
            <v-row>
                <SkillStack v-responsive.md.lg.xl :participantId="participantId" :width="250"></SkillStack>
                <SkillStack v-responsive.xs.sm :participantId="participantId" :width="180"></SkillStack>
                
            </v-row>
        
        </v-card-text>
    </v-card> 
 
 </template>
 
 <script>
 import { mapActions, mapState } from 'vuex';
 
import SkillStack from '@/components/SkillStack'
import ParticipantProfileImage from '@/components/NewParticipantProfileImage.vue';
 
 function interpolateColor(c0, c1, f){
     c0 = c0.match(/.{1,2}/g).map((oct)=>parseInt(oct, 16) * (1-f))
     c1 = c1.match(/.{1,2}/g).map((oct)=>parseInt(oct, 16) * f)
     let ci = [0,1,2].map(i => Math.min(Math.round(c0[i]+c1[i]), 255))
     return ci.reduce((a,v) => ((a << 8) + v), 0).toString(16).padStart(6, "0")
 }
 
 export default {
     props: {
        participantId: {
            type: String
        },
        teamId: {
            type:String,
        },
        chartId: {
        type: String,
        default: 'bar-chart'
        },
        datasetIdKey: {
        type: String,
        default: 'label'
        },
        width: {
        type: Number,
        default: 200
        },
        height: {
        type: Number,
        default: 50
        },
        cssClasses: {
        default: '',
        type: String
        },
        styles: {
        type: Object,
        default: () => {}
        },
        plugins: {
        type: Array,
        default: () => []
        },
        recommendationMode: {
            type: Boolean,
            default: false,
        }
    },
     components: {
        SkillStack,
        ParticipantProfileImage,
     },
     computed: {
         ...mapState({
             participants:       state => state.participantStore.data,
             participantList:    state => state.participantStore.list,
             participantStatus:  state => state.participantStore.status,
 
             teamRecommendations:        state => state.teamrecommendationStore.data,
             teamRecommendationList:     state => state.teamrecommendationStore.list,
             teamRecommendationStatus:   state => state.teamrecommendationStore.status,
             recommendationCount:        state => state.teamrecommendationStore.recommendationCount,
             recommendationsByTeamAndPlayer: state => state.teamrecommendationStore.recommendationsByTeamAndPlayer,

             staffList:                  state => state.staffStore.list,

             roles:                         state => state.roleStore.data,
             roleList:                      state => state.roleStore.list,

             selectedTryout:                state => state.tryoutStore.selectedTryout,
 
         }),
         selectorCount() {
             return this.staffList.length
         },
         headerStyle() {
 
             var rating=0;
             if(this.recommendationCount[this.teamId] && this.recommendationCount[this.teamId][this.participantId])
                 rating = (this.recommendationCount[this.teamId][this.participantId])/this.selectorCount
 
             var color1 = 'FFFFFF';
             var color2 = '00FF00';
 
             const style = {
                 backgroundColor: "#"+interpolateColor(color1,color2, rating),

                 marginBottom: "10px",
             }
 
             return style
         },
         recommendationArray() {
            if (!this.recommendationsByTeamAndPlayer[this.teamId] || !this.recommendationsByTeamAndPlayer[this.teamId][this.participantId]) return []
                return this.recommendationsByTeamAndPlayer[this.teamId][this.participantId]
         },
         recommendedRoles() {
            var rolesMap={}
            var idx=0
            if(this.recommendationMode){
                for(idx in this.teamRecommendationList){

                    const recommendationId = this.teamRecommendationList[idx]
                    const recommendation = this.teamRecommendations[recommendationId]

                    if(recommendation.participantId === this.participantId){
                        if(recommendation.role1)
                            rolesMap[this.roles[recommendation.role1].name]=true
                        if(recommendation.role2)
                            rolesMap[this.roles[recommendation.role2].name]=true
                    }
                }
            } else {
                for(idx in this.recommendationArray){
                    const recommendationId = this.recommendationArray[idx]
                    const recommendation = this.teamRecommendations[recommendationId]

                    if(recommendation.role1)
                        rolesMap[this.roles[recommendation.role1].name]=true
                    if(recommendation.role2)
                        rolesMap[this.roles[recommendation.role2].name]=true
                }
            }
            return Object.keys(rolesMap)

//            return roles ? roles : "No roles recommended"
         },
     },
     methods: {
        ...mapActions('participantStore', {
            updateParticipantProperty: 'propertyUpdate',
            updateParticipant: 'update',
        }),
        ...mapActions('roleStore',{
            findRolesForEvent: 'findAllForEvent',
        }),
        unassignFromTeam(){
            this.updateParticipantProperty({field: 'offer', id: this.participantId, value: null})
            this.updateParticipant(this.participants[this.participantId])
        }
     },
     created() {
        if(this.roleList.length===0){
            this.findRolesForEvent({eventId: this.selectedTryout})
        }
     },
     data() {
         return {
            dialog: false,
         }
     }
 }
 
 </script>
 
 <style scoped>

 .photo-col {
    padding:0px;
    margin-top:0px;
 }
 
 .participant-name{
     font-size: x-small;
     margin-top:0px;
 }
 
 .participant-id{
     font-size: x-small;
     margin-top:0px;

 }
 
 .participant-selector-tile{
     border-style: solid;
     border-color: blue;
 
 }
 .players {
     display: inline-block;
     flex-direction: column;
     border-style: solid;
     border-color: blue;
     border-radius: 5px;
     border-width: 1px;
     font-size: 1.5rem;
     min-width: 100%;
     min-height: 00%;
     padding: 1px !important;
     margin: 2px;
     flex-grow: 2;
 }
 .v-btn.v-size--default.players, .v-btn.v-size--large.players {
     font-size: 1.5rem;
     width: 3rem;
     /* padding: 49px; */
     margin: 5px;
 }
 
 .recommendation-badge {
     position: absolute;
     right: -20px;
     top: 10px;
     background: green;
     text-align: center;
     border-radius: 50%;
     border-style: none;
     border-color:green;
     color: white;
     padding: 5px 10px;
     font-size: x-small;
     font-weight: 300;
 }

 .assignment-title{
    font-size: small;
    font-weight: 600;

 }
 .assignment-text{
    font-size:small;
    text-align:left;
    padding-left:20px;
 }

 .first-name{
    font-weight:600;
    font-size:large;
 }

 .last-name {
    font-size:small;
 }
 .player-number {
    font-size:small;
 }
 </style>
 