<template>
    <div style="text-align:left;padding: 0px 10px 0px 10px">
        <v-row>
                <v-col cols="22" style="text-align:right">
                    <v-btn @click.native.stop 
                        v-if="changes" 
                        x-small 
                        v-on:click="saveDetails" 
                        depressed 
                        color="primary" 
                        class="save-btn"
                        style="margin-right: 10px;"
                    >
                        Save
                    </v-btn>
                    <v-btn color="primary" x-small @click="dialog=true">
                        Add new session
                    </v-btn>
                </v-col>
        </v-row>

        <v-row>
            <v-spacer/>
            <v-col cols="1">
                <v-dialog v-model="dialog" persistent max-width="600px">
                    <ValidationObserver v-slot="{handleSubmit, reset, invalid}" ref="form">
                        <form @submit.prevent="handleSubmit(create(reset))" style="margin:0px;padding:0px;">
                            <v-card ref="observer">
                                <v-card-title>
                                    <span class="text-h5">New Session</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            <ValidationProvider name="Session name" rules="required" v-slot="{errors}">
                                                <v-text-field v-model="newName" prepend-icon="mdi-rename-box" label="Session Name"/>
                                                <div class="form-error">{{ errors[0] }}</div>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field v-model="newVenue" prepend-icon="mdi-venue" label="Venue"/>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <ValidationProvider name="Session Date" rules="required" v-slot="{errors}">
                                                <DateCombo title="Session Date" v-model="newdate"/>
                                                <div class="form-error">{{ errors[0] }}</div>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>    
                                    <v-row>
                                        <v-col cols="6">
                                            <div class="time">
                                                <ValidationProvider name="Start Time" rules="required" v-slot="{errors}">
                                                    Start Time:
                                                    <VueTimepicker drop-direction="up" :minute-interval="15" v-model="newstartTime"></VueTimepicker>
                                                    <div class="form-error">{{ errors[0] }}</div>
                                                </ValidationProvider>
                                            </div>
                                        </v-col>

                                        <v-col cols="6">
                                            <div class="time">
                                                <ValidationProvider name="End Time" rules="required" v-slot="{errors}">
                                                    End Time:
                                                    <VueTimepicker drop-direction="up" :minute-interval="15" v-model="newendTime"></VueTimepicker>
                                                    <div class="form-error">{{ errors[0] }}</div>
                                                </ValidationProvider>
                                            </div>                                        
                                        </v-col>

                                    </v-row>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="closeDialog(reset)" >close</v-btn>
                                    <v-btn color="blue darken-1" text type="submit" :disabled="invalid" >Save</v-btn>
                                </v-card-actions>
                            </v-card>
                        </form>
                    </ValidationObserver>
                </v-dialog>
            </v-col>
        </v-row>
        <v-row>
            <v-expansion-panels>
                <session-editor v-for="id in list" :sessionId="id" :key="id"/>
            </v-expansion-panels>
        </v-row>
    </div>
</template>

<script>
import SessionEditor from '@/ClubPanes/NewTryoutSettingsPanels/SessionEditor'
import {mapState, mapActions} from 'vuex'
import DateCombo from '@/components/DateCombo'
import moment from 'moment'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
   
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'


import {rules} from '@/_helpers/rules'

export default {
    props: [],
    components: {
        SessionEditor,
        DateCombo,
        ValidationObserver,
        ValidationProvider,
        VueTimepicker,
    },
    computed: {
        ...mapState({
            tryoutMeta: state => state.tryoutStore.meta,
            status: state => state.sessionStore.status,
            sessions: state => state.sessionStore.data,
            sessionMeta: state => state.sessionStore.meta,
            list: state => state.sessionStore.list,
            selectedTryout: state => state.navigatorStore.selectedTryout,
        }),
        loading: function() {
            return this.status.loading
        },
        formattedStartTime: {
            get() {
                return this.formatTime(this.newstartTime)
            },
            set(newValue) {
                this.newstartTime = newValue
            }
        },
        changes() {
            return Object.keys(this.changeTracker).length>0 || this.orderChanges
        }

    },
    methods: {
        ...mapActions('sessionStore', { 
            findAllSessionsForEvent: 'findAllForEvent', 
            addSession : 'addSession' ,
            updateSession : 'updateSession',
        }),
        ...mapActions('criterionStore', {
            findAllCriteriaForEvent: 'findAllForEvent',
        }),
        ...mapActions('activityStore', {
            findAllActivitiesForEvent: 'findAllForEvent'
        }),
        formatTime: function(t) {
            return t

        },
        
        allowedStep: m => m % 15 === 0,

        create: function (reset) {

            reset()

                const fmtStartDateTime = new Date(this.newdate +"T" + this.newstartTime)
                const fmtEndDateTime = new Date(this.newdate +"T" + this.newendTime)

                const newSession = {
                    eventId: this.selectedTryout,
                    title: this.newName,
                    venue: this.newVenue,
                    startDateTime: fmtStartDateTime,
                    endDateTime: fmtEndDateTime,
                }
                this.addSession(newSession)
                this.newName=''
                this.newVenue=''
                this.newdate=null
                this.newdate = moment(Date.now()).format("YYYY-MM-DD")

                this.newstartTime='09:00:00'
                this.newendTime='10:00:00'


                this.dialog=false

        },
        closeDialog(reset){
            reset()
            this.newName=''

            this.newVenue=''
            this.newdate = moment(Date.now()).format("YYYY-MM-DD")
            this.newstartTime='09:00:00'
            this.newendTime='10:00:00'

            this.dialog=false
        },
        date(sessionId) {
            return this.sessionMeta[sessionId].date
        },
        startTime(sessionId) {
//            this.$refs.starttimepicker.selectingHour=true
            return this.formatTime(this.sessionMeta[sessionId].startTime)
        },
        saveStartTime: function(value) {
            this.newstartTime = value
        },
        saveEndTime: function(value){
            this.newendTime = value
        },
        saveDetails(){
            // Save all the questions that have been changed...

            for(var idx in this.sessions) {
                var sessionId = this.sessions[idx].id
                this.updateSession(this.sessions[sessionId])
                this.orderChanges = false
                this.changeTracker={}
                this.$root.$emit("settings-sessions-clear-changes-flags")
                this.$root.$emit("settings-changes-cleared", this.componentId)
                this.$store.dispatch('alert/success', 'Tryout updated', {root: true})
            }
        }


    },
    async created(){
        await this.findAllCriteriaForEvent(this.selectedTryout)
        await this.findAllSessionsForEvent(this.selectedTryout)
        this.newdate = moment(Date.now()).format("YYYY-MM-DD")
    },
    mounted(){
        this.$root.$on('tryoutRefresh', this.refreshHandler)

        this.$root.$on('settings-sessions-changes-cleared', (id)=> {
            var self=this
            self.$delete(self.changeTracker, id)
        }),
        this.$root.$on('settings-sessions-changes-made', (id)=> {
            var self=this
            self.$set(self.changeTracker, id, true)
        })
        this.$root.$on('submit-all-changes', () => {
            if(this.changes){
                this.saveDetails()
            }
        })

        this.$root.$on('backout-all-changes', async () => {
            var self=this
            if(this.changes){
                await this.findAllCriteriaForEvent(this.selectedTryout)
                await this.findAllSessionsForEvent(this.selectedTryout)
                self.changes=false
            }
        })
    },
    beforeRouteLeave(to, from, next) {
        if (Object.keys(this.changeTracker).length) {
            if (!window.confirm("Leave without saving?")) {
                return;
            }
        }
        next();
    },
    beforeDestroy () {
        this.$root.$off('tryoutRefresh', this.refreshHandler)
        if(Object.keys(this.changeTracker).length){
            const answer = window.confirm('There are unsaved changes - click "OK" to save these before leaving, or "Cancel" to exit without saving ')
            if (answer) {
                this.saveDetails()
            } 

        }
    },
    data() {
        return {
            refreshHandler: async () => {
                await this.findAllCriteriaForEvent(this.selectedTryout)
                await this.findAllSessionsForEvent(this.selectedTryout)
            },
            changeTracker: {},
            rules: rules,
            dialog: false,
            showDate: false,
            showStart: false,
            showEnd: false,
            newName: '',
            newVenue: '',
            newdate: Date.now(),
            newstartTime: '09:00:00',
            newendTime: '10:00:00',


        }
    },
}

</script>

<style>
/* Default override (not using "append-to-body") */
.vue__time-picker .dropdown ul li:not([disabled]).active {
  background: blue;
}

/* When using "append-to-body" */
.vue__time-picker-dropdown ul li:not([disabled]).active {
  background: blue;
}

</style>