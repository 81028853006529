<template>
    <div class="attribute-card">
        <v-row>
            <v-col cols="6" class="measure-col">
                <v-text-field
                    v-model="value"
                    :label="attributes[attributeId].title"
                />
            </v-col>
            <v-col cols="4" class="unit-col">
                <span class="units">{{ attributes[attributeId].units }}</span>
            </v-col>
            <v-spacer/>
        </v-row>
      
    </div>
</template>

<script>
import {mapState , mapActions} from 'vuex'
//import Loading from '@/components/loading'

export default {
    props: ['attributeId','participantId'],
    components: {
//        Loading
    },
    computed: {
        ...mapState({
            playerattributes: state => state.playerattributeStore.data,
            playerattributeList: state=> state.playerattributeStore.list,
            attributes: state => state.attributeStore.data,
            attributeStatus: state => state.attributeStore.status,
            account: state => state.account,
            selectedTryout: state => state.navigatorStore.selectedTryout,

        }),
        loading: function() {
            return this.attributeStatus.loading
        },
        value: {
            get() {
                if(this.participantId && this.participantId !== 0){
                    const value = this.playerattributes[this.participantId].attributes[this.attributeId].value
                    return value
                } else {
                    return 0
                }
            },
            //eslint-disable-next-line
            set(value) {
                if(this.participantId && this.participantId !== 0) {
                    const data = {
                        participantId: this.participantId,
                        attributeId: this.attributeId ,
                        eventId: this.selectedTryout,
                        value: value
                    }
                    this.updateValue(data)
                }
            }
        },
        description: {
            get() {
                if(this.criterionId && this.criterionId !== 0) {
                    return this.criteria[this.criterionId].decription
                } else {
                    return ""
                }
            }
        },
        criterionLoading: function () {
            if (this.criterionId && this.criterionId !== 0 && this.criterionStatus[this.criterionId]){
                return (this.criterionStatus[this.criterionId].loading )
            } else {
                return false
            }
        }

    },
    methods: {
        ...mapActions('playerattributeStore', {
            updateValue: 'updateValue'
        })
    },
    created() {
    },
    data() {
        return {
            icons: [
                "mdi-numeric-0-circle", 
                "mdi-numeric-1-circle", 
                "mdi-numeric-2-circle", 
                "mdi-numeric-3-circle", 
                "mdi-numeric-4-circle", 
                "mdi-numeric-5-circle"
                ],
            iconColor: [
                "gray",
                "red",
                "orange",
                "yellow",
                "lime",
                "light-green accent-3"
            ],
            progressColor : [
                "red",
                "red",
                "orange",
                "light-green accent-3"
            ]
        }
    },
    watch: {
        
    }

}

</script>

<style>

.col {
    margin-top: auto
}

.attribute-card {
    display: flex;
}

.unit-col{
    margin-top: 0px;
    margin-bottom: auto;
    text-align: left;
}

</style>