<template>
     <div style="text-align:left;padding: 0px 10px 0px 10px">
        <ValidationObserver>
            <v-row v-if="false">
                <v-spacer/>
                    <v-col cols="2" style="text-align:right">
                        <v-btn @click.native.stop 
                            v-if="changes" 
                            x-small 
                            v-on:click="saveDetails" 
                            depressed 
                            color="primary" 
                            class="save-btn"
                        >
                            Save
                        </v-btn>
                    </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-text-field 
                        label="Contact Name"
                        hide-details="auto"
                        v-model="contactName"
                    />
                </v-col>
                <v-col cols="12">
                    <ValidationProvider name="Contact email address" rules="required|email" v-slot="{ errors }">
                        <v-text-field label="Contact Email Address" hide-details="auto" v-model="contactEmail"></v-text-field>
                        <div style="color:red;text-align:left;">{{ errors[0] }}</div>
                    </ValidationProvider>
                </v-col>
                <v-col cols="12">
                    <ValidationProvider name="Contact phone number" :rules="{mobileNumber: {code: 'AU'}}" v-slot="{ errors }">
                        <v-text-field label="Contact Phone Number" hide-details="auto" v-model="contactPhone"></v-text-field>
                        <div style="color:red;text-align:left;">{{ errors[0] }}</div>
                    </ValidationProvider>
                </v-col>
            </v-row>
        </ValidationObserver>
    </div>
</template>

<script>

import {mapState, mapActions} from 'vuex'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {rules} from '@/_helpers/rules'
import {v4 as uuidv4} from 'uuid';


export default {
    props: [ ],
    components: {
        ValidationObserver,
        ValidationProvider,
    },
    computed: {
        ...mapState({
            tryouts: state => state.tryoutStore.data,
            tryoutStatus: state => state.tryoutStore.status,
            selectedTryout: state => state.navigatorStore.selectedTryout, 
        }),
        noContactDetails: function () {
            return (!this.tryouts[this.selectedTryout].contactName)
                || (!this.tryouts[this.selectedTryout].contactEmail)
                || (!this.tryouts[this.selectedTryout].contactPhone)
        },
        loading: function() {
            return this.tryoutStatus.loading
        },
        contactName: {
            get() {
                return this.tryouts[this.selectedTryout].contactName
            },
            set(newValue) {
            this.$root.$emit("settings-changes-made", this.componentId)
                this.changes=true
                this.updateTryoutProp({
                    field: 'contactName',
                    id: this.selectedTryout,
                    value: newValue
                })
            }
        },
        contactEmail: {
            get() {
                return this.tryouts[this.selectedTryout].contactEmail
            },
            set(newValue) {
                this.changes=true
            this.$root.$emit("settings-changes-made", this.componentId)
                this.updateTryoutProp({
                    field: 'contactEmail',
                    id: this.selectedTryout,
                    value: newValue
                })
            }
        },
        contactPhone: {
            get() {
                return this.tryouts[this.selectedTryout].contactPhone
            },
            set(newValue) {
                this.changes=true
            this.$root.$emit("settings-changes-made", this.componentId)
                this.updateTryoutProp({
                    field: 'contactPhone',
                    id: this.selectedTryout,
                    value: newValue
                })
            }
        }

    },
    mounted(){
        this.$root.$on('settings-clear-changes-flags', ()=> {
            var self=this
            self.changes=false
        })
        this.$root.$on('submit-all-changes', () => {
            if(this.changes){
                this.saveDetails()
            }
        })

        this.$root.$on('backout-all-changes', () => {
            if(this.changes){
                this.loadTryout(this.selectedTryout)
            }
        })
    },
    methods: {
        ...mapActions('navigatorStore', {
            selectClub: 'selectClub',
        }),
        ...mapActions('tryoutStore', {
            updateTryoutProp: 'update',
            updateTryout: 'updateTryout',
            loadTryout: 'loadTryout',
        }),
        async saveDetails(){
            this.changes=false
            this.updateTryout(this.tryouts[this.selectedTryout])
            this.$root.$emit("settings-changes-cleared", this.componentId)
            this.$store.dispatch('alert/success','Tryout Updated', {root: true})
        }
    },
    created(){    
        this.componentId=uuidv4()

    },
    data() {
        return {
            rules: rules,
            changes: false,

        }
    }
}

</script>

<style>

</style>