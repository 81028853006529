<template>
            <v-card>
            <v-card-title>
                <v-row v-if="!small">
                    <v-col cols="8" style="margin-top:auto;margin-bottom:auto;overflow-wrap:break-word;">
                        Application Review for {{ participants[participantId].firstName }}<wbr> {{ participants[participantId].lastName }}
                    </v-col>
                    <v-col>
                        <participant-profile-image :participantId="participantId" size="100px"></participant-profile-image>
                        
                    </v-col>
                </v-row> 
            </v-card-title>
            <v-card-text>
                <v-row v-if="!small">
                    <v-col class="info-line">
                        <span class="info-head">Status:</span> {{ participants[participantId].archived?"Withdrawn":"Active"}}
                    </v-col>
                </v-row>
                <v-row v-if="!small">
                    <v-col class="info-line">
                        <span class="info-head">Attended:</span> {{ participants[participantId].attended }}
                    </v-col>
                </v-row>
                <v-row v-if="!small">
                    <v-col class="info-line">
                        <span class="info-head">Attendance Notes:</span> {{ participants[participantId].absenceReason }}
                    </v-col>
                </v-row>
                <v-row v-if="!small">
                    <v-col class="info-line">
                        <span class="info-head">Standing Offer:</span> {{ participants[participantId].teamName?participants[participantId].teamName: "Waitlist" }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-divider/>
                <h3 v-if="!small">Application Responses</h3>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th class="text-left">
                                            Question
                                        </th>
                                        <th class="text-left">
                                            Player Response
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="questionId in questionList" :key="questionId">
                                        <td>
                                            <span style="font-weight:600">Q{{questions[questionId].order+1}}</span>
                                        </td>
                                        <td class="text-left">
                                            {{questions[questionId].question}}
                                        </td>
                                        <td v-if="formResponses[participantId][questionId]" class="text-left">
                                            {{formResponses[participantId][questionId].response}}
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>

            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                    <v-btn v-if="!small" color="primary" text @click="closeDialog">Close</v-btn>
            </v-card-actions>
        </v-card>
</template>

<script>

import { mapState, mapActions } from 'vuex';
import ParticipantProfileImage from './ParticipantProfileImage.vue';

export default {
    props: ['participantId', 'selectedSession', 'small','closeFn'],
    components: {
        ParticipantProfileImage,
    },
    computed: {
        ...mapState({
            questionList: state => state.appquestionStore.list,
            questions: state => state.appquestionStore.data,
            participants: state => state.participantStore.data,
            participantStatus: state => state.participantStore.status,

            formResponses: state => state.qresponseStore.byParticipant,
            qresponseStatus: state => state.qresponseStore.status,

            tryoutStatus: state => state.tryoutStore.status,
            navInitialising: state => state.navigatorStore.initialising,
        }),
       loading: {
            get() {
                return false
                    || this.participantStatus.loading
                    || this.tryoutStatus.loading
                    || this.navInitialising
                    || this.selectedTryout===''
                    || this.qresponseStatus.loading
            }
        },
    },
    methods: {
        ...mapActions('qresponseStore',{
            findResponses: 'findAllForParticipant',
        }),
        closeDialog(){
            this.closeFn()
        }
    },
    created(){
    },
    data(){
        return {
            showDialog: false
        }
    }
}

</script>
<style scoped>
.info-line{
    margin: 0px 10px 0px 10px;
    padding: 0px 0px 0px 0px;
    text-align: left;
}
.info-head{
    font-family: 'Varela round';
    font-weight: 600;

}
</style>