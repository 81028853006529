import {sendMessage} from '@/_helpers/service-tools'

export const sessionService = {
    findAllForEvent,
    addSession,
    updateSession,
    deleteSession,
    findOne,
    checkinSession,
    loadAllSessionsForTeam,
}

async function findOne(payload) {
    return await sendMessage('GET', 'sessions', {sessionId: payload.sessionId}, null)
}

async function loadAllSessionsForTeam(payload) {
    const response = await sendMessage('GET', 'sessions/loadAllSessionsForTeam', { teamId: payload.id}, null)
    return response
}

async function findAllForEvent(payload) {
    return await sendMessage('GET', 'sessions/list', {eventId: payload.id}, null)
}

async function addSession(session) {
    return await sendMessage('POST', 'sessions', null, session)
}

async function updateSession(session) {
    return await sendMessage('PATCH', 'sessions', {id: session.id}, session)
}

async function deleteSession(sessionId) {
    return await sendMessage('DELETE', 'sessions', {id: sessionId}, null)
}

async function checkinSession(payload) {
    const txPayload = {
        sessionId: payload.sessionId,
        userId: payload.userId
    }
    if(Object.prototype.hasOwnProperty.call(payload, 'participantId')) txPayload.participantId = payload.participantId
    return await sendMessage('PATCH', 'sessions/checkin', txPayload)
}