import { criterionService } from "../_services/criterion.service"
import Vue from 'vue'

function initialiseState(){
    return {
        status: {},
        data: {},
        meta: {},
        list: [],
        sessions: {},
        internal: {},
        error: null
    }
}

const state = initialiseState()

const actions = {
    purgeData({commit}){
        commit('purgeData')
    },

    async findAllCriteriaForEvent({dispatch, commit}, payload) {
        try{
            commit('findAllForEventRequest')
            const response = await criterionService.findAllForEvent(payload)
            commit('findAllForEventSuccess', response)
        } catch(error) {
            commit('findAllForEventFailure', error)
            dispatch('alert/error', error, {root:true})
        }
    },
    // RETIRE
    async findAllForEvent({dispatch, commit}, id) {
        try{
            commit('findAllForEventRequest')
            const response = await criterionService.findAllForEvent({eventId: id})
            commit('findAllForEventSuccess', response)
        } catch(error) {
            commit('findAllForEventFailure', error)
            dispatch('alert/error', error, {root:true})
        }
    },
    async quietFindAllForEvent({dispatch, commit}, id) {
        try{
            commit('quietFindAllForEventRequest')
            const response = await criterionService.findAllForEvent({eventId: id})
            commit('findAllForEventSuccess', response)
        }catch(error) {
            commit('findAllForEventFailure', error)
            dispatch('alert/error', error, {root:true})
        }
    },
    async addCriterion({dispatch, commit}, criterion) {
        try{
            commit('addCriterionRequest')
            // Convert the context array into JSON
            const contextString = JSON.stringify(criterion.context)
            criterion.context = contextString
            const response = await criterionService.addCriterion(criterion)
            commit('addCriterionSuccess', response)
            dispatch('alert/success', 'Your new criterion has been added', {root: true})
            return response
        } catch(error) {
            commit('addCriterionFailure', error)
            dispatch('alert/error', error, {root: true})
        }
    },
    async deleteCriterion({dispatch, commit}, payload) {
        try{
            commit('deleteCriterionRequest', payload.criterionId)
            const response = await criterionService.deleteCriterion(payload)
            setTimeout(() => {
                commit('deleteCriterionSuccess', response)
                dispatch('alert/success', 'Your new criterion has been added', {root: true})
            })
            return response
        } catch(error) {
            commit('deleteCriterionFailure', error)
            dispatch('alert/error', error, {root: true})
        }
    },
    async updateCriterion({commit, dispatch}, criterion) {
        try{
            commit ('updateCriterionRequest')
            // Convert the context array into JSON
            const contextString = JSON.stringify(criterion.context)
            criterion.context = contextString
            await criterionService.updateCriterion(criterion)
            setTimeout(() => {
                commit('updateCriterionSuccess')
            })
        } catch(error) {
            commit('updateCriterionFailure')
            dispatch('alert/error', error, {root: true})
        }
    },
    updateListOrder({commit}, list) {
        commit('updateListOrder', list)
    },
    update({commit}, payload) {
        commit('update', payload)
    },
    async archiveCriterion({commit, dispatch}, criterionId) {
        try{
            commit('archiveRequest')   
            await criterionService.archiveCriterion(criterionId)
            commit('archiveSuccess')
        } catch(error) {
            commit('archiveError')
            dispatch('alert/error', error, {root: true})
        }
    }
}

function buildCriterion(element) {
    const id = element.id

    // Convert the context string into an array

    const contextList = JSON.parse(element.context)
    element.context = contextList

    state.list.push(id)
    //Vue.set(state.criteria, id, element)
    Vue.set(state.data, id, element)
    Vue.set(state.meta, id, {
        archive: false,
    })
}

function sortListByOrder() {
    state.list.sort((a,b) => {
        return state.data[a].order - state.data[b].order
    })
}

const mutations = {
purgeData(state){
    const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }
    },
    // Archive

    archiveRequest(state, criterionId) {
        state.status = {deleting:true, id: criterionId}
        state.error = null
    },
    archiveSuccess(state) {
        delete state.data[state.status.id]
        delete state.meta[state.status.id]

        const index=state.list.indexOf(state.status.id)
        state.list.splice(index,1)
    },
    archiveFailure(state, error) {
        state.status={}
        state.error = error
    },

    // ------------- FindAllForEvent ------------- \
    
    findAllForEventRequest(state) {
        state.status={loading: true}
        state.error=null
    },
    quietFindAllForEventRequest(state) {
        state.status={}
        state.error=null
    },
    findAllForEventSuccess(state, criteria) {
        state.list=[]
        state.data={}
        
        for (var idx in criteria) {
            buildCriterion(criteria[idx])
        }
        sortListByOrder()
        state.status={}
    },
    findAllForEventFailure(state, error) {
        state.status={}
        state.error = error
    },

    // ------------- CREATE ------------- \
    
    addCriterionRequest(state) {
 //       state.status={loading: true}
        state.error=null
    },
    addCriterionSuccess(state, payload) {
//        state.status={}
        buildCriterion(payload.criterion)
        sortListByOrder()
    },
    addCriterionFailure(state, error) {
        state.status={}
        state.error = error
    },

    //---------- UPDATE DATA -----------\\

    update(state, payload) {
        const metaFields = "archive"
        const {field, id, value} = payload

        if(metaFields.includes(field)){
            state.meta[id][field]=value
            // Manage special fields...
            //switch (field) {
            //}
        } else {
            state.data[id][field]=value
        }
    },

    // -------- UPDATE LIST ORDER ------ \\

    updateListOrder(state, list) {
        state.list = list
        var count=0
        for (var idx in state.list) {
            const id = state.list[idx]
            state.data[id].order = count++
        }
    },

    // ------------- UPDATE CRITERION ------------- \
    
    updateCriterionRequest(state) {
//        state.status={loading: true}
        state.error=null
    },
    updateCriterionSuccess(state) {
       state.internal={}
    },
    updateCriterionFailure(state, error) {
        state.status={}
        state.error = error
    },

    
    //--------------------------------------------
    //   Mutations for deleteCriterion
    //
    
    deleteCriterionRequest(state, criterionId){
        Vue.set(state.status, 'loading', true)
        Vue.set(state.internal, 'target', criterionId)
        state.error=null
    },
    deleteCriterionSuccess(state, response){
        state.status = {loading: true, responseCode: response.data.code}
    
        // Remove from the data set and from the lists
        const list = [...state.list]

        var index = list.indexOf(state.internal.target);
        if (index > -1) {
          list.splice(index, 1);
        }

        Vue.set(state, 'list', list)

        Vue.delete(state.data, state.internal.target)
        Vue.delete(state.status,'loading')
        Vue.delete(state.internal, 'target')
    },
    deleteCriterionFailure(state, error){
        state.error=error
    },


}

export const criterionStore = {
    namespaced: true,
    state,
    actions,
    mutations
}