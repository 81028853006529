import { userService } from '../_services'
import { router } from '../_helpers'
import { rc_auth} from '@/_helpers/responseCodes'
import Vue from "vue"

function initialiseState(){
    const localUser = localStorage.getItem('user')

    const user = (localUser !== null && localUser !== "undefined")?JSON.parse(localUser):null

    const state = user 
        ? { status: { loggedIn: true}, user, step: 1, complete:false, internal:{}}
        : { status: {}, user: null , step: 1, complete:false, internal:{}}
    return state
}

const state = initialiseState()

const actions = {
    purgeData({commit}){
        commit('purgeData')
    },
    nextStep({commit}){
        commit('nextStep')
    },
    lastStep({commit}){
        commit('lastStep')
    },
    setStep({commit}, step){
        commit('setStep', step)
    },
    setCompletion({commit},payload){
        const {complete} = payload
        commit('setCompletion', complete)
    },
    async validate({commit}, {userId}){
        commit('validateRequest', {userId})

        try{

            const response = await userService.validate({userId})

            commit('validateSuccess', response)
            return response
        } catch(error) {
                commit('validateFailure', error)
                throw(error)
        }
    },
    async completeUser({commit}, payload){
        commit('completeUserRequest')
        try {
            const response = await userService.completeUser(payload)
            commit('completeUserSuccess', response)
            return response
        } catch (error) {
            commit('completeUserFailure')
            throw(error)
        }
    },
    //eslint-disable-next-line
    async login({dispatch, commit}, {username, password, only, socket, userId}) {
        if(!only)
            commit('loginRequest', {username});
        else
            commit('qLoginRequest', {username});

        try{
            const user = await userService.login({username, password, userId})        
            if(!only)
                commit('loginSuccess', user)
            else
                commit('qLoginSuccess', user)

            const redirectPath = sessionStorage.getItem('redirectPath')

            //Cleanup redirectPath
            sessionStorage.removeItem('redirectPath');

            dispatch('globalsettingsStore/load',null,{root:true})

            if(!only){
                if(redirectPath) {
                    router.push(redirectPath)
                } else {
                    router.push('/');
                }
            }
            return
        } catch(error) {
            if(!only)
                commit('loginFailure', error)
            else
                commit('qLoginFailure', error)

                const networkStatus = localStorage.getItem('networkStatus')
                if(networkStatus){
                    if(error.message === 'This user account is not validated'){
                        Vue.$toast.error('Your account has not been validated yet. Please check your email and click on the activation link we sent you.')
                    } else {
                        Vue.$toast.error('Login failed. Please check your username and password before trying again.')
                        //dispatch('alert/error', error, {root: true})
                    }
                }

        }

    },
    async otpLogin({commit, dispatch}, payload){
        try {
            const {userId, otp,} = payload
            var email=null

            var only=false
            if(Object.prototype.hasOwnProperty.call(payload, 'only')) only=payload.only
            if(Object.prototype.hasOwnProperty.call(payload, 'email')) email=payload.email

            commit('otpLoginRequest')
            const response = await userService.otpLogin({userId, otp, email})
            commit('otpLoginSuccess', response)
            const redirectPath = sessionStorage.getItem('redirectPath')
            sessionStorage.removeItem('redirectPath')

            if(response.data.code === rc_auth.LOGIN_OK){
                localStorage.setItem('user', JSON.stringify(response.data.result))
                localStorage.setItem('jwt', response.data.result.token)
                dispatch('globalsettingsStore.load', null, {root:true})

                if(!only){
                    if(redirectPath){
                        router.push(redirectPath)
                    } else {
                        router.push('/')
                    }
                }
            }

            return response

        } catch (error) {
            commit('otpLoginFailure', error)
            console.error('Auth::otpLogin')
            console.error(error)
        }
    },
    forceLogin({commit}, payload){
        commit('loginSuccess', payload.user)
        router.push('/')
    },
    logout({commit}) {
        userService.logout();
        commit('logout')
    },
    async register({commit}, user) {
        commit('registerRequest', user)
        try{
            const newuser = await userService.register(user)
            commit('registerSuccess', newuser);
            return {code:200, useer: newuser}
//            router.push('/checkyouremail');
        } catch(error){
            if(error.message==="The email address is already registered") {
                Vue.$toast.error('The email address you provided has already been registered. You can reset your password if you have forgotten it.')
                commit('registerFailure', error)
                return {code:400, msg: 'Email exists'}
            } else {
                commit('registerFailure', error)
                Vue.$toast.error('Registration failed. Please check the information you have provided.')
                return {code:500}
            }
        }
    },
    resend({dispatch}, email) {
        try{
            userService.resend(email)
        } catch(error){
            setTimeout(() => {
                dispatch('alert/error', error, {root: true})
            })
        }
    },
    update({dispatch, commit}, user) {
        try{
            commit('updateRequest', user)

            const response = userService.update(user)
            commit('updateSuccess', response)
        } catch (error) {
            commit('updateFailure', error)
            dispatch('alert/error', error, {root: true})
        }
    } ,
    recover({commit, dispatch}, payload) {
        try{

            const {email, recaptchaToken } = payload
            commit('recoverRequest')
        
            userService.recover(email, recaptchaToken)
            commit("recoverSuccess")
            router.push('/checkyouremail')
        } catch(error) {
            commit("recoverFailure", error)
            dispatch('alert/error', error, {root: true})
        }
    },
    async reset({commit, dispatch}, payload) {
        try{
            const {user, token, direct} = payload
            commit('resetRequest')
            
            const response = await userService.reset(user, token)
            commit("resetSuccess")
            if(!direct){
                router.push('/login')
                setTimeout(() => {
                    dispatch('alert/success', "Your password has been reset. Sending you to the login page now...")
                })
            } else {
                dispatch('account/login', {username: response.email, password: user.password}, {root:true})
            }
        } catch(error) {
            commit("resetFailure", error)
            dispatch('alert/error', error, {root: true})
        }
    },
    async sendOTP({commit}, payload){
        try {
            commit('sendOTPRequest')

           const response = await userService.sendOTP(payload)

            commit('sendOTPSuccess')
            return response
        } catch(error){
            commit("sendOTPFailure")
            console.error(error)
        }
    },
    async verifyOTP({commit}, payload){
        try{
            commit('verifyOTPRequest')
            const response = await userService.verifyOTP(payload)
            commit('verifyOTPSuccess')
            return response
        } catch(error) {
            commit('verifyOTPFailure')
            console.error(error)
        }
    },
    
    propertyUpdate({commit},payload) {
        commit('propertyUpdate', payload)
    }

}

const mutations = {
    sendOTPRequest(){
       
    },
    sendOTPSuccess(){

    },
    sendOTPFailure(){

    },
    verifyOTPRequest(){
       
    },
    verifyOTPSuccess(){

    },
    verifyOTPFailure(){

    },
    purgeData(state){
    const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }
    },
    nextStep(state){
        state.step=state.step+1
    },
    lastStep(state){
        if(state.step>0) state.step = state.step-1
    },
    setStep(state, newStep){
        state.step=newStep
    },
    setCompletion(state, complete){
        state.complete = complete
    },

    resetRequest(state) {
        state.status = {loading: true}
    },
    resetSuccess(state) {
        state.status = {}

    },
    resetFailure(state, error) {
        state.status={}
        state.error=error
    },
    recoverRequest(state) {
        state.status = {loading: true}
    },
    recoverSuccess(state) {
        state.status = {}

    },
    recoverFailure(state, error) {
        state.status={}
        state.error=error
    },
    


    otpLoginRequest(state){
        state.error=null
        state.user={}
    },
    otpLoginSuccess(state, payload){
        state.user = payload.data.result
    },
    otpLoginFailure(state, error){
        state.error = error
    },



    qLoginRequest(state, user) {
        state.internal.user=user
    },
    qLoginSuccess(state, user) {
        state.user = user;
    },
    qLoginFailure(state, error) {
        state.error = error
    },
    loginRequest(state, user) {
        state.status = { loggingIn: true};
        state.user = user;
    },
    loginSuccess(state, user) {
        state.user = user;
        state.status = { loggedIn: true}
    },
    loginFailure(state, error) {
        state.status = {}
        state.user = null
        state.error = error
    },
    logout(state) {
        state.status = {}
        state.user = null
    },
    validateRequest(state, data){
        state.status.generated=false
        state.internal.userId = data.userId
        state.error={}
    },
    validateSuccess(state, response){
        state.status.generated=response.generated
        state.status.userId = response.userId
    },
    validateFailure(state, error){
        state.error = error
    },
    completeUserRequest(state){
        state.error={}
    },
    completeUserSuccess(state){
        state.error={}
    },
    completeUserFailure(state, error){
        state.error=error
    },

    registerRequest(state) {     //, user) {
        state.status = {registering: true}
    },
    registerSuccess(state) { //    , user) {
        state.status = { registered: true}
    },
    registerFailure(state) { //, error) {
        state.status = {}
    },
    updateRequest(state) {
        state.internal = {updating: true}
    },
    updateSuccess(state) {
        state.internal = {updated: true}
    },
    updateFailure(state) {
        state.internal = {}
    },
    //------------ update data -------- \\
    propertyUpdate(state, payload) {
        const {field, value} = payload
        state.user.details[field]=value
        localStorage.setItem('user', JSON.stringify(state.user));
    }

}
export const account={
    namespaced: true,
    state,
    actions,
    mutations
}