import { seasonService } from '../_services'
import Vue from 'vue'

const dataService = seasonService

function initialiseState(){
    return {
        status: {},
        data: {},
        list: [],
        error: null,
        cache: {},
        meta: {}
    }
}

const state = initialiseState()

const actions = {
    purgeData({commit}){
        commit('purgeData')
    },
    async loadSeason({commit}, payload) {
        try{
            const {seasonId} = payload
            commit('loadRequest')
            const response = await dataService.find({seasonId})
            commit('loadSuccess', response)
            return response
        } catch(error) {
            commit('loadFailure', error)
        }
    },
    async findAllForClub({commit}, payload) {
        try{
            const { clubId , isAdmin} = payload
            commit('loadRequest')
            const response = await dataService.findAllForClub({clubId, isAdmin})
            commit('loadSuccess', response)
        } catch(error) {
            commit('loadFailure', error)
        }
    },
    async create({commit, dispatch}, payload) {
        try{
            const {season} = payload
            commit('createRequest')
            const response=await dataService.addSeason(season)
            commit('createSuccess', response)
        } catch(error) {
            commit('createFailure', error)
            dispatch('alert/error',error, {root: true})
        }
    },
    async update({commit, dispatch}, payload) {
        try{
            const {season} = payload
            commit('updateRequest', payload)
            await dataService.updateSeason(season)
//                    commit('updateSuccess', response)
        } catch(error) {
            commit('updateFailure', error)
            dispatch('alert/error', error, {root: true})
        }
    },
    propertyUpdate({commit}, payload) {
        commit('propertyUpdate', payload)
    }
}

function addToLists(data) {
    const id = data.id
    state.list.push(id)
    Vue.set(state.data, id, data)
    Vue.set(state.meta, id, {delete: false})
}

function sortListByOrder() {
    state.list.sort((a,b) => {
        return (''+state.data[a].startDate).localeCompare(state.data[b].startDate)
    })
}

const mutations = {
purgeData(state){
    const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }
    },
    loadRequest(state) {
        state.status={loading: true}
        state.error=null
    },
    loadSuccess(state, data) {
        state.data={}
        state.list=[]
        for (var idx in data) {
            addToLists(data[idx])
        }
        sortListByOrder()
        state.status={}
    },
    loadFailure(state, error) {
        state.status={}
        state.error=error
    },

    createRequest(state) {
        state.status={loading: true}
        state.error=null
    },
    createSuccess(state, data) {
        state.status={updated: true}
        addToLists(data.season)
        sortListByOrder()
        state.error=null
    },
    createFailure(state, error) {
        state.status={}
        state.error=error
    },

    updateRequest(state) {
 //       state.status={loading: true}
        state.error=null
    },
    updateSuccess(state, data) {
        state.status={updated: true, data: data}
        state.error=null
    },
    updateFailure(state, error) {
        state.status={}
        state.error = error
    },
    propertyUpdate(state, payload) {
        const metaFields = "delete"
        const {field, id, value} = payload

        if(metaFields.includes(field)){
            state.meta[id][field]=value
            // Manage special fields...
            //switch (field) {
            //}
        } else {
            state.data[id][field]=value
        }
    }
}

export const seasonStore = {
    namespaced: true,
    state,
    actions,
    mutations
}