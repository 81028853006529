<template>
    <loading v-if="loading"/>
    <v-card v-else elevation="5" rounded style="margin:10px!important;padding: 10px!important;">
        <ValidationObserver v-slot="{}">
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <div style="display: flex; flex-flow: row;">
                            <img height="50" :src="clubs[selectedClub].logo"/>
                            <div>
                                <div style="text-align:center">
                                    <h3>{{ events[selectedEvent].title }}</h3>
                                </div>
                                <div>
                                    {{ seasons[events[selectedEvent].seasonId].name }}
                                </div>
                            </div>

                        </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" style="margin-top:auto;margin-bottom:auto">
                        <h3>
                            {{ capitalize(events[selectedEvent].episodeFormName) }}
                        </h3>
                    </v-col>
                </v-row>
                <!----------------->
                <v-expansion-panels style="margin-top:10px;margin-bottom:20px;">
                    <v-expansion-panel elevation="10" style="background-color: ghostwhite">
                        <v-expansion-panel-header>
                            <h3>Session Details</h3>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row class="">
                                <v-col cols="6" sm="6" md="6" lg="4" xl="4">
                                    <v-text-field prepend-icon="mdi-clipboard-list-outline" label="Coach" readonly v-model="coachName" :tabIndex="0"/>
                                </v-col>
                            </v-row>
                            <v-row class=""> 
                                <v-col cols="6" sm="6" md="6" lg="3" xl="3" style="padding-right:10px;">
                                    <v-text-field
                                        v-model="startDate"
                                        dense
                                        hide-details="true"
                                        :label="capitalize(events[selectedEvent].eventNoun) +' Date'"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        :tabIndex="0"
                                    />
                                </v-col>
                                <v-col cols="6" sm="6" md="6" lg="3" xl="3" class="">
                                    <v-text-field
                                        dense
                                        hide-details="true"
                                        v-model="startTime"
                                        label="Evaluation Time"
                                        prepend-icon="mdi-clock-time-four-outline"
                                        readonly
                                        :tabIndex="0"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6" sm="6" md="6" lg="4" xl="4">
                                </v-col>
                            </v-row>
                            <v-row class="">
                                <v-col v-for="questionId in questionListByContext['devform']" :key="questionId" cols="6" sm="6" md="6" lg="6" xl="6" clastion-area="question-area">
                                    <QuestionRender :readonly="!userIsAuthor" :qid="questionId" :value="getResponse(selectedEpisode, questionId)" />
                                </v-col>
                            </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
    <!---------------->               
                <v-row>
                    <v-col cols="6">
                        <v-select 
                            elevation="5" 
                            :label="capitalize(events[selectedEvent].participantPronoun)" 
                            :items="activeParticipants" 
                            item-text="name" 
                            item-value="participantId" 
                            v-model="selectedParticipant" 
                            :tabIndex="0" 
                            style="background-color:ghostwhite;border:solid gainsboro 1px;border-radius:5px;box-shadow: 1px 3px 15px -5px rgba(0, 0, 0, 0.2), -2px 1px 2px -3px rgba(0, 0, 0, 0.14), 2px 1px 5px 1px rgba(0, 0, 0, 0.12);"/>
                    </v-col>
                </v-row>
                <v-row v-if="selectedParticipant !== null" style="margin-top:10px;">
                    <v-tabs v-model="tab" show-arrows fixed-tabs >
                        <v-tab v-for="item in dataPanels" :key="item.key" :href="item.href">
                            <template v-slot:default>
                                <div v-responsive.md.lg.xl >
                                {{ item.text }}
                                </div>
                                <div v-responsive.sm.xs style="font-size:x-small;">
                                {{ item.text }}
                                </div>
                            </template> 
                        </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab" style="width:100%">
                        <v-tab-item key="1" value="tab-1">
                            <!-- Render sliders for authors and status indicators for others...-->
                            <div v-if="userIsAuthor">

                                <div v-if="miniScreen" style="margin-top:20px;">
                                    <div v-if="orient==='landscape'" class="sm-criteria-container">
                                        <v-row v-if="orient==='landscape'">
                                            <v-col v-for="criterionId in selectedCriterionList" :key="criterionId" >
                                                    <skill-slider episodeMode :criterionId="criterionId" :participantId ="selectedParticipant"/>
                                            </v-col> 
                                        </v-row>
                                    </div>
                                    <div v-else class="sm-criteria-container">
                                        <skill-slider-h episodeMode v-for="criterionId in selectedCriterionList" :key="criterionId" :criterionId="criterionId" :participantId="selectedParticipant"/>
                                    </div>
                                </div>
                                <div v-else class="skill-container">
                                    <v-row>
                                        <v-col v-for="criterionId in selectedCriterionList" :key="criterionId" >
                                                <skill-slider episodeMode :criterionId="criterionId" :participantId ="selectedParticipant" :key="updateKey"/>
                                        </v-col> 
                                    </v-row> 

                                </div>
                            </div>
                            <div v-else>
                                <SkillReviewPanel />
                            </div>

                        </v-tab-item>
                        <v-tab-item key="2" value="tab-2">
                            <v-row class="no-boundaries">
                                <v-col v-for="questionId in questionListByContext['episodeform']" :key="selectedParticipant+questionId" cols="12" sm="12" md="12" lg="12" xl="12" class="no-boundaries question-area">
                                    <QuestionRender :readonly="!userIsAuthor" :qid="questionId" :value="getResponse(selectedParticipant, questionId)"/>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                        <v-tab-item key="3" value="tab-3">
                            <v-row class="no-boundaries">
                                <v-col v-for="questionId in questionListByContext['participantreflection']" :key="selectedParticipant+questionId" cols="12" sm="12" md="12" lg="12" xl="12" class="no-boundaries question-area">
                                    <QuestionRender readonly :qid="questionId" :value="getResponse(selectedParticipant, questionId)"/>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                        <v-tab-item key="4" value="tab-4">

                        </v-tab-item>
                    </v-tabs-items>
                </v-row>
                
            </v-card-text>
            <v-divider/>
            <v-card-actions>
                <v-btn v-if="changes" small color="red" ><span style="color:white;font-weight:600" @click="saveDraft">Save Draft</span></v-btn>
                <v-spacer/>
                <v-btn v-if="episodes[selectedEpisode].published" small color="red" text @click="publish(false)">Un-Publish Episode</v-btn>
                <v-btn v-else                                     small color="primary" text @click="publish(true)">Publish Episode</v-btn>
            </v-card-actions>
        </ValidationObserver>

    </v-card>
</template>

<script>
import Loading from '@/components/loading'
import {mapState, mapActions} from 'vuex'
import {ValidationObserver} from 'vee-validate'

import SkillSlider from '@/components/SkillSlider'
import SkillSliderH from '@/components/SkillSliderH'
import QuestionRender from '@/components/QuestionRender'
import SkillReviewPanel from '../../components/SkillReviewPanel.vue'


export default{
    props: [],
    components:{
        Loading,
        ValidationObserver,
        SkillSlider,
        SkillSliderH,
        QuestionRender,
        SkillReviewPanel,
    },
    computed: {
        ...mapState({
            clubs:                  state => state.clubStore.data,
            events:                 state => state.tryoutStore.data,
            seasons:                state => state.seasonStore.data,
            episodes:               state => state.episodeStore.data,
            episodeStatus:          state => state.episodeStore.status,
            roles:                  state => state.roleStore.data,
            roleStatus:             state => state.roleStore.status,
            participants:           state => state.participantStore.data,
            participantStatus:      state => state.participantStore.status,
            questions:              state => state.appquestionStore.data,
            questionListByContext:           state => state.appquestionStore.listByContext,
            qresponseByParticipant: state => state.qresponseStore.byParticipant,

            selectedClub:           state => state.navigatorStore.selectedClub,
            selectedEvent:          state => state.navigatorStore.selectedTryout,
            selectedEpisode:        state => state.navigatorStore.selectedEpisode,
            nSelectedParticipant:   state => state.navigatorStore.selectedParticipant,


            criteria: state => state.criterionStore.data,
            criterionList : state => state.criterionStore.list,
            criterionStatus: state => state.criterionStore.status,

            account: state => state.account,


        }),
        userIsAuthor(){
            const staffList = this.episodes[this.selectedEpisode].staffList
            for(var i=0;i<staffList.length;i++){
                if(staffList[i].linkId === this.account.user.details.id){
                    return true
                }
            }
            return false
        },
        loading(){
            return this.episodeStatus.loading ||
                this.participantStatus.loading ||
                !this.dataReady || 
                false;
        },
        activeParticipants(){
            const list=[]
            for(var i in this.episodes[this.selectedEpisode].participantList){
                const participantId = this.episodes[this.selectedEpisode].participantList[i].participantId
                const roleLabel = this.roles[this.episodes[this.selectedEpisode].participantList[i].role].name
                list.push({
                    participantId: participantId,
                    name: roleLabel + ': '+ this.participants[participantId].firstName + ' ' + this.participants[participantId].lastName ,
                    role: this.roles[this.episodes[this.selectedEpisode].participantList[i].role].name,
                })
            }
            return list
        },
        coachName(){
            return `${this.episodes[this.selectedEpisode].staffList[0].firstName} ${this.episodes[this.selectedEpisode].staffList[0].lastName}`
        },
        startDate(){
            return new Date(this.episodes[this.selectedEpisode].date).toLocaleDateString()
        },
        startTime(){
            return (new Date(this.episodes[this.selectedEpisode].date).toLocaleTimeString())


        },
        dataPanels(){
            const localUser = JSON.parse(localStorage.getItem('user'))
            if(this.events[this.selectedEvent] && this.events[this.selectedEvent].owner === localUser.details.id) return this.menuItems
            return this.menuItems.filter((item)=>{return item || item.admin==false});
        },
        miniScreen(){
            if (this.$vuetify.breakpoint.smAndDown) {
                return true;
            }
            return false;
        },
        landscapeOrientation(){
            return this.orient==='landscape'
        },
        selectedCriterionList: {
            get(){
                if(this.selectedSession && this.sessions[this.selectedSession] && this.sessions[this.selectedSession].criteria.length>0){
                    if(this.invertSkills){
                        return this.criterionList.filter(x => !this.sessions[this.selectedSession].criteria.includes(x));    
                    } else {
                        return this.sessions[this.selectedSession].criteria
                    }
                }
                return this.criterionList
            }
        },
        selectedParticipant: {
            get(){
                return this.nSelectedParticipant
            },
            set(value){
                this.setSelectedParticipant(value)
            }   
        },

    },
    methods: {
        ...mapActions('participantStore', [
            'loadAllParticipantsForEvent'
        ]),
        ...mapActions('episodeStore', [
            'loadEpisode',
            'updateEpisode',
            'episodePropertyUpdate'
        ]),
        ...mapActions('roleStore', [
            'loadAllRolesForEvent'
        ]),
        ...mapActions('qresponseStore',[
            'loadAllQResponsesForEvent',
            'updateQResponseProperty',
            'addQResponse',
        ]),
        ...mapActions('appquestionStore', [
            'loadAllQuestionsForEvent'
        ]),
        ...mapActions('navigatorStore', [
            'setSelectedParticipant'
        ]),
        ...mapActions('journalentryStore', [
            'createNewJournalEntry',
        ]),
        ...mapActions('criterionStore', {
            findAllCriterionForEvent : 'findAllForEvent',
            quietFindAllCriterionForEvent : 'quietFindAllForEvent',
        }),
        ...mapActions('playerskillStore', {
            findAllPlayerSkillScores: 'findAllForEvent',
            findAllPlayerSkillScoresForStaffMember: 'findAllForEventAndStaff',
            quietFindAllPlayerSkillScores: 'quietFindAllForEventAndStaff',
            updateScore: 'updateScore',
            updateLocalScore: 'updateLocalScore'
        }),

        questionNumber: function(id) {
            return `Q${this.questions[id].order+1}: `
        },
        questionLabel: function(id) {
            return `Q${this.questions[id].order+1}: ${this.questions[id].question}`
        },
        getSelectText(questionId){
            const options = JSON.parse(this.questions[questionId].options)
            

            if(this.qresponseByParticipant[this.selectedParticipant][questionId].response>options.length) return ""
            

            if(this.qresponseByParticipant[this.selectedParticipant][questionId].response === null) return ""

            const text = options[this.qresponseByParticipant[this.selectedParticipant][questionId].response].text
            return text
        },
        handleOrientationChange() {
            const orientation = window.screen.orientation.type
            if (orientation === "portrait-primary") {
                // portrait mode
                this.orient="portrait"
            } else if (orientation === "landscape-primary") {
                // landscape mode
                this.orient="landscape"
            }
        },
        async publish(state){

            this.episodePropertyUpdate({field: 'published', id: this.selectedEpisode, value: state})
            await this.updateEpisode({
                episodeId: this.selectedEpisode,
                published: state,
            })
        },

        getResponse(participantId, questionId){
            if(!Object.prototype.hasOwnProperty.call(this.qresponseByParticipant, participantId)) return null
            if(!Object.prototype.hasOwnProperty.call(this.qresponseByParticipant[participantId], questionId)) return null
            return this.qresponseByParticipant[participantId][questionId].response
        },
        async saveResponse(response){
            var participantId = this.selectedParticipant

            // Check if this is a form question or a participant question
            if(this.questions[response.qid].context==='devform'){
                participantId = this.selectedEpisode
            }

            this.updateQResponseProperty({
                eventId: this.selectedEpisode, 
                participantId: participantId,
                questionId: response.qid,
                response: response.value
            })
            this.changes=true

        },
        async saveDraft(){
            
            // Go through all the responses by participant and "create" each one... the back end will upsert them.

            for(var participantId in this.qresponseByParticipant){
                for(var questionId in this.qresponseByParticipant[participantId]){

                    await this.addQResponse(this.qresponseByParticipant[participantId][questionId] )

                }
            }

            this.changes=false



        }
    },
    watch: {},
    destroyed(){
        this.$root.$off('changed-question-response')
    },
    unmounted(){
    },
    deactivated(){
    },

    mounted(){
        window.addEventListener(
            "orientationchange",
            this.handleOrientationChange
        )
        this.$root.$on('changed-question-response', (response)=> {
            this.saveResponse(response)
        })
    },
    async created(){
        this.dataReady = false
        const userId = this.account.user.details.id

        await this.loadEpisode({episodeId: this.selectedEpisode})
        await this.loadAllParticipantsForEvent({eventId: this.selectedEvent})
        await this.loadAllRolesForEvent({eventId: this.selectedEvent})
        await this.loadAllQResponsesForEvent({eventId: this.selectedEpisode})
        await this.loadAllQuestionsForEvent({eventId: this.selectedEvent})
        await this.findAllCriterionForEvent(this.selectedEvent)
        await this.findAllPlayerSkillScores({eventId: this.selectedEpisode, userId})
        await this.findAllPlayerSkillScoresForStaffMember({eventId: this.selectedEpisode, userId})

        this.setSelectedParticipant(this.episodes[this.selectedEpisode].participantList[0].participantId)
        this.menuItems = [
                { text: "Criteria", key: "1", value: 'tab-1', href: "#tab-1", admin: false},
                { text: "Comments", key: "2", value: 'tab-2', href: "#tab-2", admin: false},
                { text: `${this.capitalize(this.events[this.selectedEvent].participantPronoun)} Reflection`, key: "3", value: 'tab-3', href: "#tab-3", admin: false},
              //  { text: "Staff Notes", key: "4", value: 'tab-4', href: "#tab-4", admin: false},

            ]
        this.dataReady=true
    },

    data(){
        return {
            tab:1,
            dataReady: false,
            menuItems:[],
            invertSkills: false,
            orient: "portrait",
            updateKey:null,
            taborder: 0,
            changes: false,
            savedResponses: {},
        }
    }

}
</script>

<style scoped>

.question {
    font-size:0.8em;
    color:blue;
    text-align: left;
    padding-left:0px;

}

.question-area{
    border-bottom: solid gray 1px;
    margin-bottom: 5px;
}
</style>