<template>
    <div style="text-align:left;padding: 0px 10px 0px 10px">
        <v-row>
            <v-col cols="8" style="font-size:small;color:red" v-if="destroyMsg">
                {{ destroyMsg }}
            </v-col>
            <v-spacer/>
            <v-col cols="2" style="text-align:right">
                <v-btn @click.native.stop 
                    v-if="false&&changes" 
                    x-small 
                    v-on:click="saveDetails" 
                    depressed 
                    color="primary" 
                    class="save-btn"
                    style="margin-right:10px"
                >
                    Save
                </v-btn>
            </v-col>
        </v-row>
            <div class="instructions">Teams are defined per season. 
                <ul>
                    <li>
                        Select all teams you want associated with this trial by clicking on the checkbox. 
                    </li>
                    <li>
                        You can click on the colored dot to customise the color that will appear when collecting data. 
                    </li>
                </ul>
                Return to "Club Home" to define a new team for the season.
                <br/>
            </div>
            <br/>
            <team-checkbox v-for="id in teamList" :key="id" :teamId="id"/>

    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TeamCheckbox from '@/ClubPanes/NewTryoutSettingsPanels/TeamCheckbox'
import {v4 as uuidv4} from 'uuid'

export default{
    props: [],
    components: {
        TeamCheckbox,
    },
    computed: {
        ...mapState({
            teams: state => state.teamStore.data,
            teamList: state => state.teamStore.list,
            teamMeta: state => state.teamStore.meta,
            teamStatus: state => state.teamStore.status,

            selectedTryout: state => state.navigatorStore.selectedTryout,
            selectedSeason: state => state.navigatorStore.selectedSeason,
        }),
        loading: function () {
            return false    
                || this.teamStatus.loading
        }
    },
    methods: {
        ...mapActions('teamStore', {
            linkTeam: 'link',
            unlinkTeam: 'unlink',
            findAllTeamsForEvent: 'findAllForEvent'
        }),
        flagChanges(){
            this.changes=true
            this.$root.$emit("settings-changes-made", this.componentId)
        },
        async saveDetails(){
            this.changes=false
            for(var idx in this.teamList) {
                const teamId = this.teamList[idx]
                if(this.teams[teamId].selected){
                    this.linkTeam({teamId, eventId: this.selectedTryout, color: this.teams[teamId].color})
                } else {
                    this.unlinkTeam({teamId, eventId: this.selectedTryout})
                }
            }
            this.$root.$emit("settings-changes-cleared", this.componentId)
            this.$store.dispatch('alert/success', 'Tryout Updated', {root:true})
        },
    },
    mounted(){
        //eslint-disable-next-line
        this.$root.$on('settings-changes-cleared', (id) => {
            
            var self=this
            self.changes=false
        })
        this.$root.$on('submit-all-changes', () => {
            if(this.changes){
                this.saveDetails()
            }
        })
        this.$root.$on('backout-all-changes', () => {
            if(this.changes){
                this.findAllTeamsForEvent({eventId: this.selectedTryout})
            }
        })
        this.$root.$on('settings-changes-made-teamlinks', () => {
            var self=this
            self.changes=true
            this.$root.$emit("settings-changes-made", this.componentId)
        })
        this.$root.$on('settings-changes-made-teamlinks-destructive', (teamId) => {
            var self=this
            self.changes=true
            if(!this.changedTeams.includes(teamId)){
                this.changedTeams.push(teamId)
                this.destroyMsg="One or more teams you are unlinking have players assigned to them. These will be unlinked if you save your changes."
            }
            this.$root.$emit("settings-changes-made", this.componentId)
        })
        this.$root.$on('settings-changes-made-teamlinks-nondestructive', (teamId) => {
            var self=this
            self.changes=true
            const index = this.changedTeams.indexOf(teamId)
            if(index >-1){
                this.changedTeams.splice(index,1)
            }
            if(this.changedTeams.length===0) this.destroyMsg=''

            this.$root.$emit("settings-changes-made", this.componentId)
        })
        this.$root.$on('tryoutRefresh', this.refreshHandler)
    },
    async created() {
        this.componentId = uuidv4()
        await this.findAllTeamsForEvent({eventId: this.selectedTryout})
    },
    beforeDestroy(){
        this.$root.$off('tryoutRefresh', this.refreshHandler)
    },
    data() {
        return {
            refreshHandler: async () => { await this.findAllTeamsForEvent({eventId: this.selectedTryout})},
            componentId: 0,
            dialog: false,
            changes: false,
            destroyMsg: '',
            changedTeams: [],
        }
    }

}
</script>

<style>
.instructions{
    font-style:italic;
    text-align: left; 
    font-size: small;
}
</style>