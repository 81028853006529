<template>
    <loading v-if='loading'/>
    <team-home-staff v-else-if="eventAllows(['admin','owner','headcoach','assistantcoach'])" style="width:100%"/>
    <team-home-athlete v-else-if="eventAllows(['athlete'])" style="width:100%"/>
    <auth-fail v-else/>
</template>

<script>
import TeamHomeStaff from '@/ClubPanes/Teams/TeamHomeStaff'
import TeamHomeAthlete from '@/ClubPanes/Teams/TeamHomeAthlete'

export default{
    props: [],
    components:{
        TeamHomeStaff,
        TeamHomeAthlete,
    },
    computed: {
        loading(){
            return false ||
                false;
        }

    },
    methods: {

    },
    watch: {},
    async created(){

    },
    data(){
        return {

        }
    }

}
</script>