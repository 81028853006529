<template>
    <loading v-if="loading"/>
    <div v-else>
        <v-row>
            <v-spacer/>
            <v-col cols="4">
                <span class="text-h4"> System Information</span>
            </v-col>
            <v-spacer/>
        </v-row>
        <v-row>
            <v-col>
                <div>
                    Major Build Version: {{ majorBuild}}
                </div>
                <div>
                    Minor Build Version: {{ minorBuild}}
                </div>

            </v-col>
        </v-row>
        <v-row>
            <v-spacer/>
            <v-col cols="6">

                <v-simple-table width="60%">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>

                                </th>
                                <th>
                                    Total
                                </th>
                                <th>
                                    New this month
                                </th>
                                <th>
                                    New this quarter
                                </th>
                                <th>
                                    New this year
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Users</td>
                                <td>{{mainStats.users.total}}</td>
                                <td>{{mainStats.users.thisMonth}} ({{mainStats.users.lastMonth}})</td>
                                <td>{{mainStats.users.thisQuarter}} ({{mainStats.users.lastQuarter}})</td>
                                <td>{{mainStats.users.thisYear}} ({{mainStats.users.lastYear}})</td>
                            </tr>
                            <tr>
                                <td>Clubs</td>
                                <td>{{mainStats.clubs.total}}</td>
                                <td>{{mainStats.clubs.thisMonth}} ({{mainStats.clubs.lastMonth}})</td>
                                <td>{{mainStats.clubs.thisQuarter}} ({{mainStats.clubs.lastQuarter}})</td>
                                <td>{{mainStats.clubs.thisYear}} ({{mainStats.clubs.lastYear}})</td>
                            </tr>
                            <tr>
                                <td>Trials</td>
                                <td>{{mainStats.tryouts.total}}</td>
                                <td>{{mainStats.tryouts.thisMonth}} ({{mainStats.tryouts.lastMonth}})</td>
                                <td>{{mainStats.tryouts.thisQuarter}} ({{mainStats.tryouts.lastQuarter}})</td>
                                <td>{{mainStats.tryouts.thisYear}} ({{mainStats.tryouts.lastYear}})</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>

            </v-col>
            <v-spacer/>
        </v-row>
        <v-row>
            <v-col cols="2" style="margin-top:0">
                <!-- 
                <v-list>

                    <v-subheader>Recent Users</v-subheader>
                    <v-list-item-group v-model="selectedUser">
                        <v-list-item v-for="user in recentUserList" :key="user.userId">
                            <v-list-item-action>
                                <v-radio :value="user.userId"/>
                            </v-list-item-action>
                            <v-list-item-content>
                                {{ user.firstName }} {{ user.lastName }}
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
                -->
                <v-subheader>Recent Users</v-subheader>
                <v-radio-group v-model="selectedUser">
                    <v-radio v-for="(user, index) in recentUserList" :key="user.userId" :label="nameLabel(index)" :value="user.userId">
                    </v-radio>
                </v-radio-group>
            </v-col>
            <v-col cols="10" style="margin-top:0">
                <div v-if="selectedUser != null">
                    <v-list>
                        <v-subheader>Recent Activity</v-subheader>
                        <v-data-table 
                            :headers="headers"
                            :items="recentActivityList"
                            :items-per-page="20"
                            class="elevation-1"
                        >

                        </v-data-table>
                    </v-list>
                </div>

            </v-col>
        </v-row>
    </div>

</template>

<script>
import { mapActions, mapState } from 'vuex';
import Loading from '@/components/loading'

export default {
    props:[],
    components: {
        Loading,
    },
    computed: {
        ...mapState({
            mainStats: state => state.systemStore.stats,
            recentUserList: state => state.systemStore.recentUserList,
            recentActivityList: state => state.systemStore.recentActivityList,
            systemStatus: state => state.systemStore.status,


        }),
        loading(){
            return false
                || this.systemStatus.loading
        },
        majorBuild(){
            return process.env.VUE_APP_BUILD_NUM_MAJOR
        },
        minorBuild(){
            return process.env.VUE_APP_BUILD_NUM_MINOR
        }

    },
    methods: {
        ...mapActions('systemStore', {
            loadMainStats: 'mainstats',
            loadRecentUsers: 'recentUsers',
            loadRecentActivity: 'recentActivity',
        }),
        nameLabel(idx){
            if(this.loading)return ''
            return `${this.recentUserList[idx].firstName} ${this.recentUserList[idx].lastName}`
        },
    },
    async created(){
        await this.loadMainStats()
        await this.loadRecentUsers()
//        await this.loadRecentActivity()
    },
    watch: {
        selectedUser(){
            this.loadRecentActivity({userId: this.selectedUser})
        }
    },
    data(){
        return {
            selectedUser:null,
            headers: [
                {
                    text: 'Datetime',
                    align: 'start',
                    sortable: true,
                    value: 'accessDate',
                },
                { text: 'Method', sortable: true, value: 'method' },
                { text: 'Response', sortable: true, value: 'responseCode' },
                { text: 'Response Time (ms)', value: 'responseTime' },
                { text: 'Route', value: 'route' },
                { text: 'IP Address', sortable: true, value: 'sourceAddress' },
            ],
        }
    }
}
</script>