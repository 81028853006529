var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.visibilityChange),expression:"visibilityChange"}]},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card-text',[_c('span',{staticClass:"sessiontitle"},[_vm._v("Registration Questions")]),_vm._l((_vm.questionList),function(questionId){return _c('div',{key:questionId},[_c('QuestionRender',{attrs:{"qid":questionId,"value":_vm.inputRole,"secondRole":true}}),(_vm.questions[questionId].type === 'short')?_c('ValidationProvider',{attrs:{"name":questionId,"rules":_vm.applicableRules(_vm.questions[questionId])},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"shrink",attrs:{"hide-details":"true"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"text-wrap"},[_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v(_vm._s(_vm.questionNumber(questionId)))]),_vm._v(" "+_vm._s(_vm.questions[questionId].question)+" "),(_vm.questions[questionId].required)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e()])]},proxy:true}],null,true),model:{value:(_vm.response[questionId]),callback:function ($$v) {_vm.$set(_vm.response, questionId, $$v)},expression:"response[questionId]"}}),_c('div',{staticClass:"field-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),(_vm.questions[questionId].type==='long')?_c('ValidationProvider',{attrs:{"name":questionId,"rules":_vm.applicableRules(_vm.questions[questionId])},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"field-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),(_vm.questions[questionId].type==='select')?_c('ValidationProvider',{attrs:{"name":questionId,"rules":_vm.applicableRules(_vm.questions[questionId])},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.questionOptions(questionId),"item-text":"text","item-value":"value","label":_vm.questionNumber(questionId)+_vm.questions[questionId].question,"value":_vm.response[questionId]},model:{value:(_vm.response[questionId]),callback:function ($$v) {_vm.$set(_vm.response, questionId, $$v)},expression:"response[questionId]"}}),_c('div',{staticClass:"field-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),(_vm.questions[questionId].type==='checkbox')?_c('ValidationProvider',{attrs:{"name":questionId,"rules":_vm.applicableRules(_vm.questions[questionId])},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"label":_vm.questionNumber(questionId)+_vm.questions[questionId].question,"value":_vm.response[questionId]},model:{value:(_vm.response[questionId]),callback:function ($$v) {_vm.$set(_vm.response, questionId, $$v)},expression:"response[questionId]"}}),_c('div',{staticClass:"field-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e()],1)}),_c('v-divider')],2),_c('v-card-actions',[(!_vm.checkinMode)?_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.lastStep()}}},[_vm._v(" Back ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":function($event){return _vm.saveResponses()}}},[_vm._v(" Continue ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }