<template>
    <div class="participant-line">
        <div>
            <v-list-item-action style="margin-right: 5px">
                <v-checkbox
                    v-model="playerSelected"
                    color="var(--cs1-lev2)"
                    hide-details
                >
                </v-checkbox>
            </v-list-item-action>
        </div>
        <div class="number-name">
            <!--
            <v-list-item-action>
                <v-btn
                    fab
                    small
                    depressed
                    color="primary"
                >
                    {{ number }}
                </v-btn>
            </v-list-item-action>
        -->
            <v-list-item-content>
                <v-list-item-title>
                    {{ name }}
                </v-list-item-title>
            </v-list-item-content>
        </div>
    </div>

</template>

<script>
import {mapState, mapActions} from 'vuex'
export default {
    props: ['participantId'],
    computed: {
        ...mapState({
            participantStatus: state => state.participantStore.status,
            participantList: state => state.participantStore.list,
            participants: state => state.participantStore.data,
            participantMeta: state => state.participantStore.meta,
        }),
        name: function () {
            return this.participants[this.participantId].firstName + ' ' + this.participants[this.participantId].lastName
        },
        number: function () {
            return this.participants[this.participantId].participantNumber 
        },
        playerSelected: {
            get() {
                return this.participantMeta[this.participantId].selected
            },
            set(value)
            {
                this.propertyUpdate({field: 'selected', id: this.participantId, value: value})
            }
        }

    },
    methods: {
        ...mapActions('participantStore', {
            propertyUpdate: 'propertyUpdate',
        })
    },
    created() {
    }
}
</script>


<style>
.participant-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
.number-name {
    display: flex;
    flex-direction: row;
}
</style>