<template>
    <div style="text-align:left;padding: 0px 10px 0px 10px">
        <v-row>
            <v-col cols="2" style="text-align:right">
                <v-btn @click.native.stop 
                    v-if="false&&changes" 
                    x-small 
                    v-on:click="saveDetails" 
                    depressed 
                    color="primary" 
                    class="save-btn"
                    style="margin-right:10px"
                >
                    Save
                </v-btn>
                    <v-btn color="primary" x-small @click="openAddDialog()">
                        Add new staff member
                    </v-btn>
            </v-col>
        </v-row>
        <v-row style="margin-top:10px;text-align: left">
            <v-col cols="12">
                <v-data-table dense :items="staffArray" :headers="filteredHeaders" >
                    
                    <template v-slot:item.name="{item}">
                        <div :class="{strike: item.delete}">{{ item.name }} <span style="color:red">{{ item.isVerified?'':'(Not registered yet)' }}</span></div>
                    </template>
                    <template v-if="tryouts[selectedTryout] && (tryouts[selectedTryout].admin || tryouts[selectedTryout].owner)" v-slot:item.admin="{item}">
                        <span v-if="item.delete">-</span>
                        <v-checkbox v-else x-small v-model="item.admin" v-on:click="flagChanges()"/>
                    </template>
                    <template v-slot:item.programcoach="{item}">
                        <span v-if="item.delete">-</span>
                        <v-checkbox v-else x-small v-model="item.programcoach" v-on:click="flagChanges()"/>
                    </template>
                    <template v-slot:item.headcoach="{item}">
                        <span v-if="item.delete">-</span>
                        <v-checkbox v-else x-small v-model="item.headcoach" v-on:click="flagChanges()"/>
                    </template>
                    <template v-slot:item.assistantcoach="{item}">
                        <span v-if="item.delete">-</span>
                        <v-checkbox v-else x-small v-model="item.assistantcoach" v-on:click="flagChanges()"/>
                    </template>
                    <template v-slot:item.support="{item}">
                        <span v-if="item.delete">-</span>
                        <v-checkbox v-else x-small v-model="item.support" v-on:click="flagChanges()"/>
                    </template>
                    <template v-slot:item.selector="{item}">
                        <span v-if="item.delete">-</span>
                        <v-checkbox v-else x-small v-model="item.selector" v-on:click="flagChanges()"/>
                    </template>
                    <template v-slot:item.actions="{item}">
                        <v-icon v-if="!item.delete" small @click="deleteStaff(item)">mdi-delete</v-icon>
                        <v-icon v-else small @click="deleteStaff(item)">mdi-undo</v-icon>
                    </template>
               
                </v-data-table>
            <v-col cols="">
                <v-dialog v-model="addDialog" width="50%">
                    <ValidationObserver v-slot="{invalid, handleSubmit}">
                            <v-card>
                                <v-card-title class="text-h5">New Staff Member</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="10">
                                            <v-select :items="filteredSeasonStaffList" v-model="selectedStaffMember">
                                                <template v-slot:selection="{item}"         >
                                                    {{ staff[item].firstName }} {{ staff[item].lastName }} 
                                                </template>
                                                <template v-slot:item="{item}"         >
                                                    {{ staff[item].firstName }} {{ staff[item].lastName }} 
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-col>
                                            <v-btn :disabled="!selectedStaffMember" color="primary" text @click="addExistingStaff(selectedStaffMember)">
                                                Add 
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="tryouts[selectedTryout].admin || tryouts[selectedTryout].owner">
                                        <v-col cols="10">
                                            <ValidationProvider rules="email" v-slot="{errors}" name="Email address">
                                                <v-text-field ref="defaultFocus" label="Email address" required v-model="email" @change="userFound=false" @keydown.enter.prevent="handleSubmit(findUser)" @keydown="searchComplete=false"/>
                                                <div class="field-error">{{errors[0]}}</div>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="2" style="margin-top: auto; margin-bottom:auto">
                                            <v-btn v-if="searchComplete" color="blue darken-1" text @click="selectedStaffMember=null;email=null;searchComplete=false">
                                                Clear
                                            </v-btn>
                                            <v-btn v-else-if="!invalid" color="blue darken-1" text @click="handleSubmit(findUser)" :disabled="disallowSearch">
                                                Search
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="searchComplete">
                                        <v-col v-if="!userFound && userIsStaff" cols="1" style="margin-top:0">
                                            <v-icon color="red">mdi-account-question</v-icon>
                                        </v-col>
                                        <v-col v-if="!userFound && userIsStaff" colls="11" style="text-align:left">
                                            <p>{{userData.user.users[0].firstName}} {{userData.user.users[0].lastName}} is already on staff for this event</p>
                                            <v-btn text x-small></v-btn>
                                        </v-col>

                                        <v-col v-if="!userFound && !userIsStaff" cols="1" style="margin-top:0">
                                            <v-icon color="red">mdi-account-question</v-icon>
                                        </v-col>
                                        <v-col v-if="!userFound && !userIsStaff" cols="11" style="text-align:left">
                                            <p>No user is registered with this email address</p>
                                            <v-btn text x-small @click="sendInvitation">
                                                Invite {{email}} to join CoachSight
                                            </v-btn>
                                        </v-col>

                                        <v-col v-if="userFound" cols="1" style="margin-top:0">
                                            <v-icon color="green">mdi-account-check</v-icon>
                                        </v-col>
                                        <v-col v-if="userFound" cols="11" style="text-align:left">
                                            <p>{{userData.user.users[0].firstName}} {{userData.user.users[0].lastName}} is registered with this email address</p>
                                            <v-btn text x-small @click="addUserToStaff">
                                                Add {{userData.user.users[0].firstName}} {{userData.user.users[0].lastName}} to staff
                                            </v-btn>
                                        </v-col>
                                        <v-spacer></v-spacer>
                                    </v-row>

                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer/>
                                    <v-btn text color="primary" @click="closeAddDialog()">Cancel</v-btn>
                                </v-card-actions>
                            </v-card>
                    </ValidationObserver>

                </v-dialog>
                <ValidationObserver v-slot="{invalid, handleSubmit, reset}">
                    <v-dialog v-model="inviteStaff" width="50%" @click:outside="{emailFirstName='';reset()}">
                        <v-card>
                            <v-card-title class="text-h5">
                                Send Staff Invitation
                            </v-card-title>
                            <v-card-text>
                                <div class="text-left">Sending an invitation to <em>{{ email }}</em>. Please provide a name to use on the invitation.</div>
                                <ValidationProvider rules="required" name="Name" v-slot="{errors}">
                                    <v-text-field ref="defaultFocus2" label="First name" v-model="emailFirstName"/>
                                    <div class="field-error">{{ errors[0] }}</div>
                                </ValidationProvider>                  
                                <ValidationProvider rules="required" name="Name" v-slot="{errors}">
                                    <v-text-field label="Last name" v-model="emailLastName"/>
                                    <div class="field-error">{{ errors[0] }}</div>
                                </ValidationProvider>                  
                                <div class="text-h5 text-left">Permissions</div>
                                <v-row>
                                    <v-col cols="3">
                                        <v-checkbox x-small label="Tryout admin" v-model="emailAdmin" />
                                    </v-col>
                                    <v-col cols="3">
                                        <v-checkbox x-small label="Tryout Head Coach" v-model="emailHeadcoach" />
                                    </v-col>
                                    <v-col cols="3">
                                        <v-checkbox x-small label="Tryout Assistant Coach" v-model="emailAssistantcoach" />
                                    </v-col>
                                    <v-col cols="3">
                                        <v-checkbox x-small label="Tryout selector" v-model="emailSelector" />
                                    </v-col>
                                    <v-col cols="3">
                                        <v-checkbox x-small label="Tryout support" v-model="emailSupport" />
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn text @click="closeInvitation">Cancel</v-btn>
                                <v-btn text color="primary" @click="handleSubmit(sendEmail);reset()">Send Invitation</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </ValidationObserver>
            </v-col>
            </v-col>
        </v-row>
        <!-- 
        <v-row>

            <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        Mailout Instructions
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        You can define the mail that goes out to new staff here. There are special values you can insert in your message:<br/>
                    <br/>
                    <ul>
                        <li ><b>$fname </b>: The Staff member's first name</li>
                        <li ><b>$lname</b>: The Staff member's Athletes last name</li>
                        <li ><b>$team</b>: The name of the team that </li>
                        <li ><b>$clubname</b>: The name of your club</li>
                        <li ><b>$clublogo</b>: Your club logo (if it has been uploaded)</li>


                    </ul>
                    <span style="font-weight:600;color: #cc6600;">Don't forget to include a link to the offer review page (see the example below).  </span>
                    <ul>
                        <li><b>$link</b>: The link to the Offer Review page</li>
                    </ul>
                    <br/>
                    The following values can also he inserted if they have been defined for the tryout in "Settings"<br/>
                    <ul>
                        <li><b>$cname</b>: The name of the tryout contact person</li>
                        <li><b>$cphone</b>: The phone number for the tryout contact person</li>
                        <li><b>$cemail</b>: The email for the tryout contact person</li>
                        <li><b>$deadline</b>: The final date that offers can be taken up</li>
                        <li><b>$training</b>: The training details provided for the selected team</li>
                    </ul>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        Manage Staff Mailout
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>

                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
    -->
    </div>
</template>

<script>

import {v4 as uuidv4} from 'uuid'
import {mapState, mapActions} from 'vuex'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {messagingService} from '@/_services'

export default {
    props: [],
    components: {
        ValidationObserver,
        ValidationProvider,

    },
    computed: {
        ...mapState({
            status: state => state.staffStore.status,
            staff: state => state.staffStore.data,
            meta: state => state.staffStore.meta,
            staffList: state => state.staffStore.list,
            seasonStaffList: state => state.staffStore.seasonList,

            userData: state => state.userStore.all,

            thisUser: state => state.account.details,
            selectedTryout: state => state.navigatorStore.selectedTryout,
            selectedSeason: state => state.navigatorStore.selectedSeason,
            tryouts: state => state.tryoutStore.data,
        }),
        loading(){
            return false 
                || this.status.loading
        },
        showFoundPanel: function() {
            return this.searchComplete && this.userFound
        },
        showNotFoundPanel: function() {
            return this.searchComplete && !this.userFound
        },
        disallowSearch: function() {
            var validEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
            return !validEmail.test(this.email)
        },
        filteredHeaders(){
           if(this.tryouts && this.tryouts[this.selectedTryout] && (this.tryouts[this.selectedTryout].admin || this.tryouts[this.selectedTryout].owner)){
            return this.adminHeaders
           }
           return this.headcoachHeaders
        },
        filteredSeasonStaffList(){
            return this.seasonStaffList.filter((item)=>!this.staffList.includes(item))
        }
    },
    methods: {
        ...mapActions('staffStore', { 
            createStaff: 'create',
            addStaff: 'add',
            findAllStaff: 'quietFindAllForEvent',
            deleteFromStaff: 'delete',
            reinstateToStaff: 'undelete',
            revokeRole: 'revokeRole',
            endowRole: 'endowRole',
            findStaffForSeason: 'findStaffForSeason',
        }),
        ...mapActions('userStore', {
            findUserByEmail: 'getByEmail',
            manualUserCreate: 'createManual',
        }),
        async openAddDialog(){
            await this.findStaffForSeason({seasonId: this.selectedSeason})
            this.addDialog=true
        },
        flagChanges(){
            this.changes=true
            this.$root.$emit("settings-changes-made", this.componentId)
        },
        async addExistingStaff(userId){
            await this.createStaff({eventId: this.selectedTryout, role: 'selector', userId })
            this.reloadList()
            this.selectedStaffMember=null
        },
        async saveDetails(){
            for(var idx in this.staffArray){
                const staffMember = this.staffArray[idx]
                {
                    if (staffMember.admin != this.staff[staffMember.id].admin){
                        if(!staffMember.admin)
                            await this.revokeRole({userId: staffMember.id, eventId: this.selectedTryout, role: 'admin'})
                        else
                            await this.endowRole({userId: staffMember.id, eventId: this.selectedTryout, role: 'admin'})
                    }
                    if (staffMember.programcoach != this.staff[staffMember.id].programcoach){
                        if(!staffMember.programcoach)
                            await this.revokeRole({userId: staffMember.id, eventId: this.selectedTryout, role: 'programcoach'})
                        else
                            await this.endowRole({userId: staffMember.id, eventId: this.selectedTryout, role: 'programcoach'})
                    }
                    if (staffMember.headcoach != this.staff[staffMember.id].headcoach){
                        if(!staffMember.headcoach)
                            await this.revokeRole({userId: staffMember.id, eventId: this.selectedTryout, role: 'headcoach'})
                        else
                            await this.endowRole({userId: staffMember.id, eventId: this.selectedTryout, role: 'headcoach'})
                    }
                    if (staffMember.assistantcoach != this.staff[staffMember.id].assistantcoach){
                        if(!staffMember.assistantcoach)
                            await this.revokeRole({userId: staffMember.id, eventId: this.selectedTryout, role: 'assistantcoach'})
                        else
                            await this.endowRole({userId: staffMember.id, eventId: this.selectedTryout, role: 'assistantcoach'})
                    }
                    if (staffMember.support != this.staff[staffMember.id].support){
                        if(!staffMember.support)
                            await this.revokeRole({userId: staffMember.id, eventId: this.selectedTryout, role: 'support'})
                        else
                            await this.endowRole({userId: staffMember.id, eventId: this.selectedTryout, role: 'support'})
                    }
                    if (staffMember.selector != this.staff[staffMember.id].selector){
                        if(!staffMember.selector)
                            await this.revokeRole({userId: staffMember.id, eventId: this.selectedTryout, role: 'selector'})
                        else
                            await this.endowRole({userId: staffMember.id, eventId: this.selectedTryout, role: 'selector'})
                    }
                }
            }
            for(idx in this.staffArray){
                const staffMember = this.staffArray[idx]
                if (staffMember.delete) {
                    await this.deleteFromStaff({userId: staffMember.id, eventId: this.selectedTryout}) 
                } 
            }

            await this.findAllStaff({eventId: this.selectedTryout})
            this.reloadList()


            this.changes=false
            this.$root.$emit("settings-changes-cleared", this.componentId)
            this.$store.dispatch('alert/success', 'Tryout Updated', {root:true})
        },
        async findUser(){
            await this.findUserByEmail(this.email)
                this.searchComplete = true
                this.userFound=false
                if(this.userData && this.userData.user.message === "Found") {
                    // Check they're not already a staff member...
               //     await this.findEventStaff({eventId: this.selectedTryout})
                    for(var id in this.staff) {
                        if(this.staff[id].userId === this.userData.user.users[0].id){
                            this.userIsStaff = true
                            return
                        }
                    }

                    this.userFound=true
                }

        },
        closeInvitation(){
            this.emailFirstName=''
            this.emailLastName=''
            this.inviteStaff=false
            this.emailAdmin= false
            this.emailSelector= false
            this.emailSupport= false
            this.emailHeadcoach=false
            this.emailAssistantcoach=false
        },
        async sendEmail(){
            // Create a new user based on the information we have
            await this.manualUserCreate({
                email: this.email,
                firstName: this.emailFirstName,
                lastName: this.emailLastName,
                updateDetails: true,
                eventId: this.selectedTryout,
            })
            const user = this.userData.user.user

            // Add this user to the staff list
            var roleStr=''
            if(this.emailAdmin) roleStr += 'admin|'
            if(this.emailSelector) roleStr += 'selector|'
            if(this.emailHeadcoach) roleStr += 'headcoach |'
            if(this.emailAssistantcoach) roleStr += 'assistantcoach |'
            if(this.emailSupport) roleStr += 'support|'
            await this.createStaff({eventId: this.selectedTryout, role: roleStr, userId: user.id })

            await messagingService.sendStaffInvitationEmail(this.selectedTryout, this.email, this.emailFirstName, user.id)

            this.inviteStaff=false
            this.addDialog=false
            this.email=''
            this.emailFirstName=''
            this.emailLastName=''

            this.searchDialog=false
            this.userFound=false
            this.userIsStaff=false
            this.searchComplete=false
            this.emailAdmin= false,
            this.emailSelector= false
            this.emailSupport= false
            this.emailHeadcoach=false
            this.emailAssistantcoach = false

            await this.findAllStaff({eventId: this.selectedTryout})
            this.reloadList()
            this.$toast.success(`An email has been sent to invite ${this.emailFirstName} to complete their registration`)
        },
        sendInvitation(){
            this.inviteStaff=true
        },
        closeAddDialog(){
            this.addDialog=false
            this.email=''
        },
        /*
        addStaff(){

        },
        */
        addUserToStaff: async function(){
            await this.createStaff({eventId: this.selectedTryout, role: this.role, userId:this.userData.user.users[0].id})
            await this.findAllStaff({eventId: this.selectedTryout})
            this.reloadList()
            console.log(this.userData.user)
            this.$toast.success(`Added ${this.userData.user.users[0].firstName} ${this.userData.user.users[0].lastName} to staff`)
            this.searchDialog=false
            this.userFound=false
            this.userIsStaff=false
            this.searchComplete=false
            this.email=null
            this.emailAdmin= false
            this.emailSelector= false
            this.emailSupport= false
            this.emailHeadcoach= false
            this.emailAssistantcoach= false

            this.addDialog=false

        },
        deleteStaff(staff){
            this.flagChanges()
            if(!staff.delete) 
                this.staffArray[this.staffArray.indexOf(staff)].delete=true
            else
                this.staffArray[this.staffArray.indexOf(staff)].delete=false
        },
        reloadList(){
            this.staffArray=[]
            for (var idx in this.staffList){
                const staffId = this.staffList[idx]
                    const data = {
                        id: this.staff[staffId].id,
                        name: `${this.staff[staffId].firstName} ${this.staff[staffId].lastName}`,
                        admin: this.staff[staffId].admin,
                        programcoach: this.staff[staffId].programcoach,
                        headcoach: this.staff[staffId].headcoach,
                        assistantcoach: this.staff[staffId].assistantcoach,
                        support: this.staff[staffId].support,
                        selector: this.staff[staffId].selector,
                        isVerified: this.staff[staffId].isVerified,
                        delete: false
                    }
                    this.staffArray.push(data)
                } 
        },
    },
    mounted(){
        this.$root.$on('settings-clear-changes-flags', ()=> {
            var self=this
            self.changes=false
        })
        this.$root.$on('submit-all-changes', () => {
            var self=this
            if(this.changes){
                this.saveDetails()
                self.changes=false
            }
        })

        this.$root.$on('backout-all-changes', () => {
            var self=this
            if(this.changes){
                this.reloadList()
                self.changes=false
            }
        })

        this.$root.$on('tryoutRefresh', this.refreshHandler)
    },
    beforeDestroy(){
        this.$root.$off('tryoutRefresh', this.refreshHandler)
    },
    watch: {
        loading(){
            if(!this.status.loading){
                this.reloadList()
            }
        },
        addDialog: function(value) {
            if (value) {
                setTimeout(() => {
                    this.$refs.defaultFocus.$refs.input.focus()
                })
            }
        },
        inviteStaff: function(value) {
            if (value) {
                setTimeout(() => {
                    this.$refs.defaultFocus2.$refs.input.focus()
                })
            }
        },
    },
    created() {
        this.componentId = uuidv4()
        this.reloadList()
    },
    data() {
        return {
            refreshHandler: async () => {
                await this.findAllStaff({eventId: this.selectedTryout})
                await this.reloadList()
            },
            componentId: 0,
            staffArray: [],
            addDialog: false,
            inviteStaff:false,
            searched: false,
            changes: false,
            adminHeaders: [
                { text: "Name", value: 'name', sortable: true },
                { text: "Administrator", value: 'admin', sortable: true},
                { text: "Program Coach", value: 'programcoach', sortable: true},
                { text: "Head Coach", value: 'headcoach', sortable: true},
                { text: "Assistant Coach", value: 'assistantcoach', sortable: true},
                { text: "Selector", value: 'selector', sortable: true},
                { text: "Support Staff", value: 'support', sortable: true},
                { text: "Actions", value: 'actions', sortable: false }
            ],
            headcoachHeaders: [
                { text: "Name", value: 'name', sortable: true },
                { text: "Head Coach", value: 'headcoach', sortable: true},
                { text: "Assistant Coach", value: 'assistantcoach', sortable: true},
                { text: "Selector", value: 'selector', sortable: true},
                { text: "Support Staff", value: 'support', sortable: true},
                { text: "Actions", value: 'actions', sortable: false }
            ],
            email: null,
            emailFirstName: '',
            emailLastName: '',
            emailAdmin: false,
            emailHeadcoach: false,
            emailAssistantcoach: false,
            emailSelector: false,
            emailSupport: false,
            userFound: false,
            userIsStaff: false,
            searchComplete: false,
            selectedStaffMember: null,
        }
    }

}

</script>