import {sendMessage} from '@/_helpers/service-tools'

export const attendanceService = {
    findAllForEvent,
    update,
}

async function findAllForEvent(eventId ) {
    return await sendMessage('GET', 'attendance/findAllForEvent', {eventId}, null)
}

async function update(payload){
    const {sessionId, participantId, ...bodyPayload} = payload
    return await sendMessage('PATCH', 'attendance', {sessionId, participantId}, bodyPayload)
}