const offerEmail1 =`
<!DOCTYPE html>
<html>
<body>

<table style="border-collapse: collapse; width: 100%;" color="#9145b6" border="0">
<tbody>
<tr>
<td style="width: 100%;"><img src="https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/CS-MUVC+Mail+header+2.png" alt="" style="width:100%"/></td>
</tr>
<tr>
<td style="width: 100%;">&nbsp;
<div style="width: 90%; margin-left: auto;margin-right: auto;font-family:sans-serif;text-align: left;">
<div>
Dear `

const offerEmail2=`,
<br/>
<br/>
<p>Thank you for taking the time to try-out with Monash University Volleyball Club.</p>
<p>We are pleased to offer you a position in the following team:
<em>`

const offerEmail3=`
</em>
</p>
<p><h2>Your Next Steps:</h2></p>
<ol>
<li>Please respond to accept of decline this offer, by clicking on this link: <a href="
`

const offerEmail4 = `
">Review Offer</a>
</li>
<li>
If you accept our offer please complete out 2022 Membership Form on UniOne (an instruction document is <a href="https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/MUVC-UniOne-Rego.v2.pdf"> located here.</a>
</li>
<li>
Please sign-up to the TeamApp app for MUVC (We will use TeamApp to communicate to you in season, and as the place we set calendars etc. Details of how we use TeamApp and how to sign in are <a href="https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/Monash+Volleyball+TeamApp+instructions+v0.pdf">located here.</a>)
</li>
</ol>
<br/>
<h4>Fees:</h4>
<p>The MUVC VVL Season fees for this year are $500 for Premiers teams, and $400 for Reserves teams. If you are new to MUVC, or require a new uniform for any reason, the uniform fee will be up to $100 (to be confirmed).</p>
<h4> Training this week:</h4>
<p>Training on Tuesday night is at ORC, at 6:00pm for Reserve teams, and 8:00 for Premier teams. There will be changes to times and venues discussed at training Tuesday.</p>
<h4>Offer Deadline:</h4>
<p>The deadline for accepting your offer is 5:00 pm, Friday, 4th March 2022</p>
`

//<p>For any questions, please do not hesitate to contact KC Chong by Email (kcchong@optusnet.com.au) or Mobile (0412132668)</p>

const offerEmail5 = `
<p>Congratulations on your selection, and we wish you the best in the 2022 Victorian Volleyball League season.</p>
</div>

<div style="font-family:sans-serif">
<p>Regards,</p>
<br/>
<p style="margin-left:40px;">Amanda Davies<br/>
Club President</p>
<br/>
Monash University Volleyball Club<br/>
Campus Community Division<br/>
Monash University<br/>
Level 1, Campus Centre West, Clayton<br/>
21 Chancellors Walk<br/>
Clayton, VIC, 3800<br/>
</p>
</div>
</div>
</td>
</tr>
<tr>
<td style="width: 100%;">&nbsp;</td>
</tr>
</tbody>
</table>

</body>
</html>
`

const offerEmail6 = `
">Review Offer</a>
</li>
<li>
If you accept our offer please complete out 2022 Membership Form on UniOne by clicking on this link:
</li>
<li>
Please sign-up to the TeamApp app for MUVC (details of how we use TeamApp and how to sign in are <a href="https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/Monash+Volleyball+TeamApp+instructions+v0.pdf">located here."</a>)
</li></ol>
<br/>
<p>Congratulations on your selection, and we wish you the best in the 2022 Victorian Volleyball League season.</p>
<p>For any questions, please do not hesitate to contact KC Chong (Email: kcchong@optusnet.com.au, Mobile: 0412132668)</p>

</div>

<div style="font-family:sans-serif">
</div>
</div>
</td>
</tr>
<tr>
<td style="width: 100%;">&nbsp;</td>
</tr>
</tbody>
</table>

</body>
</html>
`
module.exports = {offerEmail1, offerEmail2, offerEmail3, offerEmail4, offerEmail5, offerEmail6}