<template>
    <loading v-if='loading'/>
    <div v-else>
        <v-row style="width:100%">
            <v-col cols="12">
                <img :src="clubs[selectedClub].logo" height="60px"/>
                <h3>{{clubs[selectedClub].name}}</h3>
            </v-col>
            <v-col cols="12">
                <v-divider style="padding-top:40px;"/>
            </v-col>
            <v-col cols="12">
                <h3>Active Seasons</h3>
                <v-row v-if="activeSeasonList.length === 0">
                    No Active Seasons
                </v-row>
                <v-row v-else>
                    <v-col v-for="season in activeSeasonList" :key="season" cols="12" sm="6" nd="2" lg="2" xl="2">
                        <v-hover v-slot="{hover}">
                            <v-card
                                :elevation="hover?12:0"
                                :class="{'on-hover': hover, 'hover-card':true }"
                                rounded
                            >
                                <v-img
                                    :src="seasonLogo(seasons[season].logo, seasons[season].sportId)"
                                    class="white--text align-end"
                                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                    height= "200px"
                                    @click="setSeason(season)"
                                />
                                <v-card-actions>
                                    <v-card-title dense class="card-title">{{ seasons[season].name }}</v-card-title>
                                </v-card-actions>
                            </v-card>
                        </v-hover>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Loading from '@/components/loading'
import {mapState, mapActions} from 'vuex'

export default{
    props: [],
    components:{
        Loading
    },
    computed: {
        ...mapState({
            clubs:          state => state.clubStore.data,
            seasons:        state => state.seasonStore.data,
            seasonList:     state=> state.seasonStore.list,

            selectedClub:   state => state.navigatorStore.selectedClub,

        }),
        loading(){
            return false ||
                false;
        },
        activeSeasonList: function () {
            return this.seasonList.filter(seasonId => this.seasons[seasonId].active)
        },

    },
    methods: {
        ...mapActions('seasonStore', {
            findAllSeasonsForClub: 'findAllForClub',
            createSeason: 'create',
        }),
        ...mapActions('tryoutStore', {
            getAllTryoutsForSeason: 'findAllForSeason',
           // findActiveTryouts: 'findActive'
        }),
        ...mapActions('navigatorStore', {
            selectTryout: 'selectTryout',
            selectClub: 'selectClub',
            selectPane: 'selectPane',
            selectSeason: 'selectSeason',
            selectNav: 'selectNav',
        }),
        seasonLogo(logo, sportId){
            if(logo) return logo
            return this.sports[sportId].cardImage
        },
        async setSeason(season){
            await this.getAllTryoutsForSeason(season)
            this.selectSeason(season)
            this.selectPane('SeasonHome')
            this.selectNav('Season')
        },

    },
    watch: {},
    async created(){

    },
    data(){
        return {

        }
    }

}
</script>

<style scoped>

.vertical-center {
    margin:0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translateY(-50%);
    -ms-transform: translateX(-50%);
    transform: translateY(-50%);
    transform: translateX(-50%);
    text-align: center;
    font-weight: 600;
}
.card-title{
    font-size:small;
    font-family: 'Varela Round';
    word-break: break-word;
    font-weight:600;
    text-align:left;
    margin-left:0px;
    padding-left: 0px;
    margin-right:0px;
    padding-right: 0px;
    line-height:1;

}
.hover-card {
  transition: opacity .4s ease-in-out;
}

.hover-card:not(.on-hover) {
  opacity: 0.6;
 }

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}

</style>