import { qresponseService } from '../_services'
//import { router } from '../_helpers'
import Vue from 'vue'



import {rc_qresponses} from "@/_helpers/responseCodes"

const dataService = qresponseService

function initialiseState(){
    return {
        status: {},
        list: [],
        data: {},
        byParticipant: {},
        byQid: {},
        responses: {},
        individual: {},
        meta: {},
        error: null,
        internal:{},
    }
}

const state = initialiseState()

const actions = {
    purgeData({commit}){
        commit('purgeData')
    },
    async loadQResponses({dispatch, commit}, payload) {
        try{
            commit('loadQRRequest')
            const response = await dataService.loadQResponses(payload)
            commit('loadQRSuccess', response)
        } catch(error) {
            commit('loadQRFailure', error)
            dispatch('alert/error', error, {root: true})
        }
    },
    async loadAllQResponsesForEvent({dispatch, commit}, payload) {
        try{
            commit('findAllRequest')
            const response = await dataService.findAllForEvent(payload.eventId)
            commit('findAllSuccess', response)
        } catch(error) {
            commit('findAllFailure', error)
            dispatch('alert/error', error, {root: true})
        }
    },
    async findAllForEvent({dispatch, commit}, id) {
        try{
            commit('findAllRequest')
            const response = await dataService.findAllForEvent(id)
            commit('findAllSuccess', response)
        } catch(error) {
            commit('findAllFailure', error)
            dispatch('alert/error', error, {root: true})
        }
    },
    async loadAllQResponsesForParticipant({commit}, payload ) {
        try{
            commit('findAllRequest')
            const response = await dataService.findAllForParticipant(payload)
            commit('findAllSuccess', response)
            return response
        } catch(error) {
            commit('findAllFailure', error)
        }
    },

    /*** Retire ***/
    async findAllForParticipant({commit}, id) {
        try{
            commit('findAllRequest')
            const response = await dataService.findAllForParticipant(id)
            commit('findAllSuccess', response)
            return response
        } catch(error) {
            commit('findAllFailure', error)
        }
    },

    async addQResponse({dispatch, commit}, data) {
        try{
            commit('addRequest')
            const response = await dataService.add(data)
            commit('addSuccess',response)
            return response
        } catch(error) {
            setTimeout(() => {
                // Display a success message after the route change completes
                commit('addFailure',error)
                dispatch('alert/error', 
                error, {root: true})
            })
        }
    },
    /* Retire*/
    async add({dispatch, commit}, data) {
        try{
            commit('addRequest')
            const response = await dataService.add(data)
            commit('addSuccess',response)
            return response
        } catch(error) {
            setTimeout(() => {
                // Display a success message after the route change completes
                commit('addFailure',error)
                dispatch('alert/error', 
                error, {root: true})
            })
        }
    },
    async updateQResponse({commit}, data) {
        try{
            commit('updateRequest')
            const response = dataService.update(data)
            commit('updateSuccess', response)
        } catch(error) {
            commit('updateFailure', error)
        }
    },
    async update({commit}, data) {
        try{
            commit('updateRequest')
            const response = dataService.update(data)
            commit('updateSuccess', response)
        } catch(error) {
            commit('updateFailure', error)
        }
    },



    updateQResponseProperty({commit}, payload){
        commit('QRByParticipantUpdate', payload)
    },

    propertyUpdate({commit},payload) {
        commit('propertyUpdate', payload)
    }
}

function addToLists(data) {
    /*
    const id = data.id

    state.list.push(id)
    Vue.set(state.data, id, {
        meta: {
            delete : false,
        },
        data: data
    })
    */
    const id = data.id

    state.list.push(id)

    Vue.set(state.data, id, data)
    Vue.set(state.responses, data.questionId, data)


    const participantResponses = state.byParticipant[data.participantId] ? state.byParticipant[data.participantId]: {} 
    participantResponses[data.questionId] = data

    Vue.set(state.byParticipant, data.participantId, participantResponses)
    Vue.set(state.meta, id, {
        delete: false,
    })
}

function sortListByOrder() {
    // Nothing to do here...
}

const mutations = {
purgeData(state){
    const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }
    },
    addRequest(state) {
        state.status={loading: true}
        state.error=null
    },
    //eslint-disable-next-line
    addSuccess(state, response) {
        state.status = {responseCode: response.data.code}
        if(state.status.responseCode === rc_qresponses.CREATED)
            addToLists(response.data.result)
        else
            state.status.result = response.data.result
        sortListByOrder()
    },
    addFailure(state, error) {
        state.status = {}
        state.error = error
    },

    
    //--------------------------------------------
    //   Mutations for loadQR
    //
    
    loadQRRequest(state){
        Vue.set(state.status, 'loading', true)
        state.error=null
    },
    loadQRSuccess(state, response){
        state.status = {loading: true, responseCode: response.data.code}

        state.data={}
        state.byParticipant={}
        state.list=[]
        const data = response.data.result

        Vue.set(state, 'individual', data)

        Vue.delete(state.status,'loading')
    },
    loadQRFailure(state, error){
        state.error=error
    },

    //--------- FIND ALL -------\\

    findAllRequest(state) {
        state.status = {loading: true}
        state.error=null
    },
    findAllSuccess(state, data) {
        state.list=[]
        state.data={}
        state.byParticipant={}
        state.byQid={}
        state.responses={}

        for(var idx in data) {
            addToLists(data[idx])
        }
        sortListByOrder()
        state.status = {}
    },
    findAllFailure(state, error) {
        state.status = {}
        state.error = error
    },

    //------ UPDATE -------\
    updateRequest(state) {
        state.internal={loading: true}
        state.error=null
    },
    //eslint-disable-next-line
    updateSuccess(state, data){
        state.internal={updated: true}
        state.error=null
    },
    updateFailure(state, error) {
        state.internal={}
        state.error=error
    },

    // ------------- loadTryout ------------- \
    
    loadRequest(state) {
        state.status={loading: true}
        state.error=null
    },
    loadSuccess(state, data) {
        state.data = [data]   
        state.status={}
    },
    loadFailure(state, error) {
        state.status={}
        state.error = error
    },

    //------------ update data -------- \\
    propertyUpdate(state, payload) {
        const metaFields = "delete"
        const {field, id, value} = payload

        if(metaFields.includes(field)){
            state.meta[id][field]=value
            // Manage special fields...
            //switch (field) {
            //}
        } else {
            state.data[id][field]=value
        }
    },

    QRByParticipantUpdate(state, payload){
        if(!Object.prototype.hasOwnProperty.call(state.byParticipant, payload.participantId)){
            state.byParticipant[payload.participantId]={}
        }
        if(!Object.prototype.hasOwnProperty.call(state.byParticipant[payload.participantId], payload.questionId)){
            state.byParticipant[payload.participantId][payload.questionId] = {
                id: null,
                eventId: payload.eventId,
                questionId: payload.questionId,
                participantId: payload.participantId,
            }
        }
        state.byParticipant[payload.participantId][payload.questionId].response=payload.response
    }

}

export const qresponseStore = {
    namespaced: true,
    state,
    actions,
    mutations
}