
import {sendMessage} from '@/_helpers/service-tools'

export const clubService = {
    findAllForUser, create, updateClub, find, load, archive, unarchive
}

async function load(id) {
    return await sendMessage('GET', 'club',{id},null)
}

async function find (name) {
    return await sendMessage('GET','club/list',{name}, null)
}

async function findAllForUser(id) {
    return await sendMessage('GET','club/listforuser', {userId: id}, null)
}

async function create(club) {
    return await sendMessage('POST','club',null, club)
}

async function updateClub(club) {
    return await sendMessage('PATCH','club',{clubId: club.id}, club)
}

async function archive(clubId){
    return await sendMessage('PATCH', 'club/archive', {clubId: clubId},null)
}

async function unarchive(clubId){
    return await sendMessage('PATCH', 'club/unarchive', {clubId: clubId},null)
}

