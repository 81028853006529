<template>
    <div>
            Your Payment Succeeded. You can close this window and return to the import window to continue
        </div>
    
    </template>
    <script>
    
    export default{
        props: [],
        components: {},
        computed: {},
        methods: {},
        created(){
            window.close()
        },
        data(){
            return {
    
            }
        }
    }
    
    </script>