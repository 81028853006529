import { render, staticRenderFns } from "./QuestionRender.vue?vue&type=template&id=5ecb5a47&scoped=true&"
import script from "./QuestionRender.vue?vue&type=script&lang=js&"
export * from "./QuestionRender.vue?vue&type=script&lang=js&"
import style0 from "./QuestionRender.vue?vue&type=style&index=0&id=5ecb5a47&scoped=true&lang=css&"
import style1 from "./QuestionRender.vue?vue&type=style&index=1&module=true&lang=css&"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "5ecb5a47",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VInput } from 'vuetify/lib/components/VInput';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCol,VInput,VRow,VSelect,VTextField,VTextarea})
