<template>
  <div class="loading fixed inset-0 bg-gray-900 bg-opacity-50 z-20 flex flex-col justify-center">
    <div class="spinner m-auto ease-linear rounded-full border-8 border-t-8 border-gray-800 h-24 w-24">
       <v-icon color="blue">mdi-volleyball</v-icon>
    </div>
    <div v-if="progress >= 0" style="width:60%;margin-left:auto;margin-right:auto;">
      <div class="progress-message">{{ progressMessage }}</div>
      <v-progress-linear height="10" :value="progress" striped color="lime"/>
    </div> 
  </div>

</template>


<script lang="ts">

import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'loading',
  props: {
    isLoading: {
      default: ''
    },
    progress: {
      type: Number,
      default:-1 
    },
    progressMessage: {
      type: String,
      default: ''
    }
  },
  components: {
  },
  data() {
      return ({
          fullPage: true,
          message: {
            type: String,
            default: 'Loading...'
          }
    })
  },
}
</script>

<style scoped >
.loading .spinner {
  border-top-color: theme('colors.red.700');
}
.loading .spinner {
  -webkit-animation: spinner 1s linear infinite;
  animation: spinner 1s linear infinite;
}
@-webkit-keyframes spinner {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>