<template>
    <loading v-if='loading'/>
    <ClubAdminInfo v-else-if="isAdminUser()"/>
    <ClubStaffInfo v-else-if="true"/>
    <ClubStaffInfo v-else-if="staffUser"/>
    <ClubPublicInfo v-else/>
</template>

<script>
import Loading from '@/components/loading'
import ClubAdminInfo from '@/ClubPanes/ClubAdminInfo'
import ClubPublicInfo from '@/ClubPanes/ClubPublicInfo'
import ClubStaffInfo from '@/ClubPanes/ClubStaffInfo'
import {mapState, mapActions} from 'vuex'

export default{
    props: [],
    components:{
        Loading,
        ClubAdminInfo,
        ClubPublicInfo,
        ClubStaffInfo,
    },
    computed: {
        ...mapState({
            clubs:      state => state.clubStore.data,
            clubStatus: state => state.clubStore.status,
            account:    state => state.account,
            seasons:    state => state.seasonStore.data,
            seasonStatus: state => state.seasonStore.status,
            seasonList: state => state.seasonStore.list,

            tryouts:    state => state.tryoutStore.data,
            tryoutList: state => state.tryoutStore.list,
            tryoutStatus: state => state.tryoutStore.status,

            selectedClub: state => state.navigatorStore.selectedClub,
        }),
        loading(){
            return false ||
                this.clubStatus.loading ||
                this.seasonStatus.loading ||
                this.tryoutStatus.loading;
        },
        staffUser() {
            return (this.clubs[this.selectedClub].selector || 
                    this.clubs[this.selectedClub].support ||
                    this.clubs[this.selectedClub].headcoach ||
                    this.clubs[this.selectedClub].assistantcoach ||
                    false
                )
        },


    },
    methods: {
        isAdminUser() {
            return (this.clubs[this.selectedClub].ownerId === this.account.id ) || this.clubs[this.selectedClub].admin
        },
        ...mapActions('tryoutStore', {
            getAllTryoutsForSeason: 'findAllForSeason',
            findActiveTryouts: 'findActive'
        }),
        ...mapActions('seasonStore', {
            findAllSeasonsForClub: 'findAllForClub',
        }),
        ...mapActions('clubStore', {
            loadClub: 'load',
        }),

    },
    watch: {},
    async created(){
    },
    data(){
        return {
            dataReady:false,

        }
    }

}
</script>