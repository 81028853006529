<template>
    <loading v-if="loading"/>
    <div v-else-if="eventAllows(['owner', 'admin'])">
        <div><h2>Offer Mailout</h2></div>
        <div class="main-frame">
            <div>
                <div class="player-list">
                    <div class="list-header">
                        Select Team
                    </div>
                    <v-select 
                        :items="teamList" 
                        dense
                        v-model="selectedTeam"
                        v-on:input="teamChanged"
                        > 
                            <template v-slot:item="{item}">
                                {{name(item)}} 
                            </template>
                            <template v-slot:selection="{item}">
                                <span> {{name(item)}}</span> 
                            </template>
                    </v-select>
                    <div v-if="selectedTeam"  class="list-header">
                        Select Participants
                    </div>
                    <div v-if="selectedTeam" >
                        <v-virtual-scroll
                            :bench="benched"
                            :items="playerList"
                            height="600"
                            item-height="64"
                        >
                            <template v-slot:default="{ item }">
                                <v-list-item :key="item" style="padding-left: 5px">
                                    <recipient-list-item :participantId="item" /> 
                                </v-list-item>

                                <v-divider></v-divider>
                            </template>
                        </v-virtual-scroll> 
                        <div>
                            {{recipientList.length}} recipients selected
                        </div>
                    </div>
                </div>
            </div> 
            <div class="mail-frame">
                <mail-render :eventId="selectedTryout" :recipients="recipientList"/>
            </div>
            <div>
            </div>
        </div>
    </div>
    <auth-fail v-else/>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import Loading from '@/components/loading'
import RecipientListItem from '@/components/RecipientListItem'
import MailRender from '@/components/MailRender'

export default {
    components: {
        Loading,
        RecipientListItem,
        MailRender,
    },
    data() {
        return ({
            benched: 0,
//            tryoutId: 'e355fa63-d34e-4efe-9cfa-451e9e472e3b',
            selectedTeam: null,
        })
    },
    computed: {
        ...mapState({
            participantStatus: state => state.participantStore.status,
            participantList: state => state.participantStore.list,
            participants: state => state.participantStore.data,
            participantMeta: state => state.participantStore.meta,
            teamList: state => state.teamStore.list,
            teams: state => state.teamStore.data,
            teamStatus: state => state.teamStore.status,
            tryouts: state => state.tryoutStore.data,
            tryoutStatus: state => state.tryoutStore.status,

            selectedTryout: state => state.navigatorStore.selectedTryout,

            account: state => state.account,
        }),
        loading() {
            return this.participantStatus.loading
                || this.teamStatus.loading
                || this.tryoutStatus.loading
                || !this.selectedTryout
        },
        recipientList() {
            return this.participantList.filter((e)=>{
                return this.participantMeta[e].selected && this.participants[e].offer === this.selectedTeam
            })
        },
        playerList() {

            return this.participantList.filter((e) => {
                return this.participants[e].offer === this.selectedTeam
            })
        },
        unauthorized() {
            const localUser = JSON.parse(localStorage.getItem('user'))
            if(localUser.details.id === this.tryouts[this.selectedTryout].owner) return false
            return true
        }
    },
    methods: {
        ...mapActions('participantStore', {
            findAllParticipantsForEvent: 'findAllForEvent',
        }),
        ...mapActions('teamStore', {
            findAllTeamsForEvent: 'findAllForEvent',
        }),
        ...mapActions('teamrecommendationStore', {
            loadRecommendations: 'findAllForEvent'
        }),
        ...mapActions('tryoutStore', {
            findAllTryouts : 'findAll',
        }),
        ...mapActions('commentStore',{
            findAllCommentsForEvent: 'findAllForEvent',
        }),
        ...mapActions('playerskillStore', {
            findAllPlayerSkillScores: 'findAllForEventAndStaff'
        }),
        ...mapActions('criterionStore', {
            findAllCriterionForEvent: 'findAllForEvent'
        }),
        ...mapActions('messageStore',{
            loadAllMessages: 'loadAllForEvent',
        }),
        name(id) {
            return this.teams[id].name
        },
        teamChanged() {
            for(var idx in this.participantMeta) {
                this.participantMeta[idx].selected=false
            }
        }

    },
    async created() {
        // await this.findAllTryouts()
        // await this.findAllParticipants(this.eventId)
        // await this.findAllTeams({eventId: this.eventId})
        await this.findAllParticipantsForEvent(this.selectedTryout) 
        await this.findAllTeamsForEvent({eventId: this.selectedTryout})
        await this.loadRecommendations ({eventId: this.selectedTryout})
        await this.findAllCommentsForEvent({eventId: this.selectedTryout})
        await this.findAllPlayerSkillScores({eventId: this.selectedTryout, userId: this.account.user.details.id})
        await this.findAllCriterionForEvent(this.selectedTryout)
        await this.loadAllMessages(this.selectedTryout)
    },
}

</script>

<style>
.player-list {
    width: 100%;
    height: 100%;
    border-style: solid;
    border-color: var(--cs1-lev1);
    margin-left: 15px;
    border-width: 2px;
    border-radius: 10px;
}

.list-header{
    background: var(--cs1-lev1);
    color: white;
    padding: 5px;
    font-weight: 600;
}

.main-frame {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.mail-frame {
   border-style: solid;
    border-color: var(--cs1-lev1);
    margin-left: 30px;
    border-width: 2px;
    border-radius: 10px; 
    width: 100%;
}

</style>