<template>
    <div style="text-align:left;padding: 0px 10px 0px 10px">
        <v-row v-if="false">
            <v-spacer/>
                <v-col cols="2" style="text-align:right">
                    <v-btn @click.native.stop 
                        v-if="changes" 
                        x-small 
                        v-on:click="saveDetails" 
                        depressed 
                        color="primary" 
                        class="save-btn"
                    >
                        Save
                    </v-btn>
                </v-col>
        </v-row>
        <v-row>
            <v-checkbox v-model="active" label="Activate this Tryout"/>
        </v-row>
        <v-row>
            <v-checkbox v-model="onlineReg" label="Accept online registrations now"/>
        </v-row>
        <v-row>
            <v-col cols="12"  sm="12" md="6" lg="6" xl="6" style="margin-top:0px">
                    <div class="qrheading">Link for registration</div>
                    <div class="qrdesc">This link can be ssent to athletes to connect to CoachSight. This is particularly useful for athletes who 
                        have been "imported" into CoachSight from an external team management tool.</div>
                    <div class="qrlink">
                        <div style="word-break:break-all;" class="no-boundaries">
                            {{ registrationLink }} 
                        </div>
                        <div class="no-boundaries text-right">
                            <v-tooltip top >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-on=on v-bind=attrs style="margin-left:20px;" @click="copyToClipboard">mdi-content-copy</v-icon>
                                </template>
                                <span>Copy to clipboard</span>
                            </v-tooltip>
                        </div>
                    </div>
   
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <div>
                    <div class="qrheading">QRCode for registration</div>
                    <div class="qrdesc">This code can be used to allow register for this tryout.</div>
                    <qr-code :key="updateKey"
                        :width="codeSize.large"
                        :height="codeSize.large"
                        :value="registrationLink"
                        :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
                        :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0}"
                        :dotsOptions="{
                            type: 'extra-rounded',
                            color: '#26249a',
                            gradient: {
                                type: 'linear',
                                rotation: 0,
                                colorStops: [
                                    { offset: 0, color: '#9245B7' },
                                    { offset: 1, color: '#032681' },
                                ],
                            },
                        }"
                        :backgroundOptions="{ color: '#ffffff' }"
                        :cornersSquareOptions="{ 
                            type: 'extra-rounded', 
                            color: '#000000' ,
                            gradient: {
                                type: 'linear',
                                rotation: 0,
                                colorStops: [
                                    { offset: 0, color:'#000000'},
                                    { offset: 1, color: '#2197F3'}
                                ]

                            }
                        }"
                        :cornersDotOptions="{ type: 'dot', color: '#000000' }"
                        fileExt="png"
                        :download="true"
                        /> 
                </div>
            </v-col>
        </v-row>
        <!-- 
        <v-row>
            <v-col cols="6">
                <div>
                    <div class="qrheading">QRCode for Tryout Sign-in</div>
                    <div class="qrdesc">This code can be used to register an athlete's presence at a session.</div>
                    <qr-code
                        :width="codeSize.large"
                        :height="codeSize.large"
                        :value="signinLink"
                        :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
                        :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0}"
                        :dotsOptions="{
                            type: 'extra-rounded',
                            color: '#26249a',
                            gradient: {
                                type: 'linear',
                                rotation: 0,
                                colorStops: [
                                    { offset: 0, color: '#9245B7' },
                                    { offset: 1, color: '#032681' },
                                ],
                            },
                        }"
                        :backgroundOptions="{ color: '#ffffff' }"
                        :cornersSquareOptions="{ 
                            type: 'extra-rounded', 
                            color: '#000000' ,
                            gradient: {
                                type: 'linear',
                                rotation: 0,
                                colorStops: [
                                    { offset: 0, color:'#000000'},
                                    { offset: 1, color: '#2197F3'}
                                ]

                            }
                        }"
                        :cornersDotOptions="{ type: 'dot', color: '#000000' }"
                        fileExt="png"
                        :download="true"
                        /> 
                </div>
            </v-col>
        </v-row>
    -->
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import StyledQRCode from '@/components/StyledQRCode'
import {v4 as uuidv4} from 'uuid';

export default {
    props: [],
    components: {
        'qr-code' : StyledQRCode,
    },
    computed: {
        ...mapState({
            selectedTryout: state => state.navigatorStore.selectedTryout,
            tryouts: state => state.tryoutStore.data,
            status: state => state.tryoutStore.status,
        }),
        loading: function() {
            return this.status.loading
        },
        onlineReg: {
            get() {
                return this.tryouts[this.selectedTryout].onlineReg
            },
            set(value){
                this.changes=true
                this.$root.$emit("settings-changes-made", this.componentId)
                this.updateTryoutData({field: 'onlineReg', id: this.selectedTryout, value})
            }
        },
        active: {
            get(){
                return this.tryouts[this.selectedTryout].active

            },
            set(newValue){
                this.changes=true;
                this.$root.$emit("settings-changes-made", this.componentId)
                this.updateTryoutData({field: 'active', id: this.selectedTryout, value:newValue})
            }
        },
        registrationLink(){
            return process.env.VUE_APP_VUE_LINK_PREFIX + "/registerfortryout/"+this.selectedTryout
        },
        signinLink(){
            return process.env.VUE_APP_VUE_LINK_PREFIX + "/signinfortryout/"+this.selectedTryout
        }

    },
    mounted(){
        this.$root.$on('settings-clear-changes-flags', ()=> {
            var self=this
            self.changes=false
        })
        this.$root.$on('submit-all-changes', () => {
            if(this.changes){
                this.saveDetails()
            }
        })

        this.$root.$on('backout-all-changes', () => {
            if(this.changes){
                this.loadTryout(this.selectedTryout)
            }
        })

        this.$root.$on('tryoutRefresh', this.refreshHandler)
    },
    beforeDestroy(){
        this.$root.$off('tryoutRefresh', this.refreshHandler)
    },
    methods: {
        ...mapActions('tryoutStore', {
            updateTryoutData: 'update',
            updateTryout: 'updateTryout',
            loadTryout: 'loadTryout',
        }),
        async saveDetails(){
            this.changes=false
            this.updateTryout(this.tryouts[this.selectedTryout])
            this.$root.$emit("settings-changes-cleared", this.componentId)
            this.$store.dispatch('alert/success', 'Tryout Updated', {root:true})
        },
        async copyToClipboard(){
            await navigator.clipboard.writeText(this.registrationLink);
        }
    },

    async created() {
        this.componentId=uuidv4()
    },
    data(){
        return {
            refreshHandler: async()=>{
                this.updateKey = uuidv4()
            },
            updateKey: 0,
            changes: false,
            codeSize: {
                small: 100,
                medium: 200,
                large: 300
            }

        }
    }

}
</script>

<style>

.qrheading {
    font-weight: 600;
    font-size: large;
    text-align: left;
}

.qrdesc {
    text-align: left;
    margin-bottom: 20px;
}

.qrlink{
    background: lightgreen;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    border-style: solid;
    border-color: green;
}

</style>