const sessionInvitationEmail1 =`
<!DOCTYPE html>
<html>
<body>

<table style="border-collapse: collapse; width: 100%;" color="#9145b6" border="0">
<tbody>
<tr>
<td style="width: 100%;"><img src="https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/email+header+3.png" alt="" style="width:100%"/></td>
</tr>
<tr>
<td style="width: 100%;">&nbsp;
<div style="width: 90%; margin-left: auto;margin-right: auto;font-family:sans-serif;text-align: left;">
<div>
Hi `

const sessionInvitationEmail2=`,
<br/>
<br/>
<p>We would like to invite you to the late selection trial for this Tuesday. This will allow us to focus on players who we have not yet fully assessed in the earlier sesson. </p>
<p>This will be between 8pm and 10pm on Tuesday, 22nd Feb, 2022.</p>
<p>Note: 8pm is the planned start time for training, and we expect you will arrive at the venue at least 15 minutes earlier to carry out any personal stretching, warm-ups or discussions.</p>
</div>


</div>
<div style="text-align: left;" >

  <div style="display: flex;
              justify-content: center;
              border-style: solid;
              width: 50%;
              margin-left: auto;
              margin-right: auto;
              margin-top: 30px;
              margin-bottom: 30px;
              font-size:18px;
              border-radius: 36px;
              background: blue;
              color: white;
              font-weight: 900;
              padding: 40px;
              font-family:sans-serif;
              ">
    <div style="text-align:right; display: flex;flex-direction:column;justify-content: center;">
        <div>
      Your participant number is
        </div>
    </div>
    <div style="font-size:64px;font-weight: 900;padding-left:20px;">
    `
const sessionInvitationEmail3 = `
    </div>
    </div>
    <div style="width: 90%; margin-left: auto;margin-right: auto;font-family:sans-serif;text-align: left;">
        <p>The venue for Tuesday is the Oakleigh Recreation Centre Sports Stadium. </p>
        <br/>
        <a href="https://goo.gl/maps/ETMxxnaUMZY5e52Q7">Google Map to Oakleigh Recreation Centre</a>
        <br/>
        <br/>
        <p>Please follow all COVID-safety directions from staff on entering the facility, and be aware of our COVID-safe procedure (checking in etc).
        <br/>
        <p>
        See you Tuesday!
        </p>
        <br/>
        <p>Your MUVC coaching team.</p>


</div>
<div style="font-family:sans-serif">
</div>
</div>
</td>
</tr>
<tr>
<td style="width: 100%;">&nbsp;</td>
</tr>
</tbody>
</table>

</body>
</html>
`

module.exports = {sessionInvitationEmail1, sessionInvitationEmail2, sessionInvitationEmail3}