<template>
    <loading v-if='loading'/>
        <div v-else class="price-panel">

            <div :class="responsiveClass('packages')">
                <div :class="responsiveClass('package')" v-for="pricePackage in packages" :key="pricePackage.name">

                    <div :class="responsiveClass('discount')" v-if="pricePackage.discount>0">
                        <b>Special Deal</b><br/>
                        {{pricePackage.discount}}% off
                    </div>
                    <div :class="responsiveClass('best-value')" v-else-if="pricePackage.content===250">
                        <b>Best Value</b><br>
                        <span class="best-price-per">{{ localPrice(pricePackage.cost/pricePackage.content/100)}} per registration</span>
                    </div>
                    <div :class="responsiveClass('no-label')" v-else> </div>
                    <div :class="responsiveClass('package-wrapper')">
                        <div class="package-top">
                            <div class="package-name">{{pricePackage.name}}</div>
                        </div>
                        <div class="package-middle">
                            <div class="package-desc">Includes {{pricePackage.content}} registrations</div>
                            <div v-if="pricePackage.discount>0" class="package-cost">Cost: 
                                <span class="discount-old">{{localPrice(pricePackage.cost/100)}}</span>

                                <span class="discount-new">{{localPrice(pricePackage.cost/100*(100-pricePackage.discount)/100)}} </span>

                                <br/><span style="font-size:small;">{{pricePackage.conditions}}</span></div>
                            <div v-else class="package-cost">Cost: {{localPrice(pricePackage.cost/100)}} <br/><span style="font-size:small;">{{pricePackage.conditions}}</span></div>
                        </div>
                        <div class="package-bottom">
                            <div v-if="pricePackage.discount>0" class="cost-per-reg">Cost per registration: {{localPrice(pricePackage.cost/pricePackage.content/100*(100-pricePackage.discount)/100)}}</div>
                            <div v-else class="cost-per-reg">Cost per registration: {{localPrice(pricePackage.cost/pricePackage.content/100)}}</div>
                            <div v-if="pricePackage.discount>0" class="total-cost">Total cost: {{localPrice(pricePackage.cost/100*(100-pricePackage.discount)/100)}}</div>
                            <div v-else class="total-cost">Total cost: {{localPrice(pricePackage.cost/100)}}</div>
                            <div class="conditions"> {{pricePackage.conditions}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="expiry-note">
                <b>Note:</b> Unused registrations do not expire, and can be used for other tryouts your club runs now or in the future.
            </div>
        </div>
</template>

<script>
import Loading from '@/components/loading'
import { mapActions, mapState } from 'vuex';

export default{
    props: [],
    components:{
        Loading
    },
    computed: {
        loading(){
            return false ||
                false;
        },
        ...mapState({
            packages:           state => state.packageStore.data,

            tryouts:            state => state.tryoutStore.data,
            clubs:              state => state.clubStore.data,

            selectedTryout:     state => state.navigatorStore.selectedTryout,
            selectedClub:       state => state.navigatorStore.selectedClub,
        }),

    },
    methods: {
        ...mapActions('packageStore', {
            findActivePackages: 'findActive'
        }),
        localPrice(price){
            if(this.selectedTryout && this.selectedTryout !== "" ){
                return price.toLocaleString(this.tryouts[this.selectedTryout].localLanguage, {style: 'currency', currency: this.tryouts[this.selectedTryout].localCurrency})
            } else {
                return price.toLocaleString('EN-AU', {style: 'currency', currency: 'AUD'})
            }
        },
        responsiveClass(className){
            if(this.$vuetify.breakpoint.smAndDown){
                return className+' mobile'
            }
            return className
        },

    },
    watch: {},
    async created(){
        await this.findActivePackages()

    },
    data(){
        return {

        }
    }

}
</script>

<style scoped>
.price-panel{
    margin:auto;
    padding:40px;
}
.packages{
    display:flex;
    flex-flow: row;
    width:100%;
    margin:0px;
    padding:0px;
    text-align:center;
    margin-top:10px;

}
.packages.mobile{
    flex-flow: column;
}
.package{
    display:flex;
    flex-flow:column;

}
.package.mobile{
    flex-flow: row;
    margin-top:20px;
}
.package-wrapper{
    display: flex;
    flex-flow:column;
    width:100%;
    margin:0px;
    padding: 0px;
    text-align:center;
}
.package-wrapper.mobile{
    margin-left:-60px;
}

.package-top{
    border-style: solid solid none solid;
    background-color:var(--cs1-lev2);
    font-weight:600;

    border-radius: 10px 10px 0px 0px;
    margin: 0px 5px 0px 5px;
}
.package-middle{
    border-style: none solid none solid;
    margin: 0px 5px 0px 5px;
    border-radius: 0px;
    background-color: beige;
}
.package-bottom{
    border-style: none solid solid solid;
    border-radius: 0px 0px 10px 10px;
    margin: 0px 5px 0px 5px;
    background-color:var(--cs1-lev3);
}
.package-name{
    font-weight:600;
    color:white;
    text-align:center;
}

.package-desc{
    text-align:left;
    display: list-item;
    margin-left: 30px;
}

.package-cost{
    text-align:left;
    display: list-item;
    margin-left: 30px;

}

.cost-per-reg{
    text-align: center;
    font-size: x-small;
}

.required-packages{
    margin: 10px 10px 0px 10px;
}

.conditions {
    font-size: x-small;
    text-align:center;
}

.total-cost{
    font-weight:600;
    background-color:white;
    margin:auto;
    width:70%;
    border-radius:10px;
    text-align: center;

}

.new-registrations {
    text-align:left;
    margin-left:30px;
}
.remaining-registrations{
    text-align:left;
    display:list-item;
    margin-left:70px;
}

.instruction-heading{
    font-weight:600;
    font-family:"varela round";
}

.discount {
    border-radius: 10px 10px 0px 0px;
    width:70%;
    margin:auto;
    margin-bottom:0px;
    background-color:green;
    color:white;
    height:3em;
}

.discount.mobile{
    margin:60px 00px 0px 0px;
    width:30%;
    transform:rotate(-90deg) translateY(-20px);
}

.best-value{
    height:3em;
    border-radius: 10px 10px 0px 0px;
    width:70%;
    margin:auto;
    margin-bottom:0px;
    background-color: blue;
    color:white;
}
.best-value.mobile{
    margin:60px 00px 0px 0px;
    width:30%;
    transform:rotate(-90deg) translateY(-20px);
}

.no-label {
    height:3em;
}
.no-label.mobile{
width:30%
}

.discount-old {
    text-decoration: line-through;
}

.discount-new{
    font-weight:600;
    color:red;
}

.expiry-note{
    border-radius:10px;
    border-style: solid;
    margin-top:20px;
    text-align:center;
}

.processing{
    font-size:large;
    font-family: 'varela round';
}

.best-price-per{
    font-size:x-small;
    font-weight:600;

}
</style>