<template>
    <div >
        <v-row>
            <v-col cols="3">
                <img src="https://s3.ap-southeast-2.amazonaws.com/www.coachsightdev.com/coachsight_eye.png" height="30px"/>
            </v-col>
            <v-col>
                <div class="checkin">
                    CHECK-IN HERE
                </div>
            </v-col>
            <v-col cols="3">
                <div class="cs-title">
                    CoachSight Team Selector
                </div>
                <div class="cs-web">
                    www.coachsight.net
                </div>
            </v-col>
        </v-row>
        <v-divider/>
        <v-row style="width:90%;margin:auto;" >
            <div v-if="!loading" style="display:flex;flex-flow:row;margin:auto;">
                <img :src="clubs[selectedClub].logo" height="50px"/>
                <div style="margin-top:auto;margin-bottom:auto;" class="club-title">
                    {{clubs[selectedClub].name}}
                </div>

            </div>
        </v-row>
        <v-row>
             <v-col>
                <div class="session-info">
                    <div v-if="!loading" class="tryout-title">
                        {{tryouts[selectedTryout].title}}
                    </div>
                    <div v-if="!loading" class="tryout-time">
                        Time: {{ moment(sessions[sessionId].startDateTime).format('HH:mm') }}
                    </div>
                    <div v-if="!loading" class="session-title">
                        {{sessions[sessionId].title}}
                    </div>
                </div>
                <div class="qr-heading">
                    Scan QR Code to Check-In
                </div>
            </v-col>
        </v-row>
        <v-row >
            <v-spacer/>
            <v-col cols="12">
                <div >
                    <vqr size="300" style="width:300px;margin:auto;" :text="signinLink"></vqr>
                </div>
            </v-col>
            <v-spacer/>
        </v-row>
        <v-row>
            <v-col>
                <div v-if="false" class="qrcode" ref="qrcode" id="qrcode2"></div>

                <div class="qr-epilogue">
                    If you don't have your phone, check-in with one of your coaches <br/>or with a staff member.
                </div>
            </v-col>
        </v-row>
        <v-row>
             <v-col>
                <div class="session-info">
                    <div class="tryout-venue">
                        {{sessions[sessionId].venue}} 
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
//import StylesQRCode from '@/components/StyledQRCode'
import QRCodeStyling from 'qr-code-styling'
import VueQRCodeComponent from 'vue-qrcode-component'
import 'quill/dist/quill.core.css'

export default{
    props: ['sessionId', 'eventId'],
    components:{
//        'qr-code': StylesQRCode,
        'vqr': VueQRCodeComponent,
    },
    computed: {
        ...mapState({
            selectedSession:        state => state.navigatorStore.selectedSession,
            selectedClub:           state => state.navigatorStore.selectedClub,
            selectedTryout:         state => state.navigatorStore.selectedTryout,

            tryouts:                state => state.tryoutStore.data,
            tryoutStatus:           state => state.tryoutStore.status,

            clubs:                  state => state.clubStore.data,
            clubStatus:             state => state.clubStore.status,

            sessions:               state => state.sessionStore.data,
            sessionStatus:          state => state.sessionStore.status,
        }),
        loading(){
            return false ||
                this.clubStatus.loading ||
                this.sessionStatus.loading ||
                this.tryoutStatus.loading ||
                !this.dataReady
        },
        pageTitleFont(){
            return this.clubs[this.selectedClub].pageTitleFont
        },
        signinLink(){
            return process.env.VUE_APP_VUE_LINK_PREFIX+"/signinforsession/"+this.sessionId+"/"+this.selectedTryout
        }

    },
    methods: {
        ...mapActions('clubStore', {
            loadClub: 'load'
        }),
        ...mapActions('sessionStore',{
            loadSession: 'findOne',
            loadSessionsForEvent: 'findAllForEvent',
        }),
        ...mapActions('tryoutStore',{
            loadTryout: 'loadTryout'
        }),

    },
    watch: {},
    mounted(){

            this.qrCode2.append(document.getElementById("qrcode2"))
    },
    async created(){
        try{

            this.qrCode2 = new QRCodeStyling({
                width: 200,
                height: 200,
                type: "svg",
                data: this.signinLink,
                image: "https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/coachsight_eye.png",
                dotsOptions: {

                    color: "#26249a",
                    type: "extra-rounded",
                    gradient: {
                        type: 'linear',
                        rotation:0,
                        colorStops: [
                            { offset:0, color: '#9245B7'},
                            { offset:1, color: '#032681'}
                        ]
                    }
                },
                backgroundOptions: {
                    color: "#ffffff",
                },
                cornersSquareOptions: {
                    type: 'extra-rounded',
                    color: '#000000',
                    gradient: {
                        type: 'linear',
                        rotation: 0,
                        colorStops: [
                            { offset: 0, color: "#000000"},
                            { offset: 1, color: '#2197F3'},
                        ]
                    }
                },
                cornersDotOptions: {
                    type: 'dot',
                    color: '#000000'
                },
                imageOptions: {
                    crossOrigin: "anonymous",
                    margin: 5
                }

            })
            await this.loadTryout(this.eventId)
            await this.loadClub(this.tryouts[this.eventId].clubId)
            await this.loadSessionsForEvent(this.eventId)
            this.dataReady = true
        } catch(error) {
            console.error(error)
        }
    },
    data(){
        return {
            dataReady: false,
            codeSize: {
                small: 100,
                medium: 200,
                large: 300
            },
        }
    }

}
</script>

<style>
.cs-title {
    font-family: 'Varela Round';
    font-weight: 600;
    font-size:large;
    padding-left:10px;
    color:var(--cscolor2);
    margin-top:auto;
    margin-bottom:auto;
}

.cs-web{
    font-family: 'Varela Round';
    
}

.tryout-title{
    font-size: 50px;
    font-family: 'Varela Round';
    font-weight: 600;
    line-height: 50px;
}

.tryout-venue{
    font-size:30px;
    font-family: 'Varela Round';
    font-weight:600;

}
.tryout-time{
    font-size: 40px;
    font-family: 'Varela Round';
    font-weight:600;
    line-height:60px;
    margin-top:20px;
}

.session-title{
    font-size: large;
    font-family: 'Varela Round';
    font-weight:600;

}

.club-title {
    font-family: 'Varela Round';
    font-size:xx-large;
    font-weight:500;
}
.qr-heading {
    font-size:x-large;
    margin-top: 20px;
    margin-bottom: 20px;
    width:60%;
    margin:auto;
}
.qr-epilogue {
    margin-top:20px;
    font-size:large;

}

.checkin{
    font-size: x-large;
    font-family: 'Varela Round';
    font-weight: 600;
}

.session-info{
    padding:20px;
    border-style: solid;
    border-color: black;
    border-radius: 20px;
    margin:auto;
    margin-top:20px;
    margin-bottom: 20px;
    margin-left:auto;
    margin-right:auto;
}
</style>