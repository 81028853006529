import {sendMessage} from '@/_helpers/service-tools'

export const participantService = {
    add,
    addManual,
    inEvent,
    find,
    findAllForEvent,
    update,
    acceptOffer,
    declineOffer,
    clearOffer,
    archive,
    unarchive,
    findPublic,
    findByName,
    loadAllParticipantsForTeam,
}

async function addManual(data) {
    return await sendMessage('POST', 'participant/createmanual', null, data)
}

async function add(data) {
    return await sendMessage('POST','participant',null, data)
}

async function inEvent(userId, eventId) {
    return await sendMessage('GET', 'participant/inevent', {eventId, userId}, null)
}

async function loadAllParticipantsForTeam(payload) {
    return await sendMessage('GET', 'participant/loadAllParticipantsForTeam', {teamId: payload.teamId}, null)
}

async function find(id) {
    return await sendMessage('GET','participant', {participantId: id}, null)
}

async function findByName(payload) {
    return await sendMessage('GET','participant/findByName', {firstName: payload.firstName, lastName: payload.lastName, eventId: payload.eventId, clubId: payload.clubId}, null)
}

async function findAllForEvent(id) {
    return await sendMessage('GET','participant/list', {eventId: id}, null)
}

async function findPublic(payload){
    return await sendMessage('GET','participant/listpublic', {eventId: payload.eventId}, null)
}

async function update(data) {
    return await sendMessage('PATCH','participant', {participantId: data.id}, data)
}

async function acceptOffer(id) {
    return await sendMessage('PATCH', 'participant/accept', {participantId: id}, null)
}

async function declineOffer(id) {
    return await sendMessage('PATCH', 'participant/decline', {participantId: id}, null)
}

async function clearOffer(id) {
    return await sendMessage('PATCH', 'participant/clearOffer', {participantId: id}, null)
}

async function archive(payload) {
    return await sendMessage('PATCH', 'participant/archive', {participantId: payload.participantId}, null)
}

async function unarchive(payload) {
    return await sendMessage('PATCH', 'participant/unarchive', {participantId: payload.participantId}, null)
}
