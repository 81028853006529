<template>
    <loading v-if="loading"/>
    <div v-else>
        <div class="ql-editor" v-html="sampleHTML"/>
        <email-footer/> 
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import EmailFooter from '@/components/EmailFooter'
const urlPrefix = process.env.VUE_APP_VUE_LINK_PREFIX
import Loading from '@/components/loading'

import moment from 'moment'

export default {
    props: ['firstName','lastName','teamOffered','tryoutId','offerContactName','offerContactPhone','offerContactEmail', 'offerTraining'],
    components: {
        EmailFooter,
        Loading,
    },
    computed: {
        ...mapState({
            messages:       state => state.messageStore.data,
            messageList:    state => state.messageStore.list,
            messageStatus:  state => state.messageStore.status,
            msgByName:      state => state.messageStore.listByName,
            clubs:          state => state.clubStore.data,
            clubList:       state => state.clubStore.list,
            clubStatus:     state => state.clubStore.status,
            selectedClub:   state => state.navigatorStore.selectedClub,
            tryouts:        state => state.tryoutStore.data,
            selectedTryout: state => state.navigatorStore.selectedTryout,
        }),
        loading() {
            return false
                || this.messageStatus.loading

        },
        sampleHTML() {
            var finalString = this.msgByName['offerEmail'].message
            finalString = finalString.replace(/\$fname/g, this.firstName)
            finalString = finalString.replace(/\$lname/g, this.lastName)
            finalString = finalString.replace(/\$team/g, this.teamOffered)
//            finalString = finalString.replace(/\$offerLink/g, this.offerLink)
            //finalString = finalString.replace(/\$link/g, `<a href="${urlPrefix}/reviewoffer/$tryoutid}">Review Offer</a>`)
            finalString = finalString.replace(/\$link/g, `<a href="${urlPrefix}">Review Offer</a>`)
            finalString = finalString.replace(/\$cname/g, this.offerContactName)
            finalString = finalString.replace(/\$cphone/g, this.offerContactPhone)
            finalString = finalString.replace(/\$cemail/g, this.offerContactEmail)
            finalString = finalString.replace(/\$clubname/g, this.clubs[this.selectedClub].name)
            finalString = finalString.replace(/\$clublogo/g, "<img height=\"50px\" src=\""  + this.clubs[this.selectedClub].logo + "\"/>")
            finalString = finalString.replace(/\$tryoutid/g, this.tryoutId)

            var trainingInfo = this.offerTraining
            if(!trainingInfo){
                trainingInfo="No training information is available at the moment." 
            }
            finalString = finalString.replace(/\$training/g, trainingInfo)

            if(this.tryouts[this.selectedTryout].offerDeadline){
                finalString = finalString.replace(/\$deadline/g, `<span style="font-weight:600;">${moment(this.tryouts[this.selectedTryout].offerDeadline).format('hh:mm a on dddd MMMM Do, YYYY')}</span>`)
            } else {
                finalString = finalString.replace(/\$deadline/g, '<span style="font-weight:600;color:red;">No Deadline has been set yet for this competition</span>')
            }
            return finalString
        }
        
    },
    methods: {
        ...mapActions('messageStore', {
            findMessagesForEvent: 'loadAllForEvent',
        })
    },
    async created() {
//        await this.findMessagesForEvent(this.selectedTryout) 
    },
    data() {
        return {
        }
    }
}
</script>

<style>


</style>