<template>
    <div key="keyId">
        <v-row>
            <v-col class="pane-heading" cols="10">
                Edit Season Details
            </v-col>
        </v-row>
            <!-- Admin only past here-->
        <v-row v-if="isAdminUser() " >
            <v-col cols="12" >
                <v-subheader 
                    inset 
                    >Season Details</v-subheader>
                <v-divider></v-divider> 
                <ValidationObserver>
                    <v-card>
                        <v-card-text>
                            <v-row>
                                <v-col cols="3">
                                    Logo
                                    <v-img v-if="logo" :src="seasons[selectedSeason].logo" />
                                    <v-skeleton-loader
                                        v-else
                                        v-bind="attrs"
                                        type="image"
                                    ></v-skeleton-loader>
                                    <ImageUpload text :label="seasons[selectedSeason].logo?'Replace Season Logo':'Set Season Logo'" type="Upload Season Logo" cropper/>
                                </v-col>
                                <v-col 
                                    cols="9"
                                >
                                    <v-row style="margin-top:0px;">
                                        <v-col cols="12">
                                            <ValidationProvider name="Season Name" rules="required" v-slot="{errors}">
                                                <v-text-field label="Season Name" v-model="name" > </v-text-field>
                                                <div class="form-error">{{ errors[0] }}</div>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="4">
                                            <DateCombo title="Start Date" :rules="[]" v-model="startDate"/>
                                        </v-col>
                                        <v-col cols="4">
                                            <DateCombo title="End Date" :rules="[]" v-model="endDate"/>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-select label="Sport" :items="sportsArray" v-model="selectedSport"/>
                                        </v-col>
                                        <v-col> 
                                            <v-checkbox v-model="seasons[selectedSeason].active" @change="checkValue($event, selectedSeason)" label="Session is active and visible"></v-checkbox>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                    </ValidationObserver>
            </v-col>
        </v-row>
    </div>
    
</template>
    
<script>
import {mapActions, mapState} from 'vuex'
// import SeasonEditor from '@/components/SeasonEditor'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import DateCombo from '@/components/DateCombo'
import ImageUpload from  '@/components/ImageUpload'

import { v4 as uuidv4} from 'uuid'

import moment from 'moment'

    export default {
        props: ['clubId', 'keyId'],
        components: {
    //        SeasonEditor,
            ValidationProvider,
            ValidationObserver,
            DateCombo,
            ImageUpload,
        },
        computed: {
            ...mapState({
                account: state => state.account.user.details,
                menuState: state => state.navigatorStore.showBurgerMenu,
                clubs: state => state.clubStore.data,
                seasons: state => state.seasonStore.data,
                seasonList: state => state.seasonStore.list,
                selectedClub: state => state.navigatorStore.selectedClub,
                selectedSeason: state => state.navigatorStore.selectedSeason,
                sports:         state => state.sportStore.data,
                sportsList:     state => state.sportStore.list,
            }),
            sportsArray: function(){
                var sportsObjs=[]
                for(var idx in this.sportsList){
                    sportsObjs.push({
                        text: this.sports[this.sportsList[idx]].name,
                        value: this.sportsList[idx]
                    })
                }
                return sportsObjs
            },
            selectedSport: {
                get(){
                    if(!this.seasons[this.selectedSeason].sportId) return this.clubs[this.clubId].sportId
                    return this.seasons[this.selectedSeason].sportId
                },
                set(newValue){
                    this.updateSeasonProp({field: 'sportId', id: this.selectedSeason, value: newValue})
                    this.updateSeason({season:this.seasons[this.selectedSeason]})
                }
            },
            activeSeasons: function() {
                return this.seasonList.length>0
            },
            logo: {
                get(){
                    return this.seasons[this.selectedSeason].logo
                }
            },
            startDate: {
                get() {
                    if(this.selectedSeason)
                        return this.seasons[this.selectedSeason].startDate
                    return this.newstartDate
                },
                set(newValue) {
                    if(this.selectedSeason){
                        this.updateSeasonProp({
                            field: 'startDate',
                            id: this.selectedSeason,
                            value: newValue
                        })
                        this.updateSeason({season: this.seasons[this.selectedSeason]})
                    } else {
                        this.newstartDate = newValue
                    }
                }
            },
            file: {
                get() {
                    return this.aFile
                },
                async set(newValue) {
                    try{
                    this.aFile=newValue
                    const apiUrl = process.env.VUE_APP_CSAPI
                    const localUser = JSON.parse(localStorage.getItem('user'))
                    const url = new URL(`${apiUrl}/aws/uploadPath`)

                    // What was the file extension

                    const ext = this.aFile.name.substring(this.aFile.name.lastIndexOf('.')+1, this.aFile.name.length)

                    url.searchParams.append('type', `Upload Season Logo`)
                    url.searchParams.append('filename', `season_icons/${this.selectedSeason}.`+ext )
                    url.searchParams.append('seasonId', `${this.selectedSeason}` )
                    const signedURL = await fetch(url, {
                            method: 'GET',
                            headers: {
                                'Content-Type':'application/json',
                                'Authorization':'Bearer '+localUser.token,
                            }
                        }).then(response => {
                            return response.json()
                        }
                    )
                    
                    // Upload the file

                    const result = await fetch(signedURL.uploadURL, {
                        method: 'PUT',
                        headers: {
                            'Origin':'https://www.coachsight.net'
                        },
                        body: this.aFile
                    }).then(response => {
                        return response
                    })
                    if (result.status=="200") {
    //                    this.$store.dispatch('alert/success', 'Your  has been created', {root: true})
                        const logo = "https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/"+signedURL.newfilename
                        this.updateSeasonProp({field: 'logo', id: this.selectedSeason, value: '' }) 
                        this.$nextTick(()=>{
                            this.updateSeasonProp({field: 'logo', id: this.selectedSeason, value: logo }) 
                            this.updateSeason({season: this.seasons[this.selectedSeason]})
                        })

                    } else {
                        this.$store.dispatch('alert/warn', 'Your Season logo failed to upload. Please try uploading it in the club profile page', {root: true})
                    }
                    this.dialog=false;
                    } catch (error){
                        this.$toast.error('Your icon upload failed. Please try again or select a different icon.')
                    }
                }
            },
            name: {
                get() {
                    
                    if(this.selectedSeason)
                        return this.seasons[this.selectedSeason].name
                    return this.newname
                },
                set(newValue){
                    if(this.selectedSeason){
                        this.updateSeasonProp({
                            field: 'name',
                            id: this.selectedSeason,
                            value: newValue
                        })
                        this.updateSeason({season: this.seasons[this.selectedSeason]})
                    } else {
                        this.newname = newValue
                    }

                }
            },
            endDate: {
                get() {
                    if(this.selectedSeason)
                        return this.seasons[this.selectedSeason].endDate
                    return this.newendDate

                },
                set(newValue){
                    if(this.selectedSeason){ 
                        this.updateSeasonProp({
                            field: 'endDate',
                            id: this.selectedSeason,
                            value: newValue
                        })
                        this.updateSeason({season: this.seasons[this.selectedSeason]})
                    } else {
                        this.newendDate = newValue
                    }
                }
            },
        },
        methods: {
            ...mapActions('seasonStore', {
                findAllSeasonsForClub: 'findAllForClub',
                createSeason: 'create',
                updateSeasonProp: 'propertyUpdate',
                updateSeason: 'update'
            }),
            ...mapActions('navigatorStore', {
                selectSeason: 'selectSeason',
            }),
            ...mapActions('tryoutStore', {
                findAllTryoutsForSeason: 'findAllForSeason',
            }),
            
            closeDialog(){
                this.newname= ''
                this.newstartDate= ''
                this.newendDate= ''

                this.$refs.form.reset()
                this.dialog=false
            },
            isAdminUser() {
               return (this.clubs[this.selectedClub].ownerId === this.account.id ) || this.clubs[this.selectedClub].admin
            },
            checkValue(value, seasonId){
                this.updateSeasonProp({field: 'active', id: seasonId, value: value})
                this.updateSeason({season: this.seasons[seasonId]})
            },
            formatDate(date){
                return moment(date).format('D-MMM-YY')
            },
            async save() {
                await this.createSeason({
                    season: {
                        name: this.newname,
                        startDate: this.newstartDate,
                        endDate: this.newendDate,
                        clubId: this.clubId,
                        sportId: this.clubs[this.selectedClub].sportId,
                    }
                })
                this.newname= ''
                this.newstartDate= ''
                this.newendDate= ''
                this.dialog=false
            },
            async saveEdits(seasonId) {

                if (this.$refs.selectedSeason.validate()) {
                    await this.updateSeason({
                        season: this.seasons[seasonId]
                    })
                    this.editDialog=false
                }
            }
        },
        async created() {
        },
        mounted(){
            this.$root.$on('imageUploaded', () => {
                var self=this
                self.updateKey = uuidv4()
            })
        },
        data() {
            return {
                rules: {
                    required: value => !!value || 'Required',
                    smallimg: value => !value || value.size < 500000 || 'Logo size must be under 500kB',
                },
                updateKey:0,
                aFile: null,
                dialog: false,
                sdMenu: false,
                edMenu: false,
                newname: '',
                newstartDate: new Date(),
                newendDate: new Date(),
                newsdMenu: false,
                newedMenu: false,
                editDialog: false,
                editId: '',
                editSeason:'',
                needsSync: false,
                attrs: {
                    class: 'mb-6',
                    boilerplate: true,
                    elevation: 2,
                },
            }
        }
    
    
    }
    
</script>
<style>


</style>