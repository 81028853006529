var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('loading'):_c('div',[_c('v-app-bar',{staticStyle:{"padding-left":"0px","margin-left":"0px"},attrs:{"height":"32px","color":"#36dbf4"}},[(!_vm.selectedClub)?_c('div',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"root-chev",on:{"click":function($event){return _vm.clubroomNav()}}},[_vm._v(" Club Rooms ")]),_c('span',{staticClass:"first-chev"},[_vm._v("Account")])]):(_vm.selectedNav==='ClubHome')?_c('div',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"root-chev",on:{"click":function($event){return _vm.clubHomeNav()}}},[_vm._v(" Club Home ")]),_c('span',{staticClass:"first-chev"},[_vm._v("Account")])]):(_vm.selectedNav==='Season')?_c('div',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"root-chev",on:{"click":function($event){return _vm.clubHomeNav()}}},[_vm._v(" Club Home ")]),_c('span',{staticClass:"first-chev",on:{"click":function($event){return _vm.seasonHomeNav()}}},[_vm._v(" Season: "+_vm._s(_vm.seasons[_vm.selectedSeason].name))]),_c('span',{staticClass:"second-chev"},[_vm._v("Account")])]):(_vm.selectedNav === 'Tryout')?_c('div',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"root-chev",on:{"click":function($event){return _vm.clubHomeNav()}}},[_vm._v(" Club Home ")]),_c('span',{staticClass:"first-chev",on:{"click":function($event){return _vm.seasonHomeNav()}}},[_vm._v(" Season: "+_vm._s(_vm.seasons[_vm.selectedSeason].name))]),_c('span',{staticClass:"second-chev",on:{"click":function($event){return _vm.tryoutHomeNav()}}},[_vm._v(" Tryout: "+_vm._s(_vm.tryouts[_vm.selectedTryout].title)+" ")]),_c('span',{staticClass:"third-chev"},[_vm._v("Account")])]):_vm._e()]),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
var reset = ref.reset;
return [_c('v-form',{attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.submit(reset))}}},[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"disabled":invalid || !_vm.changes,"x-small":"","color":"primary"},on:{"click":function($event){return _vm.submit(reset)}}},[_vm._v(" Save ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-0",attrs:{"xs":"12","sm":"12","md":"8","lg":"8","xl":"8"}},[_c('BoundedBox',{attrs:{"borderColor":"indigo","height":"100%"}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("Main Details")]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"First name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"First Name"},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),_c('div',{staticClass:"field-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required","name":"Last name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Last Name"},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}}),_c('div',{staticClass:"field-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"","name":"Nickname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Preferred Name"},model:{value:(_vm.nickName),callback:function ($$v) {_vm.nickName=$$v},expression:"nickName"}}),_c('div',{staticClass:"field-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])],1),_c('v-col',{attrs:{"xs":"12","sm":"12","md":"4","lg":"4","xl":"4"}},[_c('BoundedBox',{attrs:{"sidePadding":"0px","borderColor":"indigo"}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("Profile Image")]),_c('div',{attrs:{"slot":"content"},slot:"content"},[(_vm.account.user.details.profileImage)?_c('div',[_c('v-img',{attrs:{"contain":"","src":_vm.account.user.details.profileImage,"height":"150px","max-height":"150px"}})],1):_c('div',[_c('v-icon',{attrs:{"size":"200"}},[_vm._v("mdi-account-circle")])],1),_c('ImageUpload',{attrs:{"consentRequired":"","text":"","label":"Change profile image","type":"User Image","cropper":true}})],1)])],1)],1),_c('v-row',[_c('v-divider')],1),_c('v-row',[_c('v-col',[_c('BoundedBox',{attrs:{"borderColor":"indigo"}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("Contact Details")]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('v-text-field',{attrs:{"label":"Phone number"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('ValidationProvider',{attrs:{"name":"Email address","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email address"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('div',{staticClass:"text-field-error",attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])],1)],1),_c('v-row',[_c('v-col',[_c('BoundedBox',{attrs:{"borderColor":"indigo"}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("Bio")]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('v-textarea',{attrs:{"hint":"Tell us a bit about yourself so the selectors have some context about you"},model:{value:(_vm.bio),callback:function ($$v) {_vm.bio=$$v},expression:"bio"}})],1)])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }