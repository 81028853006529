<template>
    <div class="container">
        <v-card class="mx-auto" :loading="status.loggingIn" outlined shaped elevation= "5" >
            <v-card-text>
                <h3>The account link you have clicked has expired</h3>

                <v-form ref="verifyForm" form @submit.prevent="handleSubmit">
                    <p>
                     You may already have verified your account, or you might have requested a new verification link.
                    </p>
                    <p>
                    To resend your verification token, type in your email address and press submit below.
                    </p>
                    <v-text-field label="Email" hide-details="auto" :rules="[rules.required]" v-model="email"/>
                    <div class="resend-btn">
                    <v-btn type="submit" color="primary" class="resend-btn">Resend Code</v-btn>
                    </div>
                    <p>
                    Already verified? <router-link to="/login" class="btn btn-link">Login here</router-link>
                    &nbsp;|&nbsp; Don't have an account yet? <router-link to="/register" class="btn btn-link">Register now</router-link>
                    </p>
                </v-form>            
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
//import Loading from '@/components/loading'

export default {
    data () {
        return {
            email: '',
            submitted: false,
            rules: {
                required:   value => !!value || 'Required',
                email:      value => !value || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'Not a valid email address' 
            },
        }
    },
    components: {
//        Loading,
    },
    computed: {
        ...mapState('account', ['status'])
    },
    created () {
        // reset login status
        this.logout();
    },
    methods: {
        ...mapActions('account', ['login', 'logout', 'resend']),
        // eslint-disable-next-line
        handleSubmit (e) {
            this.submitted = true;
            if(this.$refs.verifyForm.validate()) {
                this.resend(this.email);
            }
        }
    },
};
</script>

<style>

form {
    margin-top: 40px;
}
.container {
    display: flex;
    justify-content: center;
    padding: 40px;
}


.resend-btn {
    clear: both;
    padding-top: 20px;
    padding-bottom: 20px;
}

.login-form {
   display: flex;
    width: 100%;
}

.login-sheet {
    width: 70%;
    elevation: 10;
    color: grey lighten-3;
}
</style>
