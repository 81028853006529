<template>

    <loading 
        :active.sync="isLoading" 
        :can-cancel="false"
        :is-full-page="true"
        color="#93278f"
        loader="dots"
    />

</template>

<script>

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
    props: ['isLoading'],
    components: {
        Loading,
    },
    data(){
        return {

        }
    }

}
</script>