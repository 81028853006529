
import {sendMessage} from '@/_helpers/service-tools'

export const episodeService = {
    createEpisode, updateEpisode , loadAllEpisodesForEvent, loadEpisode, loadAllEpisodesForUserAndEvent,
}

async function createEpisode(payload ){
    return await sendMessage('POST','episode/createEpisode', null, {
        eventId: payload.eventId,
        date: payload.date,
        participantList: JSON.stringify(payload.participantList),
        staffList: JSON.stringify(payload.staffList),
        seasonId: payload.seasonId,
    })
}

async function updateEpisode(payload){
    const newPayload={
        episodeId: payload.episodeId, 
    }

    if(Object.prototype.hasOwnProperty.call(payload, 'participantList')){
        newPayload.participantList=JSON.stringify(payload.participantList)
    }
    if(Object.prototype.hasOwnProperty.call(payload, 'staffList')){
        newPayload.staffList=JSON.stringify(payload.staffList)
    }
    if(Object.prototype.hasOwnProperty.call(payload, 'published')){
        newPayload.published=payload.published
    }

    return await sendMessage('PATCH', 'episode/updateEpisode', null, newPayload)
}

async function loadEpisode(payload){
    return await sendMessage('GET', 'episode/loadEpisode', {episodeId: payload.episodeId}, null)
}

async function loadAllEpisodesForEvent(payload){
    return await sendMessage('GET', 'episode/loadAllEpisodesForEvent', {eventId: payload.eventId}, null)
}

async function loadAllEpisodesForUserAndEvent(payload){
    return await sendMessage('GET', 'episode/loadAllEpisodesForUserAndEvent', {eventId: payload.eventId,userId: payload.userId}, null)
}
