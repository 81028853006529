<template>
    <loading v-if="loading"></loading>
    <div v-else-if="this.selectedSeason===''">
        No season has been selected yet
    </div>
    <div v-else>
        <v-row>
            <v-col cols="11" class="pane-heading">
                Teams for {{this.seasons[this.selectedSeason].name}}
            </v-col>
            <v-col cols="1">
                <v-dialog v-model="teamDialog" persistent max-width="600px">
                    <template v-slot:activator="{on, attrs}">
                        <v-btn 
                            color="primary" 
                            v-on="on" 
                            x-small
                            v-bind="attrs"
                        >
                            Add
                        </v-btn>
                    </template>
                    <ValidationObserver ref="form" v-slot="{handleSubmit, reset}">
                            <v-card>
                                <v-card-title>
                                    <span class="text-h5">New Team</span>
                                </v-card-title>
                                <v-card-text>
                                    <!-- 
                                        <v-text-field v-model="newName" :rules="[rules.required]" hide-details="auto" prepend-icon="mdi-label" label="Team Name"/>
                                        <v-text-field v-model="newCompetition" :rules="[rules.required]" hide-details="auto" prepend-icon="mdi-label" label="Competition Name"/>
                                        <v-text-field v-model="newDescription" hide-details="auto" prepend-icon="mdi-label" label="Description"/>
                                    -->
                                        <v-row>
                                            <v-col cols="12">
                                                <ValidationProvider name="newName" rules="required" v-slot="{errors}">
                                                    <v-text-field v-model="newName" prepend-icon="mdi-label" label="Team Name"/>
                                                    <div class="error-message">{{errors[0]}} </div>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12">
                                                <ValidationProvider name="newCompetition" rules="required" v-slot="{errors}">
                                                    <v-text-field v-model="newCompetition" prepend-icon="mdi-label" label="Competition Name"/>
                                                    <div class="error-message">{{errors[0]}} </div>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12">
                                                <ValidationProvider name="newDescription" rules="" v-slot="{errors}">
                                                    <v-text-field v-model="newDescription" prepend-icon="mdi-label" label="Description"/>
                                                    <div class="error-message">{{errors[0]}} </div>
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>

                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn 
                                        color="blue darken-1"
                                        text
                                        @click="closeDialog"
                                    >close</v-btn>
                                    <v-btn color="blue darken-1" text @click="handleSubmit(create(reset))">Save</v-btn>
                                </v-card-actions>
                            </v-card>
                    </ValidationObserver>
                </v-dialog>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <div class="align-items-left">
                    <v-expansion-panels>
                        <team-editor v-for="id in teamList" :key="id" :teamId="id"/>
                    </v-expansion-panels>
                </div>

            </v-col>
        </v-row>
    </div>
    
    
</template>
    
<script>
import {mapState, mapActions} from 'vuex'
import TeamEditor from '@/ClubPanes/TeamEditor'
    import {rules} from '@/_helpers/rules'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
    components: {
        TeamEditor,
        ValidationObserver,
        ValidationProvider,
    },
    computed: {
        ...mapState({
            account: state => state.account,
            menuState: state => state.navigatorStore.showBurgerMenu,
            seasons: state => state.seasonStore.data,
            seasonStatus: state => state.seasonStore.status,
            selectedSeason: state => state.navigatorStore.selectedSeason,
//            teams: state => state.teamStore.seasonData,
//            teamList : state => state.teamStore.seasonList,
            teams: state => state.teamStore.data,
            teamList: state => state.teamStore.list,
            teamStatus: state => state.teamStore.status,

        }),
        loading: function() {
            return false
                || this.seasonStatus.loading
        }
    },
    methods: {
        ...mapActions('clubStore', {
            findAllClubsForUser: 'findAllForUser',
        }),

        ...mapActions('tryoutStore', {
            find: 'findAllForSeason',
        }),
        ...mapActions('teamStore', {
            findAllTeamsForSeason: 'findAllForSeason',
            createTeam: 'create', 
        }),
        closeDialog(){
            this.newName=''
            this.newDescription=''
            this.newCompetition=''
            this.teamDialog=false
            this.$refs.form.reset()
        },
        create: function () {
                const newTeam ={
                    name: this.newName,
                    competition: this.newCompetition,
                    description: this.newDescription,
                    seasonId: this.selectedSeason,
                }
                this.createTeam({team: newTeam})
                this.newName=''
                this.newDescription=''
                this.newCompetition=''
                this.$refs.form.reset()
                this.teamDialog=false


        }
    },
    created() {
        this.findAllTeamsForSeason({seasonId: this.selectedSeason})
    },
    data() {
        return {
            rules: rules,
            teamDialog: false,
            newName: '',
            newCompetition: '',
            newDescription: '',
        }
    }


}

</script>