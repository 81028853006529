<template>
    <v-row class="settings-pane">
        <h3>Development Program Details</h3>
        <v-col cols="12">
            <v-text-field 
                label="Program Title" 
                :rules="[rules.required, rules.length]" 
                outlined hide-details="auto" 
                v-model="title"
                v-on:blur="saveDetails"
            />
        </v-col>
    </v-row>
</template>

<script>

import {rules} from '@/_helpers/rules'
import {mapState, mapActions} from 'vuex'
import {v4 as uuidv4} from 'uuid'

export default{
    props: [],
    components:{

    },
    computed: {
        ...mapState({
            events:             state => state.tryoutStore.data,

            selectedEvent:      state => state.navigatorStore.selectedTryout,
        }),
        loading(){
            return false ||
                false;
        },
        title: {
            get(){
                return this.events[this.selectedEvent].title
            },
            set(newValue){
                this.notifyChange()
                this.update({field: 'title', id: this.selectedEvent, value:newValue})
            }
        }
    },
    methods: {
        ...mapActions('tryoutStore', [
            'update',
            'updateTryout',
        ]),
        notifyChange(){
            this.$root.$emit("devstream-settings-changes-made", this.componentId)
        },
        saveDetails(){
            this.updateTryout(this.events[this.selectedEvent])
        }
    },
    watch: {},
    async created(){
        this.componentId = uuidv4()
    },
    data(){
        return {
            componentId: '',
            rules,
        }
    }

}
</script>