import { messageService } from '../_services'
import Vue from 'vue'

const dataService = messageService

function initialiseState(){
    return {
        status: {},
        data: {},
        error: null,
        list: [],
        listByName: {},
        meta: {},
        created: null
    }
}

const state = initialiseState()

const actions = {
    purgeData({commit}){
        commit('purgeData')
    },
    async loadAllForEvent({commit}, payload) {
        try{
            commit('loadRequest') 
            const response = await dataService.loadAllForEvent(payload)
            commit('loadSuccess', response)
        } catch(error) {
            commit('loadFailure', error)
        }
    },
    async load({commit}, payload) {
        try{
            commit('loadRequest') 
            const response = await dataService.load(payload)
            commit('loadSuccess', [response.messages])
        } catch(error) {
            commit('loadFailure', error)
        }
    },
    async create({commit, dispatch}, payload) {
        try{
            commit('createRequest')
            const response = await dataService.create(payload)
            commit('createSuccess', response.msg)
        } catch(error) {
            commit('createFailure', error)
            dispatch('alert/error', error, {root: true})
        }
    },
    propertyUpdate({commit},payload) {
        commit('propertyUpdate', payload)
    },
    async update({commit}, payload){
        try{
            const {message} = payload
            commit('updateRequest')
            const response = await dataService.update(message)
            commit('updateSuccess', response)
        } catch(error) {
            commit('updateFailure', error)
        }
    }
}
function addToLists(list, data) {
    const id = data.id

    list.push(id)

    Vue.set(state.listByName, data.name, data)
    Vue.set(state.data, id, data)
    Vue.set(state.meta, id, {
            delete : false,
    })
}

function sortListByOrder(list) {
        list.sort((a,b) => {
            return state.data[a].name.localeCompare(state.data[b].name)
        })
}

const mutations = {
purgeData(state){
    const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }
    },

    createRequest(state) {
        state.status={loading: true}
        state.error=null
    },
    createSuccess(state, data) {
        const message = data
        state.status={created: true, id:message.id}
        addToLists(state.list, message)
        sortListByOrder(state.list)
        state.error=null
    },
    createFailure(state, error) {
        state.status={}
        state.error=error
    },

    //------ UPDATE -------\
    updateRequest(state) {
        state.internal={loading: true}
        state.error=null
    },
    //eslint-disable-next-line
    updateSuccess(state, data){
        state.internal={updated: true}
        state.error=null
    },
    updateFailure(state, error) {
        state.status={}
        state.error=error
    },

    // ------------- loadClubs ------------- \
    
    loadRequest(state) {
        state.status={loading: true}
        state.error=null
    },
    loadSuccess(state, data) {
        state.status={}
        state.data={}
        state.list=[]

        for(var idx in data){
            addToLists(state.list, data[idx])
        }
        sortListByOrder(state.list)
    },
    loadFailure(state, error) {
        state.status={}
        state.error = error
    },

    //------------ update data -------- \\
    propertyUpdate(state, payload) {
        const metaFields = "delete"
        const {field, id, value} = payload

        if(metaFields.includes(field)){
            state.meta[id][field]=value
            // Manage special fields...
            //switch (field) {
            //}
        } else {
            state.data[id][field]=value
        }
    }

}
export const messageStore = {
    namespaced: true,
    state,
    actions,
    mutations
}