import { commentService } from '../_services'
import Vue from 'vue'

const dataService = commentService

function initialiseState(){
    return {
        status: {},
        data: {},
        list: [],
        error: null,
        cache: {},
        allStaffData: {},
        meta: {},
        listByPlayerAndStaff: {},
    }
}

const state = initialiseState()

const actions = {
    purgeData({commit}){
        commit('purgeData')
    },
       async findAllForEventAndStaff({commit},payload) {
        try{
            const {eventId, userId} = payload
            commit('loadRequest')
            const response = await dataService.findAllForEventAndStaff( eventId, userId)
            commit('loadSuccess', response)
            return response
        } catch(error) {
            commit('loadFailure', error)
        }
    },
    async findAllForEvent({commit},payload) {
        try{
            const {eventId} = payload
            commit('loadAllRequest')
            const response = await dataService.findAllForEvent( eventId)
            commit('loadAllSuccess', response)
        } catch(error) {
            commit('loadAllFailure', error)
        }
    },
    clearAllStaffData({commit}) {
        commit('clearAllStaffData')
    },
    async quietFindAllForEventAndStaff({commit},payload) {
        try{
            const {eventId, userId} = payload
            commit('quietLoadRequest')
            const response =await dataService.findAllForEventAndStaff( eventId, userId)
            commit('loadSuccess', response)
            return response
        } catch(error) {
            commit('loadFailure', error)
        }
    },
    async create({commit, dispatch }, payload){
        try{
            commit('createRequest')
            const response = await dataService.create( payload)
            commit('loadSuccess', response)
        } catch(error){
            commit('loadFailure', error)
            dispatch('alert/error', error, {root: true})
        }
    },
    async update({commit}, payload) {
        try{
            const {commentId, comment, idRemark} = payload
            commit('updateRequest', payload)
            const response = await dataService.update(commentId, comment, idRemark)
            commit('updateSuccess', response)
        } catch(error) {
            commit('updateFailure', error)
        }
    },
    async delete({commit, dispatch}, payload) {
        try{
            const {commentId} = payload
            commit('deleteRequest')
            const response = await dataService.del( commentId)
            commit('deleteSuccess', response)
        } catch(error) {
            commit('deleteFailure', error)
            dispatch('alert/error', error, {root: true})
        }
    },
    async undelete({commit, dispatch}, payload) {
        try{
            const {commentId} = payload
            commit('undeleteRequest')
            const response = await dataService.undelete(commentId)
            commit('undeleteSuccess', response)
        } catch(error) {
            commit('undeleteFailure', error)
            dispatch('alert/error', error, {root: true})
        }
    },
    propertyUpdate({commit},payload) {
        commit('propertyUpdate', payload)
    }
}
function addToLists(data) {
    const id = data.id
        state.list.push(id)
    Vue.set(state.data, id, data)
    Vue.set(state.meta, id, {
            delete : false,
    })
}

function sortListByOrder() {
    /*
        state.list.sort((a,b) => {
            return (''+state.data[a].lastName).localeCompare(state.data[b].lastName)
        })
        */
}

const mutations = {
purgeData(state){
    const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }
    },

    clearAllStaffData(state) {
        state.allStaffData={}
    },

    //< ------------- loadAllTryout ------------- \

    loadAllRequest(state) {
        state.status={loading: true}
        state.error=null
        state.list=[]
    },
    loadAllSuccess(state, data) {
        state.status={}
        state.listByPlayerAndStaff={}
        for(var idx in data){
            const participantId = data[idx].participantId
            const staffId = data[idx].userId
            const comment = data[idx].comment
            if(!state.allStaffData[participantId]) state.allStaffData[participantId] = {}
            state.allStaffData[participantId][staffId]=comment

            if(!state.listByPlayerAndStaff[participantId]) state.listByPlayerAndStaff[participantId]={}
            state.listByPlayerAndStaff[participantId][staffId] = data[idx].comment
        }
    },
    loadAllFailure(state, error) {
        state.status={}
        state.error = error
    },
     // ------------- loadTryout ------------- \
    
     loadRequest(state) {
        state.status={loading: true}
        state.error=null
        state.list=[]
    },
    quietLoadRequest(state) {
        state.status={}
        state.error=null
        state.list=[]
    },
    loadSuccess(state, data) {
        state.status={}
        state.data = data
    },
    loadFailure(state, error) {
        state.status={}
        state.error = error
    },
    //------ CREATE -------\
    createRequest(state) {
        state.status={loading: true}
        state.error=null
    },
    //eslint-disable-next-line
    createSuccess(state, data){
        state.status={updated: true}
        addToLists(data.comment)
        sortListByOrder()
        state.error=null
    },
    createFailure(state, error) {
        state.status={}
        state.error=error
    },
    //------ UPDATE -------\
    updateRequest(state) {
        state.status={updating: true}
        state.error=null
    },
    //eslint-disable-next-line
    updateSuccess(state, data){
        state.status={updated: true}
        state.error=null
        state.data[data.comment.participantId]=data.comment
    },
    updateFailure(state, error) {
        state.status={}
        state.error=error
    },
    //------ Delete -------\
    deleteRequest(state) {
        state.status={deleting: true}
        state.error=null
    },
    //eslint-disable-next-line
    deleteSuccess(state, data){
        state.status={deleted: true}
        state.error=null
    },
    deleteFailure(state, error) {
        state.status={}
        state.error=error
    },
    //------ UnDelete -------\
    undeleteRequest(state) {
        state.status={deleting: true}
        state.error=null
    },
    //eslint-disable-next-line
    undeleteSuccess(state, data){
        state.status={deleted: true}
        state.error=null
    },
    undeleteFailure(state, error) {
        state.status={}
        state.error=error
    },

    //------------ update data -------- \\
    propertyUpdate(state, payload) {
        const metaFields = "delete"
        const {field, id, value} = payload

        if(metaFields.includes(field)){
            state.meta[id][field]=value
            // Manage special fields...
            //switch (field) {
            //}
        } else {
            state.data[id][field]=value
        }
    }

}
export const commentStore = {
    namespaced: true,
    state,
    actions,
    mutations
}