var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var reset = ref.reset;
return [_c('v-card',[_c('v-card-title',[_vm._v(" Team: "+_vm._s(_vm.teamName)+" ")]),_c('v-card-text',[_c('v-row',[_c('div',{staticClass:"card-title"})]),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-img',{attrs:{"src":_vm.teamLogo(),"height":"200px"}})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"teamName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Team Name","hideDetails":"auto"},model:{value:(_vm.teamName),callback:function ($$v) {_vm.teamName=$$v},expression:"teamName"}}),_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Team Gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.genders,"label":"Team Gender","hideDetails":"auto"},model:{value:(_vm.teamGender),callback:function ($$v) {_vm.teamGender=$$v},expression:"teamGender"}}),_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"teamDescription","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Description","hideDetails":"auto"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,true)}),_c('v-textarea',{attrs:{"label":"Training Details","hide-details":"auto"},model:{value:(_vm.training),callback:function ($$v) {_vm.training=$$v},expression:"training"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.closeTeamEditor(reset)}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"disabled":invalid,"color":"primary","text":""},on:{"click":function($event){return _vm.saveTeam(reset)}}},[_vm._v("Save")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }