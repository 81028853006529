import {awsService} from '../_services'

const dataService = awsService

function initialiseState(){
    return {
        status: {},
        data: {},
        list: [],
        error: null,
        cache: {},
        meta: {},
    }
}

const state = initialiseState()

const actions = {
    purgeData({commit}){
        commit('purgeData')
    },
    async deleteAsset({commit}, payload){
        try{
            commit('deleteAssetRequest')
            await dataService.deleteAsset(payload)
        } catch(error){
            console.error('Error deleing AWS asset: ')
        }
    }
}
const mutations = {
purgeData(state){
    const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }
    },
    deleteAssetRequest(){
    }
}

export const awsStore = {
    namespaced: true,
    state,
    actions,
    mutations,
}