import { adminService } from '../_services'
import Vue from 'vue'

const dataService = adminService

function initialiseState(){
    return {
        status: {},
        data: {},
        list: [],
        error: null,
        cache: {},
        meta: {},
        internal: {},
    }
}

const state = initialiseState()

const actions = {
    purgeData({commit}){
        commit('purgeData')
    },
    async findAllForEvent({commit},payload) {
        try{
            const {eventId} = payload
            commit('loadRequest')
            const response = await dataService.findAllForEvent( eventId)
            commit('loadSuccess', response)
        } catch(error) {
            commit('loadFailure', error)

        }
    },
    async create({commit, dispatch }, payload){
        try{
            const {eventId, role, userId} = payload
            commit('createRequest', payload)
            const response=await dataService.create( eventId, role, userId)
            commit('createSuccess', response)

        } catch(error) {
            commit('createFailure', error)
            dispatch('alert/error', error, {root: true})

        }
    },
    async delete({commit, dispatch}, payload) {
        try{
            commit('deleteRequest', payload)
            const response = await dataService.del( payload)
            commit('deleteSuccess', response)

        } catch (error) {
            commit('deleteFailure', error)
            dispatch('alert/error', error, {root: true})
        }
    },
    propertyUpdate({commit},payload) {
        commit('propertyUpdate', payload)
    }
}
function addToLists(data) {
    const id = data.id
    state.list.push(id)
    Vue.set(state.data, id, data)
    Vue.set(state.meta, id, {
            delete : false,
    })
}

function sortListByOrder() {
        state.list.sort((a,b) => {
            return (''+state.data[a].lastName).localeCompare(state.data[b].lastName)
        })
}

const mutations = {
purgeData(state){
    const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }
    },
     // ------------- loadTryout ------------- \
    
     loadRequest(state) {
        state.status={loading: true}
        state.error=null
    },
    loadSuccess(state, data) {
        state.status={}
        state.data={}
        state.list=[]
        for (var idx in data) {
            addToLists(data[idx])
        }
        sortListByOrder()
    },
    loadFailure(state, error) {
        state.status={}
        state.error = error
    },
    //------ CREATE -------\
    createRequest(state, data) {
        state.internal={loading: true, creating: data}
        state.error=null
    },
    //eslint-disable-next-line
    createSuccess(state, data){
        state.internal={updated: true}
        addToLists(data.admin)
        sortListByOrder()
        state.error=null
    },
    createFailure(state, error) {
        state.internal={}
        state.error=error
    },
    //------ Delete -------\
    deleteRequest(state, id) {
        state.internal={deleting: true, target: id}
        state.error=null
    },
    //eslint-disable-next-line
    deleteSuccess(state, data){
        const userId=state.internal.target.userId
        
        Vue.delete(state.data, userId )
        Vue.delete(state.meta, userId)
        var idx=-1
        
        for(var i=0;i<state.list.length;i++){
            if(state.list[i]===userId) {
                idx=i
            }
        }
        if(idx>=0){
            state.list.splice(idx,1)
        }

        state.internal={deleted: true}
        state.error=null

    },
    deleteFailure(state, error) {
        state.internal={}
        state.error=error
    },
    
    //------------ update data -------- \\
    propertyUpdate(state, payload) {
        const metaFields = "delete"
        const {field, id, value} = payload

        if(metaFields.includes(field)){
            state.meta[id][field]=value
            // Manage special fields...
            //switch (field) {
            //}
        } else {
            state.data[id][field]=value
        }
    }

}
export const adminStore = {
    namespaced: true,
    state,
    actions,
    mutations
}