var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"text-align":"left","padding":"0px 10px 0px 10px"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('v-row',[_c('v-col',{staticStyle:{"text-align":"right"},attrs:{"cols":"12"}},[(false && _vm.changes)?_c('v-btn',{staticClass:"save-btn",staticStyle:{"margin-right":"10px"},attrs:{"x-small":"","depressed":"","color":"primary"},on:{"click":_vm.saveDetails},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" Save ")]):_vm._e(),_c('v-btn',{attrs:{"color":"primary","x-small":""},on:{"click":function($event){_vm.dialog=true}}},[_vm._v(" Add new attribute ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"11"}}),_c('v-col',{attrs:{"cols":"1"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('form',{ref:"newAttributeForm",on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.create(reset))}}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("New Attribute")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Attribute Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"auto","prepend-icon":"mdi-rename-box","label":"Attribute Name"},model:{value:(_vm.newAttribute),callback:function ($$v) {_vm.newAttribute=$$v},expression:"newAttribute"}}),_c('div',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"auto","prepend-icon":"mdi-information","label":"Description"},model:{value:(_vm.newDescription),callback:function ($$v) {_vm.newDescription=$$v},expression:"newDescription"}}),_c('div',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"hide-details":"auto","prepend-icon":"mdi-information","label":"Units"},model:{value:(_vm.newUnits),callback:function ($$v) {_vm.newUnits=$$v},expression:"newUnits"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDialog}},[_vm._v("close")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","type":"submit"}},[_vm._v("Save")])],1)],1)],1)])],1)],1),_c('v-expansion-panels',_vm._l((_vm.list),function(id){return _c('attribute-editor',{key:id,attrs:{"attributeId":id}})}),1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }