import {
    sendMessage, 
//    sendMessageV
} from '@/_helpers/service-tools'

export const teamService = {
    findAllForEvent,
    findAllForSeason,
    update,
    link,
    unlink,
    create,
    del,
}

async function findAllForEvent(id) {
    return await sendMessage('GET', 'team/listinevent', {eventId: id}, null)
}

async function findAllForSeason(seasonId) {
    return await sendMessage('GET','team/listinseason', {seasonId}, null)
}

async function update(team) {
    return await sendMessage('PATCH', 'team', {teamId: team.id}, team)
}

async function create(team) {
    return await sendMessage('POST','team',null,team)
}

async function link(teamId, eventId, color) {
    return await sendMessage('PATCH', 'team/link', {teamId}, {eventId, color})
}

async function unlink(teamId, eventId) {
    return await sendMessage('PATCH', 'team/unlink', {teamId}, {eventId})
}

async function del(payload) {
    return await sendMessage('DELETE', 'team', {teamId: payload.teamId, clubId: payload.clubId}, null)
}