<template>
    <v-expansion-panel>
        <v-expansion-panel-header>
            <template v-slot:actions>
                <v-btn color="primary" v-if="changed" @click.stop="save" x-small>Save</v-btn>
                <v-icon>mdi-chevron-down</v-icon>
            </template>
                <div >
                    {{teams[teamId].competition}} :{{teams[teamId].name}}
                </div>

        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <ValidationObserver>
                <v-row>
                    <v-col cols="4">
                        <ValidationProvider name="teamName" rules="required" v-slot="{errors}">
                            <v-text-field label="Team Name" hideDetails="auto" v-model="teamName"/>
                            <div class="error-message">{{errors[0]}} </div>
                        </ValidationProvider>
                    </v-col>
                    <v-col cols="4">
                        <ValidationProvider name="competitionName" rules="required" v-slot="{errors}">
                            <v-text-field label="Competition Name" hideDetails="auto" v-model="competitionName"/>
                            <div class="error-message">{{errors[0]}} </div>
                        </ValidationProvider>
                    </v-col>
                    <v-col cols="4">
                        <ValidationProvider name="teamDescription" rules="required" v-slot="{errors}">
                            <v-text-field label="Description" hideDetails="auto" v-model="description"/>
                            <div class="error-message">{{errors[0]}} </div>
                        </ValidationProvider>
                    </v-col>
                </v-row>
            </ValidationObserver>
        </v-expansion-panel-content>
    </v-expansion-panel>

</template>

<script>

import {ValidationProvider, ValidationObserver} from 'vee-validate'
import { mapActions, mapState } from 'vuex'
export default {
    props: ["teamId"],
    components: {
        ValidationProvider,
        ValidationObserver
    },
    computed : {
        ...mapState({
            teams: state => state.teamStore.data,
        }),
        description: {
            get(){
                return this.teams[this.teamId].description
            },
            set(newValue){
                this.changed=true
                this.updateTeamProp({
                    field: 'description',
                    id: this.teamId,
                    value: newValue
                })
            }
        },
        teamName: {
            get() {
                return this.teams[this.teamId].name
            },
            set(newValue){
                this.changed=true
                this.updateTeamProp({
                    field: 'name',
                    id: this.teamId,
                    value: newValue
                })
            }
        },
        competitionName: {
            get() {
                return this.teams[this.teamId].competition
            },
            set(newValue) {
                this.changed=true
                this.updateTeamProp({
                    field: 'competition',
                    id: this.teamId,
                    value: newValue
                })
            }
        }
    },
    methods: {
        ...mapActions('teamStore', {
            updateTeamProp: 'propertyUpdate',
            updateTeam: 'update',
        }),
        save: function() {
            // eslint-disable-next-line
            const {seasonId, ...updateTeam} = this.teams[this.teamId]

            this.updateTeam({team: updateTeam})
            this.changed=false
        }
    },
    data(){
        return {
            changed: false
        }
    }
}

</script>

<style>

.error-message{
    color: red;
    text-align: left;;
}

</style>