<template>
    <loading v-if='loading'/>
    <div v-else class="root-page">
        <div v-responsive.xs.sm>
            <v-row>
                <v-col cols="8">
                    <div class="pane-heading mb-5">Tryout Homepage</div>
                </v-col>
            </v-row>
            <v-row>
                <v-col v-if="allowAthleteJournal" cols="3" style="margin-top:0px;margin-bottom:auto;">
                    <div class="journal-button" @click="selectPane('JournalAthleteHome')">
                        <v-icon large color="white">mdi-notebook-outline</v-icon>
                        <div style="font-size:small; color:white;" class="text-center">My Journal</div>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <div class="description-box">
                        <div class="description-title">Sessions</div>
                        <v-simple-table dense>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <td></td>
                                        <td>Event Name</td>
                                        <td>Date</td>
                                        <td>Venue</td>
                                        <td>Time</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(id, idx) in sessionList" :key="id">
                                        <td :class="{strike: oldDate(sessions[id].startDateTime)}"> {{ idx+1 }}</td>
                                        <td :class="{strike: oldDate(sessions[id].startDateTime)}">{{ sessions[id].title }}</td>
                                        <td :class="{strike: oldDate(sessions[id].startDateTime)}">{{ moment(sessions[id].startDateTime).format('ddd Do MMM, YYYY')  }}</td>
                                        <td :class="{strike: oldDate(sessions[id].startDateTime)}">{{ sessions[id].venue }}</td>
                                        <td :class="{strike: oldDate(sessions[id].startDateTime)}">{{ moment(sessions[id].startDateTime).format('HH:mm') }} - {{ moment(sessions[id].endDateTime).format('HH:mm') }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </div>
                </v-col>

            </v-row>
        </div>
        <div v-responsive.md.lg.xl>
            <v-row>
                <v-col cols="4">
                    <div class="pane-heading">Tryout Homepage</div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="1" style="margin-top:0px;margin-bottom:auto;">
                    <div v-if="allowAthleteJournal" class="journal-button mb-5" @click="selectPane('JournalAthleteHome')">
                        <v-icon large color="white">mdi-notebook-outline</v-icon>
                        <div style="font-size:small; color:white;" class="text-center">My Journal</div>
                    </div>

                </v-col>
                <v-col cols="8">
                    <div class="description-box">
                        <div class="description-title">Sessions</div>
                        <v-simple-table dense>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <td></td>
                                        <td>Event Name</td>
                                        <td>Date</td>
                                        <td>Venue</td>
                                        <td>Time</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(id, idx) in sessionList" :key="id">
                                        <td :class="{strike: oldDate(sessions[id].startDateTime)}"> {{ idx+1 }}</td>
                                        <td :class="{strike: oldDate(sessions[id].startDateTime)}">{{ sessions[id].title }}</td>
                                        <td :class="{strike: oldDate(sessions[id].startDateTime)}">{{ moment(sessions[id].startDateTime).format('ddd Do MMM, YYYY')  }}</td>
                                        <td :class="{strike: oldDate(sessions[id].startDateTime)}">{{ sessions[id].venue }}</td>
                                        <td :class="{strike: oldDate(sessions[id].startDateTime)}">{{ moment(sessions[id].startDateTime).format('HH:mm') }} - {{ moment(sessions[id].endDateTime).format('HH:mm') }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import Loading from '@/components/loading'
import {mapState, mapActions} from 'vuex'
import {CSColors} from '@/_helpers/constants'

export default{
    props: [],
    components:{
        Loading
    },
    computed: {
        ...mapState({
            sessions:                   state => state.sessionStore.data,
            sessionList:                state => state.sessionStore.list,

            tryouts:                    state => state.tryoutStore.data,

            selectedTryout:             state => state.navigatorStore.selectedTryout,
        }),
        loading(){
            return false ||
                false;
        },
        allowAthleteJournal(){
            if(  this.tryouts[this.selectedTryout].athlete) return true
            return false
        },

    },
    methods: {
        ...mapActions('sessionStore', [
            'loadAllSessionsForEvent'
        ]),
        ...mapActions('navigatorStore', {
            selectPane: 'selectPane',
        }),
        oldDate(date){
            return new Date(date) < new Date()
        },
    },
    watch: {},
    async created(){
        await this.loadAllSessionsForEvent({eventId: this.selectedTryout})

    },
    data(){
        return {
            CSColors,
        }
    }

}
</script>

<style scoped>

.description-box {
    border-radius: 10px;
    border-width: 1px;
    border-color: grey;
    border-style: solid;
    margin-top: 10px;
    padding-bottom: 10px;
}

.description-title {
    text-align: center;
    font-size: small;
    color:grey;
    margin-top: -10px;
    margin-left:20px;
    background-color: white;
    width: 100px;
}

.journal-button{
    background-color: var(--cs2-lev1);
    display:flex;
    flex-flow:column;
    padding:5px;
    border-radius:20px;
    min-height: 100%;
}
.journal-button:hover{
    filter: brightness(85%);
}

</style>