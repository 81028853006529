<template>
     <v-dialog v-model="editRemarkDialog" width="90vw">
        <template v-slot:activator="{on,attrs}">
            <div v-if="journalEntryId" 
                class="edit-pill" 
                :style="'background-color: '+ color "
                v-on="on"
                v-bind="attrs"
            >
                <v-icon v-if="iconOnly" style="color:white">
                    mdi-text-box-edit-outline
                </v-icon>
                <div v-else class="edit-pill-text">
                    Edit Entry
                </div>
            </div>
            <div v-else style="margin-right:5px;">
                <v-btn v-if="tinyButton" small width="100%" :style="'background-color: '+color+';padding:0px 5px 0px 5px;'" v-on="on" v-bind="attrs" >
                    <div v-if="$vuetify.breakpoint.mdAndUp && type==='broadcast'" style="font-weight:600;padding-right:5px;;color:white">Add Broadcast</div>
                    <v-icon v-if="type==='broadcast'" color="white">mdi-broadcast</v-icon>
                    <div v-if="$vuetify.breakpoint.mdAndUp && type==='coachRemark'" style="font-weight:600;padding-right:5px;;color:white">Add Coach Remark</div>
                    <v-icon v-if="type==='coachRemark'" color="white">mdi-whistle-outline </v-icon>
                    <div v-if="$vuetify.breakpoint.mdAndUp && type==='staffNote'" style="font-weight:600;padding-right:5px;;color:white">Add Staff Note</div>
                    <v-icon v-if="type==='staffNote'" color="white">mdi-book-lock-outline </v-icon>
                    <div v-if="$vuetify.breakpoint.mdAndUp && type==='reflection'" style="font-weight:600;padding-right:5px;;color:white">Add Reflection</div>
                    <v-icon v-if="type==='reflection'" color="white">mdi-notebook-outline</v-icon>
                    <div v-if="$vuetify.breakpoint.mdAndUp && type==='participantGoal'" style="font-weight:600;padding-right:5px;;color:white">Add Goal</div>
                    <v-icon v-if="type==='participantGoal'" color="white">mdi-bullseye-arrow</v-icon>
                </v-btn>
                <v-btn v-else small width="100%" :style="'background-color: '+color+';margin-top:5px;'" v-on="on" v-bind="attrs" >
                    <div v-if="type==='broadcast'" style="font-weight:600;">New Broadcast Message</div>
                    <div v-if="type==='coachRemark'" style="font-weight:600;">New Coaches Remark</div>
                    <div v-if="type==='staffNote'" style="color:white;font-weight:600;">New Staff Note</div>
                    <div v-if="type==='reflection'" style="color:white;font-weight:600;">New Reflection</div>
                    <div v-if="type==='participantGoal'" style="color:white;font-weight:600;">New Goal</div>
                </v-btn>
            </div>
        </template>
        <v-card>
            <v-card-title v-if="type==='reflection'">
                Edit Journal Reflection
            </v-card-title>
            <v-card-title v-else-if="type==='participantGoal'">
                Edit New Goal
            </v-card-title>
            <v-card-title v-else-if="type==='coachRemark'">
                Edit Coaches Remark
            </v-card-title>
            <div v-else-if="type==='staffNote'">
                <v-card-title>
                    Edit Staff Note
                </v-card-title>
                <v-card-subtitle >
                   Only staff can see these notes
                </v-card-subtitle>
            </div>
            <div v-else-if="type==='broadcast'">
                <v-card-title >
                    Add Broadcast Note
                </v-card-title>
                <v-card-subtitle >
                    All participants will see this note in their journal
                </v-card-subtitle>
            </div>
            <v-card-text>
                <div id="toolbar" style="height:50px">
                    <span class="ql-formats">
                        <select class="ql-header" defaultValue="3">
                            <option value="1">Heading</option>
                            <option value="2">Subheading</option>
                            <option value="3">Normal</option>
                        </select>

                        <button class="ql-bold"></button>
                        <button class="ql-italic"></button>
                        <button class="ql-underline"></button>

                        <select class="ql-color"/>
                    </span>

                    <span class="ql-formats">
                        <button class="ql-list" value="ordered" />
                        <button class="ql-list" value="bullet" />
                        <button class="ql-indent" value="-1" />
                        <button class="ql-indent" value="+1" />
                    </span>

                    <span class="ql-formats">
                        <button class="ql-divider"/>
                        <button class="ql-link"/>
                    </span>

                    <span class="ql-formats">
                        <button class="ql-image"/>
                    </span>

                    <span class="ql-formats">
                        <button class="ql-fileupload">
                            <svg class="paperclip" fill="#000000" height="200px" width="200px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 30.34 30.34" xml:space="preserve"><g id="ico_ico"> <g> <path d="M22.562,12.491c0,0,1.227-0.933,0.293-1.866c-0.934-0.933-1.842,0.271-1.842,0.271l-9.389,9.391 c0,0-2.199,2.838-3.871,1.122c-1.67-1.718,1.121-3.872,1.121-3.872l12.311-12.31c0,0,2.873-3.165,5.574-0.466 c2.697,2.7-0.477,5.579-0.477,5.579L12.449,24.173c0,0-4.426,5.113-8.523,1.015s1.066-8.474,1.066-8.474L15.494,6.209 c0,0,1.176-0.982,0.295-1.866c-0.885-0.883-1.865,0.295-1.865,0.295L1.873,16.689c0,0-4.549,4.989,0.531,10.068 c5.08,5.082,10.072,0.533,10.072,0.533l16.563-16.565c0,0,3.314-3.655-0.637-7.608s-7.607-0.639-7.607-0.639L6.543,16.728 c0,0-3.65,2.969-0.338,6.279c3.312,3.314,6.227-0.39,6.227-0.39L22.562,12.491z"></path> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </g> </g></svg>
                        </button>
                    </span>

                    <v-spacer/>
                </div>
                <vue-editor 
                    ref="editor" 
                    v-model="entryText" 
                    :editorOptions="editorSettings" 
                    :customModules="customModulesForEditor" 
                    use-custom-image-handler
                    @image-removed="handleImageRemoved"
                    @image-added="handleImageAdded"
                    width="100%"                         
                /> 
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn text color="secondary" @click="editRemarkDialog=false">Cancel</v-btn>
                <v-btn text color="primary" @click="saveRemark">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

import 'quill/dist/quill.core.css'
import { VueEditor } from "vue2-editor"
import ImageResize from 'quill-image-resize'
import {mapState, mapActions} from 'vuex'

export default{
    props: {
        journalEntryId: {
            type: String,
            default: null,

        },
        color: {
            type: String,
            default: 'black',
            required: true,
        },
        type: {
            type: String,
            default: null,
            required: true,
        },
        journalOwnerId: {
            type: String,
            default: null,
            required: true,
        },
        iconOnly: {
            type: Boolean,
            default: false
        },
        tinyButton: {
            type: Boolean,
            default: false,
        }
    },
    components:{
        VueEditor,
    },

    computed: {
        ...mapState({
            selectedSeason:         state => state.navigatorStore.selectedSeason,
            selectedNav:            state => state.navigatorStore.selectedNav,
            selectedTryout:         state => state.navigatorStore.selectedTryout,
            selectedParticipant:    state => state.navigatorStore.selectedParticipant,

            journalEntries:         state => state.journalentryStore.data,
            participants:           state => state.participantStore.data,



        }),
        entryText: {
            get() {
                if(!this.journalEntryId){
                    return this.newText
                } else {
                    return this.journalEntries[this.journalEntryId].content
                }
            },
            set(newValue){
                if(!this.journalEntryId){
                    this.newText=newValue
                } else {
                    this.journalPropertyUpdate({field: 'content', id: this.journalEntryId, value: newValue})
                }
            }
        }
    },
    methods: {
        ...mapActions('journalentryStore', [
            'journalPropertyUpdate',
            'updateJournalEntry',
            'createNewJournalEntry',
            'createTeamJournalEntry',
        ]),
        ...mapActions('awsStore', {
            deleteAsset: 'deleteAsset'
        }),

        async saveRemark(){
            // Do we have an ID? If not it's a new entry....

            if(!this.journalEntryId){
                try{
                    if(this.selectedNav==='Team'){
                        await this.createTeamJournalEntry({
                            teamId: this.selectedTryout,
                            journalOwnerId: this.journalOwnerId,
                            type: this.type,
                            content: this.entryText,
                            parentId: null, 
                        })
                    } else {
                        await this.createNewJournalEntry({
                            eventId: this.selectedTryout,
                            journalOwnerId: this.journalOwnerId,
                            type: this.type,
                            content: this.entryText,
                            parentId: null, 
                        })
                    }
                    this.editRemarkDialog=false
                } catch(error) {
                    this.$toast.error('Failed to create journal entry. please try again')
                }
            } else {
                try{
                    await this.updateJournalEntry({
                        journalId: this.journalEntryId,
                        content: this.entryText
                    })
                    this.$toast.success('Journal Entry Updated')
                    this.editRemarkDialog=false
                    this.editorText=null
                } catch(error) {
                    this.$toast.error('Failed to update journal entry. please try again')
                }

            }
            // Clear out the saved text.
            this.newText=''
        },

        async imageHandler(){
            const input = document.createElement('input')
            input.setAttribute('type','file')
            input.setAttribute('accept','image/*')
            input.click()

            input.onchange = async ()=>{
                const self=this
                var file = input.files[0]
                const maxFileSize=1000000
                var filesize=file.size
                if(filesize > maxFileSize){
                    this.$store.dispatch('alert/error', 'Your file is too large (Must be less than 1MB)', {root: true})
                    return
                }

                // OK Throw it up into S3

                const apiUrl = process.env.VUE_APP_CSAPI
                const localUser = JSON.parse(localStorage.getItem('user'))
                const url = new URL(`${apiUrl}/aws/uploadPath`)

                url.searchParams.append('type', `Upload Journal Attachment`)
                url.searchParams.append('filename', `${file.name}`)
                url.searchParams.append('sessionId', this.selectedSeason)

                const signedURL = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type':'application/json',
                        'Authorization': 'Bearer '+localUser.token,
                    }
                }).
                then(
                    response => {
                        return response.json()
                    },
                    error => {
                        console.error(`Error: ${error}`)
                    })

                if(signedURL.code) {
                    if(signedURL.code==='1010') {
                        this.$store.dispatch('alert/error', 'Your licence does not permit you to upload any more activity diagrams.', {root: true})
                    }
                }
                // Let's throw the file into AWS
                const result = await fetch(signedURL.uploadURL, {
                    method: 'PUT',
                    headers: {
                        'Origin': 'https://www.coachsight.net',
                    },
                    body: file
                })
                .then(response => {
                    return response
                })

                if(result.status == '200'){
                    this.$store.dispatch('alert/success', 'Your journal diagram has been uploaded', {root: true})
                    const element = self.$refs.editor
                    if(!element){
                        console.error('COULDN"T FIND THE EDITOR')
                        
                    } else {
                        self.$refs.editor.quill.focus()
                        const range = element.quill.getSelection()
                        //const imageURL = `https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/userimages/activityDiagrams/${this.account.user.details.id}/${signedURL.fileuuid}.${ext}`
                        const uploadURL = `${process.env.VUE_APP_CS_S3}/${signedURL.newfilename}`
                        self.$refs.editor.quill.insertEmbed(range.index,'image',uploadURL)

                    }


                }


            }
        },
        async fileuploadHandler(){
            const input = document.createElement('input')
            input.setAttribute('type','file')
            input.setAttribute('accept','application/pdf')
            input.click()

            input.onchange = async ()=>{
                const self=this
                var file = input.files[0]
                const maxFileSize=600000
                var filesize=file.size
                if(filesize > maxFileSize){
                    this.$store.dispatch('alert/error', 'Your file is too large (Must be less than 500KB)', {root: true})
                    return
                }

                // OK Throw it up into S3

                const apiUrl = process.env.VUE_APP_CSAPI
                const localUser = JSON.parse(localStorage.getItem('user'))
                const url = new URL(`${apiUrl}/aws/uploadPath`)

                url.searchParams.append('type', `Upload Journal Attachment`)
                url.searchParams.append('filename', `${file.name}`)
                url.searchParams.append('seasonId', `${this.selectedSeason}`)
                const signedURL = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type':'application/json',
                        'Authorization': 'Bearer '+localUser.token,
                    }
                }).
                then(
                    response => {
                        return response.json()
                    },
                    error => {
                        console.error(`Error: ${error}`)
                    })

                if(signedURL.code) {
                    if(signedURL.code==='1010') {
                        this.$store.dispatch('alert/error', 'Your licence does not permit you to upload any journal attachments.', {root: true})
                    }
                }
                // Let's throw the file into AWS
                const result = await fetch(signedURL.uploadURL, {
                    method: 'PUT',
                    headers: {
                        'Origin': 'https://www.coachsight.net',
                    },
                    body: file
                })
                .then(response => {
                    return response
                })

                if(result.status == '200'){
                    this.$store.dispatch('alert/success', 'Your journal attachment has been uploaded', {root: true})
                    const element = this.$refs.editor
                    if(!element){
                        console.error('COULDN"T FIND THE EDITOR')
                    } else {
                        self.$refs.editor.quill.focus()
                        const range = element.quill.getSelection()
                        const uploadURL = `${process.env.VUE_APP_CS_S3}/${signedURL.newfilename}`

                        self.$refs.editor.quill.insertText(range.index,'New Attachment', 'link', uploadURL)
                    }
                } else {
                    console.error('Problem uploading to S3')
                }
            }
        },
        async handleImageRemoved(image){
            this.deleteAsset({key:image, clubId: this.selectedClub, eventId: this.selectedTryout}) 
        },
        async handleImageAdded(image){
            const self=this

            var file = image
            const maxFileSize=1000000
            var filesize=file.size
            if(filesize > maxFileSize){
                this.$store.dispatch('alert/error', 'Your file is too large (Must be less than 1MB)', {root: true})
                return
            }

            // OK Throw it up into S3

            const apiUrl = process.env.VUE_APP_CSAPI
            const localUser = JSON.parse(localStorage.getItem('user'))
            const url = new URL(`${apiUrl}/aws/uploadPath`)

            url.searchParams.append('type', `Upload Journal Attachment`)
            url.searchParams.append('filename', `${file.name}`)
            url.searchParams.append('seasonId', this.selectedSeason)


            
            const signedURL = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type':'application/json',
                    'Authorization': 'Bearer '+localUser.token,
                }
            }).
            then(
                response => {
                    return response.json()
                },
                error => {
                    console.error(`Error: ${error}`)
                })

            if(signedURL.data && signedURL.data.code) {
                if(signedURL.data.code===1010) {
                    this.$store.dispatch('alert/error', 'Your licence does not permit you to upload any more journal diagrams.', {root: true})
                }
            }
            // Let's throw the file into AWS
            const result = await fetch(signedURL.uploadURL, {
                method: 'PUT',
                headers: {
                    'Origin': 'https://www.coachsight.net',
                },
                body: file
            })
            .then(response => {
                return response
            })

            if(result.status == '200'){
                this.$store.dispatch('alert/success', 'Your activity diagram has been uploaded', {root: true})
                const element = self.$refs.editor
                if(!element){
                    console.error('COULDN"T FIND THE EDITOR')
                    
                } else {
                    self.$refs.editor.quill.focus()
                    const range = element.quill.getSelection()
                    const imageURL = `${process.env.VUE_APP_CS_S3}/${signedURL.newfilename}`
                    self.$refs.editor.quill.insertEmbed(range.index,'image',imageURL)

                }


            }
        },

    },
    watch: {
    },
    async mounted(){
    },
    async created(){
    },
    data(){
        return {
            newText: '',
            editRemarkDialog: false,
            editorText: '',
            editorSettings: {
                modules:{
                    imageResize: {},
                    divider: true, 
                    toolbar: {
                        container:  "#toolbar",
                        handlers: {
                            image: this.imageHandler,
                            fileupload: () => {this.fileuploadHandler()}
                        }
                    }
                }
            },
            customModulesForEditor: [
                { alias:    "imageResize", module:ImageResize }
            ]
        }
    }

}
</script>

<style scoped>

.paperclip{

}

.paperclip:hover{
    stroke:blue;
}

.edit-pill {
    display:inline-flex;
    border-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 0px 10px 0px 10px;
    height:1.5em;
    cursor:pointer
}

.edit-pill-text{
    font-weight:900;
    color: white;
    margin-top:auto;
    margin-bottom:auto;
    font-size:0.8em;

}
</style>