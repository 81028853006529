<template>
    <div>
            Your Payment Failed. Please close this window and re-try your payment.
        </div>
    
    </template>
    <script>
    
    export default{
        props: [],
        components: {},
        computed: {},
        methods: {},
        data(){
            return {
    
            }
        }
    }
    
    </script>