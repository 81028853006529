<template>
    <div>
        
        <FSLoading :isLoading="loadingStripe"/>
        <div style="margin:50px 20px 10px 20px;">
            CoachSight uses Stripe to process payments. Stripe allows your athletes to pay using a variety of cards and payment types, with competitive transaction fees. 
            To accept payments into your account, please setup a free Stripe account and connect it to CoachSight by clicking on the button below

        
        </div>
        <div style="margin:50px 20px 10px 20px;">
            <v-btn @click="connect" :disabled="loadingStripe">
                Setup Payments Using Stripe
            </v-btn>

        </div>
    </div>

</template>

<script>
import { mapActions, mapState } from 'vuex';
import FSLoading from '@/components/FSLoading'

//import Stripe from 'stripe'

export default {
    props: [],
    components: {
        FSLoading,
    },
    computed: {
        ...mapState({
            clubs:      state => state.clubStore.data,

            stripeInfo: state => state.stripeStore.data,

            selectedClub:   state => state.navigatorStore.selectedClub,
            data: state => state.stripeStore.data,
        })
    },
    methods: {
        ...mapActions('stripeStore', {
            onboardConnect: 'onboardConnect',
        }),
        ...mapActions('clubStore', {
            updateClub: 'update',
            updateClubProp: 'propertyUpdate',
        }),
        async connect(){
            
            try {
                this.loadingStripe=true
                await this.onboardConnect({clubId: this.selectedClub})
                
                window.location=this.stripeInfo.accountLinkURL

            } catch (error) {
                console.error(error)
            }


        }
    },
    created(){
       
    },
    data() {
        return {
            display: 'freshAccount',
            loadingStripe: false,
            stripe: null
        }
    }
}
</script>

<style scoped>

</style>