import { sportService } from "../_services";
import Vue from 'vue'

function initialiseState(){
    return {
        status: {},
        list: {},
        data: {},
        meta: {},
        error: null,
        internal: {}
    }
}

const state = initialiseState()

const actions = {
    purgeData(){
        // No need to purge the sports tables
        //commit('purgeData')
    },
    async findAll({commit}){
        try{

            commit('findAllRequest')
            const response=await sportService.findAll()
            commit('findAllSuccess', response.sports)
            return response.sports
        } catch(error) {
            commit('findAllFailure', error)
            console.error('Failure')
//            throw new Error({message: 'findAll sport failed', error})
        }
    }
}

function addToLists(data){
    const id = data.id

    state.list.push(id)
    Vue.set(state.data, id, data)
    Vue.set(state.meta, id, {
        delete: false
    })
}

const mutations = {
    purgeData(state){
        const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }
    },
    findAllRequest(state){
        state.status={loading:true}
        state.error=null
    },
    findAllSuccess(state, list){
        state.status={}
        state.list=[]
        state.data={}
        state.meta={}
        state.internal={}

        for (var idx in list){
            addToLists(list[idx])
        }
    },
    findAllFailure(error){
        state.error=error
        state.status={}
    }
}

export const sportStore = {
    namespaced: true,
    state,
    actions,
    mutations
}