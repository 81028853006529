import {sendMessage} from '@/_helpers/service-tools'

export const appquestionService = {
    findAllForEvent,
    addQuestion,
    updateQuestion,
    deleteQuestion,
}

async function findAllForEvent(id) {
    return await sendMessage('GET', 'appquestion/list',{eventId: id})
}

async function addQuestion(question) {
    return await sendMessage('POST', 'appquestion', null, question)

}

async function updateQuestion(question) {
    return await sendMessage('PATCH','appquestion', {id: question.id}, question )
}

async function deleteQuestion(questionId) {
    return await sendMessage('DELETE','appquestion', {id: questionId}, null)
}

