import {sendMessage} from '@/_helpers/service-tools'

export const stripeService = {
    onboardConnect,
    onboardSuccess,
    tryoutCharge,
    offerCharge,
    packageCharge,
}

async function onboardConnect(payload) {
    return await sendMessage('GET', 'stripe/onboard/connect', {clubId: payload.clubId}, null)
}

async function onboardSuccess(payload) {
    return await sendMessage('PATCH', 'stripe/onboard/success', {clubId: payload.clubId}, null)
}

async function tryoutCharge(payload){
    return await sendMessage('POST','stripe/tryoutcharge', null, {eventId: payload.eventId, participantId: payload.participantId})
}

async function offerCharge(payload){
    return await sendMessage('POST','stripe/offercharge', null, {participantId: payload.participantId})
}

async function packageCharge(payload){
    return await sendMessage('POST','stripe/packagecharge', null, {clubId: payload.clubId, packageId: payload.packageId, qty: payload.qty})
}