<template>
    <ValidationObserver v-slot="{invalid,  reset}">

        <v-card >
            <v-card-title>
                        Team: {{teamName}}
            </v-card-title>
            <v-card-text >
                <v-row >
                    <div class="card-title">
                    </div>
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <v-img 
                            :src="teamLogo()"
                            height="200px"
                        />
                    </v-col>
                    <v-col>
                                <ValidationProvider name="teamName" rules="required" v-slot="{errors}">
                                    <v-text-field label="Team Name" hideDetails="auto" v-model="teamName"/>
                                    <div class="error-message">{{errors[0]}} </div>
                                </ValidationProvider>
                                <ValidationProvider name="Team Gender" rules="required" v-slot="{errors}">
                                    <v-select :items="genders" label="Team Gender" hideDetails="auto" v-model="teamGender"/>
                                    <div class="error-message">{{ errors[0] }}</div>
                                </ValidationProvider>
                                <ValidationProvider name="teamDescription" rules="required" v-slot="{errors}">
                                    <v-text-field label="Description" hideDetails="auto" v-model="description"/>
                                    <div class="error-message">{{errors[0]}} </div>
                                </ValidationProvider>

                                <v-textarea label="Training Details" v-model="training" hide-details="auto"/>
                    </v-col>

                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn text @click="closeTeamEditor(reset)">Cancel</v-btn>
                <v-btn :disabled="invalid" color="primary" text @click="saveTeam(reset)">Save</v-btn>
            </v-card-actions>
        </v-card>
    </ValidationObserver>
</template>

<script>

import {ValidationProvider, ValidationObserver} from 'vee-validate'
import { mapActions, mapState } from 'vuex'
export default {
    props: ["teamId"],
    components: {
        ValidationProvider,
        ValidationObserver
    },
    computed : {
        ...mapState({
            teams:      state => state.teamStore.data,
            teamStatus: state => state.teamStore.status,
            teamError:  state => state.teamStore.error,

            selectedSeason: state => state.navigatorStore.selectedSeason,
        }),
        description: {
            get(){
                if(!this.teamId) return this.newTeamDescription
                return this.teams[this.teamId].description
            },
            set(newValue){
                if(!this.teamId){
                    this.newTeamDescription=newValue
                    return 
                }
                if(!this.origDesc) this.origDesc=this.teams[this.teamId].description
                this.changed=true
                this.updateTeamProp({
                    field: 'description',
                    id: this.teamId,
                    value: newValue
                })
            }
        },
        teamName: {
            get() {
                if(!this.teamId) return this.newTeamName
                return this.teams[this.teamId].name
            },
            set(newValue){
                if (!this.teamId) {
                    this.newTeamName=newValue
                    return 
                }
                if(!this.origName) this.origName=this.teams[this.teamId].name
                this.changed=true
                this.updateTeamProp({
                    field: 'name',
                    id: this.teamId,
                    value: newValue
                })
            }
        },
        teamGender: {
            get(){
                if(!this.teamId) return this.newTeamGender
                return this.teams[this.teamId].gender
            },
            set(newValue){
                if(!this.teamId) {
                    this.newTeamGender=newValue
                    return 
                }
                if(!this.origGender) this.origGender=this.teams[this.teamId].gender
                this.changed=true
                this.updateTeamProp({
                    field: 'gender',
                    id: this.teamId, 
                    value: newValue
                })
            }
        },
        training: {
            get(){
                if(!this.teamId) return this.newTeamTraining
                return this.teams[this.teamId].training
            },
            set(value){
                if(!this.teamId){
                    this.newTeamTraining=value
                    return
                }
                if(!this.origTraining) this.origTraining = this.teams[this.teamId].training
                this.changed=true
                this.updateTeamProp({
                    field: "training",
                    id: this.teamId,
                    value: value
                })
            }
        }

    },
    methods: {
        ...mapActions('teamStore', {
            updateTeamProp: 'propertyUpdate',
            updateTeam: 'update',
            createTeam: 'create',
        }),
        save: function() {
            // eslint-disable-next-line
            const {seasonId, ...updateTeam} = this.teams[this.teamId]

            this.updateTeam({team: updateTeam})
            this.changed=false
        },
        teamLogo(){
            if(this.teamId && this.teams[this.teamId].logo!==null) return this.teams[this.teamId].logo
            if(this.teamGender==="Female") return "https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/app_assets/Team-Female.jpg"
            if(this.teamGender==="Male") return "https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/app_assets/Team-Male.jpg"
            if(this.teamGender==="Mixed") return "https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/app_assets/Team-Mixed.jpg"
            return "https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/app_assets/Team-Unspecified.jpg"
        },
        closeTeamEditor(reset){
            reset()
            if(this.origName) this.teamName = this.origName
            if(this.origDesc) this.teamDesc = this.origDesc
            if(this.origGender) this.teamGender = this.origGender
            this.$root.$emit("close-team-editor")
        },
        async saveTeam(reset){
            reset()
                if(!this.teamId) {
                    await this.createTeam({team: {
                        name: this.newTeamName,
                        gender: this.newTeamGender,
                        description: this.newTeamDescription,
                        seasonId: this.selectedSeason,
                    }})
                    this.$root.$emit("close-team-editor")
                } else {
                    // eslint-disable-next-line
                    const {seasonId, ...updateTeam} = this.teams[this.teamId]
                    this.$root.$emit("close-team-editor")
                    this.updateTeam({team: updateTeam})
                }
        }
    },
    data(){
        return {
            changed: false,
            genders: ["Unspecified", "Mixed", "Male", "Female"],
            origName: null,
            origDesc: null,
            origGender: null,
            newTeamName: null,
            newTeamDescription: null,
            newTeamGender: 'Unspecified',
            newTeamTraining: '',

        }
    }
}

</script>

<style scoped>

.card-title {
    font-weight: 600;
}
.addedit-card {
    padding:40px;
    height:100%;
}
.error-message{
    color:var(--error-red);
    text-align: left;;
}

</style>