<template>
    <loading v-if='loading'/>
    <div v-else class="root-page">
        <div v-responsive.xs.sm>
            <v-row class="subpage-frame">
                <v-col class="no-boundaries">
                    <div class="pane-heading">Development Program Homepage</div>
                </v-col>
            </v-row>
            <v-row class="no-boundaries">
                <v-col cols="12">
                    <div v-if="authorizedForEvent(['headcoach','admin','programcoach','owner'])" class="settings-button mb-5" @click="selectPane('DevStreamSettings')">
                        <v-icon large color="white">mdi-cog-outline</v-icon>
                        <div style="font-size:small; color:white;" class="text-center">Setup</div>
                    </div>
                    <div v-if="authorizedForEvent(['headcoach','admin','programcoach','owner', 'selector'])" class="settings-button mb-5" @click="selectPane('DevStreamEpisodes')">
                        <v-icon large color="white">mdi-clipboard-outline</v-icon>
                        <div style="font-size:small; color:white;" class="text-center">Episodes</div>
                    </div>
                    <div v-if="authorizedForEvent(['headcoach','admin','programcoach','owner', 'selector'])" class="settings-button mb-5" @click="selectPane('DevStreamNewEpisode')">
                        <v-icon large color="white">mdi-clipboard-plus-outline</v-icon>
                        <div style="font-size:small; color:white;" class="text-center">New Episode</div>
                    </div>
                    <div v-if="authorizedForEvent(['athlete'])" class="journal-button mb-5" @click="selectPane('JournalAthleteHome')">
                        <v-icon large color="white">mdi-notebook-outline</v-icon>
                        <div style="font-size:small; color:white;" class="text-center">My Journal</div>
                    </div>
                    <div class="settings-button mb-5" @click="selectPane('JournalHome')">
                        <v-icon large color="white">mdi-notebook-outline</v-icon>
                        <div style="font-size:small; color:white;" class="text-center">Journal Library</div>
                    </div>
                    <div v-if="authorizedForEvent(['headcoach','admin','programcoach','owner' ])" class="settings-button mb-5" @click="selectPane('DevStreamEventReports')">
                        <v-icon large color="white">mdi-chart-bell-curve-cumulative</v-icon>
                        <div style="font-size:small; color:white;" class="text-center">Program Reports</div>
                    </div>
                    <div v-if="authorizedForEvent(['programcoach' ])" class="settings-button mb-5" @click="selectPane('DevStreamProgramCoachOverview')">
                        <v-icon large color="white">mdi-binoculars</v-icon>
                        <div style="font-size:small; color:white;" class="text-center">Program Coach Overview</div>
                    </div>
                </v-col>
                <v-col cols="3">
                    <!-- {{ capitalize($pluralize(events[selectedEvent].participantPronoun)) }}
                -->
                </v-col>
            </v-row>
        </div>
        <div v-responsive.md.lg.xl>
            <v-row class="subpage-frame">
                <v-col class="no-boundaries">
                    <div class="pane-heading">Development Program Homepage</div>
                </v-col>
            </v-row>
            <v-row class="no-boundaries">
                <v-col cols="2">
                    <div v-if="authorizedForEvent(['headcoach','admin','programcoach','owner'])" class="settings-button mb-5" @click="selectPane('DevStreamSettings')">
                        <v-icon large color="white">mdi-cog-outline</v-icon>
                        <div style="font-size:small; color:white;" class="text-center">Setup</div>
                    </div>
                    <div v-if="authorizedForEvent(['headcoach','admin','programcoach','owner', 'selector'])" class="settings-button mb-5" @click="selectPane('DevStreamEpisodes')">
                        <v-icon large color="white">mdi-clipboard-outline</v-icon>
                        <div style="font-size:small; color:white;" class="text-center">Episodes</div>
                    </div>
                    <div v-if="authorizedForEvent(['headcoach','admin','programcoach','owner', 'selector'])" class="settings-button mb-5" @click="selectPane('DevStreamNewEpisode')">
                        <v-icon large color="white">mdi-clipboard-plus-outline</v-icon>
                        <div style="font-size:small; color:white;" class="text-center">New Episode</div>
                    </div>
                    <div v-if="authorizedForEvent(['athlete'])" class="journal-button mb-5" @click="selectPane('JournalAthleteHome')">
                        <v-icon large color="white">mdi-notebook-outline</v-icon>
                        <div style="font-size:small; color:white;" class="text-center">My Journal</div>
                    </div>
                    <div class="settings-button mb-5" @click="selectPane('JournalHome')">
                        <v-icon large color="white">mdi-notebook-outline</v-icon>
                        <div style="font-size:small; color:white;" class="text-center">Journal Library</div>
                    </div>
                    <div v-if="authorizedForEvent(['headcoach','admin','programcoach','owner' ])" class="settings-button mb-5" @click="selectPane('DevStreamEventReports')">
                        <v-icon large color="white">mdi-chart-bell-curve-cumulative</v-icon>
                        <div style="font-size:small; color:white;" class="text-center">Program Reports</div>
                    </div>
                    <div v-if="authorizedForEvent(['programcoach' ])" class="settings-button mb-5" @click="selectPane('DevStreamProgramCoachOverview')">
                        <v-icon large color="white">mdi-binoculars</v-icon>
                        <div style="font-size:small; color:white;" class="text-center">Program Coach Overview</div>
                    </div>
                </v-col>
                <v-col cols="3">
                    <!-- {{ capitalize($pluralize(events[selectedEvent].participantPronoun)) }}
                -->
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default{
    props: [],
    components:{
    },
    computed: {
        ...mapState({
            events:             state => state.tryoutStore.data,

            selectedEvent:     state => state.navigatorStore.selectedTryout,
        }),
        loading(){
            return false ||
                false;
        }

    },
    methods: {
        ...mapActions('participantStore', [
            'loadAllParticipantsForEvent'
        ]),
        ...mapActions('navigatorStore', [
            'selectPane'
        ]),


    },
    watch: {},
    async created(){
        await this.loadAllParticipantsForEvent({eventId: this.selectedEvent})

    },
    data(){
        return {

        }
    }

}
</script>