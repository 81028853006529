import {sendMessage} from '@/_helpers/service-tools'

export const qresponseService = {
    add,
    findAllForEvent,
    findAllForParticipant,
    update,
    loadQResponses,
}

async function add(qresponse) {
    return await sendMessage('POST', 'qresponse/createQResponse', null, qresponse)
}

async function loadQResponses(payload) {
    return await sendMessage('POST', 'qresponse/loadQResponses', {eventId: payload.eventId},{ data: payload.data})
}

async function findAllForEvent(id) {
    return await sendMessage('GET', 'qresponse/list', {eventId: id}, null)
}

async function findAllForParticipant(payload) {
    return await sendMessage('GET', 'qresponse/listforparticipant', payload, null)
}

async function update(payload) {
    return await sendMessage('PATCH', 'qresponse', {qresponseId: payload.id}, {response: payload.response})
}
