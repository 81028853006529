<template>
    <v-dialog width="80%" v-model="showAiReport">
        <template v-slot:activator="{on, attrs}">
            <v-btn x-small color="secondary" v-on="on" v-bind="attrs">Show Report</v-btn>
        </template>
            <v-card>
                <v-card-title>Athlete Feedback Report: {{ participants[participantId].firstName }} {{ participants[participantId].lastName }}</v-card-title>
                <v-card-text>
                    <!-- 
                    <vue-editor v-model="formattedReport"
                        :editorOptions="editorSettings"
                        width="80%"
                        @blur="onEditorBlur"/>
-->
                    <vue-editor v-model="formattedReport"
                        :editorOptions="editorSettings"
                        width="80%"
                    />
                </v-card-text>
            </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import {VueEditor} from 'vue2-editor'

export default{
    props: ['participantId'],
    components:{
        VueEditor
    },
    computed: {
        ...mapState({
            aiData:     state => state.aiStore.data,
            aiStatus:   state => state.aiStore.status,
            participants:   state => state.participantStore.data,
        }),
        loading(){
            return false ||
                false;
        },
        formattedReport:{
            get(){
                return this.aiData[this.participantId]
            },
            set(){
            }
        }
    },
    methods: {

    },
    watch: {},
    async created(){

    },
    data(){
        return {
            showAiReport: false,
            editorSettings: {
                modules:{
                    divider: true,
                    toolbar: {
                        container: 
                        [
                            [{header: []}],
                            ["bold","italic","underline",{ "color": []} ],
                            [{list: "ordered"}, {list: "bullet"}],
                            [{align: []}],
                            ["divider", "link"],
                            ["closeButton"],

                        ],
                        handlers: {
                            closeButton: () => {
                            },
                            customBtn: () => {
                                var range = this.quill.getSelected()
                                if(range) {
                                    this.quill.insertText('ArgH!')

                                }
                            }
                        }
                    }
                }
            },
        }
    }

}
</script>