<template>
    <div>
        <v-row style="margin-top:40px;">
            <v-col cols="12" sm="12" lg="6" xl="6" md="6">
                <BoundedBox borderColor="indigo">
                    <span slot="title">Your recommendations for {{ participants[participantId].firstName }}</span>
                    <div slot="content" style="margin-top:20px;">
                        <v-row>
                            <v-col cols="12">
                                <v-select  :menu-props="{ closeOnClick: true, closeOnContentClick: true, }" :items="availableTeams" dense label="Recommended Team" v-model="staticTeamId" @change="updateTeam">
                                    <template v-slot:item ="{item}">

                                         {{teamName(item)}} {{ description(item)}}
                                    </template>
                                    <template v-slot:selection="{item}">
                                        <span> {{  teamName(item) }} {{ description(item)}}</span>
                                    </template>
                                    <template v-slot:prepend-item>
                                        <v-list-item ripple @mousedown.prevent @click="staticTeamId=null;updateTeam()">
                                            <v-list-item-content>
                                                No Recommendation
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-select>
                                <v-select :items="roleItems" dense label="Role 1" v-model="staticRole1" @change="updateRole1"/>
                                <v-select :items="roleItems" dense label="Role 2" v-model="staticRole2" @change="updateRole2"/>
                            </v-col>
                        </v-row>
                    </div>
                </BoundedBox>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <BoundedBox borderColor="indigo">
                    <span slot="title">Average skill assesment for {{ participants[participantId].firstName }}</span>
                    <skill-stack slot="content" :participantId="participantId" :labelsOn="true" :height="200"/>

                </BoundedBox>
            </v-col>
        </v-row>
        <v-row>
            <v-divider/>
        </v-row>
        <v-row v-if="staticTeamId">
            <v-col cols="12">
                <span class="panel-heading">{{ recommendedPlayers.length }} other players you have recommended into the team</span>
            </v-col>
        </v-row>
        <v-row v-if="staticTeamId">
            <v-col v-responsive.xs.sm cols="12" v-for="recommendationId in recommendedPlayers" :key="'s-'+recommendationId">
                <player-assignment-tile :recommendationMode=true :participantId="recommendations[recommendationId].participantId"/>
            </v-col>
            <v-col v-responsive.md.lg.xl cols="4" v-for="recommendationId in recommendedPlayers" :key="recommendationId">
                <player-assignment-tile :recommendationMode=true :participantId="recommendations[recommendationId].participantId"/>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import PlayerAssignmentTile from '@/components/PlayerAssignmentTile'
import SkillStack from '@/components/SkillStack'
import BoundedBox from '@/components/BoundedBox'

export default {
    props: ['participantId', 'staffId'],
    components: {
        SkillStack,
        PlayerAssignmentTile,
        BoundedBox,
    },
    computed: {
        ...mapState({
            participants: state => state.participantStore.data, 

           recommendations: state => state.teamrecommendationStore.data,
           recommendationList: state => state.teamrecommendationStore.list,
        
           teams: state => state.teamStore.data,
           teamList: state => state.teamStore.list,

           selectedTryout : state => state.navigatorStore.selectedTryout,

           roles:   state => state.roleStore.data,
           roleList: state => state.roleStore.list,
           roleStatus:     state => state.roleStore.status,

        }),
        availableTeams: function() {
            var list = this.teamList.filter(this.filterTryoutTeams)
            var head = ["Discard"].concat(list)
            return head

        },
        recommendedPlayers: function() {
            return this.recommendationList.filter(recommendationId => (this.recommendations[recommendationId].participantId !== this.participantId) && (this.recommendations[recommendationId].teamId === this.staticTeamId))
        },
    },
    sockets:{
        "UpdatePlayerStats"(){
            this.staticRole1 = this.recommendations[this.recommendationId].role1
            this.staticTeamId = this.recommendations[this.recommendationId].teamId
            this.staticRole2 = this.recommendations[this.recommendationId].role2
        }
    },
    methods: {
        ...mapActions('teamrecommendationStore', {
            loadRecommendations: 'findAllForStaff',
            updateRecommendation: 'update',
            changeRecommendation: 'propertyUpdate',
        }),
        filterTryoutTeams(value){
            return this.teams[value].selected
        },
        teamName(id){
            if(id === 'Discard'){
                return 'No Offer'
            } else {
                return this.teams[id].name
            }
        },
        description(item){
            if(item ==='Discard') return ''
            var desc = "("
            if(this.teams[item].division){
                desc += this.teams[item].division
                if(this.teams[item].description){
                    desc += ' - ' + this.teams[item].description
                }
                desc += ')'
            } else if (this.teams[item].description){
                desc += this.teams[item].description + ')'
            } else {
                desc=""
            }
            return desc
        },
        recommendedTeamFilter(item) {
            return (this.recommendations[item].teamId)
        },
        findRecommendationId(){
            var recommendationId=null
            for(var idx in this.recommendationList){
                const recId = this.recommendationList[idx]
                if(this.recommendations[recId].participantId === this.participantId){
                    recommendationId = this.recommendationList[idx]
                }
            }
            return recommendationId
        },
        updateRole1(){
//            this.recommendations[this.recommendationId].role1 = this.staticRole1
            this.updateRecommendation({
                staffId: this.staffId, 
                eventId: this.selectedTryout,
                participantId: this.participantId,
                role1: this.staticRole1
            })
        },
        updateRole2(){
 //           this.recommendations[this.recommendationId].role2 = this.staticRole2
            this.updateRecommendation({
                staffId: this.staffId, 
                eventId: this.selectedTryout,
                participantId: this.participantId,
                role2: this.staticRole2
            })
        },
        updateTeam(){
//            this.recommendations[this.recommendationId].teamId = this.staticTeamId
            this.updateRecommendation({
                staffId: this.staffId, 
                eventId: this.selectedTryout,
                participantId: this.participantId,
                teamId: this.staticTeamId
            })
        },
    },

    watch: {
        participantId(){
            this.recommendationId = this.findRecommendationId()
            if(this.recommendations[this.recommendationId]){
                this.staticRole1 = this.recommendations[this.recommendationId].role1
                this.staticTeamId = this.recommendations[this.recommendationId].teamId
                this.staticRole2 = this.recommendations[this.recommendationId].role2
            } else {
                this.staticRole1 = null
                this.staticTeamId = null
                this.staticRole2 = null

            }
        },
        
    },
    async created(){
        for(var idx in this.roleList){
            this.roleItems.push({text:this.roles[this.roleList[idx]].name, value: this.roleList[idx]})
            this.roleNames.push(this.roles[this.roleList[idx]].name)
        }
        this.recommendationId = this.findRecommendationId()
        if(this.recommendations[this.recommendationId]){
            this.staticRole1 = this.recommendations[this.recommendationId].role1
            this.staticTeamId = this.recommendations[this.recommendationId].teamId
            this.staticRole2 = this.recommendations[this.recommendationId].role2
        } else {
            this.staticRole1 = null
            this.staticTeamId = null
            this.staticRole2 = null

        } 
    },
    data() {
        return {
            staticRole1: null,
            staticRole2: null,
            staticTeamId: null,
            roleItems: [],
            roleNames:[],
        }
    }
}
</script>

<style>

.row + .row {
    margin-top:0px;
}
</style>