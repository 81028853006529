import {sendMessage} from '@/_helpers/service-tools'

export const tryoutService = {
    findAllForSeason,
    findAll,
    findActive,
    findOne,
    addTryout,
    updateTryout,
    clearTryout,
    cloneTryout,
    deleteTryout,
    findAllForClub,
    loadDataCollectBundle,
}

async function findAllForSeason(seasonId) {
    return await sendMessage('GET', 'tryouts/listForSeason', {seasonId}, null)
}

async function loadDataCollectBundle(payload) {
    return await sendMessage('GET', 'tryouts/collectDataBundle', {eventId: payload.eventId}, null)
}

async function findAll() {
    return await sendMessage('GET', 'tryouts/list', null, null)
}

async function findActive(clubId) {
    return await sendMessage('GET', 'tryouts/listActive', {clubId: clubId}, null)
}

async function findAllForClub(clubId) {
    return await sendMessage('GET', 'tryouts/listClub', {clubId: clubId}, null)
}

async function findOne(id) {
    return await sendMessage('GET', 'tryouts', {id}, null)
}

async function addTryout(tryout) {
    return await sendMessage('POST', 'tryouts', null, tryout)
}

async function updateTryout(tryout) {
    return await sendMessage('PATCH', 'tryouts', {id: tryout.id}, tryout)
}

async function clearTryout(tryoutId) {
    return await sendMessage('PATCH', 'tryouts/clear', {eventId: tryoutId},{})
}

async function cloneTryout(payload) {
    return await sendMessage('POST', 'tryouts/clone', {}, {eventId: payload.eventId, seasonId: payload.seasonId, name: payload.name})
}

async function deleteTryout(payload) {
    return await sendMessage('DELETE', 'tryouts', {eventId: payload.eventId}, {})
}
