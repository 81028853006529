const CSColors = {
    '--cs1-lev1' : '#9145B6',
    '--cs1-lev2' : '#BA43B6',
    '--cs1-lev3' : '#FAA6FF',
    '--cs2-lev1' : '#2196F3',
    '--cs2-lev2' : '#55DDE0',
    '--cs2-lev3' : '#A5E6BA',
    '--cs2-lev4' : '#E6FFEE',
    '--cs2-lev5' : '#f8e5c8',
    '--error-red': 'red',
}

module.exports = {CSColors}