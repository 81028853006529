import {sendMessage} from '@/_helpers/service-tools'

export const teamrecommendationService = {
    findAllForStaff, update , findAllForEvent, findAllForTeam
}

async function findAllForStaff(staffId, eventId ){
    return await sendMessage('GET','teamrecommendation/listallforstaff', {staffId, eventId}, null)
}

async function findAllForEvent(eventId){
    return await sendMessage('GET', 'teamrecommendation/listallforevent', {eventId}, null)
}

async function findAllForTeam(teamId){
    return await sendMessage('GET', 'teamrecommendation/listallforteam', {teamId}, null)
}

async function update(recommendation) {
    return await sendMessage('PATCH', 'teamrecommendation', null, recommendation)
}