
import {sendMessage} from '@/_helpers/service-tools'

export const systemService = {
    mainstats,
    recentActivity,
    recentUsers,
}

async function mainstats() {
    return await sendMessage('GET', 'system/mainstats', null, null)
}

async function recentActivity(payload) {
    return await sendMessage('GET', 'system/recentactivity', payload, null)
}

async function recentUsers() {
    return await sendMessage('GET', 'system/recentUsers', null, null)
}