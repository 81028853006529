import Vue from 'vue'
import Vuex from 'vuex'

require("dotenv").config();

import { account } from './account.module'
import { activityStore} from './activity.module'
import { adminStore } from './admin.module'
import { aiStore } from './ai.module'
import { alert } from './alert.module'
import { appquestionStore } from './appquestion.module'
import { attendanceStore } from './attendance.module'
import { attributeStore } from './attribute.module'
import { authStore} from './auth.module'
import { awsStore } from './aws.module'
import { clubStore } from './club.module'
import { commentStore } from './comment.module'
import { criterionStore } from './criterion.module'
import { cscStore } from './csc.module'
import { episodeStore } from './episode.module'
import { feedbackStore } from './feedback.module'
import { feedbackLinkStore } from './feedbacklink.module'
import { globalsettingsStore } from './globalsettings.module'
import { groupStore } from './group.module'
import { journalentryStore } from './journalentry.module'
import { licenseStore} from './license.module'
import { messageStore } from './message.module'
import { navigatorStore } from './navigator.module'
import { packageStore } from './package.module'
import { participantStore} from './participant.module'
import { playerattributeStore } from './playerattribute.module'
import { playerskillStore } from './playerskill.module'
import { qresponseStore} from './qresponse.module'
import { roleStore } from './role.module'
import { seasonStore } from './season.module'
import { sessionStore } from './session.module'
import { sportStore } from './sport.module'
import { staffStore } from './staff.module'
import { stripeStore } from './stripe.module'
import { systemStore } from './system.module'
import { teamrecommendationStore } from './teamrecommendation.module'
import { teamStore } from './team.module'
import { transtokenStore } from './transtoken.module'
import { tryoutStore } from './tryouts.module'
import { tryoutregStore } from './tryoutreg.module'
import { userStore } from './users.module'


Vue.use(Vuex)


export const store = new Vuex.Store({
    modules: {
        account, 
        activityStore,
        adminStore,
        aiStore,
        alert, 
        appquestionStore,
        attendanceStore,
        attributeStore,
        authStore,
        awsStore,
        clubStore,
        commentStore,
        criterionStore,
        cscStore,
        episodeStore,
        feedbackStore,
        feedbackLinkStore,
        globalsettingsStore,
        groupStore,
        journalentryStore,
        licenseStore,
        messageStore,
        navigatorStore,
        packageStore,
        participantStore,
        playerattributeStore,
        playerskillStore,
        qresponseStore,
        roleStore,
        seasonStore,
        sessionStore,
        sportStore,
        staffStore,
        stripeStore,
        systemStore,
        teamrecommendationStore,
        teamStore,
        transtokenStore,
        tryoutregStore,
        tryoutStore,
        userStore,
    },
    actions: {
        purgeData(){
            for(var moduleIdx in Object.keys(this._modules.root._children)){
                const moduleName = Object.keys(this._modules.root._children)[moduleIdx]
                this.dispatch(`${moduleName}/purgeData`, null, {root:true})
            }
        }
    }
})