const callbackInvitationEmail1 =`
<!DOCTYPE html>
<html>
<body>

<table style="border-collapse: collapse; width: 100%;" color="#9145b6" border="0">
<tbody>
<tr>
<td style="width: 100%;"><img src="https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/email+header+3.png" alt="" style="width:100%"/></td>
</tr>
<tr>
<td style="width: 100%;">&nbsp;
<div style="width: 90%; margin-left: auto;margin-right: auto;font-family:sans-serif;text-align: left;">
<div>
Hi `

const callbackInvitationEmail2=`,
<br/>
<br/>
<p>Sorry for the late notice but we would like to invite you to one last selection trial on Thursday 24th Feb (tonight by the time you will receive this email). </p>
<p>We have been really impressed by the number and quality of players at tryouts this year and have found a way to accomodate additional teams so we need to get a little more information about your games
before we make out final decisions.
</p>
<p>This will be between 8:30pm and 10pm on Thursday, 24th Feb, 2022.</p>
<p>Note: 8:30pm is the planned start time for training, and we expect you will arrive at the venue at least 15 minutes earlier to carry out any personal stretching, warm-ups or discussions.</p>
<br/>
<p>If you are unable to make it tonight we will understand and try to make so with the information we have at hand, however attending will give us a much better chance to get our
selections right.</p>
<p><em>Could you please let Andy know if you are able to attend or not so he can plan for the session approriately. The best was is to send your name and player number, along with "CAN" or "CANNOT" by SMS to
0408 255 761, or email to andy@davison-family.com</em></p>

</div>


</div>
<div style="text-align: left;" >

  <div style="display: flex;
              justify-content: center;
              border-style: solid;
              width: 50%;
              margin-left: auto;
              margin-right: auto;
              margin-top: 30px;
              margin-bottom: 30px;
              font-size:18px;
              border-radius: 36px;
              background: blue;
              color: white;
              font-weight: 900;
              padding: 40px;
              font-family:sans-serif;
              ">
    <div style="text-align:right; display: flex;flex-direction:column;justify-content: center;">
        <div>
      Your participant number is
        </div>
    </div>
    <div style="font-size:64px;font-weight: 900;padding-left:20px;">
    `
const callbackInvitationEmail3 = `
    </div>
    </div>
    <div style="width: 90%; margin-left: auto;margin-right: auto;font-family:sans-serif;text-align: left;">
        <p>The venue for Thursday is the Monash Sports Stadium. </p>
        <br/>
        <br/>
        <p>Please follow all COVID-safety directions from staff on entering the facility, and be aware of our COVID-safe procedure (checking in etc).
        <br/>
        <p>
        We hope to see you Tonight!
        </p>
        <br/>
        <p>Your MUVC coaching team.</p>


</div>
<div style="font-family:sans-serif">
</div>
</div>
</td>
</tr>
<tr>
<td style="width: 100%;">&nbsp;</td>
</tr>
</tbody>
</table>

</body>
</html>
`

module.exports = {callbackInvitationEmail1, callbackInvitationEmail2, callbackInvitationEmail3}