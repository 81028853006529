<template>
    <loading v-if="loading"/>
    <div v-else-if="eventAllows(['admin','owner', 'headcoach'])" style="width:100%" >
        <v-app-bar v-if="$vuetify.breakpoint.smAndDown" height="32px" color="var(--cs1-lev2)" style="padding-left:0px;margin-left:0px;">
            <v-menu dense small click-on-open open-on-hover offset-y v-model="showSettingsMenu" >
                <template v-slot:activator="{on, attrs}">
                    <div v-on="on" v-bind="attrs" style="text-align:left;display:flex;">
                        <span class="third-chev"><v-icon small style="color:var(--cs2-lev1);padding-right:5px;">mdi-menu</v-icon>Tryout Settings </span>
                        <span class="setting-heading"> {{ settingsPanes[settingsPane].name }}</span>
                    </div>
                </template>
                <v-list>
                    <v-list-item v-for="(item,n) in settingsPanes" :key="n" :value="n" link>
                        <v-list-item-content @click="settingsPane=n">
                            {{ item.name }}
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>

        <v-row class="no-boundaries">
            <v-col class="no-boundaries" v-if="$vuetify.breakpoint.mdAndUp" cols="2">
                <v-navigation-drawer permanent >
                    <v-list-item class="settings-title">
                        <v-list-item-icon>
                            <v-icon style="color:white">mdi-cog</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="settings-title" >
                                Tryout Settings
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider/>
                    <v-list nav>
                        <v-list-item v-for="(item, n) in filteredPanes" :key="n" :value="item.page" link>
                            <v-divider v-if="item.name==='---'"/>
                            <div v-else style="display:flex;flex-flow:row">
                                <v-list-item-icon>
                                    <v-icon>{{ item.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content @click="settingsPane=item.page">
                                    {{ item.name }}
                                </v-list-item-content>
                            </div>
                        </v-list-item>

                    </v-list>
                </v-navigation-drawer>
            </v-col>
            <v-col cols="12" sm="12" md="10" lg="10" xl="10">
                <div class="no-boundaries text-right">
                    <v-btn @click.native.stop 
                            :disabled="!changes" 
                            x-small 
                            v-on:click="saveDetails" 
                            depressed 
                            color="primary" 
                            class="save-btn"
                        >
                            Save
                        </v-btn>
                </div>
                <manage-tryout-details v-if="settingsPane===0"/>
                <manage-tryout-contact v-if="settingsPane===1"/>
                <manage-tryout-fees v-if="settingsPane===2"/>
                <manage-tryout-publication v-if="settingsPane===3"/>
                <manage-tryout-questions v-if="settingsPane===4"/>
                <manage-tryout-criteria v-if="settingsPane===5"/>
                <manage-tryout-attributes v-if="settingsPane===6"/>
                <manage-tryout-sessions v-if="settingsPane===7"/>
                <manage-tryout-activities v-if="settingsPane===8"/>
                <manage-roles v-if="settingsPane===9"/>
                <manage-tryout-teams v-if="settingsPane===10"/>
                <!-- 
                <manage-roles v-if="settingsPane===10"/>
            -->
                <manage-child-safety v-if="settingsPane===11"/>
                <manage-tryout-staff v-if="settingsPane===12"/>
                <tryout-athlete-import v-if="settingsPane===13"/>
                <EditRegistrationEmail v-if="settingsPane===15"/>
                <EditOfferEmail v-if="settingsPane===16"/>

            </v-col>
        </v-row>

    </div>
    <auth-fail v-else/>
</template>

<script>

import Loading from '@/components/loading'
import ManageTryoutDetails from '@/ClubPanes/NewTryoutSettingsPanels/ManageTryoutDetails'
import ManageTryoutFees from '@/ClubPanes/NewTryoutSettingsPanels/ManageTryoutFees'
import ManageTryoutPublication from '@/ClubPanes/NewTryoutSettingsPanels/ManageTryoutPublication'
import ManageTryoutContact from '@/ClubPanes/NewTryoutSettingsPanels/ManageTryoutContact'
import ManageTryoutQuestions from '@/ClubPanes/NewTryoutSettingsPanels/ManageTryoutQuestions'
import ManageTryoutCriteria from '@/ClubPanes/NewTryoutSettingsPanels/ManageTryoutCriteria'
import ManageTryoutAttributes from '@/ClubPanes/NewTryoutSettingsPanels/ManageTryoutAttributes'
import ManageTryoutSessions from '@/ClubPanes/NewTryoutSettingsPanels/ManageTryoutSessions'
import ManageTryoutActivities from '@/ClubPanes/NewTryoutSettingsPanels/ManageTryoutActivities'
import ManageRoles from '@/ClubPanes/NewTryoutSettingsPanels/ManageRoles'
import ManageTryoutTeams from '@/ClubPanes/NewTryoutSettingsPanels/ManageTryoutTeams'
import ManageChildSafety from '@/ClubPanes/NewTryoutSettingsPanels/ManageChildSafety'
import ManageTryoutStaff from '@/ClubPanes/NewTryoutSettingsPanels/ManageTryoutStaff'
import TryoutAthleteImport from '@/ClubPanes/NewTryoutSettingsPanels/TryoutAthleteImport'


import EditRegistrationEmail from '@/ClubPanes/TryoutPanels/EditRegistrationEmail'
import EditOfferEmail from '@/ClubPanes/TryoutPanels/EditOfferEmail'




import { mapState, mapActions } from 'vuex'

export default {
    props: [],
    components: {
        Loading,
        ManageTryoutDetails,
        //eslint-disable-next-line
        ManageTryoutContact,
        //eslint-disable-next-line
        ManageTryoutFees,
        //eslint-disable-next-line
        ManageRoles,
        //eslint-disable-next-line
        ManageTryoutQuestions,
        //eslint-disable-next-line
        ManageTryoutSessions,
        //eslint-disable-next-line
        ManageTryoutCriteria,

        //eslint-disable-next-line
        ManageTryoutAttributes,
        //eslint-disable-next-line
        TryoutAthleteImport,
        //eslint-disable-next-line
        ManageTryoutPublication,
        //eslint-disable-next-line
        ManageTryoutTeams,
        //eslint-disable-next-line
        ManageChildSafety,

        //eslint-disable-next-line
        ManageTryoutActivities,
        //eslint-disable-next-line
        ManageTryoutStaff,
        EditRegistrationEmail,
        EditOfferEmail,
    },
    computed: {
        ...mapState({
            selectedTryout: state => state.navigatorStore.selectedTryout,
            tryouts: state => state.tryoutStore.data,
            tryoutStatus: state => state.tryoutStore.status,
            tryoutMeta: state => state.tryoutStore.meta,
            tryoutList: state => state.tryoutStore.list,

            sessions: state => state.sessionStore.data,
            sessionMeta: state => state.sessionStore.meta,
            sessionList: state => state.sessionStore.list,
            sessionStatus: state => state.sessionStore.status,

            questions: state => state.appquestionStore.data,
            questionMeta: state => state.appquestionStore.meta,
            questionList: state => state.appquestionStore.list,
            questionStatus: state => state.appquestionStore.status,

            criterionList: state => state.criterionStore.list,
            criterionMeta: state => state.criterionStore.meta,
            criteria: state => state.criterionStore.data,
            criteriaStatus: state => state.criterionStore.status,

            attributes: state => state.attributeStore.data,
            attributeList: state => state.attributeStore.list,
            attributeMeta: state => state.attributeStore.meta,
            attributeStatus: state => state.attributeStore.status,

            playerAttributes: state => state.playerattributeStore.data,
            playerAttributeStatus:  state => state.playerattributeStore.status,

            messages:       state => state.messageStore.data,
            messageList: state => state.messageStore.list,
            msgByName:      state => state.messageStore.listByName,
            messageStatus:  state => state.messageStore.status,

            staff: state => state.staffStore.data,
            selectorList: state => state.staffStore.selectorList,
            staffStatus: state => state.staffStore.status,
            staffMeta: state => state.staffStore.meta,
            supportList: state => state.staffStore.supportList,
        
            admins: state => state.adminStore.data,
            adminMeta: state => state.adminStore.meta,
            adminList: state => state.adminStore.list,
            adminStatus: state => state.adminStore.status,

            teams: state => state.teamStore.data,
            teamList: state => state.teamStore.list,
            teamStatus:     state => state.teamStore.status,

            activities: state => state.activityStore.data,
            activityList: state => state.activityStore.list,
            activityStatus: state => state.activityStore.status,

            groups: state => state.groupStore.data,
            groupList: state => state.groupStore.list,
            groupsByActivity: state => state.groupStore.groupsByActivity,

            participantList: state => state.participantStore.list,
            participantStatus: state => state.participantStore.status,

            roleList: state => state.roleStore.list,
            roleStatus: state => state.roleStore.status,
        }),
        loading: {
            get(){
                return  false
                /*
                ||this.tryoutStatus.loading 
                    || this.messageStatus.loading 
                    || this.adminStatus.loading
                  //  || this.sessionStatus.loading 
                  //  || this.questionStatus.loading
                  //  || this.criteriaStatus.loading
                  //  || this.attributeStatus.loading
                  //  || this.playerAttributeStatus.loading
                    || this.teamStatus.loading
                   // || this.activityStatus.loading
                    || this.participantStatus.loading
                    || this.roleStatus.loading
                    */
            }
        },
        changes() {
            return Object.keys(this.changeTracker).length>0
        },
        filteredPanes(){
            
            return this.settingsPanes.filter((item)=> (this.tryouts[this.selectedTryout].headcoach && item.headcoach ) || (this.tryouts[this.selectedTryout].admin && item.admin) || (this.tryouts[this.selectedTryout].owner && item.admin))
        }
    },
    methods: {
        ...mapActions('tryoutStore', { 
            loadTryout: 'loadTryout',
        }),
        ...mapActions('messageStore', {
            loadAllMessages: 'loadAllForEvent',
            createMessage: 'create',
            updateMessage: 'update',
            updateMessageProperty: 'propertyUpdate',
        }),
        ...mapActions('staffStore', { 
            findEventStaff: 'findAllForEvent',
        }),
        ...mapActions('adminStore', { 
            findEventAdmins: 'findAllForEvent',
        }),
       
        preventNav(event) {
            if (!Object.keys(this.changeTracker).length) return
            event.preventDefault()
            event.returnValue = ""
        },
        saveDetails(){
            this.$root.$emit('submit-all-changes')
        },
    },
    beforeMount() {
        window.addEventListener("beforeunload", this.preventNav)
        this.$once("hook:beforeDestroy", () => {
            window.removeEventListener("beforeunload", this.preventNav);
        })
    },

    beforeRouteLeave(to, from, next) {
        if (Object.keys(this.changeTracker).length) {
            if (!window.confirm("Leave without saving?")) {
                return;
            }
        }
        next();
    },
    mounted(){
        this.$root.$on('settings-changes-cleared', (id) => {
            var self=this
            self.$delete(self.changeTracker, id)
        })
        this.$root.$on('settings-changes-made', (id) => {
            var self=this
            self.$set(self.changeTracker, id, true)
        })
    },
    beforeDestroy () {
        if(Object.keys(this.changeTracker).length){
            const answer = window.confirm('There are unsaved changes - click "OK" to save these before leaving, or "Cancel" to exit without saving ')
            if (answer) {
                this.$root.$emit('submit-all-changes')
            } else {
                this.$root.$emit('backout-all-changes')
            }
        }
    },
    async created() {
        if(!this.tryoutList.includes(this.selectedTryout)){
            this.loadTryout(this.selectedTryout)
        }
        this.loadAllMessages(this.selectedTryout)
        .then(()=> {
            if(this.messageList.length === 0 || !this.msgByName['juniorExclusion']) {
                this.createMessage({
                    eventId: this.selectedTryout, name: 'juniorExclusion', message: 'Junior athletes (under the age of 18) are not able to attend this tryout.'
                })
            }
            if(!this.msgByName['parentInfo']) {
                this.createMessage({
                    eventId: this.selectedTryout, name: 'parentInfo', message: '<p>Junior athletes are permitted to attend our tryout, however please note that parental/guardian consent is required for them to participate. </p><p><br></p><p>All staff carry a current "<strong>Working with Children</strong>" ID and our club has a junior athlete liason with whom any issues or concerns may be discussed.</p><p><br></p><p><em style="color: rgb(230, 0, 0);">On conclusion of sessions junior athletes are not permitted to leave the stadium without the presence of their parent/guardian, so please make sure you come into the venue and introduce yourself before the start of the session.</em></p>'
                })
            }

        })
        await this.findEventStaff({eventId: this.selectedTryout})
        this.findEventAdmins({eventId: this.selectedTryout})

    },
    data() {
        return {
            changeTracker: {},
            showSettingsMenu: false,
            settingsPane: 0,
            settingsPanes: [
                {page:0, name:'Tryout Details',icon:'mdi-information-variant-box-outline',admin:1,headcoach:0},
                {page:1, name:'Tryout Contact',icon:'mdi-card-account-mail-outline',admin:1, headcoach:0},
                {page:2, name:'Tryout Fees',icon:'mdi-cash',admin:1, headcoach:0},
                {page:3, name:'Publication Details',icon:'mdi-publish',admin:1, headcoach:0},
                {page:4, name:'Registration Questions',icon:'mdi-chat-question-outline',admin:1, headcoach:0},
                {page:5, name:'Skill Criteria',icon:'mdi-karate',admin:1, headcoach:1},
                {page:6, name:'Athlete Attributes',icon:'mdi-ruler',admin:1, headcoach:1},
                {page:7, name:'Tryout Sessions',icon:'mdi-calendar-month-outline',admin:1, headcoach:0},
                {page:8, name:'Tryout Activities',icon:'mdi-weight-lifter',admin:1, headcoach:1},
                {page:9, name:'Team Roles',icon:'mdi-hat-fedora',admin:1, headcoach:1},
                {page:10, name:'Tryout Teams',icon:'mdi-account-group',admin:1, headcoach:0},
                {page:11, name:'Child Safety',icon:'mdi-human-male-female-child',admin:1, headcoach:0},
                {page:12, name:'Tryout Staff',icon:'mdi-security',admin:1, headcoach:1},
                {page:13, name:'Import Athletes', icon:'mdi-import', admin:1, headcoach:0},
                {page:14, name:'---'},
                {page:15, name: 'Edit Registration Email', icon:'mdi-card-account-mail-outline',admin:1, headcoach:0},
                {page:16, name: 'Edit Offer Email', icon: 'mdi-email-seal-outline',admin:1, headcoach:0},
            

            ],
        }
    }
}

</script>


<style>


.panel-heading {
    font-weight: 600;
    font-size: large;
    color:rgb(50, 166, 255);
}

.panel-instructions {
    font-size:small;
    font-style:italic;
    text-align: left;
}

.setting-heading {
    font-family: Constantia, sans-serif;
    font-size: small;
    text-align:left;
    color: white;
    padding-left:20px;
    font-weight:900;
    margin-top:auto;
    margin-bottom:auto;
}
.container{
    padding:0px;
}

.v-list-item__content {
    font-size:small;
    padding:0px;
    margin:0px;
}

.v-list-ite {
    min-height:24px;
}
</style>