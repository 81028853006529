<template>
    <div>
        <v-row class="player-row">
            <v-col cols="3" :style="teamColor" class="player-number-col">
                {{ participants[participantId].participantNumber }}
            </v-col>
            <v-col cols="9" class="player-name-col">
                <v-row style="margin:0;padding:0;">
                    <v-col style="margin:0;padding:0;font-weight:600;white-space:nowrap;text-overflow:clip; overflow:hidden">
                        {{ participants[participantId].firstName }} 
                    </v-col>
                </v-row>
                <v-row style="margin:0;padding:0;">
                    <v-col style="margin:0;padding:0;white-space:nowrap;text-overflow:clip; overflow:hidden">
                        {{ participants[participantId].lastName }}
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapState } from 'vuex';


export default {
    props: ['participantId'],        
    components: {},
    computed: {
        ...mapState({
            participants: state => state.participantStore.data,
            participantList: state => state.participantStore.list,

            attendancePerSession: state => state.attendanceStore.perSessionLists,
            attendance: state => state.attendanceStore.data,
            selectedSession: state => state.navigatorStore.selectedSession,
            teams: state => state.teamStore.data,

        }),
        teamColor(){
            // Check attendance first - if they're not in attendance, show gray...

            //    return 'background:grey;color:white'
            if(this.participants[this.participantId].offer === 'Discard'){
                return 'background:red;color:white'
            }
            if(this.selectedSession && this.selectedSession !== '0' && this.selectedSession !== 0 && (!this.attendancePerSession[this.selectedSession][this.participantId] || !this.attendance[this.attendancePerSession[this.selectedSession][this.participantId]].attended))
                return 'background:grey;color:white'
            if(!this.participants[this.participantId].offer) return 'background: white; color: black'

            return `background: ${this.teams[this.participants[this.participantId].offer].color}; color: ${this.contrastColor(this.teams[this.participants[this.participantId].offer].color)}`
        }
    },
    methods: {
        contrastColor(hex) {
            // Y = 0.2126 R + 0.7152 G + 0.0722 B
            // S = (max(R, G, B) - min(R, G, B)) / max(R, G, B)

            // The second one is the calculation from the HSV colour space.

            // If the saturation is low enough (pick any value you like; something 
            // between 0.3 and 0.5 would work fine), check the luminance; 
            // if that's > 0.5, your contrasting colour is black, else if 
            // it's < 0.5 the colour is white. For exactly Y = 0.5, both work.


            if (hex.indexOf('#') === 0) {
                hex = hex.slice(1);
            }
            if (hex.length === 3) {
                hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
            }
            const red = Number('0x'+hex[0]+hex[1])/255
            const green = Number('0x'+hex[2]+hex[3])/255
            const blue = Number('0x'+hex[4]+hex[5])/255

            const gamma = 2.2
            const L = 0.2126 * Math.pow( red, gamma ) + 0.7152 * Math.pow( green, gamma ) + 0.0722 * Math.pow( blue, gamma );
            

            const use_black = ( L > Math.pow( 0.5, gamma ) );

            return use_black?'#000000FF':'$FFFFFFFF'


        },
        invertColor(hex) {
            if (hex.indexOf('#') === 0) {
                hex = hex.slice(1);
            }
            // convert 3-digit hex to 6-digits.
            if (hex.length === 3) {
                hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
            }
            if (hex.length !== 6 && hex.length !== 8)  {
                throw new Error('Invalid HEX color.');
            }
            // invert color components
            var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
                g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
                b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);


            return (r * 0.299 + g * 0.587 + b * 0.114) > 186
                ? '#000000'
                : '#FFFFFF';
            // pad each with zeros and return
        },
    },
    created(){},
    data(){
        return {}
    }

}
</script>

<style scoped>

.player-row {
    border: 1px solid black;
    margin: 0px;
    padding: 0px;
}
.player-number-col {
    border-right: 1px solid black;
    font-size:small;
    margin: 0px;
    padding:0px;
}
.player-name-col {
    padding-left: 5px;
    font-size:x-small;
    margin: 0px;
    padding:0px;
}

</style>