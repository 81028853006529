import { globalsettingsService } from "../_services/globalsettings.service";

function initialiseState(){
    return {
        data: {},
        status: {loading:true},
        error: null
    }
}

const state = initialiseState()

const actions = {
    purgeData({commit}){
        commit('purgeData')
    },
    async load({commit}){
        try{
            commit('loadRequest')
            const response = await globalsettingsService.load()
            commit('load', response)
        } catch(error) {
            commit('loadFailure', error)
        }
    }
}

const mutations = {
purgeData(state){
    const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }
    },
    loadRequest(state){
        state.status={loading:true}
    },
    load(state, response){
        state.error=null
        if(response && Object.prototype.hasOwnProperty.call(response, 'settings')){
            state.data=response.settings
        } else {
            state.data={}
        }
        state.status={}
    },
    loadFailure(state, error){
        state.error = error
    }
}

export const globalsettingsStore = {
    namespaced: true,
    state,
    actions,
    mutations
}