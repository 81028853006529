<template>
    <loading v-if='loading'/>
    <dev-stream-home-staff v-else-if="eventAllows(['admin', 'owner', 'programcoach', 'headcoach','assistantcoach', 'selector'])" />
    <dev-stream-home-participant v-else-if="eventAllows(['athlete'])" />
    <auth-fail v-else/>
</template>

<script>

import DevStreamHomeStaff from '@/ClubPanes/DevelopmentStreams/DevStreamHomeStaff'
import DevStreamHomeParticipant from '@/ClubPanes/DevelopmentStreams/DevStreamHomeParticipant'

import {mapState, mapActions} from 'vuex'

export default{
    props: [],
    components:{
        DevStreamHomeStaff,
        DevStreamHomeParticipant
    },
    computed: {
        ...mapState({
            selectedEvent:          state => state.navigatorStore.selectedTryout,
        }),
        loading(){
            return false ||
                false;
        }

    },
    methods: {
        ...mapActions('participantStore', [
            'loadAllParticipantsForEvent'
        ])

    },
    watch: {},
    async created(){
      //  await this.loadAllParticipantsForEvent({eventId: this.selectedEvent})
    },
    data(){
        return {

        }
    }

}
</script>