import {aiService} from '../_services'

const dataService = aiService

function initialiseState(){
    return {
        status: {idle:true, ready:false},
        data: {},
        list: [],
        error: null,
        cache: {},
        meta: {},
    }
}

const state = initialiseState()

const actions = {
    purgeData({commit}){
        commit('purgeData')
    },
    async generateFeedback({commit}, payload){
        try{
            commit('generateFeedbackRequest')
            const response = await dataService.generateFeedback(payload)
            commit('generateFeedbackSuccess', response)
            return response
        } catch(error){
            console.error('Error generating feedback')
            console.error(error)
        }
    }
}
const mutations = {
    purgeData(state){
        const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }
    },
    generateFeedbackRequest(state){
        state.status={loading:true,idle:false,ready:false}
    },
    generateFeedbackSuccess(state, response){
        state.data[response.data.result.participantId]=response.data.result.aiResponse
        state.status={responseCode: response.data.code, ready:true, idle: true}
    },
    generateFeedbackFailure(state, error){
        state.error=error
    }
}

export const aiStore = {
    namespaced: true,
    state,
    actions,
    mutations,
}