<template>
    <loading v-if="loading"/>
    <div v-else>
        <FSLoading :isLoading="paymentStarted"/>
        <div v-if="responded">
            <v-card class="pa-10 ma-6">
            <v-card-text>
                <v-row>
                    <v-col class="text-left">
                        <span class="panel-heading">Response Received</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-left">
                        <h3>Thanks {{firstName}},</h3>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-left">
                        <p>We've let the club know that you have responded</p>
                        <p>If you change your mind, you will need to get in touch with the club directly to discuss your options.</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <contact-card :contact="{name: contactName, phone: contactPhone, email: contactEmail}"/>
                    </v-col>
                </v-row>
            </v-card-text>
            </v-card>



            <v-card-text>
                    <h4>Response Received </h4>
                    <br/>
                    <h3>Thanks {{firstName}},</h3>
                    <br/>
                    <p>We've let the club know that you have responded</p>
                    <br/>
                    <p>If you change your mind, you will need to get in touch with the club directly to discuss your options.</p>
            </v-card-text>
        </div>
        <div v-else-if="accepted">
            <v-card class="pa-10 ma-6">
            <v-card-text>
                <v-row>
                    <v-col class="text-left">
                        <span class="panel-heading">Offer Review</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-left">
                        <h3>Hi {{firstName}},</h3>

                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-left">
                        <p>We see you have already <span class="accept-response">accepted</span> your offer to play in {{teamName}}</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-left">
                        <p>If you have changed your mind, you will need to get in touch with the club directly to discuss your options.</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <contact-card :contact="{name: contactName, phone: contactPhone, email: contactEmail}"/>
                    </v-col>
                </v-row>
            </v-card-text>
            </v-card>
        </div>
        <div v-else-if="declined">
            <v-card class="pa-10 ma-6">
            <v-card-text>
                <v-row>
                    <v-col class="text-left">
                        <span class="panel-heading">Offer Review</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-left">
                        <h3>Hi {{firstName}},</h3>

                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-left">
                        <p>We see you have already <span class="decline-response">declined</span> your offer to play in {{teamName}}</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-left">
                        <p>If you have changed your mind, you will need to get in touch with the club directly to discuss your options.</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <contact-card :contact="{name: contactName, phone: contactPhone, email: contactEmail}"/>
                    </v-col>
                </v-row>
            </v-card-text>
            </v-card>
        </div>
        <div v-else class="offer-frame">
            <v-card 
                v-if="offerMade"
                width="80%"

            >
                <v-card-text>
                    <h4>Offer Review</h4>
                    <br/>
                    <h3>Congratulations {{firstName}},</h3>
                    <br/>
                    <p>Thanks for being a part of {{eventName}}. You have been offered a role in the following team:</p>
                    <h3>{{teamName}}</h3>
                    <br/>
                    <p>The terms of this offer have been emailed to you with the link you clicked.  </p>
                    <p>Please accept or decline this offer as soon as you are able by clicking on the appropriate link below. </p>
                    <p v-if="tryouts[participant.eventId].offerFeeValue>0" >Accepting your offer will require a deposit of ${{ tryouts[participant.eventId].offerFeeValue/100 }} to secure your space</p>
                    <p>The deadline for acceptance <span v-if="tryouts[participant.eventId].offerFeeValue"> and payment </span> is </p> {{ moment(tryouts[participant.eventId].offerDeadline).format('hh:mm a, ddd Do MMM, YYYY') }}

                </v-card-text>
                <v-card-actions>
                    <div class="response-btns">
                        <v-btn v-if="tryouts[participant.eventId].offerFeeValue>0" class="ma-6" color="green" @click="acceptAndPay">Accept Offer & Pay</v-btn>
                        <v-btn v-else class="ma-6" color="green" @click="accept">Accept Offer</v-btn>
                        <v-btn class="ma-6" color="red" @click = "decline">Decline Offer</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
            <v-card 
                v-else
                width="80%"

            >
                <v-card-text>
                    <h4>Offer Review</h4>
                    <br/>
                    <h3>Sorry {{firstName}},</h3>
                    <br/>
                    <p>We can't find an offer for you for {{eventName}}</p>
                </v-card-text>
            </v-card>
        </div>

    </div>


</template>

<script>

import Loading from '@/components/loading'
import {mapState, mapActions} from 'vuex'
import ContactCard from '@/components/ContactCard'
import FSLoading from '@/components/FSLoading'

export default {
    props: ['participantId'],
    components: {
        Loading,
        ContactCard,
        FSLoading,

    },
    
    computed: {
        ...mapState({
            teamList:   state => state.teamStore.list,
            teamStatus: state => state.teamStore.status,
            teams:      state => state.teamStore.data,

            tryoutList:     state => state.tryoutStore.list,
            tryoutStatus:   state => state.tryoutStore.status, 
            tryouts:        state => state.tryoutStore.data,

            participantList:    state => state.participantStore.list,
            participantStatus:  state => state.participantStore.status,
            participants:       state => state.participantStore.data,
            participant:        state => state.participantStore.participant,

            stripeError:        state => state.stripeStore.error,
            stripeInfo:         state => state.stripeStore.data,

        }),
        loading: function() {
            return this.tryoutStatus.loading
                || this.teamStatus.loading
                || this.participantStatus.loading
                || !this.loaded
        },
        offerMade: function() {
            if(!this.participantId) return false;
                    for (var idx2 in this.teams){
                        if(this.participant.offer === this.teams[idx2].id)    return true
                    }
            return false
        },
        declined: function() {
            if(!this.participantId) return false;
            return this.participant.offerResponse==="decline"
        },
        accepted: function() {
            if(!this.participantId) return false;
            return this.participant.offerResponse==="accept"
        },
        teamName: function() {
            return this.teams[this.participant.offer].name
        },
        eventName: function() {
            return this.tryouts[this.participant.eventId].title
        },
        firstName: function () {
            const localUser = JSON.parse(localStorage.getItem('user'))
            return localUser.details.firstName
        },
        contactName:function() {
            const tryoutId = this.participant.eventId
            return this.tryouts[tryoutId].contactName
        },
        contactePhone:function() {
            const tryoutId = this.participant.eventId
            return this.tryouts[tryoutId].contactePhone
        },
        contactEmail:function() {
            const tryoutId = this.participant.eventId
            return this.tryouts[tryoutId].contactEmail
        },

    },
    methods: {
        ...mapActions('tryoutStore', {
          //  findAllTryouts : 'findAll',
            findTryout: 'loadTryout',
        }),
        ...mapActions('teamStore', {
            findAllTeams : 'findAllForEvent',
        }),
        ...mapActions('participantStore', {
            findParticipant: 'find',
            updateParticipantProp: 'propertyUpdate',
            updateParticipant: 'update',
            acceptOffer : 'acceptOffer',
            declineOffer : 'declineOffer',
        }),
        ...mapActions('stripeStore', {
            offerCharge : 'offerCharge',
        }),
        
        accept() {
            this.updateParticipantProp({field: 'offerResponse', id: this.participantId, value:"accept"})
            this.acceptOffer(this.participantId)
            this.responded=true
        },
        async acceptAndPay() {
            this.paymentStarted=true
            await this.offerCharge({participantId: this.participantId})
            if(this.stripeError){
                this.$store.dispatch('alert/error','There is an error with your club\'s payment details. Please contact your club administrator or coach')
            } else {
                this.updateParticipantProp({field: 'offerResponse', id: this.participantId, value:"paymentInFlight"})
                window.location=this.stripeInfo.paymentURL
            }
        },
        decline() {
            this.updateParticipantProp({field: 'offerResponse', id: this.participantId, value:"decline"})
            this.declineOffer(this.participantId)
            this.responded=true
        }
    },
    async created() {
        await this.findParticipant({participantId: this.participantId})
        await this.findTryout(this.participant.eventId)
        await this.findAllTeams({eventId: this.participant.eventId})
        this.loaded=true;
        
    } ,
    data() {
        return ({
            paymentStarted: false,
            responded: false,
            loaded: false,
        })
    },

}
</script>

<style>

.response-btns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.offer-frame {
    width: 80%;
    text-align: left;
    margin-left: auto;
    margin-right: auto;


}

.accept-response {
    font-weight: 600;
    color: olive;
    font-style: italic;
}

.decline-response {
    font-weight: 500;
    color: red;
    font-style: italic;
}

</style>