<template>
    <div>

    Manage Tryout
    </div>
</template>

<script>
import { mapActions } from 'vuex';


export default {
    props: [],
    components: {},
    computed: {},
    methods: {
        ...mapActions('navigatorStore', {
            selectNav: 'selectNav'
        })
    },

    async created() {
        this.selectNav('Tryout')
    },
    data() {
        return {

        }
    },
}
</script>

<style>

</style>