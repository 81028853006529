<template>
    <div :class="['group-card', miniScreen?'mobile':''] " >
        <div class="group-name" @click="myClickFn">
            {{ groupName }}
        </div>
        <div  class="participant-names" v-if="validGroup" @click="myClickFn">
            <div class="participant-row" v-for="(participantData,n) in participantList" :key="n">
                <div class="participant-name">
                    {{ participantData.name }}
                </div>
                <div class="participant-role">
                    ({{ participantData.role }})
                </div>
            </div>
        </div>
        <v-spacer></v-spacer>
        <v-divider/>
        <div>
            <v-btn color="primary" icon @click="warnDialog=true"> <v-icon>mdi-trash-can-outline</v-icon></v-btn>
            <PermanentConfirm :show="warnDialog" :confirm="reallyDelete" :cancel="closeWarn" actionName="Delete Group" :identifier="groupName">
                <template v-slot:consequences>
                    All assignments to this group will be lost, all criteria ratings and comments for this group will be localStorage, and all live apps will be updated to reflect the change. 
                </template>
            </PermanentConfirm>
        </div>
    </div>
</template>

<script>
import {mapState,mapActions} from 'vuex'
import PermanentConfirm from './PermanentConfirm.vue'

export default{
    props: {
        clickFn : {
            default: () => {}
        }
    },
    components:{
        PermanentConfirm
    },
    computed: {
        ...mapState({
            groups:                 state => state.groupStore.data,
            groupList:              state => state.groupStore.list,
            roles:                  state => state.roleStore.data,
            participants:           state => state.participantStore.data,
            selectedParticipant:    state => state.navigatorStore.selectedParticipant,

            groupRoleLists:         state => state.groupStore.groupRoleLists,


        }),
        loading(){
            return false ||
                false;
        },
        participantList(){
            if(!Object.prototype.hasOwnProperty.call(this.groupRoleLists, this.selectedParticipant)){
                return ['No Members']
            }
            var partList=[]
            console.log(`Selected Participant; ${this.selectedParticipant}`)
            const activeRoles = Object.keys(this.groupRoleLists[this.selectedParticipant])
            console.log('======')
            console.log(this.groupRoleLists[this.selectedParticipant])
            console.log('======')

            for(var roleId of activeRoles){
                console.log(`RoleId: ${roleId}`)
                for(var participantId of this.groupRoleLists[this.selectedParticipant][roleId]){
                    partList.push({name:`${this.participants[participantId].firstName} ${this.participants[participantId].lastName}`, role:`${this.roles[roleId].name}`})
                }
            }
            return partList
        },
        validGroup(){
            return this.groupList.includes(this.selectedParticipant)
        },
        groupName(){
            if(this.validGroup){
                return this.groups[this.selectedParticipant].name.replace(/\//g, '/\u200B')
            }
            return 'No Group Selected'
        },
        miniScreen(){
            if (this.$vuetify.breakpoint.smAndDown) {
                return true;
            }
            return false;
        },

    },
    methods: {
        ...mapActions('groupStore', [
            'deleteGroupById'
        ]),
        ...mapActions('navigatorStore', [
            'setSelectedParticipant'
        ]),
        async reallyDelete(){

                this.warnDialog=false

                console.log('Deleting group')
                this.deleteGroupById(this.selectedParticipant)
                this.setSelectedParticipant(this.groupList.length>0?this.groupList[0]:'nogroup')
        },
        closeWarn(){
            this.warnDialog=false
        }

    },
    watch: {},
    async created(){
        if(this.miniScreen && this.clickFn && this.clickFn !==''){
            this.myClickFn = this.clickFn
        }

    },
    data(){
        return {
            myClickFn: () => {},
            warnDialog: false,
        }
    }

}
</script>

<style scoped>

.group-card {
    border-style: solid;
    border-width: 2px;
    border-radius: 10px;
    height:250px;
    min-width:200px;
}
.group-card.mobile{
    height:120px;
    width: 100%;
    margin-right:10px;
}
.group-name {
    padding:10px;
    background-color:blue;
    color:white;
    font-weight:600;
}

.participant-names{
    border-top-style:solid;
    border-top-width:thin;
    padding-top:5px;
    text-align:left;
}
.participant-row {
    display:flex;
    flex-flow:row;
    margin-top:auto;
    margin-bottom:auto;
}
.participant-name{
    font-size:9px;
    font-family: 'Varela Round';
    padding: 5px;
    font-weight: 600;
    margin-top:auto;
    margin-bottom:auto;
}

.participant-role{
    font-size:9px;
    font-weight:200;
    margin-top:auto;
    margin-bottom:auto;
}
</style>