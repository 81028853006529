<template>
    <loading v-if="loading"/>
    <div v-else-if="updateKey" >
        <div width="100%" class="club-head">
            <v-app-bar color="#2196f3" style="padding-left:10px;">
                <div v-responsive.md.lg.xl style="display:flex;flex-flow:row;">
                        <div class="club-bar" >
                            <v-avatar size="50" class="elevation-10"  @click="clubHomeNav()">
                                    <img v-if="clubs[selectedClub].logo !== null" :src="clubs[selectedClub].logo">
                            </v-avatar>
                            <span class="club-name" @click="clubHomeNav()">{{clubs[selectedClub].name}}</span>
                        </div>
                    <div class="club-season-bar">
                        <span v-if="selectedNav==='Season' || selectedNav ==='Tryout' || selectedNav === 'Team' || selectedNav === 'DevStream'" :class="responsiveClass('season-name')" @click="seasonHomeNav()">Season: {{ seasons[selectedSeason].name }}</span>
                    </div>
                </div>
                <div v-responsive.xs.sm style="display:flex;flex-flow:row;">
                    <div>
                        <v-avatar size="50" class="elevation-10"  @click="clubHomeNav()">
                                <img v-if="clubs[selectedClub].logo !== null" :src="clubs[selectedClub].logo">
                        </v-avatar>
                    </div>
                    <div>
                            <div class="club-name text-left " @click="clubHomeNav()">{{clubs[selectedClub].name}}</div>
                            <div class="club-season-bar text-left ">
                                <span v-if="selectedNav==='Season' || selectedNav ==='Tryout' || selectedNav === 'Team'" :class="responsiveClass('season-name')" @click="seasonHomeNav()">Season: {{ seasons[selectedSeason].name }}</span>
                            </div>

                    </div>
                </div>
            </v-app-bar>
            <v-app-bar v-if="selectedNav==='Tryout'" height="50px" color="#36dbf4" style="padding-left:0px;margin-left:0px;">
                <div v-if="selectedNav === 'Tryout'" style="display:flex;margin:0px;padding:0px;margin-left:10px;">
                    
                    <v-menu dense small close-on-click open-on-click open-on-hover offset-y v-if="selectedNav==='Tryout'" >
                        <template v-slot:activator="{ on, attrs }">
                                <div v-on="on" v-bind="attrs" class="tryout-name2" v-if="selectedNav==='Tryout'"><v-icon color="white">mdi-menu</v-icon> 
                                </div>
                        </template>
                        <v-list>
                            <v-list-item 
                                v-for="([icon, text, navFn, owner, admin, athlete, member, open, selector, pub, systemAdmin, headcoach], i) in menuItems"
                                :key="i" 
                                link
                                @click=navFn
                            >
                                <template v-if="divider(icon)">
                                    <div><v-divider/></div>
                                </template>
                                <template v-else>
                                    <v-list-item-icon>
                                        <v-icon>{{ icon }}</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ text }}</v-list-item-title>
                                    </v-list-item-content>

                                </template>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <div style="margin-top:auto;margin-bottom:auto;margin-left:20px;display:flex;flex-flow:row;">
                        <div :class="responsiveClass('tryout-select-label')">Tryout: </div>
                        <v-select :class="responsiveClass('tryout-select')" small color="white" hide-details outlined dense v-model="switchTryout" :items="permittedTryouts" item-text="label" item-value="value" :value="selectedTryout">
                            <template v-slot:selection="{item}">
                                <span :class="responsiveClass('bar-selects')"> {{item.label}} </span>
                            </template>
                            <template v-slot:append-inner></template>
                            <template v-slot:append-outer></template>
                            <template v-slot:append></template>
                        </v-select>
                    </div>
                    <div v-if="selectedPane === 'ParticipantList' || selectedPane === 'TryoutCollectData'" style="margin-top:auto;margin-bottom:auto;margin-left:20px;display:flex;flex-flow:row">
                        <div :class="responsiveClass('tryout-select-label')">Session</div>
                        <v-select :class="responsiveClass('tryout-select')" small color="white" hide-details outlined dense v-model="switchSession" :items="sessionNameList" item-text="name" item-value="id" :value="selectedSession">
                            <template v-slot:selection="{item}">
                                <span :class="responsiveClass('bar-selects')"> {{item.name}} </span>
                            </template>
                        </v-select>
                    </div>
                </div>

            </v-app-bar>
            <v-app-bar v-if="selectedNav ==='Team'" height="50px" color="#36dbf4" style="padding-left:0px;margin-left:0px;">
                <div style="display:flex;margin:0px;padding:0px;margin-left:10px;">
                    <v-menu dense small close-on-click open-on-click open-on-hover offset-y>
                        <template v-slot:activator="{on,attrs}">
                            <div v-on="on" v-bind="attrs" class="team-name">
                                <v-icon color="white"> mdi-menu </v-icon>
                            </div>
                        </template>
                        <v-list>
                            <v-list-item 
                                v-for="([icon, text, navFn, owner, admin, athlete, member, open, selector, pub, systemAdmin, headcoach, assistantcoach],i) in menuItems"
                                :key="i"
                                link
                                @click=navFn
                            >
                                <template v-if="divider(icon)">
                                    <div><v-divider/></div>
                                </template>
                                <template v-else>
                                    <v-list-item-icon>
                                        <v-icon>{{ icon }}</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ text }}</v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <div style="margin-top:auto;margin-bottom:auto;margin-left:20px;display:flex;flex-flow:row;">
                        <div :class="responsiveClass('team-select-label')">Team: </div>
                        <v-select :class="responsiveClass('team-select')" small color="white" hide-details outlined dense v-model="switchTryout" :items="permittedTeams" item-text="label" item-value="value" :value="selectedTryout">
                            <template v-slot:selection="{item}">
                                <span :class="responsiveClass('bar-selects')"> {{item.label}} </span>
                            </template>
                            <template v-slot:append-inner></template>
                            <template v-slot:append-outer></template>
                            <template v-slot:append></template>
                        </v-select>
                    </div>
                </div>
            </v-app-bar>
            <v-app-bar v-if="selectedNav==='DevStream'" height="50px" color="#36dbf4" style="padding-left:0px;margin-left:0px;">
                <div style="display:flex;margin:0px;padding:0px;margin-left:10px;">
                    <v-menu dense small close-on-click open-on-click open-on-hover offset-y>
                        <template v-slot:activator="{on,attrs}">
                            <div v-on="on" v-bind="attrs" class="team-name">
                                <v-icon color="white"> mdi-menu </v-icon>
                            </div>
                        </template>
                        <v-list>
                            <v-list-item 
                                v-for="([icon, text, navFn, owner, admin, athlete, member, open, selector, pub, systemAdmin, headcoach, assistantcoach],i) in menuItems"
                                :key="i"
                                link
                                @click=navFn
                            >
                                <template v-if="divider(icon)">
                                    <div><v-divider/></div>
                                </template>
                                <template v-else>
                                    <v-list-item-icon>
                                        <v-icon>{{ icon }}</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ text }}</v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <div style="margin-top:auto;margin-bottom:auto;margin-left:20px;display:flex;flex-flow:row;">
                        <div :class="responsiveClass('team-select-label')">Development Program: </div>
                        <v-select :class="responsiveClass('team-select')" small color="white" hide-details outlined dense v-model="switchTryout" :items="permittedDevStreams" item-text="label" item-value="value" :value="selectedTryout">
                            <template v-slot:selection="{item}">
                                <span :class="responsiveClass('bar-selects')"> {{item.label}} </span>
                            </template>
                            <template v-slot:append-inner></template>
                            <template v-slot:append-outer></template>
                            <template v-slot:append></template>
                        </v-select>
                    </div>
                </div>

            </v-app-bar>
            <!--
            <v-container style="padding:0px;overflow-y:scroll;">
            <v-container style="justify-content: left;padding:0px;">
            -->
                <club-info v-if="selectedPane==='Club'"/>
                <season-home v-if="selectedPane==='SeasonHome' && selectedSeason!==''"/>
                <club-settings-pane v-if="showSettings" :showBanking="showBanking"/>
                <club-season-pane :key='Date()' :clubId="selectedClub" v-if="selectedPane==='Seasons'"/>
                <season-home v-if="selectedPane==='Tryouts'"/>
                <club-team-pane v-if="selectedPane==='Teams'"/>

                <tryout-status v-if="selectedPane === 'TryoutStatus'"/>
                <tryout-registration v-if="selectedPane==='TryoutRegistration'"/>
                <tryout-review v-if="selectedPane==='TryoutReview'"/>
                <manage-tryout v-if="selectedPane==='ManageTryout'"/>
                <tryout-collect-data v-if="selectedPane==='TryoutCollectData'"/>
                <manage-tryout-settings v-if="selectedPane==='TryoutSettings'"/>
                <tryout-participants v-if="selectedPane === 'ParticipantList'"/>
                <edit-registration-email v-if="selectedPane=== 'EditRegEmail'"/>
                <edit-offer-email v-if="selectedPane=== 'EditOfferEmail'"/>
                <tryout-offers v-if="selectedPane=== 'PrepareOffers'"/>

                <new-team-selector v-if="selectedPane=== 'SelectTeams'"/>
                <system-dashboard v-if="selectedPane==='SystemInfo'"/>

                <journal-home v-if="selectedPane==='JournalHome'"/>
                <journal-athlete-home v-if="selectedPane==='JournalAthleteHome'"/>
                
                <!--   Team Pages -->

                <team-home v-if="selectedPane==='TeamHome'"/>

                <!--   Dev Program Pages -->
                
                <dev-stream-home v-if="selectedPane==='DevStreamHome'"/>
                <dev-stream-settings v-if="selectedPane==='DevStreamSettings'"/>
                <dev-stream-new-episode v-if="selectedPane === 'DevStreamNewEpisode'"/>
                <dev-stream-episode v-if="selectedPane === 'DevStreamEpisode'"/>
                <dev-stream-episodes v-if="selectedPane === 'DevStreamEpisodes'"/>
                <dev-stream-event-reports v-if="selectedPane === 'DevStreamEventReports'"/>
                <dev-stream-program-coach-overview v-if="selectedPane === 'DevStreamProgramCoachOverview'"/>

<!--
            </v-container>
        -->
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Loading from '@/components/loading'
import { router } from '../_helpers';
// Main Pages
import ClubSeasonPane from '@/components/ClubSeasonPane'
import SeasonHome from '@/components/SeasonHome'
import ClubTeamPane from '@/components/ClubTeamPane'
import ClubSettingsPane from '@/components/ClubSettingsPane'

// Tryout Pages
import TryoutStatus from '@/ClubPanes/TryoutStatus'
import TryoutRegistration from '@/ClubPanes/TryoutRegistration.vue'
import TryoutReview from '@/ClubPanes/TryoutReview'
import ManageTryout from '@/ClubPanes/ManageTryout'
import TryoutCollectData from '@/ClubPanes/TryoutPanels/TryoutCollectData'

//import TeamSelector from '@/ClubPanes/TryoutPanels/TeamSelector'
import NewTeamSelector from '@/ClubPanes/TryoutPanels/NewTeamSelector'

import TryoutOffers from '@/ClubPanes/TryoutPanels/TryoutOffers'

import ManageTryoutSettings from '@/ClubPanes/NewManageTryoutSettings'
import TryoutParticipants from '@/ClubPanes/TryoutPanels/TryoutParticipants'
import EditRegistrationEmail from '@/ClubPanes/TryoutPanels/EditRegistrationEmail'
import EditOfferEmail from '@/ClubPanes/TryoutPanels/EditOfferEmail'

import JournalHome from '@/Journal/JournalHome'
import JournalAthleteHome from '@/Journal/JournalAthleteHome'

// Team Pages
import TeamHome from '@/ClubPanes/Teams/TeamHome'

// DevStream Pages
import DevStreamHome from '@/ClubPanes/DevelopmentStreams/DevStreamHome'
import DevStreamSettings from '@/ClubPanes/DevelopmentStreams/DevStreamSettings'
import DevStreamNewEpisode from '@/ClubPanes/DevelopmentStreams/DevStreamNewEpisode'
import DevStreamEpisode from '@/ClubPanes/DevelopmentStreams/DevStreamEpisode'
import DevStreamEpisodes from '@/ClubPanes/DevelopmentStreams/DevStreamEpisodes'
import DevStreamEventReports from '@/ClubPanes/DevelopmentStreams/DevStreamEventReports'
import DevStreamProgramCoachOverview from '@/ClubPanes/DevelopmentStreams/DevStreamProgramCoachOverview'


import SystemDashboard from '@/SystemAdminPages/SystemDashboard'
import ClubInfo from '@/ClubPanes/ClubInfo'
import { v4 as uuidv4} from 'uuid'

export default {
    props: ['clubId','token'],
    components: {
        Loading,
        ClubSeasonPane,
        SeasonHome,
        ClubTeamPane,
        ClubSettingsPane,
        TryoutStatus,
        TryoutRegistration,
        TryoutReview,
        ManageTryout,
        TryoutCollectData,
        ManageTryoutSettings,
        TryoutParticipants,
        EditRegistrationEmail,
        EditOfferEmail,
//        TeamSelector,
        NewTeamSelector,
        ClubInfo,
        TryoutOffers,
        JournalHome,
        JournalAthleteHome,

        //-------
        // Team Pages
        TeamHome,

        //--------------
        // DevStreams
        DevStreamHome,
        DevStreamSettings,
        DevStreamNewEpisode,
        DevStreamEpisode,
        DevStreamEpisodes,
        DevStreamEventReports,
        DevStreamProgramCoachOverview,
        
        //---------------
        // System Pages
        SystemDashboard,

    },
    computed: {
        ...mapState({
            account: state => state.account,
            tryouts: state => state.tryoutStore.data,
            tryoutStatus: state => state.tryoutStore.status,
            tryoutList: state => state.tryoutStore.list,

            selectedClub: state => state.navigatorStore.selectedClub,
            selectedSeasonInStore: state => state.navigatorStore.selectedSeason,
            selectedClubPane: state => state.navigatorStore.selectedClubPane,
            selectedTryout : state => state.navigatorStore.selectedTryout,
            selectedNav : state => state.navigatorStore.selectedNav,
            selectedSession: state => state.navigatorStore.selectedSession,

            clubs: state => state.clubStore.data,
            clubsStatus: state => state.clubStore.status,

            seasons: state => state.seasonStore.data,
            seasonStatus: state => state.seasonStore.status,
            seasonList: state => state.seasonStore.list,

            sessions: state => state.sessionStore.data,
            sessionList: state => state.sessionStore.list,

            teams:          state => state.teamStore.data,
            teamList:       state => state.teamStore.list,

            teamStatus: state => state.teamStore.status,

            participantStatus: state => state.participantStore.status,

            navigatorInitialising: state => state.navigatorStore.initialising,

        }),
        sessionNameList: function() {
            const results=[{id: "0", name: 'All Sessions'}]
            for(var i=0;i<this.sessionList.length;i++){
                results.push({
                    id: this.sessionList[i],
                    name: this.sessions[this.sessionList[i]].title
                })
            }
            return results
        },
        switchSession: {
            get(){
                return this.selectedSession
            },
            set(newValue) {
                this.selectSession(newValue)

            }
        },
        switchTryout: {
            get(){
                return this.selectedTryout
            },
            async set(newValue){
                // Is the player permitted to access the current function in the requested tryout?



                await this.selectPane(this.destinationPane(newValue))
                await this.selectTryout(newValue)
                await this.selectSession("0")
                await this.setSelectedParticipant(null)
                this.updateKey=uuidv4()
                this.$root.$emit('tryoutRefresh')
            }
        },
        loading: function() {
            return  this.account.status.loading 
                ||  this.seasonStatus.loading 
                ||  this.tryoutStatus.loading 
                ||  this.clubsStatus.loading 
                || this.teamStatus.loading
                ||  this.navigatorInitialising 
                || !this.stable 
        },
        onlineRegList: function() {
            var list = {}
            for(var key in this.tryouts) {
                if(this.tryouts[key].onlineReg){
                    list[key] = this.tryouts[key]
                }
            }
            return list
        },
        noOnlineRegTrials : function() {
            return Object.keys(this.onlineRegList).length === 0
        },
        selectedSeasonName: {
            get() {
                if(this.selectedSeason){
                    return this.seasons[this.selectedSeason].name

                }
                return "No Season Selected"
            },
            
        },
        selectedSeason : {
            get() {
                return this.selectedSeasonInStore
            },
            set(newValue) {
                this.selectSeason(newValue)
                this.getAllTryoutsForSeason(newValue)
            }
        },
        showSettings: function() {
            if(this.clubs && this.clubs[this.selectedClub]){
                return (this.clubs[this.selectedClub].admin || this.clubs[this.selectedClub].owner) && this.selectedPane==='Settings'
            }
            return false
        },
        menuItems: function () {
            return this.rawMenuItems.filter(this.menuFilter)
        },
        selectedPane: {
            get() {
                return this.selectedClubPane
            },
            set(newValue){
                this.selectPane(newValue)
            }
        },
        rawMenuItems: function() {
            if(this.selectedNav === 'Season')
                return this.navSeason.concat(this.navSystem)
            if(this.selectedNav === 'Tryout')
                return this.navTryout.concat(this.navSystem)
            if(this.selectedNav === 'Team')
                return this.navTeam.concat(this.navSystem)
            if(this.selectedNav === 'DevStream'){
                console.log(this.navDevStream)
                return this.navDevStream.concat(this.navSystem)

            }
            return this.navClub.concat(this.navSystem)
        },
        activeSeasonList: function () {
            // If the user is a club owner, show all seasons
            if(this.account.user.details.id === this.clubs[this.selectedClub].ownerId) {
                return this.seasonList
            }
            // Otherwise only show active seasons
            return this.seasonList.filter(seasonId => this.seasons[seasonId].active)
        },
        permittedDevStreams() {
            const returnList=[]
            const list = this.tryoutList.filter(id => {
                return (this.tryouts[id].eventType==='devprogram' && (this.tryouts[id].selector || this.tryouts[id].headcoach || this.tryouts[id].admin || this.tryouts[id].owner  || this.tryouts[id].athlete|| this.tryouts[id].support) && this.tryouts[id].seasonId === this.selectedSeason)
            })
            for(var idx in list){
                returnList.push({label: this.tryouts[list[idx]].title, value: list[idx]})
            }
            return returnList

        },
        permittedTryouts() {
            const returnList=[]
            const list = this.tryoutList.filter(id => {
                return (this.tryouts[id].eventType==='tryout' && (this.tryouts[id].selector || this.tryouts[id].headcoach || this.tryouts[id].admin || this.tryouts[id].owner  || this.tryouts[id].athlete|| this.tryouts[id].support) && this.tryouts[id].seasonId === this.selectedSeason)
            })
            for(var idx in list){
                returnList.push({label: this.tryouts[list[idx]].title, value: list[idx]})
            }
            return returnList

        },
        permittedTeams() {
            const returnList=[]
            const list = this.teamList.filter(id => {
                return (this.teams[id].headcoach || this.teams[id].assistantcoach || this.teams[id].admin || this.teams[id].owner  || this.teams[id].athlete && this.teams[id].seasonId === this.selectedSeason)
            })
            for(var idx in list){
                returnList.push({label: this.teams[list[idx]].name, value: list[idx]})
            }
            return returnList

        }
    },
    async created () {
        // If the inbound url was "/stripe/onboard/success, flip the user straight into the club banking page"

        // Because this page is re-entrant, make sure we reinitialise the navigator stage from local storage

        this.initNavigator()

        this.stable=false
        this.showBanking=false

        if (this.clubId!==''){
            this.selectClub(this.clubId)
        }


        await this.loadClub(this.selectedClub)

        // If there is a bank setup in progress we get sent here so send the transaction status to the banking setup page if necessary...
        if(this.token==='success'){
            this.showBanking=true
            this.clubSettingsNav()
        }

        await this.findAllSeasonsForClub({clubId:this.selectedClub,isAdmin:false})
        //await this.getAllTryoutsForSeason(this.selectedSeason)
 //       await this.findActiveTryouts(this.selectedClub)
  
        
        if(this.selectedSeason && this.tryoutList.length===0){
            await this.getAllTryoutsForSeason(this.selectedSeason)
            await this.findAllTeamsForSeason({seasonId: this.selectedSeason})
        }  else {
            await this.findActiveTryouts(this.selectedClub)
        }


        // Are we reentrant at this point?
        if((this.selectedClubPane === '')){
            // No so just go to the club home page
            this.selectPane('Club')
            this.clubHomeNav()

        }
        this.stable=true
        /*
        if(this.selectedSession === 0){
        }
        */
    },
    methods: {
        ...mapActions('seasonStore', {
            findAllSeasonsForClub: 'findAllForClub',
        }),
        ...mapActions('clubStore', {
            findAllClubsForUser: 'findAllForUser',
            loadClub: 'load',
        }),
        ...mapActions('teamStore', {
                findAllTeamsForSeason: 'findAllForSeason',
        }),
        ...mapActions('tryoutStore', {
            getAllTryoutsForSeason: 'findAllForSeason',
            findActiveTryouts: 'findActive'
        }),
        ...mapActions('navigatorStore', ['navTo', 'navBack']),
            registrationLink(id) {
            return '/registerfortryout/' + id
        },
        ...mapActions('navigatorStore', {
            selectClub: 'justSelectClub',
            selectSeason: 'selectSeason',
            selectSession: 'setSelectedSession',
            setSelectedParticipant: 'setSelectedParticipant',
            selectPane: 'selectPane',
            selectTryout: 'selectTryout',
            selectNav: 'selectNav',
            initNavigator: 'initialiseNavigator',
        }),
        ...mapActions('stripeStore', {
            onboardSuccess: 'onboardSuccess',
        }),
        responsiveClass(className){
            if(this.$vuetify.breakpoint.smAndDown){
                return className+' mobile'
            }
            return className
        },
        logoURL(clubId) {
            return `https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/clublogos/${clubId}.jpg`
        },
        divider(text){
            if(text==='---') {
                return true
            }
            return false
        },
        clubSelectorNav(){
            this.navTo('home')
            router.push('/')
        },
        /*
        allowed(auth){
            const allowed = 1 + 
                            2*this.clubs[this.selectedClub].admin + 
                            4*this.clubs[this.selectedClub].owner + 
                            8*this.clubs[this.selectedClub].member + 
                            16*this.clubs[this.selectedClub].athlete
            return auth && allowed
        },
        */
        menuFilter(value){
            const thisClub = this.clubs[this.selectedClub]
            const thisTryout = this.tryouts[this.selectedTryout]
            const thisTeam = this.teams[this.selectedTryout]

            if(thisClub) {
                const menuItemActive = 
                    thisClub.admin && value[3] ||
                    thisClub.owner && value[4] || 
                    //thisClub.athlete && value[5] ||
                    (thisTryout && thisTryout.athlete && value[5]) ||
                    (thisTeam && thisTeam.athlete && value[5]) ||
                    //thisClub.member && value[6] || 
                    value[7] ||
                    //thisClub.selector && value[8] ||
                    (thisTryout && (thisTryout.selector && value[8])) ||
                    //thisClub.support && value[9] ||
                    thisClub.systemAdmin && value[10] ||
                    (thisTryout && thisTryout.headcoach && value[11]) ||
                    (thisTeam && thisTeam.headcoach && value[11]) ||
                    (thisTryout && thisTryout.assistantcoach && value[12]) ||
                    (thisTeam && thisTeam.assistantcoach && value[12]) ||
                    (thisTryout && thisTryout.programcoach && value[13]) ||
                    (thisTeam && thisTeam.programcoach && value[13]) ||
                    false

                return menuItemActive
            }
            return false
        },
        seasonsNav() {
            this.selectPane('Seasons')
        },
        tryoutsNav() {
            this.selectPane('Tryouts')
        },
        tryoutHomeNav() {
            this.selectNav('Tryout')
            this.selectPane('TryoutStatus')
        },
        seasonHomeNav() {
            this.selectPane('SeasonHome')
            this.selectNav('Season')
        },
        teamsNav() {
            this.selectPane('Teams')
        },
        clubSettingsNav() {
            this.selectPane('Settings')
        },
        clubHomeNav() {
            this.selectNav('Club')
            this.selectPane('Club')
        },
        manageTryoutNav() {
            this.selectPane('TryoutSettings')
        },
        tryoutParticipantsNav() {
            this.selectPane('ParticipantList')
        },
        teamSelectNav() {
            this.selectPane('SelectTeams')
        },
        newTeamSelectNav() {
            this.selectPane('NewSelectTeams')
        },

        prepareOffersNav() {
            this.selectPane('PrepareOffers')
        },
        editRegEmail() {
            this.selectPane('EditRegEmail')
        },
        editOfferEmail() {
            this.selectPane('EditOfferEmail')
        },
        tryoutCollectData() {
            this.selectPane('TryoutCollectData')
        },
        systemInfoNav() {
            this.selectPane('SystemInfo')
        },
        journalHomeNav() {
            this.selectPane('JournalHome')
        },
        programCoachOverviewNav(){
            this.selectPane('DevStreamProgramCoachOverview')
        },
        episodesNav(){
            this.selectPane('DevStreamEpisodes')
        },
        journalAthleteHomeNav() {
            this.selectPane('JournalAthleteHome')
        },
        teamHomeNav(){
            this.selectNav('Team')
            this.selectPane('TeamHome')
            this.selectTryout(this.selectedTryout)
            this.$root.$emit('tryoutRefresh')
        },
        devStreamHomeNav(){
            this.selectNav('DevStream')
            this.selectPane('DevStreamHome')
        },
        reportsNav(){
            this.selectNav('DevStream')
            this.selectPane('DevStreamEventReports')
        },
        newEpisodeFormNav(){
            this.selectPane('DevStreamNewEpisode')
        },
        destinationPane(destinationTryoutId){
            // Look at the destination tryout to see if the current pane is accessible to the user... API calls will fail badly otherwise...

            const thisClub = this.clubs[this.selectedClub]
            const thisTryout = this.tryouts[destinationTryoutId]
            const thisTeam = this.teams[destinationTryoutId]

            const currentPaneTitle = this.menuMap[this.selectedPane]

            if(thisClub) {
                // Across all rawMenuItems...

                for(var i in this.rawMenuItems){
                    const value = this.rawMenuItems[i]
                    if(value[1]===currentPaneTitle){

                        const menuItemActive = 
                            thisClub.admin && value[3] ||
                            thisClub.owner && value[4] || 
                            thisClub.athlete && value[5] ||
                            (thisTryout && thisTryout.athlete && value[5]) ||
                            (thisTeam && thisTeam.athlete && value[5]) ||
                            value[7] ||
                            (thisTryout && (thisTryout.selector && value[8])) ||
                            (thisTryout && (thisTryout.support && value[9])) ||
                            (thisTryout && thisTryout.headcoach && value[11]) ||
                            (thisTeam && thisTeam.headcoach && value[11]) ||
                            (thisTryout && thisTryout.assistantcoach && value[12]) ||
                            (thisTeam && thisTeam.assistantcoach && value[12]) ||
                            (thisTeam && thisTeam.programcoach && value[13]) ||
                            (thisTryout && thisTryout.programcoach && value[13])
                            false

                        if(menuItemActive){
                            return this.selectedPane
                        }
                    }
                }

            }
            if(this.selectedNav === 'Team') {
                return 'TeamHome'
            } else {
                return 'TryoutStatus'
            }
        }
    },
    watch: {
        selectedPane() {
         //   this.drawer=false
        }
    },
    data() {
        return {
            updateKey:uuidv4(),
            showBanking:true,
            onlineRegListLoading: true,
            noOnlineRegMsg: false,
            navClub: [
                // Auth: Owner, Admin, Athlete, Member, public, selector, support, sysadmin, headcoach, assistantcoach

                //                                                                                                     A
                //                                                                                                     s
                //                                                                                                     s  P
                //                                                                                                     i  r
                //                                                                                                     s  o
                //                                                                                                  H  t  g
                //                                                                                         S     S  e  a  r
                //                                                                                A        e  S  y  a  n  a
                //                                                                                t  M  P  l  u  s  d  t  m
                //                                                                          O  A  h  e  u  e  p  A  C  C  C
                //                                                                          W  d  l  m  b  c  p  d  o  o  o
                //                                                                          n  m  e  b  l  t  o  m  a  a  a
                //                                                                          e  i  t  e  i  o  r  i  c  c  c
                //                                                                          r  n  e  r  c  r  t  n  h  h  h

                ['mdi-home', 'Club Home', this.clubHomeNav,                                 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
                ['mdi-calendar', 'Seasons', this.seasonsNav,                                1, 0, 0, 0, 0, 0, 1, 1, 1, 1, 0],
                ['mdi-cog-outline','Club Settings',  this.clubSettingsNav,                  1, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0],
            ],
            navSeason: [
                ['mdi-calendar', 'Season Home', this.seasonHomeNav,                         1, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1],
                ['mdi-clipboard-edit-outline', 'Edit Season Details', this.seasonsNav,      1, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0],
            ],
            navTryout: [
                ['mdi-home-outline', 'Tryout Homepage', this.tryoutHomeNav,                 1, 1, 1, 0, 0, 0, 1, 1, 1, 1, 1],
                ['mdi-cog-outline', 'Tryout Setup', this.manageTryoutNav,                   1, 1, 0, 0, 0, 0, 0, 1, 1, 0, 0],
                ['mdi-clipboard-edit-outline','Collect Data', this.tryoutCollectData,       0, 0, 0, 0, 0, 1, 0, 1, 1, 1, 0],
                ['mdi-account-group', 'Participant List', this.tryoutParticipantsNav,       1, 1, 0, 0, 0, 1, 1, 1, 1, 1, 1],
                ['mdi-notebook-outline', 'Journal Library', this.journalHomeNav,            1, 1, 0, 0, 0, 0, 0, 1, 1, 1, 0],
                ['mdi-notebook-outline', 'My Journal', this.journalAthleteHomeNav,          0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0],
                ['---','Messaging', this.clubHomeNav,                                       1, 1, 0, 0, 0, 0, 1, 1, 1, 1, 1],
                ['mdi-account-multiple-check', 'Team Selections', this.teamSelectNav,       1, 1, 0, 0, 0, 1, 0, 1, 1, 1, 1],
                ['---','Messaging', this.clubHomeNav,                                       1, 1, 0, 0, 0, 0, 1, 1, 1, 1, 0],
                ['mdi-email-edit-outline', 'Prepare Offers', this.prepareOffersNav,         1, 1, 0, 0, 0, 0, 0, 1, 1, 0, 0],
            ],
            navSystem: [
                ['---','Messaging', this.clubHomeNav,                                       1, 1, 0, 0, 0, 0, 1, 1, 0, 0, 0],
                ['mdi-cog-outline', 'System Information', this.systemInfoNav,               0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0],
            ],
            navTeam: [
                ['mdi-home-outline', 'Team Homepage', this.teamHomeNav,                     1, 1, 1, 0, 0, 0, 0, 1, 1, 1, 1],
//                ['mdi-cog-outline', 'Team Setup', this.manageTryoutNav,                   1, 1, 0, 0, 0, 0, 0, 1, 1, 0, 1],
//                ['mdi-account-group', 'Participant List', this.tryoutParticipantsNav,     1, 1, 0, 0, 0, 1, 1, 1, 1, 1, 1],
                ['mdi-notebook-outline', 'Journal Library', this.journalHomeNav,            1, 1, 0, 0, 0, 0, 0, 1, 1, 1, 1],
                ['mdi-notebook-outline', 'My Journal', this.journalAthleteHomeNav,          0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0],
            ],
            navDevStream: [
                ['mdi-home-outline', 'Development Stream Homepage', this.devStreamHomeNav,  1, 1, 1, 0, 0, 1, 0, 1, 1, 1, 1],
                ['mdi-clipboard-plus-outline', 'New Episode Form', this.newEpisodeFormNav,  1, 1, 0, 0, 0, 1, 0, 1, 1, 1, 1],
                ['mdi-clipboard-multiple-outline', 'Episode Library', this.episodesNav,     1, 1, 0, 0, 0, 1, 0, 1, 1, 1, 1],
                ['mdi-notebook-outline', 'Journal Library', this.journalHomeNav,            1, 1, 0, 0, 0, 1, 0, 1, 1, 1, 1],
                ['mdi-notebook-outline', 'My Journal', this.journalAthleteHomeNav,          0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0],
                ['mdi-clipboard-multiple-outline', 'Program Reports', this.reportsNav,      1, 1, 0, 0, 0, 0, 0, 1, 1, 0, 1],
                ['mdi-binoculars', 'Program Coach Overview', this.programCoachOverviewNav,  1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 1],
            ],
            menuMap:{
                'TryoutStatus': 'Tryout Homepage',
                'TryoutSettings': 'Tryout Setup',
                'TryoutCollectData': 'Collect Data',
                'ParticipantList': 'Participant List',
                'JournalHome': 'Journal Library',
                'JournalAthleteHome':'My Journal',
                'SelectTeams':'Team Selections',
                'PrepareOffers':'Prepare Offers',
            },

            drawer: null,
            season: null,
            defaultLogo: 'https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/csclubbadge.png',
            stable:false,
        }
    }
}
</script>
<style >
    v-expansion-panels {
        width: 80%;
    }
    
    .club-tiles {
        width: 100%;
    }

    

    .xs-s-club-name {
        color: white;
        padding-left: 0px;
        font-weight: bold;
        font-size:small;
    }

    .club-name {
        color: white;
        padding-left: 10px;
        font-weight: bold;
    }

    .club-name:hover{
        text-decoration: underline;
    }

    .xs-s-tryout-name{
        font-size: x-small;
        color: white;
        padding-left : 20px;

    }

    .tryout-name2{
        font-size: small;
        color: white;
        margin-left:10px;
        margin-top:auto;
        margin-bottom:auto;
    }


    .tryout-title{
        font-weight: bolder;
    }

    .club-banner-cell {
        margin-top:0px;
    }

    .season-name {
        font-size: large;
        font-weight:600;
        color:white;
        padding-left: 50px;

    }
    .season-name.mobile{
        font-size:small;
    }

    .season-name:hover{
        text-decoration: underline;
    }
    
    .coachsight-pane {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        margin: 0vw 1vw 0vw 1vw;
        width: 97vw;
    }
    
    .clubpane {
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: inline-block;
        float: left;
        height:80vh;

    }

    .club-vcard-title {
    }

    .club-pane-banner {
        background-color:var(--cs1-lev1);
        width: 100%;

    }

    .seasontext {
        color: white;
        font-weight: 900;
    }

.season-selector {
    color: white;
    font-size: small;
}


.v-toolbar__content {
    padding-left:0px;
    margin-left: 0px;
}
.v-list-item__title {
    font-size: small;
    padding:0px;
    margin:0px;
}
.v-list-item__content {
    text-align: left;
}
.v-list-item {
    min-height: 24px;
}
.v-list-item__icon {
    margin: 0px;
}

.container{
    padding: 0px;
}

.bar-box{
    display:flex;
    flex-flow:row;

}
.chevron {
    position:relative;
    display:block;
    height:50px; /*Height should be double border thickness*/
}
.chevron::before,
.chevron::after {
    position:absolute;
    display:block;
    content:"";
    border:25px solid transparent; /*Adjust chevron size*/
    margin-left:-1;
}
/*Change the four instances of 'top' below to rotate chevron*/
/*Use (top/right/bottom/left) where you want the back of the chevron to be*/
.chevron::before {
    left:0;
    border-left-color:var(--cs2-lev1); /*Chevron Color*/
}
.chevron-block {
    cursor: pointer;
    position: relative;
    display: flex;
    /*width: 150px;*/
    height: 50px;
    background: var(--cs2-lev1);
    font: 22px Constantia, sans-serif;
    color: white;
    text-align: center;
    text-decoration: none;
    padding-left:10px;
    padding-right:10px;
    margin-left:0px;
    z-index: 30;
    font-size: small;
    font-weight:600;
    margin-top:auto;
    margin-bottom:auto;
}
.v-select.v-text-field:not(.v-text-field--single-line) input{
    display:none;
}
.bar-selects {
    font-family:'Varela round';
    color:white;
    font-weight:600;
    overflow:hidden;
    font-size:small;
}

.bar-selects.mobile {
    font-size:xx-small;
}

.club-season-bar{
    margin-top:auto;
    margin-bottom:auto;
    display:flex;
    flex-flow:row;
}

.club-season-bar.mobile{
    flex-flow:column;
}


.team-name{
    font-size: small;
    color: white;
    margin-left:10px;
    margin-top:auto;
    margin-bottom:auto;
}

.team-select-label{
    margin-top:auto;
    margin-bottom:auto;
    color:white;
    font-weight:600;
    padding-right:5px;
    font-size:medium;
}

.team-select-label.mobile{
    font-size:small;
}

.team-select.mobile .v-input__append-inner{
    display:none !important;;
}

.tryout-select-label{
    margin-top:auto;
    margin-bottom:auto;
    color:white;
    font-weight:600;
    padding-right:5px;
    font-size:medium;
}

.tryout-select-label.mobile{
    font-size:small;
}

.tryout-select.mobile .v-input__append-inner{
    display:none !important;;
}
.tony {
    
}
</style>
