import {sendMessage} from '@/_helpers/service-tools'

export const adminService = {
    findAllForEvent,
    create,
    del,
}

async function findAllForEvent(eventId ) {
    return await sendMessage('GET', 'admin/list', {eventId}, null)
}

async function create(eventId, role, userId) {
    return await sendMessage('POST', 'admin', null, {eventId, userId, role})
}

async function del(payload) {
    return await sendMessage('PATCH', 'admin/delete', payload, null)
}