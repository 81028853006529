import {sendMessage} from '@/_helpers/service-tools'

export const roleService = {
    findAllForEvent,
    create,
    update,
    del,
    linkRoleAndCriterion,
    unlinkRoleAndCriterion,
    loadAllRolesForTeam,
}

async function linkRoleAndCriterion(payload ) {
    return await sendMessage('PATCH', 'rolecriterion/link', {},{
        eventId: payload.eventId, 
        roleId: payload.roleId, 
        criterionId: payload.criterionId, 
        weight: payload.weight,
        order: payload.order,
    }, null)
}
async function unlinkRoleAndCriterion(payload ) {
    return await sendMessage('PATCH', 'rolecriterion/unlink', {},{
        eventId: payload.eventId, 
        roleId: payload.roleId, 
        criterionId: payload.criterionId, 
    }, null)
}

async function loadAllRolesForTeam(payload ) {

    const response =  await sendMessage('GET', 'role/loadAllRolesForTeam', {teamId: payload.teamId}, null)
    return response
}


async function findAllForEvent(payload ) {
    return await sendMessage('GET', 'role/listforevent', {eventId: payload.eventId}, null)
}

async function update(payload) {
    return await sendMessage('PATCH', 'role', {roleId: payload.id}, {name: payload.name})
}

async function del(payload) {
    return await sendMessage('DELETE', 'role', {roleId: payload.roleId }, null)
}

async function create(payload) {
    return await sendMessage('POST', 'role', null, {eventId: payload.eventId, name: payload.name})
}
