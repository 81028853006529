var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('loading'):(this.selectedSeason==='')?_c('div',[_vm._v(" No season has been selected yet ")]):_c('div',[_c('v-row',[_c('v-col',{staticClass:"pane-heading",attrs:{"cols":"11"}},[_vm._v(" Teams for "+_vm._s(this.seasons[this.selectedSeason].name)+" ")]),_c('v-col',{attrs:{"cols":"1"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","x-small":""}},'v-btn',attrs,false),on),[_vm._v(" Add ")])]}}]),model:{value:(_vm.teamDialog),callback:function ($$v) {_vm.teamDialog=$$v},expression:"teamDialog"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("New Team")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"newName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"mdi-label","label":"Team Name"},model:{value:(_vm.newName),callback:function ($$v) {_vm.newName=$$v},expression:"newName"}}),_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"newCompetition","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"mdi-label","label":"Competition Name"},model:{value:(_vm.newCompetition),callback:function ($$v) {_vm.newCompetition=$$v},expression:"newCompetition"}}),_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"newDescription","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"mdi-label","label":"Description"},model:{value:(_vm.newDescription),callback:function ($$v) {_vm.newDescription=$$v},expression:"newDescription"}}),_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDialog}},[_vm._v("close")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){handleSubmit(_vm.create(reset))}}},[_vm._v("Save")])],1)],1)]}}])})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"align-items-left"},[_c('v-expansion-panels',_vm._l((_vm.teamList),function(id){return _c('team-editor',{key:id,attrs:{"teamId":id}})}),1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }