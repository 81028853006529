<template>
    <div v-if="feedback[feedbackId]" style="display:flex;flex-flow:row;">
        <v-tooltip v-if="flagToDelete" top>
            <template #activator="{on}">
                <v-icon small v-on="on" @click.stop="flagToDelete=false"> mdi-delete-restore</v-icon>
            </template>
            Undelete this feedback line
        </v-tooltip>
        <v-tooltip v-else top>
            <template #activator="{on}">
                <v-icon small v-on="on" color="red" @click.stop="flagToDelete=true"> mdi-delete</v-icon>
            </template>
            Delete this feedback line
        </v-tooltip>    

        <v-text-field :style="flagToDelete?'margin-left:5px;text-decoration:line-through;color:red;':'margin-left:5px;'" hide-details dense small  single-line v-model="value"/>
        <v-btn small color="red" :style="flagToDelete?'':'display:none;'" @click="deleteFeedback({feedbackId})">Confirm Delete</v-btn>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {v4 as uuidv4} from 'uuid'

export default{
    props: ['feedbackId', 'parentComponentId'],
    components:{
    },
    computed: {
        ...mapState({
            feedback:       state => state.feedbackStore.data,
        }),
        value:{
            get(){
                if(!this.feedback[this.feedbackId]) return ''
                return this.feedback[this.feedbackId].text
            },
            set(newValue){
                this.updateFeedbackProp({field: 'text', id: this.feedbackId, value: newValue})      
                this.flagChanges()
            }
        },
    },
    methods: {
        ...mapActions('feedbackStore', [
            'updateFeedbackProp',
            'updateFeedback',
            'deleteFeedback',
        ]),
        async saveDetails(){
            if(this.flagToDelete){
                await this.deleteFeedback({feedbackId: this.feedbackId, componentId: this.componentId})
            } else {
                await this.updateFeedback({feedbackId: this.feedback[this.feedbackId]})
            }
            this.changes=false;
            this.updateKey=uuidv4()
            this.$root.$emit('settings-changes-cleared', this.componentId)
          //  this.$root.$emit(`${this.parentComponentId}-listupdate`)
        },
        flagChanges(){
            this.changes=true
            this.$root.$emit("settings-changes-made", this.componentId)
        },

    },
    mounted() {
        this.$root.$on('submit-all-changes', async () => {
            var self=this
            if (self.changes) {
                await self.saveDetails()
                self.changes=false
            }
        })
    },
    async created(){
    },
    data(){
        return {
            flagToDelete: false,
            componentId: uuidv4(),
            changes: false,
        }
    }

}
</script>