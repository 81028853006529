<template>
    <v-card class="no-borders" >
        <v-card-text>
            <span class="sessiontitle">Dates</span>
            <div class="text-left"> {{startDate}} to {{endDate}} </div>
            <br/>
            <span class="sessiontitle" v-if="description">Information</span>
            <div class="description">{{description}}</div>
            <br/>
            <span class="sessiontitle">Sessions</span>
            <v-simple-table>
                <template v-slot: default>
                    <thead>
                        <tr>
                            <th class="text-left">Date </th>
                            <th class="text-left">Time </th>
                            <th class="text-left">Venue </th>
                            <th class="text-left">Description </th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr 
                            v-for="sessionId in sessionList"
                            :key="sessionId"
                        >
                            <td class="text-left" :class="{'finishedEvent': sessionFinished(sessions[sessionId].startDateTime)}">{{moment(sessions[sessionId].startDateTime).format("ddd, MMM Do, YYYY")}}</td>
                            <td class="text-left" :class="{'finishedEvent': sessionFinished(sessions[sessionId].startDateTime)}">{{moment(sessions[sessionId].startDateTime).format("hh:mm a")}}</td>
                            <td class="text-left" :class="{'finishedEvent': sessionFinished(sessions[sessionId].startDateTime)}">{{sessions[sessionId].venue}}</td>
                            <td class="text-left" :class="{'finishedEvent': sessionFinished(sessions[sessionId].startDateTime)}">{{sessions[sessionId].title}}</td>
                        </tr>
                    </tbody>
                </template>

            </v-simple-table>
            <div v-if="reqAttendance">
                Players are required to attend {{reqAttendance}} tryout sessions to qualify for selection. If you are not able to meet this, please contact {{contactDetails}} to make an alternative arrangement.
            </div>
            <br/>
            <div v-if="feesApplicable">
                <span class="sessiontitle">Fees</span>
                    <v-data-table :items="feeTable" :headers="feeHeaders" class="elevation-1" disable-pagination disable-sort disable-filtering hide-default-footer hide-default-header ></v-data-table> 
            
            
            </div>
            <br/>
            <v-divider/>
        </v-card-text>
        <v-card-actions>
                <v-btn text @click="cancel">
                    Cancel
                </v-btn>
                <v-spacer/>
                <v-btn 
                    color="primary"
                    @click="nextStep()"
                >
                    Continue
                </v-btn>

        </v-card-actions>
        <!--
        <div class="buttons">
            <v-row>
            <v-col>

            </v-col>
            <v-spacer/>
            <v-col cols="2">
                <v-btn 
                    color="primary"
                    @click="nextStep()"
                >
                    Continue
                </v-btn>

            </v-col>
            <v-col cols="2">
                <v-btn text @click="cancel">
                    Cancel
                </v-btn>
            </v-col>

            </v-row>
        </div>
    -->
</v-card>


</template>

<script>
import {mapState, mapActions} from 'vuex'
import moment from 'moment'
import { router } from '@/_helpers';

export default {
    props: [],
    computed: {
        ...mapState({
            selectedTryout: state => state.navigatorStore.selectedTryout,
            tryouts: state => state.tryoutStore.data,
            sessions: state => state.sessionStore.data,
            sessionList: state => state.sessionStore.list,
            sessionMeta: state => state.sessionStore.meta,
            formStep: state => state.tryoutregStore.step,
            selectedClub: state => state.navigatorStore.selectedClub,

            globalSettings: state => state.globalsettingsStore.data,
        }),

        startDate: {
            get() {
                return moment(this.tryouts[this.selectedTryout].startDate).format("dddd MMMM Do")
            }
        },
        endDate: {
            get() {
               return moment(this.tryouts[this.selectedTryout].endDate).format("dddd MMMM Do, YYYY") 
            }
        },
        description: {
            get() {
                return this.tryouts[this.selectedTryout].description
            }
        },
        reqAttendance: {
            get() {
                return this.tryouts[this.selectedTryout].reqAttendance
            }
        },
        regFormState: {
            get() {
               return this.formStep 
            },
            set(newValue) {
                this.setStep(newValue)
            }
        },
        feesApplicable(){
            const tryout = this.tryouts[this.selectedTryout]
            return !tryout.feeExemption
        },
        feeHeaders(){
            return [
                {text:"Fee Type", align: 'start', sortable: false, value: 'description'},
                {text:"Fee", align: 'start', sortable: false, value: 'value'},
            ]
        },
        feeTable(){
            const feeArray = []
            const tryout = this.tryouts[this.selectedTryout]
            if(tryout.trialFee1Value>0) feeArray.push({description: tryout.trialFee1Name, value: ((this.globalSettings.baseFee+tryout.trialFee1Value)/100).toLocaleString('en-AU', {style:'currency', currency:'AUD'})})
            if(tryout.trialFee2Value>0) feeArray.push({description: tryout.trialFee2Name, value: ((this.globalSettings.baseFee+tryout.trialFee2Value)/100).toLocaleString('en-AU', {style:'currency', currency:'AUD'})})
            if(tryout.trialFee3Value>0) feeArray.push({description: tryout.trialFee3Name, value: ((this.globalSettings.baseFee+tryout.trialFee3Value)/100).toLocaleString('en-AU', {style:'currency', currency:'AUD'})})

            // If they haven't set any fee at all, just charge the platform fee.
            if(feeArray.length===0){
                feeArray.push({description: 'Tryout fee', value: (this.globalSettings.baseFee/100).toLocaleString('en-AU', {style:'currency', currency:'AUD'})})
            }
            return feeArray
        }

    },
    methods: {
        ...mapActions('sessionStore', {
            findAllSessions: 'findAllForEvent',
        }),
        sessionFinished: function(date) {
                return moment(date).isBefore( new Date())
        },
        ...mapActions('tryoutregStore', {
            setStep: 'setStep',
            nextStep: 'nextStep',
            lastStep: 'lastStep',
        }),
        ...mapActions('navigatorStore', {
            selectClubPane: 'selectPane',
            selectNav: 'selectNav',
        }),
        cancel(){
            this.selectClubPane('SeasonHome')
            this.clubHomeNav()
            router.push('/clubhouse/'+this.selectedClub)
        },
        clubHomeNav() {
            this.selectNav('Club')
            this.selectClubPane('Club')
        },
    },
    created() {
     //   this.findAllSessions(this.selectedTryout)
    }

}


</script>

<style scoped>

    .v-data-table >>> tr:hover{
        background: transparent !important
    }

    .regpane {
        padding:10px;
    }

    .finishedEvent {
        color:red;
        text-decoration: line-through;
    }
    .panetitle {
        font-weight: 900;
        font-size: larger;
        line-height: 200%;
    }
    .panedate {
        font-weight: 500;
        line-height: 200%;
    }
    .sessiontitle {
        display: block;
        text-align: left;
        font-weight: 900;
        font-size: large;
    }

    .description {
        text-align: left;
    }
</style>
