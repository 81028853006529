import  {feedbackService} from "../_services"
import { rc_feedback } from "@/_helpers/responseCodes"
import Vue from 'vue'

function initialiseState(){
    return {
        status: {},
        list: {},
        data: {},
        meta: {},
        error: null,
        internal: {},
        listByCriterionAndScore: {},
    }
}

const state = initialiseState()

const actions={
    purgeData({commit}){
        commit('purgeData')
    },
    async loadAllFeedbackForEvent({commit}, payload){
        try{
            commit('loadAllFeedbackForEventRequest')
            const response = await feedbackService.loadAllFeedbackForEvent(payload)
            commit('loadAllFeedbackForEventSuccess', response)
            return response

        } catch(error){
            commit('loadAllFeedbackForEventFailure', error)
        }
    },
    async createFeedback({commit}, payload){
        try {
            commit('createFeedbackRequest')
            const response = await feedbackService.createFeedback(payload)
            commit('createFeedbackSuccess', response)
            return response
        } catch (error) {
            commit('createFeedbackFailure', error)
        }
    },
    async updateFeedback({commit}, payload){
        try{ 
            commit('updateFeedbackRequest')
            const response = await feedbackService.updateFeedback(payload)
            commit('updateFeedbackSuccess', response)
            return response
        } catch(error) {
            commit('updateFeedbackFailure',error)
        }
    },
    async deleteFeedback({commit}, payload){
        try{
            commit('deleteFeedbackRequest', payload)
            const response = await feedbackService.deleteFeedback(payload)
            commit('deleteFeedbackSuccess', response)
            return response
        } catch(error) {
            commit('deleteFeedbackFailure',error)
            console.error(error)
        }
    },
    updateFeedbackProp({commit},payload) {
        commit('propertyUpdate', payload)
    }

}


function addToLists(data){
    const id = data.id

    const originalListByCriterionAndScore=state.listByCriterionAndScore

    if(!state.list.includes[id]){
        state.list.push(id)

        if(!originalListByCriterionAndScore[data.criterionId]) originalListByCriterionAndScore[data.criterionId]=[[], [], [], [], [], []]
        originalListByCriterionAndScore[data.criterionId][data.associatedScore].push(id)
    }

    Vue.set(state, 'listByCriterionAndScore', originalListByCriterionAndScore )

    Vue.set(state.data, id, data)
    Vue.set(state.meta, id, {
        delete: false
    })
}


const mutations={
    purgeData(state){
        const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }

    },
    //--------------------------------------------
    //   Mutations for loadAllFeedbackForEvent
    //
    
    loadAllFeedbackForEventRequest(){
        state.status={loading:true}
        state.error=null
    },
    loadAllFeedbackForEventSuccess(state, response){
        state.status={}
        state.list=[]
        state.data={}
        state.listByCriterionAndScore= {}

        for(var idx in response.data.result.criteria){
            state.listByCriterionAndScore[response.data.result.criteria[idx].id]=[[],[],[],[],[],[]]
        }
        
        for(idx in response.data.result.feedback){
            addToLists(response.data.result.feedback[idx])
        }

    },
    loadAllFeedbackForEventFailure(error){
        state.error=error
    },
    
    //--------------------------------------------
    //   Mutations for createFeedback
    //
    
    createFeedbackRequest(){
        //Vue.set(state.status, 'loading', true)
        state.error=null
    },
    createFeedbackSuccess(state, response){
        state.status = { responseCode: response.data.code}
        //state.data={}
        state.list=[]
        
        if(state.status.responseCode === rc_feedback.OK) {
            addToLists(response.data.result.feedback)
        }
        //Vue.delete(state.status,'loading')
    },
    createFeedbackFailure(error){
        state.error=error
    },    
    
    //--------------------------------------------
    //   Mutations for updateFeedback
    //
    
    updateFeedbackRequest(){
//        Vue.set(state.status, 'loading', true)
        state.error=null
    },
    updateFeedbackSuccess(state, response){
        state.status = { responseCode: response.data.code}
    },
    updateFeedbackFailure(error){
        state.error=error
    },

      //------------ update data -------- \\
      propertyUpdate(state, payload) {
        const metaFields = "delete,new"
        const {field, id, value} = payload

        if(metaFields.includes(field)){
            state.meta[id][field]=value
            // Manage special fields...
            //switch (field) {
            //}
        } else {
            state.data[id][field]=value
        }
    },
    
    //--------------------------------------------
    //   Mutations for deleteFeedback
    //
    
    deleteFeedbackRequest(state, payload){
        state.status={deleting:true}
        state.error=null
        state.internal.target=payload.feedbackId
    },
    deleteFeedbackSuccess(state, response){
        const item = state.data[state.internal.target]        

        state.list.splice(state.list.indexOf(state.internal.target),1)

        const newList = state.listByCriterionAndScore

        const index = newList[item.criterionId][item.associatedScore].indexOf(state.internal.target)
        newList[item.criterionId][item.associatedScore].splice(index,1)

        Vue.set(state, 'listByCriterionAndScore', newList)

        Vue.delete(state.data, state.internal.target)

        state.internal={}
        state.status = {responseCode: response.data.code}
    
    },
    deleteFeedbackFailure(state, error){
        state.error=error
    },
}
export const feedbackStore = {
    namespaced: true,
    state,
    actions,
    mutations
}