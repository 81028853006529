<template>
    <loading v-if="loading"/>
    <div v-else-if="tryouts[tryoutId].onlineReg">
        <tryout-registration :tryoutId="tryoutId"/>
    </div>
    <div v-else >


        <h1>Selection Tryout Registration is not yet open</h1>
        <br/>
        <div class="info-panel">
            <div>
                <v-row >
                    <v-col cols="2">
                        <img :src="logo" height="60px"/>
                    </v-col>
                    <v-spacer/>
                    <v-col cols="10">
                        <h2> {{tryouts[tryoutId].title}} </h2>
                    </v-col>
                </v-row>

            </div>
            <br/>
            <h3>Selection Dates:</h3>
            <div> Selection trials will run from {{formatDate(tryouts[tryoutId].startDate)}} to {{formatDate(tryouts[tryoutId].endDate)}} </div>
            <br/>
            <h3> Selection Sessions:</h3>
            <div> {{numSessionsString}} </div>
            <p/>
            <ul v-for="sessionId in sessionList" :key="sessionId">
                <li>
                {{sessions[sessionId].title}}: {{moment(sessions[sessionId].startDateTime).format('dddd, MMMM Do hh:mm a')}} at {{sessions[sessionId].venue}} 
                </li>
            </ul>
            <br/>
            <h3>Details</h3>
            <div class="description">{{tryouts[tryoutId].description}}</div>
            <p/>
            <h3 v-if="tryouts[tryoutId].contactName">Contact Details</h3>
            <p v-if="tryouts[tryoutId].contactName">If you require further information, please contact:</p>
            <div style="margin-left:40px">
                <h4 v-if="tryouts[tryoutId].contactName">{{tryouts[tryoutId].contactName}}</h4>
                <p v-if="tryouts[tryoutId].contactPhone">{{tryouts[tryoutId].contactEmail}}</p>
                <p v-if="tryouts[tryoutId].contactPhone">{{tryouts[tryoutId].contactPhone}}</p>
            </div>

        </div>

        
    </div>


</template>

<script>
import {mapState, mapActions} from 'vuex'
import Loading from '@/components/loading'
import moment from 'moment'

import TryoutRegistration from '@/ClubPanes/TryoutRegistration'

import {rules} from '@/_helpers/rules'

export default {
    props: [
        'tryoutId',
    ],
    components: {
        Loading,
        TryoutRegistration,
    },
    computed : {
        ...mapState({
            account:        state => state.account,

            tryouts:        state => state.tryoutStore.data,
            tryoutStatus:   state => state.tryoutStore.status,

            sessions:       state => state.sessionStore.data,
            sessionList:    state => state.sessionStore.list,
            sessionStatus:  state => state.sessionStore.status,

            seasons:        state => state.seasonStore.data,
            seasonStatus:   state => state.seasonStore.status,

            clubs:          state => state.clubStore.data,
            clubStatus:     state => state.clubStore.status,
        }),
        loading: function () {
            return  false
                ||  this.account.status.loading
                ||  this.tryoutStatus.loading
                ||  this.seasonStatus.loading
                ||  this.clubStatus.loading
                ||  this.sessionStatus.loading
                || !this.created
        },
        numSessionsString() {
            return "There are " + this.sessionList.length + " sessions available:"
        },
        logo() {
            const tryout = this.tryouts[this.tryoutId]
            const season = this.seasons[tryout.seasonId]
            const club = this.clubs[season.clubId]

            return club.logo
        }
    },
    methods: {
        ...mapActions('navigatorStore', {
            selectClub: 'selectClub',
            selectClubPane: 'selectPane',
            selectTryout: 'selectTryout',
            selectSeason: 'selectSeason',
        }),
        ...mapActions('tryoutStore', {
            loadTryout: 'loadTryout',
        }),
        ...mapActions('seasonStore', {
            loadSeason: 'loadSeason',
        }),
        ...mapActions('clubStore', {
            loadClub: 'load',
        }),
        ...mapActions('sessionStore', {
            loadAllSessionsForTryout: 'findAllForEvent',
        }),
        ...mapActions('appquestionStore', {
            findAllQuestions : 'loadAllQuestionsForEvent'
        }),
        ...mapActions('messageStore',{
            findAllMessages: 'loadAllForEvent'
        }),
        formatDate: function(d) {
            return d
                ? moment(d).format("DD/MM/YYYY")
                : "";
        },
    },
    async created() {
        if(!this.created){

        const tryout = await this.loadTryout(this.tryoutId)
        this.selectTryout(this.tryoutId)
        await this.loadAllSessionsForTryout(this.tryoutId)
        await this.loadSeason({seasonId: tryout.seasonId})

        await this.loadClub(this.seasons[tryout.seasonId].clubId)
        await this.findAllQuestions({eventId: this.tryoutId})
        await this.findAllMessages(this.tryoutId)
        sessionStorage.removeItem('redirectPath')

        this.created=true
        }
    },
    data() {
        return {
            created: false,
            rules: rules,
            response: {
                
            }
        }
    },
    watch: {
        questions : function() {
            for(var idx in this.questionList) {
                if(!this.response[this.questionList[idx]]) this.response[this.questionList[idx]]=""
            }
        }
    }
}
</script>

<style>
.v-label {
    display: block !important;
}

.v-input--selection-controls .v-input__slot > .v-label {
    flex-direction: column;
    align-items: flex-start;
}
.aquestion-label {
    flex-direction: column;
    align-items: flex-start;
    display: block !important;
    word-break: break-word;

}
.questionLabel-mobile{
    font-size: 0.9em;
    display: flex;
    justify-content: left;
    text-align: left;
            padding-left: 2em;
        text-indent:-2em;
    margin-top: 5px;
}

.info-panel{
    padding: 20px;
    text-align: left;
}

li {
    padding-bottom: 10px;
    list-style: disc;
}
</style>