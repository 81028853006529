<template>
<div>
        PAyment Cancelled
    </div>

</template>
<script>

export default{
    props: [],
    components: {},
    computed: {},
    methods: {},
    data(){
        return {

        }
    }
}

</script>
