import { sendMessage } from "../_helpers/service-tools";

export const feedbackService = {
    loadAllFeedbackForEvent,
    createFeedback,
    updateFeedback,
    deleteFeedback,
}

async function deleteFeedback(payload){
    return await sendMessage('DELETE', 'feedback', {feedbackId: payload.feedbackId}, null)
}

async function loadAllFeedbackForEvent(payload){
    return await sendMessage('GET', 'feedback', {eventId: payload.eventId}, null)
}

async function createFeedback(payload){
    return await sendMessage('POST', 'feedback', {}, {eventId: payload.eventId, criterionId: payload.criterionId, associatedScore: payload.associatedScore, text: payload.text, category: payload.category})
}

async function updateFeedback(payload){
    const newPayload={}

    if(Object.prototype.hasOwnProperty.call(payload, 'text')) newPayload.text=payload.text
    if(Object.prototype.hasOwnProperty.call(payload, 'category')) newPayload.category=payload.category
    if(Object.prototype.hasOwnProperty.call(payload, 'associatedScore')) newPayload.associatedScore=payload.associatedScore
    return await sendMessage('PATCH', 'feedback', {feedbackId: payload.id}, newPayload)
}