<template>
    <loading v-if="loading"/>
    <div v-else>
        <v-row>
            <v-col cols="11" class="pane-heading">
                Edit Registration Confirmation Email
            </v-col>
            <v-spacer/>
            <v-col cols="1" style="text-align: right;">
                <v-btn        small color="primary" v-if="editting" @click="save">Save</v-btn>
                <v-btn v-else small color="primary" @click="editting=true">Edit</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header style="font-size:small">
                        <b>Editor Instructions</b>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="text-left">
                
                    You can define the mail that goes out to registered participants here. There are special values you can insert in your message:<br/>
                    <br/>
                    <ul>
                        <li ><b>$fname </b>: The Participant's first name</li>
                        <li ><b>$lname</b>: The Participant's last name</li>
                        <li ><b>$tryoutname</b>: The name of the tryout that the participant has registered for</li>
                        <li ><b>$clubname</b>: The name of your club</li>
                        <li ><b>$clublogo</b>: Your club logo (if it has been uploaded)</li>
                        <li ><b>$number</b>: The participant's ID number</li>

                    </ul>
                    <br/>
                    The following values can also he inserted if they have been defined for the tryout in "Settings"<br/>
                    <ul>
                        <li><b>$cname</b>: The name of the tryout contact person</li>
                        <li><b>$cphone</b>: The phone number for the tryout contact person</li>
                        <li><b>$cemail</b>: The email for the tryout contact person</li>

                    </ul>

                    </v-expansion-panel-content>
                </v-expansion-panel>

            </v-expansion-panels>
        </v-row>
        <v-row>
            <div v-if="!editting" style="padding-top: 20px">
                <b>Preview Email</b>
            </div>
            <div v-if="editting" style="padding-top: 20px">
                <b>Edit your Registration Email below</b>
            </div>
        </v-row>
        <v-row>
            <div style="width:100%">
                <v-card class="vue-editor">
                                <vue-editor v-if="editting" v-model="registrationMessage" :editorOptions="editorSettings" width="100%" />
                                <div v-else class="ql-editor" v-html="sampleHTML"/>
                                <email-footer/>
                </v-card>
            </div>
        </v-row>
    </div>

</template>

<script>
import Loading from '@/components/loading'
import { VueEditor } from "vue2-editor"

import 'quill/dist/quill.core.css'
import { mapActions, mapState } from 'vuex'
import EmailFooter from '@/components/EmailFooter'

//eslint-disable-next-line
import DividerToolbar from 'quill-divider'

/*
import {Quill} from "vue2-editor"
var ColorClass=Quill.import('attributors/class/color')
var SizeStyle = Quill.import('attributors/style/size')

Quill.register(ColorClass, true)
Quill.register(SizeStyle,true)
*/

export default {
    props: [],
    components: {
        EmailFooter,
        Loading,
        VueEditor,
    },
    computed: {
        ...mapState({
            messages:       state => state.messageStore.data,
            messageList:    state => state.messageStore.list,
            messageStatus:   state => state.messageStore.status,
            msgByName:      state => state.messageStore.listByName,

            clubs:          state => state.clubStore.data,
            clubStatus:     state => state.clubStore.status,

            tryouts:        state => state.tryoutStore.data,
            tryoutStatus:   state => state.tryoutStore.status,

            selectedClub:   state => state.navigatorStore.selectedClub,
            selectedTryout: state => state.navigatorStore.selectedTryout,

        }),
        loading: function() {
            return false 
                || this.messageStatus.loading
                || this.clubStatus.loading
                || this.tryoutStatus.loading
                
        },
        registrationMessage: {
            get(){
                return this.messages[this.msgByName['registrationEmail'].id].message
            },
            set(newValue){
                this.updateMessageProperty({field: 'message', id: this.msgByName['registrationEmail'].id, value: newValue})
            }
        },
        contactName: function() {
            if(this.tryouts[this.selectedTryout].contactName) return this.tryouts[this.selectedTryout].contactName
            return '<span style="color:red;font-weight:600">No contact name has been defined for this tryout</span>' 
        },
        contactPhone: function() {
            if(this.tryouts[this.selectedTryout].contactPhone) return this.tryouts[this.selectedTryout].contactPhone
            return '<span style="color:red;font-weight:600">No contact phone has been defined for this tryout</span>' 
        },
        contactEmail: function() {
            if(this.tryouts[this.selectedTryout].contactEmail) return this.tryouts[this.selectedTryout].contactEmail
            return '<span style="color:red;font-weight:600">No contact email has been defined for this tryout</span>' 
        },
        sampleHTML() {
            var finalString = this.messages[this.msgByName['registrationEmail'].id].message
            finalString = finalString.replace(/\$fname/g, this.firstName)
            finalString = finalString.replace(/\$lname/g, this.lastName)
            finalString = finalString.replace(/\$tryoutname/g, this.tryouts[this.selectedTryout].title)
            finalString = finalString.replace(/\$cname/g, this.contactName)
            finalString = finalString.replace(/\$number/g, this.participantNumber)
            finalString = finalString.replace(/\$cphone/g, this.contactPhone)
            finalString = finalString.replace(/\$cemail/g, this.contactEmail)
            finalString = finalString.replace(/\$clubname/g, this.clubs[this.selectedClub].name)
            finalString = finalString.replace(/\$clublogo/g, "<img height=\"50px\" src=\""  + this.clubs[this.selectedClub].logo + "\"/>")
            return finalString
        },
        
    },
    methods: {
        ...mapActions('messageStore', {
            createMessage: 'create',
            loadAllMessages: 'loadAllForEvent',
            updateMessage: 'update',
            updateMessageProperty: 'propertyUpdate',
        }),
        save() {
            this.updateMessage({message: this.messages[this.msgByName['registrationEmail'].id]})
            this.editting=false
        },
    },
    async created(){
        if(this.selectedTryout) {
            await this.loadAllMessages(this.selectedTryout)
        }

        if(this.messageList.length === 0 || !this.msgByName['registrationEmail']) {
            // The messages need to be created
            await this.createMessage({eventId: this.selectedTryout, name: 'registrationEmail', message: this.block})
        }

    },
    data() {
        return {
            firstName: 'Jane',
            lastName: 'Smith',

            customToolbar: [
                [{header: []}],
                ["bold","italic","underline",{ "color": []} ],
                [{list: "ordered"}, {list: "bullet"}],
                [{align: []}],
                ["close-button"],
            ],
            editorSettings: {
                modules:{
                    divider: true, 
                    toolbar: {
                        container: [
                            [{header: []}],
                            ["bold","italic","underline",{ "color": []} ],
                            [{list: "ordered"}, {list: "bullet"}],
                            [{align: []}],
                            ["divider", "link"],
                            [{"indent": "-1"}, {'indent': '+1'}],
                            [{'script': 'sub'}, {'script':'super'}],
                            ["closeButton"],

                        ],
                        handlers: {
                            closeButton: () => {
                            }
                        }
                    }
                }
            },
            editting: false,
            block: `<h2>$clublogo $clubname</h2><div class="quill-hr" contenteditable="false"><hr style="border: none;border-bottom: 1px inset;" class=""></div><p>\n</p><p>Dear $fname $lname,</p><p>\n</p><p>Thanks for registering for $tryoutname</p><p>\n</p><p>Your participant number will be used to identify you during the trials, and will be marked on your leg and shoulder with a temporary marker (please advise out staff if you have an allergy to these pens)</p><p><br></p><h2 class="ql-align-center"><strong style="color: rgb(0, 102, 204);">Your participant number is:</strong></h2><p class="ql-align-center">$number</p><p><br></p><p>If you are not able to attend our tryout sessions, please ensure you contact us to let us know.</p><p>\n</p><h3>Tryout Contact:</h3><p class="ql-indent-1">$cname</p><p class="ql-indent-1">$cemail</p><p class="ql-indent-1">$cphone</p>`,
            participantNumber: `<div style="text-align: left"><div style="display: flex;justify-content: center;border-style: solid;width: 20%;margin-left: auto;margin-right: auto;margin-top: 30px;margin-bottom: 30px;font-size:40px;border-radius: 36px;background: blue;color: white;font-weight: 900;padding: 40px;font-family:sans-serif;"><div style="text-align:right; display: flex;flex-direction:column;justify-content: center;"><div>42</div></div></div>`,
        }
    }
}

</script>

<style>
@import "https://cdnjs.cloudflare.com/ajax/libs/quill/1.3.7/quill.core.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/quill/1.3.7/quill.snow.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/quill/1.3.7/quill.bubble.css";

.vue-editor {
}

.ql-toolbar:after {
    content: none !important;
    text-align: left;
}

.ql-closeButton {
    text-align: center;
}

</style>