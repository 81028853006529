<template>
    <div class="container">
        <v-card class="mx-auto" :loading="status.loading" outlined shaped elevation= "5" >
            <v-card-text>
                <h3>Reset your password</h3>
                <v-form ref="loginForm" form @submit.prevent="handleSubmit">
                    <input type="hidden" id="g-recaptcha-response" name="g-recaptcha-response">
                    <input type="hidden" name="action" value="validate_captcha">
                    We will send you an email with a link to reset your password...
                    <v-text-field label="Email" hide-details="auto" :rules="[rules.required, rules.email]" v-model="email"/>
                
                    <div class="form-group row pt-6">
                        <label for="robot" class="col-sm2 col-form-label"></label>
                        <div class="col-sm-10 padding-on-top">
                            <vue-recaptcha ref="recaptcha" @verify="onVerify" :sitekey="recaptchaSiteKey"></vue-recaptcha>
                        </div>
                    </div>
                    <div class="form-group-btn">
                        <v-btn 
                            class="login-btn" 
                            type="submit" 
                            color="primary" 
                            :disabled="status.loading"
                        >
                            Reset
                        </v-btn>

                    </div>
                    <div>
                        Remembered it now? <router-link to="/login" class="btn btn-link">Login here</router-link>
                    </div>
                    <v-divider></v-divider>
                    <div class="pt-6">
                        Don't have an account yet? <router-link to="/register" class="btn btn-link">Register now</router-link>
                    </div>
                </v-form>
            </v-card-text>

        </v-card>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { VueRecaptcha } from 'vue-recaptcha'

const _recaptchaSiteKey = process.env.VUE_APP_RECAPTCHA_KEY

export default {
    data () {
        return {
            recaptchaSiteKey: _recaptchaSiteKey,
            recaptchaToken: '',
            robot: false,
            email: '',
            submitted: false,
            rules: {
                required:   value => !!value || 'Required',
                email:      value => !value || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'Not a valid email address' 
            },
        }
    },
    components: {
//        Loading,
        VueRecaptcha
    },
    computed: {
        ...mapState('account', ['status'])
    },
    methods: {
        ...mapActions('account', ['recover']),
        // eslint-disable-next-line
        handleSubmit (e) {
            if(this.robot){
                this.submitted = true;
                let self = this;

                if(self.$refs.loginForm.validate()) {
                    self.recover({email: self.email, recaptchaToken: this.recaptchaToken});
                }
            }
        },
        onVerify: function (response) {
            if(response) {
                this.robot = true
                this.recaptchaToken = response
            }
        },
    }
};
</script>

<style>

form {
    margin-top: 40px;
}
.container {
    display: flex;
    justify-content: center;
    padding: 40px;
}


.form-group-btn {
    clear: both;
    padding-top: 20px;
    padding-bottom: 20px;
}

.login-form {
   display: flex;
    width: 100%;
}

.login-sheet {
    width: 70%;
    elevation: 10;
    color: grey lighten-3;
}
</style>