<template>
    <div :style="cssProps" class="bounded-column">
        <div class="bounded-column-title" :style="cssProps"><slot name="title"></slot></div>
        <div class="bounded-column-content"><slot name="content"></slot></div>
    </div>
</template>

<script>

    export default {
        name: 'BoundedColumn',
        props: {
            titleHeight: {
                default:null
            },
            titleBackgroundColor: {
                default: null
            },
            titleColor: {
                default: null
            },
            sideMargin: {
                default: '00px',
            },
            sidePadding: {
                default:'00px',
            },
            borderColor: {
                default: 'grey'
            },
            height: {
                default: 'auto'
            },
            topPadding: {
                default: '10px'
            },
            topMargin: {
                default: '0px'
            },
            bottomMargin: {
                default: '10px'
            },
        },
        computed: {
            cssProps() {
                return {
                    '--top-padding': this.topPadding,
                    '--side-margin': this.sideMargin,
                    '--side-padding' : this.sidePadding,
                    '--border-color' : this.borderColor,
                    '--top-margin' : this.topMargin,
                    '--bottom-margin' : this.bottomMargin,
                    '--height' : this.height,
                    '--background': this.titleBackgroundColor,
                    '--color': this.titleColor?this.titleColor:'black',
                    '--titleHeight': this.titleHeight?this.titleHeight:'auto',
                }
            }
        },
    }
</script>

<style scoped>
.bounded-column {
        border-radius: 10px;
        border-width: 2px;
        border-color: var(--border-color);
        border-style: solid;
        padding-left: var(--side-padding);
        padding-right:var(--side-padding);
        margin-right: var(--side-margin);
        margin-left: var(--side-margin);
        padding-top: var(--top-padding);
        height: var(--height);
        margin-top: var(--top-margin);
        margin-bottom: var(--bottom-margin);
        background: var(--background);
        
    }
.bounded-column-title {
    text-align: center;
    font-size: small;
    margin-top: 0px;
    margin-left:0px;
    background: var (--background);
    padding-left:10px;
    padding-right: 10px;
    word-break:break-word;
    color: var(--color);
    font-weight: 600;
    height: var(--titleHeight);
    border-bottom-color: white;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    display:flex;
    flex-direction:column;
}
.bounded-column-content {
    margin-bottom:10px;
}
</style>