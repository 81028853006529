<template>
    <div style="text-align:left;padding: 0px 10px 0px 10px">
        <ValidationObserver v-slot="{handleSubmit, reset}" ref="form">
            <v-row>
                <v-col cols="12" style="text-align:right">
                    <v-btn @click.native.stop 
                        v-if="false && changes" 
                        x-small 
                        v-on:click="saveDetails" 
                        depressed 
                        color="primary" 
                        class="save-btn"
                        style="margin-right:10px"
                    >
                        Save
                    </v-btn>
                        <v-btn color="primary" x-small @click="dialog=true">
                            Add new attribute
                        </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="11"/>
                <v-col cols="1">
                    <v-dialog v-model="dialog" persistent max-width="600px">
                        <form ref="newAttributeForm" @submit.prevent="handleSubmit(create(reset))">
                            <v-card>
                                <v-card-title>
                                    <span class="text-h5">New Attribute</span>
                                </v-card-title>
                                <v-card-text>
                                            <v-row>
                                                <v-col cols="12">
                                                    <ValidationProvider name="Attribute Name" rules="required" v-slot="{errors}">
                                                        <v-text-field hide-details="auto" v-model="newAttribute" prepend-icon="mdi-rename-box" label="Attribute Name"/>
                                                        <div class="form-error">{{ errors[0] }}</div>
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col cols="12">
                                                    <ValidationProvider name="Description" rules="required" v-slot="{errors}">
                                                        <v-text-field hide-details="auto" v-model="newDescription" prepend-icon="mdi-information" label="Description"/>
                                                        <div class="form-error">{{ errors[0] }}</div>
                                                    </ValidationProvider>
                                                        
                                                </v-col>
                                                <v-col cols="12">
                                                        <v-text-field hide-details="auto" v-model="newUnits" prepend-icon="mdi-information" label="Units"/>
                                                </v-col>
                                            </v-row>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="closeDialog" >close</v-btn>
                                    <v-btn color="blue darken-1" text type="submit" >Save</v-btn>
                                </v-card-actions>
                            </v-card>
                        </form>
                    </v-dialog>
                </v-col>
            </v-row>
            <v-expansion-panels>
                <attribute-editor :attributeId="id" v-for="id in list" :key="id"/>
            </v-expansion-panels>
        </ValidationObserver>
    </div>

</template>

<script>
import {mapState, mapActions} from 'vuex'
import AttributeEditor from '@/ClubPanes/NewTryoutSettingsPanels/AttributeEditor'
import { ValidationObserver, ValidationProvider } from 'vee-validate'


import {rules} from '@/_helpers/rules'

export default {
    props: [],
    components: {
        AttributeEditor,
        ValidationObserver,
        ValidationProvider,
    },
    computed: {
        ...mapState({
            status: state => state.attributeStore.status,
            attributes: state => state.attributeStore.data,
            meta: state => state.attributeStore.meta,
            list: state => state.attributeStore.list,
            selectedTryout: state => state.navigatorStore.selectedTryout,
        }),
        loading: function() {
            return this.status.loading
        },
        changes() {
            return Object.keys(this.changeTracker).length>0 || this.orderChanges
        }
    },
    methods: {
        ...mapActions('attributeStore', {
            findAllAttributes: 'findAllForEvent',
            addAttribute: 'create',
            updateAttribute: 'update',
            archiveAttribute: 'archive',
        }),
        create: function async (reset) {
            reset()
            let self = this;
            const newAttr= {
                title: self.newAttribute,
                type: 'int',
                description: self.newDescription,
                eventId: self.selectedTryout,
                units: self.newUnits
            }
            self.addAttribute(newAttr)
            self.newAttribute=''
            self.newDescription=''
            self.$refs.form.reset()
            self.dialog=false
        },
        closeDialog(){
            this.newAttribute=''
            this.newDescription=''
            this.newUnits=''
            this.$refs.form.reset()
            this.dialog=false
        },
        saveDetails(){
            for(var idx in this.list) {
                const attributeId = this.list[idx]
                this.updateAttribute(this.attributes[attributeId])
            }
            this.changeTracker={}
            this.orderChanges=false
            this.$store.dispatch('alert/success', 'Tryout Updated', {root:true})
            this.$root.$emit("settings-clear-attribute-changes-flags")
        }
    },
    mounted(){
        this.$root.$on('settings-attribute-changes-cleared', (id)=> {
            var self=this
            self.$delete(self.changeTracker, id)
        }),
        this.$root.$on('settings-attribute-changes-made', (id) => {
            var self=this
            self.$set(self.changeTracker, id, true)
        })
        this.$root.$on('submit-all-changes', () => {
            if(this.changes){
                this.saveDetails()
            }
        })

        this.$root.$on('backout-all-changes', async () => {
            if(this.changes){
                this.findAllAttributes({eventId: this.selectedTryout})
            }
        })
        this.$root.$on('tryoutRefresh',this.refreshHandler)
    }, 
    async created() {
        await this.findAllAttributes({eventId: this.selectedTryout})
    },
    beforeDestroy(){
        this.$root.$off('tryoutRefresh',this.refreshHandler)
    },
    data(){
        return {
            refreshHandler: async()=>{
                await this.findAllAttributes({eventId: this.selectedTryout})
            },
            orderChanges: false,
            changeTracker: {},
            rules: rules,
            dialog: false,
            newAttribute: '',
            newDescription: '',
            newUnits: '',
        }
    }
}
</script>

<style>

</style>