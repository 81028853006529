<template>
    <loading 
        class="loading"
        :active.sync="loading" 
        v-if="loading"
        :can-cancel="false"
        :is-full-page="true"
        loader="dots"
    />
    <v-card v-else>
        <FSLoading :isLoading="loadingPayment"/>
        <ValidationObserver v-if="!loadingPayment" v-slot="{invalid}">
            <v-card-text>
            <span class="sessiontitle">Registration Confirmation</span>
            <div class="text-left">You are registering for: {{tryouts[selectedTryout].title}}</div>
            <br/>

            <span class="sessiontitle">Your responses to our questions</span>
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th></th>
                            <th class="text-left">
                                Question
                            </th>
                            <th class="text-left">
                                Your Response
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="questionId in questionList" :key="questionId">
                            <td>
                                <span style="font-weight:600">{{questionNumber(questionId)}}</span>
                            </td>
                            <td class="text-left">
                                {{questions[questionId].question}}
                            </td>
                            <td v-if="formResponses" class="text-left">
                                <span v-if="questions[questionId].type==='select'">{{ getSelectText(questionId)}}</span>
                                <span v-else-if="questions[questionId].type==='checkbox'">{{ formResponses[questionId]?"Yes":"No" }}</span>
                                <span v-else-if="questions[questionId].type==='role'">
                                   <!-- Crude - assume there are two roles specified--> 
                                   {{ formResponses.hasOwnProperty(questionId)?roles[JSON.parse(formResponses[questionId])[0]].name :'' }},
                                   {{ formResponses.hasOwnProperty(questionId)?roles[JSON.parse(formResponses[questionId])[1]].name :'' }}
                                </span>
                                <span v-else> {{formResponses[questionId]}}</span>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <br/>
            <template v-if="!tryouts[selectedTryout].feeExemption">
                <span class="sessiontitle">Fees</span>
                <div class="instruction">Select the fee below which is applicable for you</div>
                <v-radio-group v-model="feeSelection">
                    <v-radio 
                        v-for="fee in feeTable" :key="fee.id" :label="`${fee.value} (${fee.description})`" :value="fee.id"
                    />
                </v-radio-group>
            </template>
            <br/>
            <span class="sessiontitle">Acknowledgement</span>
                <ValidationProvider name="Acknowledgement or waiver" rules="set" v-slot="{errors}">
                    <v-checkbox 
                        v-model="waiver"
                        label="I acknowledge that participating in, and/or paying for, this series of trainings and selections does not guarantee that I will be selected for a team, and that in the event that I am not selected I am not entitled to a refund. Likewise, should I decide to withdraw from this selection series I also acknowledge that I am not entitled to a refund."
                    />
                    <div class="field-error">{{errors[0]}}</div>
                </ValidationProvider>
                <ValidationProvider name="Acknowledgement of use of profile information" rules="set" v-slot="{errors}">
                    <v-checkbox 
                        v-model="privacy"
                        label="I acknowledge that by registering for this event, CoachSight will allow this club and its staff to access my profile information and to collect information about my performance and athletic characteristics"
                    />
                    <div class="field-error">{{errors[0]}}</div>
                </ValidationProvider>
                <v-divider/>
                <!-- 
                <div class="buttons">
                    <v-row>
                        <v-col cols="2">
                            <v-btn 
                                color="secondary"
                                @click="lastStep()"
                            >
                                Back
                            </v-btn>
                        </v-col>
                        <v-spacer/>
                        <v-col cols="4">
                            <v-btn v-if="tryouts[selectedTryout].feeExemption" color="primary" @click="saveResponses()" :disabled="invalid" > Confirm </v-btn>
                            <v-btn v-else color="primary" @click="loadingPayment=true;saveResponses()" :disabled="invalid || loadingPayment" > Confirm and Pay using Stripe </v-btn>
                        </v-col>
                        <v-col cols="2">
                            <v-btn text @click="cancel">
                                Cancel
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            -->
            </v-card-text>
            <v-card-actions>
                <v-btn 
                    color="secondary"
                    @click="lastStep()"
                >
                    Back
                </v-btn>
                <v-spacer/>
                <v-btn v-if="tryouts[selectedTryout].feeExemption" color="primary" @click="saveResponses()" :disabled="invalid" > Confirm </v-btn>
                <v-btn v-else color="primary" @click="loadingPayment=true;saveResponses()" :disabled="invalid || loadingPayment" > Confirm and Pay using Stripe </v-btn>

            </v-card-actions>
        </ValidationObserver>
    </v-card>

</template>

<script>
import { mapState, mapActions} from 'vuex'
//import Loading from '@/components/loading'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import FSLoading from '@/components/FSLoading'
import {rules} from '@/_helpers/rules'
import { router } from '@/_helpers';
import { ValidationObserver, ValidationProvider} from 'vee-validate'

export default {
    props: [
    ],
    components: {
        FSLoading,
        Loading,
        ValidationObserver, 
        ValidationProvider,
    },

    computed : {

        ...mapState({
            tryouts: state => state.tryoutStore.data,
            status: state => state.tryoutStore.status,
            sessions: state => state.sessionStore.data,
            sessionMeta: state => state.sessionStore.meta,
            sessionList: state => state.sessionStore.list,
            questions: state => state.appquestionStore.data,
            questionList: state => state.appquestionStore.list,
            participants: state=> state.participantStore.data,
            participantList: state=> state.participantStore.list,
            participantStatus: state => state.participantStore.status,
            account: state => state.account,
            selectedTryout: state => state.navigatorStore.selectedTryout,
            formStep: state => state.tryoutregStore.step,
            formResponses: state => state.tryoutregStore.qresponses,

            formStatus: state => state.tryoutregStore.status,
            regComplete: state => state.tryoutregStore.complete,
            selectedClub: state => state.navigatorStore.selectedClub,
            qresponses: state => state.qresponseStore.responses,

            stripeInfo: state => state.stripeStore.data,
            stripeError: state => state.stripeStore.error,

            roles: state => state.roleStore.data, 

            globalSettings: state => state.globalsettingsStore.data,
        }),
        loading(){
            return false 
                || this.wait 
                || this.formStatus.unstable
        },
        validated() {

                if(this.$refs.signupForm) {
                    return this.$refs.signupForm.validate()
                } else {
                    return false
                }
        },
        contactDetails: {

            get() {
                return "Andy Davison (0408 255 761)"
            }
        },
        regFormState: {
            get() {
               return this.formStep 
            },
            set(newValue) {
                this.setStep(newValue)
            }
        },
        feeTable(){
            const feeArray = []
            const tryout = this.tryouts[this.selectedTryout]
            if(tryout.trialFee1Value>0 || tryout.trialFee1Name !== '') feeArray.push({id: 1, description: tryout.trialFee1Name, value: ((this.globalSettings.baseFee+tryout.trialFee1Value)/100).toLocaleString('en-AU', {style:'currency', currency:'AUD'})})
            if(tryout.trialFee2Value>0 || tryout.trialFee2Name !== '') feeArray.push({id: 2, description: tryout.trialFee2Name, value: ((this.globalSettings.baseFee+tryout.trialFee2Value)/100).toLocaleString('en-AU', {style:'currency', currency:'AUD'})})
            if(tryout.trialFee3Value>0 || tryout.trialFee3Name !== '') feeArray.push({id: 3, description: tryout.trialFee3Name, value: ((this.globalSettings.baseFee+tryout.trialFee3Value)/100).toLocaleString('en-AU', {style:'currency', currency:'AUD'})})

            // If they haven't set any fee at all, just charge the platform fee.
            if(feeArray.length===0){
                feeArray.push({id: 0, description: 'Tryout fee', value: (this.globalSettings.baseFee/100).toLocaleString('en-AU', {style:'currency', currency:'AUD'})})
            }
            return feeArray
        }

    },
    methods: {
        ...mapActions('tryoutStore', {
            findAllTryouts: 'findAll',
            updateTryoutData: 'update',
        }),
        ...mapActions('sessionStore', {
            findAllSessions: 'findAllForEvent'
        }),
        /*
        ...mapActions('appquestionStore', {
            findAllQuestions : 'loadAllQuestionsForEvent'
        }),
        */
        ...mapActions('qresponseStore', {
            addResponse: 'add'
        }),
        ...mapActions('participantStore', {
            addParticipant: 'add',
            updateParticipant: 'update',
            updateParticipantProp: 'propertyUpdate',
        }),
        ...mapActions('tryoutregStore', {
            setStep: 'setStep',
            lastStep: 'lastStep',
            nextStep: 'nextStep',
            setCompletion: 'setCompletion',
            setError: 'setError',
            setNewParticipant: 'setNewParticipant',
        }),
        ...mapActions('navigatorStore', {
            selectClubPane: 'selectPane',
        }),
        ...mapActions('stripeStore', {
            tryoutCharge: 'tryoutCharge',
        }),
        ...mapActions('qresponseStore', {
            updateQResponseProps: 'propertyUpdate',
            updateQResponse: 'update',
        }),
        cancel(){
            this.selectClubPane('SeasonHome')
            router.push('/clubhouse/'+this.selectedClub)
        },
        applicableRules(question) {
            let response=""
            if (question.required)
                response="required"
            return response

        },
        juniorAthlete() {
            const age = new Date().getFullYear() -  this.account.user.details.yearOfBirth
            return age < this.tryouts[this.selectedTryout].juniorAge
        },
        aRule() {
            return "required"
        },
        getSelectText(questionId){
            const options = JSON.parse(this.questions[questionId].options)

            if(this.formResponses[questionId]>options.length) return ""
            const text = options[this.formResponses[questionId]].text
            return text
        },
        async saveResponses () {
            // TODO: Need to check whether there is already a participant record, in case we are re-entrant...
            this.loadingPayment=true
            this.wait=true
            // Create a new participant
            try {

                const consentResponses = this.formResponses[3]

                var participantId=null

                if(this.participantList.length===0){
                    // This is a new registration
                    const newParticipant = {
                        userId: this.account.user.details.id,
                        eventId: this.selectedTryout,
                        firstName: this.account.user.details.firstName,
                        lastName: this.account.user.details.lastName,
                        feeType: this.feeSelection>0?this.feeSelection:0,

                    }

                    if(consentResponses){
                        if(Object.prototype.hasOwnProperty.call(consentResponses,'parentConsent')) newParticipant.parentConsent = consentResponses.parentConsent
                        if(Object.prototype.hasOwnProperty.call(consentResponses,'imageConsent')) newParticipant.imageConsent = consentResponses.imageConsent
                        if(Object.prototype.hasOwnProperty.call(consentResponses,'parentName')) newParticipant.parentName = consentResponses.parentName
                        if(Object.prototype.hasOwnProperty.call(consentResponses,'parentPhone')) newParticipant.parentPhone = consentResponses.parentPhone
                        if(Object.prototype.hasOwnProperty.call(consentResponses,'parentEmail')) newParticipant.parentEmail = consentResponses.parentEmail
                    }
                    newParticipant.junior = this.juniorAthlete()
                    await this.addParticipant(newParticipant)


                    this.setNewParticipant({participant: this.participantStatus.added})
                    var partId='';

                    for(var idx in this.questionList){
                        const id = this.questionList[idx]
                        partId = this.participantList[0]
                        this.addResponse({
                            participantId: partId,
                            eventId: this.selectedTryout,
                            questionId: id,
                            response: this.formResponses[id]
                        })
                    }
                    participantId = this.participantStatus.added.id
                    // Set the cached tryout to say that the user has registered...
                    this.updateTryoutData({field: 'registered', id: this.selectedTryout, value: true})
                    this.updateTryoutData({field: 'participantId', id: this.selectedTryout, value: this.participantStatus.added.id})

                } else {
                    participantId = this.participantList[0]
                    // There is an existing participant and responses, so lets update those as necessary...
                    this.updateParticipantProp({field: 'feeType',id: participantId, value: this.feeSelection})
                    this.updateParticipantProp({field: 'parentConsent',id: participantId, value: this.parentConsent})
                    this.updateParticipantProp({field: 'imageConsent',id: participantId, value: this.imageConsent})
                    this.updateParticipantProp({field: 'parentName',id: participantId, value: this.parentName})
                    this.updateParticipantProp({field: 'parentPhone',id: participantId, value: this.parentPhone})
                    this.updateParticipantProp({field: 'parentEmail',id: participantId, value: this.parentEmail})

                    this.updateParticipant(this.participants[participantId])


                    for(var idx2 in this.questionList){
                        const id = this.questionList[idx2]
                        partId = this.participantList[0]
                        this.updateQResponseProps({
                            field: 'response',
                            id: this.qresponses[id].id,
                            value: this.formResponses[id]
                        })
                        await this.updateQResponse(this.qresponses[id])
                    }
                    this.updateTryoutData({field: 'registered', id: this.selectedTryout, value: true})
                    this.updateTryoutData({field: 'participantId', id: this.selectedTryout, value: this.participantList[0]})
                    this.setNewParticipant({participant: this.participantList[0]})


                }


                // Send them an email with the confirmation information
                await this.tryoutCharge({eventId: this.selectedTryout, participantId: participantId})
                if(this.stripeError) {
                    this.$toast.error('Your payment transaction could not be processed')
                    this.wait=false
                    this.loadingPayment=false
                } else {
                    // Send us off to the Stripe payment interface
                    window.location=this.stripeInfo.paymentURL
                }

              //  this.setCompletion({complete: true})
              //  this.nextStep()

            } catch(error) {
                this.wait=false
                if(error.message==="Already Registered") {
                    this.setCompletion({complete: false})
                    this.setError({error: 'Already Registered'})
                    this.nextStep()
                    
                }
            }
        },
        questionNumber: function(id) {
            return `Q${this.questions[id].order+1}: `
        },
        questionLabel: function(id) {
            return `Q${this.questions[id].order+1}: ${this.questions[id].question}`
        },



    },
    mounted() {
    },
    created() {
    //        const localUser = JSON.parse(localStorage.getItem('user'))
    },
    data() {
        return {
            loadingPayment: false,
            wait: false,
            rules: rules,
            waiver: false,
            privacy: false,
            feeSelection: null,
        }
    },

}
</script>

<style>
.v-label {
    display: block !important;
}

.instruction{
    font-style: italic;
    font-size: small;
    text-align:left
}

.v-input--selection-controls .v-input__slot > .v-label {
    flex-direction: column;
    align-items: flex-start;
}
.aquestion-label {
    flex-direction: column;
    align-items: flex-start;
    display: block !important;
    word-break: break-word;

}
.questionLabel-mobile{
    font-size: 0.9em;
    display: flex;
    justify-content: left;
    text-align: left;
            padding-left: 2em;
        text-indent:-2em;
    margin-top: 5px;
}

.field-error {
    text-align: left;
    color: red;
    font-size: small;
}

.loading{
    color: var(--cs1-lev1)
}


</style>