<template>
    <div style="text-align:left;padding: 0px 10px 0px 10px">
        <ValidationObserver v-slot="{handleSubmit, reset}" ref="form">
            <v-row>
                <v-col cols="12" style="text-align:right">
                    <v-btn @click.native.stop 
                        v-if="false && changes" 
                        x-small 
                        v-on:click="handleSubmit(saveDetails(reset))" 
                        depressed 
                        color="primary" 
                        class="save-btn"
                        style="margin-right:10px"
                    >
                        Save
                    </v-btn>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="6">
                    <v-checkbox label="Allow juniors to apply to this event" v-model="allowJuniors"/>
                </v-col>
            </v-row>
            <v-divider/>
            <v-row v-if="allowJuniors">
                <v-col cols="4">
                    <h3>Age Cut-off Date</h3><br/>
                    What is the cut off date for determining age qualification (defaults to 1st Jan next year)
                    
                    <v-menu
                        v-model="cutoffDateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                        >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                            v-model="dateFormatted"
                            label="Cutoff Date"
                            hint="DD/MM/YYYY format"
                            persistent-hint
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="juniorCutoff"
                            no-title
                            @input="cutoffDateMenu = false"
                        ></v-date-picker>
                    </v-menu>


                </v-col>
                <v-spacer/>
                <v-col cols="6" v-if="juniorCutoff" class="mt-0">
                    <v-text-field :rules="[rules.yob]" :label="ageMsg" v-model="juniorAge"/>
                </v-col>
                <v-col cols="12" class="text-left">
                    <h3>Note: Parental/Guardian Consent will be requested for online sign-ups</h3>
                    By allowing juniors, CoachSight will add a step to the registration process to collect the contact details for a junior's parent/guardian. 
                    You should follow up with guardians via email or by phone to confirm their consent is genuine.
                </v-col>
            </v-row>
            <v-divider v-if="allowJuniors"/>
            <v-row v-if="allowJuniors">
                <v-col cols="12" class="text-left">
                    <span>This message will be displayed on the parental consent form. Parents will be asked to acknowledge its content.</span>
                </v-col>
                <v-col cols="12">
                    <vue-editor v-model="iparentInfo" :editorOptions="editorSettings" @text-change="textChangePI" width="100%"/>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col>
                    <span>This message will be displayed to athletes who are below your specified junior age</span>
                </v-col>
                <v-col cols="12">
                    <vue-editor v-model="ijuniorExclusion" :editorOptions="editorSettings" @text-change="textChangeJE" width="100%"/>
                </v-col>
            </v-row>

        </ValidationObserver>
    </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import {mapState, mapActions} from 'vuex'
import { rules } from '@/_helpers/rules'
import {VueEditor} from 'vue2-editor'
import {v4 as uuidv4} from 'uuid'
import moment from 'moment'

//eslint-disable-next-line
import DividerToolbar from 'quill-divider'

export default {
    props: [],
    components: {
        VueEditor,
        ValidationObserver,
    },
    computed: {
        ...mapState({
            tryouts:        state => state.tryoutStore.data,
            tryoutStatus:   state => state.tryoutStore.status,

            messages:       state => state.messageStore.data,
            messageList:    state => state.messageStore.list,
            msgByName:      state => state.messageStore.listByName,
            messageStatus:   state => state.messageStore.status,

            selectedTryout: state => state.navigatorStore.selectedTryout,
        }),
        loading: function() {
            return false
                || this.tryoutStatus.loading 
                || this.messageStatus.loading
        },
        dateFormatted() {
            return this.formatDate(this.juniorCutoff)
        },
        
        allowJuniors: {
            get() {
                return this.tryouts[this.selectedTryout].allowJuniors
            },
            set(newValue) {
                this.flagChanges()
                this.updateTryoutProp({
                    field: 'allowJuniors',
                    id: this.selectedTryout,
                    value: newValue
                })
            }
        },
        juniorCutoff: {
            get() {
                return moment(this.tryouts[this.selectedTryout].juniorCutoff).format('YYYY-MM-DD')
            },
            set(newValue){
                this.flagChanges()
                this.updateTryoutProp({
                    field: 'juniorCutoff',
                    id: this.selectedTryout,
                    value: newValue
                })
            }
        },
        juniorAge: {
            get() {
                return this.tryouts[this.selectedTryout].juniorAge
            },
            set(newValue){
                this.flagChanges()
                this.updateTryoutProp({
                    field: 'juniorAge',
                    id: this.selectedTryout,
                    value: newValue
                })
            }
        },
        rules: function() {
            return {
                yob: value => (this.validJuniorCutoffAge(value)) || 'Age must be between 1 and 21',
                juniorCutoff: value => (this.validJuniorCutoff(value)) || 'Jutoff date must be provided',
                ...this.baseRules,
            }
        },
        juniorMessage: {
            get() {
//                return this.messages[this.msgByName['juniorExclusion'].id].message
                return this.ijuniorExclusion
            },
            set(newValue) {
                this.flagChanges()
                this.ijuniorExclusion = newValue
            }
        },
        parentInfo: {
            get() {
                return this.iparentInfo
            },
            set(newValue) {
                this.flagChanges()
                this.iparentInfo = newValue
            }
        },
        noChildSafetyPlan: function() {
            return false
        },
        ageMsg(){
            return `What is the maximum age for a junior before ${moment(this.juniorCutoff).format('DD/MM/YYYY')} ?`
        }
    },
    methods: {
        ...mapActions('tryoutStore', {
            updateTryoutProp: 'update',
            updateTryout: 'updateTryout',
            loadTryout: 'loadTryout',
        }),
        ...mapActions('messageStore',{
            updateMessageProperty: 'propertyUpdate',
            updateMessage: 'update',
            findAllMessages: 'loadAllForEvent'
        }),
        flagChanges(){
            this.changes=true
            this.$root.$emit("settings-changes-made", this.componentId)
        },
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
        parseDate (date) {
            if (!date) return null

            const [day, month, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },

        async saveDetails(reset){
            if(reset) reset()
            this.updateTryout(this.tryouts[this.selectedTryout])            
            this.updateMessageProperty({field:'message', id: this.msgByName['parentInfo'].id, value: this.iparentInfo})
            this.updateMessageProperty({field:'message', id: this.msgByName['juniorExclusion'].id, value: this.ijuniorExclusion})
            this.updateMessage({message: this.messages[this.msgByName['juniorExclusion'].id]})
            this.updateMessage({message: this.messages[this.msgByName['parentInfo'].id]})

            this.changes=false
            this.$root.$emit("settings-changes-cleared", this.componentId)
            this.$store.dispatch('alert/success', 'Tryout Updated', {root:true})
        },
        textChangeJE(){
            this.updateMessageProperty({field:'message', id: this.msgByName['juniorExclusion'].id, value: this.ijuniorExclusion})
            if(this.juniorExclusionFirstChange){
                this.juniorExclusionFirstChange=false
                return
            }
            this.flagChanges()
        },
        textChangePI(){
            this.updateMessageProperty({field:'message', id: this.msgByName['parentInfo'].id, value: this.iparentInfo})
            if(this.parentInfoFirstChange){
                this.parentInfoFirstChange=false
                return
            }
            this.flagChanges()
        },
        validJuniorCutoffAge(value){
            if(!this.allowJuniors){
                return true
            }
            if(!value) {
                return 'Junior Cutoff Age is required if juniors are allowed to apply'
            }
            if (value>0 && value <=21){
                return true
            }
            return 'Invalid value for cut-off age (0-21)'
        },
        validJuniorCutoff(value){
            if(!this.allowJuniors){
                return true
            }
            if(!value){
                return 'Junior Cutoff Date is required if juniors are alloweed to apply'
            }
            let date = new Date(value)
            if (date instanceof Date && !isNaN(date)){
                return true
            }
            return 'Cut-off date is not valie'
        },

    },
    mounted(){
        this.$root.$on('settings-clear-changes-flags', ()=> {
            var self=this
            self.changes=false
        })
        this.$root.$on('submit-all-changes', () => {
            if(this.changes){
                this.saveDetails()
            }
        })

        this.$root.$on('backout-all-changes', () => {
            if(this.changes){
                this.loadTryout(this.selectedTryout)
                this.findAllMessages(this.selectedTryout)
                this.ijuniorExclusion = this.messages[this.msgByName['juniorExclusion'].id].message
                this.iparentInfo = this.messages[this.msgByName['parentInfo'].id].message
            }
        })

        this.$root.$on('tryoutRefresh', this.refreshHandler)
    },
    created() { 
        this.componentId = uuidv4()
        this.ijuniorExclusion = this.messages[this.msgByName['juniorExclusion'].id].message
        this.iparentInfo = this.messages[this.msgByName['parentInfo'].id].message
    },
    beforeDestroy(){
        this.$root.$off('tryoutRefresh', this.refreshHandler)

    },
    data() {
        return {
            refreshHandler: async () => {
                await this.findAllMessages(this.selectedTryout)
                this.ijuniorExclusion = this.messages[this.msgByName['juniorExclusion'].id].message
                this.iparentInfo = this.messages[this.msgByName['parentInfo'].id].message
            },
            cutoffDateMenu: false,
            componentId: 0,
            changes: false,
            baseRules: rules,
            iparentInfo: '',
            parentInfoFirstChange:true,
            juniorExclusionFirstChange:true,
            ijuniorExclusion: '',
            editorSettings: {
                modules:{
                    divider: true,
                    toolbar: {
                        container: 
                        [
                            [{header: []}],
                            ["bold","italic","underline",{ "color": []} ],
                            [{list: "ordered"}, {list: "bullet"}],
                            [{align: []}],
                            ["divider", "link"],
                            ["closeButton"],

                        ],
                        handlers: {
                            closeButton: () => {
                            },
                            customBtn: () => {
                                var range = this.quill.getSelected()
                                if(range) {
                                    this.quill.insertText('ArgH!')

                                }
                            }
                        }
                    }
                }
            },
        }
    }
}

</script>

<style>

@import "https://cdnjs.cloudflare.com/ajax/libs/quill/1.3.7/quill.core.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/quill/1.3.7/quill.snow.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/quill/1.3.7/quill.bubble.css";

.ql-toolbar:after {
    content: none !important;
    text-align: left;
}

.ql-closeButton {
    text-align: center;
}
</style>