<template>
    <div v-if="!loading">
        <h4>Offer Accepted</h4>
        <p>Your position in {{ currentTeam.name }} has been accepted. </p><p>You will receive more information about the team shortly.</p>

        <p>        Congratulations!</p>
    </div>

</template>

<script>
import { mapActions, mapState } from 'vuex';

import { router } from '@/_helpers'

export default{
    props: [ 'token','type' ],
    components: {},
    computed: {
        ...mapState({
            transtoken: state => state.transtokenStore.data,
            transtokenError: state => state.transtokenStore.error,
            account:    state => state.account,
            teams:      state => state.teamStore.data,
            teamList:   state => state.teamStore.list,
            tryouts:    state => state.tryoutStore.data,
            tryoutList: state => state.tryoutStore.list,

            participants: state => state.participantStore.data,
            participantList: state => state.participantStore.list,
            participant: state => state.participantStore.participant,

        }),
        loading(){
            return false
                || this.account.loading
                || !this.stable
        },
        currentTeam(){
            return this.teams[this.participant.offer]
        }

    },
    methods: {
        ...mapActions('participantStore', {
            findAllParticipantsForEvent: 'findAllForEvent',
            findParticipant: 'find',
        }),
        ...mapActions('transtokenStore',{
            fetchTransactionInfo: 'fetch',
            completeTransaction: 'completeTransaction'

        }),
        ...mapActions('tryoutStore', {
            loadTryout: 'loadTryout',
        }),
        ...mapActions('tryoutregStore', {
            setStep: 'setStep',
        }),
        ...mapActions('navigatorStore', {
            selectClub: 'selectClub',
            selectClubPane: 'selectPane',
            selectSeason: 'selectSeason',
            selectTryout: 'selectTryout',
            selectBankState: 'selectBankState',
            selectNav: 'selectNav',
            initNavigator: 'initialiseNavigator',
        }),
        ...mapActions('teamStore', {
            findAllTeams: 'findAllForEvent'
        }),
    },
    async created() {
        this.stable=false
        // load the transation data
        this.initNavigator()

        await this.fetchTransactionInfo(this.token)

        if(this.tryoutList.length===0) await this.loadTryout(this.transtoken.eventId)
        if(this.teamList.length===0) await this.findAllTeams({eventId: this.transtoken.eventId})

        // If the token fetch failed... flash up an error msg and go to the root page...

        if(this.transtokenError){

            this.$store.dispatch('alert/warn', 'Your payment succeeded, but something went wrong with the app. Please reload the page.', {root:true})
            //router.push('/')
        }

        // Reload the participant data...
        await this.findParticipant({participantId: this.transtoken.participantId })

        // setup the club, season and pane data to send the app straight back to the closing screen

/*

        this.selectClub(this.transtoken.clubId)
        this.selectTryout(this.transtoken.eventId)
        this.selectSeason(this.transtoken.seasonId)
        this.selectClubPane('TryoutRegistration')
        this.selectNav('Season')
        this.selectBankState('paid')
//        await this.completeTransaction(this.transtoken.clubId)
        router.push(`/clubhouse/${this.transtoken.clubId}`)
*/
        this.stable=true
    },
    data(){
        return {
            stable: false,
            router: router,


        }
    }
}

</script>