<template>
    <loading v-if="loading"/>
    <div v-else>
        <div v-if="userStatus.verified">
            <v-row class="coachsight-content hero">
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <div >

                            <div v-if="newUser" class="welcome-info-frame">
                                <div class="welcome-head">
                                    Welcome to CoachSight!
                                </div>

                                <div class="welcome-info">
                                    It looks like your new, so let's start by setting up your password.

                                </div>
                            </div>
                            <ValidationObserver v-slot="{invalid, handleSubmit}">
                                <v-card style="margin-left:auto;margin-right:auto;margin-top:30px;" :loading="status.loggingIn" ourlines shaped elevation="3" width="60%">
                                    <v-card-title>
                                        Set your new password
                                    </v-card-title>
                                    <v-card-text>
                                        <v-overlay :value="submitted">
                                            <v-card>
                                                <v-card-text>
                                                    <div class="processing">Resetting your password</div>
                                                    <v-progress-circular
                                                        indeterminate
                                                        color="primary"
                                                        >
                                                    </v-progress-circular>  

                                                </v-card-text>
                                            </v-card>
                                        </v-overlay>
                                        <!-- 
                                        <ValidationProvider name="password" rules="required|strength:2|password:@confirm" mode="aggressive" v-slot="{errors}">
                                            <v-text-field ref="defaultFocus" label="Password" v-model="user.password" :append-icon="showPassword? 'mdi-eye' : 'mdi-eye-off'"
                                                validate-on-blur
                                                :type="showPassword? 'text' : 'password'"
                                                @click:append="showPassword = !showPassword"
                                            />
                                            <div class="form-error">{{ errors[0] }}</div>
                                        </ValidationProvider>
                                    -->
                                        <ValidationProvider name="password" rules="required|password:@confirm" mode="aggressive" v-slot="{errors}">
                                            <v-text-field ref="defaultFocus" label="Password" v-model="user.password" :append-icon="showPassword? 'mdi-eye' : 'mdi-eye-off'"
                                                validate-on-blur
                                                :type="showPassword? 'text' : 'password'"
                                                @click:append="showPassword = !showPassword"
                                            />
                                            <div class="form-error">{{ errors[0] }}</div>
                                        </ValidationProvider>
                                        <ValidationProvider name="confirm" rules="required" mode="aggressive" v-slot="{errors}">
                                            <v-text-field label="Confirm Password" v-model="user.confirmPassword" :type="showPassword? 'text':'password'" />
                                            <div class="form-error">{{ errors[0] }}</div>
                                        </ValidationProvider>
                                        <div class="strength-label">Password Strength</div>
                                        <v-progress-linear
                                            :color="score.color"
                                            :value="score.value"
                                            label="Password Strength"
                                            />
                                        <img v-show="status.registering" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer/>
                                        <v-btn :disabled="invalid" text color="primary" @click="handleSubmit(resetPassword)">Save Password</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </ValidationObserver>
                        </div>

                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                            <div class="welcome-image">
                                <v-img src="https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/app_assets/hockey-field-coach.png"></v-img>
                                <v-img class="welcome-logo" src="https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/app_assets/Totally-White-animated-icon.gif"/>
                            </div>
                    </v-col>
                </v-row>






<!--
            <div style="width:50%;margin-left:auto;margin-right:auto">
                <ValidationObserver v-slot="{invalid, handleSubmit}">
                    <v-card class="mx-auto" :loading="status.loggingIn" outlined shaped elevation= "5" >
                        <v-card-text>
                            <h3>Resetting your password</h3>
                            <div>
                                <ValidationProvider name="password" rules="required|strength:3|password:@confirm" mode="aggressive" v-slot="{errors}">
                                    <v-text-field ref="defaultFocus" label="Password" v-model="user.password" :append-icon="showPassword? 'mdi-eye' : 'mdi-eye-off'"
                                        validate-on-blur
                                        :type="showPassword? 'text' : 'password'"
                                        @click:append="showPassword = !showPassword"
                                    />
                                    <div class="form-error">{{ errors[0] }}</div>
                                </ValidationProvider>
                                <ValidationProvider name="confirm" rules="required" mode="aggressive" v-slot="{errors}">
                                    <v-text-field label="Confirm Password" v-model="user.confirmPassword" :type="showPassword? 'text':'password'" />
                                    <div class="form-error">{{ errors[0] }}</div>
                                </ValidationProvider>
                                <div class="strength-label">Password Strength</div>
                                <v-progress-linear
                                    :color="score.color"
                                    :value="score.value"
                                    label="Password Strength"
                                    />
                            </div>
                            <img v-show="status.registering" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer/>
                            <v-btn :disabled="invalid" text color="primary" @click="handleSubmit(resetPassword)">Save Password</v-btn>
                        </v-card-actions>
                    </v-card>
                </ValidationObserver>
            </div>
        -->
        </div>
        <div v-else>
            <v-row>
                <v-col cols="12">
                    <span class="text-h5">Your request could not be verified</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    Your password reset request may have expired. If you want a new link, click the button below
                </v-col>
            </v-row>
            <v-row>
                <v-spacer/>
                <v-col cols="4">
                    <v-btn :disabled="disableLinkBtn" color="primary" @click="sendNewLink">Send a new reset link</v-btn>
                </v-col>
                <v-spacer/>
            </v-row>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import zxcvbn from 'zxcvbn';
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import Loading from '@/components/loading'

import { router } from '../_helpers'

export default {
    props: ["token", "newUser"],
    data () {
        return {
            disableLinkBtn:false,
            password: null,
            confirm: null,            
            showPassword: false,
            user: {
                email: '',
                phone: '',
                firstName: '',
                lastName: '',
                password: '',
                confirmPassword: ''
            },
            submitted: false,
            settingPassword: false
        }
    },
    components: {
        ValidationObserver,
        ValidationProvider,
        Loading,
    },
    computed: {
        ...mapState('account', ['status']),
        ...mapState({
            userStatus:     state => state.userStore.status,
        }) ,
        loading(){
            return false 
                || this.userStatus.loading
        },
        score() {
            const result = zxcvbn(this.user.password);
            switch(result.score)
            {
                case 4: return {
                    color: "light-green accent-3",
                    value:100
                }
                case 3: return {
                    color: 'lime',
                    value:75
                }
                case 2: return {
                    color: "yellow",
                    value: 50
                }
                case 1: return {
                    color: "orange",
                    value: 25
                }
                default: return {
                    color: "red",
                    value: 0
                }
            }
        },
    },
    methods: {
        ...mapActions('account', ['reset', 'login']),
        ...mapActions('userStore', ['verifyPasswordToken', 'newPasswordToken']),
        
        async resetPassword() {
                this.submitted = true;
                await this.reset({user: this.user, token: this.token, direct:true});
                this.$toast.success('Your password has been reset. We\'ll log you in now')
                this.submitted = false;

        },
        enableLinkBtn(){
            this.disableLinkBtn=false
        },
        async sendNewLink(){
            this.disableLinkBtn=true
            await this.newPasswordToken({token: this.token})
            if(this.userStatus.error) {
                var self=this
                setTimeout(function(){
                    self.enableLinkBtn()
                },5000 )
            } else {
                this.$toast.success('A new reset link has been sent to your email address')
                router.push('/')
            }
        }
    },
    async created(){
        /*
        setTimeout(() => {
            self.$refs.defaultFocus.$refs.input.focus()
        },200)
        */
        await this.verifyPasswordToken({token: this.token})
    }
};
</script>

<style scoped>

.container {
    display: flex;
    justify-content: center;
    padding: 40px;
}

.form-group-btn {
    margin-bottom: 10px;
}

.padding-on-top {
    padding-top: 20px;
}

.strength-label {
    font-size: x-sm;
}
.welcome-head{
    font-size:x-large;
    color: var(--cs1-lev1);
    font-family: "Varela Round";
    font-weight:600;

}

.welcome-info{
    margin:20px;
    text-align:left;
    font-size: large;
    font-family: "Varela Round"


}
.welcome-frame{
    display:flex;
    flex-flow: row;
}

.welcome-info-frame{
    display:flex;
    flex-flow:column;
}

.welcome-image{
    position:relative;
    display:inline;

}

.welcome-logo{
    position:absolute;
    bottom:0;
    transform: scale(0.5);
}
</style>