import {sendMessage} from '@/_helpers/service-tools'

export const activityService = {
    findAllForEvent,
    create,
    del,
    update,
}

async function findAllForEvent(eventId ) {
    return await sendMessage('GET', 'activity/findAllForEvent', {eventId}, null)
}

async function create(payload) {
    return await sendMessage('POST', 'activity', null, payload)
}

async function del(payload) {
    return await sendMessage('DELETE', 'activity', payload, null)
}

async function update(payload){
    return await sendMessage('PATCH', 'activity', {activityId: payload.id}, payload)
}