import zxcvbn from 'zxcvbn';

export const rules = {
        required:   value => !!value || 'Required',
        min:        value => (value && value.length >=5) || 'Password must be at least 5 characters long',
        strength:   value => (value && zxcvbn(value).score >=1 ) || 'Please use a stronger password. Try a mix of letters, numbers and symbols',
        confirm:    value => (value && value === this.user.password) || "Passwords don't match",
        email:      value => !value || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'Not a valid email address' ,
        length:     value => (value && value.length >= 5) || 'This must be longer than 5 characters',
        percent:    value => ((Number.isInteger(value))&&value >=0 && value <=100),
        numeric:    value => (isNaN(value))
}