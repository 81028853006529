<template>
    <loading v-if="loading"/>
    <div v-else >
        <!-- 
        <v-app-bar v-if="selectedClub" color="#2196f3" style="padding-left:10px;">
            <div>
                <v-avatar size="50" class="elevation-10" >
                        <img v-if="clubs[selectedClub].logo !== null" :src="clubs[selectedClub].logo">
                </v-avatar>
                <span class="club-name">{{clubs[selectedClub].name}}</span>
            </div>
        </v-app-bar>
        -->
        <v-app-bar height="32px" color="#36dbf4" style="padding-left:0px;margin-left:0px;">
                <div v-if="!selectedClub" style="display:flex;">
                    <span class="root-chev" @click="clubroomNav()"> Club Rooms </span>
                    <span class="first-chev" >Account</span>
                </div>
                <div v-else-if="selectedNav==='ClubHome'" style="display:flex;">
                    <span class="root-chev" @click="clubHomeNav()"> Club Home </span>
                    <span class="first-chev" >Account</span>
                </div>
                <div v-else-if="selectedNav==='Season'" style="display:flex;">
                    <span class="root-chev" @click="clubHomeNav()"> Club Home </span>
                    <span class="first-chev" @click="seasonHomeNav()"> Season: {{ seasons[selectedSeason].name }}</span>
                    <span class="second-chev" >Account</span>
                </div>
                <div v-else-if ="selectedNav === 'Tryout'" style="display:flex;">
                    <span class="root-chev" @click="clubHomeNav()"> Club Home </span>
                    <span class="first-chev" @click="seasonHomeNav()"> Season: {{ seasons[selectedSeason].name }}</span>
                    <span class="second-chev" @click="tryoutHomeNav()"> Tryout: {{ tryouts[selectedTryout].title }} </span>
                    <span class="third-chev" >Account</span>
                </div>

        </v-app-bar>

        <ValidationObserver v-slot="{handleSubmit, invalid, reset}" ref="form">
            <v-form @submit.prevent="handleSubmit(submit(reset))" lazy-validation>
                <v-row>
                    <v-spacer/>
                    <v-col cols="2">
                        
                        <v-btn
                            :disabled="invalid || !changes"
                            x-small
                            color="primary"
                            @click="submit(reset)"
                        >
                            Save
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col xs="12" sm="12" md="8" lg="8" xl="8" class="mt-0">
                        <BoundedBox borderColor="indigo" height="100%">
                            <span slot="title">Main Details</span>
                            <div slot="content">
                                <ValidationProvider rules="required" name="First name" v-slot="{errors}">
                                    <v-text-field label="First Name" v-model="firstName"/>
                                    <div class="field-error">{{ errors[0] }}</div>
                                </ValidationProvider>
                                <ValidationProvider rules="required" name="Last name" v-slot="{errors}">
                                    <v-text-field label="Last Name" v-model="lastName"/>
                                    <div class="field-error">{{ errors[0] }}</div>
                                </ValidationProvider>
                                <ValidationProvider rules="" name="Nickname" v-slot="{errors}">
                                    <v-text-field label="Preferred Name" v-model="nickName"/>
                                    <div class="field-error">{{ errors[0] }}</div>
                                </ValidationProvider>

                            </div>
                        </BoundedBox>
                    </v-col>
                    <v-col xs="12" sm="12" md="4" lg="4" xl="4" >
                        <BoundedBox sidePadding="0px" borderColor="indigo">
                            <span slot="title">Profile Image</span>
                            <div slot="content">
                                <div v-if="account.user.details.profileImage">
                                    <v-img contain :src="account.user.details.profileImage" height="150px" max-height="150px"/>
                                </div>
                                <div v-else>
                                    <v-icon size="200">mdi-account-circle</v-icon>
                                </div>
                                <ImageUpload consentRequired text label="Change profile image" type="User Image" :cropper="true" />
                            </div>
                        </BoundedBox>
                    </v-col>
                </v-row>
                <v-row><v-divider/></v-row>
                <v-row>
                    <v-col>
                        <BoundedBox borderColor="indigo">
                            <span slot="title">Contact Details</span>
                            <div slot="content">
                                <v-text-field label="Phone number" v-model="phone"/>
                                <ValidationProvider name="Email address" rules="required|email" v-slot="{errors}">
                                    <v-text-field label="Email address" v-model="email"/>
                                    <div id="error" class="text-field-error" >{{  errors[0]  }}</div>
                                </ValidationProvider>

                            </div>
                        </BoundedBox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <BoundedBox borderColor="indigo">
                            <span slot="title">Bio</span>
                            <div slot="content">
                                <v-textarea 
                                    hint="Tell us a bit about yourself so the selectors have some context about you"
                                    v-model="bio"
                                />
                            </div>
                        </BoundedBox>
                        </v-col>
                </v-row>
            </v-form>
        </ValidationObserver>
    </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import { ValidationProvider, ValidationObserver, extend } from "vee-validate"
import { required, email } from 'vee-validate/dist/rules'
import { router} from '../_helpers/router'
import Loading from '@/components/loading'
import BoundedBox from '@/components/BoundedBox'
import {rules} from '@/_helpers/rules'
import ImageUpload from '@/components/ImageUpload'

extend('required', required);
extend('email', email)

export default {
    
    components: {
        ValidationObserver,
        ValidationProvider,
        Loading,
        BoundedBox,
        ImageUpload,
    },
   
    computed: {
        ...mapState({
            account: state => state.account,
            tryouts: state => state.tryoutStore.data,
            tryoutStatus: state => state.tryoutStore.status,
            seasons: state => state.seasonStore.data,
            seasonStatus: state => state.seasonStore.status,
            clubs:   state => state.clubStore.data,
            clubStatus: state => state.clubStore.status,

        }),
        ...mapState({
            selectedClub: state => state.navigatorStore.selectedClub,
            selectedNav:  state => state.navigatorStore.selectedNav,
            selectedSeason: state => state.navigatorStore.selectedSeason,
            selectedTryout: state => state.navigatorStore.selectedTryout,

        }),
        loading() {
            return this.account.status.loading
            || this.account.status.loggingIn
            || this.clubStatus.loading 
            || this.seasonStatus.loading
            || this.tryoutStatus.loading
        },
        status: function() {
            return this.account.status
        },
        fieldRules() {
            return { 
                size: value => ! value || value.size < 1500000 || 'Profile Image must be < 500kB',
            }
        },
        firstName: {
            get() {
                return this.account.user.details.firstName
            },
            set(value){
                this.changes=true
                this.propertyUpdate({field:'firstName', value: value})
//                this.update({user:this.account.user.details, userId: this.account.user.details.id})
            }
        },
        lastName: {
            get() {
                return this.account.user.details.lastName
            },
            set(value){
                this.changes=true
                this.propertyUpdate({field:'lastName', value: value})
//                this.update({user:this.account.user.details, userId: this.account.user.details.id})
            }
        },
        nickName: {
            get() {
                return this.account.user.details.nickName
            },
            set(value){
                this.changes=true
                this.propertyUpdate({field:'nickName', value: value})
//                this.update({user:this.account.user.details, userId: this.account.user.details.id})
            }
        },
        email: {
            get() {
                return this.account.user.details.email
            },
            set(value){
                this.changes=true
                this.propertyUpdate({field:'email', value: value})
                //this.update({user:this.account.user.details, userId: this.account.user.details.id})
            }
        },
        phone: {
            get() {
                return this.account.user.details.phone
            },
            set(value){
                this.changes=true
                this.propertyUpdate({field:'phone', value: value})
//                this.update({user:this.account.user.details, userId: this.account.user.details.id})
            }
        },
        bio: {
            get() {
                return this.account.user.details.bio
            },
            set(value){
                this.changes=true
                this.propertyUpdate({field:'bio', value: value})
//                this.update({user:this.account.user.details, userId: this.account.user.details.id})
            }
        },
    },
    methods: {
        ...mapActions('account', ['propertyUpdate','update']),
        ...mapActions('seasonStore',{
            findAllSeasonsForClub: 'findAllForClub',
        }),
        ...mapActions('clubStore', {
            loadClub: 'load'
        }),
        ...mapActions('tryoutStore', {
            findAllTryoutsForSeason: 'findAllForSeason',
        }),
        submit(reset) {
            this.submitted = true
            this.update({userId: this.account.user.details.id, user: this.account.user.details, clubId:0, eventId: 0})
            this.changes=false
            this.$toast.success('Your account details have been updated')
            reset()
        },
        submitForm(){
           // const validated = this.$refs.form.validate()
        },
        back(reset){
            reset()
            if(this.selectedClub){
                router.push(`/clubhouse/${this.selectedClub}`)
            } else {
                router.push(`/clubrooms`)
            }

        },
        clubHomeNav(){
            router.push(`/clubhouse/${this.selectedClub}`)
        },
        seasonHomeNav(){
            this.selectPane('SeasonHome')
            this.selectNav('Season')
            router.push(`/clubhouse/${this.selectedClub}`)
        },
        tryoutHomeNav(){
            router.push(`/clubhouse/${this.selectedClub}`)
        },
        clubroomNav(){
                router.push(`/clubrooms`)
        }
    },
    async created(){
        if(this.selectedClub && Object.keys(this.clubs).length ===0){
            this.loadClub(this.selectedClub)
        }
        if(this.selectedSeason && Object.keys(this.seasons).length ===0){
            this.findAllSeasonsForClub({clubId:this.selectedClub, isAdmin: false})
        }
        if(this.selectedTryout && Object.keys(this.tryouts).length ===0) {
            this.findAllTryoutsForSeason(this.selectedSeason)
        }
    },
    data() {
        return {
            changes: false,
            rules: rules,
            router: router,
            user:{
                email: '',
                phone: '',
                firstName: '',
                lastName: '',
                nickName: '',
                sports: 'nosport0-0000-0000-0000-000000000000',
            },
            submitted: false,
            aFile: [],
        }
    },
}
</script>

<style scoped>

.text-field-error{
    font-size:small;
    color:red;
    text-align:left;
    margin-top:-20px;
    margin-bottom: 20px;

}

</style>